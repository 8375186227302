export const sesEventsToTrack = [ "open", "click", "complaint", "bounce" ]; //Sends are tracked via our internal event
export const sesEventsToTrackMap = arrayToTrueMapWithS(sesEventsToTrack);

export function arrayToTrueMapWithS(a : string[]) : {[key:string]:boolean} {
    const o:{[key:string]:boolean} = {};
    a.map(k => o[k + "s"] = true);

    return o;
}

export function arrayToTrueMap(a : string[]) : {[key:string]:boolean} {
    const o:{[key:string]:boolean} = {};
    a.sort((a,b) => a < b ? -1: 1).map(k => o[k] = true);

    return o;
}

export function yScaleMax(reportData: any) : "auto" | number {
    if(!reportData) {
        return 1;
    }

    const found = !!reportData.find((set:{data:{y:number}[]}) => !!set.data.find(dataPoint => dataPoint.y > 0));
    if(found) {
        return "auto";
    }

    return 1;
}

export const lineColorsByEventName:{[key:string]:string} = {
    "email_send_result_success": "#83b9c1",
    "ses_event_send": "#83b9c1",
    "send_email_request": "#83b9c1",
    "sends": "#83b9c1",

    "ses_event_open": "#8FBC8F",
    "opens": "#8FBC8F",
    
    "ses_event_click": "#9663Cc",
    "clicks": "#9663Cc",
    
    "ses_event_bounce": "#f2c80e",
    "bounces": "#f2c80e",

    "email_send_result_error": "#99484D",
    "unsuccessful_sends": "#99484D",

    "unsubscribes": "#ea1ed2",
    "unsubscribe": "#ea1ed2",

    "complaints": "#fd625d",
    "ses_event_complaint": "#fd625d"
}
export const lineColors:string[] = Object.values(lineColorsByEventName)
export function getLineColor(columnName: string): string {
    if(!lineColorsByEventName[columnName.toLowerCase()]) {
        console.error("missing color for " + columnName)
        return "#000"
    }
    return lineColorsByEventName[columnName.toLowerCase()]
}
