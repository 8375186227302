import {showToast} from "../ToastNotifications";
import {
  EmailPipelineReport, GetEmailPipelineReportResponsePayload, GrepLogResponsePayload, QueueReportResponsePayload,
} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";


export const REQUEST_EMAIL_PIPELINE_REPORT = 'REQUEST_EMAIL_PIPELINE_REPORT';
export const RECEIVE_EMAIL_PIPELINE_REPORT = 'RECEIVE_EMAIL_PIPELINE_REPORT';
export const REQUEST_GREP_LOG = 'REQUEST_GREP_LOG';
export const RECEIVE_GREP_LOG = 'RECEIVE_GREP_LOG';
export const REQUEST_ARCHITECTURE_ATTRIBUTES = 'REQUEST_ARCHITECTURE_ATTRIBUTES';
export const RECEIVE_ARCHITECTURE_ATTRIBUTES = 'RECEIVE_ARCHITECTURE_ATTRIBUTES';

interface IRequestEmailPipelineReport {
  type: typeof REQUEST_EMAIL_PIPELINE_REPORT,
}
interface IReceiveEmailPipelineReport {
  type: typeof RECEIVE_EMAIL_PIPELINE_REPORT,
  report: EmailPipelineReport,
}

interface IRequestGrepLog {
  type: typeof REQUEST_GREP_LOG,
  grepFor: string,
}
interface IReceiveGrepLog {
  type: typeof RECEIVE_GREP_LOG,
  report: GrepLogResponsePayload,
}

interface IRequestArchitectureAttributes {
  type: typeof REQUEST_ARCHITECTURE_ATTRIBUTES,
}
interface IReceiveArchitectureAttributes {
  type: typeof RECEIVE_ARCHITECTURE_ATTRIBUTES,
  report: QueueReportResponsePayload,
}

export function requestArchitectureReport(): IRequestArchitectureAttributes {
  return {
    type: REQUEST_ARCHITECTURE_ATTRIBUTES,
  }
}
export function receiveArchitectureReport(report: QueueReportResponsePayload): IReceiveArchitectureAttributes {
  return {
    type: RECEIVE_ARCHITECTURE_ATTRIBUTES,
    report,
  }
}


export function requestEmailPipelineReport(): IRequestEmailPipelineReport {
  return {
    type: REQUEST_EMAIL_PIPELINE_REPORT,
  }
}
export function receiveEmailPipelineReport(report: EmailPipelineReport): IReceiveEmailPipelineReport {
  return {
    type: RECEIVE_EMAIL_PIPELINE_REPORT,
    report,
  }
}

export function requestGrepLog(grepFor: string): IRequestGrepLog {
  return {
    type: REQUEST_GREP_LOG,
    grepFor,
  }
}
export function receiveGrepLog(report: GrepLogResponsePayload): IReceiveGrepLog {
  return {
    type: RECEIVE_GREP_LOG,
    report,
  }
}

export type HealthActionTypes = IRequestEmailPipelineReport | IReceiveEmailPipelineReport | IRequestGrepLog | IReceiveGrepLog | IReceiveArchitectureAttributes | IRequestArchitectureAttributes;

export function fetchEmailPipelineReport()  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestEmailPipelineReport());
    return fetchEmailPipelineReportPromise(dispatch)
  }
}

function fetchEmailPipelineReportPromise(dispatch: any) {
  //Get sender templates from api endpoint
  const url = "http://localhost:8008/emailpipelinereport"
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:EmailPipelineReport) => {
    // if(json.errors && json.errors.length > 0) {
    //   dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_EMAIL_PIPELINE_REPORT));
    //   showToast("Couldn't fetch report: " + errorsToUL(json.errors), "error");
    // }
    dispatch(receiveEmailPipelineReport(json))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_EMAIL_PIPELINE_REPORT));
    showToast(`Couldn't fetch report: ${denastifyError(e.message)}`, "error");
  });
}


export function fetchGrepLog(grepFor:string)  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestGrepLog(grepFor));
    return fetchGrepLogPromise(dispatch, grepFor)
  }
}

function fetchGrepLogPromise(dispatch: any, grepFor:string) {
  //Get sender templates from api endpoint
  //let url = getGatewayUrl("healthcheck") + "/greplog?grepFor=" + encodeURIComponent(grepFor);
  const url = "http://localhost:8008/greplog?grepFor=" + encodeURIComponent(grepFor);
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
      .then(res => res.json())
      .then((json:GrepLogResponsePayload) => {
        if(json.errors && json.errors.length > 0) {
          dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_GREP_LOG));
          showToast(<>Couldn't fetch report: {errorsToUL(json.errors)}</>, "error");
        }
        dispatch(receiveGrepLog(json))
      }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_GREP_LOG));
    showToast(`Couldn't fetch report: ${denastifyError(e.message)}`, "error");
  });
}

export function fetchArchitectureReport()  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestArchitectureReport());
    return fetchArchitectureReportPromise(dispatch)
  }
}

function fetchArchitectureReportPromise(dispatch: any) {
  //Get sender templates from api endpoint
  const url = getGatewayUrl("healthcheck") + "/queueattributes";
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
      .then(res => res.json())
      .then((json:QueueReportResponsePayload) => {
        console.log(json);
        if(json.errors && json.errors.length > 0) {
          dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_ARCHITECTURE_ATTRIBUTES));
          showToast(<>Couldn't fetch report: {errorsToUL(json.errors)}</>, "error");
        }
        dispatch(receiveArchitectureReport(json))
      }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_ARCHITECTURE_ATTRIBUTES));
    showToast(`Couldn't fetch report: ${denastifyError(e.message)}`, "error");
  });
}



