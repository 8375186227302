import React, {FormEvent, MouseEvent} from 'react';
import {connect} from "react-redux";
import {fetchSenderPersonas, submitSenderPersonaAction} from './actions/SenderPersonas'
import {Button, Card, Grid, TextField, Typography} from "@material-ui/core";
import {Email, EmojiPeople} from "@material-ui/icons";
import {validateEmail, validateExists} from "./InputValidation";
import SaveIcon from "@material-ui/icons/Save";
import {v4 as uuidv4} from "uuid";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {RouteComponentProps, withRouter} from "react-router-dom";
import { fetchDKIMSettings } from './actions/Settings';
import { GetDKIMInfoResponsePayload, SenderPersona } from './generated/types/payloadTypes';
import { showToast } from './ToastNotifications';

interface IState {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    json: any,
    placeholder: any,
    fetchedPersona: boolean,
    allowValidation: boolean,
}

interface IProps extends RouteComponentProps<{ id?: string  }> {
    dispatch: any,
    match: any,
    width: string,
    fetchedPersona?: any,
    receivingDKIMSettings?: boolean,
    dkimSettings?: GetDKIMInfoResponsePayload,
}

class NewSenderPersonaComponent extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id: uuidv4(),
            firstName: "",
            lastName: "",
            email: "",
            json: null,
            placeholder: {},
            fetchedPersona: false,
            allowValidation: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.firstNameChange = this.firstNameChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
    }

    componentDidMount(){
        if(this.props.match.params.id) {
            this.props.dispatch(fetchSenderPersonas([this.props.match.params.id]))
        }
        this.props.dispatch(fetchDKIMSettings());
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(!prevState.fetchedPersona && nextProps.fetchedPersona) {
            console.log(nextProps.fetchedPersona);
            return {
                id: nextProps.fetchedPersona.id,
                email: nextProps.fetchedPersona.email,
                firstName: nextProps.fetchedPersona.first_name,
                lastName: nextProps.fetchedPersona.last_name,
                json: nextProps.fetchedPersona.json,
                placeholder: prevState.placeholder,
                fetchedPersona: true,
                allowValidation: prevState.allowValidation,
            }
        }

        return null;
    }

    firstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                firstName: value
            }
        })
    }
    lastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                lastName: value
            }
        })
    }
    emailChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                email: value
            }
        })
    }

    validateForm(state: IState):boolean {
        if(validateExists(state.firstName) !== "") {
            return false;
        }
        if(validateExists(state.lastName) !== "") {
            return false;
        }
        if(validateEmail(state.email) !== "") {
            return false;
        }

        return true;
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            // Make sure they don't have mydomain.com registered with an email of MyDomain.com in the sender persona
            // It will cause DKIM issues if they do that
            const emailParts = this.state.email.split("@")
            if(emailParts.length < 2) {
                return
            }
            const emailDomain = emailParts[1]
            const domains: {[key:string]:any} = this.props.dkimSettings.domains
            const mismatchedDomain = Object.keys(domains).find(d => d !== emailDomain && d.toLowerCase() === emailDomain.toLowerCase())
            let email = this.state.email
            if(mismatchedDomain) {
                email = emailParts[0] + "@" + mismatchedDomain
                this.setState({
                    ...this.state,
                    email
                })
                showToast(`You provided a domain of ${emailDomain} but have registered ${mismatchedDomain}. ` +
                    ` We've matched up the upper and lowercase characters in your sender persona for you, to help ` + 
                    ` avoid any issues with domain-ownership verification.`, "default", {
                        autoClose: 30000
                    })
            }
    
            this.props.dispatch(submitSenderPersonaAction(
                this.state.id,
                this.state.firstName,
                this.state.lastName,
                email,
                JSON.stringify(this.state.json),
                (sp:SenderPersona):any => {
                    const desiredPath = "/people/sender-personas/edit/" + this.state.id
                    if(this.props.location.pathname !== desiredPath) {
                        this.props.history.replace(desiredPath)
                        showToast("sender persona created", "success")
                    } else {
                        showToast("sender persona updated", "success")
                    }
                }))
        }
    }
    
    render() {
        return (
            <>
                <Card elevation={defaultCardElevation}  style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"div"} >
                        {!this.props.match.params.id ? "New " : "Edit " }
                        Sender Persona
                    </Typography>
                    <hr />

                    <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item >
                            <EmojiPeople />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TextField label={"First name"}
                                       autoFocus={true}
                                       required={true}
                                       value={this.state.firstName}
                                       onChange={this.firstNameChange}
                                       error={this.state.allowValidation && validateExists(this.state.firstName)!==""}
                                       helperText={this.state.allowValidation && validateExists(this.state.firstName)!==""? validateExists(this.state.firstName): ""}
                                       style={{width:"calc(50% - 5px)",marginRight:"10px",minWidth:"200px",maxWidth:"300px"}}
                            />
                            <TextField label={"Last name"}
                                       value={this.state.lastName}
                                       onChange={this.lastNameChange}
                                       style={{width:"calc(50% - 5px)",minWidth:"200px",maxWidth:"300px"}}
                                       error={this.state.allowValidation && validateExists(this.state.lastName)!==""}
                                       helperText={this.state.allowValidation && validateExists(this.state.lastName)!==""? validateExists(this.state.lastName): ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item >
                            <Email />
                        </Grid>
                        <Grid  item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TextField label={"Email"}
                               required={true}
                               type={"email"}
                                style={{width:"100%",minWidth:"200px",maxWidth:"610px"}}
                                value={this.state.email}
                                onChange={this.emailChange}
                                error={this.state.allowValidation && validateEmail(this.state.email)!==""}
                                helperText={this.state.allowValidation && validateEmail(this.state.email)!==""? validateEmail(this.state.email): ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" className={"button-footer"} >
                        <Grid item >
                            <Email style={{visibility:"hidden"}} />
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    startIcon={<SaveIcon />}
                                    onClick={this.handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    </form>
                </Card>
                <Card elevation={defaultCardElevation}  style={{...defaultCardStyles, paddingTop:"1rem"}}>
                    <Typography variant={"body1"} component={"p"}>
                        <b>Note:</b> After clicking save, we will send a verification email to the sender persona's
                        email address.  Please make sure you have access to this sender persona's email inbox before saving!
                    </Typography>
                </Card>
            </>
        )
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    //Find the persona with the id passed in via the route
    let fetchedPersona = null;
    if(state.senderPersonas && Object.keys(state.senderPersonas).length > 0) {
        if(state.senderPersonas[ownProps.match.params.id]) {
            fetchedPersona = state.senderPersonas[ownProps.match.params.id];
        }
    }

    return {...state, fetchedPersona}
}


export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewSenderPersonaComponent))
