import {Typography} from "@material-ui/core";
import { useLocation } from 'react-router-dom'

export interface BlogPost {
    title: string,
    blurb?: JSX.Element,
    body: JSX.Element,
    route: string,
    createdAt: Date,
    updatedAt?: Date,
    author?: string,
}

const centerStyle = {
    display: 'flex',  
    justifyContent:'center', 
    alignItems:'center'
}
const daveCline = "Dave Cline"

export const pBottom = "1rem"
export const pImageStyle = {
    marginBottom:pBottom,
    display: 'flex',  
    justifyContent:'center', 
    alignItems:'center'
}
export const blogPostCardStyle = {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem"
}

export const blogPosts:BlogPost[] = [
    // {
    //     route: "/blog/serverless",
    //     title: "Saving with serverless",
    //     blurb: <>How we save you money by using the right tech.<br /><br /></>,
    //     body: <div style={centerStyle}><img src="/images/Blog-2023-03-06-4.svg" style={{width:"4000px", height:"150px"}} /></div>,
    //     createdAt: new Date("2023-06-14 12:00:00"), // use noon so that the date conversion doesn't go back to 00:00:00 utc
    //     author: daveCline,
    // },
    {
        route: "/blog/other-esps-guess",
        title: "Hello {user_name}",
        blurb: <>How most email service providers are not telling you what they actually sent.</>,
        body: <div style={centerStyle}><img src="/images/Blog-2023-06-30-00.png" style={{width:"400px", height:"150px"}} /></div>,
        createdAt: new Date("2023-06-30 12:00:00"), // use noon so that the date conversion doesn't go back to 00:00:00 utc
        author: daveCline,
    },
    {
        route: "/blog/timezone-based-email-delivery",
        title: "Targeting timezones",
        blurb: <>How using your contacts' timezones improves your open rates.</>,
        body: <div style={centerStyle}><img src="/images/Blog-2023-08-08-00.png" style={{width:"400px", height:"150px"}} /></div>,
        createdAt: new Date("2023-08-08 12:00:00"), // use noon so that the date conversion doesn't go back to 00:00:00 utc
        author: daveCline,
    },
    {
        route: "/blog/send-event-based-transactional-or-drip-campaign-emails",
        title: "Event triggers",
        blurb: <>How to use your company's events to trigger drip campaigns and transactional emails.</>,
        body: <div style={centerStyle}><img src="/images/Blog-2023-12-03-1.svg" style={{width:"400px", height:"150px"}} /></div>,
        createdAt: new Date("2023-12-03 12:00:00"), // use noon so that the date conversion doesn't go back to 00:00:00 utc
        author: daveCline,
    }
] 


export function postMetaDetails() {
    const location = useLocation();
    const thisPost = blogPosts.find(post => post.route === location.pathname)
    return <Typography variant={"body2"} style={{textAlign:"right",color:"#777",width:"100%"}}>
            {thisPost.author && <>Author: {thisPost.author}</>}<br />
            Posted: {thisPost.createdAt.toLocaleDateString()}
        </Typography>
}
