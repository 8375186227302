import React, {FormEvent, MouseEvent} from 'react';
import {connect} from "react-redux";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import {validateEmail, validateExists} from "./InputValidation";
import {Business, Email, HowToReg, Notes, Person} from "@material-ui/icons";
import { CONTACT_CAPTCHA } from "./actions/Common";
import Captcha from "./components/Captcha";
import {GetCaptchaResponsePayload} from "./generated/types/payloadTypes";
import {withRouter,RouteComponentProps} from "react-router-dom";
import {contactUs} from './actions/Contact'

interface IState {
    email: string,
    name: string,
    details: string,
    captchaValue: string,
    allowValidation?: boolean,
}

interface IProps extends RouteComponentProps<{ ignorethis?: string  }>{
    dispatch: any,
    processingContactUsForm?: boolean,
    currentCaptcha?: GetCaptchaResponsePayload,
    width: string,
    reportErrors?: {[key:string]:string},
    header?: JSX.Element,
    autoFocus?: boolean,
    buttonText?: string,
    textAreaLabel?: string,
}

class ContactUsForm extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            name:"",
            email:"",
            details:"",
            allowValidation:false,
            captchaValue: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.simpleStateChange = this.simpleStateChange.bind(this);
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            this.props.dispatch(contactUs({
                name: this.state.name,
                captcha_id: this.props.currentCaptcha.captcha_key,
                captcha_answer: this.state.captchaValue,
                details: this.state.details,
                email: this.state.email,
            }))
        }
    }

    simpleStateChange(stateKey: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const value = event.target.value;
            this.setState(state => {
                return {
                    ...state,
                    [stateKey]: value
                }
            })
        }
    }

    validateForm(state: IState):boolean {
        if(validateEmail(state.email) !== "") {
            return false;
        }
        if(validateExists(state.name) !== "") {
            return false;
        }
        if(validateExists(state.details) !== "" || state.details.length < 10) {
            return false;
        }
        if(validateExists(state.captchaValue) !== "") {
            return false;
        }

        return true;
    }

    captchaErrors() : string {
        if(validateExists(this.state.captchaValue) !== "") {
            return validateExists(this.state.captchaValue);
        }

        if(this.props.reportErrors[CONTACT_CAPTCHA]) {
            return this.props.reportErrors[CONTACT_CAPTCHA];
        }

        return "";
    }

    render() {
        return <form>
            {this.props.header || <Typography variant={"h1"} component={"p"} style={{marginBottom:"1rem"}}>Contact Us</Typography>}
            
            <Grid container style={{marginTop:"1rem"}}>
            <Grid item xs={1} md={1} lg={1} xl={1} style={{maxWidth:"35px", width:"100%"}}>
                    <Person style={{marginTop:"5px"}} />
                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                    <TextField placeholder={"Your name"}
                                id="contactUsNameId"
                                required={true}
                                autoFocus={!!this.props.autoFocus}
                                style={{width:"100%", minWidth:"300px", marginTop:"1px", backgroundColor:"#eef", borderLeft:"4px solid #eef"}}
                                value={this.state.name}
                                onChange={this.simpleStateChange("name")}
                                error={this.state.allowValidation && validateExists(this.state.name)!==""}
                                helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                    />
                </Grid>
            </Grid>
            <Grid container style={{marginTop:"0.5rem"}}>
                <Grid item xs={1} md={1} lg={1} xl={1} style={{maxWidth:"35px", width:"100%"}}>
                    <Email style={{marginTop:"5px"}} />
                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                    <TextField placeholder={"Your email"}
                                required={true}
                                style={{width:"100%", minWidth:"300px", backgroundColor:"#eef", borderLeft:"4px solid #eef"}}
                                value={this.state.email}
                                type={"email"}
                                onChange={this.simpleStateChange("email")}
                                error={this.state.allowValidation && validateEmail(this.state.email)!==""}
                                helperText={this.state.allowValidation && validateEmail(this.state.email)!==""? validateEmail(this.state.email): ""}
                    />
                </Grid>
            </Grid>
            <Grid container style={{marginTop:"0.5rem"}} alignItems={"stretch"} >
                <Grid item xs={1} md={1} lg={1} xl={1} style={{maxWidth:"35px", width:"100%"}}>
                    <HowToReg style={{marginTop:"5px"}}  />
                </Grid>
                <Grid item xs={11} md={11} lg={11} xl={11} sm={11}>
                    <Grid container wrap={"nowrap"} alignItems={"stretch"}  style={{marginTop:"0px"}} >
                        <Grid item>
                            <Captcha dispatch={this.props.dispatch} style={{width:80*2 + "px",height:15*2 + "px", borderStyle:"dotted", borderWidth:"2px", borderColor:"#aaa"}}/>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} component={"p"} style={{marginLeft:"4px",marginTop:"2px"}}>
                                Please enter this code in the field below
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1} md={1} lg={1} xl={1} style={{maxWidth:"35px", width:"100%"}}>
                    <Business style={{"visibility":"hidden"}} />
                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                    <TextField placeholder={"Captcha code"}
                                required={true}
                                style={{width:"100%", minWidth:"300px", backgroundColor:"#eef", borderLeft:"4px solid #eef"}}
                                value={this.state.captchaValue}
                                onChange={this.simpleStateChange("captchaValue")}
                                error={this.state.allowValidation && this.captchaErrors()!==""}
                                helperText={this.state.allowValidation && this.captchaErrors()!==""?this.captchaErrors(): ""}
                    />
                </Grid>
            </Grid>
            <Grid container style={{marginTop:"0.5rem"}}>
                <Grid item xs={1} md={1} lg={1} xl={1} style={{maxWidth:"35px", width:"100%"}}>
                    <Notes style={{marginTop:"0px"}} />
                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                    <TextField placeholder={this.props.textAreaLabel || "Inquiry details"}
                                multiline={true}
                                minRows={5}
                                required={true}
                                style={{width:"100%", minWidth:"300px", backgroundColor:"#eef", borderLeft:"4px solid #eef"}}
                                value={this.state.details}
                                onChange={this.simpleStateChange("details")}
                                error={this.state.allowValidation && validateExists(this.state.details)!==""}
                                helperText={this.state.allowValidation && validateExists(this.state.details)!==""? validateExists(this.state.details): ""}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems={"flex-end"} style={{marginTop:"10px",marginBottom:"1rem"}}>
                <Grid item xs={1} md={1} lg={1} xl={1} style={{maxWidth:"35px", width:"100%"}}>
                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                    <Button
                        disabled={this.props.processingContactUsForm}
                        variant={"contained"}
                        color={"primary"}
                        onClick={this.handleSubmit}>
                        {this.props.buttonText || "Contact Us"}
                    </Button>
                </Grid>
            </Grid>
            <a id="contact_us" />
            </form>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ContactUsForm))

