import {IChart} from "@mrblenny/react-flow-chart/src";
import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  GetWorkflowsResponsePayload,
  UpsertWorkflowPayload,
  UpsertWorkflowResponsePayload,
  Node, DeleteWorkflowsResponsePayload, SendSegmentToWorkflowPayload, SendSegmentToWorkflowResponsePayload, UpsertWorkflowForValidationPayloadData, UpsertWorkflowForValidationResponsePayload,
} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";
import { NotificationWaiter, NotificationChecker, NotificationActor } from '../waiters/notifications'

export const REQUEST_WORKFLOWS = 'REQUEST_WORKFLOWS';
export const ARCHIVE_WORKFLOWS = 'ARCHIVE_WORKFLOWS';
export const ARCHIVE_WORKFLOWS_RESPONSE = 'ARCHIVE_WORKFLOWS_RESPONSE';
export const RECEIVE_WORKFLOWS = 'RECEIVE_WORKFLOWS';
export const SELECT_WORKFLOW = 'SELECT_WORKFLOW';
export const REQUEST_NEW_WORKFLOW = 'REQUEST_NEW_WORKFLOW';
export const RESPONSE_NEW_WORKFLOW = 'RESPONSE_NEW_WORKFLOW';
export const SELECT_NODE = 'SELECT_NODE';
export const RUN_SEGMENT_THROUGH_WORKFLOW = 'RUN_SEGMENT_THROUGH_WORKFLOW';
export const RUN_SEGMENT_THROUGH_WORKFLOW_RESPONSE = 'RUN_SEGMENT_THROUGH_WORKFLOW_RESPONSE';
const WorkflowNotificationWaiter = new NotificationWaiter()

interface IRequestWorkflowsAction {
  type: typeof REQUEST_WORKFLOWS,
}

interface IResponseNewWorkflowAction {
  type: typeof RESPONSE_NEW_WORKFLOW,
  success:string,
  error:string,
  data: any
}
interface IReceiveWorkflowsAction {
  type: typeof RECEIVE_WORKFLOWS,
  error:string,
  workflows: any
}
interface ISelectWorkflowAction {
  type: typeof SELECT_WORKFLOW,
  selectedWorkflows: any
}
interface IRequestNewWorkflowAction {
  type: typeof REQUEST_NEW_WORKFLOW,
}
interface ISelectNodeAction {
  type: typeof SELECT_NODE,
  id: string, //Node id
}
interface IArchiveWorkflowsAction {
  type: typeof ARCHIVE_WORKFLOWS,
  ids: string[],
}
interface IArchiveWorkflowsResponseAction {
  type: typeof ARCHIVE_WORKFLOWS_RESPONSE,
  job_log_id: string,
  workflow_id: string,
  errors: string[],
}
interface IRunSegmentThroughWorkflowAction {
  type: typeof RUN_SEGMENT_THROUGH_WORKFLOW,
  workflow_id: string,
  segment_id: string,
  root_node_id: string,
}
interface IRunSegmentThroughWorkflowResponseAction {
  type: typeof RUN_SEGMENT_THROUGH_WORKFLOW_RESPONSE,
  errors: string[],
}

export type WorkflowActionTypes = IRequestWorkflowsAction | IReceiveWorkflowsAction | ISelectWorkflowAction
  | IRequestNewWorkflowAction | IResponseNewWorkflowAction | ISelectNodeAction | IArchiveWorkflowsAction 
  | IArchiveWorkflowsResponseAction | IRunSegmentThroughWorkflowAction | IRunSegmentThroughWorkflowResponseAction;

export function runSegmentThroughWorflowAction(segmentId: string, workflowId:string, rootNodeId: string):IRunSegmentThroughWorkflowAction {
  return {
    type: 'RUN_SEGMENT_THROUGH_WORKFLOW',
    segment_id: segmentId,
    workflow_id: workflowId,
    root_node_id: rootNodeId,
  }
}
export function runSegmentThroughWorflowResponseAction(errors: string[]):IRunSegmentThroughWorkflowResponseAction {
  return {
    type: 'RUN_SEGMENT_THROUGH_WORKFLOW_RESPONSE',
    errors,
  }
}
export function requestNewWorkflowAction():IRequestNewWorkflowAction {
  return {
    type: 'REQUEST_NEW_WORKFLOW',
  }
}
export function archiveWorkflowsAction(ids:string[]):IArchiveWorkflowsAction {
  return {
    type: 'ARCHIVE_WORKFLOWS',
    ids,
  }
}
export function archiveWorkflowsResponseAction(job_log_id:string, workflow_id: string, errors: string[]):IArchiveWorkflowsResponseAction {
  return {
    type: 'ARCHIVE_WORKFLOWS_RESPONSE',
    job_log_id,
    workflow_id,
    errors,
  }
}
export function responseNewWorkflowAction(successMessage:string, errorMessage:string, data:any):IResponseNewWorkflowAction  {
  return {
    success: successMessage,
    error: errorMessage,
    type: 'RESPONSE_NEW_WORKFLOW',
    data
  }
}

export function requestWorkflowsAction():IRequestWorkflowsAction  {
  return {
    type: 'REQUEST_WORKFLOWS'
  }
}

export function receiveWorkflowsAction(workflows: any, error: string):IReceiveWorkflowsAction  {
  return {
    workflows,
    error,
    type: 'RECEIVE_WORKFLOWS'
  }
}

export function submitRunSegmentThroughWorkflowAction(segmentId: string, workflowId: string, rootNodeId: string,
    onSuccess?:(segmentId:string, workflowId:string)=>void,onFailure?:(reason:string) => void)  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(runSegmentThroughWorflowAction(segmentId, workflowId, rootNodeId));
    return postRunSegmentThroughWorkflowPromise(dispatch, segmentId, workflowId, rootNodeId, onSuccess, onFailure)
  }
}


function postRunSegmentThroughWorkflowPromise(dispatch:(...args:any)=>any, 
  segmentId: string, workflowId: string, rootNodeId:string,
  onSuccess?:(segmentId:string, workflowId:string)=>void,onFailure?:(reason:string) => void) {

  const url = getGatewayUrl("segmentwrapper") + "/segments/send_to_workflow";
  const sendPayload:SendSegmentToWorkflowPayload = {
    segment_id: segmentId,
    workflow_id:workflowId,
    root_node_id:rootNodeId,
  };
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(sendPayload),
    timeout: 2000,
  };

  console.log("Posting send-segment-to-workflow request to url "+ url);
  fetch(url, options)
  .then(res => res.json())
  .then((json:SendSegmentToWorkflowResponsePayload) => {
    if (json.errors && json.errors.length > 0) {
      if(onFailure) {
        onFailure("couldn't send segment to workflow, " + denastifyError(JSON.stringify(json.errors)))
      } else {
        showToast(<>Couldn't send segment to workflow: {errorsToUL(json.errors)}</>, "error");
      }
    } else {
      if(onSuccess) {
        onSuccess(segmentId, workflowId)
      } else {
        showToast(`Successfully sent segment to workflow`, "success");
        dispatch(runSegmentThroughWorflowResponseAction(null));
      }
    }
  }).catch(e => {
    if(onFailure) {
      onFailure(`Couldn't send segment to workflow: ${denastifyError(e.message)}`)
    } else {
      showToast(<>Couldn't send segment to workflow: {denastifyError(e.message)}</>, "error");
    }
  })
}

export  function submitWorkflowAction(id:string, name:string, workflowDisplay: IChart, json:Node, showNotification = true, onValidationSuccess?:()=>void)  {
  return async function(dispatch:(...args:any)=>any) {
    return await postWorkflowPromise(dispatch, id, name, workflowDisplay, json, showNotification, onValidationSuccess)
  }
}

async function isValidWorkflow(id:string, name:string, workflowDisplay:IChart, json:Node):Promise<boolean> {
  const url = getGatewayUrl("workflow") + "/workflows/validate"
  const workflow:UpsertWorkflowForValidationPayloadData = {
    id: id,
    name: name,
    root_node: json,
    job_log_id:"",
    display: workflowDisplay,
  };
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(workflow),
    timeout: 2000,
  }

  console.log("Posting workflow (for validation) to url "+ url);
  let valid = false
  await fetch(url, options)
    .then(res => res.json())
    .then((json:UpsertWorkflowForValidationResponsePayload) => {
      if (json.errors && json.errors.length > 0) {
        valid = false
        showToast(<>Couldn't save workflow  '{name}': {errorsToUL(json.errors)}</>, "error");
        return 
      } 
      valid = true
    }).catch(e => {
      showToast(<>Workflow '{name}' failed workflow validation</>, "error");
      valid = false 
    })

  return valid
}

async function postWorkflowPromise(dispatch:(...args:any)=>any, id:string, name:string, workflowDisplay:IChart, json:Node, showNotification:boolean, onValidationSuccess?:()=>void) {
  // Hit the validation endpoint first to make sure we could potentially save this workflow
  if(!(await isValidWorkflow(id, name, workflowDisplay, json))) {
    return
  }

  // 
  dispatch(requestNewWorkflowAction())
  if(showNotification) {
    showToast(`Saving workflow '${name}'.  This may take several minutes.  To see the most recent status update, check the notifications tab`, "default",
    {
      autoClose:10000,
    })
  }

  const url = getGatewayUrl("workflowwrapper") + "/workflows"
  const workflow:UpsertWorkflowPayload = {
    id: id,
    name: name,
    root_node: json,
    job_log_id:"",
    display: workflowDisplay,
  };
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(workflow),
    timeout: 2000,
  }

  console.log("Posting workflow to url "+ url);
  fetch(url, options)
  .then(res => res.json())
  .then((json:UpsertWorkflowResponsePayload) => {
    if (json.errors && json.errors.length > 0) {
      showToast(<>Couldn't save workflow  '{name}': {errorsToUL(json.errors)}</>, "error");
    } else {
      let message = `Successfully saved workflow '${name}'`
      if(json.data.behind_another_job) {
        message = `Workflow '${name}' has been saved successfully, but it's waiting for another ` +
          `workflow update to finish before it's ready to go.  We'll let you know when it's finished.  ` +
          `Feel free to check the notifications tab for more details`
          activateWorkflowUpdateNotificationChecker(json.data.job_log_entry_id, name, false)
      } 
      showToast(message, "success");
      dispatch(responseNewWorkflowAction("Workflow saved successfully", null, json));
    }
  }).catch(e => {
    console.log("503s at this point just mean the front end got tired of waiting for the backend.  Workflow creation is probably still happening. This is more common than not.")
    activateWorkflowUpdateNotificationChecker(id, name, true)
  })

  
  // Activate validation success now after we've had a second to save the workflow
  if(onValidationSuccess) {
    setTimeout(onValidationSuccess, 1000)
  }
}

export function activateWorkflowUpdateNotificationChecker(id:string, name:string, isWorkflowId: boolean, onSuccess?:()=>void, onFailure?:()=>void) {
  // Wait for notifications about the workflow to  show up
  const checker:NotificationChecker = {
    check: (n:any) => {
      console.log("looking at job " + n.id)
      if(n.job_name === "JOB_CREATE_WORKFLOW" && n.ended_at &&n.start_details 
      && (!isWorkflowId || (n.start_details.workflow_id && n.start_details.workflow_id === id))
      && ( isWorkflowId || (n.id && n.id === id))) {
        console.log("FOUND WORKFLOW")
        return true 
      }
      return false
    },
    maxChecks: 200,
  }
  const actor:NotificationActor = {
    act: (n:any) => {
      if(n.success) {
        if(onSuccess) {
          onSuccess()
        } else {
          showToast(`Successfully saved workflow '${name}'`, "success");
        }
      } else {
        if(onFailure) {
          onFailure()
        } else {
          showToast(`There was a problem saving workflow '${name}'.  Please check the notifications tab for more details`, "error")
        }
      }
      return true
    },
  }
  WorkflowNotificationWaiter.addNotificationHandler("workflowcompletionwaiter" + id, checker, actor)
}

export function fetchWorkflows(ids: string[] = [])  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestWorkflowsAction());
    return fetchWorkflowsPromise(dispatch, ids)
  }
}

export function archiveWorkflows(ids: string[], names?: string[], onSuccess?: (ids:string[]) => void, onFailure?: (reason:string) => void) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(archiveWorkflowsAction(ids));
    showToast(`Archiving workflow ${names && names.length?names[0]:''}.  This may take a few minutes.  To see the most recent status, check the notifications tab`, "default",
      {
        autoClose:10000,
      });
    return archiveWorkflowsPromise(dispatch, ids, names, onSuccess, onFailure);
  }
}


function fetchWorkflowsPromise(dispatch:(...args:any)=>any, ids: string[] = []) {
  dispatch(requestWorkflowsAction());

  //Get sender users from api endpoint
  let url = getGatewayUrl("workflowwrapper") + "/workflows";
  if(ids && ids.length > 0) {
    url += "?ids=" + ids.join(",");
  }
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetWorkflowsResponsePayload) => {
    if (json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_WORKFLOWS));
      showToast(<>Couldn't fetch workflows: {errorsToUL(json.errors)}</>, "error");
    }
    if(json.data && json.data.length) {
      console.log("Found " + json.data.length + " workflows");
      try {
        dispatch(receiveWorkflowsAction(json.data, null));
      } catch(e) {
        const e2 = e as any
        dispatch(requestDataErrorAction(denastifyError(e2?.message),REQUEST_WORKFLOWS));
        showToast(<>An error occurred while loading the workflow: {denastifyError(e2?.message)}</>, "error");
      }
    } else {
      dispatch(receiveWorkflowsAction(null, null));
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_WORKFLOWS));
    showToast(`Couldn't fetch workflows: ${denastifyError(e.message)}`, "error");
  });
}


function archiveWorkflowsPromise(dispatch:(...args:any)=>any,ids:string[],names?:string[],onSuccess?: (ids:string[]) => void, onFailure?: (reason:string) => void) {
  ((ids:string[], names?:string[]) => {
    for(let i = 0; i < ids.length; i++) {
      const id = ids[i]
      const name = names && names.length > i ? names[i] : ''

      //Get sender users from api endpoint
      const url = getGatewayUrl("workflowwrapper") + "/workflows/" + encodeURIComponent(id);
      console.log("POSTING TO URL",url);
      const options:any =  {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'x-api-key': getAuthKey(),
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrer: 'no-referrer',
      };
      fetch(url, options)
      .then(res => res.json())
      .then((json:DeleteWorkflowsResponsePayload) => {
        if(json.errors && json.errors.length > 0) {
          dispatch(archiveWorkflowsResponseAction(json.job_log_id, json.workflow_id, json.errors));
          if(onFailure) {
            onFailure(json.errors.join(", "))
          } else {
            showToast(<>Couldn't archive workflow {name}: {errorsToUL(json.errors)}</>, "error");
          }
        } else {
          dispatch(archiveWorkflowsResponseAction(json.job_log_id, json.workflow_id, null));
          if(onSuccess) {
            onSuccess([id])
          } else {
            showToast("Successfully archived workflow " + name, "success");
          }
        }
      }).catch(e => {
        if(onFailure) {
          onFailure(e.message)
          return
        }
        // dispatch(archiveWorkflowsResponseAction(null, null, [e.message]));
        // showToast(`Couldn't archive workflow(s): ${denastifyError(e.message)}`, "error");
        console.log("503s at this point just mean the front end got tired of waiting for the backend.  Workflow archival is probably still happening. This is more common than not.")

        // Wait for notifications about the workflow to  show up
        const checker:NotificationChecker = {
          check: (n:any) => {
            if(n.job_name === "JOB_DELETE_WORKFLOW" && n.ended_at && n.start_details 
            && n.start_details.workflow_id && n.start_details.workflow_id === id) {
              console.log("waiter matched " + JSON.stringify(n))
              return true 
            }
            return false
          },
          maxChecks: 200,
        }
        const actor:NotificationActor = {
          act: (n:any) => {
            if(n.success) {
              showToast("Successfully archived workflow " + name, "success");
            } else {
              showToast(`There was a problem archiving workflow ${name}.  Please check the notifications tab for more details`, "warning")
            }
            return true
          },
        }
        WorkflowNotificationWaiter.addNotificationHandler("workflowarchivewaiter" + id, checker, actor)
      });
    }
  })(ids, names);
}