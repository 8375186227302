import React from 'react';
import { connect } from "react-redux";
import {Typography, Button} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import { Loading } from './Loading';

interface IProps {
    limit: number,
    offset: number,
    totalItems: number,
    isLoading: boolean,
    onPrevPage?: (event:any) => void,
    onNextPage?: (event:any) => void,
    prevPageText?: JSX.Element,
    nextPageText?: JSX.Element,
    buttonVariant?: "text" | "outlined" | "contained",
}

class PagingToolbarComponentFromProps extends React.Component<IProps, null> {
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    prevPage(event:any) {
        if(this.props.onPrevPage) {
            this.props.onPrevPage(event)
        }
    }

    nextPage(event:any) {
        if(this.props.onNextPage) {
            this.props.onNextPage(event)
        }
    }

    pageText():string {
        if(this.props.limit <= 0) {
            return "1 of X"
        }

        let pageNum = this.props.offset / this.props.limit;
        if(Number.isNaN(pageNum)) {
            return "1 of X"
        }
        let totalPages = this.props.totalItems / this.props.limit;
        if(Number.isNaN(totalPages)) {
            return "1 of X"
        }

        pageNum = Math.floor(pageNum) + 1
        totalPages = Math.ceil(totalPages)
        if(totalPages === 0) {
            totalPages = 1
        }

        return pageNum.toString() + " of " + totalPages.toString()
    }


    render() {
        return <Grid container alignItems={"center"} alignContent={"center"} >
            <Grid item>
                <Button variant={this.props.buttonVariant || "contained"} color={"primary"} onClick={this.prevPage}>
                {this.props.prevPageText || "Prev Page"}
                </Button>
            </Grid>
            <Grid item>
                {!this.props.isLoading && <Typography component={"p"} variant={"body1"} style={{display:"inline-block",marginLeft:"10px",marginRight:"10px",minWidth:"50px"}}>
                    {this.pageText()}
                </Typography>}
                {this.props.isLoading && <Loading mySize="sm" />}
            </Grid>
            <Grid item>
                <Button variant={this.props.buttonVariant || "contained"} color={"primary"} onClick={this.nextPage}>
                    {this.props.nextPageText || "Next Page"}
                </Button>
            </Grid>
        </Grid>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PagingToolbarComponentFromProps)

