import { Card, Typography, Button, TextField } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { defaultCardElevation, defaultCardStyles } from '../App';
import { SenderPersona, Template } from '../generated/types/payloadTypes';
import { AllState } from '../reducers';
import SelectSenderPersona from './components/SelectSenderPersona';
import SelectTemplate from './components/SelectTemplate';
import { Save } from '@material-ui/icons';
import {v4 as uuidv4} from "uuid";
import { fetchSenderPersonas, submitSenderPersonaAction } from '../actions/SenderPersonas';
import { showToast } from '../ToastNotifications';


interface IProps {
    eventName: string,
    pageNum: number,
    width: string,
    dispatch: any,
    senderPersonas?: {[key:string]:SenderPersona},
    templates?: {[key:string]:Template},
    senderPersona: SenderPersona,
    templateId: string,
    isNewSenderPersona: boolean,
    isNewTemplate: boolean,
    onPageChange:(pageNum:number) => void,
    onSenderPersonaChange: (senderPersona: SenderPersona,  isNewSenderPersona: boolean) => void,
    onTemplateChange: (t:Template, isNewTemplate: boolean) => void,
    onEventNameChange: (n:string) => void,

}
interface IState {
   
}
const newSenderPersonaPage = 4

class WorkflowEventTriggerCampaign extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
        };
    }

    foundCreatedSenderPersona():boolean {
        if(!this.props.senderPersonas || Object.keys(this.props.senderPersonas).length == 0) {
            return false
        }

        return !!Object.keys(this.props.senderPersonas).find((id:string) => id === this.props.senderPersona.id)
    }

    onCreateNewSenderPersonaForm():boolean {
        if(this.props.pageNum === newSenderPersonaPage && this.props.isNewSenderPersona) {
            return true
        }

        return false
    }

    
    isSelectTemplateForm(): boolean {
        return this.props.pageNum === 5
    }
    
    isEventNamePage(): boolean {
        return this.props.pageNum === 3
    }
    isSenderPersonasPage(): boolean {
        return this.props.pageNum === 4
    }
  
    isNextButtonEnabled() : boolean {
        if(this.props.pageNum === newSenderPersonaPage && this.props.senderPersona && this.props.senderPersona.id) {
            return true
        }

        if(this.isSenderPersonasPage()) {
            if(this.props.senderPersona && this.props.senderPersona.id) {
                return true
            }
        }

        if(this.isEventNamePage()) {
            if(this.props.eventName) {
                return true
            }
        }

        if(this.isSelectTemplateForm()) {
            if(this.props.templateId) {
                return true
            }
        }

        return false
    }

    getButtons() : JSX.Element { 
            const isNextDisabled = !this.isNextButtonEnabled()
            const prevButton = <Button color="primary" variant="contained" disabled={this.props.pageNum === 1} onClick={(e) => { e.stopPropagation(); e.preventDefault(); if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum-1) } }} style={{marginRight:"1rem"}}>&lt;&nbsp;&nbsp; Campaign Setup</Button>
            const nextButton = <Button disabled={isNextDisabled} color="primary" variant="contained" onClick={(e) => { e.stopPropagation(); e.preventDefault();  if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum+1) } }} style={{marginRight:"1rem"}}>Campaign Setup &nbsp;&nbsp;&gt;</Button>
            const savePersonaButton = <Button color="primary" variant="contained" style={{marginRight:"1rem"}}
                startIcon={<Save />}
                onClick={(e) => { 
                    e.stopPropagation()
                    e.preventDefault() 
                    
                    const sp = this.props.senderPersona
                    sp.id = uuidv4()
                    this.props.onSenderPersonaChange(sp, true) // add id
                    this.props.dispatch(submitSenderPersonaAction(
                       sp.id,
                       sp.first_name,
                       sp.last_name,
                       sp.email,
                       sp.json ? JSON.stringify(sp.json) : "",
                       (sp:SenderPersona) => {
                            this.props.onSenderPersonaChange(sp, true)
        
                            // Refetch so we know we can enable to next button
                            this.props.dispatch(fetchSenderPersonas([sp?.id]))
                            showToast("Successfully saved sender persona, please log in to " + sp?.email + " and verify the account", "success")
                        })
                    )
                }}>Save Persona</Button>

            const buttons = [prevButton, nextButton]
            if(this.onCreateNewSenderPersonaForm()) {
                buttons.push(savePersonaButton)
            }

            return <>{buttons}</>
    }


    render() {
        return <>
        <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
            <Typography variant="h1" component="h1" style={{marginBottom:"1rem"}}>New Campaign</Typography>
                {this.isEventNamePage() && <>
                    <Typography variant="h2" component="p">
                        What event will you be sending to our api?
                    </Typography>
                    <TextField value={this.props.eventName} autoFocus={true} style={{width:"100%", maxWidth:"500px", minWidth:"200px",marginBottom:"1rem"}} placeholder='Event name'
                        onChange={(e) => { 
                            e.stopPropagation(); 
                            e.preventDefault(); 
                            this.props.onEventNameChange(e.target?.value?.toString())
                        }} />
                </>}
                {this.isSenderPersonasPage() && <>
                    <SelectSenderPersona 
                        onSenderPersonaChange={this.props.onSenderPersonaChange}
                        senderPersona={this.props.senderPersona}
                        isNewSenderPersona={this.props.isNewSenderPersona}
                        width={this.props.width}
                        dispatch={this.props.dispatch} /></>}
                {this.isSelectTemplateForm() && 
                    <SelectTemplate 
                        onTemplateChange={this.props.onTemplateChange} 
                        templateId={this.props.templateId}
                        isNewTemplate={this.props.isNewTemplate}
                        width={this.props.width} 
                        dispatch={this.props.dispatch} />}
            <div style={{marginTop:"1rem"}}>
                {this.getButtons()}
            </div>
        </Card>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(WorkflowEventTriggerCampaign)
