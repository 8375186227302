import React, {FormEvent, ChangeEvent} from 'react';
import {submitTemplateAction,fetchTemplates} from './actions/Templates'
import { connect } from "react-redux";
import EmailEditor, { FileInfo, FileUploadDoneData} from 'react-email-editor';
import {Button, Card, Grid, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Switch} from "@material-ui/core";
import {Description, CompareArrows, Cached, Subject, Search, Visibility, VisibilityOff, Email, EmojiPeople, Computer, Group} from "@material-ui/icons";
import {validateExists} from "./InputValidation";
import SaveIcon from "@material-ui/icons/Save";
import {v4 as uuidv4} from "uuid";
import {EmailContact, SenderPersona, Template} from "./generated/types/payloadTypes";
import {fetchS3TemplateImageUploadUrl} from "./Common";
import {showToast} from "./ToastNotifications";
import {defaultCardElevation,defaultCardStyles} from "./App";
import { getImagesBucketUrl, inSandbox} from "./actions/Auth";
import { denastifyError} from "./actions/Common";
import {isMobile, MobileView} from 'react-device-detect';
import {InformationArea} from "./components/InformationArea";
import PreviewTemplate from './PreviewTemplate';
import TemplateVariables from './TemplateVariables';
import {RouteComponentProps, withRouter} from "react-router-dom";
import { MyModal } from './Dialog';
import ListSenderPersonas from './ListSenderPersonas';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import ListTemplates from './ListTemplates';
import { EmailBlastIcon } from './Icons';
import { sendSingleEmail } from './actions/Events';

interface IState {
    id: string,
    name: string,
    subject: string,
    type: string,
    fetchedTemplate: boolean,
    template: Template,
    allowValidation: boolean,
    emailEditorError?: string
    loadedInitialTemplate: boolean,
    isUnsupportedBrowser: boolean,
    previewMode: boolean,
    successfullySetUpImageHandling: boolean,
    removedLinkTypes: boolean,
    sendingTestEmail: boolean,
    testEmailToEmail: string,
    testEmailFromId: string,
    testEmailToId: string,
    testEmailTemplateId: string,
    testEmailType: "simulator" | "sender persona",
}

interface IProps extends RouteComponentProps<{ id?: string  }> {
    dispatch: any,
    match: any,
    width: string,
    fetchedTemplate?: Template,
    newWithId?: boolean,
    senderPersonas?: {[key: string]: SenderPersona}
}

class NewTemplateComponent extends React.Component<IProps, IState> {

    props:IProps;
    state:IState;
    emailEditorRef: any;
    numDefaultTries = 0;
    maxDefaultTries = 40;
    numImageHandlingTries = 0;
    maxImageHandlingTries = 40;
    delayBetweenTriesMS = 200;
    updatedDefaults = false;
    successfullySetUpImageHandling = false;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        const templateId = this.props.match?.params?.id ? this.props.match.params.id: uuidv4()
        this.state = {
            sendingTestEmail: false,
            id: templateId,
            testEmailTemplateId: templateId,
            loadedInitialTemplate: false,
            name: "",
            subject: "",
            type: "TEMPLATE_MARKETING",
            fetchedTemplate: false,
            template: null,
            allowValidation: false,
            isUnsupportedBrowser: false,
            previewMode: false,
            successfullySetUpImageHandling: false,
            removedLinkTypes: false,
            testEmailToEmail: "success@simulator.amazonses.com",
            testEmailFromId: "",
            testEmailToId: "",
            testEmailType: "simulator",
        };

        this.emailEditorRef = React.createRef<any>();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.subjectChange = this.subjectChange.bind(this);
        this.typeChange = this.typeChange.bind(this);
        this.onEmailEditorLoad = this.onEmailEditorLoad.bind(this);
        this.uploadImageFile = this.uploadImageFile.bind(this);
        this.updateDefaults = this.updateDefaults.bind(this);
        this.setUpImageHandling = this.setUpImageHandling.bind(this);
        this.previewClicked = this.previewClicked.bind(this);
    }

    componentDidMount(){
        if(this.isEdit()) {
            this.props.dispatch(fetchTemplates([this.props.match.params.id]))
        }
    }

    isEdit(): boolean {
        return !this.props.newWithId && this.props.match?.params?.id
    }

    updateDefaults() {
        if(this.updatedDefaults) {
            return;
        }

        try {
            console.log("Updating default background color");
            //Get the default html, then change its background color to white, cause gray is dumb
            this.emailEditorRef.current.editor.exportHtml((data:any) => {
                if(Object.values(data).length === 0 || !data.design.body) {
                    console.log("Still waiting for template editor to load...");
                    if(this.numDefaultTries++ < this.maxDefaultTries) {
                        setTimeout(() => this.updateDefaults(), this.delayBetweenTriesMS);
                    }
                    return;
                }
                data.design = defaultDesign;
                data.design.body.values.backgroundColor = "#ffffff";
                this.emailEditorRef.current.editor.loadDesign(data.design);
                this.updatedDefaults = true;
                console.log("Default background color updated successfully");
            });
        } catch(e:any) {
            if(this.numDefaultTries++ < this.maxDefaultTries) {
                setTimeout(() => this.updateDefaults(), this.delayBetweenTriesMS);
            } else {
                //Only really happens if user is clicking around a lot and leaves the page before the timer is done, let's not alert
                //showToast("Unable to automatically update default background color.  You may want to manually set the background to another color (the default is gray)!", "warning");
            }
        }
    }

    setUpImageHandling() {
        if(this.state.successfullySetUpImageHandling) {
            return;
        }

        try {
            this.emailEditorRef.current.editor.exportHtml((data:any) => {
                if (Object.values(data).length === 0) {
                    console.log("Still waiting for template editor to load...");
                    if (this.numImageHandlingTries++ < this.maxImageHandlingTries) {
                        setTimeout(() => this.setUpImageHandling(), this.delayBetweenTriesMS);
                    }
                    return;
                }

                try {
                    this.emailEditorRef.current.registerCallback('image', this.uploadImageFile);
                    this.setState({
                        ...this.state,
                        successfullySetUpImageHandling: true,
                    })
                    console.log("Successfully registered callback");
                } catch(e) {
                    console.warn("unable to register callback")
                    console.warn((e as any).message)
                }
            })
        } catch(e:any) {
            if(this.numImageHandlingTries++ < this.maxImageHandlingTries) {
                setTimeout(() => this.setUpImageHandling(), this.delayBetweenTriesMS);
            } else {
                //Only really happens if user is clicking around a lot and leaves the page before the timer is done, let's not alert
                //showToast("Unable to automatically update default background color.  You may want to manually set the background to another color (the default is gray)!", "warning");
            }
        }
    }

    uploadImageFile(file:FileInfo, done:(data:FileUploadDoneData) => void) {
        fetchS3TemplateImageUploadUrl(this.props.dispatch, file.attachments[0].name, file.attachments[0].type).then(url => {
            console.log("template upload url is ", url);
            console.log({
                method: 'PUT',
                headers: {
                    'Content-Type': file.attachments[0].type,
                },
                body: file.attachments[0]
            });
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.attachments[0].type,
                },
                body: file.attachments[0]
            }).then(response => {
                // Make sure the response was valid
                if (response.status >= 200 && response.status < 300) {
                    showToast(`Successfully uploaded image ${file.attachments[0].name}`, "success");
                    done({ progress: 100, url: getImagesBucketUrl() + "/template-images/" + file.attachments[0].name })
                    return response
                } else {
                    showToast(response.statusText, "error");
                }
            });
        });
    }

    onEmailEditorLoad() {
        setTimeout(() => {
            this.setUpImageHandling();

            if(!this.state.removedLinkTypes) {
                try {
                    //Remove weird link types
                    this.emailEditorRef.current.editor.setLinkTypes([
                        {
                            name: 'phone',
                            enabled: false
                        },
                        {
                            name: 'email',
                            enabled: false
                        },
                        {
                            name: 'sms',
                            enabled: false
                        }
                    ]);
                    console.log("successfully removed unwanted link types")
                    this.setState({
                        ...this.state,
                        removedLinkTypes: true,
                    })
                } catch(e:any) {
                    console.error(e.message);
                }
            }


            try {
                //Possibly better way to do this: https://github.com/unlayer/react-email-editor/issues/22
                if(this.isEdit() && !this.state.loadedInitialTemplate && this.state.template) {
                    //Try to load template
                    try {
                        this.emailEditorRef.current.editor.loadDesign(this.state.template.json);
                        console.log("Loaded template successfully");
                        this.setState({
                            ...this.state,
                            loadedInitialTemplate:true,
                        })
                    } catch (e:any) {
                        console.error(`Unable to load saved template on editor load ${this.state.template}, ${e.message}`);
                    }
                } else if(!this.isEdit()) {
                    //If we're not loading a template, set the default background color to white
                    this.updateDefaults();
                    console.log("update defaults")
                }
            } catch(e:any) {
                showToast("Unable to load template: " +denastifyError( e.message), "error");
                console.error(e.message);
            }

        }, 500);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if(this.isEdit() && !this.state.loadedInitialTemplate && this.state.template) {
            try {
                this.emailEditorRef.current.editor.loadDesign(this.state.template.json);

                console.log("Loaded template successfully");
                this.setState({
                    ...this.state,
                    loadedInitialTemplate:true,
                })
            } catch(e:any) {
                console.error(`Unable to load saved template ${prevProps.fetchedTemplate}, ${e.message}`);
            }
        } else if(!this.isEdit()) {
            this.updateDefaults();
        }
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(!prevState.fetchedTemplate && nextProps.fetchedTemplate) {
            return {
                ...prevState,
                id: nextProps.fetchedTemplate.id,
                name: nextProps.fetchedTemplate.name,
                subject: nextProps.fetchedTemplate.subject,
                type: nextProps.fetchedTemplate.type,
                template: nextProps.fetchedTemplate, //The whole object
                fetchedTemplate:true,
            };
        }

        return null;
    }

    nameChange(event:React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                name: value
            }
        })
    }

    subjectChange(event:React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                subject: value
            }
        })
    }

    typeChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        const value = event.target.value as string;
        this.setState(state => {
            return {
                ...state,
                type: value
            }
        })
    }

    async validateForm(state: IState):Promise<boolean> {
        //Make sure the email editor actual results in a real email
        //Do this first b/c we have to explicitly set the error for the email editor
        let emailDesign = "";
        let emailHtml = "";
        await new Promise((resolve, reject) => {
            this.emailEditorRef.current.editor.exportHtml((data:any) => {
                const { design, html } = data;
                emailDesign = design;
                emailHtml = html;
                resolve(null);
            });
        });
        if(!emailDesign || emailDesign === "<empty string>") {
            this.setState(state => {
                return {
                    ...state,
                    emailEditorError: "You must add email contents",
                }
            });
            return false;
        }
        if(!emailHtml || emailHtml === "<empty string>" || emailHtml.length === 4999) {
            this.setState(state => {
                return {
                    ...state,
                    emailEditorError: "You must add email contents",
                }
            });
            return false;
        }
        if(this.state.type === "TEMPLATE_MARKETING" && emailHtml.indexOf("{{unsubscribe_link}}") === -1) {
            this.setState(state => {
                return {
                    ...state,
                    emailEditorError: "Marketing emails must include an unsubscribe link!  Please see the instructions below the template editor",
                }
            });
            return false;
        }
        //Clear email editor error
        if(this.state.emailEditorError) {
            this.setState(state => {
                return {
                    ...state,
                    emailEditorError: "",
                }
            });
        }

        if(validateExists(state.name) !== "") {
            return false;
        }
        if(validateExists(state.subject) !== "") {
            return false;
        }
        if(validateExists(state.type) !== "") {
            return false;
        }

        return true;
    }

    previewClicked(event:React.MouseEvent) {
        this.setState({
            ...this.state,
            previewMode: !this.state.previewMode,
        })

        this.emailEditorRef.current.editor.exportHtml((data:any) => {
            const { design, html } = data;
            const json = JSON.parse(JSON.stringify(design));

            this.setState({
                ...this.state,
                template: {
                    ...this.state.template,
                    html_body: html,
                    plain_text_body: html,
                    json,
                }
            });
        });
    }

    async handleSubmit(event:React.MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(await this.validateForm(this.state)) {
            this.emailEditorRef.current.editor.exportHtml((data:any) => {
                const { design, html } = data;
                let json = {}
                try {
                    json = JSON.parse(JSON.stringify(design));
                }catch(e) {
                    console.error("can't parse json from " + JSON.stringify(design));
                }

                this.props.dispatch(submitTemplateAction(
                    this.state.id,
                    this.state.name,
                    this.state.type,
                    this.state.subject,
                    html,
                    html,
                    json, 
                    false,
                    () => {
                        const desiredPath = "/components/templates/edit/" + this.state.id
                        if(this.props.location.pathname !== desiredPath) {
                            this.props.history.replace(desiredPath)
                        }
                    }))

                this.setState({
                    ...this.state,
                    template: {
                        ...this.state.template,
                        html_body: html,
                        plain_text_body: html,
                        json,
                    }
                });
            });
        }
    }

    render() {
        return (<>
            <MobileView>
                <InformationArea type={"warning"}  style={{marginTop:"1rem", width:"100%"}}
                     contents={"Mobile devices are not currently supported for template editing. " +
                     "For full template editing functionality please switch to a device capable of drag-dropping form elements such as a laptop."}  />
            </MobileView>
            {this.state.sendingTestEmail && <MyModal title={"Send test email"}
                   style={{width:"70vh", zIndex:10}}
                   cardStyle={{padding:"2em"}}
                   open={this.state.sendingTestEmail}
                   content={<div>
                   <Typography component="p" variant="body1" style={{marginBottom:"2rem"}}>
                        You can send a single tester email via this interface.  {inSandbox() && <>
                            Since you are still in sandbox mode, you'll only be able to send to 
                            one of your sender personas or to success@simulator.amazonses.com
                        </>}
                   </Typography>
                   <Grid container style={{marginBottom:"0.5rem", height:"2.5rem"}}>
                        <Grid item md={3} lg={3} xl={3} style={{textAlign:"end",alignContent:"end", justifyContent:"end", paddingRight:"15px"}}>
                            <Typography variant="body1" style={{marginBottom:"5px"}}>
                                Template:
                            </Typography>
                        </Grid>
                        <Grid item xs={11} sm={11} md={8} lg={8} xl={8}>
                            <ListTemplates 
                                dispatch={this.props.dispatch}
                                type='select'
                                width={'100%'} 
                                style={{width:"100%"}}
                                selectedTemplateId={this.state.testEmailTemplateId} />
                        </Grid>
                   </Grid>
                   <Grid container spacing={2} style={{marginBottom:"0.5rem"}}>
                        
                        <Grid item md={3} lg={3} xl={3} style={{textAlign:"end",alignContent:"end", justifyContent:"end"}}>
                            <Typography variant="body1">
                                Recipient Type: 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                            <ToggleButtonGroup
                                color="primary"
                                value={this.state.testEmailType}
                                exclusive
                                onChange={(e, val) => val !== null && this.setState({...this.state, testEmailType: val})}
                                aria-label="Recipient Type"
                                size='small'
                                >
                                <ToggleButton value="simulator">
                                    {inSandbox() && <><Computer /> Simulator Email</>}
                                    {!inSandbox() && <><Group /> Contact</>}
                                </ToggleButton>
                                <ToggleButton value="sender persona"><EmojiPeople /> Sender Persona</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                   </Grid>
                   <Grid container style={{marginBottom:"0.5rem", height:"2.5rem"}}>
                        <Grid item md={3} lg={3} xl={3} style={{textAlign:"end",alignContent:"end", justifyContent:"end", paddingRight:"15px"}}>
                            <Typography variant="body1" style={{marginBottom: this.state.testEmailType ? "5px": "0px"}}>
                                Recipient:
                            </Typography>
                        </Grid>
                        <Grid item xs={11} sm={11} md={8} lg={8} xl={8}>
                            <div style={{display: this.state.testEmailType==="simulator"? "block":"none"}}>
                                <TextField style={{width:"100%", marginBottom:"5px"}} value={this.state.testEmailToEmail} onChange={(e) => { this.setState({...this.state, testEmailToEmail: e.target.value })}} />
                            </div>
                            <div style={{display: this.state.testEmailType!=="simulator"? "block":"none"}}>
                                <ListSenderPersonas placeholder='Select recipient' selectedSenderPersonaIds={[this.state.testEmailToId]} onChange={e => this.setState({ ...this.state, testEmailToId: e.target.value.toString() })} width={'100%'} style={{width:"100%"}} type={'select'} dispatch={this.props.dispatch} />
                            </div>
                        </Grid>
                   </Grid>
                   <Grid container style={{marginBottom:"0.5rem", height:"2.5rem"}}>
                        <Grid item md={3} lg={3} xl={3} style={{textAlign:"end",alignContent:"end", justifyContent:"end", paddingRight:"15px"}}>
                            <Typography variant="body1" style={{marginBottom: this.state.testEmailType ? "5px": "0px"}}>
                                From:
                            </Typography>
                        </Grid>
                        <Grid item xs={11} sm={11} md={8} lg={8} xl={8}>
                            <ListSenderPersonas placeholder='Select sender' selectedSenderPersonaIds={[this.state.testEmailFromId]} onChange={e => this.setState({ ...this.state, testEmailFromId: e.target.value.toString() })} width={'100%'} style={{width:"100%"}} type={'select'} dispatch={this.props.dispatch} />
                        </Grid>
                    </Grid>
                   </div>}
                   buttons={<div style={{width:"100%",marginTop:"2em"}}>
                        <Button 
                            variant="contained"
                            color="primary"
                            startIcon={<EmailBlastIcon />}
                            style={{
                                marginLeft:"5px",
                                marginRight:"3em",
                                float:"right",
                            }}
                            onClick={(event:any) => {
                                event.stopPropagation();
                                event.preventDefault();

                                if(!this.props.fetchedTemplate) {
                                    showToast("missing template", "error")
                                    return
                                }
                                if(!(this.props.senderPersonas && this.props.senderPersonas[this.state.testEmailFromId])) {
                                    showToast("missing sender", "error")
                                    return
                                }
                                if(!this.state.testEmailToEmail && !(this.props.senderPersonas && this.props.senderPersonas[this.state.testEmailToId])) {
                                    showToast("missing recipient", "error")
                                    return
                                }

                                const sender = this.props.senderPersonas[this.state.testEmailFromId]
                                const from = {
                                    email: sender.email,
                                    name: (sender.first_name + " " + sender.last_name).trim(),
                                    user_id: sender.id,
                                }
                                let recipient: EmailContact = {
                                    email: this.state.testEmailToEmail,
                                    user_id: uuidv4(),
                                    name: "",
                                }
                                if(this.state.testEmailType === "sender persona") {
                                    const persona = this.props.senderPersonas[this.state.testEmailToId]
                                    recipient = {
                                        email: persona.email,
                                        user_id: persona.id,
                                        name: (persona.first_name + " " + persona.last_name).trim()
                                    }
                                }
                                
                                console.log("Sending email...")
                                this.props.dispatch(sendSingleEmail({
                                    template_id: this.state.testEmailTemplateId,
                                    subject: this.props.fetchedTemplate.subject,
                                    from,
                                    to: [recipient],
                                    cc: [],
                                    bcc: [],
                                    body:  this.props.fetchedTemplate.html_body,
                                    campaign_id: '',
                                    segment_id: '',
                                    workflow_id: '',
                                    scheduled_email_group_id: '',
                                    channel:  this.props.fetchedTemplate.channel_id,
                                }))
                            }}>Send Email</Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            style={{
                                marginLeft:"5px",
                                marginRight:"5px",
                                float:"right",
                            }}
                            onClick={(event:any) => {
                                event.stopPropagation();
                                event.preventDefault();
                                this.setState({...this.state, sendingTestEmail: false})
                            }}>Cancel</Button>
                    </div>}
                />}
            <Card elevation={defaultCardElevation} 
                style={{
                    ...defaultCardStyles,
                    minWidth:"1030px",
                    marginTop: this.state.isUnsupportedBrowser || isMobile? "1rem":"0"}} > {/* min width to match iframe */}
                <Typography variant={"h1"} component={"div"} >
                    {!this.isEdit() ? "New " : "Edit " }
                    Template
                </Typography>
                <hr />
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            <Description />
                        </Grid>
                        <Grid item sm={4} md={4} lg={4} xl={4}>
                            <TextField label={"Template name"}
                                       autoFocus={true}
                                       required={true}
                                       style={{width:"100%"}}
                                       value={this.state.name}
                                       onChange={this.nameChange}
                                       error={this.state.allowValidation && validateExists(this.state.name)!==""}
                                       helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            {this.state.type === "TEMPLATE_MARKETING" ? <Cached /> : <CompareArrows />}
                        </Grid>
                        <Grid item sm={4} md={4} lg={4} xl={4}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel required={true}>Type</InputLabel>
                                <Select style={{width:"100%"}}
                                        value={this.state.type}
                                        onChange={this.typeChange}>
                                    <MenuItem key={"TEMPLATE_MARKETING"} value={"TEMPLATE_MARKETING"}>Marketing</MenuItem>
                                    <MenuItem key={"TEMPLATE_TRANSACTIONAL"} value={"TEMPLATE_TRANSACTIONAL"}>Transactional</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"} style={{marginBottom:"0.5rem"}}>
                        <Grid item >
                            <Subject />
                        </Grid>
                        <Grid item sm={4} md={4} lg={4} xl={4}>
                            <TextField label={"Subject"}
                                       required={true}
                                       style={{width:"100%"}}
                                       value={this.state.subject}
                                       onChange={this.subjectChange}
                                       error={this.state.allowValidation && validateExists(this.state.subject)!==""}
                                       helperText={this.state.allowValidation && validateExists(this.state.subject)!==""? validateExists(this.state.subject): ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"} style={{marginBottom:"0.5rem"}}>
                        <Grid item>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    startIcon={<SaveIcon />}
                                    type={"submit"}
                                    onClick={this.handleSubmit}>
                                Save
                            </Button>
                            <Button variant={"outlined"}
                                    color={"primary"}
                                    style={{marginLeft:"5px"}}
                                    startIcon={this.state.previewMode?<VisibilityOff /> : <Visibility />}
                                    onClick={this.previewClicked}>
                                {this.state.previewMode? "Return to edit mode" : "Render variables"}
                            </Button>
                            <Button variant={"outlined"}
                                    color={"secondary"}
                                    style={{marginLeft:"5px"}}
                                    startIcon={<Email />}
                                    onClick={e => this.setState({...this.state, sendingTestEmail: !this.state.sendingTestEmail})}>
                                Send test email
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item >
                            {this.state.allowValidation && this.state.emailEditorError &&
                            <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                                <Grid item >
                                    <p className={"error-text"}>{this.state.emailEditorError}</p>
                                </Grid>
                            </Grid>}
                            {this.state.previewMode && <>
                                <hr />
                                <Typography variant={"body1"} component={"p"}>
                                    Variable tags are being replaced with sample text, e.g. {"{{user.first_name}}"} =&gt; Dave.  Note that
                                    actual results will depend on the data available at email-rendering time.  To return to editing mode, click
                                    the the button above.
                                </Typography>
                                <hr />
                                <PreviewTemplate html={this.state.template ? this.state.template.html_body : ""} />
                            </>}
                            <div className="wrapper" style={{display:this.state.previewMode?"none":"block"}}>
                                <div className={"overlay"} />
                                <EmailEditor
                                    ref={this.emailEditorRef}
                                    onLoad={this.onEmailEditorLoad}
                                    style={{height:"750px"}}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Card>
            <Card elevation={defaultCardElevation} style={{...defaultCardStyles, minWidth:"1030px"}}>
                <Typography variant={"h2"} component={"p"}>
                    Images are public!
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                    Images uploaded here will be made publicly available so that your contacts can see them in their inboxes.
                    Please don't upload any images you don't want everyone on the internet to be able to see!
                </Typography>
            </Card>
            {this.state.type === "TEMPLATE_MARKETING" && <Card elevation={defaultCardElevation} style={{...defaultCardStyles,minWidth:"1030px"}}>
                <Typography variant={"h2"} component={"p"}>
                    Required Unsubscribe Link
                </Typography>
                <Typography variant={"body1"} component={"p"}>
                    Marketing messages require an unsubscribe link to be placed somewhere in the template. To add one
                    of these unsubscribe links, drag-dop an "HTML" element onto your template and alter
                    its contents to contain the following:
                </Typography>
                <div className={"inset-code-box"} style={{marginTop:"1rem"}}>
                    <code>
                        &lt;hr&gt;
                        &lt;p style="color:#888"&gt;
                        If you’d prefer not to receive these emails, you can
                        &lt;a style="color:#787878" href="{"{{unsubscribe_link}}"}"&gt;unsubscribe here&lt;/a&gt;
                        &lt;/p&gt;
                    </code>
                </div>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem", marginBottom:"1rem"}}>
                    Next, alter the text around the &lt;a&gt;&lt;/a&gt; link to say whatever you want.  As long as a link with
                    the variable {"{{unsubscribe_link}}"} is in the template, you'll be fine!
                </Typography>
            </Card>}
            <Card elevation={defaultCardElevation} style={{...defaultCardStyles, minWidth:"1030px"}}>
                <Typography variant={"h2"} component={"p"}>
                    Template Variables
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                    You can show contact- or event-specific information by including
                    the variable tags listed below (in the usage column) in your template text.
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                    For example a template containing "Hello {"{{user.first_name}}"}" would render
                    "Hello Sally" when sent to Sally or "Hello Dave" when sent to Dave.  If we don't have a value
                    for a given variable, we will render a blank. For "Hello {"{{user.first_name}}"}", we would render
                    "Hello " if we don't know the contact's first name.
                </Typography>

                <TemplateVariables style={{marginBottom:"1rem"}} />
            </Card>
            {this.state.type !== "TEMPLATE_MARKETING" && <Card elevation={defaultCardElevation} style={{...defaultCardStyles, minWidth:"1030px"}}>
                <Typography variant={"h2"} component={"p"}>
                    Adding links
                </Typography>
                <Typography variant={"body1"} component={"p"}>
                    To add a hypertext link, drag-drop an "HTML" element to your template and
                    alter its contents to contain a hypertext link.  Here is an example of a link that
                    will take users to swiftmissive.com:
                </Typography>
                <div className={"inset-code-box"} style={{marginTop:"1rem"}}>
                    <code>
                        &lt;a href="https://swiftmissive.com" target="_blank"&gt;Swift Missive!&lt;/a&gt;
                    </code>
                </div>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    If you'd like you can copy-paste the above code into your HTML template element's contents,
                    then change the href url (https://swiftmissive.com) to the url you want users to be taken to
                    and change the link text (Swift Missive!) to the text you want your contact to see.  The example above
                    will render a link that looks like this:  <a href="https://swiftmissive.com" target="_blank" rel="noreferrer">Swift Missive!</a>
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    Images and buttons are easier to turn into links.  Just drag-drop them to the template then click them to access
                    their properties and change their urls to where you want your contacts to go when they press the button or image.
                </Typography>
            </Card>}
        </>)
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    //Find the template with the id passed in via the route
    let fetchedTemplate = null;
    if(state.templates && Object.keys(state.templates).length > 0) {
        if(state.templates[ownProps.match.params.id]) {
            fetchedTemplate = state.templates[ownProps.match.params.id];
        }
    }

    return {
        ...state,
        fetchedTemplate,
    }
 }

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewTemplateComponent))

const defaultDesign = JSON.parse(`{"counters":{"u_column":1,"u_row":1,"u_content_html":1},"body":{"rows":[{"cells":[1],"columns":[{"contents":[{"type":"html","values":{"html":"<hr> <p style=\\"color:#888\\">If you’d prefer not to receive these emails, you can <a style=\\"color:#787878\\" href=\\"{{unsubscribe_link}}\\">unsubscribe here</a></p>","containerPadding":"10px","_meta":{"htmlID":"u_content_html_1","htmlClassNames":"u_content_html"},"selectable":true,"draggable":true,"duplicatable":true,"deletable":true,"hideable":true}}],"values":{"backgroundColor":"","padding":"0px","border":{},"_meta":{"htmlID":"u_column_1","htmlClassNames":"u_column"}}}],"values":{"displayCondition":null,"columns":false,"backgroundColor":"","columnsBackgroundColor":"","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"padding":"0px","hideDesktop":false,"_meta":{"htmlID":"u_row_1","htmlClassNames":"u_row"},"selectable":true,"draggable":true,"duplicatable":true,"deletable":true,"hideable":true}}],"values":{"textColor":"#000000","backgroundColor":"#ffffff","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"contentWidth":"500px","contentAlign":"center","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"preheaderText":"","linkStyle":{"body":true,"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"linkHoverUnderline":true},"_meta":{"htmlID":"u_body","htmlClassNames":"u_body"}}},"schemaVersion":6}`);