import {
  IConfig,
  IFlowChartCallbacks,
  ILinkBaseInput, INodeBaseInput,
  IOnCanvasDropInput,
  IOnDragCanvasInput,
  IOnDragCanvasStopInput,
  IOnDragNodeInput,
  IOnDragNodeStopInput,
  IOnLinkBaseEvent,
  IOnLinkCompleteInput,
  IOnLinkMoveInput, IOnNodeSizeChangeInput, IOnPortPositionChangeInput
} from "@mrblenny/react-flow-chart/src";
import {actions as defaultFlowchartActions} from "@mrblenny/react-flow-chart/src/container";

export function actionCallbacks(thisThis:any) :IFlowChartCallbacks {
  return {
    // onNodeDoubleClick(input: INodeBaseInput): void {
    //   return;
    // },
    // onZoomCanvas(input: { config?: IConfig }): void {
    //   return;
    // },
    onCanvasClick(input: { config?: IConfig }): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onCanvasClick(input)(thisThis.state.workflow),
      });
    }, onCanvasDrop(input: IOnCanvasDropInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onCanvasDrop(input)(thisThis.state.workflow),
      });
    }, onDeleteKey(input: { config?: IConfig }): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onDeleteKey(input)(thisThis.state.workflow),
      });
    }, onDragCanvas(input: IOnDragCanvasInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onDragCanvas(input)(thisThis.state.workflow),
      });
    }, onDragCanvasStop(input: IOnDragCanvasStopInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onDragCanvasStop(input)(thisThis.state.workflow),
      });
    }, onDragNode(input: IOnDragNodeInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onDragNode(input)(thisThis.state.workflow),
      });
    }, onDragNodeStop(input: IOnDragNodeStopInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onDragNodeStop(input)(thisThis.state.workflow),
      });
    }, onLinkCancel(input: IOnLinkBaseEvent): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkCancel(input)(thisThis.state.workflow),
      });
    }, onLinkClick(input: ILinkBaseInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkClick(input)(thisThis.state.workflow),
      });
    }, onLinkComplete(input: IOnLinkCompleteInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkComplete(input)(thisThis.state.workflow),
      });
    }, onLinkMouseEnter(input: ILinkBaseInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkMouseEnter(input)(thisThis.state.workflow),
      });
    }, onLinkMouseLeave(input: ILinkBaseInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkMouseLeave(input)(thisThis.state.workflow),
      });
    }, onLinkMove(input: IOnLinkMoveInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkMove(input)(thisThis.state.workflow),
      });
    }, onLinkStart(input: IOnLinkBaseEvent): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onLinkStart(input)(thisThis.state.workflow),
      });
    }, onNodeClick(input: INodeBaseInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onNodeClick(input)(thisThis.state.workflow),
      });
    }, onNodeMouseEnter(input: INodeBaseInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onNodeMouseEnter(input)(thisThis.state.workflow),
      });
    }, onNodeMouseLeave(input: INodeBaseInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onNodeMouseLeave(input)(thisThis.state.workflow),
      });
    }, onNodeSizeChange(input: IOnNodeSizeChangeInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onNodeSizeChange(input)(thisThis.state.workflow),
      });
    }, onPortPositionChange(input: IOnPortPositionChangeInput): void {
      thisThis.setState({
        ...thisThis.state,
        workflow:  defaultFlowchartActions.onPortPositionChange(input)(thisThis.state.workflow),
      });
    }
  };
}