import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  ArchiveCampaignsResponsePayload, Campaign,
  CampaignWithMetrics,
  GetCampaignsResponsePayload,
  GetCampaignsWithMetricsResponsePayload,
  GetNumCampaignsWithMetricsResponsePayload,
  UpsertCampaignPayload,
  UpsertCampaignResponsePayload,
} from "../generated/types/payloadTypes";
import { getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_CAMPAIGNS = 'REQUEST_CAMPAIGNS';
export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';
export const REQUEST_CAMPAIGNS_WITH_METRICS = 'REQUEST_CAMPAIGNS_WITH_METRICS';
export const RECEIVE_CAMPAIGNS_WITH_METRICS = 'RECEIVE_CAMPAIGNS_WITH_METRICS';
export const REQUEST_NUM_CAMPAIGNS_WITH_METRICS = 'REQUEST_NUM_CAMPAIGNS_WITH_METRICS';
export const RECEIVE_NUM_CAMPAIGNS_WITH_METRICS = 'RECEIVE_NUM_CAMPAIGNS_WITH_METRICS'
export const ARCHIVE_CAMPAIGNS = 'ARCHIVE_CAMPAIGNS';
export const ARCHIVE_CAMPAIGNS_RESPONSE = 'ARCHIVE_CAMPAIGNS_RESPONSE';
export const REQUEST_NEW_CAMPAIGN = 'REQUEST_NEW_CAMPAIGN';

interface IRequestCampaignsAction {
  type: typeof REQUEST_CAMPAIGNS,
}
interface IReceiveCampaignsAction {
  type: typeof RECEIVE_CAMPAIGNS,
  campaigns: Campaign[],
}
interface IRequestCampaignsWithMetricsAction {
  type: typeof REQUEST_CAMPAIGNS_WITH_METRICS,
  ids: string[],
  limit: number,
  offset:number,
  orderColumns: string[],
  splitTemplates: boolean,
}
interface IRequestNumCampaignsWithMetricsAction {
  type: typeof REQUEST_NUM_CAMPAIGNS_WITH_METRICS,
  ids: string[],
  splitTemplates: boolean,
}
interface IReceiveCampaignsWithMetricsAction {
  type: typeof RECEIVE_CAMPAIGNS_WITH_METRICS,
  campaignsWithMetrics: CampaignWithMetrics[],
}
interface IReceiveNumCampaignsWithMetricsAction {
  type: typeof RECEIVE_NUM_CAMPAIGNS_WITH_METRICS,
  num: number,
}
interface IRequestNewCampaignAction {
  type: typeof REQUEST_NEW_CAMPAIGN,
}
interface IArchiveCampaignsAction {
  type: typeof ARCHIVE_CAMPAIGNS,
  id:string,
}
interface IArchiveCampaignsResponseAction {
  type: typeof ARCHIVE_CAMPAIGNS_RESPONSE,
  campaignId:string,
  success: boolean,
}
export type CampaignActionTypes = IRequestCampaignsAction | IReceiveCampaignsAction| IRequestNewCampaignAction 
  | IArchiveCampaignsAction | IArchiveCampaignsResponseAction | IRequestCampaignsWithMetricsAction 
  | IReceiveCampaignsWithMetricsAction | IRequestNumCampaignsWithMetricsAction | IReceiveNumCampaignsWithMetricsAction

export function archiveCampaignsAction(id: string):IArchiveCampaignsAction {
  return {
    type: 'ARCHIVE_CAMPAIGNS',
    id,
  }
}

export function archiveCampaignsResponseAction(success: boolean, campaignId: string):IArchiveCampaignsResponseAction {
  return {
    type: 'ARCHIVE_CAMPAIGNS_RESPONSE',
    campaignId,
    success,
  }
}

export function requestCampaignsAction():IRequestCampaignsAction {
  return {
    type: 'REQUEST_CAMPAIGNS',
  }
}

export function requestCampaignsWithMetricsAction(ids: string[], limit:number, offset:number, orderColumns:string[], splitTemplates:boolean):IRequestCampaignsWithMetricsAction {
  return {
    type: 'REQUEST_CAMPAIGNS_WITH_METRICS',
    ids,
    limit,
    offset,
    orderColumns,
    splitTemplates
  }
}

export function requestNumCampaignsWithMetricsAction(ids: string[], splitTemplates:boolean):IRequestNumCampaignsWithMetricsAction {
  return {
    type: 'REQUEST_NUM_CAMPAIGNS_WITH_METRICS',
    ids,
    splitTemplates
  }
}

export function receiveCampaignsAction(campaigns: Campaign[]):IReceiveCampaignsAction {
  return {
    type: 'RECEIVE_CAMPAIGNS',
    campaigns,
  }
}

export function receiveCampaignsWithMetricsAction(campaignsWithMetrics: CampaignWithMetrics[]):IReceiveCampaignsWithMetricsAction {
  return {
    type: 'RECEIVE_CAMPAIGNS_WITH_METRICS',
    campaignsWithMetrics,
  }
}

export function receiveNumCampaignsWithMetricsAction(num: number): IReceiveNumCampaignsWithMetricsAction {
  return {
    type: 'RECEIVE_NUM_CAMPAIGNS_WITH_METRICS',
    num,
  }
}

export function requestNewCampaign():IRequestNewCampaignAction{
  return {
    type: 'REQUEST_NEW_CAMPAIGN',
  }
}

export function archiveCampaign(id: string, onSuccess?: () => void, onFailure?: (reason:string) => void) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(archiveCampaignsAction(id));
    return archiveCampaignPromise(dispatch, id, onSuccess, onFailure);
  }
}

export function fetchCampaigns(ids: string[] = [], names: string[] = []) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestCampaignsAction());
    return fetchCampaignsPromise(dispatch, ids, names)
  }
}

export function fetchCampaignsWithMetrics(ids: string[] = [], limit = 10, offset = 0, orderColumns:string[] = [], splitTemplates = true) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestCampaignsWithMetricsAction(ids, limit, offset, orderColumns, splitTemplates));
    return fetchCampaignsWithMetricsPromise(dispatch, ids, limit, offset, orderColumns, splitTemplates)
  }
}

export function fetchNumCampaignsWithMetrics(ids: string[] = [], splitTemplates = true) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNumCampaignsWithMetricsAction(ids, splitTemplates));
    return fetchNumCampaignsWithMetricsPromise(dispatch, ids, splitTemplates)
  }
}


function fetchCampaignsWithMetricsPromise(dispatch:(...args:any)=>any, ids:string[] = [], limit = 10, offset = 0,  orderColumns:string[] = [], splitTemplates = true) {
  //Get sender campaigns from api endpoint
  let url = getGatewayUrl("campaign")  + "/campaign_metrics";
  const urlParts = []
  if(ids && ids.length > 0) {
    urlParts.push("ids=" + encodeURIComponent(ids.join(",")))
  }
  if(limit) {
    urlParts.push("limit=" + limit.toString())
  }
  if(offset) {
    urlParts.push("offset=" + offset.toString())
  }
  if(orderColumns.length > 0) {
    urlParts.push("sort_columns=" + encodeURIComponent(orderColumns.join(",")))
  }
  if(splitTemplates) {
    urlParts.push("split_templates=true")
  }
  if(urlParts.length > 0) {
    url += "?" + urlParts.join("&")
  }

  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetCampaignsWithMetricsResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_CAMPAIGNS_WITH_METRICS))
      showToast(<>Couldn't fetch campaigns w/metrics: {errorsToUL(json.errors)}</>, "error")
    }

    console.log("Found " + (json.campaigns_with_metrics?json.campaigns_with_metrics.length:0) + " campaigns with metrics");
    dispatch(receiveCampaignsWithMetricsAction(json.campaigns_with_metrics))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_CAMPAIGNS_WITH_METRICS));
    showToast(`Couldn't fetch campaigns w/metrics: ${denastifyError(e.message)}`, "error");
  });
}


function fetchNumCampaignsWithMetricsPromise(dispatch:(...args:any)=>any, ids:string[] = [], splitTemplates = true) {
  //Get sender campaigns from api endpoint
  let url = getGatewayUrl("campaign")  + "/campaign_metrics/count";
  const urlParts = []
  if(ids && ids.length > 0) {
    urlParts.push("ids=" + encodeURIComponent(ids.join(",")))
  }
  if(splitTemplates) {
    urlParts.push("split_templates=true")
  }
  if(urlParts.length > 0) {
    url += "?" + urlParts.join("&")
  }

  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetNumCampaignsWithMetricsResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_CAMPAIGNS_WITH_METRICS))
      showToast(<>Couldn't fetch num campaigns w/metrics: {errorsToUL(json.errors)}</>, "error")
    }

    console.log("Found " + json.num_campaigns + " campaigns with metrics");
    dispatch(receiveNumCampaignsWithMetricsAction(json.num_campaigns))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_NUM_CAMPAIGNS_WITH_METRICS));
    showToast(`Couldn't fetch campaigns w/metrics: ${denastifyError(e.message)}`, "error");
  });
}



function fetchCampaignsPromise(dispatch:(...args:any)=>any, ids:string[] = [], names:string[] = []) {
  //Get sender campaigns from api endpoint
  let url = getGatewayUrl("campaign")  + "/campaigns";
  const queryString = []
  if(ids && ids.length > 0) {
    queryString.push("ids=" + ids.join(","))
  }
  if(names && names.length > 0) {
    queryString.push("names=" + names.map(n => encodeURIComponent(n)).join(","))
  }
  if(queryString.length > 0) {
    url += "?" + queryString.join("&")
  }

  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetCampaignsResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_CAMPAIGNS))
      showToast(<>Couldn't fetch campaigns: {errorsToUL(json.errors)}</>, "error")
    }

    let campaigns:Campaign[] = [];
    if(json.campaigns) {
      campaigns = json.campaigns;
    }

    console.log("Found " + campaigns.length + " campaigns");
    dispatch(receiveCampaignsAction(campaigns))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_CAMPAIGNS));
    showToast(`Couldn't fetch campaigns: ${denastifyError(e.message)}`, "error");
  });
}

export function submitCampaignAction(id:string,name:string, type:string, scheduledEmailBlastGroupIds:string[], 
    workflowIds:string[], templateIds:string[], onSuccess?:(c:Campaign) => any, onFailure?:(reason:string) => any) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNewCampaign());
    return postCampaignPromise(dispatch,id,name,type,scheduledEmailBlastGroupIds, workflowIds, templateIds, onSuccess, onFailure) 
  }
}

function postCampaignPromise(dispatch:(...args:any)=>any,id:string,name:string,type:string, scheduledEmailBlastGroupIds:string[], 
    workflowIds:string[], templateIds:string[], onSuccess?:(c:Campaign) => any, onFailure?:(reason:string) => any) {
  //Get sender campaigns from api endpoint
  const url = getGatewayUrl("campaign") + "/campaigns";
  console.log("POSTING TO URL",url);
  const data:UpsertCampaignPayload = {
    campaign:{
      id,
      type,
      name,
      scheduled_email_blast_group_ids:scheduledEmailBlastGroupIds,
      workflow_ids: workflowIds,
      template_ids: templateIds,
    },
  }

  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:UpsertCampaignResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      console.log("ERRORS")
      if(onFailure) {
        onFailure(denastifyError(JSON.stringify(json.errors)))
      } else {
        showToast(<>Couldn't save campaign: {errorsToUL(json.errors)}</>, "error");
      }
    } else {
      console.log("NO ERRORS")
      if(onSuccess) {
        onSuccess(json.campaign)
      } else {
        showToast("Successfully saved campaign '" + name + "'", "success");
      }
    }
  }).catch(e => {
    if(onFailure) {
      onFailure(denastifyError(e.message))
    } else {
      showToast(`Couldn't save campaign '${name}': ${denastifyError(e.message)}`, "error");
    }
  })
}


function archiveCampaignPromise(dispatch:(...args:any)=>any,id:string, onSuccess?: () => void, onFailure?: (reason:string) => void) {
  ((id: string) => {
    //Get sender campaigns from api endpoint
    const url = getGatewayUrl("campaign") + "/campaigns/" + id;
    console.log("POSTING TO URL",url);
    const options:any =  {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    };
    fetch(url, options)
        .then(res => res.json())
        .then((json:ArchiveCampaignsResponsePayload) => {
          console.log(JSON.stringify(json))
          if(json.errors && json.errors.length > 0) {
            dispatch(archiveCampaignsResponseAction(false, json.campaign_id || ""));
            if(onFailure) {
              onFailure(json.errors.join(", "))
            } else {
              showToast(<>Couldn't archive campaign: {errorsToUL(json.errors)}</>, "error");
            }
          } else {
            dispatch(archiveCampaignsResponseAction(true, json.campaign_id || ""));
            if(onSuccess) {
              onSuccess()
            } else {
              showToast("Successfully archived campaign", "success");
            }
          }
        }).catch(e => {
          dispatch(archiveCampaignsResponseAction(false, ""));
          if(onFailure) {
            onFailure(e.message)
          } else {
            showToast(`Couldn't archive campaign: ${denastifyError(e.message)}`, "error");
          }
        });
  })(id);
}