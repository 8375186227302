import {toast, ToastContainer} from "react-toastify";
import {ToastOptions} from "react-toastify/dist/types";

const defaultOptions:ToastOptions = {
    position: "bottom-center",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progressStyle: {
        height: "4px",
        borderRadius:"2px",
        marginLeft:"1rem",
        marginRight:"1rem",
    },
    style: {
        borderRadius:"10px",
        borderWidth:"1px",
        borderStyle:"solid"
    }
};

export function showToast(message: any, messageType: "success"|"warning"|"error"|"dark"|"default", options?: ToastOptions) {
    const myOptions = {
        ...defaultOptions,
        ...options,
    } as ToastOptions;

    let myFunc = toast.info;
    switch(messageType) {
        case "success":
            myFunc = toast.success;
            myOptions.style = {
                ...myOptions.style,
                // background:"linear-gradient(to top, #F5FFF5, #FFF, #FFF, #FFF, #FFF, #FFF, #F5FFF5)",
                background: "radial-gradient(#FDFFFD, #FDFFFD, #FDFFFD, #FDFFFD, #FAFFFA, #F5FFF5)",
                borderColor:"#CFC",
            }
            break;
        case "warning":
            myFunc = toast.warn;
            myOptions.style = {
                ...myOptions.style,
                background: "radial-gradient(#FFFFFD, #FFFFFD, #FFFFFD, #FFFFFD, #FFFFFA, #FFFFF5)",
                borderColor:"#FFA",
            }
            break;
        case "error":
            myFunc = toast.error;
            myOptions.style = {
                ...myOptions.style,
                background: "radial-gradient(#FFFDFD, #FFFDFD, #FFFDFD, #FFFDFD, #FFFAFA, #FFF5F5)",
                borderColor:"#FCC",
            }
            break;
        case "default":
            myFunc = toast.info;
            myOptions.style = {
                ...myOptions.style,
                background: "radial-gradient(#FDFDFF, #FDFDFF, #FDFDFF, #FDFDFF, #FAFAFF, #F4F4FF)",
                borderColor:"#DDF",
            }
            break;
        case "dark":
            myFunc = toast.dark;
            myOptions.style = {
                ...myOptions.style,
                background: "radial-gradient(#000, #000, #000, #000, #111, #333)",
                borderColor:"#444",
            }
            break;
    }
    myFunc(message, myOptions);
}

export function ToastContainerWrapper(props: {width: string}) {
    return <ToastContainer className={"hideForPrint"} style={{ width:"80%", maxWidth:"800px", minWidth:"400px", }}/>;
}

