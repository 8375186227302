import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {GetFileUploadStatusResponsePayload, GetJobsResponsePayload, JobLogEntry} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';

export const REQUEST_BULK_UPLOAD_STATUS = 'REQUEST_BULK_UPLOAD_STATUS';
export const RECEIVE_BULK_UPLOAD_STATUS = 'RECEIVE_BULK_UPLOAD_STATUS';

interface IRequestNotificationsAction {
  type: typeof REQUEST_NOTIFICATIONS,
}
interface IReceiveNotificationsAction {
  type: typeof RECEIVE_NOTIFICATIONS,
  data: any,
}
interface IRequestBulkUploadStatusAction {
  type: typeof REQUEST_BULK_UPLOAD_STATUS,
  s3Key: string,
}
interface IReceiveBulkUploadStatusAction {
  type: typeof RECEIVE_BULK_UPLOAD_STATUS,
  data: GetFileUploadStatusResponsePayload,
}
export type NotificationActionTypes = IRequestNotificationsAction | IReceiveNotificationsAction | IRequestBulkUploadStatusAction | IReceiveBulkUploadStatusAction;

export function requestNotifications() : IRequestNotificationsAction {
  return {
    type: REQUEST_NOTIFICATIONS,
  }
}

export function receiveNotifications(data: JobLogEntry[]): IReceiveNotificationsAction {
  return {
    type: RECEIVE_NOTIFICATIONS,
    data,
  }
}


export function requestBulkUploadStatus(s3Key: string) : IRequestBulkUploadStatusAction {
  return {
    type: REQUEST_BULK_UPLOAD_STATUS,
    s3Key,
  }
}

export function receiveBulkUploadStatus(data: GetFileUploadStatusResponsePayload): IReceiveBulkUploadStatusAction {
  return {
    type: RECEIVE_BULK_UPLOAD_STATUS,
    data,
  }
}


export function fetchNotifications(limit = 0, offset = 0, ids:string[] = [])  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNotifications());
    return fetchNotificationsPromise(dispatch, limit, offset, ids)
  }
}


export function fetchBulkUploadProgress(s3Key: string, startedAt?: Date)  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestBulkUploadStatus(s3Key));
    return fetchBulkUploadProgressPromise(dispatch, s3Key, startedAt)
  }
}

function fetchNotificationsPromise(dispatch: any, limit = 0, offset = 0, ids:string[] = []) {
  console.log("Fetching notifications");

  let url = getGatewayUrl("joblog")  + "/joblog";
  const queryStringParams = []
  if(ids && ids.length > 0) {
    queryStringParams.push("ids=" + encodeURIComponent(ids.join(",")))
  }
  if(limit) {
    queryStringParams.push(`limit=${limit}`)
  }
  if(offset) {
    queryStringParams.push(`offset=${offset}`)
  }
  const queryString = queryStringParams.join("&")
  if(queryString) {
    url += "?" + queryString
  }

  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetJobsResponsePayload) => {
    if(json.data) {
      console.log(`Found ${json.data?.length} notifications`);
      dispatch(receiveNotifications(json.data))
    }
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_NOTIFICATIONS));
      showToast(<>Couldn't fetch notifications: {errorsToUL(json.errors)}</>, "error");
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_NOTIFICATIONS));
    showToast(`Couldn't fetch notifications: ${denastifyError(e.message)}`, "error");
  });
}


function fetchBulkUploadProgressPromise(dispatch: any, s3Key:string, startedAt?: Date) {
  console.log("Fetching bulk upload progress for " + s3Key);

  let url = getGatewayUrl("joblog")  + "/bulk_upload_status";
  const queryStringParams = []
  if(s3Key) {
    let jobLogKey = s3Key
    if(s3Key.indexOf(".xlsx") !== -1) {
        jobLogKey += ".csv"
    }
    queryStringParams.push("s3_key=" + encodeURIComponent(jobLogKey))
  }
  if(startedAt) {
    // queryStringParams.push(`started_at=${(startedAt.toISOString().replace(/.\d+Z$/g, "Z"))}`)
  }
  const queryString = queryStringParams.join("&")
  if(queryString) {
    url += "?" + queryString
  }

  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetFileUploadStatusResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_BULK_UPLOAD_STATUS));
      dispatch(receiveBulkUploadStatus({
        errors: json.errors,
        num_in_progress_jobs: 1,
        num_total_jobs: 1,
        s3_key: s3Key,
        processing_errors: [],
      }))
      // showToast(<>Couldn't fetch notifications: {errorsToUL(json.errors)}</>, "error");
    } else {
      dispatch(receiveBulkUploadStatus(json))
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_BULK_UPLOAD_STATUS));
    dispatch(receiveBulkUploadStatus({
      errors: ["Unable to determine processing status due to '" + denastifyError(e.message) + "'"],
      num_in_progress_jobs: 1,
      num_total_jobs: 1,
      s3_key: s3Key,
      processing_errors: [],
    }))
    // showToast(`Couldn't fetch bulk upload status: ${denastifyError(e.message)}`, "error");
  });
}