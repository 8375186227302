import React, { ChangeEvent } from 'react';
import {connect} from "react-redux";
import {AllState} from "./reducers";
import {Card, Grid, Typography} from "@material-ui/core";
import {defaultCardElevation, defaultCardStyles} from "./App";
import ClickStats from "./reports/ClickStats";
import ListCampaigns from './ListCampaigns';
import { EmojiFlags } from '@material-ui/icons';
import OpenStats from './reports/OpenStats';
import QuickStats from './reports/QuickStats';
import SendsByHour from './reports/SendsByHour';
import RecentClickStats from './reports/RecentClicksStats'
import RecentOpenStats from './reports/RecentOpenStats';
import OpensAndClicksBySource from './reports/OpensAndClicksBySource';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IState {
    campaignId?: string,
}

interface IProps extends RouteComponentProps<{ ignorethis?: string  }>{
    dispatch: any,
    width: "xs" | "sm" | "md" | "lg" | "xl",
    reportData?: {[key:string]:any[]},
    errors?: {[key:string]:any[]},
}

class PageInsightDetails extends React.Component<IProps, IState> {
    props: IProps;
    state: IState;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        
        let campaignId = ""
        if(this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search)
            campaignId = urlParams.get("campaign_id") || ""
        }
        
        this.state = {
            ...this.state,
            campaignId,
        }
        this.campaignChange = this.campaignChange.bind(this);
    }

    
    campaignChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        const campaignId = event.target.value.toString() 
        let queryString = "?campaign_id=" + encodeURIComponent(campaignId)
        if(!campaignId || campaignId === "placeholder") {
            queryString = ""
        }

        this.props.history.push(this.props.location.pathname + queryString)
        this.setState({
            ...this.state,
            campaignId,
        })
    }

    render() {

        return <div style={{marginBottom:"4rem"}}>
            <Card elevation={defaultCardElevation} style={{ 
                    ...defaultCardStyles,
                }}
                className='printBreakAvoidInside'
                >
                <Typography variant={"h1"} component={"div"}>Insights: Statistics</Typography>
                <hr />
                <Grid container spacing={1}  style={{marginTop:"1rem", marginBottom:"1rem"}}>
                    <Grid item style={{ justifyContent:"flex-end", alignContent:"flex-end", alignItems:"flex-end", padding:"5px"}}>
                        <Typography variant={"body1"} style={{fontSize:"16pt"}}>
                            Filters:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ListCampaigns 
                            type="select" 
                            dispatch={this.props.dispatch} 
                            onChange={this.campaignChange}
                            selectStartAdornment={<EmojiFlags />}
                            placeholder="All campaigns"
                            placeholderEnabled={true}
                            selectedCampaignId={this.state.campaignId}
                            showDates={true}
                            style={{
                                width:"100%",
                                minWidth:"300px",
                                maxWidth:"400px",
                            }}
                        />
                    </Grid> 
                </Grid>
                < hr />
                <QuickStats key={"quickstats" + this.state.campaignId} campaignId={this.state.campaignId} dispatch={this.props.dispatch} />
            </Card>

            <Card elevation={defaultCardElevation} 
                className='printBreakAvoidInside'
                style={{ 
                    ...defaultCardStyles,
                    paddingTop:"0.5rem",
                    overflow:this.props.width !== "xs" ? "visible" : "hidden",
                }}>
                <SendsByHour errors={this.props.errors} 
                    reportData={this.props.reportData} 
                    key={"24hrsends" + this.state.campaignId} 
                    id={"24hrsends" + this.state.campaignId} 
                    minHeight="300px"
                    campaignId={this.state.campaignId}
                    hideToolbar={true}
                    dispatch={this.props.dispatch}
                    initiallyDeselectedColumns={["unsuccessful_sends", "complaints", "bounces","unsubscribes"]} />
            </Card>

            <Card elevation={defaultCardElevation} 
                className='printBreakAvoidInside'
                style={{ 
                    ...defaultCardStyles,
                    paddingTop:"0.5rem",
                }}>
                <OpenStats key={"openstats" + this.state.campaignId} campaignId={this.state.campaignId} dispatch={this.props.dispatch} by="subject" />
            </Card>

            <Card elevation={defaultCardElevation} 
                className='printBreakAvoidInside'
                style={{ 
                    ...defaultCardStyles,
                    paddingTop:"0.5rem",
                }}>
                <RecentOpenStats key={"recentopenstats" + this.state.campaignId} campaignId={this.state.campaignId} dispatch={this.props.dispatch} />
            </Card>

            
            <Card elevation={defaultCardElevation} 
                style={{ 
                    ...defaultCardStyles,
                    paddingTop:"0.5rem",
                    overflow:"visible",
                }}>
                <OpensAndClicksBySource width={this.props.width} key={"opensandclicksbysource" + this.state.campaignId} campaignId={this.state.campaignId} dispatch={this.props.dispatch} />
            </Card>

            <Card elevation={defaultCardElevation} 
                className='printBreakAvoidInside'
                style={{ 
                    ...defaultCardStyles,
                    paddingTop:"0.5rem",
                }}>
                <ClickStats key={"clickstats" + this.state.campaignId} campaignId={this.state.campaignId} dispatch={this.props.dispatch} />
            </Card>

            <Card elevation={defaultCardElevation} 
                className='printBreakAvoidInside'
                style={{ 
                    ...defaultCardStyles,
                    paddingTop:"0.5rem",
                }}>
                <RecentClickStats key={"recentclickstats" + this.state.campaignId} campaignId={this.state.campaignId} dispatch={this.props.dispatch} />
            </Card>
        </div>
    }
}



function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {
        reportData: { ...state.reportData }, //Create copy of report data
        errors: { ...state.reportErrors }, //Create copy of error data
    }
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageInsightDetails));

