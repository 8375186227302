import React, {FormEvent, MouseEvent} from 'react';
import {fetchUsers, submitUserAction} from './actions/Users'
import { connect } from "react-redux";
import {Button, Card, FormControl, Grid, IconButton, Paper, TextField, Typography} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Event from "@material-ui/icons/Event";
import {Email, Language, Person, Close, Clear} from "@material-ui/icons";
import {validateEmail, validateExists} from "./InputValidation";
import {v4 as uuidv4} from "uuid";
import {User} from "./generated/types/payloadTypes";
import {TimezoneSelect} from "./components/TimezoneSelect";
import {defaultCardElevation,defaultCardStyles} from "./App";
import moment from "moment-timezone";
import {RouteComponentProps, withRouter} from "react-router-dom";
import ListEvents from './ListEvents';
import DateTimePickerRow from './components/DateTimePickerRow';

interface IState {
    id: string,
    firstName: string,
    lastName: string,
    timezone: string,
    email: string,
    unsubscribedAt: moment.Moment,
    dob: moment.Moment,
    json: {[key:string]:any},
    placeholder: any,
    fetchedUser: boolean,
    allowValidation: boolean,
}

interface IProps extends RouteComponentProps<{ id?: string  }>  {
    dispatch: any,
    match: any,
    width: string,
    fetchedUser?: User,
}

class NewUserComponent extends React.Component<IProps, IState>  {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: this.props.match.params.id || uuidv4(),
            firstName: "",
            lastName: "",
            timezone: "",
            email: "",
            dob: null,
            unsubscribedAt: null,
            json: {},
            placeholder: {},
            fetchedUser: false,
            allowValidation: false, //Set to true on form submission
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.firstNameChange = this.firstNameChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.dobChange = this.dobChange.bind(this);
        this.timezoneChange = this.timezoneChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(!prevState.fetchedUser && nextProps.fetchedUser) {
            let dob:moment.Moment = null;
            if(nextProps.fetchedUser.date_of_birth) {
                const dateParts = nextProps.fetchedUser.date_of_birth.split("T");
                dob = moment.tz(dateParts[0] + "T11:59:59Z", "UTC");
            }
            let unsubscribedAt:moment.Moment = null
            if(nextProps.fetchedUser.unsubscribed_at) {
                unsubscribedAt = moment(nextProps.fetchedUser.unsubscribed_at)
            }

            return {
                id: nextProps.fetchedUser.id,
                email: nextProps.fetchedUser.email,
                firstName: nextProps.fetchedUser.first_name,
                lastName: nextProps.fetchedUser.last_name,
                dob,
                timezone: nextProps.fetchedUser.timezone,
                json: nextProps.fetchedUser.json,
                placeholder: prevState.placeholder,
                fetchedUser: true,
                allowValidation: prevState.allowValidation,
                unsubscribedAt,
            }
        }

        return null;
    }

    componentDidMount(){
        if(this.props.match?.params?.id) {
            this.props.dispatch(fetchUsers([this.props.match.params.id], null, null, 1,0, null, "", ""))
        }
    }

    firstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                firstName: value
            }
        })
    }
    lastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState({
            ...this.state,
            lastName: value
        })
    }
    emailChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState({
            ...this.state,
            email: value
        })
    }
    dobChange(newDate: moment.Moment) {
        this.setState({
            ...this.state,
            dob: newDate,
        })
    }

    clearTimezone() {
        this.setState({
            ...this.state,
            timezone: null,
        })
    }
    timezoneChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState({
            ...this.state,
            timezone: value
        })
    }

    static withTens(num:number) : string {
        if(num < 10) {
            return "0" + num.toString();
        }

        return num.toString();
    }


    static toDateString(d : Date) : string {
        return d.getFullYear() + "-" + NewUserComponent.withTens(d.getMonth() +1) + "-" + NewUserComponent.withTens(d.getDate());
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation()
        event.preventDefault()

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm()) {
            this.props.dispatch(submitUserAction(
                this.state.id,
                this.state.firstName,
                this.state.lastName,
                this.state.timezone,
                this.state.email,
                this.state.dob,
                this.state.json,
                () => {
                    const desiredPath = "/people/users/edit/" + this.state.id
                    if(this.props.location.pathname !== desiredPath) {
                        this.props.history.replace(desiredPath)
                    }
                }));
        }
    }

    validateForm():boolean {
        if(!this.state.email || validateEmail(this.state.email) !== "") {
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"div"} >
                        {!this.props.match?.params?.id ? "New " : "Edit " }
                        Contact
                    </Typography>
                    <hr />

                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Person />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TextField label={"First name"}
                                       autoFocus={true}
                                       style={{width:"calc(50% - 5px)",marginRight:"10px",minWidth:"200px",maxWidth:"300px"}}
                                       value={this.state.firstName}
                                       onChange={this.firstNameChange}
                            />
                            <TextField label={"Last name"}
                                       style={{width:"calc(50% - 5px)",minWidth:"200px",maxWidth:"300px"}}
                                       value={this.state.lastName}
                                       onChange={this.lastNameChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Email />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TextField label={"Email"}
                                       style={{width:"100%",minWidth:"200px",maxWidth:"610px"}}
                                       value={this.state.email}
                                       required={true}
                                       onChange={this.emailChange}
                                       type={"email"}
                                       error={this.state.allowValidation && validateEmail(this.state.email)!==""}
                                       helperText={this.state.allowValidation && validateEmail(this.state.email)!==""? validateEmail(this.state.email): ""}

                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Event />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <div style={{width:"calc(50% - 5px)",marginRight:"10px",minWidth:"200px",maxWidth:"300px"}}>
                            <DateTimePickerRow
                                isDateClearable={true}
                                disableMinDate={true}
                                dateLabel="Date of birth" 
                                dispatch={this.props.dispatch}
                                onChange={this.dobChange}
                                datetimeMoment={this.state.dob}
                                hideTimePicker={true}
                            />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item >
                            <Language />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
                            <TimezoneSelect label={"Contact's current timezone"}
                                    placeholder={"Select timezone"}
                                    style={{
                                        width:this.state.timezone ?"calc(50% - 25px)":"calc(50% - 5px)",minWidth:"200px",
                                        maxWidth:this.state.timezone ?"275px":"300px"}}
                                    timezone={this.state.timezone}
                                    timezoneChange={this.timezoneChange}
                            />
                            {this.state.timezone && <IconButton size="small" style={{color:"gray",width:"25px",marginTop:"20px"}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.clearTimezone()
                                }}><Clear /></IconButton>}  
                        </Grid>
                    </Grid>
                    {this.state.unsubscribedAt && <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item >
                            <Close style={{color:"red"}} />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TextField 
                                style={{width:"calc(50% - 5px)",marginRight:"10px",minWidth:"200px",maxWidth:"300px", borderBottom:"1px red solid"}}
                                label="Unsubscribed at" 
                                disabled={true} 
                                value={this.state.unsubscribedAt.toDate().toLocaleDateString() + " " + this.state.unsubscribedAt.toDate().toLocaleTimeString()}  />
                        </Grid>
                    </Grid>}
                    <Grid container spacing={1} alignItems="flex-end" className={"button-footer"}>
                        <Grid item >
                            <Email style={{visibility:"hidden"}} />
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    startIcon={<SaveIcon />}
                                    onClick={this.handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
                {(this.state.fetchedUser && this.props.fetchedUser.email && this.props.match?.params?.id) &&  <ListEvents buttons={{ "zoom": true, "viewemail": true }} hideSearch={true} searchType={"email"} search={this.props.fetchedUser.email} type={'list'} width={this.props.width} dispatch={this.props.dispatch}  />}
            </>
        )
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    //Find the user with the id passed in via the route
    let fetchedUser:User = null;
    if(state.users && Object.keys(state.users).length > 0) {
        if(state.users[ownProps.match?.params?.id]) {
            fetchedUser = state.users[ownProps.match?.params?.id];
        }
    }

    return {
        ...state,
        fetchedUser,
    }
 }

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewUserComponent))
