import React from 'react';
import {connect} from "react-redux";
import ListEmailBlasts from "./ListEmailBlasts";
import {clearAllButStateData} from "./actions/Common";

interface IProps {
    width: string,
    dispatch: any,
}

class PageEmailBlasts extends React.Component<IProps, null> {
    componentDidMount(): void {
        //this.props.dispatch(clearAllButStateData([["emailBlasts"], ["receivingEmailBlasts"], ["reportData"], ["receivingReportData"]]));
    }

    render() {
        return <>
            <ListEmailBlasts type='list' dispatch={this.props.dispatch} width={this.props.width} />
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageEmailBlasts)


