import React from "react";
import {showToast} from "../ToastNotifications";
import {ContactUsPayload, GenericResponsePayload,} from "../generated/types/payloadTypes";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {getAuthKey} from "./Auth"
import { CONTACT_CAPTCHA } from "./Common";

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_RESPONSE = 'CONTACT_US_RESPONSE';

type IContactUsRequest = {
  type: typeof CONTACT_US_REQUEST,
} & ContactUsPayload
type IContactUsResponse = {
  type: typeof CONTACT_US_RESPONSE,
  code: number,
} & GenericResponsePayload

export type ContactUsActionTypes = IContactUsRequest | IContactUsResponse

export function contactUsAction(payload: ContactUsPayload):IContactUsRequest {
  return {
    type: 'CONTACT_US_REQUEST',
    ...payload,
  }
}

export function contactUsResponseAction(code:number, payload: GenericResponsePayload):IContactUsResponse {
  return {
    type: 'CONTACT_US_RESPONSE',
    code,
    errors: payload.errors,
  }
}

export function contactUs(payload: ContactUsPayload) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(contactUsAction(payload));
    return contactUsPromise(dispatch, payload);
  }
}

async function contactUsPromise(dispatch:(...args:any)=>any, payload:ContactUsPayload) {
  (async (payload: ContactUsPayload) => {
    //Get sender users from api endpoint
    const url = process.env.REACT_APP_API_CONTACT_GATEWAY_URL + "/contact_us";
    console.log("POSTING TO URL",url);
    const options:any =  {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(payload),
    };
    let foundError = false;
    await fetch(url, options)
      .then(res => res.json())
      .then((json:GenericResponsePayload) => {
        if(json.errors && json.errors.length > 0) {
          showToast(<>Couldn't send contact email: {errorsToUL(json.errors)}</>, "error");
          dispatch(contactUsResponseAction(400, json));
          dispatch(requestDataErrorAction(JSON.stringify(json.errors), CONTACT_CAPTCHA));
          foundError = true;
        } else {
          dispatch(contactUsResponseAction(200, json));
        }
      }).catch(e => {
        if(e.name !== "AbortError") {
          dispatch(contactUsResponseAction(400, {errors: null}));
          dispatch(requestDataErrorAction(e.message,CONTACT_US_REQUEST));
          foundError = true;
          showToast("Couldn't send contact email: " +denastifyError(e.message), "error");
        }
      });

    if(!foundError) {
      showToast("We'll respond to your inquiry as soon as we can!", "success");
      dispatch(contactUsResponseAction(200, {errors:null}));
    }
  })(payload);
}