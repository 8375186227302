import React from 'react';
import { connect } from "react-redux";
import {Card, Paper, Typography} from "@material-ui/core";
import {LoggerS3Payload,} from "./generated/types/payloadTypes";
import {fetchEmailSendResults} from "./actions/Emails";
import {Loading} from "./components/Loading";
import {AllState} from "./reducers";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {RouteComponentProps, withRouter} from "react-router-dom";
import { decode } from 'js-base64';

interface IState {
}

interface IProps extends RouteComponentProps<{ location?: string  }>  {
    dispatch: any,
    match: any,
    receivingEmailSendResults?: boolean,
    emailSendResults?: {[key:string]: LoggerS3Payload}
    emailOnly?: boolean,
    width: string,
    style?: any,
}

class ViewEmailComponent extends React.Component<IProps, IState>  {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
        };
    }

    componentDidMount(){
        if(this.props.match?.params?.location) {
            this.props.dispatch(fetchEmailSendResults(this.props.match.params.location));
        }
    }

    decodedId():string {
        return decode(this.props.match?.params?.location)
    }

    render() {
        return (
            <>
                {!this.props.emailOnly && <>
                    <Card style={{...defaultCardStyles, ...this.props.style, minHeight:"200px"}} elevation={defaultCardElevation} >
                        <Typography variant={"h1"} component={"div"} >
                            Email Viewer
                        </Typography>
                        <hr />
                        <Typography variant={"body1"} component={"p"}>
                            Below you can see the email exactly as it was sent to your contact.
                            Unlike with most other ESPs, no interpolation or re-rendering of the template was performed to produce this view.
                        </Typography>


                        {this.props.receivingEmailSendResults && <Loading />}
                        {!this.props.receivingEmailSendResults && <div style={{width:"100%", borderWidth:"1px", padding:"4px 4px 4px 4px", borderRadius:"1rem", borderColor:"#aaa", borderStyle:"dashed", marginTop:"1rem"}}
                             dangerouslySetInnerHTML={{__html:this.props.emailSendResults[this.decodedId()]?.mailer_payload?.body || "No email body found"}} />}
                    </Card>
                </>
                }
            </>
        )
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    //Find the user with the id passed in via the route
    let fetchedSendResult = null;
    if(state.emailSendResults && Object.keys(state.emailSendResults).length > 0) {
        if(state.emailSendResults[ownProps.match.params.location]) {
            fetchedSendResult = state.emailSendResults[ownProps.match.params.location];
        }
    }

    return {
        ...state,
        fetchedSendResult,
    }
 }

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ViewEmailComponent))
