import React from 'react';
import {defaultCardElevation} from "../App";
import {Card, Typography} from "@material-ui/core";
import {pBottom, pImageStyle,blogPostCardStyle,postMetaDetails} from "./blogPosts"

interface IProps {
    dispatch?: any,
}

export function PageBlog20230630() {
    return <>
        <Card elevation={defaultCardElevation} style={blogPostCardStyle}>
            <Typography variant={"h1"} style={{textAlign:"center"}}>Most ESPs don't know what they sent</Typography>
            <Typography variant={"h3"} style={{textAlign:"center"}}>Where "hello {"{user_name}"}" issues come from and what you can do about it</Typography>
            {postMetaDetails()}
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Hello again!👋
            </Typography>
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Today I'm going to talk about
            how most email service providers (ESPs) basically just guess what they're going to send to your users,
            and how that can make analyzing your campaigns nearly impossible.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            As you may have guessed, Swift Missive saves an exact copy of your emails 
            <i> as they are sent to your contacts</i>, eliminating any guesswork and giving you a clear
            idea of what your contacts received.
            </Typography>

            <Typography variant={"h2"}>What most ESPs say they'll send</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Most email service providers like to give you a brief preview of what your rendered 
            templates will look like when they're sent.  They go ahead and fill 
            out any template variables with computer-generated data that gives the best picture of what your 
            contacts will receive.  I'd say this makes a lot of sense and is a good way to give a preview.
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
                    <img src="/images/Blog-2023-06-30-01.png" 
                        style={{width:"60%"}}
                        title="A perfectly rendered template"
                        alt="A perfectly rendered template" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                "All your emails will render this well!  Trust us!!" -- Most email service providers
            </Typography>
            
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            But when ESPs show you "what you'll send," they are often also making some incorrect assumptions 
            about what they'll know about your contacts in the future.
            Sure, they might have data for Sally and John when they fire off your email campaigns, 
            but what about Karen?  Karen might get the "hello {"{user_name}"}" treatment described
            in the next section!  Sorry, Karen.
            </Typography>

            <Typography variant={"h2"}>What your contacts might actually be seeing</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Here is the kind of email your contacts will see if your ESP doesn't 
            have the data it needs when it goes to send your emails.
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
            <img src="/images/Blog-2023-06-30-00.png" 
                        style={{width:"60%"}}
                        title="A template rendered without the data to fill out its template variables"
                        alt="A template rendered without the data to fill out its template variables" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                How your email template may have actually rendered
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Note how the variables {"{user_name}"} and so-on were left as variables and not replaced with values like "Karen" and "Hurray!"
            Template misrendering like this can happen when your contacts' data can't reach your templates at rendering time,
            because the data is stuck in a data traffic-jam.  Here is what that looks like behind the scenes at ESPs:
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
            <img src="/images/Blog-2023-06-30-04.svg" 
                        style={{width:"60%"}}
                        title="Data traffic-jams may cause data to be unavailable at rendering time"
                        alt="Data traffic-jams may cause data to be unavailable at rendering time" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                A basic overview of contact data stream feeding into the email stream
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            To illustrate what this diagram is showing, let's imagine that there's a 
            data traffic-jam from noon until 2pm, and an email blast is triggered
            at 1pm.  You contacts' data will be trying to reach the template renderer to be part
            of the email blast, but it won't be able to get there because it's stuck in the traffic-jam. 
            As a result, the template renderer is not going to have the data 
            it needs to render your email templates correctly and your contacts might receive garbled emails.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            The problem I have with most ESPs isn't so much that they suffer from 
            data traffic-jams.  It's that they pretend they don't.  Without the ability to 
            know what your contacts actually saw, you'll never know whether your email campaigns 
            are under-performing because of data issues or some other issues.  You shouldn't have to guess!
            </Typography>

            <Typography variant={"h2"}>How Swift Missive helps avoids guesswork</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            We have some experienced professionals over here who knew from the start that the email
            world was full of misleading claims about what delivered emails looked like.  And we knew 
            figuring out why 
            a campaign didn't deliver can be extremely frustrating (or even impossible) when your email 
            service provider isn't giving your straight answers about what your contacts received.  We wanted 
            your job to be easier, so we designed our system to show you the actual email you sent instead.
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
            <img src="/images/Blog-2023-06-30-05.svg" 
                        style={{width:"90%"}}
                        title="Swift Missive makes a copy of each email that is sent"
                        alt="Swift Missive makes a copy of each email that is sent" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                Swift Missive makes a copy of each email that is sent and can show it to you
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            We're not going to pretend we have infinitely fast systems over here at Swift Missive.  We don't have
            quantum computers or processors the size of buildings.  So it's possible that even our system will
            experience data traffic-jams sometimes.  But what we can guarantee is that if we do have issues rendering 
            your templates because of data traffic-jams or any other problems, <i>we will tell you about it</i> instead of just pretending 
            we sent a perfectly rendered template.  
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            We hope that by offering this additional transparency it will be easier for you, our beloved customers,
            to figure out what is going on with campaigns that are under-performing, and to adjust your workflows 
            to avoid such problems in the future.  Note that you are <i>less</i> likely to experience issues like this 
            if you use Swift Missive instead of other email service providers, we are just saying you are <i>much more </i> 
            likely to actually know if you are being affected than you would be anywhere else.
            </Typography>

            <Typography variant={"h2"}>What to do about data traffic-jams</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            We suggest these simple steps to avoid sending mangled emails, no matter what email service 
            provider you are using:
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"ol"}>
                <li>Understand the problems that can arise (e.g. data traffic-jams)</li>
                <li>Observe your sends to see if you are affected</li>
                <li>Adjust your workflows as needed</li>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            By reading this blog post you've already completed the first step!  Hurray!
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            And fortunately for you (if you are already using Swift Missive anyways), step two is even easier.  Just
            log into your account and check out the recent-sends report in the insights tab.  If you are using 
            another ESP, I'm afraid this is where I can't help you.  In fact, even your ESP 
            probably can't help you because they usually don't keep a copy of the emails they send.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Once you view some of your recent sends, you should be able to tell if your template variables 
            are being rendered correctly.  If you see some ugly template variables like the mangled email 
            above, that's also what your contact saw!  Most likely this means you are sending your ESP a lot of contact data and 
            then are firing off an email blast very shortly afterwards.  If this is the case, try waiting 
            a few hours in between the data upload and the email blast and see if you get better results.  The 
            more contact data you upload, the longer you should wait before the email blast.
            </Typography>

            <Typography variant={"h2"}>We hope this helps!</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            It's actually somewhat of a faux pas for us to reveal this type of information to our customers.  Other 
            ESPs tend to hide this kind of thing, thinking it benefits them to pretend their 
            systems will work perfectly no matter what happens.  And honestly they might be right about it benefiting them--
            not a lot of people really care to
             dig into the inner workings of their email platforms.  And by pretending they don't have these problems, 
             other ESPs don't have to figure out ways to help avoid it, or to try to explain 
            complex problems to their customers. 
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            But we strive to take a better approach.  We want our system to be as perfect  
            as possible but we won't lie to you in order 
            to make it appear that way.  Instead what we'll do is offer transparency about potential issues and
            empower you to make decisions based on facts. Only then can you work out any issues and make your 
            future campaigns more effective.  We hope this helps! 
            </Typography>
        </Card>
    </>
}
