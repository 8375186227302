import React from 'react';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom'
import { deleteAuthInfo } from './actions/Auth';
import PageLogin from './PageLogin';

interface IState {
}

interface IProps {
    width: string,
    dispatch: any,
}

class PageLogout extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
        };
        
        deleteAuthInfo()
        window.location.href="/login"
    }

    render() {
        return <>
            <PageLogin width={this.props.width} dispatch={this.props.dispatch} />
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageLogout);
