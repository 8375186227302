import React from 'react';
import {connect} from "react-redux";
import ListSegments from "./ListSegments";
import {clearAllButStateData} from "./actions/Common";
import { Card, Grid, Typography, Fab } from '@material-ui/core';
import { Add, FileCopyOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { defaultCardStyles, defaultCardElevation } from './App';

interface IProps {
    dispatch: any,
    width: string,
}

class PageSegments extends React.Component<IProps, null> {
    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([
            ["segments"],
            ["receivingSegments"],
        ]));
    }

    render() {
        return  <Card style={defaultCardStyles} elevation={defaultCardElevation}>
            <Grid container>
                <Grid item>
                    <Typography variant={"h1"} component={"div"}>Segments</Typography>
                </Grid>
                <Grid item style={{marginLeft:"10px"}}>
                <Link to={"/people/segments/new/bulk/2"} title='Upload contact list'>
                        <Fab size={"large"} color={"primary"} aria-label={"Upload contact list"}>
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                            >
                                <Add style={{marginLeft:"-5px"}} />
                                <FileCopyOutlined  style={{marginLeft:"-5px"}}/>
                            </span>
                        </Fab>
                    </Link>
                    <Link to={"/people/segments/new"} style={{marginLeft:"0.5rem"}} title='Add a blank segment'>
                        <Fab size={"small"} color={"secondary"} aria-label={"Add a blank segment"}>
                            <Add  />
                        </Fab>
                    </Link>
                </Grid>
            </Grid>
            <hr />
            <ListSegments buttons={{"edit":true}} type='list' {...this.props} />
        </Card>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageSegments)

