import React from 'react';
import {requestDataErrorAction, errorsToUL, denastifyError} from "./actions/Common";
import {GetUploadUrlResponsePayload} from "./generated/types/payloadTypes";
import {showToast} from "./ToastNotifications";
import {getAuthKey, getGatewayUrl} from "./actions/Auth";
import { useLocation } from 'react-router-dom';

export async function fetchS3BulkUploadUrl(dispatch: (...args: any) => any, filename: string, fileType: string): Promise<string> {
    const url = getGatewayUrl("segmentwrapper") + "/segments/upload_url?filename=" + encodeURIComponent(filename) + "&type=" + encodeURIComponent(fileType);
    console.log(url);
    return await fetch(url, {
        method: 'get',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': getAuthKey(),
        }
    })
        .then(res => res.json())
        .then((json: GetUploadUrlResponsePayload) => {
            if (json.errors && json.errors.length > 0) {
                dispatch(requestDataErrorAction(JSON.stringify(json.errors), "s3uploadurl"));
                showToast(<>Couldn't fetch upload url: {errorsToUL(json.errors)}</>, "error");
                return "";
            }

            if (typeof json.data !== "string") {
                return "";
            }

            return json.data;
        }).catch(e => {
            dispatch(requestDataErrorAction(e.message, "s3uploadurl"));
            showToast(`Couldn't fetch upload url: ${denastifyError(e.message)}`, "error");
            return "";
        });
}

export async function fetchS3TemplateImageUploadUrl(dispatch: (...args: any) => any, filename: string, fileType: string): Promise<string> {
    const url = getGatewayUrl("template") + "/templates/images/upload_url?filename=" + encodeURIComponent(filename) + "&type=" + encodeURIComponent(fileType);
    console.log(url);
    return await fetch(url, {
        method: 'get',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': getAuthKey(),
        }
    })
        .then(res => res.json())
        .then((json: GetUploadUrlResponsePayload) => {
            if (json.errors && json.errors.length > 0) {
                dispatch(requestDataErrorAction(JSON.stringify(json.errors), "s3templateimageuploadurl"));
                showToast(<>Couldn't fetch image upload url: {errorsToUL(json.errors)}</>, "error");
                return "";
            }

            if (typeof json.data !== "string") {
                return "";
            }

            return json.data;
        }).catch(e => {
            dispatch(requestDataErrorAction(e.message, "s3templateimageuploadurl"));
            showToast(`Couldn't fetch image upload url: ${denastifyError(e.message)}`, "error");
            return "";
        });
}

export function hashEquals(s: string):boolean {
    const hash = getHash()
    return hash.toLowerCase() == s.toLowerCase()
}

export function getHash():string {
    const { hash } = useLocation()
    return hash
}

export function redactSensitiveInfoForDisplay(jsonObject: any): any {
    if(!jsonObject) {
        return jsonObject
    }

    const sensitiveKeys: {[key:string]:boolean} = {
        "company_name": true,
        "project_name": true,
        "companyname": true,
        "projectname": true,
        "bucket": true,
        "key": true,
        "password": true,
        "pass": true,
    }

    const newObject:any = {}
    const keys = Object.keys(jsonObject) 
    for(let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const lowerKey =  key.toLowerCase()

        //Recurse if needed
        if(typeof jsonObject[key] == "object") {
            jsonObject[key] = redactSensitiveInfoForDisplay(jsonObject[key])
        }

        if(!sensitiveKeys[lowerKey]) {
            newObject[key] = jsonObject[key]
        }


    }

    return newObject
}

export function reduceArraysToLength(arrayLength: number, ...arrays: any[][]): any[][] {
    for(let i = 0; i < arrays.length; i++) {
        arrays[i] = arrays[i].slice(0, arrayLength)    
    }

    return arrays
}