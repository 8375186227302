import React, {FormEvent, MouseEvent} from "react";
import {
    Button,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {connect} from "react-redux";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {addDomainAction, fetchDKIMSettings} from "./actions/Settings";
import {Loading} from "./components/Loading";
import {validateDomain} from "./InputValidation";
import {Add, AssignmentOutlined, CheckCircle, Error, FileCopy, HelpOutline, HourglassEmpty} from "@material-ui/icons";
import { GetDKIMInfoResponsePayload } from "./generated/types/payloadTypes";
import { showToast } from "./ToastNotifications";

interface IState {
    allowValidation: boolean,
    domain: string,
}

interface IProps {
    dispatch: any,
    receivingDKIMSettings?: boolean,
    dkimSettings?: GetDKIMInfoResponsePayload,
    width: string,
    addingDomain?: boolean,
}

class PageDomains extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.state = {
            allowValidation: false,
            domain: "",
        };
        this.props = props;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.domainChange = this.domainChange.bind(this);
    }

    validateForm(state: IState):boolean {
        if(validateDomain(state.domain) !== "") {
            return false;
        }

        return true;
    }


    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            this.props.dispatch(addDomainAction(this.state.domain));

            this.setState({
                ...this.state,
                domain: "",
                allowValidation: false,
            })
        }
    }

    domainChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                domain: value
            }
        })
    }

    componentDidMount() {
        this.props.dispatch(fetchDKIMSettings());
    }

    copyToClipboard(text: string)  { 
        navigator.clipboard.writeText(text)
        showToast("Copied to clipboard!", "success", {
            hideProgressBar: true,
            autoClose: 1000,
            closeButton: false,
        })
    }

    render() {
        const hasDomains = (!this.props.receivingDKIMSettings && this.props.dkimSettings?.domains && Object.keys(this.props.dkimSettings.keys).length > 0)
        return <>
            <Card elevation={defaultCardElevation}  style={{...defaultCardStyles}}>
                <Typography variant={"h1"}>Domain Settings</Typography>
                <hr />
                <Typography variant={"h2"} style={{marginTop:"1rem"}}>Introduction to verifying your domains</Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    <b>Please use the instructions on this page to
                    verify your domains before you send more than 100 emails!  It will help to protect
                    your sender reputation and to make sure your emails don't end up in the spam folder!</b>
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    Verifying your domains tells the internet who you are, like a login for a website.  
                    There are three ways to verify your domains:
                </Typography>
                <Typography variant={"body1"} component={"ol"} style={{marginTop:"1rem"}}>
                    <li><u>DMARC</u> - Tells email receivers what to do if an email doesn't look like it came from you</li>
                    <li><u>DKIM</u> - Authenticates the domain you are sending from (like a login, but for your sending domain)</li>
                    <li><u>SPF</u> - Validates the servers your emails are coming from.  You don't need to do anything for this</li>
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    Since we don't have control over your domain, we'll need you to go into your DNS settings and get
                    DMARC and DKIM set up.  Don't worry about SPF, though--since we control the servers your emails
                    are sent from, we'll take care of that one for you.  {!hasDomains && "Let's get started!"}
                </Typography>
                <Typography variant={"h2"} style={{marginTop:"1rem"}}>
                    What domains will you be sending from?
                </Typography>
                {hasDomains && <>
                    <Typography variant={"h3"} component={"p"} style={{marginTop:"1rem"}}>
                        Currently registered domains
                    </Typography>
                    <Typography variant={"body1"} component={"div"} style={{marginTop:"0.5rem"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell><b>SPF</b></TableCell>
                                    <TableCell><b>DKIM</b></TableCell>
                                    <TableCell><b>DMARC</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {Object.keys(this.props.dkimSettings.domains).map(domain => <TableRow key={"row"+domain}>
                                    <TableCell style={{fontSize:"12pt"}}><b>{domain}</b></TableCell>
                                    <TableCell><CheckCircle color={"primary"} style={{marginBottom:"-5px"}} /> Passed</TableCell>
                                    <TableCell>{
                                        this.props.dkimSettings.domains[domain].DkimVerificationStatus === "Success" ? 
                                            <span>
                                                <CheckCircle color={"primary"} style={{marginRight:"5px", marginBottom:"-5px"}}/> 
                                                Passed
                                            </span> :
                                        this.props.dkimSettings.domains[domain].DkimVerificationStatus === "Failed" ?
                                            <span style={{alignContent:"flex-end", alignItems:"flex-end", justifyContent:"flex-end"}}>
                                                <Error color="error" style={{marginRight:"5px", marginBottom:"-5px"}} />
                                                DNS settings not found<br />
                                                (For setup instructions, see below)
                                            </span>
                                        : <><HourglassEmpty style={{marginRight:"5px", marginBottom:"-5px"}} />In progress</>
                                    } </TableCell>
                                    <TableCell>
                                        <span title="We can't verify this automatically yet. Please use this 3rd party tool to make sure you have a valid DMARC record!">
                                            <HelpOutline style={{marginRight:"5px", marginBottom:"-5px"}} /> 
                                            <a href={"https://mxtoolbox.com/SuperTool.aspx?action=dmarc%3a" + encodeURIComponent(domain) +"&run=toolpage"} 
                                                target="_blank" 
                                                style={{color:"#777"}}>
                                                    Check with 3rd party tool
                                            </a><br />
                                            (For setup instructions, see below)
                                        </span>
                                    </TableCell>
                                </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Typography>
                </>}
                {hasDomains && <Typography variant={"h3"} style={{marginTop:"2rem"}}>
                    Add a domain
                </Typography>}
                <form onSubmit={this.handleSubmit}>
                    <Grid container wrap={"nowrap"} alignItems={"flex-start"} style={{marginLeft:"10px"}}>
                        <Grid item>
                            <TextField label={"Enter your sending domain"}
                               style={{minWidth:"300px"}}
                               placeholder={"yourdomain.com"}
                               value={this.state.domain}
                               onChange={this.domainChange}
                               error={this.state.allowValidation && validateDomain(this.state.domain)!==""}
                               helperText={this.state.allowValidation && validateDomain(this.state.domain)!==""? validateDomain(this.state.domain): ""}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"}
                                style={{marginLeft:"5px", marginTop:"12px"}}
                                color={"primary"}
                                type={"submit"}
                                startIcon={<Add />}
                                disabled={!!this.props.addingDomain}
                                onClick={this.handleSubmit}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Typography variant={"h1"} style={{marginTop:"2rem"}}>
                    <b>Domain Setup</b>
                </Typography>
                <Typography variant={"body1"} component="p"style={{marginTop:"1rem"}}>
                    After telling us what domains
                    you'll be sending email from, you'll need to access the
                    DNS records for your domain in order to install the keys listed below and verify domain ownership.  These DNS
                    records are managed by your domain registrar and/or hosting provider and should be available via
                    their website.
                </Typography>
                <Typography variant={"body1"} style={{marginTop:"1rem"}}>
                    Here are some links that might help if you get stuck.
                    <ul>
                        <li><a target="_blank" href={"https://support.cloudflare.com/hc/en-us/articles/200169046-How-do-I-add-a-CNAME-record-"} rel="noreferrer">Cloudflare -- How do I add a CNAME record</a></li>
                        <li><a target="_blank" href={"https://help.dreamhost.com/hc/en-us/articles/215414867-How-do-I-add-custom-DNS-records-"} rel="noreferrer">Dreamhost -- How do I add a custom DNS record</a></li>
                        <li><a target="_blank" href={"https://www.godaddy.com/help/add-a-cname-record-19236"} rel="noreferrer">GoDaddy -- Add a CNAME record</a></li>
                        <li><a target="_blank" href={"https://www.hostgator.com/help/article/manage-dns-records-with-hostgatorenom"} rel="noreferrer">HostGator -- Manage DNS Records with HostGator/eNom</a></li>
                        <li><a target="_blank" href={"https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237/how-do-i-add-txtspfdkimdmarc-records-for-my-domain"} rel="noreferrer">Namecheap -- How do I add TXT/SPF/DKIM/DMARC records for my domain?</a></li>
                        <li><a target="_blank" href={"https://www.names.co.uk/support/1156-changing_your_domains_dns_settings.html"} rel="noreferrer">Names.co.uk -- Changing your domains DNS Settings</a></li>
                        <li><a target="_blank" href={"https://support.wix.com/en/article/adding-or-updating-cname-records-in-your-wix-account"} rel="noreferrer">Wix -- Adding or Updating CNAME Records in Your Wix Account</a></li>
                    </ul>
                </Typography>
                <Typography variant={"h3"} style={{marginTop:"1.5rem"}}>
                    DMARC
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    Add these keys to your DNS settings for each domain you have.  These will tell email receiving hosts (gmail, etc) what to do
                    if they receive email from someone pretending to be you.  If you find you already have a DMARC record 
                    in your DNS settings, please leave it as is--Swift Missive can work with any valid DMARC record in place.
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    We'll be using the same keys and values for each domain in this case.  Once you think you have your keys set up
                    correctly, please use the provided link to check your DMARC setup with a 3rd party tool.
                </Typography>
                {(!this.props.receivingDKIMSettings && (!this.props.dkimSettings?.domains || Object.keys(this.props.dkimSettings.keys).length == 0)) && <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem", marginBottom:"1rem"}}>
                    No domains have been added to your account yet.  This area will populate with keys once you add a domain.
                </Typography>}
                {(!this.props.receivingDKIMSettings && this.props.dkimSettings?.domains && Object.keys(this.props.dkimSettings.keys).length > 0) &&
                    Object.keys(this.props.dkimSettings.domains).map(domain => {
                        return <ul key={"keysfordmarc"+domain}>
                            <li key={"keysforlidmarc"+domain} style={{marginBottom:"1rem"}}>
                                <span style={{marginRight:"15px"}}>
                                    <span style={{marginRight:"5px"}}><b>{domain}</b></span> 
                                    DNS records
                                </span>
                                <span title="We can't verify this automatically yet. Please use this 3rd party tool to make sure you have a valid DMARC record!">
                                    <HelpOutline style={{marginRight:"5px", marginBottom:"-5px"}} /> 
                                    <a href={"https://mxtoolbox.com/SuperTool.aspx?action=dmarc%3a" + encodeURIComponent(domain) +"&run=toolpage"} 
                                        target="_blank" 
                                        style={{color:"#777"}}>
                                            Check status with 3rd party tool
                                    </a>
                                </span>
                                
                                <TableContainer key={"dmarctable"+domain}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>DNS Record Type</b></TableCell>
                                                <TableCell><b>Key</b></TableCell>
                                                <TableCell><b>Value</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        <TableRow key={"dmarktablerowvalues" + domain}>
                                            <TableCell>
                                                TXT
                                            </TableCell>
                                            <TableCell>
                                                    <AssignmentOutlined style={{cursor:"hand", marginBottom:"-5px", marginRight:"5px"}} 
                                                    onClick={() => this.copyToClipboard("_dmarc")}/>
                                                    _dmarc
                                            </TableCell>
                                            <TableCell>
                                                <AssignmentOutlined style={{cursor:"hand", marginBottom:"-5px", marginRight:"5px"}}  
                                                    onClick={() => this.copyToClipboard("v=DMARC1; p=none;")}
                                                />
                                                v=DMARC1; p=none;
                                            </TableCell>
                                        </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </li>
                        </ul>
                })}
                <Typography variant={"h3"} style={{marginTop:"1rem"}}>
                    DKIM
                </Typography>
                {(!!this.props.receivingDKIMSettings) && <Loading style={{marginTop:"10px", marginBottom:"10px"}} />}
                {(!this.props.receivingDKIMSettings && this.props.dkimSettings?.keys && Object.keys(this.props.dkimSettings.keys).length > 0) && 
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    DKIM keys for each of your domains are listed here.  You'll need to
                    log into your domain registrar / hosting provider's web site and access your DNS settings
                    in order to put these keys in place.  Once you've
                    accessed your domains DNS settings, add one DNS record for each of the rows below.
                    We will pick up the keys automatically once you have them in place in your DNS settings!
                </Typography>}
                {(!this.props.receivingDKIMSettings && (!this.props.dkimSettings?.domains || Object.keys(this.props.dkimSettings.keys).length == 0)) && <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem", marginBottom:"1rem"}}>
                    No domains have been added to your account yet.  This area will populate with keys once you add a domain.
                </Typography>}
                {(!this.props.receivingDKIMSettings && this.props.dkimSettings?.domains && Object.keys(this.props.dkimSettings.keys).length > 0) &&
                    Object.keys(this.props.dkimSettings.domains).map(domain => {
                        return <ul key={"keysfor"+domain}>
                            <li key={"keysforli"+domain} style={{marginBottom:"1rem"}}>
                                <span style={{marginRight:"15px"}}>
                                    <span style={{marginRight:"5px"}}><b>{domain}</b></span> 
                                    DNS records
                                </span>
                                {
                                    this.props.dkimSettings.domains[domain].DkimVerificationStatus === "Success" ? 
                                        <span>
                                            <CheckCircle color={"primary"} style={{marginRight:"5px", marginBottom:"-5px"}}/> 
                                            Passed
                                        </span> :
                                    this.props.dkimSettings.domains[domain].DkimVerificationStatus === "Failed" ?
                                        <span style={{alignContent:"flex-end", alignItems:"flex-end", justifyContent:"flex-end"}}>
                                            <Error color="error" style={{marginRight:"5px", marginBottom:"-5px"}} />
                                            DNS settings not found<br />
                                        </span>
                                    : <><HourglassEmpty style={{marginRight:"5px", marginBottom:"-5px"}} />Check in-progress</>
                                }
                                <TableContainer key={"dkimtable"+domain}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>DNS Record Type</b></TableCell>
                                                <TableCell><b>Key</b></TableCell>
                                                <TableCell><b>Value</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.dkimSettings?.keys[domain]?.map((key:string) => <TableRow key={"row_" + key}>
                                                <TableCell>
                                                    CNAME
                                                </TableCell>
                                                <TableCell style={{whiteSpace:"nowrap"}}>
                                                    <AssignmentOutlined style={{cursor:"hand", marginBottom:"-5px", marginRight:"5px"}} 
                                                        onClick={() => this.copyToClipboard(key + "._domainkey." + domain)}/>
                                                        {key + "._domainkey." + domain}
                                                </TableCell>
                                                <TableCell style={{whiteSpace:"nowrap"}}>
                                                    <AssignmentOutlined style={{cursor:"hand", marginBottom:"-5px", marginRight:"5px"}} 
                                                        onClick={() => this.copyToClipboard(key + ".dkim.amazonses.com")}/>
                                                    {key + ".dkim.amazonses.com"}
                                                </TableCell>
                                            </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </li>
                        </ul>
                })}
            </Card>
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageDomains)
