import React from 'react';
import {connect} from "react-redux";
import Handlebars from "handlebars";
import {defaultUser, defaultEvent, defaultSenderPersona} from './TemplateVariables'

interface IProps {
    html:string,
    style?: any,
}

class PreviewTemplate extends React.Component<IProps, null> {
    renderHandlebarsHTML(html:string):string {
        const template = Handlebars.compile(html);
        const htmlString = template({
            user: defaultUser,
            event: defaultEvent,
            sender_persona: defaultSenderPersona,
            unsubscribe_link: "https://unsubscribe.swiftmissive.com/unsubscribe/eyJjbQ==",
        });
        return htmlString
    }

    render() {
        return <div dangerouslySetInnerHTML={{__html:this.renderHandlebarsHTML(this.props.html)}} style={this.props.style} />
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PreviewTemplate)