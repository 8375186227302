import {UserFilter, filterMap as baseMap} from "../generated/types/payloadTypes"

const newBaseMap = {...baseMap}
delete newBaseMap["num_in_segment"] //We will specifically translate to/from is_in_segment for this key

//@TODO support these attributes
delete newBaseMap["contact_state"]
delete newBaseMap["contact_city"]
delete newBaseMap["contact_json"]
delete newBaseMap["date_workflow_completed"]
delete newBaseMap["date_workflow_started"]

export const filterMap:{[key:string]:{allowed_operators:string[], query_fields: [""], join_tables: [""], value_types: string[], operators_in_having_clause:boolean}} = {
    ...newBaseMap,
    "is_in_segment": {
        allowed_operators:[],
        value_types: ["segment_id"],
        join_tables: [""],
        query_fields: [""],
        operators_in_having_clause: false,
    }
}
export const allowEmptyOperatorMap:{[key:string]:boolean} = {
    "is_in_segment": true,
}

export function translateFilters(originalFilters:UserFilter[]): UserFilter[] {
    const isDate = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.?\d?\d?\d?Z$/
    const newFilters:UserFilter[] = []
    const filtersCopy = JSON.parse(JSON.stringify(originalFilters)) as UserFilter[]
    filtersCopy.map(f => {
        if(f.attribute == "is_in_segment") {
            f.attribute = "num_in_segment"
            f.operator = "greater_than"
            f.values = [0, f.values[0]] //0, segment_id
        }

        //Look for date values and turn them into utc 00:00:00 instead of utc 04:00:00 etc
        if(f.values) {
            for(let i = 0; i < f.values.length; i++) {
                if(isDate.test(f.values[i].toString())) {
                    const newDateParts = f.values[i].toString().split("T")
                    f.values[i] = newDateParts[0] + "T00:00:00Z"                    
                }
            }
        }

        newFilters.push(f)
    })

    return newFilters
}