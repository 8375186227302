import React from "react";
import { connect } from "react-redux";
import { AllState } from "../../reducers";
import { PieChart } from 'react-minimal-pie-chart'
import Slider from '@material-ui/core/Slider';
import { Template } from "../../generated/types/payloadTypes";
import { isArray } from "lodash";
import { Typography } from "@material-ui/core";

interface IProps {
    width: string,
    dispatch: any,
    numTemplates: number,
    templates?: {[key:string]:Template},
    selectedTemplateIds: string[],
    selectedTemplateWeights: number[],
    onSelectedTemplatWeightsChange: (selectedTemplateWeights: number[]) => void,
}
interface IState {
}
const colors = [
    "#5c61ff",
    "#ff5cb2",
    "#4cff98",
    "#5cb2ff",
    "#33aa65",
    "#ffba3c",
]
const darkerColors = [
    "#00059f",
    "#9f0352",
    "#009f38",
    "#02529f",
    "#004a05",
    "#9f5a00",
]

class SelectTemplateWeights extends React.Component<IProps, IState> {
    state:IState
    props:IProps

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {}

        this.getLabelStyle = this.getLabelStyle.bind(this)
        this.getData = this.getData.bind(this)
    }

    getLabelStyle(i:number):any {
        let color = darkerColors[i % darkerColors.length]
        if((i % darkerColors.length) === 0 && i === this.props.numTemplates -1) {
            color = darkerColors[1]
        }

        return {
            fill: color,
            fontSize: '4pt',
            fontFamily: 'sans-serif',
        }
    }

    getData():{title:string, value:number, color:string}[] {
        const data:{title:string, value:number, color:string}[] = []
        for(let i = 0; i < this.props.numTemplates; i++) {
            
            let color = colors[i % colors.length]
            if((i % colors.length) === 0 && i === this.props.numTemplates -1) {
                color = colors[1]
            }
            
            let title = "Template " + (i + 1).toString()
            if(this.props.templates && this.props.templates[this.props.selectedTemplateIds[i]]) {
                title += " " + this.props.templates[this.props.selectedTemplateIds[i]].name
            }
            if(this.props.width === "xs") {
                title = this.truncateText(title, 35)
            } else if(this.props.width === "sm") {
                title = this.truncateText(title, 50)
            } else {
                title = this.truncateText(title)
            }

            const value = this.props.selectedTemplateWeights[i] 

            data.push({title, value, color}) 
        }

        return data
    }

    truncateText(t: string, length = 60): string {
        const ellipses = t.length > length ? "...":""
        return t.substring(0, length) + ellipses
    }

    getSliders():JSX.Element[] {
        const sliders:JSX.Element[] = []
        const data = this.getData()
        for(let i = 0; i < this.props.numTemplates; i++) {
            if(i >= data.length) {
                console.error("missing data somehow")
                continue
            }

            sliders.push(<>
                <Typography variant="body1" component="span" style={{color:data[i].color, whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}}>
                    {data[i].title}
                </Typography>    
                <Slider
                    value={data[i].value}
                    min={0}
                    max={100}
                    step={1}
                    valueLabelDisplay="auto"
                    style={{color:data[i].color}}
                    onChange={(event: any, value: number | number[]) => {
                        const selectedTemplateWeights = [...this.props.selectedTemplateWeights]
                        if(isArray(value)) {
                            if(value.length >= 1) {
                                selectedTemplateWeights[i] = value[0]
                            } 
                        } else {
                            selectedTemplateWeights[i] = value
                        }
                        this.props.onSelectedTemplatWeightsChange(selectedTemplateWeights)
                    }}
                />
            </>)
        }

        return sliders
    }

    getTotalWeight():number {
        let weight = 0 
        this.props.selectedTemplateWeights.map(w => weight += w)
        return weight
    }

    render() {
        

        return <>
                <Typography variant="h2" component="p">
                    How often should we use each template?
                </Typography>
                <div>
                    <div style={{width:"100%", display:"flex",justifyContent:"center"}}>
                        <div style={{width:"100%", minWidth:"300px", maxWidth:"500px", }}>
                            <PieChart 
                                style={{overflow:"visible"}}
                                label={({ dataEntry }) => dataEntry.percentage > 0 ? this.truncateText(dataEntry.title,20) + " " + (Math.round(dataEntry.percentage*10)/10) + "%" : ""}
                                labelPosition={70}
                                labelStyle={this.getLabelStyle}
                                data={this.getData()} />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{width:"100%", display:"flex",justifyContent:"center"}}>
                        <div style={{width:"100%", minWidth:"300px", maxWidth:"500px" }}>
                        {this.getSliders()}
                        </div>
                    </div>
                </div>
            </>
    }
}


function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectTemplateWeights)
