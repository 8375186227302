import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  GetSenderPersonasResponsePayload,
  SenderPersona,
  UpsertSenderPersonasPayload,
  UpsertSenderPersonasResponsePayload
} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_SENDER_PERSONAS = 'REQUEST_SENDER_PERSONAS';
export const RECEIVE_SENDER_PERSONAS = 'RECEIVE_SENDER_PERSONAS';
export const SELECT_SENDER_PERSONA = 'SELECT_SENDER_PERSONA';
export const REQUEST_NEW_SENDER_PERSONA = 'REQUEST_NEW_SENDER_PERSONA';
export const REQUEST_NEW_SENDER_PERSONA_RESPONSE = 'REQUEST_NEW_SENDER_PERSONA_RESPONSE';
interface IRequestSenderPersonasAction {
  type: typeof REQUEST_SENDER_PERSONAS,
}
interface IReceiveSenderPersonasAction {
  type: typeof RECEIVE_SENDER_PERSONAS,
  data: SenderPersona[],
}
interface ISelectSenderPersonaAction {
  type: typeof SELECT_SENDER_PERSONA,
  selectedPersonaId: string
}
interface IRequestNewSenderPersonaAction {
  type: typeof REQUEST_NEW_SENDER_PERSONA,
}
interface IRequestNewSenderPersonaResponseAction {
  type: typeof REQUEST_NEW_SENDER_PERSONA_RESPONSE,
  senderPersona: SenderPersona,
}
export type SenderPersonaActionTypes = IRequestSenderPersonasAction | IReceiveSenderPersonasAction 
  | ISelectSenderPersonaAction | IRequestNewSenderPersonaAction | IRequestNewSenderPersonaResponseAction;

export function requestSenderPersonas():IRequestSenderPersonasAction {
  return {
    type: REQUEST_SENDER_PERSONAS
  }
}

export function receiveSenderPersonas(data: any):IReceiveSenderPersonasAction {
  return {
    type: RECEIVE_SENDER_PERSONAS,
    data
  }
}

export function selectSenderPersonaAction(selectedPersonaId: string):ISelectSenderPersonaAction {
  return {
    type: SELECT_SENDER_PERSONA,
    selectedPersonaId
  }
}

export function requestNewSenderPersona():IRequestNewSenderPersonaAction {
  return {
    type: REQUEST_NEW_SENDER_PERSONA
  }
}

export function requestNewSenderPersonaResponse(senderPersona: SenderPersona):IRequestNewSenderPersonaResponseAction {
  return {
    type: REQUEST_NEW_SENDER_PERSONA_RESPONSE,
    senderPersona,
  }
}


export function fetchSenderPersonas(ids:string[] = []) {
  return function(dispatch: any) {
    dispatch(requestSenderPersonas);
    return fetchSenderPersonasPromise(dispatch, ids)
  }
}

export function submitSenderPersonaAction(id:string, firstName:string, lastName:string, email:string, json:string,
  onSuccess?:(c:SenderPersona) => any, onFailure?:(reason:string) => any) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNewSenderPersona());
    return postSenderPersonaPromise(dispatch, id, firstName, lastName, email, json, onSuccess, onFailure)
  }
}

function fetchSenderPersonasPromise(dispatch:(...args:any)=>any, ids:string[] = []) {
  dispatch(requestSenderPersonas());

  //Get sender personas from api endpoint
  let url = getGatewayUrl("senderpersonawrapper") + "/sender_personas";
  if(ids && ids.length > 0) {
    url += "?ids=" + ids.join(",");
  }
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetSenderPersonasResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_SENDER_PERSONAS));
      showToast(<>Couldn't fetch sender personas: {errorsToUL(json.errors)}</>, "error");
    }

    if(json.data && json.data.length) {
      console.log("Found " + json.data.length + " sender personas");
      dispatch(receiveSenderPersonas(json.data))
    } else {
      dispatch(receiveSenderPersonas(null));
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_SENDER_PERSONAS));
    showToast(`Couldn't fetch sender personas: ${denastifyError(e.message)}`, "error");
  });
}

function postSenderPersonaPromise(dispatch:(...args:any)=>any, id:string, firstName:string, lastName:string, email:string, json:string,
  onSuccess?:(c:SenderPersona) => any, onFailure?:(reason:string) => any) {

  //Get sender templates from api endpoint
  const url = getGatewayUrl("senderpersonawrapper") + "/sender_personas";
  console.log("POSTING TO URL",url);
  const data:UpsertSenderPersonasPayload = {
    sender_personas: [{
      id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      json: json? JSON.parse(json) : null,
    }]
  };
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:UpsertSenderPersonasResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      if(onFailure) {
        onFailure(denastifyError(JSON.stringify(json.errors)))
      } else {
        showToast(<>Couldn't save sender persona: {errorsToUL(json.errors)}</>, "error");
      }
    } else {
      if(json.data && json.data.length > 0) {
        if(onSuccess) {
          onSuccess(json.data[0])
        } else {
          dispatch(requestNewSenderPersonaResponse(json.data[0]))
          showToast("Successfully saved sender persona", "success");
        }
      }
    }
  }).catch(e => {
    if(onFailure) {
      onFailure(denastifyError(JSON.stringify(e.message)))
    } else {
      showToast(`Couldn't save sender persona: ${denastifyError(e.message)}`, "error");
    }
  })
}