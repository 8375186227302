import React from 'react';
import {connect} from "react-redux";
import ListTemplates from "./ListTemplates";
import {clearAllButStateData} from "./actions/Common";

interface IProps {
    dispatch: any,
    width: string,
    templatesListKey?:string,
}

class PageTemplates extends React.Component<IProps, null> {
    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([["templates"],["receivingTemplates"]]));
    }

    render() {
        return   <>
            <ListTemplates  type='list' {...this.props} />
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageTemplates)

