import React from 'react';
import {Button,Card,Typography,Grid} from "@material-ui/core";
import {defaultCardElevation,defaultCardStyles} from "./App";
import { Toll,ImageSearch, Security, Schedule, CallSplit, Image, TrendingUp, Stop, PostAdd, Email, EmojiPeople, Fireplace  } from '@material-ui/icons';
import {Link,useLocation} from "react-router-dom"
import ContactUsForm from './ContactUs'

function isLarge(width: string) {
    switch(width) {
        case "xs":
        case "sm":
            return false;
    }

    return true;
}

function actionButtonRow(): JSX.Element {
    let contactLink = "/about/contact-us"
    if(useLocation().pathname == contactLink) {
        contactLink += "/2"
    }

    return <div>
        <Link to="/demo-login">
            <Button variant={"outlined"}
                    color={"primary"}
                    type={"submit"}
                    style={{marginRight:"0.5rem"}}>
                Try the live demo
            </Button>
        </Link>
        <Link to={contactLink}>
            <Button variant={"outlined"}
                    color={"primary"}
                    type={"submit"}>
                Contact us
            </Button>
        </Link>
    </div>
}

export function PageAbout(props: {width: string, dispatch:any, focusContactName?: boolean}) {
    const containerStyle:React.CSSProperties ={
        minHeight:"200px",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        alignContent:"center",
        textAlign:"center",
        paddingLeft:"1rem",
        paddingRight:"1rem",
    }
    const textStyle:React.CSSProperties ={
        padding:"2rem",
    }
    const imageStyle:React.CSSProperties ={
        width:"100%",
        borderRadius:"5px",
    }
    const liStyle:React.CSSProperties={
        minHeight:"50px", 
        display: "flex",
        justifyContent: "top",
        alignItems:"top",
        alignContent:"top"
    }
    const iconStyle:React.CSSProperties = {
        width:"40px",
        height:"40px",
        marginRight:"10px"
    }

 

    return <>
        <Card elevation={defaultCardElevation} style={{
            ...defaultCardStyles, 
            paddingBottom:"2rem",
            paddingTop:"1rem"
        }}>
        <Grid container>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2} style={{
                display: "flex",
                justifyContent: "center",
                alignItems:"center",
                alignContent:"center",
                paddingLeft:"1%"}}>
                <img src="/logo.svg"
                    style={{
                        width:"8rem", 
                    }}  />
            </Grid>
            <Grid item xs={9} sm={9} md={10} lg={10} xl={10}>
                <Typography variant={"h1"} style={{
                    fontSize: props.width=="xs"? "4.5rem":  isLarge(props.width)?"6rem":"5rem", 
                    paddingLeft: props.width=="xs"? "1.5rem":"0.8rem", 
                    paddingTop:"0.6rem"
                }}>
                Swift Missive
                </Typography>
            </Grid>
        </Grid>
        <hr style={{marginTop:"1.5rem" }}/>
        {actionButtonRow()}
        
        <Typography variant='h1' component={"p"} style={{marginTop:"1.5rem", marginBottom:"1.5rem"}}> 
            An email platform to help you...
        </Typography>

        <Grid container spacing={4} style={containerStyle}>
            {(props.width !=="xs" && props.width !=="sm") &&<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography variant={"h2"} style={textStyle}> 
                    Form lasting relationships with your customers
                </Typography>
            </Grid>}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img src="/images/ad_asset_man_with_megaphone3.jpg"
                        style={imageStyle}  />
            </Grid>
            {(props.width=="xs" || props.width=="sm") && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant={"h2"} style={isLarge(props.width) ? {} : {marginTop:"2rem"}}> 
                    Form lasting relationships with your customers
                </Typography>
            </Grid>}
        </Grid>

        <Grid container style={containerStyle}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img src="/images/ad_asset_running_pupper_with_letters.jpg"
                        style={imageStyle}  />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant={"h2"} style={textStyle}> 
                    Drive traffic to your website
                </Typography>
            </Grid>
        </Grid>

        <Grid container style={containerStyle}>
            {(props.width !=="xs" && props.width !=="sm") &&<Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{paddingRight:"0.5rem"}}>
                <Typography variant={"h2"} style={textStyle}> 
                Gather feedback on how your company is doing
                </Typography>
            </Grid>}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img src="/images/ad_asset_cat_with_letters2.jpg"
                       style={{
                        width:"100%",
                        borderRadius:"5px",
                    }}  />
            </Grid>
            {(props.width =="xs" || props.width =="sm") &&<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingRight:"0.5rem"}}>
                <Typography variant={"h2"}> 
                Gather feedback on how your company is doing
                </Typography>
            </Grid>}
        </Grid>

        <Grid container style={containerStyle}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img src="/images/ad_asset_whale_with_letters.jpg"
                        style={imageStyle}  />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant={"h2"} style={textStyle}> 
                Measure the effectiveness of your email marketing strategies   
                </Typography>
            </Grid>
        </Grid>

        <Grid container style={containerStyle}>
            {(props.width !=="xs" && props.width !=="sm") &&<Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{paddingRight:"0.5rem"}}>
                <Typography variant={"h2"}> 
                Lower the cost of your email marketing efforts
                </Typography>
            </Grid>}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <img src="/images/ad_asset_dragon_with_letters2.jpg"
                        style={imageStyle}  />
            </Grid>
            {(props.width =="xs" || props.width =="sm") &&<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingRight:"0.5rem"}}>
                <Typography variant={"h2"} style={textStyle}> 
                Lower the cost of your email marketing efforts
                </Typography>
            </Grid>}
        </Grid>
        {actionButtonRow()}
        </Card>


        <Card elevation={defaultCardElevation} style={defaultCardStyles}>

        <Typography variant='h2' component={"p"} style={{fontSize:"4rem",marginTop:"0.5rem"}}> 
            Key features
        </Typography>
        <Typography variant='h2' component={"ul"} style={{listStyleType:"none",fontSize:"2rem"}}> 
            <li style={liStyle}><Toll style={iconStyle} />Pay only to send emails, not to store contacts</li>
            <li style={liStyle}><EmojiPeople style={iconStyle} />Provision unlimited sender personas</li>
            <li style={liStyle}><ImageSearch style={iconStyle} />See the exact emails that you sent</li>
            <li style={liStyle}><Schedule style={iconStyle} />Send in specific timezones (including contacts' timezones)</li>
            <li style={liStyle}><Fireplace style={iconStyle} />Enjoy out-of-the-box warmed up IP reputation</li>
            <li style={liStyle}><Security style={iconStyle} />Defend your sender reputation with automated email suppression lists</li>
        </Typography>
        {actionButtonRow()}
        </Card>

        <Card elevation={defaultCardElevation} style={defaultCardStyles}>
        <Typography variant='h2' component={"p"} style={{fontSize:"4rem",marginTop:"0.5rem"}}> 
            Additional features
        </Typography>

        <Typography variant='h2' component={"ul"} style={{listStyleType:"none",fontSize:"2rem"}}> 
            <li style={liStyle}><Email style={iconStyle} />Schedule email blasts and newsletters</li>
            <li style={liStyle}><CallSplit style={{...iconStyle,transform:"rotate(180deg)"}}/> 
                Create drag-drop workflows that are triggered by events</li>
            <li style={liStyle}><Image style={iconStyle} />Style templates with our intuitive drag-drop template editor (now with AI!)</li>
            <li style={liStyle}><TrendingUp style={iconStyle} />View real-time reports</li>
            <li style={liStyle}><PostAdd style={iconStyle} />Add contacts manually or via CSV</li>
        </Typography>
        {actionButtonRow()}
        </Card>
        <Card elevation={defaultCardElevation} style={defaultCardStyles}>
            <ContactUsForm width={props.width} dispatch={props.dispatch} 
            autoFocus={!!props.focusContactName}
            header={
                <Typography variant='h2' component={"p"} style={{fontSize:"4rem",marginTop:"0.5rem", marginBottom:"0"}}> 
                    Contact Us
                </Typography>
            } />
        </Card>
    </>
}