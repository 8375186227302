import { Card, Typography, Button } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { defaultCardElevation, defaultCardStyles } from '../App';
import { ScheduledEmailGroup, SenderPersona, Template } from '../generated/types/payloadTypes';
import {  fetchSenderPersonas, submitSenderPersonaAction } from '../actions/SenderPersonas';
import { AllState } from '../reducers';
import SelectSenderPersona from './components/SelectSenderPersona';
import SelectTemplate from './components/SelectTemplate';
import { Save } from '@material-ui/icons';
import SelectToType from './components/SelectToType';
import SelectSegments from './components/SelectSegments';
import SelectUsers from './components/SelectUsers';
import SelectTiming from './components/SelectTiming';
import {v4 as uuidv4} from "uuid";
import { showToast } from '../ToastNotifications';

interface IProps {
    pageNum: number,
    width: string,
    dispatch: any,
    senderPersonas?: {[key:string]:SenderPersona},
    templates?: {[key:string]:Template},
    senderPersona: SenderPersona,
    scheduledEmailGroup: ScheduledEmailGroup,
    templateId: string,
    isNewSenderPersona: boolean,
    isNewTemplate: boolean,
    toType: "segments"|"users"|"segments_and_users",
    sendAtType: "specific_timezone" | "users_timezone",
    sendAtMoment: moment.Moment,
    sendAtTimezone: string,
    selectedSegmentIds:{[key:string]:boolean},
    selectedUserIds:{[key:string]:boolean},
    onPageChange:(pageNum:number) => void,
    onSegmentsChange:(selectedSegmentIds:{[key:string]:boolean}) => void,
    onSenderPersonaChange: (senderPersona: SenderPersona,  isNewSenderPersona: boolean) => void,
    onTemplateChange: (t:Template, isNewTemplate: boolean) => void,
    onSendAtChange: (sendAtMoment: moment.Moment, sendAtType:"specific_timezone"|"users_timezone", sendAtTimezone: string) => void,
    onToTypeChange: (toType: "segments"|"users"|"segments_and_users") => void,
    onUsersChange:(userIds:{[key:string]:boolean}) => void,
}
interface IState {
   
}
const newSenderPersonaPage = 3

class ScheduledBlastCampaign extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
        };
    }

    foundCreatedSenderPersona():boolean {
        if(!this.props.senderPersonas || Object.keys(this.props.senderPersonas).length == 0) {
            return false
        }

        return !!Object.keys(this.props.senderPersonas).find((id:string) => id === this.props.senderPersona.id)
    }

    onCreateNewSenderPersonaForm():boolean {
        if(this.props.pageNum === newSenderPersonaPage && this.props.isNewSenderPersona) {
            return true
        }

        return false
    }

    isSelectTemplateForm(): boolean {
        return this.props.pageNum === newSenderPersonaPage+1
    }
    
    isToTypePage(): boolean {
        return this.props.pageNum ===newSenderPersonaPage+2
    }
    isSelectSegmentsPage(): boolean {
        return (this.props.pageNum === 6 && (this.props.toType === "segments" || this.props.toType==="segments_and_users"))
    }
    isSelectUsersPage(): boolean {
        return ((this.props.pageNum === 6 && this.props.toType === "users") || (this.props.pageNum===7 && this.props.toType==="segments_and_users"))
    }
    isSelectTimingPage(): boolean {
        return ((this.props.pageNum === 7 && (this.props.toType === "users" || this.props.toType === "segments")) 
            || (this.props.pageNum=== 8 && this.props.toType==="segments_and_users"))
    }

    isNextButtonEnabled() : boolean {
        if(this.props.pageNum === newSenderPersonaPage && this.props.senderPersona && this.props.senderPersona.id) {
            return true
        }

        if(this.isSelectTemplateForm()) {
            if(this.props.templateId) {
                return true
            }
        }

        if(this.isToTypePage()) {
            return true
        }

        if(this.isSelectSegmentsPage()) {
            if(Object.keys(this.props.selectedSegmentIds).length > 0) {
                return true
            }
        }

        if(this.isSelectUsersPage()) {
            if(Object.keys(this.props.selectedUserIds).length > 0) {
                return true
            }
        }
        
        if(this.isSelectTimingPage()) {
            if(this.props.sendAtMoment) {
                return true
            }
        }

        return false
    }


    getButtons() : JSX.Element { 
            const isNextDisabled = !this.isNextButtonEnabled() 
            const prevButton = <Button color="primary" variant="contained" disabled={this.props.pageNum === 1} onClick={(e) => { e.stopPropagation(); e.preventDefault(); if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum-1) } }} style={{marginRight:"1rem"}}>&lt;&nbsp;&nbsp; Campaign Setup</Button>
            const nextButton = <Button disabled={isNextDisabled} color="primary" variant="contained" onClick={(e) => { e.stopPropagation(); e.preventDefault();  if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum+1) } }} style={{marginRight:"1rem"}}>Campaign Setup &nbsp;&nbsp;&gt;</Button>
            const savePersonaButton = <Button color="primary" variant="contained" style={{marginRight:"1rem"}}
                startIcon={<Save />}
                onClick={(e) => { 
                    e.stopPropagation(); 
                    e.preventDefault(); 
                    
                    const sp = this.props.senderPersona
                    sp.id = uuidv4()
                    this.props.onSenderPersonaChange(sp, true) // add id
                    this.props.dispatch(submitSenderPersonaAction(
                       sp.id,
                       sp.first_name,
                       sp.last_name,
                       sp.email,
                       sp.json ? JSON.stringify(sp.json) : "",
                       (sp:SenderPersona) => {
                            this.props.onSenderPersonaChange(sp, true)
        
                            // Refetch so we know we can enable to next button
                            this.props.dispatch(fetchSenderPersonas([sp?.id]))
                            showToast("Successfully saved sender persona, please log in to " + sp?.email + " and verify the account", "success")
                        })
                    )
                }}>Save Persona</Button>
            
            const buttons = [prevButton, nextButton]
            if(this.onCreateNewSenderPersonaForm()) {
                buttons.push(savePersonaButton)
            }

            return <>{buttons}</>
    }

    render() {
        return <>
        <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
            <Typography variant="h1" component="h1" style={{marginBottom:"1rem"}}>New Campaign</Typography>
                {this.props.pageNum === newSenderPersonaPage && <>
                    <SelectSenderPersona 
                        onSenderPersonaChange={this.props.onSenderPersonaChange}
                        senderPersona={this.props.senderPersona}
                        isNewSenderPersona={this.props.isNewSenderPersona}
                        width={this.props.width}
                        dispatch={this.props.dispatch} /></>}
                {this.isSelectTemplateForm() && 
                    <SelectTemplate 
                        onTemplateChange={this.props.onTemplateChange} 
                        templateId={this.props.templateId}
                        isNewTemplate={this.props.isNewTemplate}
                        width={this.props.width} 
                        dispatch={this.props.dispatch} />}
                {this.isToTypePage() && <SelectToType 
                    toType={this.props.toType}
                    width={this.props.width} 
                    dispatch={this.props.dispatch} 
                    onToTypeChange={this.props.onToTypeChange} />}
                {this.isSelectSegmentsPage() && 
                    <SelectSegments 
                        prevPageText={<>{"< Segments"}</>}
                        nextPageText={<>{"Segments  >"}</>}
                        selectedSegmentIds={this.props.selectedSegmentIds}
                        width={this.props.width} 
                        dispatch={this.props.dispatch}
                        onSegmentsChange={this.props.onSegmentsChange} /> }
                {this.isSelectUsersPage() && <>
                    <SelectUsers 
                        prevPageText={<>{"< Contacts"}</>}
                        nextPageText={<>{"Contacts  >"}</>}
                        selectedUserIds={this.props.selectedUserIds}
                        width={this.props.width} 
                        dispatch={this.props.dispatch} 
                        onUsersChange={this.props.onUsersChange} />
                </>}
                {this.isSelectTimingPage() && 
                    <SelectTiming 
                        width={this.props.width} 
                        dispatch={this.props.dispatch} 
                        sendAtMoment={this.props.sendAtMoment}
                        sendAtTimezone={this.props.sendAtTimezone}
                        sendAtType={this.props.sendAtType}
                        onSendAtChange={this.props.onSendAtChange} />
                }
            <div style={{marginTop:"1rem"}}>
                {this.getButtons()}
            </div>
            {this.isSelectUsersPage() && <div>
                {Object.keys(this.props.selectedUserIds).length === 0 && <Typography style={{color:"red", marginTop:"1rem", marginBottom:"1rem"}} variant={"body1"}>
                    Please select one or more contacts
                </Typography>}
            </div>}
            {this.isSelectSegmentsPage() && <div>
                {Object.keys(this.props.selectedSegmentIds).length === 0 && <Typography style={{color:"red", marginTop:"1rem", marginBottom:"1rem"}} variant={"body1"}>
                    Please select one or more segments
                </Typography>}
            </div>}
        </Card>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ScheduledBlastCampaign)
