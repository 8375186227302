import { Card, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";


export function TestamonialCard(props: {
    style?: any
    imageUrl?: string,
    attributeTo?: JSX.Element,
    quote: string,
}) {
    const imageUrl = props.imageUrl || "/logo.svg"
    return <Card 
        elevation={2}
        style={{ width: "250px", height:"400px", display:"inline-block", margin:"10px", borderRadius:"20px", padding:"0.5rem", ...props.style}}>
        <div style={{
            borderRadius: "20px", 
            maxWidth:"100%", 
            height:"180px", 
            marginBottom:"1rem", 
            backgroundImage:"url(" + imageUrl + ")",
            backgroundPosition:"center",
            backgroundColor:"#000",
            backgroundSize: "180px",
            backgroundRepeat:"no-repeat",
         }} />
        <Typography variant={"body1"} style={{width:"100%", textAlign:"center", fontSize:"14pt",marginBottom:"1rem"}}>
            <b>{props.quote}</b>
        </Typography>
        <Typography variant={"body1"} component={"p"} style={{color:"#777", paddingLeft:"1rem"}}>
            {props.attributeTo}
        </Typography>
    </Card>
}