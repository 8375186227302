export const NAV_LINK_CLICKED = 'NAV_LINK_CLICKED';

interface INavLinkClicked {
    type: typeof NAV_LINK_CLICKED,
    path: string,
}

export type NavActionTypes = INavLinkClicked;


export function navLinkClickedAction(path:string):INavLinkClicked {
    return {
        type: 'NAV_LINK_CLICKED',
        path,
    }
}

export function navLinkClicked(path: string) {
    return function(dispatch:(...args:any)=>any) {
        dispatch(navLinkClickedAction(path));
    }
}