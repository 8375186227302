import React from 'react';
import { CheckCaptchaPayload, GetCaptchaResponsePayload, CheckCaptchaResponsePayload } from '../generated/types/payloadTypes';
import {showToast} from "../ToastNotifications";

export const REQUEST_DATA_ERROR = 'REQUEST_DATA_ERROR';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CLEAR_ALL_BUT_STATE = 'CLEAR_ALL_BUT_STATE';
export const CAPTCHA_REQUEST = 'CAPTCHA_REQUEST';
export const CAPTCHA_RESPONSE = 'CAPTCHA_RESPONSE';
export const CHECK_CAPTCHA_REQUEST = 'CHECK_CAPTCHA_REQUEST';
export const CHECK_CAPTCHA_RESPONSE = 'CHECK_CAPTCHA_RESPONSE';
export const SIGNUP_CAPTCHA = "SIGNUP_CAPTCHA"; //Special const used to pass signup errors to specific captcha component
export const CONTACT_CAPTCHA = "CONTACT_CAPTCHA"; //Special const used to pass contact-us form errors to specific captcha component

type ICaptchaResponse = {
    type: typeof CAPTCHA_RESPONSE,
  } & GetCaptchaResponsePayload
  type ICheckCaptchaRequest = {
    type: typeof CHECK_CAPTCHA_REQUEST,
  } & CheckCaptchaPayload
  type ICheckCaptchaResponse = {
    type: typeof CHECK_CAPTCHA_RESPONSE,
  } & CheckCaptchaResponsePayload
  type ICaptchaRequest = {
    type: typeof CAPTCHA_REQUEST,
  }

interface IRequestDataErrorAction {
    type: typeof REQUEST_DATA_ERROR,
    error: string,
    fetchFor: string,
}
interface IClearStateAction {
    type: typeof CLEAR_STATE,
    keyPath: string[], //Will be used by lodash _.get() and _.set() https://lodash.com/docs/4.17.15#set
}
interface IClearAllButStateAction {
    type: typeof CLEAR_ALL_BUT_STATE,
    keyPaths: string[][],
}

export type CommonActionTypes = IRequestDataErrorAction | IClearStateAction | IClearAllButStateAction
    | ICaptchaResponse | ICheckCaptchaResponse | ICheckCaptchaRequest | ICaptchaRequest

export function requestDataErrorAction(error:string, fetchFor: string) : IRequestDataErrorAction {
    return {
        type: REQUEST_DATA_ERROR,
        error,
        fetchFor,
    }
}

export function clearStateData(keyPath: string[]) : IClearStateAction {
    return {
        type: CLEAR_STATE,
        keyPath,
    }
}

export function clearAllButStateData(keyPaths: string[][]) : IClearAllButStateAction {
    return {
        type: CLEAR_ALL_BUT_STATE,
        keyPaths,
    }
}

export function denastifyError(errorString: string):string {
    errorString = errorString.trim()
    if(!errorString) {
      return ""
    }
    if(errorString.endsWith("Failed to fetch")) {
      errorString = errorString.replace("Failed to fetch", "Unknown networking error")
     }

    const replaceMap = [
        "\\[",
        "\\]",
        "\\{",
        "\\}",
        `"`,
        `"`,
    ]
    const regex = new RegExp("[" + replaceMap.join("") + "]", "g")
    return errorString.replaceAll(regex, " ").trim()
}

export function errorsToUL(errors: string[]): JSX.Element {
    if(errors.length == 1) {
        return <>{denastifyError(errors[0])}</>
    }
    
    return <ul>
        {errors.map((err,i) => <li key={"errorkey_" + i}>{denastifyError(err)}</li>)}
    </ul>
}



export function requestCaptcha():ICaptchaRequest{
    return {
      type: 'CAPTCHA_REQUEST',
    }
  }
  
  export function fetchCaptcha() {
    return function(dispatch:(...args:any)=>any) {
      dispatch(requestCaptcha());
      return fetchCaptchaPromise(dispatch);
    }
  }
  
  export function receiveCaptchaAction(captcha: GetCaptchaResponsePayload):ICaptchaResponse {
    return {
      type: 'CAPTCHA_RESPONSE',
        ...captcha,
    }
  }
  
  function fetchCaptchaPromise(dispatch:(...args:any)=>any) {
    const url = process.env.REACT_APP_API_AUTH_WRAPPER_GATEWAY_URL  + "/captcha";
    console.log(url);
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      }
    })
        .then(res => res.json())
        .then((json:GetCaptchaResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),CAPTCHA_REQUEST));
            showToast(<>Couldn't fetch captcha: {errorsToUL(json.errors)}</>, "error");
          }
          console.log("Got captcha " + json.captcha_key);
          dispatch(receiveCaptchaAction(json));
    }).catch(e => {
      dispatch(requestDataErrorAction(e.message,CAPTCHA_REQUEST));
      showToast(`Couldn't fetch captcha: ${denastifyError(e?.message)}`, "error");
    });
  }
  