import React, {ChangeEvent} from 'react';
import {connect} from "react-redux";
import {
   Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from "@material-ui/core";
import {fetchSegments} from "./actions/Segments";
import {Link} from "react-router-dom";
import {Add, ArrowDropDown, ArrowDropUp, Edit, Remove} from "@material-ui/icons";
import {Segment} from "./generated/types/payloadTypes";
import { Loading } from './components/Loading';
import {v4 as uuidv4} from "uuid";
import PagingToolbarFromProps from './components/PagingToolbarFromProps';

interface IState {
    id: string,
    selectedSegmentId: string,
    offset: number,
    limit: number,
    displayPerPageLimit: number,
    orderColumn: string,
    orderDirection: string,
}

interface IProps {
    dispatch: any,
    type: string,
    segments?: {[key:string]:Segment},
    receivingSegments?: boolean,
    buttons:{[key:string]:boolean},
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    onSegmentAdd?: (segmentId: string) => any,
    onSegmentRemove?: (segmentId: string) => any,
    selectedSegmentIds?: string[],
    label?:string,
    placeholder?: string,
    reportErrors?: {[key:string]:string},
    style?:any,
    required?: boolean,
    helperText?: string,
    title?: string,
    hideAddButton?: boolean;
    toggleIfNameClicked?: boolean; // for lists with add/remove button
    editButtonTargetBlank?: boolean;
    width: string,
    showSelectedOnly?: boolean;
    hideSearch?: boolean,
    pagingButtonVariant?: "text" | "outlined" | "contained",
    limit?: number,
    prevPageText?: JSX.Element,
    nextPageText?: JSX.Element,
}

class ListSegments extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4(),
            selectedSegmentId:this.props.placeholder?"placeholder":"",
            limit: 3000,
            displayPerPageLimit: props.limit || 10,
            offset: 0,
            orderColumn: "created_at",
            orderDirection: "DESC",
        };
        this.segmentChange = this.segmentChange.bind(this);
        this.pagePrev = this.pagePrev.bind(this);
        this.pageNext = this.pageNext.bind(this);
    }

    segmentDisplay(segment: Segment):string {
        if(!segment) {
            return null;
        }

        let users = "";
        if(segment.user_ids && segment.user_ids.length) {
            users = " w/" + segment.user_ids.length + " users";
        }
        return segment.name + users;
    }

    
    componentDidMount(){
        if(this.props.showSelectedOnly) {
            if(this.props.selectedSegmentIds && this.props.selectedSegmentIds.length > 0) {
                this.props.dispatch(fetchSegments(this.props.selectedSegmentIds, null, null, null, null, true))
            }
        } else {
            if(this.props.type == "list") {
                this.props.dispatch(fetchSegments(null, null, this.state.limit, this.state.offset, 
                    this.state.orderColumn?[this.state.orderColumn + " " + this.state.orderDirection]:null,
                    true))
            } else {
                this.props.dispatch(fetchSegments(null, null, null, null, null, true))
            }
        }
    }

    segmentChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedSegmentId: "",
            });
            return;
        }

        const selectedSegmentId = event.target.value.toString()
        this.setState({
            ...this.state,
            selectedSegmentId,
        });

        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    getButtons(s:Segment) : JSX.Element{
        const editButton = !this.props.editButtonTargetBlank ? <Link key="editButton" to={"/people/segments/edit/"+s.id} className="svgShadow"><Edit /></Link>
            : <a key="editButton2" href={"/people/segments/edit/"+s.id} target="_blank" className="svgShadow"><Edit /></a>

        //This add button is for when we are pushing the add event back up to the parent
        const addButtonCallback = <Link key="addButton" to={""} className="svgShadow" onClick={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            if(this.props.onSegmentAdd) {
                this.props.onSegmentAdd(s.id)
            }
        }}><Add /></Link>
        //This remove button is for when we are pushing the remove event back up to the parent
        const removeButtonCallback = <Link key="removeButton" to={""} className="svgShadow" onClick={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            if(this.props.onSegmentRemove) {
                this.props.onSegmentRemove(s.id)
            }
        }}><Remove /></Link>

        //If this is an add/remove list
        if(this.props.selectedSegmentIds && this.props.buttons["add"] && this.props.buttons["remove"]) {
            let thisEditButton = <></>
            if(this.props.buttons["edit"]) {
                thisEditButton = editButton
            }
            
            if(this.props.selectedSegmentIds.find(id => id == s.id)) {
                return <div style={{width:"100%"}}>{removeButtonCallback}{thisEditButton}</div>
            } else {
                return <div style={{width:"100%"}}>{addButtonCallback}{thisEditButton}</div>
            }
        }

        const buttons = []
        if(this.props.buttons["add"]) {
            buttons.push(addButtonCallback)
        }
        if(this.props.buttons["remove"]) {
            buttons.push(removeButtonCallback)
        }
        if(this.props.buttons["edit"]) {
            buttons.push(editButton)
        }

        return <div style={{width:"100%"}}>
            {buttons.map(c => c)}
        </div>
    }

    truncateText(text: string, len = 70) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    selectMenuItems(): JSX.Element[] {
        if(!this.props.segments || Object.values(this.props.segments).length===0) {
            return []
        }
        
        const options:JSX.Element[] = []
        Object.values(this.props.segments).map(segment => {
            if(!segment.invisible) {
                options.push(<MenuItem key={segment.id} value={segment.id}>{this.segmentDisplay(segment)}</MenuItem>)
            }
        })

        return options
    }
    
    pagePrev(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset -= this.state.displayPerPageLimit
        if(newState.offset <= 0) {
            if(this.state.offset === 0) {
                return
            }
            newState.offset = 0
        }

        this.setState(newState)
        // this.props.dispatch(fetchSegments(null, null, this.displayPerPageLimit, newState.offset, 
        //     newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:null,
        //     true))
    }

    pageNext(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset += this.state.displayPerPageLimit

        const numSegments = Object.keys(this.props?.segments || {})?.length || 0
        if(newState.offset > numSegments) {
            return
        }

        this.setState(newState)
        // this.props.dispatch(fetchSegments(null, null, this.displayPerPageLimit, newState.offset, 
        //     newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:null,
        //     true))
    }

    sortBy(event:any, tableHeader: string) {
        event.stopPropagation();
        event.preventDefault();

        let orderDirection = "ASC";

        //Toggle direction if clicking again
        if(this.state.orderColumn == tableHeader) {
            if(this.state.orderDirection === "ASC") {
                orderDirection = "DESC";
            } else {
                orderDirection = "ASC";
            }
        }

        const newState = {
            ...this.state,
            orderColumn: tableHeader,
            orderDirection,
        }
        this.setState(newState)

        this.props.dispatch(fetchSegments(null, null, this.state.limit, 0, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:null,
            true))
    }

    sortDirectionWidget(tableHeader: string) : JSX.Element {
        if(this.state.orderColumn === tableHeader) {
            if(this.state.orderDirection === "ASC") {
                return <ArrowDropUp />
            } else {
                return <ArrowDropDown />
            }
        }

        return null;
    }

    headerWidget(headerName: string, displayName: string|JSX.Element) : JSX.Element {
        return <Link to={""} onClick={(event) => this.sortBy(event, headerName)}>
            <Grid container wrap={"nowrap"} alignContent={"stretch"} alignItems={"center"}>
                <Grid item>
                    <Typography variant={"body1"} component={"p"}>{displayName}</Typography>
                </Grid>
                <Grid item style={{minHeight:"20px"}}>
                    {this.sortDirectionWidget(headerName)}
                </Grid>
            </Grid>
        </Link>
    }

    render() {
        return <>
                { this.props.type === 'list' && <>
                    <Grid container>
                        <Grid item style={{marginRight:"10px", marginBottom:"5px"}}>
                           <PagingToolbarFromProps 
                                offset={this.state.offset} 
                                limit={this.state.displayPerPageLimit} 
                                totalItems={this.props.segments?Object.values(this.props.segments).length:0}
                                isLoading={this.props.receivingSegments} 
                                prevPageText={this.props.prevPageText}
                                nextPageText={this.props.nextPageText}
                                onNextPage={this.pageNext}
                                onPrevPage={this.pagePrev} 
                                buttonVariant={this.props.pagingButtonVariant || "contained"}
                                />
                        </Grid>
                        {/* {!this.props.hideSearch && <Grid item>
                            <TextField placeholder={"search"}
                               style={{width:"40%", minWidth:"300px", marginTop:"3px"}}
                               onChange={e => this.searchChange(e.target.value.toString())}
                               InputProps={{
                                   startAdornment: <>
                                       <Select
                                           onChange={this.searchTypeChange}
                                           style={{backgroundColor:"#eee",borderTopLeftRadius:"5px"}}
                                           value={this.state.searchType}>
                                           <MenuItem value={"email"}>&nbsp;Email</MenuItem>
                                           <MenuItem value={"first_name"}>&nbsp;First Name</MenuItem>
                                           <MenuItem value={"last_name"}>&nbsp;Last Name</MenuItem>
                                           <MenuItem value={"timezone"}>&nbsp;Timezone</MenuItem>
                                       </Select>
                                       <Search  />
                                   </>
                               }}/>
                        </Grid>} */}
                    </Grid>
                    {this.props.receivingSegments && (!this.props.segments || Object.keys(this.props.segments).length === 0) ? 
                        <div style={{minHeight:"400px", paddingTop:"2rem"}}><Loading /></div> :

                    <div style={{position:"relative"}}>
                    {this.props.receivingSegments && <Loading overlay={true} mySize='lg' text={<Typography variant="h1">Loading...</Typography>} />}
                    <TableContainer>
                        <Table cellSpacing={0}>
                        <TableHead>
                                <TableRow>
                                    <TableCell key="c1"></TableCell>
                                    <TableCell key="c2">{this.headerWidget("name", "Name")}</TableCell>
                                    <TableCell key="c3">{this.headerWidget("num_users", "Users")}</TableCell>
                                    <TableCell key="c4">{this.headerWidget("created_at", "Added")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.segments && Object.values(this.props.segments).map((s, i:number) => {
                                    if(!(i >= this.state.offset && i < this.state.offset + this.state.displayPerPageLimit)) {
                                        return <></>
                                    }
                                    return <TableRow key={"row" + i.toString()}>
                                        <TableCell  key={"row" + i.toString() + "col1"} style={{width:"25px", whiteSpace:"nowrap"}}>
                                            {this.getButtons(s)}
                                        </TableCell>
                                        <TableCell  key={"row" + i.toString() + "col2"} style={{maxWidth:"300px", overflow:"hidden", textOverflow:"ellipsis"}}>
                                            {!this.props.toggleIfNameClicked ? <a href={"/people/segments/edit/" + s.id} target="_blank">
                                                <Typography component="span" variant="body1" title={s.name}>
                                                {s.name}
                                                </Typography>
                                            </a> : 
                                            <Link to={""} onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()

                                                if(this.props.selectedSegmentIds && this.props.selectedSegmentIds.indexOf(s.id) !== -1) {
                                                    if(this.props.onSegmentRemove) {
                                                        this.props.onSegmentRemove(s.id)
                                                    }
                                                } else {
                                                    if(this.props.onSegmentAdd) {
                                                        this.props.onSegmentAdd(s.id)
                                                    }
                                                }
                                            }}>
                                                <Typography component="span" variant="body1" title={s.name}>
                                                {s.name}
                                                </Typography>
                                            </Link>}

                                        </TableCell>
                                        <TableCell key={"row" + i.toString() + "col3"}>
                                            <Typography component="span" variant="body1" title={s.num_users.toLocaleString()}>
                                            {s.num_users.toLocaleString()}
                                            </Typography>
                                        </TableCell>
                                        <TableCell key={"row" + i.toString() + "col4"}>
                                            <Typography component="span" variant="body1" title={new Date(s.created_at).toLocaleDateString() + " " +new Date(s.created_at).toLocaleTimeString()}>
                                            {new Date(s.created_at).toLocaleDateString() + " " + 
                                            new Date(s.created_at).toLocaleTimeString() }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div>}
                </>
                }
                {this.props.type === 'select' && !this.props.receivingSegments &&
                    <Select id={"segmentSelect"+this.state.id}
                            required={!!this.props.required}
                            MenuProps={{ disableScrollLock: true }}
                            value={this.state.selectedSegmentId}
                            onChange={this.segmentChange}
                            style={{width:"100%"}}
                            >
                        { this.props.placeholder && <MenuItem key="selectplaceholder" value="placeholder" disabled selected>{this.props.placeholder}</MenuItem>}
                        {  this.selectMenuItems() }
                    </Select>
                } 
                {this.props.type === 'select' && this.props.receivingSegments && 
                    <Loading mySize='sm' style={{marginTop:"4px"}} text={<Typography variant="body1" component="span" style={{fontSize:"1rem", paddingLeft:"5px"}}>
                        Fetching segments
                    </Typography>} />
                }
                {/* { this.props.type === 'multiselect' && <>
                    {this.props.receivingSegments && <Loading mySize={"sm"} />}
                    {!this.props.receivingSegments &&
                        <FormControl style={this.props.style} error={!!this.props.reportErrors[REQUEST_SEGMENTS] || !!this.props.helperText} >
                            {this.props.label && <InputLabel id={"segmentSelect"+this.state.id} required={!!this.props.required}>{this.props.label}</InputLabel>}
                            <Select id={"segmentSelect"+this.state.id}
                                    required={!!this.props.required}
                                    MenuProps={{ disableScrollLock: true }}
                                    name='segmentSelect'
                                    value={this.state.selectedSegmentIds}
                                    onChange={this.segmentChange}
                                    renderValue={selected => this.renderSegmentSelections(selected as string[])}
                                    input={<Input id="select-multiple-chip" />}
                                    multiple>
                                { this.props.placeholder && <MenuItem value="" disabled>{this.props.placeholder}</MenuItem>}
                                { Object.values(this.state.segments).map(segment => {
                                    return <MenuItem key={"m" + segment.id} value={segment.id}>
                                        <Checkbox key={"c" + segment.id} checked={this.state.selectedSegmentIds.indexOf(segment.id) > -1} />
                                        <ListItemText key={"li" + segment.id} primary={this.segmentDisplay(segment)} />
                                    </MenuItem>
                                })}
                            </Select>
                            <FormHelperText required={!!this.props.required}>{this.props.reportErrors[REQUEST_SEGMENTS] || this.props.helperText || ""}</FormHelperText>
                        </FormControl>}
                    </>
                } */}
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    let fetchedSegments = null;
    if(state.segments && Object.keys(state.segments).length > 0) {
        fetchedSegments = state.segments;
    }

    return { ...state, fetchedSegments };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListSegments);




