import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Card, Popper, Typography} from "@material-ui/core";
import {Close, Delete} from "@material-ui/icons";
import {defaultCardElevation,defaultCardStyles} from "./App";

interface IDialogProps {
    title: JSX.Element | string;
    content: JSX.Element | string;
    buttons: JSX.Element;
    anchorEl?: any;
    open?: boolean;
    onBlur?: any;
    style?: any;
    cardStyle?: any;
}

export const MyCancelButton = (props:any):JSX.Element => {
    return <Button variant={"contained"}
                   disabled={!!props.disabled}
                   startIcon={<Close />}
                   onClick={props.onClick}
                   color={props.color?props.color:"primary"}
                   style={{float:"left",...props.style}}>
        {props.text ? props.text : "Cancel"}
    </Button>
};


export const MyOkButton = (props:any):JSX.Element => {
    return <Button variant={"contained"}
                   onClick={props.onClick}
                   color={"primary"}
                   disabled={!!props.disabled}
                   style={{float:"right", ...props.style}}>
        {props.text ? props.text : "OK"}
    </Button>
};

export const MyArchiveButton = (props:any):JSX.Element => {
    return <Button variant={"contained"}
                   startIcon={<Delete />}
                   onClick={props.onClick}
                   color={"secondary"}
                   style={{float:"right",...props.style}}>
        {props.text ? props.text : "Archive"}
    </Button>
};

export const MyModal = (props:IDialogProps):JSX.Element => {
    return <Popper open={true} anchorEl={props.anchorEl} style={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'fixed',
        maxWidth:"600px",
        width:"70%",
        minWidth:"380px",
        ...props.style,
    }} onBlur={props.onBlur}>
        <Card elevation={defaultCardElevation} style={{border:"1px solid #bbb",padding:"10px 10px 10px 10px", width:"100%", height:"100%", ...props.cardStyle}}>
            <div style={{marginBottom:"5px"}}>
                {typeof props.title === "string" && <Typography variant={"h3"}>{props.title}</Typography>}
                {typeof props.title !== "string" && props.title}
            </div>
            <div style={{marginBottom:"5px"}}>
                {props.content}
            </div>
            <div style={{marginBottom:"5px"}}>
                {props.buttons}
            </div>
        </Card>
    </Popper>
};

const ConfirmDialog = (props:any):JSX.Element => {
    const { title, children, open, setOpen, onConfirm, dialogActions } = props;

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle disableTypography={true} id="confirm-dialog">
                <Typography variant={"h3"}>{title}</Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            {!props.dialogActions &&
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="secondary"
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="default"
                >
                    Yes
                </Button>
            </DialogActions>}
            {props.dialogActions}
        </Dialog>
    );
};


export const OkDialog = (props:any):JSX.Element => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle disableTypography={true} id="confirm-dialog">
                <Typography variant={"h3"}>{title}</Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="default"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;