/* eslint-disable no-case-declarations */
import * as TemplateActions from '../actions/Templates'
import * as SenderPersonaActions from '../actions/SenderPersonas'
import * as SegmentActions from '../actions/Segments'
import * as UsersActions from '../actions/Users'
import * as WorkflowActions from '../actions/Workflows'
import * as ReportActions from '../actions/Reports'
import * as NotificationActions from '../actions/Notifications'
import * as EmailBlastActions from "../actions/EmailBlasts";
import * as CampaignActions from "../actions/Campaigns";
import * as CommonActions from "../actions/Common";
import * as EmailActions from "../actions/Emails";
import * as AuthActions from "../actions/Auth";
import * as SettingsActions from "../actions/Settings";
import * as HealthActions from "../actions/Health";
import * as NavActions from "../actions/Nav";
import * as PagingToolbarActions from "../actions/PagingToolbar";
import * as ContactUsActions from "../actions/Contact";
import * as EventActions from "../actions/Events"

import {
    Campaign, CampaignWithMetrics, EmailPipelineReport, 
    GetCaptchaResponsePayload, GrepLogResponsePayload,
    LoggerS3Payload, LoginResponsePayload, QueueReportResponsePayload,
    ScheduledEmailGroupWithMetrics,
    Segment,
    SenderPersona,
    Template,
    User,
    Workflow,
    Event,
    SystemEvent,
    GetDKIMInfoResponsePayload,
    CompanyUser,
} from "../generated/types/payloadTypes";
import {v4 as uuidv4} from "uuid";
import {
    GET_COMPANY_USERS_REQUEST,
    getAuthKey,
    SIGNUP_PASSWORD,
    SIGNUP_REQUEST,
    storeAuthInfo
} from "../actions/Auth";
import { NotificationWaiter as MyNotificationWaiter} from "../waiters/notifications"
import { CAPTCHA_REQUEST, SIGNUP_CAPTCHA, CONTACT_CAPTCHA } from '../actions/Common'
import {REQUEST_SENDER_PERSONAS} from "../actions/SenderPersonas";
import {REQUEST_TEMPLATES} from "../actions/Templates";
import {REQUEST_NUM_USERS, REQUEST_USERS} from "../actions/Users";
import {REQUEST_CAMPAIGNS,REQUEST_CAMPAIGNS_WITH_METRICS, REQUEST_NUM_CAMPAIGNS_WITH_METRICS} from "../actions/Campaigns";
import {REQUEST_EMAIL_BLASTS} from "../actions/EmailBlasts";
import {REQUEST_SEGMENTS} from "../actions/Segments";
import {REQUEST_WORKFLOWS} from "../actions/Workflows";
import _set from 'lodash/set';
import _get from 'lodash/get';
import { CONTACT_US_REQUEST } from '../actions/Contact'
import { REQUEST_EVENTS } from '../actions/Events'

const NotificationWaiter = new MyNotificationWaiter()
export enum userTableIds {
    LIST_USERS_TABLE = "LIST_USERS_TABLE",
    LIST_USERS_PAGING_TABLE = "LIST_USERS_PAGING_TABLE",
    COUNT_USERS_TABLE = "COUNT_USERS_TABLE",
}

export enum campaignTableIds {
    LIST_CAMPAIGNS_TABLE = "LIST_CAMPAIGNS_TABLE",
    COUNT_CAMPAIGNS_TABLE = "COUNT_CAMPAIGNS_TABLE",
}

export enum userSegmentTableIds {
    COUNT_USERS_IN_SEGMENT_TABLE = "COUNT_USERS_IN_SEGMENT_TABLE",
    LIST_USERS_IN_SEGMENT_TABLE = "LIST_USERS_IN_SEGMENT_TABLE",
    PAGING_LIST_USERS_IN_SEGMENT_TABLE = "PAGING_LIST_USERS_IN_SEGMENT_TABLE",
    LIST_USERS_NOT_IN_SEGMENT_TABLE = "LIST_USERS_NOT_IN_SEGMENT_TABLE",
    PAGING_LIST_USERS_NOT_IN_SEGMENT_TABLE = "PAGING_LIST_USERS_NOT_IN_SEGMENT_TABLE",
}

export interface AllState {
    success: string,
    error: string,
    signupDataErrors: {[key:string]: string},

    receivingSenderPersonas: boolean,
    senderPersonas: {[key:string]: SenderPersona},

    receivingTemplates: boolean,
    templates: {[key:string]: Template},
    templatesListKey: string,

    receivingSegments: boolean,
    receivingFilteredUsers: boolean,
    filteredUsers: User[],
    totalFilteredUsers: number,
    segments: {[key:string]:Segment},

    receivingUsers: boolean,
    receivingUsersInSegments: boolean,
    receivingUsersNotInSegments: boolean,
    receivingNumUsers: boolean,
    receivingNumUsersInSegments: boolean,
    receivingNumUsersNotInSegments: boolean,
    numUsers: number,
    numUsersInSegments: number,
    numUsersNotInSegments: number,
    users: {[key:string]:User},
    usersInSegments: {[key:string]:User},
    usersNotInSegments: {[key:string]:User},
    usersListKey: string,
    usersCountListKey: string,

    companyUsers: {[key:string]: CompanyUser},
    receivingCompanyUsers: boolean,

    receivingCampaigns: boolean,
    campaigns: {[key:string]:Campaign},
    campaignsListKey: string,
    campaignsWithMetrics: CampaignWithMetrics[],
    numCampaignsWithMetrics: number,
    receivingNumCampaignsWithMetrics: boolean,
    receivingCampaignsWithMetrics: boolean,

    receivingWorkflows: boolean,
    workflows: {[key:string]: Workflow},
    workflowsListKey: string,

    paging: {[key:string]: { offset: number, limit: number }},

    receivingReportData: {[key:string]: boolean},
    reportData: {[key:string]: any[]}, //report id to data mapping
    reportErrors: {[key:string]: string[]},
    usersInSegmentComponentKey: string,
    usersNotInSegmentComponentKey: string,

    receivingEmailBlasts: boolean,
    emailBlasts: {[key:string]: ScheduledEmailGroupWithMetrics},
    emailBlastsListKey: string,

    receivingEvents: boolean,
    events: {[key:string]: Event|SystemEvent},
    eventsListKey: string,
    receivingNumEvents: boolean,
    numEvents: number,
    eventsCountListKey: string,

    receivingNotifications: boolean,
    notifications: any[],
    receivingBulkUploadProgress: {[key:string]:boolean},
    bulkUploadProgress: {[key:string]:{numInProgress:number, numTotal:number, s3Key:string, errors:string[], processingErrors:string[]}},

    receivingEmailSendResults: boolean,
    emailSendResults: {[key:string]: LoggerS3Payload}

    processingLogin: boolean,
    processingSignUp: boolean,
    waitingForSignUpArchitecture: boolean,

    processingContactUs: boolean,

    receivingDKIMSettings: boolean,
    dkimSettings: GetDKIMInfoResponsePayload,

    addingDomain: boolean,

    receivingCaptcha: boolean,
    currentCaptcha: GetCaptchaResponsePayload,

    receivingEmailPipelineReport: boolean,
    emailPipelineReport: EmailPipelineReport,

    receivingGrepLog: {[key:string]: boolean},
    grepLog: {[key:string]: GrepLogResponsePayload},

    receivingArchitectureAttributes: boolean,
    architectureAttributes: QueueReportResponsePayload,

    navPath: string,
}

type AvailableActions = TemplateActions.TemplateActionTypes
  | SenderPersonaActions.SenderPersonaActionTypes
  | SegmentActions.SegmentActionTypes
  | UsersActions.UserActionTypes
  | WorkflowActions.WorkflowActionTypes
  | ReportActions.ReportActionTypes
  | NotificationActions.NotificationActionTypes
  | EmailBlastActions.EmailBlastActionTypes
  | CommonActions.CommonActionTypes
  | EmailActions.EmailActionTypes
  | CampaignActions.CampaignActionTypes
  | AuthActions.AuthActionTypes
  | SettingsActions.SettingsActionsTypes
  | HealthActions.HealthActionTypes
  | NavActions.NavActionTypes
  | PagingToolbarActions.PagingToolbarActionTypes
  | ContactUsActions.ContactUsActionTypes
  | EventActions.EventActionTypes


//Gets modified within reducer
const myState:AllState = {
    success: "",
    error: "",
    signupDataErrors: {},

    receivingSenderPersonas: false,
    senderPersonas: {},

    receivingTemplates: false,
    templates: {},
    templatesListKey: uuidv4(),

    receivingSegments: false,
    receivingFilteredUsers: false,
    filteredUsers: [],
    totalFilteredUsers: 0,
    segments: {},

    receivingUsers: false,
    receivingUsersInSegments: false,
    receivingUsersNotInSegments: false,
    receivingNumUsers: false,
    receivingNumUsersInSegments: false, 
    receivingNumUsersNotInSegments: false,
    numUsers: 0,
    numUsersInSegments: 0,
    numUsersNotInSegments: 0,
    users: {},
    usersInSegments: {},
    usersNotInSegments: {},
    usersListKey: uuidv4(),
    usersCountListKey: uuidv4(),
    usersInSegmentComponentKey: uuidv4(),
    usersNotInSegmentComponentKey: uuidv4(),

    companyUsers: {},
    receivingCompanyUsers: false,

    receivingCampaigns: false,
    campaigns: {},
    campaignsListKey: uuidv4(),
    receivingCampaignsWithMetrics: false, 
    campaignsWithMetrics: [],
    receivingNumCampaignsWithMetrics: false,
    numCampaignsWithMetrics: 0,

    receivingWorkflows: false,
    workflows: {},
    workflowsListKey: uuidv4(),

    paging: {},

    receivingReportData: {},
    reportData: {},
    reportErrors: {},

    receivingEmailBlasts: false,
    emailBlasts: {},
    emailBlastsListKey: uuidv4(),

    receivingEvents: false,
    events: {},
    eventsListKey: uuidv4(),
    receivingNumEvents: false,
    numEvents: 0,
    eventsCountListKey: uuidv4(),

    receivingNotifications: false,
    notifications: [],
    receivingBulkUploadProgress: {},
    bulkUploadProgress: {},

    receivingEmailSendResults: false,
    emailSendResults: {},

    processingLogin: false,
    processingSignUp: false,
    waitingForSignUpArchitecture: false,

    processingContactUs: false,

    dkimSettings: null,
    receivingDKIMSettings: false,

    addingDomain: false,

    receivingCaptcha: false,
    currentCaptcha: null,

    receivingEmailPipelineReport: false,
    emailPipelineReport: null,

    receivingGrepLog: {},
    grepLog: {},

    receivingArchitectureAttributes: false,
    architectureAttributes: null,

    navPath: "/",
}

//Not modified anywhere
const initialState = JSON.parse(JSON.stringify(myState));
function getInitialStateCopy() : AllState {
    return JSON.parse(JSON.stringify(initialState));
}


export default function reducer(state:AllState = myState, action:AvailableActions):AllState {
    console.log("REDUCER",action.type);
    switch (action.type) {
        case CommonActions.CLEAR_STATE:
             _set(state, action.keyPath, null);
            return state;
        case CommonActions.CLEAR_ALL_BUT_STATE:
            // Don't do anything, this is buggy
            // Namely, in situations like when we switch off of a page and then back to that page, data is not
            // reloaded if it was initially loaded in the componentDidMount function
            
            return state

            // const newState:AllState = getInitialStateCopy();

            // // Never clear file upload / processing state
            // action.keyPaths.push(["bulkUploadProgress"])
            // action.keyPaths.push(["receivingBulkUploadProgress"])

            // action.keyPaths.map(keyPath => {
            //     _set(newState, keyPath, _get(state, keyPath));
            // });
            // return newState;
        case CommonActions.REQUEST_DATA_ERROR:
            console.error(action.error)
            switch(action.fetchFor) {
                case CONTACT_US_REQUEST:
                    delete state.signupDataErrors[CONTACT_CAPTCHA];
                    if(action.error.toLowerCase().indexOf("captcha") !== -1) {
                        console.log("Rerouting error to contact-us captcha");
                        action.fetchFor = CONTACT_CAPTCHA;
                    } 
                    break;
                case REQUEST_SENDER_PERSONAS:
                    state.receivingSenderPersonas = false;
                    break;
                case CAPTCHA_REQUEST:
                    state.receivingCaptcha = false;
                    break;
                case REQUEST_TEMPLATES:
                    state.receivingTemplates = false;
                    break;
                case REQUEST_USERS:
                    state.receivingUsers = false;
                    state.receivingUsersInSegments = false;
                    state.receivingUsersNotInSegments = false;
                    break;
                case GET_COMPANY_USERS_REQUEST:
                    state.receivingCompanyUsers = false;
                    break;
                case REQUEST_EVENTS:
                    state.receivingEvents = false;
                    break;
                case EventActions.REQUEST_NUM_EVENTS:
                    state.receivingNumEvents = false;
                    break;
                case REQUEST_NUM_USERS:
                    state.receivingNumUsers = false;
                    state.receivingNumUsersInSegments = false;
                    state.receivingNumUsersNotInSegments = false;
                    break;
                case REQUEST_CAMPAIGNS:
                    state.receivingCampaigns = false;
                    break;
                case REQUEST_CAMPAIGNS_WITH_METRICS:
                    state.receivingCampaignsWithMetrics = false;
                    break;
                case REQUEST_NUM_CAMPAIGNS_WITH_METRICS:
                    state.receivingNumCampaignsWithMetrics = false;
                    break;
                case REQUEST_EMAIL_BLASTS:
                    state.receivingEmailBlasts = false;
                    break;
                case REQUEST_SEGMENTS:
                    state.receivingSegments = false;
                    break;
                case REQUEST_WORKFLOWS:
                    state.receivingWorkflows = false;
                    break;
                case SIGNUP_REQUEST:
                    delete state.signupDataErrors[SIGNUP_CAPTCHA];
                    delete state.signupDataErrors[SIGNUP_PASSWORD];
                    if(action.error.toLowerCase().indexOf("captcha") !== -1) {
                        console.log("Rerouting error to signup captcha");
                        action.fetchFor = SIGNUP_CAPTCHA;
                    } else if(action.error.toLowerCase().indexOf("password") !== -1) {
                        console.log("Rerouting error to signup password");
                        action.fetchFor = SIGNUP_PASSWORD;
                    }

                    break;
                default:
                    state.receivingReportData[action.fetchFor] = false;
                    break;
            }

            return {
                ...state,
                reportErrors: {
                    ...state.reportErrors,
                    [action.fetchFor]: [action.error],
                }
            };
        case NotificationActions.REQUEST_NOTIFICATIONS:
            return {
                ...state,
                receivingNotifications: true
            };
        case NotificationActions.RECEIVE_NOTIFICATIONS:
            NotificationWaiter.actOnNotifications(action.data)
            return {
                ...state,
                notifications: action.data,
                receivingNotifications: false
            };
        case NotificationActions.REQUEST_BULK_UPLOAD_STATUS:
            let key2 = action.s3Key
            if(key2.indexOf("xlsx") !== -1 && key2.endsWith(".csv")) {
                key2 = key2.substring(0, key2.length - 4)
            }

            return {
                ...state,
                receivingBulkUploadProgress: {
                    ...state.receivingBulkUploadProgress,
                    [key2]: true,
                }
            };
        case NotificationActions.RECEIVE_BULK_UPLOAD_STATUS:
            // Remove .csv from s3 key if this is an xslx file key
            let key = action.data.s3_key 
            if(key.indexOf("xlsx") !== -1 && key.endsWith(".csv")) {
                key = key.substring(0, key.length - 4)
            }

            return {
                ...state,
                receivingBulkUploadProgress: {
                    ...state.receivingBulkUploadProgress,
                    [key]: false,
                },
                bulkUploadProgress: {
                    ...state.bulkUploadProgress,
                    [key]: {
                        s3Key: key,
                        numInProgress: action.data.num_in_progress_jobs,
                        numTotal: action.data.num_total_jobs,
                        processingErrors: action.data.processing_errors,
                        errors: action.data.errors,
                    }
                }
            };
        case CommonActions.CAPTCHA_REQUEST:
            return {
                ...state,
                receivingCaptcha: true
            };
        case CommonActions.CAPTCHA_RESPONSE:
            return {
                ...state,
                currentCaptcha: action as GetCaptchaResponsePayload,
                receivingCaptcha: false
            };
        case SegmentActions.REQUEST_FILTER_USERS:
            return { 
                ...state,
                receivingFilteredUsers: true,
            }
        case SegmentActions.REQUEST_FILTER_USERS_RESPONSE:
            return { 
                ...state,
                receivingFilteredUsers: false,
                filteredUsers : action.users,
                totalFilteredUsers: action.totalUsers,
            }
        case SettingsActions.REQUEST_DKIM_SETTINGS:
            return {
                ...state,
                receivingDKIMSettings: true
            };
        case SettingsActions.RECEIVE_DKIM_SETTINGS:
            return {
                ...state,
                receivingDKIMSettings: false,
                dkimSettings: action.getDKIMPayload,
            };
        case SettingsActions.REQUEST_DOMAIN_ADDITION:
            return {
                ...state,
                addingDomain: true,
            };
        case SettingsActions.REQUEST_DOMAIN_ADDITION_RESPONSE:
            return {
                ...state,
                addingDomain: false,
            };
        case EmailBlastActions.REQUEST_NEW_EMAIL_BLAST:
            return {
                ...state,
                emailBlasts: null,
            };
        case EmailBlastActions.REQUEST_EMAIL_BLASTS:
            return {
                ...state,
                receivingEmailBlasts: true
            };
        case EmailBlastActions.RECEIVE_EMAIL_BLASTS:
            const emailBlasts : {[key:string]: ScheduledEmailGroupWithMetrics } = {};
            if(action.data) {
                action.data.map((emailBlast:ScheduledEmailGroupWithMetrics) => emailBlasts[emailBlast.id] = emailBlast);
            }

            return {
                ...state,
                emailBlasts,
                receivingEmailBlasts: false
            };
        case EmailBlastActions.ARCHIVE_EMAIL_BLAST_RESPONSE:
            return {
                ...state,
                emailBlastsListKey: uuidv4(),
            };
        case EventActions.REQUEST_EVENTS:
            return {
                ...state,
                receivingEvents: true
            };
        case EventActions.RECEIVE_EVENTS:
            const events : {[key:string]: Event|SystemEvent } = {};
            if(action.data) {
                action.data.map((e:Event|SystemEvent) => events[e.id] = e);
            }

            return {
                ...state,
                events,
                receivingEvents: false
            };
        case EventActions.REQUEST_NUM_EVENTS:
            return {
                ...state,
                receivingNumEvents: true
            };
        case EventActions.RECEIVE_NUM_EVENTS:
            return {
                ...state,
                numEvents: action.numEvents,
                receivingNumEvents: false
            };
        case SenderPersonaActions.REQUEST_NEW_SENDER_PERSONA:
            return {
                ...state,
            };
        case SenderPersonaActions.REQUEST_NEW_SENDER_PERSONA_RESPONSE:
            const newSenderPersonas = {...state.senderPersonas} 
            if(action.senderPersona) {
                newSenderPersonas[action.senderPersona.id] = action.senderPersona
            }
            return {
                ...state,
                senderPersonas: newSenderPersonas,
            };
        case SenderPersonaActions.REQUEST_SENDER_PERSONAS:
            return {
                ...state,
                receivingSenderPersonas: true
            };
        case SenderPersonaActions.RECEIVE_SENDER_PERSONAS:
            const senderPersonas : {[key:string]: SenderPersona } = {};
            if(action.data) {
                action.data.map((senderPersona:SenderPersona) => senderPersonas[senderPersona.id] = senderPersona);
            }

            return {
                ...state,
                senderPersonas,
                receivingSenderPersonas: false
            };
        case EmailActions.REQUEST_EMAIL_SEND_RESULT:
            return {
                ...state,
                receivingEmailSendResults: true
            };
        case EmailActions.RECEIVE_EMAIL_SEND_RESULT:
            return {
                ...state,
                emailSendResults: {
                    ...action.data,
                },
                receivingEmailSendResults: false
            };
        case TemplateActions.REQUEST_NEW_TEMPLATE:
            return {
                ...state,
            };
        case TemplateActions.REQUEST_NEW_TEMPLATE_RESPONSE:
            return {
                ...state,
                templates: { ...state.templates, [action.template.id]:action.template },
                templatesListKey: uuidv4(),
            };
        case TemplateActions.REQUEST_TEMPLATES:
            return {
                ...state,
                receivingTemplates: true
            };
        case TemplateActions.RECEIVE_TEMPLATES:
            const templates : {[key:string]: Template } = {};
            if(action.data) {
                action.data.map((template:Template) => templates[template.id] = template);
            }

            return {
                ...state,
                templates,
                receivingTemplates: false
            };
        case TemplateActions.ARCHIVE_TEMPLATES_RESPONSE:
            if(action.ids) {
                const templates = state.templates;
                if(templates) {
                    //Remove archived templates from overall state
                    Object.values(action.ids).map(templateId => {
                        console.log("Removing template ", templateId);
                        delete templates[templateId];
                    });
                }

                return {
                    ...state,
                    templates,
                    templatesListKey: uuidv4(),
                };
            }

            return state;
        case CampaignActions.REQUEST_NEW_CAMPAIGN:
            return {
                ...state,
                campaigns: null,
            };
        case CampaignActions.REQUEST_CAMPAIGNS:
            return {
                ...state,
                receivingCampaigns: true
            };
        case CampaignActions.REQUEST_CAMPAIGNS_WITH_METRICS:
            return {
                ...state,
                receivingCampaignsWithMetrics: true
            }
        case CampaignActions.RECEIVE_CAMPAIGNS:
            const campaigns : {[key:string]: Campaign } = {};
            if(action.campaigns) {
                action.campaigns.map((campaign:Campaign) => campaigns[campaign.id] = campaign);
            }

            return {
                ...state,
                campaigns,
                campaignsListKey: uuidv4(),
                receivingCampaigns: false
            };
        case CampaignActions.REQUEST_NUM_CAMPAIGNS_WITH_METRICS:
            return {
                ...state,
                receivingNumCampaignsWithMetrics: true
            }
        case CampaignActions.RECEIVE_NUM_CAMPAIGNS_WITH_METRICS:
            return {
                ...state,
                numCampaignsWithMetrics: action.num,
                receivingNumCampaignsWithMetrics: false
            };
        case CampaignActions.RECEIVE_CAMPAIGNS_WITH_METRICS:
            return {
                ...state,
                campaignsListKey: uuidv4(),
                receivingCampaignsWithMetrics: false,
                campaignsWithMetrics: action.campaignsWithMetrics,
            };
        case CampaignActions.ARCHIVE_CAMPAIGNS_RESPONSE:
            if(action.success && action.campaignId) {
                const campaigns:{[key:string]:Campaign} = {}
                Object.values(state.campaigns).map(c => {
                    if(c.id !== action.campaignId) {
                        campaigns[c.id] = c
                    } 
                })
                const campaignsWithMetrics:CampaignWithMetrics[] = []
                Object.values(state.campaignsWithMetrics).map(c => {
                    if(c.campaign.id !== action.campaignId) {
                        campaignsWithMetrics.push(c)
                    } 
                })

                return {
                    ...state,
                    campaigns,
                    campaignsWithMetrics,
                    campaignsListKey: uuidv4(),
                };
            }

            return state;
        case SegmentActions.REQUEST_NEW_SEGMENT:
            return {
                ...state,
                users: null,
                usersInSegmentComponentKey: uuidv4(),
                reportData: {
                    ...state.reportData,
                    [userTableIds.LIST_USERS_TABLE]: null,
                },
                segments: null,
            };
        case SegmentActions.REQUEST_SEGMENTS:
            return {
                ...state,
                receivingSegments: true
            };
        case SegmentActions.RECEIVE_SEGMENTS:
            const segments : {[key:string]: Segment } = {};
            if(action.data) {
                action.data.map((segment:Segment) => segments[segment.id] = segment);
            } else {
                console.log("no segments recieved, got " + JSON.stringify(action))
            }

            return {
                ...state,
                segments,
                receivingSegments: false
            };
        case SegmentActions.RECEIVE_NEW_SEGMENT_RESPONSE:
            const segmentMap:{[key:string]:Segment} = {};
            action.segments.map(segment => segmentMap[segment.id] = segment)
            return {
                ...state,
                segments: {
                    ...state.segments,
                    ...segmentMap,
                }
            }
        case SegmentActions.REMOVE_USERS_FROM_SEGMENT_RESPONSE:
            if(action.success) {
                return {
                    ...state,
                    usersInSegmentComponentKey: uuidv4(),
                    usersNotInSegmentComponentKey: uuidv4(),
                };
            }
            return {
                ...state,
            };
        case SegmentActions.ADD_USERS_TO_SEGMENT_RESPONSE:
            if(action.success) {
                return {
                    ...state,
                    usersInSegmentComponentKey: uuidv4(),
                    usersNotInSegmentComponentKey: uuidv4(),
                };
            }
            return {
                ...state,
            };
        case SegmentActions.RECEIVE_SEGMENT_NAME_UPDATE_RESPONSE:
            if(action.data?.id) {
                state.segments[action.data.id].name = action.data.name
            }
            return {
                ...state,
            }
        case UsersActions.REQUEST_USERS:
            if(action.segmentIds && action.segmentIds.length > 0) {
                return {
                    ...state,
                    receivingUsersInSegments: true
                };
            }
            if(action.nonSegmentIds && action.nonSegmentIds.length > 0) {
                return {
                    ...state,
                    receivingUsersNotInSegments: true
                };
            }
            return {
                ...state,
                receivingUsers: true
            };
        case UsersActions.REQUEST_NUM_USERS:
            if(action.segmentIds && action.segmentIds.length > 0) {
                return {
                    ...state,
                    receivingNumUsersInSegments: true
                };
            }
            if(action.nonSegmentIds && action.nonSegmentIds.length > 0) {
                return {
                    ...state,
                    receivingNumUsersNotInSegments: true
                };
            }

            return {
                ...state,
                receivingNumUsers: true
            };
        
        // case UsersActions.REQUEST_NEW_USER:
        //     return {
        //         ...state,
        //         users: null,
        //     };
        case UsersActions.RECEIVE_USERS:
            const users : {[key:string]: User } = {};
            if(action.data) {
                action.data.map((u:User) => users[u.id] = u);
            }

            if(action.segmentIds && action.segmentIds.length > 0) {
                return {
                    ...state,
                    usersInSegments: users,
                    receivingUsersInSegments: false
                };
            }
            if(action.nonSegmentIds && action.nonSegmentIds.length > 0) {
                return {
                    ...state,
                    usersNotInSegments: users,
                    receivingUsersNotInSegments: false
                };
            }

            return {
                ...state,
                users,
                receivingUsers: false
            };
        case UsersActions.RECEIVE_NUM_USERS:
            if(action.segmentIds && action.segmentIds.length > 0) {
                return {
                    ...state,
                    numUsersInSegments: action.numUsers,
                    receivingNumUsersInSegments: false,
                };
            }
            if(action.nonSegmentIds && action.nonSegmentIds.length > 0) {
                return {
                    ...state,
                    numUsersNotInSegments: action.numUsers,
                    receivingNumUsersNotInSegments: false,
                };
            }

            return {
                ...state,
                numUsers: action.numUsers,
                receivingNumUsers: false,
            };
        case UsersActions.ARCHIVE_USERS_RESPONSE:
            if(action.success) {
                //Remove archived users from overall state
                if(action.emailToIdMap) {
                    Object.keys(action.emailToIdMap).map(email => {
                        const userObject = action.emailToIdMap[email]
                        const userId = userObject.id

                        //Remove data from non-reporting data sets
                        if(state.users) {
                            delete state.users[userId];
                        }
                    })
                }

                return {
                    ...state,
                    usersListKey: uuidv4(),
                    usersInSegmentComponentKey: uuidv4(),
                    usersCountListKey: uuidv4(),
                };
            }

            return state;
        case AuthActions.GET_COMPANY_USERS_REQUEST:
            return {
                ...state,
                receivingCompanyUsers: true
            };
        case AuthActions.ADD_COMPANY_USER_RESPONSE: 
            if(action.errors && action.errors.length > 0) {
                return state
            }
            if(!action.company_user || !action.company_user?.id) {
                return state
            } 
            return {
                ...state,
                companyUsers: {
                    ...state.companyUsers,
                    [action.company_user.id]: action.company_user,
                }
            }
        case AuthActions.REMOVE_COMPANY_USER_RESPONSE:
            if(action.errors && action.errors.length > 0) {
                return state
            }
            if(!action.removedUser || !action.removedUser?.id) {
                return state
            } 
            const newCompanyUsers:{[key:string]: CompanyUser} = {}
            Object.values(state.companyUsers).map(cu => {
                if(cu.id !== action.removedUser.id) {
                    newCompanyUsers[cu.id] = cu
                }
            })
            return {
                ...state,
                companyUsers: newCompanyUsers,
            }
        case AuthActions.GET_COMPANY_USERS_RESPONSE:
            const companyUsers:{[key:string]:CompanyUser} = {}
            action?.company_users?.map(cu => companyUsers[cu.id] = cu)
            return {
                ...state,
                companyUsers,
                receivingCompanyUsers: false
            };
        case WorkflowActions.REQUEST_NEW_WORKFLOW:
            return {
                ...state,
                workflows: null,
            };
        case WorkflowActions.REQUEST_WORKFLOWS:
            return {
                ...state,
                receivingWorkflows: true,
            };
        case WorkflowActions.RECEIVE_WORKFLOWS:
            const workflows : {[key:string]: Workflow } = {};
            if(action.workflows) {
                action.workflows.map((workflow:Workflow) => workflows[workflow.id] = workflow);
            }

            return {
                ...state,
                workflows,
                receivingWorkflows: false,
            };
        case WorkflowActions.ARCHIVE_WORKFLOWS_RESPONSE:
            if(action.workflow_id) {
                console.log("Removing workflow ", action.workflow_id);
                if(state.workflows) {
                    delete state.workflows[action.workflow_id];
                }

                return {
                    ...state,
                    workflowsListKey: uuidv4(),
                };
            }

            return state;
        case ReportActions.REQUEST_REPORT_DATA:
            return {
                ...state,
                receivingReportData: {
                    ...state.receivingReportData,
                    [action.forId]: true,
                },
            };
        case ReportActions.RECEIVE_REPORT_DATA:
            const reportData = state.reportData || {};
            reportData[action.forId] = action.reportData.length > 0 ? action.reportData : null;
            const reportErrors = state.reportErrors || {};
            reportErrors[action.forId] = action.reportErrors.length > 0 ? action.reportErrors : null;

            return {
                ...state,
                reportData,
                reportErrors,
                receivingReportData: {
                    ...state.receivingReportData,
                    [action.forId]: false,
                }
            };
        case AuthActions.LOGIN_REQUEST:
            return {
                ...state,
                processingLogin: true,
            };
        case AuthActions.LOGIN_RESPONSE:
            storeAuthInfo(action as LoginResponsePayload);
            if(getAuthKey()) {
                window.location.reload();
            }

            return {
                ...state,
                processingLogin: false,
            };
        case AuthActions.SIGNUP_REQUEST:
            return {
                ...state,
                processingSignUp: true,
            };
        case AuthActions.SIGNUP_RESPONSE:
            return {
                ...state,
                processingSignUp: false,
                waitingForSignUpArchitecture: action.code === 200,
            };
        case ContactUsActions.CONTACT_US_REQUEST:
                return {
                    ...state,
                    processingContactUs: true,
                };
        case ContactUsActions.CONTACT_US_RESPONSE:
            return {
                ...state,
                processingContactUs: false,
            };
        case HealthActions.REQUEST_EMAIL_PIPELINE_REPORT:
            return {
                ...state,
                receivingEmailPipelineReport: true,
            };
        case HealthActions.RECEIVE_EMAIL_PIPELINE_REPORT:
            console.log(action.report);
            return {
                ...state,
                receivingEmailPipelineReport: false,
                emailPipelineReport: action.report,
            };
        case HealthActions.REQUEST_GREP_LOG:
            return {
                ...state,
                receivingGrepLog: {
                    ...state.receivingGrepLog,
                    [action.grepFor]: true,
                }
            };
        case HealthActions.RECEIVE_GREP_LOG:
            return {
                ...state,
                grepLog: {
                    ...state.grepLog,
                    [action.report.grep_for]: action.report,
                },
                receivingGrepLog: {
                    ...state.receivingGrepLog,
                    [action.report.grep_for]: false,
                }
            };
        case HealthActions.REQUEST_ARCHITECTURE_ATTRIBUTES:
            return {
                ...state,
                receivingArchitectureAttributes: true,
            };
        case HealthActions.RECEIVE_ARCHITECTURE_ATTRIBUTES:
            return {
                ...state,
                receivingArchitectureAttributes: false,
                architectureAttributes: action.report,
            };
        case NavActions.NAV_LINK_CLICKED:
            console.log("Selecting nav path " + action.path);
            return {
                ...state,
                navPath: action.path,
            };
        case PagingToolbarActions.PREV_PAGE:
        case PagingToolbarActions.NEXT_PAGE:
            return {
                ...state,
                paging: {
                    ...state.paging,
                    [action.tableId]: {
                        offset: action.offset || 0,
                        limit: action.limit || 10,
                    },
                }
            }
        default:
            return state
    }
}


