import React from "react";
import {CircularProgress, Typography} from "@material-ui/core";

export function Loading(props?:{mySize?:string, style?:any, hideText?:boolean, text?:JSX.Element, overlay?: boolean})  {
    let textStyle:any;
    let progressSize;
    let mySize = "md";
    const overlayStyle = props.overlay ? { 
        position:"absolute",
        paddingLeft:"20%",
        paddingTop:"15%",
        zIndex: 2, 
        width:"100%", 
        height:"100%", 
        backgroundColor: "rgba(255,255,255,0.7)" 
    } : {}

    if(props && props.mySize) {
        mySize = props.mySize;
    }
    switch(mySize) {
        case "lg":
            textStyle = {
                fontSize:"2.5em",
                marginLeft:"9px",
            };
            progressSize = 40;
            break;
        case "sm":
            textStyle = {
                fontSize:"1.3em",
                marginLeft:"3px",
            };
            progressSize = 16;
            break;
        case "xs":
            textStyle = {
                fontSize:"1.0em",
                marginLeft:"1px",
            };
            progressSize = 10;
            break;
        default:
            textStyle = {
                fontSize:"2.0em",
                marginLeft:"6px",
            };
            progressSize = 30;
            break;
    }


    return <div style={{
                display:"inline-block",
                height:progressSize +"px", 
                ...overlayStyle,
                ...props.style
            }}>
            <div style={{display:"inline-block", height:progressSize +"px"}}>
                <CircularProgress size={progressSize} color={"secondary"} />
            </div>
            {!props.hideText && <div style={{display:"inline-block", height:progressSize +"px"}}>
                {props.text ||<Typography variant="body1" style={textStyle} component="span">
                    Loading...
                </Typography>}
            </div>}
        </div>

}