import {IChart, ILink, INode, IPosition} from "@mrblenny/react-flow-chart/src";
import {INodeSettings, usersLocalTimezone} from "./NodeSettings";
import {Node} from "../generated/types/payloadTypes"
import {v4 as uuidv4} from "uuid";
import moment from "moment-timezone";

interface MyNodeSettings {
  delay_ms?: number;
  template_id?: string;
  sender_persona_id?: string;
  delay_until?: moment.Moment;
  delay_until_users_timezone?: boolean;
  delay_until_any_date?: boolean;
  delay_until_exclusive?: boolean; //true if we should drop any events that occur after the selected date/time
  event_name?: string,
}

interface MyNode{
  id: string;
  type: string;
  position: IPosition;
  event_topic?: string;
  event_type?: string;
  children?: MyNode[];
  settings?: MyNodeSettings;
}

export interface WorkflowJSON {
  display: {
      nodes: {[key:string]: ChartNode},
  },
  links?:{[key:string]:ILink}
}

interface ChartNode {
  id:string,
  ports:{[key:string]:{id:string,position:IPosition,type:string}},
  position:IPosition,
  properties:{[key:string]:string},
  size:{width:number, height:number},
  type: string,
}

const eventTopicMap: {[key:string]:string} = {
  "user_upserted": "user",
  "event": "event",
};

function getRootNodeEventType(rootNode: INode): string {
  if(rootNode.type === "user_upserted") {
    return "user_upserted";
  }

  const settings = mapNodeSettings(rootNode.properties);
  return settings.event_name;
}

function mapNodeSettings(from:INodeSettings):MyNodeSettings {
  if(!from) {
    console.warn("Can't convert empty settings map");
    return {};
  }

  if(from.delayUntil && !from.delayUntil.tz) {
    from.delayUntil = moment(from.delayUntil)
  }

  // Dates are displayed in UTC, so if we are supposed to be waiting for a specific
  // timezone then we need to pretend the same day/hour was given but in that timezone instead
  if(from.delayUntil && from.delayUntil.tz && from.delayUntilTimezone && from.delayUntilTimezone !== usersLocalTimezone) {
    from.delayUntil = moment.tz(from.delayUntil.format("YYYY-MM-DDTHH:mm:ss"), from.delayUntilTimezone)
    console.log("converted from UTC to " +  from.delayUntilTimezone)
  }
  return {
    delay_ms: from.delayMS,
    template_id: from.templateId,
    sender_persona_id: from.senderPersonaId,
    delay_until: from.delayUntil || moment.utc(), 
    delay_until_users_timezone: from.delayUntilTimezone === usersLocalTimezone,
    delay_until_any_date: from.delayUntilAnyDate,
    delay_until_exclusive: from.delayUntilExclusive,
    event_name: from.eventName,
  }
}

function getNodeChildren(workflow: IChart, node: INode, num = 0) : MyNode[] {
  num++;
  if(num > 200) {
    console.error("Detected either insane number of nodes or we have an infinite loop in the workflow");
    return [];
  }

  const children:MyNode[] = [];
  for(const linkId in workflow.links) {
    const link:ILink = workflow.links[linkId];
    if(link.from.nodeId === node.id) {
      const workflowChildNode = workflow.nodes[link.to.nodeId]
      children.push({
        id:workflowChildNode.id,
        type:workflowChildNode.type.toUpperCase(),
        position:workflowChildNode.position,
        children:getNodeChildren(workflow, workflowChildNode, num),
        settings:mapNodeSettings(workflowChildNode.properties),
      });
    }
  }

  return children;
}

function findRootNode(workflow: IChart) : INode {
  let rootNode:INode = null;

  //Find root node
  for(const nodeId in workflow.nodes) {
    const node:INode = workflow.nodes[nodeId];
    if(node.type === "user_upserted" || node.type === "event") {
      if(rootNode) {
        console.error("Two+ root nodes in workflow");
        return null;
      }
      rootNode = node; //Keep going even though we found it here, so we can catch errors
    }
  }

  return rootNode;
}

function MyNodeToNode(node: MyNode) : Node {
  return {
    id: node.id,
    type: node.type,
    event_topic:node.event_topic,
    event_type:node.event_type,
    children: node.children.map(myNode => MyNodeToNode(myNode)),
    settings: {
      delay_ms: node.settings.delay_ms ? node.settings.delay_ms : 0,
      sender_persona_id: node.settings.sender_persona_id ? node.settings.sender_persona_id : "",
      template_id: node.settings.template_id ? node.settings.template_id : "",
      send_at_close_enough_ms: 0, //Should be ignored
      delay_until: node.settings.delay_until ? node.settings.delay_until : null,
      delay_until_users_timezone: !!node.settings.delay_until_users_timezone,
      delay_until_any_date: !!node.settings.delay_until_any_date,
      delay_until_exclusive: !!node.settings.delay_until_exclusive,
    },
  }
}

export function IChartToNode(workflow: IChart) : Node {
  const rootNode = findRootNode(workflow);
  if(!rootNode) {
    return null;
  }


  return {
    id: rootNode.id,
    type: "ROOT",
    event_topic: eventTopicMap[rootNode.type],
    event_type: getRootNodeEventType(rootNode),
    children: getNodeChildren(workflow, rootNode).map(myNode => MyNodeToNode(myNode)),
  }
}


export function GetDisplayFromNodes(thisNode:Node, eventName:string, depth = 0, yMap:{[key:number]:number} = {}, timezoneMap:{[key:string]: {tz:string,dateString:string,timeString:string}} = {}): WorkflowJSON {
  const workflowJSON:WorkflowJSON = {
    display: {
      nodes: {},
    },
    links: {},
  }
  thisNode.type = thisNode.type.toUpperCase()

  //Ports
  const bottomPorts = [0,1,2,3,4].map(portNum => {
    return {
      id: uuidv4(),
      position: {
        x: 23 + 26 * portNum,
        y: 95 + 93 * depth,
      },
      type:"bottom",
    }
  })
  const bottomPortsMap:any = {}
  bottomPorts.map((p:any) => bottomPortsMap[p.id] = p)
  const topPorts = [0,1,2,3,4].map(portNum => {
    return {
      id: uuidv4(),
      position: {
        x: 23 + 26 * portNum,
        y: -3 + 93 * depth,
      },
      type:"top",
    }
  })
  const topPortsMap:any = {}
  topPorts.map((p:any) => topPortsMap[p.id] = p)


  let ports = {...bottomPortsMap, ...topPortsMap}
  if(thisNode.type === "SENDER") {
    ports = topPortsMap
  } else if(thisNode.type === "ROOT") {
    ports = bottomPortsMap
  }

  let properties = {}
  if(depth === 0) {
    properties = {
      eventName,
    }
  } else if(thisNode.type === "SENDER") {
    properties = {
      senderPersonaId: thisNode.settings?.sender_persona_id,
      templateId: thisNode.settings?.template_id,
    }
  } else if(thisNode.type === "DELAY") {
    properties = {
      delayMS: thisNode.settings?.delay_ms,
    }
  } else if(thisNode.type === "DELAY_UNTIL") {
    const delayUntil = thisNode.settings?.delay_until
    const delayUntilData = timezoneMap[thisNode.id]
    if(!delayUntilData) {
      throw new Error("missing timezone for node " + thisNode.id + ".  Options were " + JSON.stringify(timezoneMap))
    }

    properties = {
      delayUntil: delayUntil,
      delayUntilDate: delayUntilData.dateString,
      delayUntilAnyDate: !!thisNode.settings?.delay_until_any_date,
      delayUntilTimezone: delayUntilData.tz,
      delayUntilExclusive: !!thisNode.settings?.delay_until_exclusive,
    }
  }

  //Node
  const y = 10 + 150 * depth
  if(yMap[y]) {
    yMap[y]++
  } else {
    yMap[y] = 1
  }
  const myNode:ChartNode =  {
      id: thisNode.id,
      ports,
      position: {
        x: 11 + (yMap[y]-1) * 160,
        y,
      },
      properties,
      size: {
        width: 151,
        height: 93
      },
      type: thisNode.type==="ROOT"?"event":thisNode.type.toString().toLowerCase(),
  }
  workflowJSON.display.nodes[myNode.id] = myNode
  thisNode.children.map(n => {
    const d = GetDisplayFromNodes(n, eventName, depth + 1, yMap, timezoneMap)
    workflowJSON.display.nodes = {
      ...workflowJSON.display.nodes,
      ...d.display.nodes,
    }
  })

  //Links
  if(depth === 0) {
    const allLinkPairs = GetLinkPairs(thisNode, workflowJSON.display.nodes, thisNode.id, null)
    allLinkPairs.map(pair => {
      const linkId = uuidv4()
      workflowJSON.links[linkId] = {
        id: linkId,
        from: {
          nodeId: pair.fromNode,
          portId: pair.from,
        },
        to: {
          nodeId: pair.toNode,
          portId: pair.to,
        }
      }
    })
  }

  return workflowJSON
}

function GetLinkPairs(thisNode:Node, chartNodes:{[key:string]:ChartNode}, lastBottomNodeId: string, lastBottom:any): {from:string, fromNode:string, to:string, toNode: string}[] {
  let links:{from:string, to:string, fromNode:string, toNode:string}[] = []

  const thisBottomPorts:{id:string}[] = []
  const thisTopPorts:{id:string}[] = []
  const thisChartNode = chartNodes[thisNode.id]
  Object.values(thisChartNode.ports).map(port => {
    if(port.type === "bottom") {
      thisBottomPorts.push(port)
    } else {
      thisTopPorts.push(port)
    }
  })

  if(lastBottom && thisTopPorts.length > 0) {
    links.push({
      from:lastBottom.id, 
      to: thisTopPorts[0].id,
      fromNode: lastBottomNodeId,
      toNode:thisNode.id,
    })
  }

  if(thisBottomPorts.length === 0) {
    return links
  }

  thisNode.children.map(c => {
    links = [
      ...links,
      ...GetLinkPairs(c, chartNodes, thisNode.id, thisBottomPorts[0])
    ]
  })

  return links
}
