import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {NavDef, routeExists, routes} from "./RoutesV2";
import {connect} from "react-redux";
import { JobLogEntry} from "../generated/types/payloadTypes";
import {  Typography, List, ListItem, ListItemIcon, Card } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, RadioButtonUnchecked } from '@material-ui/icons';
import { myTheme } from '../App';

interface IState {
    expandedByHref:{[key:string]:boolean}
    lastClickedHref: string,
}
interface IProps {
    dispatch?: any,
    notifications?: JobLogEntry[],
    location: {
        pathname: string,
    },
}

class LeftNavV2 extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props: IProps) {
        super(props);
        this.props = props;
        this.state = {
            expandedByHref: {},
            lastClickedHref: "",
        }
        this.onClickLink = this.onClickLink.bind(this)
    }

    componentDidMount() {
        // Re-open whatever path on the right was open before refresh
        const pathnameParts = this.props.location.pathname.split("/")
        const pathnameMap: {[key:string]: boolean} = {} 
        let lastClickedHref = this.props.location.pathname
        for(let i = 1; i < pathnameParts.length; i++) {
            let thisPath = ""
            for(let j = 1; j <= i; j++) {
                thisPath += "/" + pathnameParts[j]
            }
            if(routeExists(routes, thisPath)) {
                pathnameMap[thisPath] = true
                lastClickedHref = thisPath
            }
        }

        this.setState({
            ...this.state,
            expandedByHref: {
                ...this.state.expandedByHref, 
                ...pathnameMap,
            },
            lastClickedHref,
        })
    }

    unexpandAllChildNodesMap(routeDef: NavDef):{childRoutes: {[key:string]:boolean}, hadExpandedChild: boolean} {
        let childRoutesMap:{[key:string]:boolean} = {}
        let hadExpandedChildLocal = false
        if(routeDef.children && routeDef.children.length > 0) {
            routeDef.children.map((c:NavDef) => {
                childRoutesMap[c.href] = false
                if(c.children && c.children.length > 0) {
                    c.children.map((cc:NavDef) => {
                        const { childRoutes, hadExpandedChild } = this.unexpandAllChildNodesMap(cc)
                        childRoutesMap = {
                            ...childRoutesMap,
                            ...childRoutes,
                        }
                        hadExpandedChildLocal = hadExpandedChildLocal || hadExpandedChild
                    })
                }
            })
        }


        return {childRoutes: childRoutesMap, hadExpandedChild: hadExpandedChildLocal}
    }

    onClickLink(routeDef:NavDef, e:any) {
        let expandedByHref = {...this.state.expandedByHref}
        if(expandedByHref[routeDef.href]) {
            if(this.state.lastClickedHref === routeDef.href) {
                const { childRoutes } = this.unexpandAllChildNodesMap(routeDef)
                expandedByHref = {
                    ...expandedByHref,
                    ...childRoutes,
                    [routeDef.href]: false,
                }
            }
        } else if(routeDef.children && routeDef.children.length > 0) {
            expandedByHref[routeDef.href] = true
        }
        const lastClickedHref = routeDef.href

        this.setState({
            ...this.state,
            expandedByHref,
            lastClickedHref,
        })
    }

    renderNavNode(routeDef: NavDef, depth = 0, parentHrefs:string[] = []): JSX.Element {
        const paddingLeft = (depth * 5).toString() + "px"
        const paddingLeftLeaf = (5 + depth * 5).toString() + "px"
        let textStyle:any = {
            color: "#000",
            fontSize: (16 - depth).toFixed(0) + "pt",
            fontWeight: "normal",
            textDecoration: "none",
            textShadow: "",
            boxShadow: "",
        }
        let iconColor = "#000"
        let iconClassName = ""
        if(this.state.lastClickedHref === routeDef.href) {
            textStyle = {
                color: myTheme.palette.primary.main,
                fontWeight: "normal",
                fontSize: (17 - depth).toFixed(0) + "pt",
                textShadow: "1px 1px 2px #eee",
                boxShadow: "1px 1px 2px #eee",
            }
            iconColor = myTheme.palette.primary.main
            iconClassName = "svgShadow" 
            
        }

        if(routeDef.children && routeDef.children.length > 0) {
            return <>
             <Link to={routeDef.href} style={textStyle} onClick={(e) => this.onClickLink(routeDef, e)}>
                <ListItem style={{paddingLeft, minWidth:"200px", width:"100%", paddingTop:"5px", paddingBottom:"5px"}}>
                    <ListItemIcon className={iconClassName} style={{minWidth:"15px", color:iconColor}}>{this.state.expandedByHref[routeDef.href] ? <ArrowDropUp style={{transform:"rotate(90deg)"}} /> : <ArrowDropDown />}</ListItemIcon>
                    <ListItemIcon className={iconClassName} style={{minWidth:"15px", color:iconColor, visibility:(routeDef.icon?"visible":"hidden")}}>{routeDef.icon}</ListItemIcon>
                    <Typography component="p" style={{marginLeft:"5px", fontSize:textStyle.fontSize,fontWeight:textStyle.fontWeight}}>{routeDef.text}</Typography>
                </ListItem>
            </Link> 

            {!!this.state.expandedByHref[routeDef.href] && <ListItem>
                <List style={{paddingTop:"0", paddingBottom:"0"}}>
                {routeDef.children.map(child => this.renderNavNode(child, depth+1, [...parentHrefs, routeDef.href]))}
                </List> 
            </ListItem>}
            </>
        }

        return <Link to={routeDef.href} style={textStyle} onClick={(e) => this.onClickLink(routeDef, e)}>
            <ListItem style={{paddingLeft:paddingLeftLeaf, minWidth:"200px", width:"100%", paddingTop:"5px", paddingBottom:"5px"}}>
            <ListItemIcon className={iconClassName} style={{minWidth:"15px", color:iconColor}}><RadioButtonUnchecked fontSize="small" style={{width:"8px"}} /></ListItemIcon>
            <Typography component="p" variant="body1" style={{fontSize:textStyle.fontSize,fontWeight:textStyle.fontWeight}}>{routeDef.text}</Typography>
            </ListItem>
            </Link>
    } 

    render() {
        return <>
            <div style={{opacity:1, backgroundColor:"white", paddingLeft:"0.5rem", marginTop:"0", height:"100%", minHeight:"100dvh"}}>
            <div style={{width:"100%", position:"sticky", top:0, zIndex:1,  paddingTop:"1.5rem"}}>
            <List>
                {routes.map(r => this.renderNavNode(r))}
            </List>
            </div>
            </div>
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return {...state }
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(LeftNavV2));