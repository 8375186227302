import React from "react";
import {connect} from "react-redux";
import {fetchCaptcha} from "../actions/Common";
import {Loading} from "./Loading";
import {GetCaptchaResponsePayload} from "../generated/types/payloadTypes";


interface IState {
    imageBytes: any,
    currentCaptcha: GetCaptchaResponsePayload,
}

interface IProps {
    dispatch: any,
    receivingCaptcha?: boolean,
    fetchedCaptcha?: GetCaptchaResponsePayload,
    style?: any,
}

class Captcha extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            imageBytes: null,
            currentCaptcha: null,
        };
    }

    componentDidMount(): void {
        this.props.dispatch(fetchCaptcha());
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(nextProps.fetchedCaptcha) {
            return {
                ...prevState,
                currentCaptcha: nextProps.fetchedCaptcha,
            }
        }

        return null;
    }

    render() {
        return <div>
            {this.props.receivingCaptcha && <Loading mySize={"sm"} />}
            {this.state.currentCaptcha && <img  style={this.props.style} src={"data:image/png;base64," + this.state.currentCaptcha.captcha_image_bytes} />}
        </div>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    let fetchedCaptcha = null;
    if(state.currentCaptcha) {
        fetchedCaptcha = state.currentCaptcha;
    }

    return { ...state, fetchedCaptcha };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(Captcha)


