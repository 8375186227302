import { Typography, RadioGroup, FormControlLabel, Radio, Grid, TextField } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { AllState } from "../../reducers";
import { Link } from "react-router-dom";
import { Loading } from "../../components/Loading";
import ListTemplates from "../../ListTemplates";
import {v4 as uuidv4} from "uuid";
import { fetchTemplates } from "../../actions/Templates";
import { Template } from "../../generated/types/payloadTypes";

interface IProps {
    width: string,
    dispatch: any,
    templates?: {[key:string]:Template},
    selectedTemplateIds?: string[], //We need this so we can query for more than 1 id when creating a new template
    onTemplateChange: (t:Template, isNewTemplate: boolean) => void
    templateId: string,
    isNewTemplate: boolean,
    numEmails?: number,
    forABTest?: boolean,
    emailNum?: number,
}
interface IState {
    newTemplateId: string,
}
let templateCheckerIntervalId:NodeJS.Timeout = null

class SelectSenderPersona extends React.Component<IProps, IState> {
    state:IState
    props:IProps

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            newTemplateId: uuidv4(),
        };
    }

    
    componentDidMount() {
        this.startTemplateChecker(this.props.templateId)
     }
 
     componentWillUnmount() {
         if(templateCheckerIntervalId) {
             clearInterval(templateCheckerIntervalId)
         }
     }
 

    startTemplateChecker(templateId:string) {
        if(templateCheckerIntervalId) {
            console.log("clearing previous template checker")
            clearInterval(templateCheckerIntervalId)
            templateCheckerIntervalId = null
        }
        templateCheckerIntervalId = setInterval(() => {
            if(this.props.isNewTemplate) {
                if(this.props.templates && this.props.templates[templateId]) {
                    clearInterval(templateCheckerIntervalId)
                    templateCheckerIntervalId = null
                } else {
                    const ids = [templateId]

                    if(this.props.selectedTemplateIds) {
                        ids.push(...this.props.selectedTemplateIds)
                    }
                    this.props.dispatch(fetchTemplates(ids))
                }
            } else {
                clearInterval(templateCheckerIntervalId)
                templateCheckerIntervalId = null
            }
        }, 2000)
    }

    headerText():string {
        if(this.props.forABTest) {
            return "Please select template number " +  (this.props.emailNum?.toString()||"1")
        } else if(this.props.numEmails) {
            return "What template do you want to use for email number "+ (this.props.emailNum?.toString()||"1") + "?"
        }

        return "What template do you want to use?"
    }

    templateChange(isNewTemplate: boolean) {
        const template = new Template()
        template.id = this.props.templateId
        if(isNewTemplate) {
            const newTemplateId = uuidv4()
            template.id = newTemplateId
            this.setState({
                ...this.state, 
                newTemplateId,
            })
            this.startTemplateChecker(newTemplateId)
        } else {
            if(templateCheckerIntervalId) {
                clearInterval(templateCheckerIntervalId)
                templateCheckerIntervalId = null
            }
        }

        this.props.onTemplateChange(template, isNewTemplate)
    }

    render() {
        return <>
        <Typography variant="h2" component="p">
            {this.headerText()}
        </Typography>
        <RadioGroup
            style={{paddingLeft:"1rem"}}
            value={this.props.isNewTemplate}
            row={false} 
            name="templateType"
            onChange={(e) => {
                e.stopPropagation(); 
                e.preventDefault();  
                this.templateChange(e.target?.value?.toString() === "true")
            }}>
                <FormControlLabel value={false} control={<Radio color="primary" value={false} />} 
                    label={<Typography variant="body1" component="span">An existing template</Typography>} 
                    color="primary" />
                <FormControlLabel value={true} control={<Radio color="primary" value={true} />} 
                    label={<Typography variant="body1" component="span">A new template</Typography>} 
                    color="primary" />
        </RadioGroup>
        {this.props.isNewTemplate ? <div>
            <Typography variant="h2" component="div" style={{marginTop:"1rem", marginBottom:"0.5rem"}}>New template</Typography>
            {(!this.props.templates || !this.props.templates[this.props.templateId])  ? <>
                <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                    To create a new template, please &nbsp;<Link to={"/components/templates/new/" + this.state.newTemplateId} target="_blank">click here</Link>&nbsp;
                    to open a new tab.  When you've saved your new template come back to this page.
                </Typography>
                <Loading mySize="sm" style={{marginBottom:"1rem"}} text={<Typography variant={"body1"} component={"span"} style={{paddingLeft:"0.5rem"}}>Waiting for template to be saved....</Typography>} />
                </>:<div style={{marginBottom:"1rem"}}>
                    <Typography variant={"body1"} component={"span"} style={{color:"green",marginRight:"3px"}}>
                        Template&nbsp;
                        {this.props.templates && this.props.templates[this.props.templateId]?<Link to={"/components/templates/edit/" + this.state.newTemplateId} target="_blank">{this.props.templates[this.state.newTemplateId] ?this.props.templates[this.state.newTemplateId].name:""}</Link>:""} 
                        &nbsp;selected!
                    </Typography>
                    <Typography variant={"body1"} component={"span"}>
                        To create a different template instead,&nbsp;<Link to="" onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            this.templateChange(true)
                        }}>click here</Link>
                    </Typography>
                </div>}
          </div>
        : <div style={{marginTop:"1.5rem", marginBottom:"2rem",paddingLeft:"1rem"}}>
            <ListTemplates type="select" 
                selectedTemplateId={this.props.templateId}
                width={this.props.width} 
                dispatch={this.props.dispatch} 
                style={{width:"100%", maxWidth:"500px", minWidth:"200px"}} 
                label='Select your template'
                onChange={e => {
                    e.stopPropagation()
                    e.preventDefault()

                    const template = new Template()
                    template.id = e.target?.value?.toString()
                    this.props.onTemplateChange(template, false)
                }}
            />
        </div>}
    </>
    }
}


function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectSenderPersona)
