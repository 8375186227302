import { Button, Grid, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment-timezone";
import React from "react";
import { connect } from "react-redux";

interface IProps {
    dateLabel?: string,
    isDateClearable?: boolean,
    disabled?: boolean,
    disableMinDate?: boolean,
    hideDatePicker?: boolean,
    hideTimePicker?: boolean,
    dispatch: any,
    datetimeMoment?: moment.Moment,
    onChange: (newDate:moment.Moment) => void,
}


class DateTimePickerRow extends React.Component<IProps, any>  {
    props:IProps
  
    constructor(props:IProps) {
      super(props);
      this.props = props;
      this.onChangeToUTC = this.onChangeToUTC.bind(this)
    }

    onChangeToUTC(from: moment.Moment) {
        if(!from) {
            this.props.onChange(null)
            return 
        }
        const definitelyUTCMoment = moment.tz(from.format('YYYY-MM-DDTHH:mm:ss'), "UTC").seconds(0)
        this.props.onChange(definitelyUTCMoment)
    }

    render() {
        return <Grid container  wrap="nowrap">
             {!this.props.hideDatePicker && <Grid item style={{width:!this.props.hideTimePicker?"20%":"100%", maxWidth:!this.props.hideTimePicker?"200px":"inherit", minWidth:"100px"}}>
               <MobileDatePicker
                    label={this.props.dateLabel}
                    disabled={!!this.props.disabled}
                    value={this.props.datetimeMoment}
                    onAccept={this.onChangeToUTC}
                    onChange={this.onChangeToUTC}
                    minDate={(this.props.disableMinDate || this.props.disabled)? null : (moment.tz.guess() ? 
                        moment.tz(moment.tz(moment().toDate(), moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'), "UTC") : 
                        moment("1900-01-01T00:00:00Z"))}
                    renderInput={(params:any) => 
                        <TextField  style={{width:"100%" }}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: this.props.isDateClearable? 
                                    <IconButton  size="small" style={{color:"gray", visibility: this.props.datetimeMoment ? "visible":"hidden"}}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            this.onChangeToUTC(null)
                                        }}><Clear /></IconButton> : null,
                            }}
                            />}
                    
                    />
            </Grid>}
            {!this.props.hideTimePicker && <Grid item style={{width:!this.props.hideDatePicker?"80%":"100%", minWidth:"100px"}}>
                <MobileTimePicker
                    disabled={!!this.props.disabled}
                    value={this.props.datetimeMoment}
                    onChange={this.onChangeToUTC} 
                    onAccept={this.onChangeToUTC}
                    renderInput={(params:any) => <TextField  style={{width:"100%"}} {...params} />}
                    />
            </Grid>}
        </Grid>
    }
}


function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(DateTimePickerRow);
