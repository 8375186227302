
export class UpsertAccountPayload {
  company_name:string;
  project_name:string;
  email:string;
  first_name:string;
  last_name:string;
}
export class UpsertAccountResponsePayload {
  account_id:string;
  errors:string[];
}
export class CompanyUserEnvVars {
  endpoints:any;
  api_key:string;
}
export class Company {
  id:string;
  name:string;
  domains:string[];
  display_name:string;
  created_at?:any;
  updated_at?:any;
}
export class Project {
  id:string;
  company_id:string;
  name:string;
  display_name:string;
  created_at?:any;
  updated_at?:any;
}
export class CompanyUser {
  id:string;
  company_id:string;
  project_id:string;
  first_name:string;
  last_name:string;
  email:string;
  password_reset_at?:any;
  created_at?:any;
  updated_at?:any;
  json:{[key:string]:any};
}
export class LoginResponsePayload {
  api_client_key:string;
  user:CompanyUser;
  company:Company;
  project:Project;
  endpoints:any;
  errors:string[];
  not_in_sandbox?:any;
}
export class LoginPayload {
  email:string;
  password:string;
}
export class SignUpPayload {
  company_user:CompanyUser;
  password:string;
  company_display_name:string;
  project_display_name:string;
  captcha_key:string;
  captcha_answer:string;
  product_package_id:string;
}
export class AddCompanyUserPayload {
  company_user:CompanyUser;
  password:string;
  company_name:string;
  project_name:string;
}
export class RemoveCompanyUserPayload {
  company_user:CompanyUser;
  company_name:string;
  project_name:string;
}
export class ProductPackage {
  id:string;
  name:string;
  config_details:any;
}
export class GetCompanyUsersResponsePayload {
  company_users:CompanyUser[];
  errors:string[];
}
export class AddCompanyUserResponsePayload {
  company_user:CompanyUser;
  errors:string[];
}
export class RemoveCompanyUserResponsePayload {
  errors:string[];
}
export class SignUpResponsePayload {
  company_user:CompanyUser;
  company:Company;
  project:Project;
  errors:string[];
}
export class GetCaptchaResponsePayload {
  captcha_image_bytes:any[];
  captcha_key:string;
  errors:string[];
}
export class CheckCaptchaPayload {
  key:string;
  answer:string;
}
export class CheckCaptchaResponsePayload {
  errors:string[];
}
export class GetSecretsPayload {
  company_name:string;
  project_name:string;
}
export class GetSecretsResponsePayload {
  s:any[];
  errors:string[];
}
export class Campaign {
  id:string;
  name:string;
  type:string;
  workflow_ids:string[];
  template_ids:string[];
  scheduled_email_blast_group_ids:string[];
  created_at?:any;
  updated_at?:any;
}
export class UpsertCampaignPayload {
  campaign:Campaign;
}
export class GetCampaignsResponsePayload {
  campaigns:Campaign[];
  errors:string[];
}
export class CampaignWithMetrics {
  campaign:Campaign;
  template_id:string;
  template_name:string;
  sends:number;
  opens:number;
  clicks:number;
  bounces:number;
  unsuccessful_sends:number;
  unsubscribes:number;
  complaints:number;
}
export class GetCampaignsWithMetricsResponsePayload {
  campaigns_with_metrics:CampaignWithMetrics[];
  errors:string[];
}
export class GetNumCampaignsWithMetricsResponsePayload {
  num_campaigns:number;
  errors:string[];
}
export class UpsertCampaignResponsePayload {
  campaign?:Campaign;
  errors:string[];
}
export class ArchiveCampaignsResponsePayload {
  campaign_id:string;
  errors:string[];
}
export class WeightedTemplateID {
  template_id:string;
  weight:number;
}
export class ScheduledEmailDetails {
  to_segment_ids:string[];
  to_user_ids:string[];
  to_user_emails:string[];
  cc_to_user_ids:string[];
  bcc_to_user_ids:string[];
  sender_persona_id:string;
  template_id:string;
  template_ids_with_weights:WeightedTemplateID[];
  timezone:string;
}
export class S3MailerLogPayload {
  mailer_payload:MailerPayload;
  send_error:string;
}
export class EmailContact {
  user_id:string;
  email:string;
  name:string;
}
export class MailerPayload {
  from:EmailContact;
  to:EmailContact[];
  cc:EmailContact[];
  bcc:EmailContact[];
  reply_to?:EmailContact;
  subject:string;
  body:string;
  campaign_id:string;
  segment_id:string;
  workflow_id:string;
  scheduled_email_group_id:string;
  template_id:string;
  channel:string;
}
export class GenericResponsePayload {
  errors:string[];
}
export class ProxyPayload {
  topic:string;
  input?:any;
}
export class ContactUsPayload {
  name:string;
  email:string;
  details:string;
  captcha_id:string;
  captcha_answer:string;
}
export class IsDuplicateResponsePayload {
  is_duplicate:boolean;
  errors:string[];
}
export class IsDuplicateBucketKeyResponsePayload {
  is_duplicate:boolean;
  errors:string[];
}
export class LogProcessedBucketKeyPayload {
  worker_id:string;
  bucket:string;
  key:string;
}
export class LogProcessedBucketKeyResponsePayload {
  num_dupicates:number;
  errors:string[];
}
export class LogProcessedIdPayload {
  worker_id:string;
  id:string;
}
export class LogProcessedIdResponsePayload {
  num_dupicates:number;
  errors:string[];
}
export class CreateArchitectureForCompanyProjectPayload {
  arch_payload:any;
  dq_arch_payload:any;
}
export class CreateArchitectureForCompanyProjectResponsePayload {
  errors:string[];
}
export class UpsertArchitectureStateResponsePayload {
  errors:string[];
}
export class UpsertDQArchitectureStateResponsePayload {
  errors:string[];
}
export class AddUsersToDatabasePayload {
  company_name:string;
  project_name:string;
  user:string;
  pass:string;
  read_only_user:string;
  read_only_pass:string;
}
export class LoggerS3Payload {
  send_error:string;
  sent_to:string;
  mailer_payload?:MailerPayload;
}
export class SendResult {
  message_id:string;
  error:any;
  error_string:string;
  to:EmailContact;
  campaign_id:string;
  segment_id:string;
  workflow_id:string;
  scheduled_email_group_id:string;
  template_id:string;
  bucket:string;
  key:string;
  bucketing_error:any;
  bucketing_error_string:string;
}
export class UpsertEntitiesPayload {
  entities:Entity[];
}
export class GetEntitiesPayload {
  name:string;
  ids:string[];
}
export class UpsertEntitiesResponsePayload {
  data:Entity[];
  errors:string[];
}
export class GetEntitiesResponsePayload {
  data:Entity[];
  errors:string[];
}
export class Entity {
  id:string;
  version:moment.Moment;
  json:{[key:string]:any};
  name:string;
}
export class InsertEventPayload {
  events:Event[];
}
export class EmitEmailSendRequestPayload {
  mailer_payload?:MailerPayload;
}
export class EmitEmailSendRequestResponsePayload {
  errors:string[];
}
export class InsertEventResponsePayload {
  data:Event[];
  errors:string[];
}
export class GetEventsForUsersPayload {
  user_ids:string[];
}
export class GetEventsForUsersResponsePayload {
  data:Event[];
  errors:string[];
}
export class GetEventsAndSystemEventsResponsePayload {
  data:Event[];
  errors:string[];
}
export class GetNumEventsAndSystemEventsResponsePayload {
  num_events:number;
  errors:string[];
}
export class EventWrapper {
  topic:any;
  event_type:any;
  event:any[];
  emitted_at:moment.Moment;
  segment_to_workflow_job_id:string;
}
export class Status {
  name:string;
  is_healthy:boolean;
  error:string;
}
export class GetGatewayHealthResponsePayload {
  statuses:Status[];
  errors:string[];
}
export class GetEmailPipelineReportResponsePayload {
  report?:EmailPipelineReport;
  errors:string[];
}
export class GrepLogResponsePayload {
  grep_for:string;
  matches:string[];
  errors:string[];
}
export class EmailPipelineReport {
  emails_per_step:any;
  totals_per_step:any;
  step_order:string[];
}
export class QueueReportResponsePayload {
  queue_attributes?:any;
  errors:string[];
}
export class FilterOutInProgressOrFinishedOneShotsPayload {
  all_emails:ScheduledEmail[];
}
export class FilterOutInProgressOrFinishedOneShotsResponsePayload {
  emails:ScheduledEmail[];
  errors:string[];
}
export class FilterOutInProgressOrFinishedTimezonesPayload {
  all_emails_by_timezone:any;
}
export class FilterOutInProgressOrFinishedTimezonesResponsePayload {
  emails:any;
  errors:string[];
}
export class ClaimScheduledEmailsResponsePayload {
  claimed_emails:ScheduledEmail[];
  errors:string[];
}
export class UnclaimScheduledEmailsPayload {
  emails:ScheduledEmail[];
}
export class UnclaimScheduledEmailsResponsePayload {
  errors:string[];
}
export class JobLogEntry {
  id:string;
  job_name:any;
  worker_id:string;
  worker_type:string;
  success:boolean;
  start_details:{[key:string]:any};
  end_details:{[key:string]:any};
  started_at?:any;
  ended_at?:any;
}
export class StartJobPayload {
  job_name:string;
  worker_id:string;
  worker_type:string;
  start_details:{[key:string]:any};
}
export class StartJobResponsePayload {
  data?:JobLogEntry;
  errors:string[];
}
export class GetFinishedJobsPayload {
  job_name:any;
  started_on_or_after:moment.Moment;
}
export class GetFinishedJobsResponsePayload {
  data:JobLogEntry[];
  errors:string[];
}
export class GetRunningJobsPayload {
  job_name:any;
  started_on_or_after:moment.Moment;
}
export class GetRunningJobsResponsePayload {
  data:JobLogEntry[];
  errors:string[];
}
export class GetJobsPayload {
  ids:string[];
  limit:number;
  offset:number;
}
export class GetJobsResponsePayload {
  data:JobLogEntry[];
  errors:any[];
}
export class FinishJobPayload {
  id:string;
  success?:any;
  end_details:{[key:string]:any};
}
export class FinishJobResponsePayload {
  data?:JobLogEntry;
  errors:string[];
}
export class FindUnfinishedJobResponsePayload {
  data:JobLogEntry[];
  errors:string[];
}
export class GetFileUploadStatusResponsePayload {
  s3_key:string;
  num_in_progress_jobs:number;
  num_total_jobs:number;
  processing_errors:string[];
  errors:string[];
}
export class NodeSettings {
  delay_ms:number;
  delay_until:moment.Moment;
  delay_until_users_timezone?:any;
  delay_until_any_date?:any;
  delay_until_exclusive?:any;
  send_at_close_enough_ms:number;
  sender_persona_id:string;
  template_id:string;
}
export class Node {
  id:string;
  type:any;
  event_topic:any;
  event_type:any;
  children:Node[];
  settings?:NodeSettings;
}
export class DelayedMessageWrapper {
  message:string;
  is_delay_node_message:boolean;
  num_delay_cycles:number;
}
export class GetEmailSendResultsResponsePayload {
  send_results:any;
  errors:string[];
}
export class GetEmailSendResultsPayload {
  s3_locations:string[];
}
export class ReportingQueryPayload {
  query:string;
}
export class GetDataResponsePayload {
  data:any[];
  errors:string[];
}
export class GetIssueEventsResponsePayload {
  data:any[];
  errors:string[];
}
export class Event {
  id:string;
  name:string;
  user_id:string;
  email:string;
  json:{[key:string]:any};
  metric1:number;
  metric2:number;
  metric3:number;
  emitted_at:moment.Moment;
  created_at:moment.Moment;
}
export class AggregatedEvent {
  id:string;
  name:string;
  json:{[key:string]:any};
  count:number;
  unique_count:number;
  metric1:number;
  metric2:number;
  metric3:number;
  unique_metric1:number;
  unique_metric2:number;
  unique_metric3:number;
  emitted_at:moment.Moment;
  created_at:moment.Moment;
}
export class SystemEvent {
  id:string;
  name:string;
  user_id:string;
  email:string;
  json:{[key:string]:any};
  metric1:number;
  metric2:number;
  metric3:number;
  emitted_at:moment.Moment;
  created_at:moment.Moment;
  filters:ReportingAggregateFilterValue[];
}
export class AggregatedSystemEvent {
  id:string;
  name:string;
  json:{[key:string]:any};
  count:number;
  unique_count:number;
  metric1:number;
  metric2:number;
  metric3:number;
  unique_metric1:number;
  unique_metric2:number;
  unique_metric3:number;
  emitted_at:moment.Moment;
  created_at:moment.Moment;
  filters:ReportingAggregateFilterValue[];
}
export class ReportingAggregateFilter {
  column_name:string;
  index_definition:string;
  column_definition:string;
  aggregated_column_name:string;
  aggregated_column_definition:string;
  aggregated_index_definition:string;
  suffix:string;
  is_daily:boolean;
  is_monthly:boolean;
  values_are_strings:boolean;
  can_have_value:boolean;
  convert_blank_to_null_in_db:boolean;
}
export class ReportingAggregateFilterValue {
  column_name:string;
  index_definition:string;
  column_definition:string;
  aggregated_column_name:string;
  aggregated_column_definition:string;
  aggregated_index_definition:string;
  suffix:string;
  is_daily:boolean;
  is_monthly:boolean;
  values_are_strings:boolean;
  can_have_value:boolean;
  convert_blank_to_null_in_db:boolean;
  values:string[];
}
export class ConvertFileInS3RequestPayload {
  from_bucket:string;
  from_key:string;
  to_bucket:string;
  to_key:string;
}
export class ConvertFileInS3ResponsePayload {
  errors:string[];
}
export class DistributorPart2JobPayload {
  job_type:any;
  group_id:string;
  segment_id:string;
  template_id:string;
  template_ids_with_weights:WeightedTemplateID[];
  sender_persona_id:string;
  timezone:string;
  send_in_users_timezone:boolean;
  user_min:number;
  user_max:number;
  users_per_chunk:number;
}
export class ScheduledEmailGroup {
  id:string;
  name:string;
  enabled:boolean;
  created_at:moment.Moment;
  send_at:moment.Moment;
  send_in_users_timezone:boolean;
  sent_at_per_timezone:any;
  scheduled_emails:ScheduledEmail[];
  payload:ScheduledEmailDetails;
}
export class ScheduledEmailGroupWithMetrics {
  id:string;
  name:string;
  enabled:boolean;
  created_at:moment.Moment;
  send_at:moment.Moment;
  send_in_users_timezone:boolean;
  sent_at_per_timezone:any;
  num_users:number;
  num_segments:number;
}
export class ScheduledEmail {
  id:string;
  group_id:string;
  segment_id:string;
  name:string;
  created_at:moment.Moment;
  send_at:moment.Moment;
  send_in_users_timezone:boolean;
  sent_at_per_timezone:any;
  payload:ScheduledEmailDetails;
  claimed_by:string;
  claimed_at:moment.Moment;
}
export class ScheduledEmailRenderAndEmitJob {
  scheduled_email_group_id:string;
  segment_id:string;
  template_id:string;
  template_ids_with_weights:WeightedTemplateID[];
  sender_persona_id:string;
  timezone:string;
  send_in_users_timezone:boolean;
  user_min:number;
  user_max:number;
  channel:string;
}
export class ScheduleEmailPayload {
  id:string;
  name:string;
  to_segment_ids:string[];
  to_user_ids:string[];
  to_user_emails:string[];
  cc_to_user_ids:string[];
  bcc_to_user_ids:string[];
  sender_persona_id:string;
  template_id:string;
  template_ids_with_weights:WeightedTemplateID[];
  timezone:string;
  send_at:moment.Moment;
  send_in_users_timezone:boolean;
}
export class GetScheduledEmailsWithMetricsResponsePayload {
  data:ScheduledEmailGroupWithMetrics[];
  errors:string[];
}
export class ScheduleEmailResponsePayload {
  data?:ScheduledEmailGroup;
  errors:string[];
}
export class GetScheduledEmailsPayload {
  ids:string[];
}
export class GetScheduledEmailsResponsePayload {
  data:ScheduledEmailGroup[];
  errors:string[];
}
export class DisableScheduledEmailGroupResponsePayload {
  errors:string[];
}
export class Segment {
  id:string;
  name:string;
  user_ids:string[];
  num_users:number;
  version?:any;
  invisible?:any;
  created_at?:any;
  updated_at?:any;
}
export class GetTimezonesForUsersInSegmentResponsePayload {
  segment_id:string;
  timezones:string[];
  errors:string[];
}
export class UpsertSegmentsPayload {
  segments:Segment[];
}
export class UpsertSegmentsResponsePayload {
  data:Segment[];
  errors:string[];
}
export class UpdateSegmentNamePayload {
  name:string;
}
export class UpdateSegmentNameResponsePayload {
  data?:Segment;
  errors:string[];
}
export class AddUsersToSegmentPayload {
  user_ids:string[];
  emails:string[];
}
export class AddUsersToSegmentResponsePayload {
  data:boolean;
  errors:string[];
}
export class AddUsersToSegmentByEmailPayload {
  emails:string[];
}
export class AddUsersToSegmentByEmailResponsePayload {
  data:boolean;
  errors:string[];
}
export class GetSegmentsPayload {
  ids:string[];
  names:string[];
}
export class GetSegmentsResponsePayload {
  data:Segment[];
  errors:string[];
}
export class GetUploadUrlResponsePayload {
  data:string;
  errors:string[];
}
export class GetNumUsersInSegmentsResponsePayload {
  segment_id:string;
  num_users:number;
  errors:string[];
}
export class GetMinAndMaxIdsForSegmentTimezoneResponsePayload {
  segment_id:string;
  timezone?:any;
  default_timezone:string;
  min:number;
  max:number;
  errors:string[];
}
export class ProcessS3FilePartJob {
  segment_id:string;
  headers:string[];
  joblog_entry_id:string;
  s3_bucket:string;
  s3_key:string;
  start_at_byte:number;
  end_at_byte:number;
  sqs_receipt_handle:string;
  header_length_bytes:number;
}
export class ProcessUserSegmentRetriesJob {
  segment_id:string;
  upsert_user_payload?:UpsertUserPayload;
  add_users_to_segment_payload?:AddUsersToSegmentPayload;
}
export class RemoveUsersFromSegmentResponsePayload {
  user_id_to_segment_id:any;
  errors:string[];
}
export class UserFilter {
  attribute:string;
  operator:string;
  values:any[];
}
export class FilterUsersPreviewPayload {
  user_filters:UserFilter[];
  limit:number;
  offset:number;
  conjunction:string;
}
export class FilterUsersPreviewResponsePayload {
  users:User[];
  total_users:number;
  errors:string[];
}
export class CreateSegmentFromFiltersPayload {
  user_filters:UserFilter[];
  conjunction:string;
  name:string;
}
export class CreateSegmentFromFiltersResponsePayload {
  num_users:number;
  errors:string[];
}
export class CreateSegmentFromFiltersJobPayload {
  segment_id:string;
  limit:number;
  offset:number;
  user_filters:UserFilter[];
  conjunction:string;
}
export class SendSegmentToWorkflowJobPayload {
  segment_id:string;
  workflow_id:string;
  workflow_root_input_queue_url:string;
  max_id:number;
  min_id:number;
  segment_to_workflow_job_id:string;
}
export class SendSegmentToWorkflowPayload {
  segment_id:string;
  workflow_id:string;
  root_node_id:string;
}
export class SendSegmentToWorkflowResponsePayload {
  errors:string[];
}
export class SenderPersona {
  id:string;
  first_name:string;
  last_name:string;
  email:string;
  json:{[key:string]:any};
  version?:any;
  created_at?:any;
  updated_at?:any;
}
export class GetSenderPersonasPayload {
  ids:string[];
}
export class GetSenderPersonasResponsePayload {
  data:SenderPersona[];
  errors:string[];
}
export class UpsertSenderPersonasPayload {
  sender_personas:SenderPersona[];
}
export class UpsertSenderPersonasResponsePayload {
  data:SenderPersona[];
  errors:string[];
}
export class SESEventMail {
  messageId:string;
  source:string;
  destination:string[];
  tags:any;
}
export class SESEvent {
  eventType:string;
  mail:SESEventMail;
}
export class AddDomainPayload {
  company_name:string;
  project_name:string;
  domain:string;
}
export class AddDomainResponsePayload {
  domains:string[];
  errors:string[];
}
export class GetDKIMInfoResponsePayload {
  keys:any;
  domains?:any;
  errors:string[];
}
export class GetDomainsForCompanyResponsePayload {
  domains:string[];
  errors:string[];
}
export class Template {
  id:string;
  name:string;
  subject:string;
  type:any;
  enabled:boolean;
  html_body:string;
  plain_text_body:string;
  channel_id:string;
  json:{[key:string]:any};
  version?:any;
  created_at?:any;
  updated_at?:any;
}
export class GetTemplatesPayload {
  ids:string[];
}
export class GetTemplatesResponsePayload {
  data:Template[];
  errors:string[];
}
export class DeleteTemplatesResponsePayload {
  ids:string[];
  errors:string[];
}
export class UpsertTemplatesPayload {
  templates:Template[];
}
export class UpsertTemplatesResponsePayload {
  data:Template[];
  errors:string[];
}
export class GetUploadTemplateImageUrlResponsePayload {
  data:string;
  errors:string[];
}
export class TraceInfo {
}
export class EmailKeyParts {
  email:string;
  user_id:string;
  template_id:string;
  workflow_id:string;
  campaign_id:string;
  scheduled_email_group_id:string;
  sender_persona_id:string;
  unsubscribe_key:string;
}
export class LinkParts {
  company:string;
  project:string;
  encoded_email_key:string;
}
export class User {
  id:string;
  email:string;
  first_name:string;
  last_name:string;
  date_of_birth?:any;
  timezone:string;
  json:{[key:string]:any};
  created_at?:any;
  unsubscribed_at?:any;
}
export class UpsertUserPayload {
  users:User[];
}
export class UpsertUserResponsePayload {
  data:User[];
  errors:string[];
}
export class GetUsersResponsePayload {
  data:User[];
  errors:string[];
}
export class GetNumUsersResponsePayload {
  num_users:number;
  errors:string[];
}
export class GetUsersInSegmentWithTimezonePayload {
  segment_id:string;
  min_id:number;
  max_id:number;
  timezone:string;
  default_timezone:string;
}
export class GetUsersInSegmentWithTimezoneResponsePayload {
  data:User[];
  errors:string[];
}
export class ArchiveUsersResponsePayload {
  archived_email_to_user_map:any;
  errors:string[];
}
export class UserEntity {
  user_id:string;
  user_version:moment.Moment;
  entity_id:string;
  entity_version:moment.Moment;
  created_at:moment.Moment;
}
export class Workflow {
  id:string;
  version:moment.Moment;
  name:string;
  json:{[key:string]:any};
  created_at:moment.Moment;
  updated_at:moment.Moment;
}
export class UpsertWorkflowPayload {
  id:string;
  job_log_id:string;
  name:string;
  root_node?:Node;
  display:{[key:string]:any};
}
export class UpsertWorkflowResponsePayloadData {
  workflow?:Workflow;
  job_log_entry_id:string;
  behind_another_job:boolean;
}
export class UpsertWorkflowResponsePayload {
  data?:UpsertWorkflowResponsePayloadData;
  errors:string[];
}
export class UpsertWorkflowForValidationPayloadData {
  id:string;
  job_log_id:string;
  name:string;
  root_node?:Node;
  display:{[key:string]:any};
}
export class UpsertWorkflowForValidationResponsePayload {
  errors:string[];
}
export class GetWorkflowsPayload {
  ids:string[];
}
export class GetWorkflowsResponsePayload {
  data:Workflow[];
  errors:string[];
}
export class UpsertWorkflowAdminPayload {
  id:string;
  job_log_id:string;
  name:string;
  root_node?:Node;
  old_root_node?:Node;
  display:{[key:string]:any};
}
export class UpsertWorkflowAdminResponsePayload {
  data:JobLogEntry;
  errors:string[];
}
export class DeleteWorkflowsPayload {
  id:string;
}
export class DeleteWorkflowsResponsePayload {
  job_log_id:string;
  workflow_id:string;
  errors:string[];
}
export class WorkflowPayloadMetaInfo {
  trigger_topic:any;
  trigger_type:any;
  trigger_received_at:moment.Moment;
  segment_to_workflow_job_id:string;
  sent_at:moment.Moment;
}
export class WorkflowUserTriggerWrapper {
  trigger_topic:any;
  trigger_type:any;
  trigger_received_at:moment.Moment;
  segment_to_workflow_job_id:string;
  sent_at:moment.Moment;
  user?:User;
}
export class WorkflowEventTriggerWrapper {
  trigger_topic:any;
  trigger_type:any;
  trigger_received_at:moment.Moment;
  segment_to_workflow_job_id:string;
  sent_at:moment.Moment;
  event?:Event;
}
export const filterMap:{[key:string]:{allowed_operators:string[], query_fields: [""], join_tables: [""], value_types: string[], operators_in_having_clause:boolean}} = {
  "contact_city": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "is_missing",
      "is_not_missing"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "string"
    ],
    "operators_in_having_clause": false
  },
  "contact_date_of_birth": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "date"
    ],
    "operators_in_having_clause": false
  },
  "contact_email": {
    "allowed_operators": [
      "contains",
      "does_not_contain",
      "equals",
      "not_equals",
      "is_missing",
      "is_not_missing"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "string"
    ],
    "operators_in_having_clause": false
  },
  "contact_first_name": {
    "allowed_operators": [
      "contains",
      "does_not_contain",
      "equals",
      "not_equals",
      "is_missing",
      "is_not_missing"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "string"
    ],
    "operators_in_having_clause": false
  },
  "contact_json": {
    "allowed_operators": [
      "contains",
      "does_not_contain",
      "equals",
      "not_equals",
      "is_missing",
      "is_not_missing",
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "string"
    ],
    "operators_in_having_clause": false
  },
  "contact_last_name": {
    "allowed_operators": [
      "contains",
      "does_not_contain",
      "equals",
      "not_equals",
      "is_missing",
      "is_not_missing"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "string"
    ],
    "operators_in_having_clause": false
  },
  "contact_state": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "is_missing",
      "is_not_missing"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "string"
    ],
    "operators_in_having_clause": false
  },
  "date_added": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "date"
    ],
    "operators_in_having_clause": false
  },
  "date_bounced": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "date"
    ],
    "operators_in_having_clause": false
  },
  "date_unsubscribed": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "date"
    ],
    "operators_in_having_clause": false
  },
  "date_workflow_completed": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "date"
    ],
    "operators_in_having_clause": false
  },
  "date_workflow_started": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "date"
    ],
    "operators_in_having_clause": false
  },
  "num_clicks": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number"
    ],
    "operators_in_having_clause": false
  },
  "num_clicks_via_campaign": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "campaign_id"
    ],
    "operators_in_having_clause": false
  },
  "num_clicks_via_email_blast": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "scheduled_email_blast_id"
    ],
    "operators_in_having_clause": false
  },
  "num_clicks_via_workflow": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "workflow_id"
    ],
    "operators_in_having_clause": false
  },
  "num_clicks_with_template": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "template_id"
    ],
    "operators_in_having_clause": false
  },
  "num_in_segment": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "segment_id"
    ],
    "operators_in_having_clause": false
  },
  "num_opens": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number"
    ],
    "operators_in_having_clause": false
  },
  "num_opens_via_campaign": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "campaign_id"
    ],
    "operators_in_having_clause": false
  },
  "num_opens_via_email_blast": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "scheduled_email_blast_id"
    ],
    "operators_in_having_clause": false
  },
  "num_opens_via_workflow": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "workflow_id"
    ],
    "operators_in_having_clause": false
  },
  "num_opens_with_template": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "template_id"
    ],
    "operators_in_having_clause": false
  },
  "num_sends": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number"
    ],
    "operators_in_having_clause": false
  },
  "num_sends_via_campaign": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "campaign_id"
    ],
    "operators_in_having_clause": false
  },
  "num_sends_via_email_blast": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "scheduled_email_blast_id"
    ],
    "operators_in_having_clause": false
  },
  "num_sends_via_workflow": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "workflow_id"
    ],
    "operators_in_having_clause": false
  },
  "num_sends_with_template": {
    "allowed_operators": [
      "equals",
      "not_equals",
      "less_than",
      "greater_than"
    ],
    "query_fields": [
      ""
    ],
    "join_tables": [
      ""
    ],
    "value_types": [
      "number",
      "template_id"
    ],
    "operators_in_having_clause": false
  }
}