import {
    AppBar,
    Menu,
    MenuItem,
    Toolbar,
} from "@material-ui/core";
import {Menu as MenuIcon} from "@material-ui/icons"
import React from "react";
import {Link} from "react-router-dom";
import {myTheme} from "../App";
import Button from "@material-ui/core/Button";
import {publicRoutes, routes} from "./RoutesV2";

export function PublicTopNav() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <AppBar position="fixed" style={{opacity:0.97}}>
        <Toolbar  style={{minHeight:"50px"}}>
            <Button variant={"contained"}
                    onClick={!open?handleMenu:handleClose}
                    color={"primary"}
                    startIcon={<MenuIcon />}>
                Navigation
            </Button>
            <PublicTopMenu open={open}
              anchorEl={anchorEl}
              onClick={handleClose}
            />
        </Toolbar>
    </AppBar>;
}

export function RoutePublicMenuItems() : JSX.Element {
    let listItemNum = 0;
    const primaryColor = "#333";
    const secondaryColor = myTheme.palette.primary.light;


    return <> {publicRoutes.map(routeDef => {
        const paletteStyle = routeDef.depth !== 1?
            {color:primaryColor }:
            {color:secondaryColor};

        return <Link to={routeDef.href} key={"link" + (listItemNum++)} style={{ textDecoration: 'none' }}>
            <MenuItem
                style={{
                    padding:"10px 10px 10px 10px",
                    ...paletteStyle
                }}
                key={"item"+ (listItemNum++)}
                onClick={routeDef.onClick}>
                {routeDef.icon}&nbsp;&nbsp;&nbsp;&nbsp;
                {routeDef.text}
            </MenuItem>
        </Link>
    })}</>
}


export function PublicTopMenu(props: any): JSX.Element {
    return <Menu {...props} style={{marginTop:"30px", minWidth:"200px"}}>
        {RoutePublicMenuItems()}
    </Menu>
}


