import React from "react";
import {AllState} from "../reducers";
import {connect} from "react-redux";
import {
 Typography, withWidth
} from "@material-ui/core";
import { fetchOpensAndClicksBySourceReportDataAction } from "../actions/Reports";
import { Loading } from "../components/Loading";
import { v4 as uuidv4 } from 'uuid';
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import {parse} from 'express-useragent';
import { getLineColor } from "./Common";

interface IProps {
    dispatch: any,
    reportData?: {[key:string]:any[]},
    receivingReportData?: {[key:string]:boolean},
    campaignId?: string, 
    width: string,
}
interface IState {
    userAgentId:string,
    domainId:string,
}

class OpensAndClicksBySource extends React.Component<IProps, IState> {
    props:IProps;
    state:IState;

    constructor(props:IProps) {
        super(props)
        this.props = props;
        this.state = {
            userAgentId: uuidv4(),
            domainId: uuidv4(),
        }
    }

    componentDidMount(){
        this.props.dispatch(fetchOpensAndClicksBySourceReportDataAction(this.state.userAgentId, this.props.campaignId, "user_agent", 10000, 0))
        this.props.dispatch(fetchOpensAndClicksBySourceReportDataAction(this.state.domainId, this.props.campaignId, "domain", 10000, 0))
    }

    domainDataToBarData(jsonData: any): BarDatum[] {
        if(!jsonData.map) {
            console.error("attempting to draw domain data but don't have data")
            return [{
                value: 0,
                domain: "n/a",
            }]
        }

        const vals:BarDatum[] = []
        jsonData.map((domainData:any) => {
            if(!domainData.num_opens  && !domainData.num_clicks) {
                return
            }
            vals.push({
                opens: domainData.num_opens,
                clicks: domainData.num_clicks,
                key: domainData.to_domain,
            })
        })
        
        return vals
    } 
    
    
    deviceDataToBarData(jsonData: any, by: "device" | "browser"): BarDatum[] {
        if(!jsonData.map) {
            console.error("attempting to draw device data but don't have data")
            return [{
                value: 0,
                domain: "n/a",
            }]
        }

        const opensClicksByDevice : {[key:string]:{opens:number,clicks:number}} = {}
        if(by !== "browser") {
            opensClicksByDevice["Desktop"] = {opens:0, clicks:0}
            opensClicksByDevice["Mobile"] = {opens:0, clicks:0}
        }
        jsonData.map((uaData:any) => {
            if(!uaData.num_clicks && !uaData.num_opens) {
                return
            } 

            const details = parse(uaData.user_agent)
            let device = details.isDesktop ? "Desktop" : details.isMobile ? "Mobile" : "Other"
            if(by === "browser") {
                device = details.browser
            }
            if(device === "unknown") {
                device = "Other"
            }
            if(!opensClicksByDevice[device]){
                opensClicksByDevice[device] = { opens: 0, clicks: 0 }
            }
            opensClicksByDevice[device].clicks += uaData.num_clicks || 0
            opensClicksByDevice[device].opens += uaData.num_opens || 0
        })

        const vals:BarDatum[] = []
        Object.keys(opensClicksByDevice).map(device => {
            vals.push({
                opens: opensClicksByDevice[device].opens,
                clicks: opensClicksByDevice[device].clicks,
                key: device,
            })
        })

        return vals
    } 

    render() {
        const leftGraphMargin = this.props.width === "xs" || this.props.width === "sm" ? 60 : 80
        const rightGraphMargin = this.props.width === "xs" || this.props.width === "sm" ? 0 : 50
        return <div>
            <div className="printBreakAvoidInside">
            <Typography component={"div"} variant={"h2"} >Opens and clicks by source</Typography>

            <Typography component={"div"} variant={"body1"} ><b>By device</b></Typography>
            <div  key={"domaindatadevice"}  style={{width:"100%", height:  this.props.reportData[this.state.domainId]?"250px": "80px"}}>
                { this.props.reportData[this.state.userAgentId]? <ResponsiveBar
                    data={this.deviceDataToBarData(this.props.reportData[this.state.userAgentId], "device")}
                    keys={["opens","clicks"]}
                    indexBy="key"
                    margin={{
                        top: 20,
                        right: rightGraphMargin,
                        bottom: 50,
                        left: leftGraphMargin
                    }}
                    colors={[
                        getLineColor("opens"),
                        getLineColor("clicks"),
                    ]}
                    axisLeft={{
                        legend: "Opens / Clicks",
                        format: value => parseInt(value.toString()) == parseFloat(value.toString()) ? value.toString(): "",
                        legendOffset: -50,
                        legendPosition: 'middle'
                    }}
                    legends={[
                        {
                            dataFrom: "keys",
                            translateX: 10,
                            anchor: 'top-left',
                            direction: 'column',
                            itemDirection: 'left-to-right',
                            itemWidth: 150,
                            itemHeight: 20,
                            itemOpacity: 1,
                            symbolShape: 'circle',
                        }
                    ]}
                    padding={0.6}
                    groupMode="grouped"
                    axisTop={null}
                    axisRight={null}
                    enableGridX
                    enableGridY
                    enableLabel={false}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: this.deviceDataToBarData(this.props.reportData[this.state.userAgentId], "device").length > 5 ? -10 : 0
                    }}
                    /> : this.props.receivingReportData[this.state.userAgentId]?  
                    <Loading /> :
                    <div style={{margin:"1rem"}}>No data</div> }
            </div>
            </div>

            <div className="printBreakAvoidInside">
            <Typography component={"div"} variant={"body1"} ><b>By browser</b></Typography>
            <div  key={"domaindatabrowser"}  style={{width:"100%", height:  this.props.reportData[this.state.domainId]?"320px": "80px"}}>
                { this.props.reportData[this.state.userAgentId]? <ResponsiveBar
                    data={this.deviceDataToBarData(this.props.reportData[this.state.userAgentId], "browser")}
                    keys={["opens","clicks"]}
                    indexBy="key"
                    margin={{
                        top: 20,
                        right: rightGraphMargin,
                        bottom: 80,
                        left: leftGraphMargin
                    }}
                    colors={[
                        getLineColor("opens"),
                        getLineColor("clicks"),
                    ]}
                    axisLeft={{
                        legend: "Opens / Clicks",
                        format: value => parseInt(value.toString()) == parseFloat(value.toString()) ? value.toString(): "",
                        legendOffset: -50,
                        legendPosition: 'middle'
                    }}
                    legends={[
                        {
                            dataFrom: "keys",
                            translateX: 10,
                            anchor: 'top-left',
                            direction: 'column',
                            itemDirection: 'left-to-right',
                            itemWidth: 150,
                            itemHeight: 20,
                            itemOpacity: 1,
                            symbolShape: 'circle',
                        }
                    ]}
                    padding={0.6}
                    groupMode="grouped"
                    axisTop={null}
                    axisRight={null}
                    enableGridX
                    enableGridY
                    enableLabel={false}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: Object.keys(this.props.reportData[this.state.userAgentId]).length > 5 ? -30 : 0
                        
                    }}
                    />: this.props.receivingReportData[this.state.userAgentId]?  
                    <Loading /> :
                    <div style={{margin:"1rem"}}>No data</div> }
            </div>
            </div>

            
            <div className="printBreakAvoidInside">

            <Typography component={"div"} variant={"body1"} ><b>By contact's domain</b></Typography>
            <div  key={"domaindatabar"}  style={{width:"100%", height:  this.props.reportData[this.state.domainId]?"250px": "80px"}}>
                { this.props.reportData[this.state.domainId]? <ResponsiveBar
                    data={this.domainDataToBarData(this.props.reportData[this.state.domainId])}
                    keys={["opens","clicks"]}
                    indexBy="key"
                    margin={{
                        top: 20,
                        right: rightGraphMargin,
                        bottom: 80,
                        left: leftGraphMargin
                    }}
                    colors={[
                        getLineColor("opens"),
                        getLineColor("clicks"),
                    ]}
                    axisLeft={{
                        legend: "Opens / Clicks",
                        format: value => parseInt(value.toString()) == parseFloat(value.toString()) ? value.toString(): "",
                        legendOffset: -50,
                        legendPosition: 'middle'
                    }}
                    legends={[
                        {
                            dataFrom: "keys",
                            translateX: 10,
                            anchor: 'top-left',
                            direction: 'column',
                            itemDirection: 'left-to-right',
                            itemWidth: 150,
                            itemHeight: 20,
                            itemOpacity: 1,
                            symbolShape: 'circle',
                        }
                    ]}
                    padding={0.6}
                    groupMode="grouped"
                    axisTop={null}
                    axisRight={null}
                    enableGridX
                    enableGridY
                    enableLabel={false}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation:  Object.keys(this.props.reportData[this.state.domainId]).length > 15 ? -30
                            : Object.keys(this.props.reportData[this.state.domainId]).length > 5 ? -10 : 0
                    }}
                    /> : 
                    this.props.receivingReportData[this.state.domainId]?  
                        <Loading /> :
                        <div style={{margin:"1rem"}}>No data</div> 
                    }
            </div>
            </div>

        </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state;
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(withWidth()(OpensAndClicksBySource))
