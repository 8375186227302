import React, {ChangeEvent} from 'react';
import { connect } from "react-redux";
import {
    Card,
    Chip, 
    Grid, 
    MenuItem,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from "@material-ui/core";
import {fetchEmailBlasts, archiveEmailBlasts} from "./actions/EmailBlasts";
import {Link} from "react-router-dom";
import {ScheduledEmailGroup} from "./generated/types/payloadTypes";
import {Add, Edit, Stop, Visibility, Check, Close, Delete} from "@material-ui/icons";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {Loading} from "./components/Loading";
import Fab from "@material-ui/core/Fab";
import {MyArchiveButton, MyCancelButton, MyModal} from "./Dialog";
import {InformationArea} from "./components/InformationArea";
import {v4 as uuidv4} from "uuid";
import { EmailBlastIcon } from './Icons';
import moment from 'moment';


interface IState {
    id: string,
    selectedEmailBlastId: string,
    selectedEmailBlastIds: string[],
    upForDeletionBlast?: ScheduledEmailGroup,
    anchorEl?: any,
}

interface IProps {
    dispatch: any,
    type:string,
    reportErrors?: {[key:string]:string},
    style?: any,
    receivingEmailBlasts?: boolean,
    receivingReportData?: {[key:string]:boolean},
    reportData?:  {[key:string]: any},
    label?: string,
    placeholder?: string,
    selectedEmailBlastIds?: string[],
    selectedEmailBlastId?: string,
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    onSelectedEmailBlastIdsChange?: (ids: string[]) => any
    emailBlasts?: {[key:string]: ScheduledEmailGroup},
    required?: boolean,
    emailBlastsListKey?: string,
    width: string,
}

const tableId = "email blast table";
class ListEmailBlasts extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4(),
            selectedEmailBlastId:  this.props.selectedEmailBlastId || this.props.placeholder?"placeholder": "",
            selectedEmailBlastIds: this.props.selectedEmailBlastIds || [],
        };
        this.emailBlastChange = this.emailBlastChange.bind(this);
        this.archiveBlast = this.archiveBlast.bind(this);
        
    }

    componentDidMount() {
        this.props.dispatch(fetchEmailBlasts())
    }

    // static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
    //     if(nextProps.emailBlasts) {
    //         const selectedEmailBlastId = prevState.selectedEmailBlastId;
    //         return {
    //             ...prevState,
    //             selectedEmailBlastId,
    //         }
    //     }

    //     return null;
    // }


    renderEmailBlastSelections(selectedIds: string[]):JSX.Element {
        if(!this.props.emailBlasts) {
            return <></>
        }

        const selectedEmailBlasts = selectedIds.map(id => this.props.emailBlasts[id]);
        const selectedEmailBlastsDisplay = selectedEmailBlasts.map(emailBlast => emailBlast.name);

        return <div>
            {selectedEmailBlastsDisplay.map((displayString:string,index:number) => {
                if(displayString) {
                    return <Chip key={"chip"+index} label={displayString}/>
                }
            })}
        </div>;
    }

    emailBlastChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedEmailBlastId: "",
                selectedEmailBlastIds: [],
            })
            return
        }

        if(!this.props.emailBlasts) {
            return
        }

        const selectedEmailBlastId = event.target.value.toString()
        if(this.props.type == "select") {
            this.setState({
                ...this.state,
                selectedEmailBlastId,
            });
            if(this.props.onSelectedEmailBlastIdsChange) {
                this.props.onSelectedEmailBlastIdsChange([selectedEmailBlastId])
            }
        } else if(this.props.type == "multiselect") {
            const newIds = [
                ...this.state.selectedEmailBlastIds,
                selectedEmailBlastId,
            ]
            this.setState({
                ...this.state,
                selectedEmailBlastIds: newIds,
            });

            if(this.props.onSelectedEmailBlastIdsChange) {
                this.props.onSelectedEmailBlastIdsChange(newIds)
            }
        } else {
            console.error("unknown email blast group component type " + this.props.type)
        }


        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    
    archiveBlast(id: string) {
        this.props.dispatch(archiveEmailBlasts([id]));
    }


    truncateText(text: string, len = 60) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    
    nameColumnWidth(): string {
        switch(this.props.width) {
            case "xs":
                return "7rem"
            case "sm":
                return "9rem"
            case "md":
                return "11rem"
            default:
                return "12rem"
        }
    }

    selectMenuItems(excludeSelected: boolean): JSX.Element[] {
        if(!this.props.emailBlasts ||  Object.values(this.props.emailBlasts).length===0) {
            return []
        }
        
        const options:JSX.Element[] = []
        Object.values(this.props.emailBlasts).map(blast => {
            if(excludeSelected && (
                this.state.selectedEmailBlastId == blast.id ||
                this.state.selectedEmailBlastIds.find(id => blast.id == id)   
            )) {
                return
            }

            if(blast.enabled) {
                options.push(<MenuItem key={blast.id} value={blast.id}>{blast.name}</MenuItem>)
            }
        })

        return options
    }

    getEmailBlastSelect(excludeSelected:boolean):JSX.Element {
        return <Select id={"emailBlastSelect"+this.state.id}
            required={!!this.props.required}
            MenuProps={{ disableScrollLock: true }}
            value={this.state.selectedEmailBlastId}
            onChange={this.emailBlastChange}
            style={{width:"100%"}}
            >
                { this.props.placeholder && <MenuItem key="selectplaceholder" value="placeholder" disabled selected style={{color:"#888"}}>{this.props.placeholder}</MenuItem>}
                {  this.selectMenuItems(excludeSelected) }
        </Select>
    }

    getMultiSelectForm() : JSX.Element {
        const selectedObjects: JSX.Element  = <>{this.state.selectedEmailBlastIds.map((id:string, index:number) => {
            if(!this.props.emailBlasts || Object.keys(this.props.emailBlasts).length == 0) {
                return <></>
            }

            const thisEmailBlast = this.props.emailBlasts[id]
            if(!thisEmailBlast) {
                console.error("Missing email blast " + id)
                return <></>
            }

            return <Grid container 
                    wrap="nowrap"  
                    key={"template" + id + index.toString()} 
                    alignItems={"flex-end"} 
                    style={{borderBottom:"1px dotted #ccc", margin:"0", padding:"0", width:"100%", minWidth:"200px"}}>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{minWidth:"30px"}}>
                    <EmailBlastIcon />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={10} xl={10}>
                    <Link target="_blank" to={"/components/email-blasts/edit/" + thisEmailBlast.id}>
                    <Typography variant={"body1"} component={"p"} 
                        title={thisEmailBlast.name}
                        style={{whiteSpace:"nowrap", width:"100%", textOverflow:"ellipsis", overflow:"hidden"}}>
                        {thisEmailBlast.name}
                    </Typography>
                    </Link>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={1} xl={1} className='clearfix'>
                    <Link to={""} style={{float:"right"}} onClick={(ev) => {
                         ev.stopPropagation()
                         ev.preventDefault()

                         const myEmailBlastIds = []
                         for(let i = 0; i < this.state.selectedEmailBlastIds.length; i++) {
                             if(thisEmailBlast.id != this.state.selectedEmailBlastIds[i]) {
                                myEmailBlastIds.push(this.state.selectedEmailBlastIds[i])
                             }
                         }
 
                         this.setState({
                             ...this.state,
                             selectedEmailBlastIds: myEmailBlastIds,
                         })
                        
                         if(this.props.onSelectedEmailBlastIdsChange) {
                            this.props.onSelectedEmailBlastIdsChange(myEmailBlastIds)
                         }
                    }}><Delete /></Link>
                </Grid>
            </Grid>
        })}</>

        return <><div style={{marginBottom:"1rem"}}>
            {this.getEmailBlastSelect(true)}
        </div>
        <div>
            {selectedObjects}
        </div></>
    }

    sendAtField(emailBlast: ScheduledEmailGroup): JSX.Element {
        if(!emailBlast.send_at) {
            return <></>
        }
        if(typeof emailBlast.send_at == "string") {
            emailBlast.send_at = moment(emailBlast.send_at)
        }
        
        const sendAtMoment = moment.tz.guess() ? 
                        moment(emailBlast.send_at.format('YYYY-MM-DD HH:mm:ss')).tz(moment.tz.guess(), true) : 
                        emailBlast.send_at
        let sendAtString = sendAtMoment.toDate().toLocaleDateString() + " " + sendAtMoment.toDate().toLocaleTimeString()
        if(!moment.tz.guess()) {
            sendAtString = sendAtMoment.utc().toISOString()
        }
        let timezoneString = emailBlast.payload?.timezone || ""
        if(emailBlast.send_in_users_timezone) {
            timezoneString = "*Contact's local TZ"
        }
 
        return <Typography variant="body1" component="span">
            {sendAtString}<br />
            {timezoneString}
        </Typography>   
    }

    render() {
        return <>
            { this.props.type === 'list' && <Card style={{...defaultCardStyles, ...this.props.style}} elevation={defaultCardElevation}>
                <Grid container>
                    <Grid item>
                        <Typography variant={"h1"} component={"div"}>Email Blasts</Typography>
                    </Grid>
                    <Grid item style={{marginLeft:"10px"}} title='Create a new email blast'>
                        <Link to={"/components/email-blasts/new"}>
                            <Fab size={"small"} color={"primary"} aria-label={"Create a new email blast"}>
                                <Add />
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>
                <hr />

                 {/* Confirm deletion dialog */}
                 {this.state.upForDeletionBlast && <MyModal title={"Disable email blast?"}
                        anchorEl={this.state.anchorEl?this.state.anchorEl:null}
                        content={<>
                           <Typography variant={"body1"} component={"p"} style={{marginBottom:"15px"}}>
                               Are you sure you want stop all further emails from sending for 
                               {this.state.upForDeletionBlast.name?
                               <>"<i>{this.state.upForDeletionBlast.name}</i>?"</>:<>this blast?</>}
                           </Typography>
                           <InformationArea type={"warning"}  style={{marginBottom:"1rem"}}
                                contents={<div style={{paddingTop:"5px"}}>This can't be undone!</div>}  />
                            </>
                        }
                        buttons={<>
                           <MyCancelButton onClick={(event:any) => {
                               event.stopPropagation();
                               event.preventDefault();
                               this.setState({
                                   ...this.state,
                                   upForDeletionBlast: null,
                               });
                             }}/>
                           <MyArchiveButton onClick={(event:any) => {
                               event.stopPropagation();
                               event.preventDefault();

                               this.archiveBlast(this.state.upForDeletionBlast.id);
                               this.setState({
                                   ...this.state,
                                   upForDeletionBlast: null,
                               });
                           }}/>
                        </>}
                    />}
                
                    {this.props.receivingEmailBlasts &&  <div style={{minHeight:"300px", paddingTop:"2rem"}}><Loading /></div>}
                    {!this.props.receivingEmailBlasts && <TableContainer>
                        <Table cellSpacing={0}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell><Typography component="span" variant="body1">Name</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">Enabled</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">Send At</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">Segments</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">Contacts</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                               
                                {this.props.emailBlasts && Object.values(this.props.emailBlasts).map((e,i) => {
                                    return <TableRow key={"key_" + i}>
                                        <TableCell style={{width:"25px", whiteSpace:"nowrap"}}>
                                            <Link to={"/components/email-blasts/edit/"+e.id}  className="svgShadow"><Visibility /></Link>
                                            {e.enabled && <>
                                                <Link to={""} className="svgShadow" onClick={(event)=>{
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    this.setState({
                                                        ...this.state,
                                                        // anchorEl: event.target,
                                                        upForDeletionBlast: e,
                                                    })
                                                }}><Stop /></Link>
                                            </>}
                                        </TableCell>
                                        <TableCell style={{maxWidth:"300px", overflow:"hidden", textOverflow:"ellipsis"}}>
                                            <Typography component="span" variant="body1" title={e.name}>
                                            {e.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" variant="body1" title={e.enabled?"enabled":"disabled"}>
                                            {e.enabled?<Check />  : <span style={{color:"red"}}><Close /></span>}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" variant="body1">
                                            {this.sendAtField(e)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" variant="body1">
                                            {e.payload?.to_segment_ids?e.payload.to_segment_ids.length:0}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" variant="body1">
                                            {e.payload?.to_user_ids?e.payload.to_user_ids.length:0}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    <Typography component={"p"} variant="body1" style={{marginTop:"10px"}}>
                        * An asterisk means the email blast sends/sent in the contact's timezone.  For example if
                        the send-at time shown is 1:00pm, then contacts in your timezone would get the email at 1:00pm, while
                        others would get the email at 1:00pm in their timezones, which might be before or after 1:00pm
                        for you.  You can learn about the importance of sending in your contacts' timezones 
                        <Link to="/blog/timezone-based-email-delivery" target="_blank" style={{marginLeft:"3px"}}>in our blog</Link>.
                    </Typography>
                </Card>}
            {this.props.type === 'select' && !this.props.receivingEmailBlasts &&
                   this.getEmailBlastSelect(false)
                } 
            {this.props.type === 'select' && this.props.receivingEmailBlasts && 
                <Loading mySize='sm' style={{marginTop:"4px"}}  text={<Typography variant="body1" component="span" style={{fontSize:"1rem", paddingLeft:"5px"}}>
                    Fetching email blasts
                </Typography>} />
            } 

            { this.props.type === 'multiselect' && <>
                {this.props.receivingEmailBlasts  && <Loading mySize={"sm"} />}
                {!this.props.receivingEmailBlasts && this.getMultiSelectForm()}
            </>
            }
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    let emailBlasts = null;
    if(state.emailBlasts && Object.keys(state.emailBlasts).length > 0) {
        emailBlasts = state.emailBlasts;
    }

    return { ...state, emailBlasts };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListEmailBlasts);

