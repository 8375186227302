import React, {FormEvent, MouseEvent} from 'react';
import {connect} from "react-redux";
import {Button, Card, Container, Grid, TextField, Typography} from "@material-ui/core";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {validateBusinessEmail, validateEmail, validateExists} from "./InputValidation";
import {Business, Email, HowToReg, Lock, Person, LocalOffer} from "@material-ui/icons";
import {signUp, SIGNUP_PASSWORD} from "./actions/Auth";
import {SIGNUP_CAPTCHA} from "./actions/Common";
import Captcha from "./components/Captcha";
import {GetCaptchaResponsePayload} from "./generated/types/payloadTypes";
import {Redirect,withRouter,RouteComponentProps, Link} from "react-router-dom";


interface IState {
    allowValidation: boolean,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    companyName:string,
    projectName:string,
    captchaValue: string,
    discountCode: string,
}

interface IProps extends RouteComponentProps<{ ignorethis?: string  }>{
    match: any,
    dispatch: any,
    processingSignUp?: boolean,
    waitingForSignUpArchitecture?: boolean,
    currentCaptcha?: GetCaptchaResponsePayload,
    width: string,
    reportErrors?: {[key:string]:string},
}

class PageSignup extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            firstName:"",
            lastName:"",
            email:"",
            password:"",
            allowValidation:false,
            companyName:"",
            projectName:"default",
            captchaValue: "",
            discountCode: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.simpleStateChange = this.simpleStateChange.bind(this);
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            const params = new URLSearchParams(location.search)
            const source = params.get("source")
            this.props.dispatch(signUp({
                company_user: {
                    id: "",
                    company_id: "",
                    project_id: "",
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    json: {
                        promo_code: this.state.discountCode,
                        source,
                    },
                },
                company_display_name: this.state.companyName,
                project_display_name: this.state.projectName,
                product_package_id: "", //@TODO make this use actual package id
                captcha_key: this.props.currentCaptcha.captcha_key,
                captcha_answer: this.state.captchaValue,
                password: this.state.password,
            }));
        }
    }

    simpleStateChange(stateKey: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const value = event.target.value;
            this.setState(state => {
                return {
                    ...state,
                    [stateKey]: value
                }
            })
        }
    }

    validateForm(state: IState):boolean {
        if(validateBusinessEmail(state.email) !== "") {
            return false;
        }
        if(validateExists(state.password) !== "") {
            return false;
        }
        if(validateExists(state.firstName) !== "") {
            return false;
        }
        if(validateExists(state.lastName) !== "") {
            return false;
        }
        if(validateExists(state.companyName) !== "") {
            return false;
        }
        if(validateExists(state.captchaValue) !== "") {
            return false;
        }

        return true;
    }

    captchaErrors() : string {
        if(validateExists(this.state.captchaValue) !== "") {
            return validateExists(this.state.captchaValue);
        }

        if(this.props.reportErrors[SIGNUP_CAPTCHA]) {
            return this.props.reportErrors[SIGNUP_CAPTCHA];
        }

        return "";
    }

    passwordErrors() : string {
        if(validateExists(this.state.password) !== "") {
            return validateExists(this.state.password);
        }

        if(this.props.reportErrors[SIGNUP_PASSWORD]) {
            return this.props.reportErrors[SIGNUP_PASSWORD];
        }

        return "";
    }

    render() {
        return <>
            <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                <Typography variant={"h1"} component={"div"} style={{marginBottom:"1.5rem"}}>Sign Up</Typography>
               
                <form onSubmit={this.handleSubmit}>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Person style={{visibility:'hidden'}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                    <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                        Welcome!  We should have your account ready within <br />about 30 minutes of your initial signup.
                    </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Person style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"First name"}
                                   required={true}
                                   autoFocus={true}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.firstName}
                                   onChange={this.simpleStateChange("firstName")}
                                   error={this.state.allowValidation && validateExists(this.state.firstName)!==""}
                                   helperText={this.state.allowValidation && validateExists(this.state.firstName)!==""? validateExists(this.state.firstName): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Last name"}
                                   required={true}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.lastName}
                                   onChange={this.simpleStateChange("lastName")}
                                   error={this.state.allowValidation && validateExists(this.state.lastName)!==""}
                                   helperText={this.state.allowValidation && validateExists(this.state.lastName)!==""? validateExists(this.state.lastName): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Email style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Business Email"}
                                   required={true}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.email}
                                   type={"email"}
                                   onChange={this.simpleStateChange("email")}
                                   error={this.state.allowValidation && validateBusinessEmail(this.state.email)!==""}
                                   helperText={this.state.allowValidation && validateBusinessEmail(this.state.email)!==""? validateBusinessEmail(this.state.email): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Lock style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Password"}
                                   required={true}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.password}
                                   type={"password"}
                                   onChange={this.simpleStateChange("password")}
                                   error={this.state.allowValidation && this.passwordErrors()!==""}
                                   helperText={this.state.allowValidation &&this.passwordErrors()!==""?this.passwordErrors(): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Business style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Company name"}
                                   required={true}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.companyName}
                                   onChange={this.simpleStateChange("companyName")}
                                   error={this.state.allowValidation && validateExists(this.state.companyName)!==""}
                                   helperText={this.state.allowValidation && validateExists(this.state.companyName)!==""? validateExists(this.state.companyName): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <LocalOffer style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Discount code"}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.discountCode}
                                   onChange={this.simpleStateChange("discountCode")}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems={"stretch"} >
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <HowToReg style={{marginTop:"20px"}}  />
                    </Grid>
                    <Grid item xs={11} md={11} lg={11} xl={11} sm={11}>
                        <Grid container wrap={"nowrap"} alignItems={"stretch"}  style={{marginTop:"20px"}} >
                            <Grid item>
                                <Captcha dispatch={this.props.dispatch} style={{width:80*2 + "px",height:15*2 + "px", borderStyle:"dotted", borderWidth:"2px", borderColor:"#aaa"}}/>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"} component={"p"} style={{marginLeft:"4px",marginTop:"2px"}}>
                                    Please enter this code in the field below
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                    <Grid container>
                        <Grid item xs={1} md={1} lg={1} xl={1}>
                            <Business style={{marginTop:"20px","visibility":"hidden"}} />
                        </Grid>
                        <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                            <TextField label={"Captcha code"}
                                       required={true}
                                       style={{width:"100%", minWidth:"300px"}}
                                       value={this.state.captchaValue}
                                       onChange={this.simpleStateChange("captchaValue")}
                                       error={this.state.allowValidation && this.captchaErrors()!==""}
                                       helperText={this.state.allowValidation && this.captchaErrors()!==""?this.captchaErrors(): ""}
                            />
                        </Grid>
                    </Grid>
                <Grid container alignItems={"flex-end"} style={{marginTop:"1rem"}}>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <Button
                            disabled={this.props.processingSignUp}
                            variant={"contained"}
                            color={"primary"}
                            type={"submit"}
                            onClick={this.handleSubmit}
                            style={{marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                            Sign up
                        </Button>
                        <Link to="/demo-login">
                            <Button variant={"outlined"}
                                    color={"primary"}
                                    type={"submit"}
                                    style={{marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                               Try it first instead
                        </Button></Link>
                    </Grid>
                </Grid>
                </form>
                {this.props.waitingForSignUpArchitecture && <Redirect to={"/waiting-for-architecture"} />}
            </Card>
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageSignup))

