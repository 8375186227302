import {Description, ErrorOutline, InfoOutlined, ReportProblemOutlined} from "@material-ui/icons";
import {Grid, Typography} from "@material-ui/core";
import React from "react";

export function InformationArea(props: { contents: JSX.Element | string, type: "warning" | "error" | "info", style?: any }): JSX.Element {
    const {contents, type} = props;
    let icon = null;
    switch (type) {
        case "warning":
            icon = <ReportProblemOutlined className={"info-area-icon"} fontSize="large"/>;
            break;
        case "error":
            icon = <ErrorOutline className={"info-area-icon"} fontSize="large"/>;
            break;
        case "info":
            icon = <InfoOutlined className={"info-area-icon"} fontSize="large"/>;
            break;
        default:
            throw new Error(`Unknown informationArea type ${type}`);
    }

    return <Grid container className={"info-area info-area-" + type} style={{...props.style}}>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {icon}
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            <Typography variant={"body1"} component={"p"}>
                {contents}
            </Typography>
        </Grid>
    </Grid>
}