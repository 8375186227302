import {  Card, Grid, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import React, { ChangeEvent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { AllState } from "../reducers"
import { Delete, Event } from "@material-ui/icons"
import {UserFilter} from "../generated/types/payloadTypes"
import {filterMap} from "./SegmentFilterMapWrapper"
import ListSegments from "../ListSegments"
import ListEmailBlasts from "../ListEmailBlasts"
import ListWorkflows from "../ListWorkflows"
import ListTemplates from "../ListTemplates"
import {v4 as uuidv4} from "uuid";
import ListCampaigns from "../ListCampaigns"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from "moment"
import DateTimePickerRow from "./DateTimePickerRow"

const filterBGColors = {
    "and": "#efe",
    "or": "#eef",
}

type UserFilterWithKey = UserFilter & { nodeKey: string}
interface IState {
    nodes: UserFilterWithKey[],
}

interface IProps {
    dispatch: any,
    nodeJoinType: "and" | "or",
    width: string,
    nodeUpdateCallback: (nodes:UserFilterWithKey[]) => void
}

class SegmentFilter extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            nodes: [this.newNode()],
        }
        this.props.nodeUpdateCallback(this.state.nodes)
    }

    newNode(): UserFilterWithKey {
        return {
            attribute: "placeholder",
            operator: "",
            values: [],
            nodeKey: uuidv4(),
        } 
    }

    attributeSelectOptions(): JSX.Element[] {
        const options:JSX.Element[] = []
        Object.keys(filterMap).map(k => {
            options.push(<MenuItem value={k}>{k.replaceAll("_", " ")}</MenuItem>)
        })

        return options
    }

    operatorSelectOptions(index: number): JSX.Element[] {
        const attribute = this.state.nodes[index].attribute
        if(!attribute || !filterMap[attribute] || !filterMap[attribute] || 
            filterMap[attribute]["allowed_operators"].length == 0) {
            return []
        }
        const options:JSX.Element[] = []
        filterMap[attribute]["allowed_operators"].map(o => {
            let displayOperator = o.replaceAll("_", " ")
            if(filterMap[attribute].value_types[0] == "date") {
                if(o == "less_than") {
                    displayOperator = "before"
                }
                if(o == "greater_than") {
                    displayOperator = "after"
                }
            }
            options.push(<MenuItem value={o}>{displayOperator}</MenuItem>)

        })
        
        return options
    }

    valueInput(index:number, inputIndex:number): JSX.Element {
        if(!this.state.nodes[index]) {
            return <></>
        }

        const attribute = this.state.nodes[index].attribute
        if(!attribute || !filterMap[attribute] || !filterMap[attribute]["value_types"]
        || filterMap[attribute]["value_types"].length-1 < inputIndex) {
            return <></>
        }

        const valueType = filterMap[attribute]["value_types"][inputIndex]

        //On change handlers for input 
        const textOnChange = (event:ChangeEvent<{name?:string, value?:unknown}>) => {
            const nodes = [...this.state.nodes]
            nodes[index].values[inputIndex] = event.target.value ? event.target.value.toString() : ""

            this.setState({
                ...this.state,
                nodes,
            })

            this.props.nodeUpdateCallback(nodes)
        }
        const dateOnChange = (newDate: moment.Moment) => {
            const nodes = [...this.state.nodes]
            nodes[index].values[inputIndex] = moment()
            if(newDate) {
                nodes[index].values[inputIndex] = newDate
            }

            this.setState({
                ...this.state,
                nodes,
            })

            this.props.nodeUpdateCallback(nodes)
        }
        const idOnchange = (event:ChangeEvent<{name?:string, value?:unknown}>) => {
            const nodes = [...this.state.nodes]
            nodes[index].values[inputIndex] = event.target.value ? event.target.value.toString() : ""

            this.setState({
                ...this.state,
                nodes,
            })

            this.props.nodeUpdateCallback(nodes)
        }
        
        switch(valueType) {
            case "string":
                return <TextField 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    style={{width:"100%"}}
                    onChange={textOnChange}
                    value={this.state.nodes[index].values[0] || ""}
                />
            case "number":
                return <TextField 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    style={{width:"100%"}}
                    onChange={textOnChange}
                    type="number"
                    value={this.state.nodes[index].values[0] || ""}
                />
            case "date":
                return <Grid container style={{paddingRight:"8px"}}>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <DateTimePickerRow 
                            hideTimePicker={true}
                            disableMinDate={true}
                            dispatch={this.props.dispatch}
                            key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                            datetimeMoment={this.state.nodes && this.state.nodes[index] && this.state.nodes[index].values
                                    && this.state.nodes[index].values[0] ? this.state.nodes[index].values[0] : null}
                            onChange={dateOnChange}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{paddingLeft:"8px",paddingTop:"8px"}}>
                        <Typography variant="body1" component="p">
                            UTC
                        </Typography>
                    </Grid>
                </Grid> 
            case "workflow_id":
                return <ListWorkflows 
                    width={this.props.width} 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    type="select" 
                    onChange={idOnchange}
                    placeholder="select workflow"
                    dispatch={this.props.dispatch} />
            case "campaign_id":
                return <ListCampaigns 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    type="select" 
                    onChange={idOnchange}
                    placeholder="select campaign"
                    dispatch={this.props.dispatch} />
            case "segment_id":
                return <ListSegments 
                    buttons={{}}
                    width={this.props.width} 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    type="select" 
                    placeholder="select segment"
                    dispatch={this.props.dispatch} 
                    onChange={idOnchange}
                    />
            case "template_id":
                return <ListTemplates width={this.props.width} 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    type="select" 
                    style={{width:"100%"}}
                    placeholder="select template"
                    onChange={idOnchange}
                    dispatch={this.props.dispatch} />
            case "scheduled_email_blast_id":
                return <ListEmailBlasts width={this.props.width} 
                    key={"input" + index.toString() + "_" + inputIndex.toString() + this.state.nodes[index].nodeKey}
                    type="select" 
                    placeholder="select email blast"
                    onChange={idOnchange}
                    dispatch={this.props.dispatch} />
        }

        console.error("unable to find value input element for type " + valueType)

        return <></>
    }

    valueInputs(index:number): JSX.Element {
        const attribute = this.state.nodes[index].attribute
        const operator = this.state.nodes[index].operator
        if(!operator || !attribute || !filterMap[attribute] || !filterMap[attribute]["value_types"]) {
            return <></>
        }
        const numValues = filterMap[attribute]["value_types"].length

        const values:JSX.Element[] = []
        for(let i = 0; i < numValues; i++) {
            values.push(this.valueInput(index, i))
        }

        return <>{values}</>
    }

    renderNode(index:number): JSX.Element {
        return <Card elevation={1} 
            key={"filtercard" + index}
            style={{
                border: "1px solid",
                borderColor: filterBGColors[this.props.nodeJoinType],
                display:"inline-block",
                margin:"0.5rem",
                padding:"0.5rem",
                width:"90%",
            }}>
            <div key={"filtercarddiv" + index} style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                    <Link to={""} className="svgShadow" onClick={(event:any) => {
                        event.preventDefault()
                        event.stopPropagation()

                        if(index === this.state.nodes.length -1) {
                            console.log("can't delete extra automatically-created node")
                            return
                        }

                        const nodes = this.removeNode([...this.state.nodes], index)
                        const newNodes = this.removeBlankNodes(nodes)
                        newNodes.push(this.newNode())

                        this.setState({
                            ...this.state,
                            nodes: newNodes,
                        })

                        this.props.nodeUpdateCallback(newNodes)
                    }}>
                        <Delete style={index !== this.state.nodes.length -1?{}:{color:"#ddd"}}  />
                    </Link>
            </div>
            <Select value={this.state.nodes[index].attribute}
                key={"selectattribute" + index}
                style={{
                    width:"100%",
                }}
                onChange={(event:ChangeEvent<{name?:string, value?:unknown}>) => {
                    event.preventDefault()
                    event.stopPropagation()

                    const nodes = [...this.state.nodes]
                    nodes[index] = this.newNode()
                    nodes[index].attribute =  event.target.value ? event.target.value.toString() : ""

                    const newNodes = this.removeBlankNodes(nodes)
                    newNodes.push(this.newNode())

                    this.setState({
                        ...this.state,
                        nodes: newNodes,
                    })

                    this.props.nodeUpdateCallback(newNodes)
                }}>
                    <MenuItem value="placeholder" key={"attribute" + index.toString() + "placeholder"} disabled>Select attribute</MenuItem>
                    {this.attributeSelectOptions()}
            </Select><br />
            {this.valueInput(index,1)}
            {(this.state.nodes[index].attribute == "" || 
              this.state.nodes[index].attribute=="placeholder" || 
              (filterMap[this.state.nodes[index].attribute] && 
               filterMap[this.state.nodes[index].attribute].allowed_operators.length != 0)) && <>
             <Select value={this.state.nodes[index].operator}
                key={"selectoperator" + index}
                style={{
                    width:"100%",
                }}
                disabled={!this.state.nodes[index].attribute || this.state.nodes[index].attribute=="placeholder"}
                onChange={(event:ChangeEvent<{name?:string, value?:unknown}>) => {
                    const nodes = [...this.state.nodes]
                    nodes[index].operator = event.target.value ? event.target.value.toString() : ""

                    this.setState({
                        ...this.state,
                        nodes,
                    })

                    this.props.nodeUpdateCallback(nodes)
                }}>
                    {this.operatorSelectOptions(index)}
            </Select><br /></>}
            {this.valueInput(index, 0)}
            {/* <TextField placeholder="Value" 
                value={this.state.nodes[index].value} 
                style={{width:"100%"}}
                onChange={(event:ChangeEvent<{name?:string, value?:unknown}>) => {
                    const nodes = [...this.state.nodes]
                    nodes[index].value = event.target.value ? event.target.value.toString() : ''

                    const newNodes = this.removeBlankNodes(nodes)
                    newNodes.push(this.newNode())

                    this.setState({
                        ...this.state,
                        nodes: newNodes,
                    })

                    this.props.nodeUpdateCallback(newNodes)
                }} /><br /> */}
        </Card>
    }

    removeNode(nodes: UserFilterWithKey[], index: number): UserFilterWithKey[] {
        return nodes.filter((n, i) => i !== index)
    }

    
    removeBlankNodes(startNodes: UserFilterWithKey[]): UserFilterWithKey[] {
        const endNodes = startNodes.filter(n => {
            return !(
                (n.attribute == "" || n.attribute=="placeholder") &&
                n.operator == "" &&
                n.values.length == 0
            )
        })

        return endNodes
    }

    addNode() {
        const nodes = this.state.nodes
        nodes.push(this.newNode())
        this.setState({
            ...this.state,
            nodes,
        })

        this.props.nodeUpdateCallback(nodes)
    }

    renderNodes(): JSX.Element {
        const orderedNodes = []
        for(let i = 0; i < this.state.nodes.length; i++) {
            orderedNodes.push(<div key={"renderedNode" + i.toString()} style={{
                width:"100%",
                minWidth:"300px",
                maxWidth:"450px",
                display:"inline-block"}}>
                <Grid  key={"renderedNodeGrid" + i.toString()} container wrap="nowrap" style={{width:"100%", flexGrow:"1"}}>
                <Grid  key={"renderedNodeItem1" + i.toString()} item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div  key={"renderNode2_" + i.toString()} style={{minWidth:"100px", height:"150px",alignItems:"center", justifyContent:"center", display:"flex"}}>
                            <Typography  key={"renderNode3_" + i.toString()} component="div" variant={"h3"} style={i!=0 && i == this.state.nodes.length-1?{color:"#ddd"}:{}}>
                                {i?this.props.nodeJoinType.toLowerCase():"Filters:"}</Typography>
                    </div>
                </Grid>
                <Grid  key={"renderedNodeItem2" + i.toString()} item xs={8} sm={8} md={8} lg={8} xl={8}>
                    {this.renderNode(i)}  
                </Grid>
                </Grid>
            </div>)
        }
             
        return <div key={"segmentfilteruirendernodes"} style={{display:"inline-flex", alignItems:"top", flexWrap:"wrap", width:"100%"}}>{orderedNodes && orderedNodes.length > 0 ? orderedNodes : null}</div>
    }

    render(): JSX.Element {
        return <div key={"segmentfilteruirender"}>
            {this.renderNodes()}
        </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state}
}


export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SegmentFilter)
