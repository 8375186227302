import styled from 'styled-components'
import {ICanvasOuterDefaultProps} from "@mrblenny/react-flow-chart/src";

export const CanvasOuterCustom = styled.div<ICanvasOuterDefaultProps>`
  position: relative;
  background-size: 15px 15px;
  background-color: #eee;
  background-image:
    linear-gradient(90deg,hsla(0,0%,100%,1) 1px,transparent 0),
    linear-gradient(180deg,hsla(0,0%,100%,1) 1px,transparent 0);
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: not-allowed;
` as any;