import React, {MouseEvent} from "react";
import {
    AttachMoney,
    Business,
    Description,
    EmojiPeople,
    FilterList,
    Group,
    KeyboardArrowRight, MeetingRoom, PersonAdd, PlayArrow, Settings,
    TrendingUp, VpnKey, QueryBuilder,  Notifications, DateRange, LocalLibrary, EmojiFlags, Widgets, ChatBubble, ChatBubbleOutline, 
} from "@material-ui/icons";
import {EmailBlastIcon, WorkflowIcon} from "../Icons";
import {deleteAuthInfo} from "../actions/Auth";
import { SEOInfo } from "./SEO";

export interface NavDef {
    href:string,
    icon?:JSX.Element,
    onClick?: (event: MouseEvent) => void;
    text:string,
    depth?: number,
    isNotifications?: boolean,
    seo?: SEOInfo,
    children?: NavDef[],
}

const SWPrefix = "Swift Missive — "
const blogRoute = { href: "/blog", icon: <LocalLibrary />, text: "Blog", seo:{
    title: SWPrefix+"Blog",
    description: "Read about Swift Missive news, or learn about how this email service provider uses innovative tech to beat the competition!",
} }
export const publicRoutes: NavDef[] = [
    { href: "/login", icon: <VpnKey />, text: "Login", seo:{
        title: SWPrefix+"Login",
        description: "Log in to Swift Missive, the cost-effective email service provider supports transactional emails, drip campaigns, newsletters, and more!",
    } },
    { href: "/demo-login", icon: <PlayArrow />, text: "Try It", seo:{
        title: SWPrefix+"Instant-Access Demo",
        description: "Try out Swift Missive for free!  No credit card or sign-up required.  This email service provider supports transactional emails, drip campaigns, newsletters, and more!",
    } },
    { href: "/signup", icon: <PersonAdd />, text: "Sign Up", seo:{
        title: SWPrefix+"Sign Up",
        description: "Join Swift Missive.  This email service provider supports transactional emails, drip campaigns, newsletters, and more!",
    } },
    { href: "/pricing", icon: <AttachMoney />, text: "Pricing", seo:{
        title: SWPrefix+"Pricing",
        description: "Swift Missive is way more cost-effective than most other email service providers.  See for yourself with the graph on this page!",
    } },
    { href: "/testamonials", icon: <ChatBubbleOutline />, text: "Testamonials", seo:{
        title: SWPrefix+"Testamonials",
        description: "See what people are saying about our email service provider!",
    } },
    blogRoute,
    { href: "/about", icon: <Business />, text: "About Us",  seo:{
        title: SWPrefix+"About",
        description: "Learn how Swift Missive can help your company grow!",
    } },
];

export const routes: NavDef[] = [
    { href: "/insights", icon: <TrendingUp />, text: "Insights", seo:{
            title: SWPrefix+"Insights",
            description: "Gather the right data to inform your marketing efforts. See how effective your email campaigns have been, see saved renderings of recent emails, and so on.",
        },
        children: [
            { href: "/insights/issues", icon: <QueryBuilder />, text: "Issues", depth: 1, seo: {
                title: SWPrefix+"Insights Into Issues",
                description: "Gather the right data to inform your marketing efforts.  See bounces, unsubscribes, and failed sends, and then figure out why they happened so that you can avoid them in the future!",
            } },
            { href: "/insights/stats", icon: <QueryBuilder />, text: "Statistics", depth: 1, seo: {
                title: SWPrefix+"Campaign Statistics",
                description: "Examine more detailed email campaign statistics to help you make well-informed email marketing choices.",
            } },
        ]
    },
    { href: "/campaigns", icon: <EmojiFlags />, text: "Campaigns", seo: {
            title: SWPrefix+"Campaigns",
            description: "Organize and drive your email marketing efforts using campaigns.  View your campaigns here.",
        },
        children: [
            { href: "/campaigns/v2/new", icon: <KeyboardArrowRight />, text: "Add Campaign", depth: 1 , seo: {
                title: SWPrefix+"New Campaign",
                description: "Create a new campaign to organize and drive your email marketing pushes.",
            }  },
        ],
    },
    
    { href: "/people", icon: <Group />, text: "People", seo: {
            title: SWPrefix+"People",
            description: "Contacts, sender personas, and segments.",
        },  
        children: [
            { href: "/people/users", icon: <Group />, text: "Contacts", depth: 1, seo: {
                    title: SWPrefix+"Contacts",
                    description: "Check out who you currently have in the system.  Swift Missive does not charge for contact storage.",
                },
                children: [
                    { href: "/people/users/new/bulk", icon: <KeyboardArrowRight />, text: "Upload File", depth: 2, seo: {
                        title: SWPrefix+"Bulk Upload Contacts",
                        description: "Add contacts to your account using our bulk uploader.  Contact storage is free.",
                    }  },
                    { href: "/people/users/new", icon: <KeyboardArrowRight />, text: "Add One", depth: 2, seo: {
                        title: SWPrefix+"Add a Single Contact",
                        description: "Add a single contact to your account manually.  Swift Missive does not charge for contact storage.",
                    }   },
                ]  
            },
            { href: "/people/segments", icon: <FilterList />, depth:1, text: "Segments", seo: {
                    title: SWPrefix+"Segments",
                    description: "View all contact-segments in your account.  Swift Missive does not charge for contact storage.",
                },
                children: [
                    { href: "/people/segments/new/bulk/2", icon: <KeyboardArrowRight />, text: "From File", depth: 2, seo: {
                        title: SWPrefix+"Bulk Upload Contacts",
                        description: "Add contacts to your account using our bulk uploader.  Contact storage is free.",
                    }  },
                    { href: "/people/segments/new/filter", icon: <FilterList />, text: "From Filters", depth: 2, seo: {
                        title: SWPrefix+"Filter Users",
                        description: "Filter contact to make segments or just to explore who you have in Swift Missive.",
                    }  },
                    { href: "/people/segments/new", icon: <KeyboardArrowRight />, text: "Create Empty", depth: 2, seo: {
                        title: SWPrefix+"Add an Empty Segment",
                        description: "Add a new empty segment.  You can add contacts to it later.",
                    }  },
                ]
            },
            { href: "/people/sender-personas", icon: <EmojiPeople />, depth:1, text: "Sender Personas", seo: {
                    title: SWPrefix+"Sender Personas",
                    description: "View the sender personas you've created in Swift Missive.",
                }  ,
                children: [
                    { href: "/people/sender-personas/new", icon: <KeyboardArrowRight />, text: "Create Persona", depth: 2, seo: {
                        title: SWPrefix+"Create Sender Persona",
                        description: "Create a new, friendly sender persona that you can send emails from.  Avoid exposing your identity and allow multiple employees to respond via the same sender persona for a seamless marketing or support experience.",
                    }  },
                ],
            },
        ],
    },
    
    { href: "/components", icon: <Widgets />, text: "Components", seo: {
            title: SWPrefix+"Components Used to Build Campaigns",
            description: "See and edit the various building blocks that go into your campaigns.",
        },
        children: [
            { 
                href: "/components/email-blasts", icon: <EmailBlastIcon />, depth:1, text: "Email Blasts", 
                seo: {
                    title: SWPrefix+"Email Blasts and Newsletters",
                    description: "See what email blasts and newsletters you've sent in the past.  You can also disable email blasts from here.",
                },
                children: [
                    { href: "/components/email-blasts/new", icon: <KeyboardArrowRight />, text: "Schedule Blast", depth: 2, seo: {
                        title: SWPrefix+"Send an Email Blast or Newsletter",
                        description: "Prepare and send a large number of emails in any timezone, including each contact's timezone.",
                    } },
                ]  
            },
            { href: "/components/workflows", icon: <WorkflowIcon />, depth: 1, text: "Workflows", seo: {
                    title: SWPrefix+"Workflows and Drip Campaigns",
                    description: "Take a look at your workflows and drip campaigns.  You can also archive workflows/drip campaigns from this page.",
                },  
                children: [
                    { href: "/components/workflows/new", icon: <KeyboardArrowRight />, text: "Create Workflow", depth: 2, seo: {
                        title: SWPrefix+"Create a New Workflow or Drip Campaign",
                        description: "Use the drag-drop interface on this page to set up workflows and/or drip campaigns.  Schedule well-timed follow up emails that send in your contacts' timezones.  Or drive an entire segment through the workflow all at once.",
                    }  },
                ]
            },
            { href: "/components/templates", icon: <Description />, depth: 1, text: "Templates", seo: {
                    title: SWPrefix+"Templates",
                    description: "View the templates you've created in Swift Missive.  You can also archive templates from here.",
                },
                children: [
                    { href: "/components/templates/new", icon: <KeyboardArrowRight />, depth:2, text: "Create Template", seo: {
                        title: SWPrefix+"Create Template",
                        description: "Create a new email template on this page using our AI-assisted drag-drop template editor.",
                    }  },
                ],
            },
        ]
    },


    { href: "/account", icon: <Settings />, text: "Account", seo: {
            title: SWPrefix+"Account",
            description: "Various account settings and internal details go here.  You can also log out from here.",
        },   
        children: [
            { href: "/account/team", icon: <Settings />, depth:1, text: "Team", seo: {
                title: SWPrefix+"Team",
                description: "Add or remove users from your account.",
            }   },
            { href: "/account/api", icon: <Settings />, depth:1, text: "API integration", seo: {
                title: SWPrefix+"API integration",
                description: "Connect your system's backend to Swift Missive and trigger emails based on events.",
            }   },
            { href: "/account/domains", icon: <Settings />, depth:1, text: "Domains", seo: {
                title: SWPrefix+"Domains",
                description: "View and setup DKIM validation for your sender domains.",
            }   },
            { href: "/account/notifications", icon: <Notifications />, depth:1, text: "Notifications", isNotifications: true, seo: {
                title: SWPrefix+"Notifications",
                description: "View notifications for your account, such as which jobs are currently running in our system.  Swift Missive believes that maximum transparecy makes your job much easier.",
            }   },
            { href: "/account/events", icon: <DateRange />, depth:1, text: "Events", seo: {
                title: SWPrefix+"Events",
                description: "View events that have occurred in Swift Missive either via the events api or internally.",
            }   },
           
            { href: "/logout", icon: <MeetingRoom />, text: "Logout", depth: 1 },
        ]
    },
]

export function routeExists(rootNodes: NavDef[], pathname: string): boolean {
    if(rootNodes.find(rn => rn.href === pathname)) {
        return true
    }

    return !!rootNodes.find(rn => rn.children && routeExists(rn.children, pathname))
}