import React from 'react';
import {defaultCardElevation} from "../App";
import {Card, Typography} from "@material-ui/core";
import {pBottom, pImageStyle,blogPostCardStyle,postMetaDetails} from "./blogPosts"

interface IProps {
    dispatch?: any,
}



export function PageBlog20230613() {
    return <>
        <Card elevation={defaultCardElevation} style={blogPostCardStyle}>
            <Typography variant={"h1"} style={{textAlign:"center"}}>Saving with Serverless</Typography>
            <Typography variant={"h3"} style={{textAlign:"center"}}>What serverless tech is, and why it makes us cooler than everyone else</Typography>
            {postMetaDetails()}
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Hi friends!👋
            </Typography>
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Welcome to the Swift Missive blog.  Today I'm going to talk about how serverless technologies work, 
            why they are the best fit for certain types of SaaS products, and how that is awesome for your bottom line.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Wait, what's that?  Swift Missive uses serverless technology all over the place?  
            You don't say!  Maybe that's why our prices are <a href="/pricing">way lower</a> than everyone elses!
            </Typography>

            <Typography variant={"h2"}>What is serverless technology?</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Serverless technology is, simply put, a bunch of tiny virtual servers that show up when you need them, 
            and disappear when you don't.  The "-less" part refers to the way the little servers are largely 
            off doing other fun server-type things until you call on them to do some work for you.  
            It's one of the more recent methods nerds like us can use to run our software.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>Some older (I'll call them "serverful" from here on) patterns that are still in common use are:</Typography>
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"ul"}>
            <li>Keeping a bunch of computers running in a little server room somewhere in an office.  These rooms often include some poor nerd who is stuck back there making sure everything keeps running all day.</li>
            <li>Running computers in "the cloud."  Which is a fancy way to say: having someone else run your computers for you in their office, making <i>their</i> nerds stay back there to make sure everything keeps running.</li>
            <li>Running containers (i.e. virtual servers that are <i>always running</i>) in the cloud.</li>
            </Typography>

            <Typography variant={"h2"}>Why does serverless matter?</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Serverless tech is important for the simple fact that it <i>usually</i> isn't doing anything.  
            Let's do a quick comparison of the different patterns that we mentioned earlier, 
            and see how they differ from serverless tech.
            </Typography>

            <Typography variant={"body1"} style={pImageStyle} component={"p"}>
                    <img src="/images/Blog-2023-03-06-1.svg" 
                        style={{width:"90%"}}
                        title="Serverless only supplies processing power when needed"
                        alt="Serverless only supplies processing power when needed" 
                        />
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            As you can see, serverless tech is the only technology available that can very rapidly adapt
            to changes in demand.  All other server configurations supply a certain amount of processing
            power that stays around even if you don't need it.  Let me show you what that means when it
            comes to server costs.
            </Typography>

            <Typography variant={"body1"} style={pImageStyle} component={"p"}>
            <img src="/images/Blog-2023-03-06-2.svg" 
                style={{width:"90%"}}
                title="Serverless only costs money when you use it"
                alt="Serverless only costs money when you use it" />
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Does this graph look familiar?  That's because the processing power available to you
            and the cost you are paying for it are directly related.  All the serverful patterns I listed earlier 
            cost the same amount of money no matter what they are doing because they always provide
            the same amount of processing power.  Serverless, on the other hand, 
            is the only pattern whose processing power--therefore also costs--change to suit your needs. 
            This is why serverless 
            architectures can be much more cost effective if you use them in the right places. You might be starting to catch my 
            drift already, but keep reading if you want to find out how we use this to <i>your</i> advantage.
            </Typography>

            <Typography variant={"h2"}>What's this about Swift Missive being cool?</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
                Let's take a quick look at an example to really send the value of serverless tech home.  
                Imagine a scenario where we have an email list of our 10 million most loyal customers, and 
                we want to thank them by sending them a 50% off coupon.</Typography>

            <Typography variant={"body1"} style={pImageStyle} component={"p"}>
            <img src="/images/Blog-2023-06-13-03.png" 
                style={{width:"90%", maxWidth:"600px"}}
                title="Cute dog in a swift missive email template"
                alt="Cute dog in a swift missive email template" />
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
                Who wouldn't want to receive that email??  
                Let's say we sent all our emails out and everyone was happy to get a nice discount.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
                So now let's think about our serverful vs serverless graphs above.  
                What will our processing power needs look like before, during, and after we send all 10 
                million of these emails all at once.  We really only want to be paying for server power when we 
                need it for that huge email burst, right?  And we don't need anything before or after that spike, right?
                Let's graph it.
            </Typography>

            <Typography variant={"body1"}  style={pImageStyle} component={"p"}>
            <img src="/images/Blog-2023-03-06-4.svg" 
                style={{width:"70%"}}
                title="A newsletter blast shows a spike in processing needs"
                alt="A newsletter blast shows a spike in processing needs" />
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
                This time I colored in the area of the graph that represents unneeded (and expensive!) 
                server power in orange.  See how the orange area of the serverlful graph is much larger than
                    the orange area of the serverless one?  This means we are saving a ton of money by not 
                    having servers running all the time.  
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            This cost-saving strategy is especially effective for email service providers like Swift Missive, 
            because we need a very low amount of processing power to keep things running during the quiet times, 
            but we also need to rapidly increase our processing capabilities when sending newsletters and so on.
            </Typography>

            <Typography variant={"h2"}>The future is now</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Much of the tech world is still only beginning to realize the true power of serverless.  
            We're the only email service provider that uses it this extensively, which we think makes us 
            the coolest email service provider in town.  And since we take that cost savings and pass it on to you, 
            we hope you agree!
            </Typography>
        </Card>
    </>
}
