import { FormControlLabel, Radio, RadioGroup, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { AllState } from '../../reducers';
import { TimezoneSelect } from '../../components/TimezoneSelect';
import DateTimePickerRow from '../../components/DateTimePickerRow';
import moment from 'moment';

interface IProps {
    width: string,
    dispatch: any,
    onSendAtChange: (sendAtMoment: moment.Moment, sendAtType:"time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay", sendAtTimezone: string, sendAtDelaySeconds: number) => void
    sendAtMoment: moment.Moment, 
    sendAtDelaySeconds: number,
    sendAtType:"time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay",
    sendAtTimezone: string,
    emailNum: number,
}
interface IState {
    delayInterval:"second"|"minute"|"hour"|"day",
}

class SelectEmailDelays extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props
        this.state = {
            delayInterval:"second",
        }
    }

    toDisplayNumber(numSeconds:number, delayInterval:"second"|"minute"|"hour"|"day"):number{
        let divisor = 1
        if(delayInterval === "minute") {
            divisor = 60
        } else if(delayInterval === "hour") {
            divisor = 3600
        } else if(delayInterval === "day") {
            divisor = 86400
        }

        return Math.floor(numSeconds / divisor)
    }

    toDelaySeconds(num:number, delayInterval:"second"|"minute"|"hour"|"day"):number{
        let multiplier = 1
        if(delayInterval === "minute") {
            multiplier = 60
        } else if(delayInterval === "hour") {
            multiplier = 3600
        } else if(delayInterval === "day") {
            multiplier = 86400
        }

        return Math.floor(num * multiplier)
    }

    render() {
        const displayNumber = this.toDisplayNumber(this.props.sendAtDelaySeconds, this.state.delayInterval)

        return  <>
            <Typography variant="h2" component="p">
                When do you want to send email number {this.props.emailNum.toString()}?
            </Typography>
            <RadioGroup
                style={{paddingLeft:"1rem", paddingBottom:"1rem"}}
                value={this.props.sendAtType}
                row={false} 
                name="sendAtType"
                onChange={(e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    let sendAtType:"time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay" = "delay"
                    sendAtType = e.target?.value?.toString() as typeof sendAtType
                    this.props.onSendAtChange(this.props.sendAtMoment, sendAtType, this.props.sendAtTimezone, this.props.sendAtDelaySeconds)
            }}>
                <FormControlLabel value={"delay"} control={<Radio color="primary" value={"delay"} />} 
                    label={<Typography variant="body1" component="span">After a set delay</Typography>} 
                    color="primary" />
                <FormControlLabel value={"time_of_day_specific_timezone"} control={<Radio color="primary" value={"time_of_day_specific_timezone"} />} 
                    label={<Typography variant="body1" component="span">At the next time of day in a specific timezone</Typography>} 
                    color="primary" />
                <FormControlLabel value={"time_of_day_users_timezone"} control={<Radio color="primary" value={"time_of_day_users_timezone"} />} 
                    label={<Typography variant="body1" component="span">At the next time of day in each contact's timezone</Typography>} 
                    color="primary" />
                <FormControlLabel value={"specific_timezone"} control={<Radio color="primary" value={"specific_timezone"} />} 
                    label={<Typography variant="body1" component="span">On or after a date and time in a specific timezone</Typography>} 
                    color="primary" />
                <FormControlLabel value={"users_timezone"} control={<Radio color="primary" value={"users_timezone"} />} 
                    label={<Typography variant="body1" component="span">On or after a date and time in each contact's timezone</Typography>} 
                    color="primary" />
            </RadioGroup>
            <div style={{marginBottom:"2rem"}}>
                {(this.props.sendAtType === "delay")&& <>
                    <Typography variant="body1" component="div" style={{fontSize:"9pt", color:"#999",paddingLeft:"1rem",paddingBottom:"4px"}}>Send email after *</Typography>
                    <div style={{paddingLeft:"1rem"}}>
                    <TextField type="number" value={displayNumber}   id= "component-simple"
                        style={{width:"50%", maxWidth:"100px", minWidth:"80px"}}
                        onChange={e => {
                            e.stopPropagation()
                            e.preventDefault()

                            let val = e.target?.value?.toString() 
                            if(val && val.length > 1 && val[0] === '0') {
                                val = val.substring(1)
                            }
                            let delayNum = parseInt(val)
                            if(Number.isNaN(delayNum)) {
                                delayNum = 0
                            }
                            const delaySeconds = this.toDelaySeconds(delayNum, this.state.delayInterval)

                            this.props.onSendAtChange(this.props.sendAtMoment, this.props.sendAtType, this.props.sendAtTimezone, delaySeconds)
                        }}/>
                    <Select value={this.state.delayInterval} 
                        label="."
                        onChange={e => {
                            e.stopPropagation()
                            e.preventDefault()

                            const newDelayInterval = e.target?.value?.toString() as ("second"|"minute"|"day"|"hour") || "second"

                            this.setState({
                                ...this.state, 
                                delayInterval: newDelayInterval,
                            })
                            //this.props.onSendAtChange(this.props.sendAtDate, this.props.sendAtType, this.props.sendAtTimezone, this.props.sendAtDelaySeconds)
                        }}>
                        <MenuItem value={"second"}>Second{displayNumber>1||displayNumber==0?"s":""}</MenuItem>
                        <MenuItem value={"minute"}>Minute{displayNumber>1||displayNumber==0?"s":""}</MenuItem>
                        <MenuItem value={"hour"}>Hour{displayNumber>1||displayNumber==0?"s":""}</MenuItem>
                        <MenuItem value={"day"}>Day{displayNumber>1||displayNumber==0?"s":""}</MenuItem>
                    </Select>
                    </div> 
                </>}
                {(this.props.sendAtType !== "delay")&& <>
                    <div style={{paddingLeft:"1rem",width:"100%",minWidth:"300px", maxWidth:"500px"}}>
                        <DateTimePickerRow 
                            onChange={(m:moment.Moment) => this.props.onSendAtChange(m, this.props.sendAtType, this.props.sendAtTimezone, this.props.sendAtDelaySeconds)} 
                            dispatch={this.props.dispatch} 
                            hideDatePicker={this.props.sendAtType === "time_of_day_specific_timezone" || this.props.sendAtType === "time_of_day_users_timezone"}
                            datetimeMoment={ this.props.sendAtMoment } />
                    </div>
                    {(this.props.sendAtType === "specific_timezone" || this.props.sendAtType === "time_of_day_specific_timezone") && <div style={{paddingLeft:"1rem"}}> 
                            <TimezoneSelect 
                                label={"Timezone"}
                                required={true}
                                placeholder={"Select timezone"}
                                style={{width:"100%",minWidth:"300px", maxWidth:"500px"}}
                                includeUsersLocalTimezoneOption={true}
                                timezone={this.props.sendAtTimezone}
                                timezoneChange={(e:any) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    
                                    const sendAtTimezone = e.target?.value?.toString() ||  "America/New_York"
                                    this.props.onSendAtChange(this.props.sendAtMoment, this.props.sendAtType, sendAtTimezone, this.props.sendAtDelaySeconds)
                                }}
                            />
                            
                        </div>}
                    {(this.props.sendAtType === "specific_timezone" || this.props.sendAtType === "users_timezone") && 
                    <Typography component="div" variant="body1" style={{marginTop:"1rem", marginBottom:"1rem"}}>
                    Note for this delay option: if it is past a given time in the contacts' timezones, they will be sent the email immediately.  
                    For instance
                    if it's now 1pm in London and you are targeting 12:30pm in your contacts' timezones, 
                    then emails to Londonners will be sent immediately, while New Yorkers will have their emails delayed
                    util 1pm eastern time.
                    </Typography>}
                </>}
            </div>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectEmailDelays)
