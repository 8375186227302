import React from 'react';
import {connect} from "react-redux";
import ListSenderPersonas from "./ListSenderPersonas";
import {clearAllButStateData} from "./actions/Common";


interface IProps {
    width: string,
    dispatch: any,
}

class PageSenderPersonas extends React.Component<IProps, null> {
    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([["sendPersonas"],["receivingSenderPersonas"], ["dkimSettings"], ["receivingDKIMSettings"]]));
    }
    render() {
        return <>
            <ListSenderPersonas type='list' {...this.props} />
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageSenderPersonas)
