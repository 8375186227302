import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component } from 'react';
import {BrowserRouter, Link, Route} from "react-router-dom";
import UserPage from "./PageUsers";
import WorkflowPage from "./PageWorkflows";
import TemplatePage from "./PageTemplates";
import NewTemplate from "./NewTemplate";
import PageEmailBlasts from "./PageEmailBlasts";
import NewEmailBlast from "./NewEmailBlast";
import NewUserComponent from "./NewUser"
import NewCampaignComponent from "./NewCampaign"
import SegmentPage from './PageSegments'
import NewSegment from './NewSegment'
import SenderPersonaPage from './PageSenderPersonas'
import NewSenderPersona from './NewSenderPersona'
import NewWorkflowComponent from './NewWorkflow'
import ReportsDashboard from "./PageInsights";
import PageNewEmailBlast from "./PageNewEmailBlast";
import {
    createTheme,
    ThemeProvider,
} from '@material-ui/core/styles';
import {
    Container, createStyles,
    Grid,
    Theme,
    withWidth,
} from "@material-ui/core";
import 'fontsource-roboto';
import PageLogin from "./PageLogin";
import {ToastContainerWrapper, showToast} from "./ToastNotifications";
import PageCampaigns from "./PageCampaigns";
import PageSignup from "./PageSignup";
import {extendCookieTTL, getAuthInfo, getAuthKey} from "./actions/Auth";
import PageWaitingForArchitecture from "./PageWaitingForArchitecture";
import {PageAbout} from "./PageAboutV2";
import PageInsights from "./PageInsights";
import {PageDemoLogin} from "./PageDemoLogin";
import {PageBlog} from "./PageBlog"
import {PagePricing} from "./PagePricing";
import PageInsightDetails from "./PageInsightDetails"
import PageEvents from './PageEvents';
import PageNotifications from './PageNotifications';
import { fetchNotifications } from './actions/Notifications';
import { store } from './store'
import NewUsers from './NewUsers';
import {PageBlog20230613} from './blog/PageBlog20230613' // /blog/serverless
import PageIssues from './PageIssues';
import PageSegmentsFromFilter from './PageSegmentsFromFilter';
import { PageBlog20230630 } from './blog/PageBlog20230630';
import { PageBlog20230808 } from './blog/PageBlog20230808';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './nav/SEO';
import ViewEmail from './ViewEmail';
import PageCampaignsWizard from './PageCampaignWizard';
import NavContainer from './nav/NavContainer';
import PageLogout from './PageLogout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment-timezone";
import { topNavMinHeight } from './nav/TopNavV2';
import { PageBlog20231203 } from './blog/PageBlog20231203';
import PageTeam from './PageTeam';
import PageDomains from './PageDomains';
import PageAPI from './PageAPI';
import PageTestimonials from './PageTestimonials';

//Makes handling timezones way easier since we always split them out from the date-time pickers
moment.tz.setDefault('UTC');

export const myTheme = createTheme({
    typography: {
        h1: {
            fontSize: "4.1rem",
            fontWeight:"bold",
        },
        h2: {
            fontSize: "2.3rem",
            marginBottom:"1rem",
        },
        h3: {
            fontSize: "1.5rem",
            marginBottom:"1rem",
        },
        h4: {
            fontSize: "1.5rem",
            marginBottom:"1rem",
        },
        body1: {
            fontSize: "1rem",
        },
        fontFamily: [
            'Lato',
            'Roboto',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
});

export const styles = (theme:Theme):any => createStyles({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});
export const actualStyles = styles(myTheme);
export const defaultCardElevation = 2;
export const defaultCardStyles = {
    paddingBottom:"1rem",
    paddingLeft:"1rem", 
    paddingRight:"1rem", 
    paddingTop:"0.5rem",
    marginTop:"0px",
    marginBottom:"0.5rem",
    borderRadius: "1rem",
    border:"1px solid #eee",
}

export function showLeftNav(width: string): boolean {
    switch(width) {
        case "xs":
        case "sm":
        case "md":
            return false;
    }

    return true;
}

class App extends Component {
    props:{width: "xs" |"sm" | "md" | "lg" |"xl"};

    constructor(props:{width: "xs" |"sm" | "md" | "lg" |"xl"}) {
        super(props);
        this.props = props;
    }

    showGutters() {
        switch(this.props.width) {
            case "xs":
                return false;
        }

        return true;
    }

    componentDidMount(): void {

        //Refresh the auth cookie since we are seeing activity
        try {
            setTimeout(extendCookieTTL, 5000);
        } catch(e) {
            console.error(JSON.stringify(e));
        }

        //If they are in the sandbox, show a message
        try {
            const allInfo = getAuthInfo()
            if(allInfo && allInfo.company && !allInfo.not_in_sandbox) {
                const forDemo = allInfo.company.name === "davestestcompanyr12"
                showToast(<><p>Welcome to the Swift Missive sandbox!  Feel free to mess around in here as much as you'd like.</p>
                    <p>In this mode you will only be able to send emails to success@simulator.amazonses.com.</p>
                    {!forDemo &&  <><p>To activate your account and send live emails, please:</p>
                    <ol>
                    <li>Add a <Link to="/components/templates/new">template</Link> that you are going to use in one of your email campaigns.</li>
                    <li><Link to="/people/users/new/bulk">Upload</Link> or <Link to="/people/users/new">manually</Link> create any number of contacts in the system.</li>
                    <li><Link to="/people/sender-personas/new">Create</Link> a sender persona to send emails from.</li>
                    <li>Shoot us an email at support@swiftmissive.com to let us know the number of emails you expect to send each month.</li>
                    </ol></>}
                    </>, "default", {autoClose:false,closeOnClick:false,draggable:false});
            }
        } catch(e) {
            console.error(JSON.stringify(e))
        }

        try {
            //Poll for notifications
            setInterval(() => {
                if(getAuthKey()) {
                    store.dispatch(fetchNotifications(100))
                }
            }, 15000)

            //Go ahead and get notifications right now too
            if(getAuthKey()) {
                store.dispatch(fetchNotifications(100))
            }
        } catch(e) {
            console.error(JSON.stringify(e));
        }
    }

    render() {
        const isAuthed = !!getAuthKey();
        // if (!showedSandboxMessage) { //(!showedSandboxMessage && isAuthed && inSandbox()) {
        //     showToast(<p>Swift Missive is currently hibernating. 😴😴😴 <br /><br /> If you'd like to sign up or view a demo, please email dave at swiftmissive.com</p>, //getSandboxText(),
        //     "info", {
        //         position: "bottom-center",
        //         autoClose: false,
        //         hideProgressBar: false,
        //         closeOnClick: false,
        //         pauseOnHover: false,
        //         draggable: true,
        //     }); 
        //     showedSandboxMessage = true;
        // }
        const showLeftNav2 = showLeftNav(this.props.width)
        const showGutters = this.showGutters()
        return (
            <ThemeProvider theme={myTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
            <HelmetProvider>
            <BrowserRouter>
                <div className="App" style={{minHeight:"100dvh"}}>
                    <SEO />
                    <Container maxWidth={"lg"} disableGutters={!showGutters} style={{ 
                            border: "3px solid #eef",
                            borderTop: "0",
                            borderBottom:"0",
                            backgroundColor:"rgba(244, 244, 255, 0.65)", 
                            minHeight:"100dvh", 
                            padding:"0",
                        }}>
                        <ToastContainerWrapper width={this.props.width} />
                        {!showLeftNav2 && <NavContainer showLeftNav={showLeftNav2} isAuthed={isAuthed} />}
                        <Grid container style={{
                                padding:"0",
                                marginTop: showLeftNav2?0:"50px",
                            }}>
                            {showLeftNav2 && <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <NavContainer showLeftNav={showLeftNav2} isAuthed={isAuthed} />
                            </Grid>}
                            <Grid style={{
                                paddingLeft:showGutters?"10px": "0px",
                                paddingTop:"5px",
                                paddingRight:showGutters?"5px":"0px",
                            }} item   xs={showLeftNav2?9:12} sm={showLeftNav2?9:12} md={showLeftNav2?9:12} lg={showLeftNav2?9:12} xl={showLeftNav2?9:12}>
                                <Route exact path="/login">
                                    {!isAuthed?<PageLogin width={this.props.width} dispatch={store.dispatch} />:<PageInsights width={this.props.width} dispatch={store.dispatch} />}
                                </Route>
                                <Route exact path="/logout">
                                    <PageLogout width={this.props.width} dispatch={store.dispatch} />
                                </Route>
                                <Route exact path="/demo-login">
                                    {!isAuthed?<PageDemoLogin width={this.props.width} dispatch={store.dispatch} />:<PageInsights width={this.props.width} dispatch={store.dispatch} />}
                                </Route>
                                <Route exact path="/signup">
                                    {<PageSignup width={this.props.width} dispatch={store.dispatch} />} 
                                </Route>
                                <Route exact path="/waiting-for-architecture">
                                    {<PageWaitingForArchitecture signupCookieSeconds={60 * 5} width={this.props.width} dispatch={store.dispatch} />} 
                                </Route>
                                <Route exact path="/campaigns/v2/new">
                                    {<PageCampaignsWizard width={this.props.width} dispatch={store.dispatch} />} 
                                </Route>
                                <Route exact path="/about">
                                    <PageAbout width={this.props.width} dispatch={store.dispatch}  />
                                </Route>
                                <Route exact path="/about/contact-us">
                                    <PageAbout width={this.props.width} dispatch={store.dispatch} focusContactName={true}  />
                                </Route>
                                <Route exact path="/about/contact-us/2">
                                    <PageAbout width={this.props.width} dispatch={store.dispatch} focusContactName={true}  />
                                </Route>
                                <Route exact path="/pricing">
                                    <PagePricing />
                                </Route>
                                <Route exact path="/testamonials">
                                    <PageTestimonials width={this.props.width} dispatch={store.dispatch}  />
                                </Route>
                                <Route exact path="/blog">
                                    <PageBlog />
                                </Route>
                                <Route exact path="/blog/serverless">
                                    <PageBlog20230613 />
                                </Route>
                                <Route exact path="/blog/other-esps-guess">
                                    <PageBlog20230630 />
                                </Route>
                                <Route exact path="/blog/timezone-based-email-delivery">
                                    <PageBlog20230808 />
                                </Route>
                                <Route exact path="/blog/send-event-based-transactional-or-drip-campaign-emails">
                                    <PageBlog20231203 />
                                </Route>
                                <Route exact path="/">
                                    {isAuthed? <ReportsDashboard width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/insights">
                                    {isAuthed? <ReportsDashboard width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/insights/issues">
                                    {isAuthed? <PageIssues width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/insights/analysis">
                                    {isAuthed? <PageInsightDetails width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/insights/stats">
                                    {isAuthed? <PageInsightDetails width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/campaigns">
                                    {isAuthed? <PageCampaigns width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> } 
                                </Route>
                                <Route exact path="/campaigns/new">
                                    {isAuthed? <NewCampaignComponent width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/campaigns/edit/:id">
                                    {isAuthed? <NewCampaignComponent width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people">
                                    {isAuthed? <UserPage width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/users">
                                    {isAuthed? <UserPage width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/users/new">
                                    {isAuthed? <NewUserComponent width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/users/edit/:id">
                                    {isAuthed? <NewUserComponent width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/email/:location+">
                                    {isAuthed? <ViewEmail  width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/segments">
                                    {isAuthed? <SegmentPage  width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> } 
                                </Route>
                                <Route exact path="/people/segments/new">
                                    {isAuthed? <NewSegment width={this.props.width} dispatch={store.dispatch}  />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/users/new/bulk">
                                    {isAuthed? <NewUsers width={this.props.width} dispatch={store.dispatch}  />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/segments/new/bulk/2">
                                    {isAuthed? <NewUsers width={this.props.width} dispatch={store.dispatch}  />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/segments/new/filter">
                                    {isAuthed? <PageSegmentsFromFilter width={this.props.width} dispatch={store.dispatch}  />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/segments/edit/:id">
                                    {isAuthed? <NewSegment width={this.props.width} dispatch={store.dispatch}  />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/sender-personas">
                                    {isAuthed? <SenderPersonaPage width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> } 
                                </Route>
                                <Route exact path="/people/sender-personas/new">
                                    {isAuthed? <NewSenderPersona width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/people/sender-personas/edit/:id">
                                    {isAuthed? <NewSenderPersona width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components">
                                    {isAuthed? <PageEmailBlasts width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/workflows">
                                    {isAuthed? <WorkflowPage width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/workflows/new">
                                    {isAuthed? <NewWorkflowComponent width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/workflows/edit/:id">
                                    {isAuthed? <NewWorkflowComponent width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/templates">
                                    {isAuthed? <TemplatePage width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/templates/new">
                                    {isAuthed? <NewTemplate width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/templates/new/:id">
                                    {isAuthed? <NewTemplate newWithId={true} width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/templates/edit/:id">
                                    {isAuthed? <NewTemplate width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> } 
                                </Route>
                                <Route exact path="/components/email-blasts">
                                    {isAuthed? <PageEmailBlasts width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/components/email-blasts/new">
                                    {isAuthed? <PageNewEmailBlast width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> } 
                                </Route>
                                <Route exact path="/components/email-blasts/edit/:id">
                                    {isAuthed? <NewEmailBlast width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account">
                                    {isAuthed? <PageTeam width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account/team">
                                    {isAuthed? <PageTeam width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account/api">
                                    {isAuthed? <PageAPI />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account/domains">
                                    {isAuthed? <PageDomains width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account/events">
                                    {isAuthed? <PageEvents width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account/notifications">
                                    {isAuthed? <PageNotifications width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                                <Route exact path="/account/settings">
                                    {isAuthed? <PageTeam width={this.props.width} dispatch={store.dispatch} />: <PageAbout width={this.props.width} dispatch={store.dispatch}  /> }
                                </Route>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </BrowserRouter>
            </HelmetProvider>
            </LocalizationProvider>
            </ThemeProvider>
        );
    }
}

export default withWidth({initialWidth: 'md'})(App);


export function withTens(num: number): string {
    if(num < 10) {
        return "0" + num.toString();
    }

    return num.toString();
}

export function toHourMinuteString(d : Date) : string {
    return withTens(d.getHours()) + ":" + withTens(d.getMinutes());
}

export function toDateString(d : Date) : string {
    return d.getFullYear() + "-" + withTens(d.getMonth() +1) + "-" + withTens(d.getDate());
}

export function toUTCDateString(d: Date): string {
    return d.getUTCFullYear() + "-" + withTens(d.getUTCMonth() +1) + "-" + withTens(d.getUTCDate());
}

export function toUTCHourMinuteString(d : Date) : string {
    return withTens(d.getUTCHours()) + ":" + withTens(d.getUTCMinutes());
}

export function withWordBreakTags(originalString: string, addWbrAfter: string[]) : JSX.Element {
    const splitter = "____WBRGOESHERE___DONOTUSE____"

    for(let i = 0; i < addWbrAfter.length; i++) {
        originalString = originalString.replaceAll(addWbrAfter[i], addWbrAfter[i] + splitter)
    }
    const stringParts = originalString.split(splitter)
    const jsxElements = stringParts.map((t, i) => <span key={"stringkey" + i}>{t}{i !== stringParts.length-1 && <wbr />}</span>)

    return <>{jsxElements}</>
}