import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  DeleteTemplatesResponsePayload,
  GetTemplatesResponsePayload,
  Template,
  UpsertTemplatesPayload,
  UpsertTemplatesResponsePayload
} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_TEMPLATES = 'REQUEST_TEMPLATES';
export const RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const REQUEST_NEW_TEMPLATE = 'REQUEST_NEW_TEMPLATE';
export const REQUEST_NEW_TEMPLATE_RESPONSE = 'REQUEST_NEW_TEMPLATE_RESPONSE';
export const ARCHIVE_TEMPLATES = 'ARCHIVE_TEMPLATES';
export const ARCHIVE_TEMPLATES_RESPONSE = 'ARCHIVE_TEMPLATES_RESPONSE';
interface IRequestTemplatesAction {
  type: typeof REQUEST_TEMPLATES,
}
interface IArchiveTemplatesAction {
  type: typeof ARCHIVE_TEMPLATES,
  ids: string[],
}
interface IArchiveTemplatesResponseAction {
  type: typeof ARCHIVE_TEMPLATES_RESPONSE,
  ids: string[],
  errors: string[],
}
interface IReceiveTemplatesAction {
  type: typeof RECEIVE_TEMPLATES,
  data: any
}
interface ISelectTemplateAction {
  type: typeof SELECT_TEMPLATE,
  selectedTemplateId: string
}
interface IRequestNewTemplateAction {
  type: typeof REQUEST_NEW_TEMPLATE,
}
interface IRequestNewTemplateResponseAction {
  type: typeof REQUEST_NEW_TEMPLATE_RESPONSE,
  template: Template,
}
export type TemplateActionTypes = IRequestTemplatesAction | IReceiveTemplatesAction | ISelectTemplateAction
    | IRequestNewTemplateAction | IArchiveTemplatesAction | IArchiveTemplatesResponseAction | IRequestNewTemplateResponseAction;

export function requestTemplatesAction() : IRequestTemplatesAction {
  return {
    type: REQUEST_TEMPLATES
  }
}

export function archiveTemplatesAction(ids: string[]):IArchiveTemplatesAction {
  return {
    type: 'ARCHIVE_TEMPLATES',
    ids,
  }
}
export function archiveTemplatesResponseAction(ids:string[], errors: string[]):IArchiveTemplatesResponseAction {
  return {
    type: 'ARCHIVE_TEMPLATES_RESPONSE',
    ids,
    errors,
  }
}

export function archiveTemplates(ids: string[]) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(archiveTemplatesAction(ids));
    return archiveTemplatesPromise(dispatch, ids);
  }
}

export function receiveTemplatesAction(data: any): IReceiveTemplatesAction {
  return {
    type: RECEIVE_TEMPLATES,
    data
  }
}

export function selectTemplateAction(selectedTemplateId: string):ISelectTemplateAction {
  return {
    type: SELECT_TEMPLATE,
    selectedTemplateId
  }
}

export function requestNewTemplateAction():IRequestNewTemplateAction {
  return {
    type: REQUEST_NEW_TEMPLATE
  }
}


export function requestNewTemplateResponseAction(template: Template):IRequestNewTemplateResponseAction {
  return {
    type: REQUEST_NEW_TEMPLATE_RESPONSE,
    template,
  }
}


export function fetchTemplates(ids:string[] = [])  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestTemplatesAction());
    return fetchTemplatesPromise(dispatch, ids)
  }
}

export function submitTemplateAction(id:string, templateName:string, templateType:string, templateSubject:string,
                                     htmlBody:string, plainTextBody:string, json: any, isDuplication: boolean, onSuccess?: () => void) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNewTemplateAction());
    return postTemplatePromise(dispatch, id, templateName,templateType,templateSubject,htmlBody,plainTextBody,json, isDuplication, onSuccess)
  }
}

function fetchTemplatesPromise(dispatch: any, ids:string[] = []) {
  //Get sender templates from api endpoint
  let url = getGatewayUrl("template") + "/templates";
  if(ids && ids.length > 0) {
    url += "?ids=" + ids.join(",");
  }
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetTemplatesResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_TEMPLATES));
      showToast(<>Couldn't fetch templates: {errorsToUL(json.errors)}</>, "error");
    }
    if(json.data) {
      console.log(`Found ${json.data.length} templates`);
    }
    dispatch(receiveTemplatesAction(json.data))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_TEMPLATES));
    showToast(`Couldn't fetch templates: ${denastifyError(e.message)}`, "error");
  });

}

function postTemplatePromise(dispatch:(...args:any)=>any, id:string, templateName:string, templateType:string, templateSubject:string,
                             htmlBody:string, plainTextBody:string, json: any, isDuplication: boolean, onSuccess?: () => void) {
  //Get sender templates from api endpoint
  const savedOrDuplicated = isDuplication? "duplicated":"saved"
  const saveOrDuplicate = isDuplication? "duplicate":"save"
  const url = getGatewayUrl("template") + "/templates";
  console.log("POSTING TO URL",url);
  const data:UpsertTemplatesPayload = {
    templates: [{
      id,
      html_body: htmlBody,
      plain_text_body: plainTextBody,
      subject: templateSubject,
      type: templateType,
      name: templateName,
      json,
      channel_id: null,
      enabled: true,
    }]
  };
  fetch(url, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    },
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .then((json:UpsertTemplatesResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      showToast(<>Couldn't save template: {errorsToUL(json.errors)}</>, "error");
    } else if(json.data && json.data.length > 0) {
      dispatch(requestNewTemplateResponseAction(json.data[0]))
      if(onSuccess) {
        onSuccess()
      }
      showToast("Successfully " + savedOrDuplicated + " template", "success");
    } else { 
      showToast("Unable to " + saveOrDuplicate + " template, did not receive response from server", "error")
    }
  }).catch(e => {
    showToast("Couldn't " + saveOrDuplicate + ` template: ${denastifyError(e.message)}`, "error");
  });
}


function archiveTemplatesPromise(dispatch:(...args:any)=>any,ids:string[]) {
  ((ids:string[]) => {
    //Get sender users from api endpoint
    const url = getGatewayUrl("template") + "/templates/" + encodeURIComponent(ids.join(","));
    console.log("POSTING TO URL",url);
    const options:any =  {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    };
    fetch(url, options)
    .then(res => res.json())
    .then((json:DeleteTemplatesResponsePayload) => {
      if(json.errors && json.errors.length > 0) {
        showToast(<>Couldn't archive templates: {errorsToUL(json.errors)}</>, "error");
        dispatch(archiveTemplatesResponseAction(json.ids, json.errors));
      } else {
        dispatch(archiveTemplatesResponseAction(json.ids, null));
        showToast("Successfully archived templates", "success");
      }
    }).catch(e => {
      dispatch(archiveTemplatesResponseAction(null, [e.message]));
      showToast(`Couldn't archive templates: ${denastifyError(e.message)}`, "error");
    });
  })(ids);
}