import React from 'react';
import {
    Card, Typography
} from "@material-ui/core";
import {defaultCardElevation, defaultCardStyles} from "./App"
import {BlogPost,blogPosts} from "./blog/blogPosts"
import {Link} from "react-router-dom"
import { getAuthKey } from './actions/Auth';

const centerStyle = {
    display: 'flex',  
    justifyContent:'center', 
    alignItems:'center'
}

const leftStyle = {
    justifyContent:'left', 
    alignItems:'left'
}

function renderBlogPostCard(post: BlogPost): JSX.Element {
    return <Link to={post.route} style={{textDecoration:"none", display:"inline-flex"}}>
        <Card elevation={1} style={{maxWidth:"350px", minWidth:"300px",height:"300px", padding:"1rem", margin:"1rem"}}>
            <Typography variant="h2" component="div" style={centerStyle}>
                <u>{post.title}</u>
            </Typography>            
            {post.blurb && <Typography variant="body1" component="div" style={{...centerStyle, height:"50px", color:"#777"}}>
                {post.blurb}
            </Typography>}
            <div style={{paddingTop:"1rem", marginBottom:"1rem"}}>
                {post.body}
            </div>
            <div>
                {post.author && <Typography variant="body2" component="span" style={{color:"#aaa",marginRight:"0.5rem"}}>
                    Author: {post.author}
                </Typography>}
                <Typography variant="body2" component="span" style={{color:"#aaa",marginRight:"0.5rem"}}>
                    Post date: {post.createdAt.toLocaleDateString()} 
                </Typography>
                {post.updatedAt && <Typography variant="body2" component="span" style={{color:"#aaa",marginRight:"0.5rem"}}>
                    Updated: {post.updatedAt.toLocaleDateString()}
                </Typography>}
            </div>
        </Card>
    </Link>
}

export function PageBlog() {
    const isAuthed = !!getAuthKey();

    return <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
    <Typography variant={"h1"} component={"div"} style={{marginBottom:"1rem"}}>
        Blog
    </Typography>
    {isAuthed && <hr />}

    <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
        Welcome to the Swift Missive blog!  Here are some of our recent posts.
    </Typography>
        {
            blogPosts.map(post => renderBlogPostCard(post))
        }
    </Card>
}
