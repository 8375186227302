import React, {ChangeEvent, FormEvent, MouseEvent} from 'react';
import { connect } from "react-redux";
import {Button, Card, Grid, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import {Compare, Email, EmojiFlags, Forward, QuestionAnswer} from "@material-ui/icons";
import {validateExists} from "./InputValidation";
import {v4 as uuidv4} from "uuid";
import {Campaign, ScheduledEmailGroup, Template, Workflow} from "./generated/types/payloadTypes";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {fetchCampaigns, submitCampaignAction} from "./actions/Campaigns";
import ListWorkflows from "./ListWorkflows";
import ListTemplates from "./ListTemplates"
import {EmailBlastIcon, WorkflowIcon} from "./Icons";
import ListEmailBlasts from "./ListEmailBlasts";
import {AllState} from "./reducers";
import {dashboardReportIds} from "./PageInsights";
import {clearStateData} from "./actions/Common";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";

interface IState {
    id: string,
    name: string,
    type: string, //"scheduled_email_group" | "workflow" | "template" | "placeholder",
    fetchedCampaign: boolean,
    selectedWorkflowIds: string[],
    selectedScheduledEmailBlastGroupIds: string[],
    selectedTemplateIds: string[],
    allowValidation: boolean,

}

interface IProps extends RouteComponentProps<{ id?: string  }> {
    dispatch: any,
    width: string,
    match: any,
    fetchedCampaign?: Campaign,
    fetchedWorkflows?: {[key:string]:Workflow},
    fetchedEmailBlasts?: {[key:string]: ScheduledEmailGroup},
    fetchedTemplates?: {[key:string]: Template},
    campaignAssociations?: {[key:string]: string[]},
}

class NewCampaignComponent extends React.Component<IProps, IState>  {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id: uuidv4(),
            name: "",
            type: "placeholder",
            fetchedCampaign: false,
            selectedWorkflowIds: [],
            selectedScheduledEmailBlastGroupIds: [],
            selectedTemplateIds: [],
            allowValidation: false, //Set to true on form submission
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.workflowChange = this.workflowChange.bind(this);
        this.emailBlastChange = this.emailBlastChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(!prevState.fetchedCampaign && nextProps.fetchedCampaign) {
            const c = nextProps.fetchedCampaign
            return {
                ...prevState,
                fetchedCampaign: true,
                id: c.id,
                name: c.name,
                type: c.type,
                selectedWorkflowIds: c.workflow_ids,
                selectedScheduledEmailBlastGroupIds: c.scheduled_email_blast_group_ids,
                selectedTemplateIds: c.template_ids,
            }
        }

        return prevState
    }

    componentDidMount(){
        if(this.props.match.params.id) {
            this.props.dispatch(fetchCampaigns([this.props.match.params.id]))
        }

        dashboardReportIds.map((reportId:string) => this.props.dispatch(clearStateData(["reportData", reportId])));
    }


    nameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                name: value
            }
        })
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            this.props.dispatch(submitCampaignAction(
                this.state.id,
                this.state.name,
                this.state.type,
                this.state.selectedScheduledEmailBlastGroupIds,
                this.state.selectedWorkflowIds,
                this.state.selectedTemplateIds));
        }
    }

    handleSelectWorkflow(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();



    }

    workflowChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        event.stopPropagation();
        event.preventDefault();

        // this.setState({
        //     ...this.state,

        // });
    }

    emailBlastChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        event.stopPropagation();
        event.preventDefault();

        // this.setState({
        //     ...this.state,
        // });
    }

    handleTypeChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        event.stopPropagation();
        event.preventDefault();

        const typeName = event.target.value.toString()
        if(typeName != "placeholder" &&
           typeName != "scheduled_email_group" &&
           typeName != "workflow" && 
           typeName != "template") {
            console.error("unknown type selection " + event.target.value.toString())
            return
        }

        console.log("Setting type to " + typeName)

        this.setState({
            ...this.state,
            selectedScheduledEmailBlastGroupIds: [],
            selectedTemplateIds: [],
            selectedWorkflowIds: [],
            type: typeName,
        });
    }

    validateForm(state: IState):boolean {
        if(validateExists(state.name) !== "") {
            return false;
        }

        return true;
    }

    render() {
        return (
            <div>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"p"} >
                        {!this.props.match.params.id ? "New " : "Edit " }
                        Campaign
                        {!this.props.match.params.id ? " " : " Associations" }
                    </Typography>
                    <hr />
                    {this.props.match.params.id && <>
                    <Typography variant="body1" component="p" style={{marginBottom:"1rem", marginTop:"1rem"}}>
                        This page will allow you to edit the <b>associations</b> of this campaign, for reporting-only purposes.
                    </Typography>
                    <Typography variant="body1" component="p" style={{marginBottom:"1rem", marginTop:"1rem"}}>
                        To change how this campaign functions, we recommend you return to the &nbsp;
                        <Link to="/campaigns">campaign list</Link> &nbsp; page, archive the campaign,
                        then create a new one. 
                    </Typography>
                    </>}
                    <form onSubmit={this.handleSubmit}>
                        <Grid container spacing={1}  alignItems="flex-end">
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{maxWidth:"30px"}}>
                                <EmojiFlags />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <TextField label={"Campaign Name"}
                                    required={true}
                                    autoFocus={true}
                                    style={{width:"100%", minWidth:"300px"}}
                                    value={this.state.name}
                                    onChange={this.nameChange}
                                    error={this.state.allowValidation && validateExists(this.state.name)!==""}
                                    helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}  alignItems="flex-end">
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{maxWidth:"30px"}}>
                                {this.state.type == "placeholder" && <Forward style={{visibility:"hidden"}} />}
                                {this.state.type == "workflow" && <WorkflowIcon />}
                                {this.state.type == "scheduled_email_group" && <EmailBlastIcon />}
                                {this.state.type == "template" && <Compare />}
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <Select value={this.state.type} onChange={this.handleTypeChange} style={{width:"100%", minWidth:"300px"}}>
                                    <MenuItem value="placeholder" disabled={true}>Campaign Type</MenuItem>
                                    <MenuItem value="workflow">Workflow / Drip</MenuItem>
                                    <MenuItem value="scheduled_email_group">Scheduled Email Blast / Newsletter</MenuItem>
                                    <MenuItem value="template">Template A/B Test</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        {this.state.type == "workflow" && <><Grid container spacing={1}  alignItems="flex-end">
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{maxWidth:"30px"}}>
                                <EmojiFlags  style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <Typography variant={"body1"} component={"p"} style={{marginTop:"0.5rem",marginBottom:"0.5rem"}}>
                                    <b>Associated workflows:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}  alignItems="flex-end">
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2} style={{maxWidth:"60px"}}>
                                <EmojiFlags  style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={6} lg={6} xl={6}>
                                <ListWorkflows width={this.props.width} 
                                    dispatch={this.props.dispatch}
                                    onSelectedWorkflowIdsChange={(ids:string[]) => {
                                        this.setState({
                                            ...this.state,
                                            selectedWorkflowIds: ids,
                                        })
                                    }}
                                    placeholder="Select workflow to add..."
                                    key={"listworkflows"}
                                    type={"multiselect"}
                                    selectedWorkflowIds={this.state.selectedWorkflowIds}
                                    style={{width:"100%", minWidth:"300px"}}  />
                            </Grid>
                        </Grid></>}
                        {this.state.type == "scheduled_email_group" && <><Grid container spacing={1}  alignItems="flex-end">
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{maxWidth:"30px"}}>
                                <EmojiFlags  style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <Typography variant={"body1"} component={"p"} style={{marginTop:"0.5rem",marginBottom:"0.5rem"}}>
                                    <b>Associated email blasts / newsletters:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}  alignItems="flex-end">
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2} style={{maxWidth:"60px"}}>
                                <EmojiFlags  style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={6} lg={6} xl={6}>
                                <ListEmailBlasts dispatch={this.props.dispatch}
                                    width={this.props.width}
                                    key={"listemailblasts"} 
                                    placeholder="Select email blast / newsletter to add..."
                                    onSelectedEmailBlastIdsChange={(ids:string[]) => {
                                        this.setState({
                                            ...this.state,
                                            selectedScheduledEmailBlastGroupIds: ids,
                                        })
                                    }}
                                    selectedEmailBlastIds={this.state.selectedScheduledEmailBlastGroupIds}
                                    type={"multiselect"}
                                    style={{width:"100%", minWidth:"300px"}}  />
                            </Grid>
                        </Grid></>}
                        {this.state.type == "template" && <><Grid container spacing={1}  alignItems="flex-end">
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{maxWidth:"30px"}}>
                                <EmojiFlags  style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <Typography variant={"body1"} component={"p"} style={{marginTop:"0.5rem",marginBottom:"0.5rem"}}>
                                    <b>Templates to compare:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}  alignItems="flex-end">
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2} style={{maxWidth:"60px"}}>
                                <EmojiFlags  style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={6} lg={6} xl={6}>
                                <ListTemplates dispatch={this.props.dispatch}
                                    width={this.props.width}
                                    key={"listtemplates"}
                                    placeholder="Select template to add..."
                                    onSelectedTemplateIdsChange={(ids:string[]) => {
                                        this.setState({
                                            ...this.state,
                                            selectedTemplateIds: ids,
                                        })
                                    }}
                                    selectedTemplateIds={this.state.selectedTemplateIds}
                                    type={"multiselect"}
                                    style={{width:"100%", minWidth:"300px"}}  />
                            </Grid>
                        </Grid></>}
                        <Grid container spacing={1}  alignItems="flex-end" className={"button-footer"} style={{marginTop:"0.5rem"}}>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{maxWidth:"30px"}}>
                                <Email style={{visibility:"hidden"}} />
                            </Grid>
                            <Grid item>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        startIcon={<SaveIcon />}
                                        type={"submit"}
                                        onClick={this.handleSubmit}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
                {/* <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography component={"p"} variant={"body1"}>
                        <b>Note:</b> Be careful when associating multiple campaigns with individual workflows and/or email blasts. Reports
                        will show a copy of the data per campaign, so if there are two campaigns associated with one workflow,
                        then your reports may show you two sets of metrics, making it look like you've sent twice as many emails! <br /><br />
                        <i>It is recommended that you only associate one campaign with each workflow or email blast to avoid any confusion.</i>
                    </Typography>
                </Card> */}
            </div>
        )
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    let fetchedCampaign = null;
    if(state.campaigns && Object.keys(state.campaigns).length > 0) {
        if(state.campaigns[ownProps.match.params.id]) {
            fetchedCampaign = state.campaigns[ownProps.match.params.id];
        }
    }

    return {
        ...state,
        fetchedCampaign,
    }
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewCampaignComponent))
