import React, { FormEvent } from 'react';
import {connect} from "react-redux";
import {AllState} from "./reducers";
import {Button, Card, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography} from "@material-ui/core";
import {FilterList, Save, Visibility} from "@material-ui/icons";
import {defaultCardElevation, defaultCardStyles} from "./App";
import SegmentFilter from "./components/SegmentFilterUI"
import { validateExists } from './InputValidation';
import {User, UserFilter} from './generated/types/payloadTypes'
import { submitRequestFilteredUsersAction, submitRequestSegmentFromFiltersAction } from './actions/Segments';
import {filterMap, allowEmptyOperatorMap} from "./components/SegmentFilterMapWrapper"
import { Link } from 'react-router-dom';
import { Loading } from './components/Loading';

interface IState {
    name: string,
    allowValidation: boolean,
    allowNodeValidation: boolean,
    nodeJoinType: "or" | "and",
    nodes: UserFilter[],
}

interface IProps  {
    dispatch: any,
    width: string,
    filteredUsers?: User[],
    totalFilteredUsers?: number,
    receivingFilteredUsers?: boolean,
    receivingBulkUploadProgress?: {[key:string]:boolean},
    bulkUploadProgress?: {[key:string]:{numInProgress:number, numTotal:number,s3Key:string}},
}

class PageSegmentsFromFilter extends React.Component<IProps, IState> {
    props: IProps;
    state: IState;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            name: "",
            allowValidation: false,
            allowNodeValidation: false,
            nodeJoinType: "or",
            nodes: [],
           // filteredUsers: [],
        }
        this.nameChange = this.nameChange.bind(this);
        this.nodeJoinTypeChange = this.nodeJoinTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitPreview = this.handleSubmitPreview.bind(this);
        this.nodeUpdateCallback = this.nodeUpdateCallback.bind(this);
    }

    nodeUpdateCallback(nodes: UserFilter[]) {
        this.setState({
            ...this.state,
            nodes,
            allowNodeValidation:false,
        })
    }

    nameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState({
            ...this.state,
            name: value
        })
    }
    
    handleSubmitPreview(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowNodeValidation) {
            this.setState({
                ...this.state,
                allowNodeValidation:true,
            })
        }

        if(this.validateNodes() == "") {
            const myNodes = this.state.nodes.slice(0,this.state.nodes.length-1)
            this.props.dispatch(submitRequestFilteredUsersAction(myNodes,
                this.state.nodeJoinType, 100, 0))
        }
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation || !this.state.allowNodeValidation) {
            this.setState({
                ...this.state,
                allowValidation:true,
                allowNodeValidation: true,
            })
        }

        if(this.validateForm()) {
            const myFilters = this.state.nodes.slice(0,this.state.nodes.length-1)
            this.props.dispatch(submitRequestSegmentFromFiltersAction(
                myFilters, this.state.nodeJoinType, this.state.name,
            ))
        }
    }

    validateNodes(): string {
        const nodeErrors:string[] = []
        
        if(this.state.nodes.length <= 1) {
            return "you must provide at least 1 filter"
        }

        this.state.nodes.map((n, i) => {
            if(i == this.state.nodes.length-1) {
                return 
            }
            if(n.attribute == "placeholder") {
                return
            }
            if(!filterMap[n.attribute]) {
                nodeErrors.push("filter " + (i+1).toString() + " is not fully filled out")
                return
            }
            const matchingFilterValueTypes = filterMap[n.attribute].value_types
            const emptyValues = n.values.filter(val => val.toString() == "" || val.toString() == "null")
            if(!n.attribute || emptyValues.length > 0 || n.values.length != matchingFilterValueTypes.length 
                || (!allowEmptyOperatorMap[n.attribute] && !n.operator)) {
                nodeErrors.push("filter " + (i+1).toString() + " is not fully filled out")                
           }
        })

        if(nodeErrors.length == 0) {
            return ""
        }

        return nodeErrors.join(", ")
    }

    validateForm():boolean {
        if(validateExists(this.state.name) !== "") {
            return false;
        }

       if(this.state.nodes.length == 0) {
            return false
       }

       if(this.validateNodes() != "") {
            return false
       }

        return true;
    }

    nodeJoinTypeChange(event: React.ChangeEvent<{name?: string, value: unknown}>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target?.value?.toString();
        if(value != "or" && value != "and") {
            console.error("somehow got non-or non-and value for select")
            return
        }

        this.setState({
            ...this.state,
            nodeJoinType: value,
        })
    }

    render() :JSX.Element {
        return <><Card elevation={defaultCardElevation} style={{ 
            ...defaultCardStyles,
        }}>
            <Typography variant={"h1"} component="div">
                Filter Contacts
            </Typography>

            <hr />
            <form>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <FilterList />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField label={"New segment name"}
                        required={true}
                        autoFocus={true}
                        style={{width:"100%",minWidth:"300px", maxWidth:"500px"}}
                        value={this.state.name}
                        onChange={this.nameChange}
                        error={this.state.allowValidation && validateExists(this.state.name)!==""}
                        helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    {this.state.nodeJoinType == "and" ? <FilterList style={{transform:"rotate(-90deg)"}} />
                        : <FilterList style={{transform:"rotate(90deg)"}} />}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Select label={"Filter conjunction"}
                        required={true}
                        style={{width:"100%",minWidth:"300px", maxWidth:"500px"}}
                        value={this.state.nodeJoinType}
                        onChange={this.nodeJoinTypeChange}
                    >
                        <MenuItem value="and">Match every filter below</MenuItem>  
                        <MenuItem value="or">Match any filter below</MenuItem>  
                    </Select>   
                </Grid>
            </Grid>
           
            <SegmentFilter nodeJoinType={this.state.nodeJoinType} dispatch={this.props.dispatch} width={this.props.width} nodeUpdateCallback={this.nodeUpdateCallback} />
            
            {this.state.allowNodeValidation && this.validateNodes() != "" && <Grid spacing={1} container style={{marginTop:"0.5rem"}}>
                <Grid item></Grid>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <Typography variant="body1" component="p" style={{color:"red"}}>
                        Filter errors: {this.validateNodes()}
                    </Typography>
                </Grid>
            </Grid>}

            <Grid spacing={1} container>
                <Grid item>
                <Button variant={"contained"}
                            color={"primary"}
                            startIcon={<Save />}
                            onClick={this.handleSubmit}
                            // disabled={!!this.state.waitingForSave}
                        >
                        Save as segment
                    </Button> 
                </Grid>
                <Grid item>
                    <Button variant={"contained"}
                        color={"secondary"}
                        startIcon={<Visibility />}
                        onClick={this.handleSubmitPreview}
                        // disabled={!!this.state.waitingForSave}
                    >
                        Preview
                </Button>
                </Grid>
            </Grid>
            </form>
        </Card>
        <Card elevation={defaultCardElevation} style={{ 
            ...defaultCardStyles,
        }}>
            <Typography variant="h1" component={"div"} style={{marginTop:"0.5rem", marginBottom:"1rem"}}>Preview</Typography>
            {(!this.props.receivingFilteredUsers && !!this.props.totalFilteredUsers && this.props.totalFilteredUsers != 0) && <Typography variant="body1" component={"div"}
                style={{marginLeft:"1rem"}}
            >
                <b>{this.props.totalFilteredUsers.toLocaleString()} contacts matched.</b>&nbsp;&nbsp;
                Here is a sample:
            </Typography>}
            {this.props.receivingFilteredUsers && <Loading />}
            {!this.props.receivingFilteredUsers && (!this.props.filteredUsers || !this.props.filteredUsers.length)?"Nothing here, yet":""}
            {!this.props.receivingFilteredUsers && this.props.filteredUsers && this.props.filteredUsers.length > 0 && <TableContainer><Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Added</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {this.props.filteredUsers.map(u => <TableRow key={"userrow_" + u.id}>                    
                            <TableCell><Link to={"/people/users/edit/"+u.id}>{u.email}</Link></TableCell>
                            <TableCell>{u.first_name}</TableCell>
                            <TableCell>{u.last_name}</TableCell>
                            <TableCell>{new Date(u.created_at).toLocaleDateString() + " " + 
                                new Date(u.created_at).toLocaleTimeString()}</TableCell>
                        </TableRow>)}
                </TableBody>
            </Table></TableContainer>}
        </Card>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageSegmentsFromFilter)

