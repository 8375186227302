import { Typography,Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { AllState } from '../../reducers';
import ListSegments from '../../ListSegments';


interface IProps {
    width: string,
    dispatch: any,
    onSegmentsChange:(selectedSegmentIds:{[key:string]:boolean}) => void
    selectedSegmentIds:{[key:string]:boolean},
    prevPageText?: JSX.Element,
    nextPageText?: JSX.Element,
}
interface IState {
}

class SelectSegments extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props
        this.state = {
            selectedSegmentIds: {},
        }
    }

    render() {
        return  <>
            <Typography variant="h2" component="p">
                Please select your target segments
            </Typography>
            <Typography variant="body1" component="p" style={{marginBottom:"1rem"}}>
                <b>{Object.keys(this.props.selectedSegmentIds).length ?? "0" } segment{Object.keys(this.props.selectedSegmentIds).length == 1 ? "":"s" } currently selected</b>
            </Typography>
            <ListSegments 
                type="list" 
                limit={5}
                pagingButtonVariant='outlined'
                dispatch={this.props.dispatch} 
                selectedSegmentIds={Object.keys(this.props.selectedSegmentIds)}
                width={this.props.width} 
                buttons={{"add":true,"remove":true, "edit":true}}
                editButtonTargetBlank={true}
                toggleIfNameClicked={true} 
                prevPageText={this.props.prevPageText}
                nextPageText={this.props.nextPageText}
                onSegmentAdd={id => {
                    const selectedSegmentIds= {
                        ...this.props.selectedSegmentIds, [id]: true,
                    }
                    this.setState({...this.state, selectedSegmentIds})
                    if(this.props.onSegmentsChange) {
                        this.props.onSegmentsChange(selectedSegmentIds)
                    }
                }}
                onSegmentRemove={id => {
                    const newSegmentIds:{[key:string]:boolean} = {}
                    Object.keys(this.props.selectedSegmentIds).map(currentId => {
                        if(id != currentId) {
                            newSegmentIds[currentId] = true
                        }
                    })
                    this.setState({...this.state, selectedSegmentIds:newSegmentIds})
                    if(this.props.onSegmentsChange) {
                        this.props.onSegmentsChange(newSegmentIds)
                    }
                }}
                />
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectSegments)
