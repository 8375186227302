import * as React from 'react'
import styled from 'styled-components'
import { INodeDefaultProps } from '@mrblenny/react-flow-chart'
import {INodeInnerDefaultProps} from "@mrblenny/react-flow-chart/src";

export const colorMap: {[key:string]:string} = {
  "root": "#986baa",
  "event": "#986baa",
  "user_upserted": "#986baa",
  "delay": "#4c716e",
  "delay_until": "#4c716e", 
  "splitter": "#4c716e",
  "sender": "#4b5b8b",
};

export const nameMap: {[key:string]:string} = {
  "root": "Root",
  "event": "Event trigger",
  "user_upserted": "User trigger",
  "delay": "Delay",
  "delay_until": "Delay until",
  "splitter": "Split",
  "sender": "Send",
};

function styledBox(nodeType: string) {
  return styled.div`
    position: absolute;
    padding-top: 18px;
    padding-bottom:18px;
    border: 1px solid #4a4a4a;
    min-width: 150px;
    min-height: 20px;
    vertical-align: center;
    text-align:center;
    background-color: ${colorMap[nodeType]};
    font-size: 1.1em;
    color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 3px -4px rgba(0,0,0,0.4), 0px 4px 4px 0px rgba(0,0,0,0.34), 0px 3px 7px 0px rgba(0,0,0,0.32);
  `
}

const RootBox = styledBox("root");
const DelayBox = styledBox("delay");
const DelayUntilBox = styledBox("delay_until");
const SplitterBox = styledBox("splitter");
const SenderBox = styledBox("sender");

export const NodeInnerCustom = ({ node, config }: INodeInnerDefaultProps) => {
  return (
      <div>
        <p>{nameMap[node.type]}</p>
      </div>
  )
};

export const DavemailNodeFactory = React.forwardRef(({ node, children, ...otherProps }: INodeDefaultProps, ref: React.Ref<HTMLDivElement>) => {
  switch(node.type?.toLowerCase()) {
    case "user_upserted":
      return <RootBox ref={ref} {...otherProps}>{children}</RootBox>;
    case "event":
      return <RootBox ref={ref} {...otherProps}>{children}</RootBox>;
    case "delay":
      return <DelayBox ref={ref} {...otherProps}>{children}</DelayBox>;
    case "delay_until":
      return <DelayUntilBox ref={ref} {...otherProps}>{children}</DelayUntilBox>;
    case "splitter":
      return <SplitterBox ref={ref} {...otherProps}>{children}</SplitterBox>;
    case "sender":
      return <SenderBox ref={ref} {...otherProps}>{children}</SenderBox>;
    default:
      console.error("Unknown node type " + node.type);
      break;
  }

  throw new Error(`Unknown node type ${node.type}`);
});