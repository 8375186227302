import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import { GetEmailSendResultsResponsePayload, } from "../generated/types/payloadTypes";
import {  getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_EMAIL_SEND_RESULT = 'REQUEST_EMAIL_SEND_RESULT';
export const RECEIVE_EMAIL_SEND_RESULT = 'RECEIVE_EMAIL_SEND_RESULT';

interface IRequestEmailSendResultAction {
  type: typeof REQUEST_EMAIL_SEND_RESULT,
}
interface IReceiveEmailSendResultAction {
  type: typeof RECEIVE_EMAIL_SEND_RESULT,
  data: any
}
export type EmailActionTypes = IRequestEmailSendResultAction | IReceiveEmailSendResultAction;

export function requestEmailSendResultAction():IRequestEmailSendResultAction {
  return {
    type: 'REQUEST_EMAIL_SEND_RESULT'
  }
}

export function receiveEmailSendResultsAction(data:any):IReceiveEmailSendResultAction {
  return {
    type: 'RECEIVE_EMAIL_SEND_RESULT',
    data
  }
}

export function fetchEmailSendResults(location: string) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestEmailSendResultAction());
    return fetchEmailSendResultsPromise(dispatch, location)
  }
}

function fetchEmailSendResultsPromise(dispatch:(...args:any)=>any, location:string) {
  //Get send-email-results from api endpoint
  const url = getGatewayUrl("reportingwrapper") + "/email_send_results?locations=" + encodeURIComponent(location);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetEmailSendResultsResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_EMAIL_SEND_RESULT));
      showToast(<>Couldn't fetch email-send results: {errorsToUL(json.errors)}</>, "error");
    }

    let emailSendResults:any[] = [];
    if(json.send_results) {
      emailSendResults = json.send_results;
    }
    console.log("Found " + emailSendResults.length + " email-send results");
    dispatch(receiveEmailSendResultsAction(emailSendResults))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_EMAIL_SEND_RESULT));
    showToast(`Couldn't fetch email-send results: ${denastifyError(e.message)}`, "error");
  });
}
