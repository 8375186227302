import React, {FormEvent, MouseEvent} from 'react';
import {Button, Card, Grid, TextField, Typography} from "@material-ui/core";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {validateEmail, validateExists} from "./InputValidation";
import {Email, Lock} from "@material-ui/icons";
import {getAuthKey, login} from "./actions/Auth";
import {connect} from "react-redux";
import { Link } from 'react-router-dom'
import { getSignupCookieTime } from './PageWaitingForArchitecture';
import { InformationArea } from './components/InformationArea';

interface IState {
    allowValidation: boolean,
    email: string,
    password: string,
    walkthroughCustomerName: string,
    walkthroughCustomerEmail: string,
    walkthroughDetails: string,
    walkthroughRequested: boolean,
    waitingForReadMessage: boolean,
    signupCookieStillExists: boolean,
}

interface IProps {
    dispatch: any,
    processingLogin?: boolean,
    forDemo?: boolean,
    width: string,
}

class PageLogin extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            email:"",
            password:"",
            allowValidation:false,
            walkthroughCustomerName: "",
            walkthroughCustomerEmail: "",
            walkthroughDetails: "",
            walkthroughRequested: false,
            waitingForReadMessage: false,
            signupCookieStillExists: false,
        };

        // If we're not on the demo page and they just signed up, show them a message saying to wait
        if(!this.props.forDemo) {
            const signupCookieStillExists = getSignupCookieTime()
            if(signupCookieStillExists) {
                this.state.waitingForReadMessage = true 
                this.state.signupCookieStillExists = true 
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        waitingForReadMessage: false,
                    })
                }, 8000)
            }
        }


        if(this.props.forDemo) {
            this.state.email = "demouser@swiftmissive.com";
            this.state.password = "demouserpassword@swiftmissive.com";
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.walkthroughCustomerNameChange = this.walkthroughCustomerNameChange.bind(this);
        this.walkthroughEmailChange = this.walkthroughEmailChange.bind(this);
        this.walkthroughDetailsChange = this.walkthroughDetailsChange.bind(this);
        this.submitWalkthroughRequest = this.submitWalkthroughRequest.bind(this);
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            console.log("Logging in...");
            this.props.dispatch(login({
                email: this.state.email,
                password: this.state.password,
            }));
        }
    }

    
    submitWalkthroughRequest(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(this.validateForm(this.state)) {
            console.log("Requesting walkthrough...");
            this.props.dispatch(login({
                email: this.state.email,
                password: this.state.password,
            }));
        }
    }

    walkthroughCustomerNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                walkthroughCustomerName: value
            }
        })
    }

    walkthroughEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                walkthroughEmail: value
            }
        })
    }

    walkthroughDetailsChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                walkthroughDetails: value
            }
        })
    }

    emailChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                email: value
            }
        })
    }

    passwordChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        const value = event.target.value;
        this.setState(state => {
            return {
                ...state,
                password: value
            }
        })
    }

    validateForm(state: IState):boolean {
        if(validateEmail(state.email) !== "") {
            return false;
        }
        if(validateExists(state.password) !== "") {
            return false;
        }

        return true;
    }

    render() {
        return <>
            {!(!this.props.processingLogin && getAuthKey()) && <>
            <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                <Typography variant={"h1"} component={"div"} style={{marginBottom:"1rem"}}>
                    {this.props.forDemo && "Try the live demo!"}
                    {!this.props.forDemo && "Login"}
                </Typography>
                {this.props.forDemo && <>
                    <Typography variant={"body1"} component={"p"}>
                        The button below will log you into our demo environment.  To login here you must agree to the following:
                    </Typography>
                    <Typography variant={"body1"}>
                    <ul>
                        <li>I will not upload images I don’t have copyrights to or that are illegal</li>
                        <li>Use does not imply ownership over the demo environment or its contents</li>
                        <li>Images may be automatically replaced by cute puppies 🐶</li>
                    </ul>
                    </Typography>
                </>}

                <form onSubmit={this.handleSubmit}>
                <Grid container  style={this.props.forDemo?{height:"1px", visibility:"hidden"}:{}}>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Email style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Email"}
                                   disabled={!!this.props.forDemo}
                                   autoFocus={true}
                                   required={true}
                                   style={{width:"100%", minWidth:"300px"}}
                                   value={this.state.email}
                                   type={"email"}
                                   name={"email"}
                                   onChange={this.emailChange}
                                   error={this.state.allowValidation && validateEmail(this.state.email)!==""}
                                   helperText={this.state.allowValidation && validateExists(this.state.email)!==""? validateEmail(this.state.email): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container style={this.props.forDemo?{height:"1px", visibility:"hidden"}:{}}>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Lock style={{marginTop:"20px"}} />
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7}>
                        <TextField label={"Password"}
                                   disabled={!!this.props.forDemo}
                                   style={{width:"100%", minWidth:"300px"}}
                                   required={true}
                                   value={this.state.password}
                                   type={"password"}
                                   name={"password"}
                                   onChange={this.passwordChange}
                                   error={this.state.allowValidation && validateExists(this.state.password)!==""}
                                   helperText={this.state.allowValidation && validateExists(this.state.password)!==""? validateExists(this.state.password): ""}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{marginTop:"10px", marginBottom:"0.5rem"}}>
                    <Grid item xs={7} md={7} lg={7} xl={7} sm={7} style={{paddingLeft:"1.5rem"}}>
                        <Button
                            disabled={!!this.props.processingLogin || this.state.waitingForReadMessage}
                            variant={"contained"}
                            color={"primary"}
                            onClick={this.handleSubmit}
                            type={"submit"}
                            style={{marginRight:"1rem", marginBottom:"0.5rem"}}>
                            {!this.props.forDemo?"Login":"I Agree"}
                        </Button>
                        {this.props.forDemo && <Link to="/about/contact-us">
                            <Button variant={"outlined"}
                                    color={"primary"}
                                    type={"submit"}
                                    style={{marginRight:"1rem", marginBottom:"0.5rem"}}>
                                I'd prefer a walkthrough
                            </Button>
                        </Link>}
                    </Grid>
                </Grid>
                {(!this.props.forDemo && this.state.signupCookieStillExists) && <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InformationArea type={"warning"} contents={<Typography variant='h2'>
                            We're still getting your account set up!  This will usually take about 20 minutes.  Please come back
                            at that time.
                        </Typography>}  />
                    </Grid>
                </Grid>}
                </form>
            </Card>
            </>}
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageLogin);
