import React from "react";
import {connect} from "react-redux";
import {Card, Typography} from "@material-ui/core";
import {Link} from 'react-router-dom'
import {defaultCardElevation,defaultCardStyles} from "./App";
import { Email } from "@material-ui/icons";
import moment from "moment";
import cookies from "js-cookie";

interface IProps {
    match?: any,
    width: string,
    dispatch: any,
    signupCookieSeconds:number,
}

class PageWaitingForArchitecture extends React.Component<IProps, null> {
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        setSignupCookie(Number.isNaN(this.props.signupCookieSeconds) ? 5 * 60 : this.props.signupCookieSeconds)
    }


    render() {
        return  <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
               <Typography variant={"h1"}  style={{fontSize:"38pt"}}><Email  style={{fontSize:"30pt"}} />Please check your email<Email style={{fontSize:"30pt"}}/></Typography>
                <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem",marginTop:"1rem"}}>
                      Welcome! We are currently getting your account ready.  <br /><br />
                      It usually takes about 30 minutes to set up new accounts.   <br /><br />
                      You'll receive an email when you're good to go!
                </Typography>
            </Card>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageWaitingForArchitecture)


export const SIGNUP_COOKIE_NAME = "signupcookie"
export function getSignupCookieTime() {
    const signupTime = cookies.get(SIGNUP_COOKIE_NAME)
    if(signupTime) {
        console.log("sign up wait ends at " + signupTime.toString())
    } else {
        console.log("no sign up time found")
    }
    return signupTime
}

export function setSignupCookie(ttlSeconds: number) {
    if(Number.isNaN(ttlSeconds)) {
        console.error("missing ttlSeconds in signup cookie expiry")
        return
    }
    if(getSignupCookieTime()) {
        console.warn("not setting signup time, it's already set")
        return
    }
    const getRidOfCookieAt = moment().add(ttlSeconds.toString(), "seconds")
    cookies.set(SIGNUP_COOKIE_NAME, JSON.stringify(getRidOfCookieAt.toISOString()), { expires: getRidOfCookieAt.toDate(), sameSite: "strict" })
    console.log("signup time ends at " + getRidOfCookieAt.toLocaleString())
}