import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {GetDataResponsePayload} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_REPORTS = 'REQUEST_REPORTS';
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS';
export const REQUEST_REPORT_DATA = 'REQUEST_REPORT_DATA';
export const RECEIVE_REPORT_DATA = 'RECEIVE_REPORT_DATA';

interface IRequestReportsAction {
  type: typeof REQUEST_REPORTS,
}
interface IReceiveReportsAction {
  type: typeof RECEIVE_REPORTS,
  data: any
}
interface IRequestReportDataAction {
  type: typeof REQUEST_REPORT_DATA,
  forId: string,
  campaignId?:string,
}
interface IReceiveReportDataAction {
  type: typeof RECEIVE_REPORT_DATA,
  reportData: any,
  reportErrors: any,
  forId: string,
}
export type ReportActionTypes = IRequestReportsAction | IReceiveReportsAction | IRequestReportDataAction | IReceiveReportDataAction;

export function requestReportDataAction(forId:string, campaignId?:string):IRequestReportDataAction {
  return {
    type: 'REQUEST_REPORT_DATA',
    forId,
    campaignId,
  }
}
export function receiveReportDataAction(data:any, errors:string[], forId:string):IReceiveReportDataAction {
  return {
    type: 'RECEIVE_REPORT_DATA',
    reportData: data,
    reportErrors: errors,
    forId,
  }
}

export function fetchReportDataAction(query: string, forId: string) {
  return function(dispatch:(...args:any)=>any) {
    return fetchReportDataPromise(dispatch, query, forId)
  }
}

function fetchReportDataPromise(dispatch:(...args:any)=>any, query: string, forId: string) {
  dispatch(requestReportDataAction(forId));

  ((forIdClosure:string) => {
    //Get sender Reports from api endpoint
    //let queryUTF8 = Buffer.from(query).toString('utf8');
    const queryUTF8Base64 =  Buffer.from(query).toString('base64');
    const url = getGatewayUrl("reporting") + "/data";
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      },
      body: queryUTF8Base64,
    })
    .then(res => res.json())
    .then((json:GetDataResponsePayload) => {
      if(json.errors && json.errors.length > 0) {
        dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
        showToast(<>Couldn't fetch report data: {errorsToUL(json.errors)}</>, "error");
      }
      dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
    }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);
}


export function fetch24HourReportDataAction(forId: string, campaignId: string) {
  return function(dispatch:(...args:any)=>any) {
    return fetch24HourReportDataPromise(dispatch, forId, campaignId)
  }
}

function fetch24HourReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId: string) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    const url = getGatewayUrl("reporting") + "/sendslast24hours?campaign_id=" + encodeURIComponent(campaignId);
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch 24-hour-sends report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch 24-hour-sends report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);
}

export function fetch31DayReportDataAction(forId: string, campaignId:string) {
  return function(dispatch:(...args:any)=>any) {
    return fetch31DayReportDataPromise(dispatch, forId, campaignId)
  }
}

function fetch31DayReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    const url = getGatewayUrl("reporting") + "/sendslast31days?campaign_id=" + encodeURIComponent(campaignId);
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch 31-day-sends report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch 31-day-sends report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);
}


export function fetchMostClickedLinksReportDataAction(forId: string, campaignId:string) {
  return function(dispatch:(...args:any)=>any) {
    return fetchMostClickedLinksReportDataPromise(dispatch, forId, campaignId)
  }
}

function fetchMostClickedLinksReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    const url = getGatewayUrl("reporting") + "/mostclickedlinks?campaign_id=" + encodeURIComponent(campaignId);
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch most-clicked-links report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch most-clicked-links report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);
}


export function fetchRecentlyClickedLinksReportDataAction(forId: string, campaignId:string, limit:number, offset:number) {
  return function(dispatch:(...args:any)=>any) {
    return fetchRecentlyClickedLinksReportDataPromise(dispatch, forId, campaignId, limit, offset)
  }
}

function fetchRecentlyClickedLinksReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string, limit:number, offset:number) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    let url = getGatewayUrl("reporting") + "/recentlyclickedlinks?campaign_id=" + encodeURIComponent(campaignId);
    if(limit) {
      url += "&limit=" + limit.toString()
    }
    if(offset) {
      url += "&offset=" + offset.toString()
    }
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch recently-clicked-links report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch recently-clicked-links report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);

}
export function fetchOpensAndClicksBySourceReportDataAction(forId: string, campaignId:string, by:"user_agent"|"ip"|"domain", limit:number, offset:number) {
  return function(dispatch:(...args:any)=>any) {
    return fetchOpensAndClicksBySourceReportDataPromise(dispatch, forId, campaignId, by, limit, offset)
  }
}

function fetchOpensAndClicksBySourceReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string, by:"user_agent"|"ip"|"domain", limit:number, offset:number) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    let url = getGatewayUrl("reporting") + "/opensbysource?" + 
      "campaign_id=" + encodeURIComponent(campaignId) + 
      "&by=" + encodeURIComponent(by)
    if(limit) {
      url += "&limit=" + limit.toString()
    }
    if(offset) {
      url += "&offset=" + offset.toString()
    }
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch by-source report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch  by-source report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);

}


export function fetchMostOpenedEmailsReportDataAction(forId: string, campaignId:string, by:"sender_persona"|"subject", limit:number, offset:number) {
  return function(dispatch:(...args:any)=>any) {
    return fetchMostOpenedEmailsReportDataPromise(dispatch, forId, campaignId, by, limit, offset)
  }
}

function fetchMostOpenedEmailsReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string, by:"sender_persona"|"subject", limit:number, offset:number) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    let url = getGatewayUrl("reporting") + "/mostopenedemails?" + 
      "campaign_id=" + encodeURIComponent(campaignId) + 
      "&by=" + encodeURIComponent(by)
    if(limit) {
      url += "&limit=" + limit.toString()
    }
    if(offset) {
      url += "&offset=" + offset.toString()
    }
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch most-opened emails report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch most-opened emails report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);

}


export function fetchRecentlyOpenedEmailsReportDataAction(forId: string, campaignId:string, limit:number, offset:number) {
  return function(dispatch:(...args:any)=>any) {
    return fetchRecentlyOpenedEmailsReportDataPromise(dispatch, forId, campaignId, limit, offset)
  }
}

function fetchRecentlyOpenedEmailsReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string, limit:number, offset:number) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    let url = getGatewayUrl("reporting") + "/recentlyopenedemails?" + 
      "campaign_id=" + encodeURIComponent(campaignId) 
    if(limit) {
      url += "&limit=" + limit.toString()
    }
    if(offset) {
      url += "&offset=" + offset.toString()
    }
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch most-opened emails report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch most-opened emails report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);

}

export function fetchQuickStatsReportDataAction(forId: string, campaignId:string) {
  return function(dispatch:(...args:any)=>any) {
    return fetchQuickStatsReportDataPromise(dispatch, forId, campaignId)
  }
}

function fetchQuickStatsReportDataPromise(dispatch:(...args:any)=>any, forId: string, campaignId:string) {
  dispatch(requestReportDataAction(forId, campaignId));
  if(campaignId == "" || campaignId == "placeholder" || !campaignId || campaignId.toLowerCase() == "all") {
    campaignId = "all"
  }

  ((forIdClosure:string) => {
    const url = getGatewayUrl("reporting") + "/quickstats?campaign_id=" + encodeURIComponent(campaignId);
    fetch(url, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': getAuthKey(),
      }
    })
        .then(res => res.json())
        .then((json:GetDataResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),forIdClosure));
            showToast(<>Couldn't fetch quick stats / report data: {errorsToUL(json.errors)}</>, "error");
          }
          dispatch(receiveReportDataAction(json.data ? json.data : {}, json.errors ? json.errors : null, forIdClosure));
        }).catch(e => {
      dispatch(requestDataErrorAction(e.message,forIdClosure));
      showToast(`Couldn't fetch quick stats / report data: ${denastifyError(e.message)}`, "error");
    });
  })(forId);

}