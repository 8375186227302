import React, {ChangeEvent} from 'react';
import {connect} from "react-redux";
import {
    fetchSegments,
    submitAddUsersToSegmentAction,
    submitRemoveUsesrsFromSegmentAction, submitSegmentAction, submitSegmentNameUpdateAction,
} from './actions/Segments'
import {
    Button,
    Card, Grid,  TextField,
    Typography
} from "@material-ui/core";
import {FilterList, Search,Add} from "@material-ui/icons";
import {Segment, User} from "./generated/types/payloadTypes";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {defaultCardElevation, defaultCardStyles, withTens} from "./App";
import {AllState, userSegmentTableIds} from "./reducers";
import {v4 as uuid} from "uuid"
import SaveIcon from "@material-ui/icons/Save";
import {validateExists} from "./InputValidation";
import ListUsers from './ListUsers';

interface IState {
    id: string;
    name: string;
    confirmDeleteDialogOpen: {[key:string]:boolean};
    allowValidation: boolean;
    waitingForSave: boolean;
    fetchedSegment:boolean;
}

interface IProps extends RouteComponentProps<{ id?: string  }> {
    dispatch: any,
    width: string,
    style?: any;
    segments?: {[key:string]:Segment},
    usersInSegmentComponentKey?: string,
    usersNotInSegmentComponentKey?: string,
    fetchedSegment?:Segment;
    usersInSegmentKey?: string,
    usersNotInSegmentKey?: string,
}

class EditSegmentComponent extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: this.props.match.params.id || uuid(),
            name: "",
            confirmDeleteDialogOpen: {},
            allowValidation: false,
            waitingForSave: false,
            fetchedSegment: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.removeUserFromSegment = this.removeUserFromSegment.bind(this);
        this.addUserToSegment = this.addUserToSegment.bind(this);
    }

    componentDidMount(){
        if(this.props.match?.params?.id) {
            this.props.dispatch(fetchSegments([this.props.match.params.id]))
        }
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(!prevState.fetchedSegment && nextProps.fetchedSegment) {
            return {
                ...prevState,
                id: nextProps.fetchedSegment.id,
                name: nextProps.fetchedSegment.name,
                fetchedSegment: true,
            }
        }

        //If we just saved a new segment and now have it
        if(prevState.waitingForSave && nextProps.segments && nextProps.segments[prevState.id]) {
            if(!nextProps.match.params.id) {
                //window.location.href = '/segments/edit/' + prevState.id;
                return null
            } else {
                return {
                    ...prevState,
                    waitingForSave: false,
                }
            }
        }

        return null;
    }


    nameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        // const name = event.target.value;
        this.setState(state => {
            return {
                ...state,
                name: event.target.value,
            }
        })
    }

    validateForm(state: IState):boolean {
        if(validateExists(state.name) !== "") {
            return false;
        }

        return true;
    }

    handleSubmit(event: React.MouseEvent) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state, allowValidation:true, waitingForSave:true}

        if(this.validateForm(newState)) {
            if(!this.props.match.params.id) {
                this.props.dispatch(submitSegmentAction(
                    newState.id,
                    newState.name,
                    [], 
                    () => {
                        const desiredPath = "/people/segments/edit/" + this.state.id
                        if(this.props.location.pathname !== desiredPath) {
                            this.props.history.replace(desiredPath)
                        }
                    }))
            } else {
                this.props.dispatch(submitSegmentNameUpdateAction(
                    newState.id,
                    newState.name)
                )
            }
        }

        this.setState(newState)
    }

    removeUserFromSegment(userId: string) {
        this.props.dispatch(submitRemoveUsesrsFromSegmentAction(this.state.id, [userId]));
    }

    addUserToSegment(userId: string) {
        this.props.dispatch(submitAddUsersToSegmentAction(this.state.id, [userId]));
    }


    truncateText(text: string, len = 40) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    render() {
        return (
            <>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles, ...this.props.style}}>
                    <Typography variant={"h1"} component={"div"} >
                        {this.props.match.params.id ? "Edit Segment" : "New Segment" }
                    </Typography>
                    <hr  />

                    <Grid container spacing={1} alignItems="flex-end" style={{marginBottom:"1rem"}}>
                        <Grid item>
                            <FilterList />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField label={"Name"}
                                       style={{minWidth:"300px", width:"100%"}}
                                       value={this.state.name}
                                       onChange={this.nameChange}
                                       error={this.state.allowValidation && validateExists(this.state.name)!==""}
                                       helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    startIcon={<SaveIcon />}
                                    onClick={this.handleSubmit}
                                    disabled={!!this.state.waitingForSave}
                                    style={{marginLeft:"10px"}}>
                                {this.props.match.params.id? "Rename" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>

                    {!this.props.match.params.id && <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                        You'll be able to add contacts to your segment after
                        entering a name and hitting the save button.  You can also 
                        filter contacts and then create a new segment <Link to={"/people/segments/new/filter"} style={{marginLeft:"2px"}}>here</Link>
                    </Typography>}
                </Card>


                
                {/* users in segment table */}
                {this.props.match.params.id && <Card style={defaultCardStyles} elevation={defaultCardElevation}>
                    <Typography variant="h1" component="p">
                        In segment
                    </Typography>
                    <hr />
                    <ListUsers 
                        key={this.props.usersInSegmentComponentKey}
                        buttons={{"remove":true}}
                        onUserRemove={this.removeUserFromSegment}
                        segmentIds={[this.props.match.params.id]} 
                        type={'list'} 
                        width={this.props.width}
                        hideAddButton={true}
                        dispatch={this.props.dispatch} />
                </Card>}

                {/* Users not in segment table */}
                {this.props.match.params.id &&<Card style={defaultCardStyles} elevation={defaultCardElevation}>
                    <Typography variant="h1" component="p">
                        Not in segment
                    </Typography>
                    <hr />
                    <ListUsers 
                        key={this.props.usersNotInSegmentComponentKey}
                        buttons={{"add":true}}
                        onUserAdd={this.addUserToSegment}
                        nonSegmentIds={[this.props.match.params.id]} 
                        type={'list'} 
                        width={this.props.width} 
                        hideAddButton={true}
                        dispatch={this.props.dispatch} />
                </Card>}
            </>
        )
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    //Find the segment with the id passed in via the route
    let fetchedSegment:Segment = null;
    if(state.segments && Object.keys(state.segments).length > 0) {
        if(state.segments[ownProps.match?.params?.id]) {
            fetchedSegment = state.segments[ownProps.match?.params?.id];
        }
    }

    return {...state, fetchedSegment}
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(EditSegmentComponent));

