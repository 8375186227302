import React from 'react';
import {connect} from "react-redux";
import ListWorkflows from "./ListWorkflows";
import {clearAllButStateData} from "./actions/Common";

interface IProps {
    width: string,
    dispatch: any,
}

class PageWorkflows extends React.Component<IProps, null> {
    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([
            ["workflows"],["receivingWorkflows"],["reportErrors"]]));
    }

    render() {
        return  <>
            <ListWorkflows type='list' {...this.props} />
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageWorkflows)

