import React, { ChangeEvent } from 'react';
import {connect} from "react-redux";
import {AllState} from "./reducers";
import {Card, Typography} from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import {defaultCardElevation, defaultCardStyles} from "./App";
import SendsByHour from "./reports/SendsByHour";
import SendsByDay from "./reports/SendsByDay";
import {clearAllButStateData} from "./actions/Common";
import ListEvents from './ListEvents';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IState {
    childIds: string[],
    campaignId?: string,
    deselectedColumns1: string[],
    deselectedColumns2: string[],
    columns?: string[],
}

interface IProps extends RouteComponentProps<{ ignorethis?: string  }>{
    dispatch: any,
    reportData?: {[key:string]:any[]},
    width: string,
    errors?: {[key:string]:any[]},
}

export const dashboardReportIds:string[] = [];
for(let i = 0; i < 20; i++) {
    dashboardReportIds.push(uuidv4());
}

class PageInsights extends React.Component<IProps, IState> {
    props: IProps;
    state: IState;


    constructor(props:IProps) {
        super(props);
        this.props = props;

        let campaignId = ""
        if(this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search)
            campaignId = urlParams.get("campaign_id") || ""
        }

        let deselectedColumns1:string[] = ["unsuccessful_sends", "complaints", "bounces","unsubscribes" ]
        let deselectedColumns2:string[] = ["unsuccessful_sends", "complaints", "bounces","unsubscribes" ]
        if(this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search)
            const s = urlParams.get("deselected_columns1")
            if(s) {
                deselectedColumns1 = s.split(",")
            }
            const s2 = urlParams.get("deselected_columns2")
            if(s2) {
                deselectedColumns2 = s2.split(",")
            }
        }
        
        this.state = {
            ...this.state,
            campaignId,
            deselectedColumns1,
            deselectedColumns2,
        }

        this.campaignChange = this.campaignChange.bind(this);
        this.columnSelectionChange1 = this.columnSelectionChange1.bind(this);
        this.columnSelectionChange2 = this.columnSelectionChange2.bind(this);
    }

    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([["reportData"], ["receivingReportData"], ["reportErrors"]]));
    }

    campaignChange(campaignId: string) {
        let queryString = ["campaign_id=" + encodeURIComponent(campaignId)]
        if(!campaignId || campaignId === "placeholder") {
            queryString = []
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        if(urlParams.get("deselected_columns1")) {
            queryString.push("deselected_columns1=" + urlParams.get("deselected_columns1"))
        }
        if(urlParams.get("deselected_columns2")) {
            queryString.push("deselected_columns2=" + urlParams.get("deselected_columns2"))
        }

        this.props.history.push(this.props.location.pathname + (queryString.length > 0 ? "?" + queryString.join("&") : ""))
        this.setState({
            ...this.state,
            campaignId,
        })
    }

    columnSelectionChange1(deselectedColumns: string[]) {
        let queryString = ["deselected_columns1=" + encodeURIComponent(deselectedColumns.join(","))]
        if(!deselectedColumns || deselectedColumns.length === 0) {
            queryString = ["deselected_columns1=" + "placeholder"]
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        if(urlParams.get("campaign_id")) {
            queryString.push("campaign_id=" + urlParams.get("campaign_id"))
        }
        if(urlParams.get("deselected_columns2")) {
            queryString.push("deselected_columns2=" + urlParams.get("deselected_columns2"))
        }
        
        this.props.history.push(this.props.location.pathname + (queryString.length > 0 ? "?" + queryString.join("&") : ""))
        this.setState({
            ...this.state,
            deselectedColumns1:deselectedColumns,
        })
    }

    columnSelectionChange2(deselectedColumns: string[]) {
        let queryString = ["deselected_columns2=" + encodeURIComponent(deselectedColumns.join(","))]
        if(!deselectedColumns || deselectedColumns.length === 0) {
            queryString = ["deselected_columns2=" + "placeholder"]
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        if(urlParams.get("campaign_id")) {
            queryString.push("campaign_id=" + urlParams.get("campaign_id"))
        }
        if(urlParams.get("deselected_columns1")) {
            queryString.push("deselected_columns1=" + urlParams.get("deselected_columns1"))
        }

        this.props.history.push(this.props.location.pathname + (queryString.length > 0 ? "?" + queryString.join("&") : ""))
        this.setState({
            ...this.state,
            deselectedColumns2:deselectedColumns,
        })
    }

    render() {
        return <>
            <Card key={"sendsbydaycard"} elevation={defaultCardElevation} style={{ 
                    ...defaultCardStyles,
                    overflow:"visible" ,
                }}>
                <Typography variant={"h1"} component={"div"}>Insights</Typography>
                <hr />
                <SendsByDay 
                    key={"30dayskey" + this.state.campaignId}
                    onDeselectedColumnChange={this.columnSelectionChange1}
                    onCampaignChange={this.campaignChange}
                    errors={this.props.errors} 
                    selectedCampaignId={this.state.campaignId}
                    reportData={this.props.reportData} 
                    id={dashboardReportIds[1]}  
                    dispatch={this.props.dispatch}
                    initiallyDeselectedColumns={this.state.deselectedColumns1} />
            </Card>
            <Card key={"sendsbyhourcard"} elevation={defaultCardElevation} style={{ ...defaultCardStyles ,overflow:"visible", paddingTop:"1rem" }} >
                <SendsByHour 
                    key={"24hrsends" + this.state.campaignId}
                    onDeselectedColumnChange={this.columnSelectionChange2}
                    onCampaignChange={this.campaignChange}
                    errors={this.props.errors} 
                    campaignId={this.state.campaignId}
                    reportData={this.props.reportData} 
                    id={dashboardReportIds[2]} 
                    dispatch={this.props.dispatch}
                    initiallyDeselectedColumns={this.state.deselectedColumns2} />
            </Card>
            <ListEvents key={"listevents"} buttons={{ "viewemail": true, "zoom":true,  }} 
                dispatch={this.props.dispatch} 
                width={this.props.width}
                title={"Recent emails"}
                searchType={"name"} 
                search={"email_send_result"} 
                includeEvents={false}
                includeSystemEvents={true}
                type={'email-list'} />
        </>
    }
}



function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {
        reportData: { ...state.reportData }, //Create copy of report data
        errors: { ...state.reportErrors }, //Create copy of error data
    }
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageInsights))

