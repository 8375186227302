import React from 'react';
import { connect } from "react-redux";
import {fetchNotifications, REQUEST_NOTIFICATIONS} from "./actions/Notifications";
import {Loading} from "./components/Loading";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography, Card} from "@material-ui/core";
import { Close, Check} from "@material-ui/icons";
import { defaultCardElevation, defaultCardStyles } from './App';
import { JobLogEntry } from './generated/types/payloadTypes';

const NOTIFICATIONS_TABLE = "NOTIFICATIONS_TABLE";

interface IProps {
    dispatch:any,
    notifications?: any[],
    width: string,
    receivingNotifications?: boolean,
    reportErrors?: {[key:string]:string},
}

const JobNameMap: any = {
    "JOB_DISTRIBUTE_BULK_UPLOAD_PROCESSING_JOB" : "Parsed contact file",
    "JOB_CREATE_WORKFLOW": "Created workflow",
    "JOB_DELETE_WORKFLOW": "Archived workflow",
    "JOB_PROCESS_SCHEDULED_EMAIL_PART": "Processed partial email blast",
    "JOB_DISTRIBUTE_SCHEDULED_EMAIL_PART": "Distributed partial email blast",
};

class PageNotifications extends React.Component<IProps, null>  {
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.refreshButtonClicked = this.refreshButtonClicked.bind(this);
    }

    componentDidMount(){
        this.props.dispatch(fetchNotifications(1000));
    }

    refreshButtonClicked(event:React.MouseEvent) {
        event.preventDefault();
        this.props.dispatch(fetchNotifications(1000));
    }

    getErrors(allJSON:any) : string {
        const errors:string[] = [];
        Object.keys(allJSON).map(k => {
            if(k.toLowerCase().indexOf("error") !== -1) {
                if(Object.keys(allJSON[k]).length > 0 && JSON.stringify(allJSON[k]) == "[{}]") {
                    return;
                }
                if(Object.keys(allJSON[k]).length > 0 && JSON.stringify(allJSON[k]) != "[{}]") {
                    errors.push(JSON.stringify(allJSON[k]));
                } else {
                    errors.push(allJSON[k]);
                }
            }
        })

        return errors.join(", ")
    }

    truncateText(text: string, len = 60) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    render() {
        return (  <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"div"}>Notifications</Typography>
                    <hr />
                    <Typography variant={"body1"} style={{marginBottom:"20px"}}>
                        This page displays information on behind-the-scenes activities.  It's here to provide transparency
                        into things that you might not otherwise be able to see: processing of csv uploads, creation of workflows, etc.
                        Most users will not need to use this page.
                    </Typography>
                    {(!this.props.reportErrors[REQUEST_NOTIFICATIONS] && (!this.props.notifications || this.props.notifications.length === 0)  && this.props.receivingNotifications) && <Loading />}
                    {this.props.reportErrors[REQUEST_NOTIFICATIONS] && <p className={"error-text"}>
                        Unable to load notifications: {this.props.reportErrors[REQUEST_NOTIFICATIONS]}
                    </p>}
                    {((this.props.notifications && this.props.notifications.length > 0) || !this.props.receivingNotifications) && <TableContainer><Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell style={{minWidth:"150px"}}>Activity</TableCell>
                                <TableCell style={{minWidth:"200px"}}>Start/End UTC</TableCell>
                                <TableCell>Errors</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {((this.props.notifications && this.props.notifications.length > 0) || !this.props.receivingNotifications) && this.props.notifications && this.props.notifications.map((notification:JobLogEntry) => {
                            const combinedJSON = {
                                ...notification.start_details,
                                ...notification.end_details,
                            }
                            const startDate = new Date(notification.started_at);
                            const endDate = new Date(notification.ended_at);
                            return <TableRow key={notification.id}>
                                <TableCell>
                                    {!notification.ended_at ? 
                                        <Loading mySize={"sm"} hideText={true}/> : 
                                            notification.success ? <Check/> : <Close style={{color:"red"}}/>}
                                </TableCell>
                                <TableCell>
                                    {JobNameMap[notification.job_name] ? JobNameMap[notification.job_name] : notification.job_name}<br />
                                    <i>
                                    {notification.job_name === "JOB_DISTRIBUTE_BULK_UPLOAD_PROCESSING_JOB" ? 
                                        <span title={notification.start_details.filename}>{this.truncateText(notification.start_details.filename)}</span> 
                                        : <></>}
                                    {notification.start_details && notification.start_details.workflow_payload 
                                        && (notification.job_name === "JOB_CREATE_WORKFLOW" || notification.job_name === "JOB_DELETE_WORKFLOW")?
                                        <span title={JSON.parse(notification.start_details.workflow_payload).name}>
                                            {this.truncateText(JSON.parse(notification.start_details.workflow_payload).name)}
                                        </span> : <></>}
                                    {notification.job_name === "JOB_PROCESS_SCHEDULED_EMAIL_PART"
                                        || notification.job_name === "JOB_DISTRIBUTE_SCHEDULED_EMAIL_PART" ? 
                                            "Timezone " + (notification.start_details.timezone || "UTC") : ""}
                                    </i>
                                </TableCell>
                                <TableCell>
                                    {startDate.toLocaleDateString()} {startDate.toLocaleTimeString()}<br />
                                    {notification.ended_at ? endDate.toLocaleDateString() + " " + endDate.toLocaleTimeString() : "(in progress, or failed w/o error)"}
                                </TableCell>
                                <TableCell>
                                    {this.getErrors(combinedJSON)}
                                </TableCell>
                            </TableRow>
                        })}
                        </TableBody>
                    </Table></TableContainer>
                    }
                </Card>
        )
    }
}



function mapStateToProps(state:any, ownProps:IProps):any {
    return {
        ...state,
    }
 }

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageNotifications)
