import React from "react";
import {AllState} from "../reducers";
import {connect} from "react-redux";
import {
Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withWidth
} from "@material-ui/core";
import { Link } from "react-router-dom"
import { fetchRecentlyClickedLinksReportDataAction } from "../actions/Reports";
import { Loading } from "../components/Loading";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment-timezone"

interface IProps {
    dispatch: any,
    reportData?: {[key:string]:any[]},
    receivingReportData?: {[key:string]:boolean},
    campaignId?: string,
}
interface IState {
    id:string,
}

class RecentClickStats extends React.Component<IProps, IState> {
    props:IProps;
    state:IState;

    constructor(props:IProps) {
        super(props)
        this.props = props;
        this.state = {
            id: uuidv4(),
        }
    }

    componentDidMount(){
        this.props.dispatch(fetchRecentlyClickedLinksReportDataAction(this.state.id, this.props.campaignId, 5, 0))
    }
    
    render() {
        const myTimezone = moment.tz.guess()
        return <div>
            <Typography component="div" variant="h2" style={{marginBottom:"0"}}>
                Recently clicked links
            </Typography>
            {(!this.props.receivingReportData[this.state.id] && !this.props.reportData[this.state.id]) ? <>
                <div style={{marginTop:"1rem"}}>No links have been clicked recently for the given filters</div>  
            </> : <>
                {this.props.receivingReportData[this.state.id] ? <Loading /> :
            <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1">
                            Link
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1">
                            Email
                            </Typography>
                        </TableCell>
                        <TableCell>
                            Clicked at
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.reportData[this.state.id] && this.props.reportData[this.state.id].map((clickedLinkData, i) => <TableRow key={"row_" + i}>
                        <TableCell style={{maxWidth: "260px", wordBreak:"break-word"}}><Typography variant="body1"><a href={clickedLinkData.link_url} target="_blank" rel="noreferrer">{clickedLinkData.link_url}</a></Typography></TableCell>  
                        <TableCell><Typography variant="body1">
                            { clickedLinkData.user_id ? <Link target="_blank" to={"/people/users/edit/" + clickedLinkData.user_id}>
                                {clickedLinkData.email}
                            </Link> : <>{clickedLinkData.email}</> } 
                        </Typography></TableCell>
                        <TableCell style={{minWidth:"190px"}}><Typography variant="body1">
                            { clickedLinkData.emitted_at ? <>{new Date(clickedLinkData.emitted_at).toLocaleString()}{myTimezone ? <><br />TZ: {myTimezone}</>:<></>}</> : <></> }
                        </Typography></TableCell>  
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
            }</>}
        </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state;
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(withWidth()(RecentClickStats))
