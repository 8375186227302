import React from 'react';
import {connect} from "react-redux";
import {AllState} from "./reducers";
import {Card, TableCell, TableRow, Typography} from "@material-ui/core";
import {defaultCardElevation, defaultCardStyles} from "./App";
import SendsByDay from "./reports/SendsByDay";
import {Link,  RouteComponentProps, withRouter} from 'react-router-dom'
import {clearAllButStateData} from "./actions/Common";
import {SystemEvent} from "./generated/types/payloadTypes"
import EventDetailsModal from './components/EventDetailsModal';

const troubleReasonsId = "TROUBLE_REASONS_REPORT_TABLE"

interface IState {
    anchorEl?: any,
    detailedEvent?: SystemEvent,
    rawEventDetails: boolean,
    copiedRawDetails: boolean,
    deselectedColumns1: string[],
    campaignId: string,
}

interface IProps extends RouteComponentProps<{ ignorethis?: string  }> {
    match:any,
    dispatch: any,
    reportData?: {[key:string]:any[]},
    width: string,
    errors?: {[key:string]:any[]},
    troubleReasonsId?: string,
}

class PageIssues extends React.Component<IProps, IState> {
    props: IProps;
    state: IState;

    constructor(props:IProps) {
        super(props);
        this.props = props;

        let campaignId = ""
        if(this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search)
            campaignId = urlParams.get("campaign_id")
        }

        let deselectedColumns1:string[] = ["placeholder" ]
        if(this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search)
            const s = urlParams.get("deselected_columns1")
            if(s) {
                deselectedColumns1 = s.split(",")
            }
        }
        

        this.state = {
            detailedEvent: null,
            rawEventDetails: false,
            copiedRawDetails: false,
            campaignId,
            deselectedColumns1,
        }

        
        this.campaignChange = this.campaignChange.bind(this);
        this.columnSelectionChange1 = this.columnSelectionChange1.bind(this);
    }

    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([["reportData"], ["receivingReportData"], ["reportErrors"]]));
    }

    getWidth() {
        return 400;
    }

    campaignChange(campaignId: string) {
        let queryString = ["campaign_id=" + encodeURIComponent(campaignId)]
        if(!campaignId || campaignId === "placeholder") {
            queryString = []
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        if(urlParams.get("deselected_columns1")) {
            queryString.push("deselected_columns1=" + urlParams.get("deselected_columns1"))
        }

        this.props.history.push(this.props.location.pathname + (queryString.length > 0 ? "?" + queryString.join("&") : ""))
        this.setState({
            ...this.state,
            campaignId,
        })
    }


    columnSelectionChange1(deselectedColumns: string[]) {
        let queryString = ["deselected_columns1=" + encodeURIComponent(deselectedColumns.join(","))]
        if(!deselectedColumns || deselectedColumns.length === 0) {
            queryString = ["deselected_columns1=" + "placeholder"]
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        if(urlParams.get("campaign_id")) {
            queryString.push("campaign_id=" + urlParams.get("campaign_id"))
        }
        
        this.props.history.push(this.props.location.pathname + (queryString.length > 0 ? "?" + queryString.join("&") : ""))
        this.setState({
            ...this.state,
            deselectedColumns1:deselectedColumns,
        })
    }


    render() {
        return <div>
             {!!this.state.detailedEvent && <EventDetailsModal 
                dispatch={this.props.dispatch} 
                detailedEvent={this.state.detailedEvent}  
                okButtonHook={(event:any) => {
                    this.setState({
                        ...this.state,
                        detailedEvent: null,
                    })
                }}
            />  }
            <Card elevation={defaultCardElevation} style={{ 
                    ...defaultCardStyles,
                    overflow:"visible" 
                }}>
                <Typography variant={"h1"} component={"div"}>Insights: Issues</Typography>
                <hr />
                <SendsByDay
                    onDeselectedColumnChange={this.columnSelectionChange1}
                    onCampaignChange={this.campaignChange} 
                    initiallyDeselectedColumns={this.state.deselectedColumns1}
                    selectedCampaignId={this.state.campaignId}
                    errors={this.props.errors} 
                    reportData={this.props.reportData} 
                    id={"troubleByDay"}  
                    title={"Issues in last month"}
                    eventsToTrack={["unsuccessful_sends", "complaints", "bounces", "unsubscribes"]}
                    dispatch={this.props.dispatch} />
            </Card>
        </div>
    }
}



function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {
        reportData: { ...state.reportData }, //Create copy of report data
        errors: { ...state.reportErrors }, //Create copy of error data
    }
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageIssues))

