import { TextField,Card, Typography, Button, Radio, FormControlLabel, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow, debounce } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { defaultCardElevation, defaultCardStyles } from './App';
import { Campaign, JobLogEntry, ScheduledEmailGroup, ScheduledEmailGroupWithMetrics, SenderPersona, Template, Workflow, Segment, WeightedTemplateID } from './generated/types/payloadTypes';
import {  Check, CheckCircle, Error, HourglassEmpty, Save, TimerSharp } from '@material-ui/icons';
import {v4 as uuidv4} from "uuid";
import { AllState } from './reducers';
import ScheduledEmailBlastCampaign from "./wizards/ScheduledBlastCampaign"
import { MyCancelButton, MyModal, MyOkButton } from './Dialog';
import { submitEmailBlastAction } from './actions/EmailBlasts';
import { fetchCampaigns, submitCampaignAction } from './actions/Campaigns';
import { Loading } from './components/Loading';
import { Link } from 'react-router-dom';
import WorkflowEventTriggerCampaign from './wizards/WorkflowEventTriggerCampaign';
import WorkflowDripCampaign from './wizards/WorkflowDripCampaign';
import { submitRunSegmentThroughWorkflowAction, submitWorkflowAction } from './actions/Workflows';
import { GetBasicWorkflowFields, GenerateWorkflow } from './wizards/components/WorkflowFields';
import { fetchNotifications } from './actions/Notifications';
import ABTestCampaign from './wizards/ABTestCampaign';
import { usersLocalTimezone } from './workflow/NodeSettings';
import moment from 'moment';
import { IChartToNode } from './workflow/Serializer';
import { reduceArraysToLength } from './Common';


interface IProps {
    width: string,
    dispatch: any,
    receivingCampaigns?: boolean,
    campaigns?: {[key:string]:Campaign},
    senderPersonas?: {[key:string]:SenderPersona},
    segments?: {[key:string]:Segment},
    templates?: {[key:string]:Template},
    workflows?: {[key:string]:Workflow},
    emailBlasts?: {[key:string]:ScheduledEmailGroupWithMetrics}
    notifications?: {[key:string]:JobLogEntry}
    
}
interface IState {
    pageNum: number,
    campaign: Campaign,
    senderPersona: SenderPersona,
    selectedSenderPersonas: SenderPersona[],
    scheduledEmailGroup: ScheduledEmailGroup,
    workflow: Workflow,
    workflowRootNodeId: string,
    templateId: string,
    selectedTemplateIds: string[],
    selectedTemplateWeights: number[],
    isNewSenderPersona: boolean,
    isNewTemplate: boolean,
    isNewSenderPersonas: boolean[],
    isNewTemplates: boolean[],
    toType: "segments"|"users"|"segments_and_users",
    sendAtType: "specific_timezone" | "users_timezone",
    selectedSendAtTypes: ("time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay")[],
    selectedSendAtDelaySeconds: number[],
    sendAtMoment: moment.Moment,
    selectedSendAtMoments: moment.Moment[],
    sendAtTimezone: string,
    selectedSendAtTimezones: string[],
    selectedSegmentIds:{[key:string]:boolean},
    selectedUserIds:{[key:string]:boolean},
    confirmingLaunch:boolean,
    executingLaunch: boolean,
    launchSteps: LaunchStep[],
    launchModalKey: string,
    eventName?:string,
    numEmails: number,
    nextButtonEnabled: boolean,
}

interface LaunchStep {
    name: string,
    status: "waiting"|"in_progress"|"succeeded"|"failed"|"cancelled",
    error?: string,
    results?: any,
    execute: () => any,
}

const defaultValue = "scheduled_email_blast"
let workflowCheckerIntervalId:NodeJS.Timeout = null
let numChecks = 0
const maxChecks = 100
const minReviewCol1Width = "130px"

function getCampaignsWithName(dispatch: any, name:string) {
    dispatch(fetchCampaigns(null, [name]))
}
const debouncedFetchCampaignsWithName = debounce(getCampaignsWithName, 500);

class PageCampaignsMock extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;

        const nowLocalButUTC = moment(new Date()).tz(moment.tz.guess()).tz("UTC", true)  //Creating two of these so we don't alter the same moment object
        const nowLocalButUTC2 = moment(new Date()).tz(moment.tz.guess()).tz("UTC", true) //Creating two of these so we don't alter the same moment object
        const newPersona = new SenderPersona()
        this.state = {
            selectedTemplateWeights: [1],
            campaign: new Campaign(),
            senderPersona: newPersona,
            selectedSenderPersonas: [newPersona],
            scheduledEmailGroup: new ScheduledEmailGroup(),
            workflow: new Workflow(),
            workflowRootNodeId: uuidv4(),
            templateId: "",
            selectedTemplateIds: [""],
            isNewSenderPersona: false,
            isNewSenderPersonas: [false],
            isNewTemplate: false,
            isNewTemplates: [false],
            pageNum: 1,
            selectedSegmentIds: {},
            selectedUserIds: {},
            toType: "segments",
            sendAtType: "users_timezone",
            selectedSendAtTypes: ["delay"],
            sendAtMoment: nowLocalButUTC,
            selectedSendAtMoments: [nowLocalButUTC2],
            selectedSendAtDelaySeconds: [1],
            sendAtTimezone: "America/New_York",
            selectedSendAtTimezones: ["America/New_York"],
            confirmingLaunch: false,
            executingLaunch: false,
            launchSteps: [],
            launchModalKey: uuidv4(),
            numEmails: 1,
            nextButtonEnabled: true,
        }
        this.state.campaign.id = uuidv4();
        this.state.scheduledEmailGroup.id = uuidv4();
        this.state.workflow.id = uuidv4();

        this.state.campaign.type = defaultValue
        this.handleCampaignTypeChange = this.handleCampaignTypeChange.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onSegmentsChange = this.onSegmentsChange.bind(this)
        this.onSenderPersonaChange = this.onSenderPersonaChange.bind(this)
        this.onTemplateChange = this.onTemplateChange.bind(this)
        this.onSendAtChange = this.onSendAtChange.bind(this)
        this.onUsersChange = this.onUsersChange.bind(this)
        this.onToTypeChange = this.onToTypeChange.bind(this)
        this.onNumEmailsChange = this.onNumEmailsChange.bind(this)
        this.onSelectedTemplatWeightsChange = this.onSelectedTemplatWeightsChange.bind(this)
    }

    onSelectedTemplatWeightsChange(selectedTemplateWeights: number[]) {
        this.setState({
            ...this.state,
            selectedTemplateWeights,
        })
    }

    onNumEmailsChange(numEmails:number) {
        if(numEmails < 1) {
            numEmails = 1
        }

        const selectedSenderPersonas = [...this.state.selectedSenderPersonas]
        const isNewSenderPersonas = [...this.state.isNewSenderPersonas]
        if(numEmails > selectedSenderPersonas.length) {
            for(let i = 0; i < numEmails - selectedSenderPersonas.length; i++) {
                const newPersona = new SenderPersona()
                selectedSenderPersonas.push(newPersona)

                isNewSenderPersonas.push(false)
            }
        }

        const selectedTemplateIds = [...this.state.selectedTemplateIds]
        const isNewTemplates = [...this.state.isNewTemplates]
        const selectedTemplateWeights = [...this.state.selectedTemplateWeights]
        if(numEmails > selectedTemplateIds.length) { 
            for(let i = 0; i < numEmails - selectedTemplateIds.length; i++) {
                selectedTemplateIds.push("")
                isNewTemplates.push(false)
                selectedTemplateWeights.push(1)
            }
        }

        const selectedSendAtDelaySeconds = [...this.state.selectedSendAtDelaySeconds]
        const selectedSendAtMoments = [...this.state.selectedSendAtMoments]
        const selectedSendAtTypes = [...this.state.selectedSendAtTypes]
        const selectedSendAtTimezones = [...this.state.selectedSendAtTimezones]
        const nowLocalButUTC = moment(new Date()).tz(moment.tz.guess()).tz("UTC", true) 
        if(numEmails > selectedSendAtDelaySeconds.length) { 
            for(let i = 0; i < numEmails - selectedSendAtDelaySeconds.length; i++) {
                selectedSendAtDelaySeconds.push(1)
                selectedSendAtMoments.push(nowLocalButUTC)
                selectedSendAtTypes.push("delay")
                selectedSendAtTimezones.push("America/New_York")
            }
        }

        this.setState({
            ...this.state, 
            numEmails,
            selectedSenderPersonas,
            isNewSenderPersonas,
            selectedTemplateIds,
            isNewTemplates,
            selectedSendAtDelaySeconds,
            selectedSendAtMoments,
            selectedSendAtTimezones,
            selectedSendAtTypes,
            selectedTemplateWeights,
        })
    }

    handleCampaignTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault()
        event.stopPropagation()

        const type = event.target?.value?.toString()
        if(event.target?.value) {
            this.setState({
                ...this.state,
                campaign: {
                    ...this.state.campaign,
                    type,
                },
            })
        }
    }

    foundCreatedSenderPersona():boolean {
        if(!this.props.senderPersonas || Object.keys(this.props.senderPersonas).length == 0) {
            return false
        }

        return !!Object.keys(this.props.senderPersonas).find((id:string) => id === this.state.senderPersona.id)
    }
 
    onPageChange(pageNum: number) {
        this.setState({...this.state, pageNum})
    }

    notificationIndicatesFinishedWorkflow(notification: JobLogEntry, workflowId: string): boolean {
        return (
           notification.job_name === "JOB_CREATE_WORKFLOW"
        && notification.ended_at 
        && notification.start_details 
        && notification.start_details?.workflow_id === workflowId)
    }

    startWorkflowCreatedChecker(workflowId:string, onSuccess:() => any, onFailure:(rason:string) => any) {
        if(workflowCheckerIntervalId) {
            console.log("clearing previous workflow checker")
            clearInterval(workflowCheckerIntervalId)
            workflowCheckerIntervalId = null
        }

        console.log("checking for workflow " + workflowId)
        workflowCheckerIntervalId = setInterval(() => {
            if(numChecks > maxChecks) {
                clearInterval(workflowCheckerIntervalId)
                workflowCheckerIntervalId = null
                numChecks = 0
                onFailure("")
                return
            }

            if(this.state.executingLaunch) {
                const notificationKeys = Object.keys(this.props.notifications)
                for(let i = 0; i < notificationKeys.length; i++) {
                    if(this.notificationIndicatesFinishedWorkflow(this.props.notifications[notificationKeys[i]], workflowId)) {
                        clearInterval(workflowCheckerIntervalId)
                        workflowCheckerIntervalId = null
                        numChecks = 0
                        onSuccess()
                        return
                    }
                }

                this.props.dispatch(fetchNotifications(100))
            } else {
                clearInterval(workflowCheckerIntervalId)
                workflowCheckerIntervalId = null
                numChecks = 0
                return
            }

            numChecks++
        }, 2000)
    }

    isNextEnabled() {
        if(this.state.pageNum <= 1) {
            return true 
        }
        if(this.state.pageNum === 2 && this.state.campaign && this.state.campaign.name && this.state.campaign.name.length > 0) {
            // Don't allow duplicate campaign names
            if(this.props.campaigns && Object.values(this.props.campaigns).find(c => c.name === this.state.campaign?.name)) {
                return false
            }

            return true
        }

        return false
    }


    getButtons() : JSX.Element { 
            if(this.readyForSuccessPage()) {
                return <></>
            }

            const isNextDisabled = !this.isNextEnabled()
            const prevButton = <Button color="primary" variant="contained" disabled={this.state.pageNum === 1} onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.setState({...this.state, pageNum:this.state.pageNum-1})}} style={{marginRight:"1rem"}}>&lt;&nbsp;&nbsp; Campaign Setup</Button>
            const nextButton = <Button disabled={isNextDisabled} color="primary" variant="contained" onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.setState({...this.state, pageNum:this.state.pageNum+1})}} style={{marginRight:"1rem"}}>Campaign Setup &nbsp;&nbsp;&gt;</Button>
            const reviewAndLaunchButton = <Button color="primary" variant="contained" style={{marginRight:"1rem"}}
                onClick={(e) => { 
                    e.stopPropagation(); 
                    e.preventDefault();

                    this.setState({
                        ...this.state,
                        executingLaunch: false, 
                        confirmingLaunch: true,
                        launchSteps: this.getLaunchSteps(() => {
                            this.setState({...this.state, executingLaunch: false, confirmingLaunch:false, pageNum: this.state.pageNum+1 })
                        }, () => {
                            this.setState({...this.state, executingLaunch: false, launchModalKey: uuidv4() })
                        }),
                    })
                    
                    return

                }}>Launch campaign</Button>

            const buttons = [prevButton]
            if(!this.readyForReviewAndLaunchPage()) {
                buttons.push(nextButton)
            } else {
                buttons.push(reviewAndLaunchButton)
            }

            return <>{buttons}</>
    }

    campaignTypeDisplay(campaignType: string):string {
        const stringMap:{[key:string]:string} = {
            [defaultValue]: "scheduled email blast",
            "event_trigger_campaign": "drip campaign",
            "drip_campaign": "drip campaign",
            "event_triggered_drip_campaign": "event-triggered drip campaign",
            "a_b_test": "A/B test",
        }

        if(stringMap[campaignType]) {
            return stringMap[campaignType]
        }

        console.error("unrecognized campaign type " + campaignType + " in display-string code")
        return campaignType
    }

    onSegmentsChange(selectedSegmentIds:{[key:string]:boolean}) {
        this.setState({
            ...this.state,
            selectedSegmentIds,
        })
    }
    onSenderPersonaChange(senderPersona: SenderPersona,  isNewSenderPersona: boolean) {
        this.setState({
            ...this.state,
            senderPersona,
            isNewSenderPersona,
        })
    }

    onTemplateChange(template:Template, isNewTemplate: boolean) {
        const templateId = template.id 
        this.setState({
            ...this.state,
            templateId,
            isNewTemplate,
        })
    
    }
    onSenderPersonasChange(selectedSenderPersonas: SenderPersona[],  isNewSenderPersonas: boolean[]) {
        for(let i = 0; i < isNewSenderPersonas.length; i++) {
            if(isNewSenderPersonas[i]) {
                selectedSenderPersonas[i] = new SenderPersona()
            }
        }

        this.setState({
            ...this.state,
            selectedSenderPersonas,
            isNewSenderPersonas,
        })
    }
    onTemplatesChange(templates:Template[], isNewTemplates: boolean[]) {
        this.setState({
            ...this.state,
            selectedTemplateIds: templates.map(t => t.id),
            isNewTemplates,
        })
    
    }
    onSendAtChange(sendAtMoment: moment.Moment, sendAtType:"specific_timezone"|"users_timezone", sendAtTimezone: string) {
        this.setState({
            ...this.state,
            sendAtMoment,
            sendAtType,
            sendAtTimezone,
        })
    }
    onToTypeChange(toType: "segments"|"users"|"segments_and_users") {
        //Don't send to users if we had some selected and then went back and change to-type to not include them
        let selectedUserIds = this.state.selectedUserIds
        if(toType === "segments") {
            selectedUserIds = {} 
        }
        //Don't send to segments if we had some selected and then went back and change to-type to not include them
        let selectedSegmentIds = this.state.selectedSegmentIds
        if(toType === "users") {
            selectedSegmentIds = {} 
        }

        this.setState({
            ...this.state,
            selectedUserIds,
            selectedSegmentIds,
            toType,
        })
    }
    onUsersChange(selectedUserIds:{[key:string]:boolean}) {
        this.setState({
            ...this.state, 
            selectedUserIds,
        })
    }

    readyForReviewAndLaunchPage(): boolean {
        switch(this.state.campaign?.type) {
            case defaultValue: //scheduled_email_blast
                return this.state.pageNum === 7 + (this.state.toType === "segments_and_users" ? 2 : 1)
            case "event_trigger_campaign": 
                return this.state.pageNum === 6
            case "drip_campaign": 
            case "event_triggered_drip_campaign": 
                return this.state.pageNum === 5 + this.state.numEmails * 3
            case "a_b_test": 
                if(this.state.toType === "users" || this.state.toType === "segments") {
                    return this.state.pageNum ===9 + this.state.numEmails
                }
                return this.state.pageNum ===10 + this.state.numEmails
        }

        return false
    }

    readyForSuccessPage(): boolean {
        switch(this.state.campaign?.type) {
            case defaultValue: //scheduled_email_blast
                return this.state.pageNum === 8 + (this.state.toType === "segments_and_users" ? 2 : 1)
            case "event_trigger_campaign": 
                return this.state.pageNum === 7
            case "drip_campaign":
            case "event_triggered_drip_campaign": 
                return this.state.pageNum === 5 + this.state.numEmails * 3 + 1
            case "a_b_test": 
            if(this.state.toType === "users" || this.state.toType === "segments") {
                return this.state.pageNum ===10 + this.state.numEmails
            }
            return this.state.pageNum ===11 + this.state.numEmails
        }

        return false
    }

    translateCampaignType(campaignType: string): string {
        const map:{[key:string]:string} = { 
            "event_trigger_campaign": "workflow",
            "drip_campaign": "workflow",
            "event_triggered_drip_campaign": "workflow",
            "a_b_test": "template",
            [defaultValue]: "scheduled_email_group",
        }

        return map[campaignType] ?? ""
    }

    getLaunchSteps(onSuccessfulFinish?: () => any, onFailureFinish?: () => any): LaunchStep[] {
        const steps:LaunchStep[] = []

        switch(this.state.campaign?.type) {
            case defaultValue: //"scheduled_email_blast"
                //Campaign
                steps.push({
                    execute: () => {
                        // if(!this.state.scheduledEmailGroup.id) {
                        //     steps[0].status = "failed"
                        //     steps[0].error = "missing associated scheduled email group id"
                        //     steps[1].status = "cancelled"
                        //     if(onFailureFinish) {
                        //         onFailureFinish()
                        //     }
                        //     return
                        // }
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [this.state.scheduledEmailGroup.id], null, null,
                            (c: Campaign) => {
                                steps[0].status = "succeeded"
                                steps[0].results = {
                                    campaign: c,
                                }
                                steps[1].status = "in_progress"
                                steps[1].execute()
                            }, (reason: string) => {
                                steps[0].status = "failed"
                                steps[0].error = reason
                                steps[1].status = "cancelled"
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Create base campaign",
                    status:"waiting",
                })
                // Create the email blast
                steps.push({
                    execute: () => {
                        let sendAtTimezone = this.state.sendAtTimezone
                        if(this.state.sendAtType === "users_timezone") {
                            sendAtTimezone = usersLocalTimezone
                        }

                        const emailBlast = this.state.scheduledEmailGroup
                        emailBlast.name = this.state.campaign?.name;
                        emailBlast.send_at = this.state.sendAtMoment
                        emailBlast.payload = {
                            template_ids_with_weights: [],
                            to_user_ids: Object.keys(this.state.selectedUserIds),
                            template_id: this.state.templateId,
                            to_segment_ids: Object.keys(this.state.selectedSegmentIds),
                            timezone: sendAtTimezone,
                            sender_persona_id: this.state.senderPersona?.id,
                            bcc_to_user_ids: [],
                            cc_to_user_ids: [],
                            to_user_emails: [],
                        };
                        this.props.dispatch(submitEmailBlastAction(emailBlast.id, emailBlast.name, emailBlast.payload.template_id, [],
                            emailBlast.payload.sender_persona_id, emailBlast.payload.to_segment_ids, emailBlast.payload.to_user_ids,
                            emailBlast.send_at, sendAtTimezone,
                            (seg: ScheduledEmailGroup) => {
                                steps[1].status = "succeeded"
                                steps[1].results = {
                                    scheduledEmailGroup: seg,
                                }
                                if(onSuccessfulFinish) {
                                    onSuccessfulFinish()
                                }
                            }, (reason: string) => {
                                steps[1].status = "failed"
                                steps[1].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }));
                    },
                    name: 'Schedule the associated email blast',
                    status: 'waiting',
                })
            break
            case "a_b_test":
                //Campaign
                steps.push({
                    execute: () => {
                        // if(!this.state.scheduledEmailGroup.id) {
                        //     steps[0].status = "failed"
                        //     steps[0].error = "missing associated scheduled email group id"
                        //     steps[1].status = "cancelled"
                        //     if(onFailureFinish) {
                        //         onFailureFinish()
                        //     }
                        //     return
                        // }
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [this.state.scheduledEmailGroup.id], null, this.state.selectedTemplateIds,
                            (c: Campaign) => {
                                steps[0].status = "succeeded"
                                steps[0].results = {
                                    campaign: c,
                                }
                                steps[1].status = "in_progress"
                                steps[1].execute()
                            }, (reason: string) => {
                                steps[0].status = "failed"
                                steps[0].error = reason
                                steps[1].status = "cancelled"
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Create base campaign",
                    status:"waiting",
                })
                // Create the email blast
                steps.push({
                    execute: () => {
                        if(this.state.selectedTemplateIds.length === 0) {
                            return
                        }

                        const idsWithWeights:WeightedTemplateID[] = []
                        for(let i = 0; i < this.state.numEmails; i++) {
                            idsWithWeights.push({
                                template_id: this.state.selectedTemplateIds[i],
                                weight: this.state.selectedTemplateWeights[i],
                            })
                        }

                        let sendAtTimezone = this.state.sendAtTimezone
                        if(this.state.sendAtType === "users_timezone") {
                            sendAtTimezone = usersLocalTimezone
                        }

                        const emailBlast = this.state.scheduledEmailGroup
                        emailBlast.name = this.state.campaign?.name;
                        emailBlast.send_at = this.state.sendAtMoment
                        emailBlast.payload = {
                            template_ids_with_weights:idsWithWeights,
                            to_user_ids: Object.keys(this.state.selectedUserIds),
                            template_id: this.state.selectedTemplateIds[0],
                            to_segment_ids: Object.keys(this.state.selectedSegmentIds),
                            timezone: sendAtTimezone,
                            sender_persona_id: this.state.senderPersona?.id,
                            bcc_to_user_ids: [],
                            cc_to_user_ids: [],
                            to_user_emails: [],
                        };
                        this.props.dispatch(submitEmailBlastAction(emailBlast.id, emailBlast.name, emailBlast.payload.template_id,
                            emailBlast.payload.template_ids_with_weights,
                            emailBlast.payload.sender_persona_id, emailBlast.payload.to_segment_ids, emailBlast.payload.to_user_ids,
                            emailBlast.send_at, sendAtTimezone,
                            (seg: ScheduledEmailGroup) => {
                                steps[1].status = "succeeded"
                                steps[1].results = {
                                    scheduledEmailGroup: seg,
                                }
                                if(onSuccessfulFinish) {
                                    onSuccessfulFinish()
                                }
                            }, (reason: string) => {
                                steps[1].status = "failed"
                                steps[1].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }));
                    },
                    name: 'Schedule the associated email blast',
                    status: 'waiting',
                })
            break
            case "event_trigger_campaign":
                //Create Campaign
                //For some reason this step only creates the campaign and refuses to associate
                //the campaign with the workflow ahead of time.  It makes no sense b/c there are no foreign keys
                //and no errors are being thrown.  However, just reposting the campaign after the workflow creation seems to work
                steps.push({
                    execute: () => {
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [], [this.state.workflow.id], [],
                            (c: Campaign) => {
                                steps[0].status = "succeeded"
                                steps[0].results = {
                                    campaign: c,
                                }
                                steps[1].status = "in_progress"
                                steps[1].execute()
                            }, (reason: string) => {
                                steps[0].status = "failed"
                                steps[0].error = reason
                                steps[1].status = "cancelled"
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Create base campaign",
                    status:"waiting",
                })
                steps.push({
                    execute: () => {
                        const {chart, rootNode} = GetBasicWorkflowFields(this.state.eventName, this.state.senderPersona.id, this.state.templateId)
                        this.props.dispatch(submitWorkflowAction(this.state.workflow.id, this.state.campaign.name, chart, rootNode, false))

                        this.startWorkflowCreatedChecker(this.state.workflow.id, 
                        () => {
                            steps[1].status = "succeeded"
                            steps[2].status = "in_progress"
                            steps[2].execute()
                        }, (reason:string) => {
                            steps[1].status = "failed"
                            steps[1].error = reason || "This workflow took a very long time to be created. Please check the notifications tab and campaign details to make sure it was created and succesfully associated with the campaign."
                            steps[2].status = "cancelled"
                            if(onFailureFinish) {
                                onFailureFinish()
                            }
                        })
                    },
                    name: 'Create the event-triggered workflow / drip campaign',
                    status: 'waiting',
                })
                steps.push({
                    execute: () => {
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [], [this.state.workflow.id], [],
                            (c: Campaign) => {
                                steps[2].status = "succeeded"
                                steps[2].results = {
                                    campaign: c,
                                }
                                if(onSuccessfulFinish) {
                                    onSuccessfulFinish()
                                }
                            }, (reason: string) => {
                                steps[2].status = "failed"
                                steps[2].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name: 'Link the campaign to the workflow / drip campaign',
                    status: 'waiting',
                })

            break
            case "drip_campaign":
                //Create Campaign
                //For some reason this step only creates the campaign and refuses to associate
                //the campaign with the workflow ahead of time.  It makes no sense b/c there are no foreign keys
                //and no errors are being thrown.  However, just reposting the campaign after the workflow creation seems to work
                steps.push({
                    execute: () => {
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [], [this.state.workflow.id], [],
                            (c: Campaign) => {
                                steps[0].status = "succeeded"
                                steps[0].results = {
                                    campaign: c,
                                }
                                steps[1].status = "in_progress"
                                steps[1].execute()
                            }, (reason: string) => {
                                steps[0].status = "failed"
                                steps[0].error = reason
                                steps[1].status = "cancelled"
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Create base campaign",
                    status:"waiting",
                })
                steps.push({
                    execute: () => {
                        const eventName = "autogenerated-for-segments:" + (Object.keys(this.state.selectedSegmentIds || {})).join(",")
                        const [ selectedSendAtTypes, selectedSendAtTimezones, selectedSendAtDelaySeconds,
                            selectedSendAtMoments, selectedSenderPersonas, selectedTemplateIds] = reduceArraysToLength(
                                this.state.numEmails, 
                                this.state.selectedSendAtTypes,  
                                this.state.selectedSendAtTimezones,
                                this.state.selectedSendAtDelaySeconds,
                                this.state.selectedSendAtMoments,
                                this.state.selectedSenderPersonas,
                                this.state.selectedTemplateIds
                            )
                        const workflow = GenerateWorkflow(
                            this.state.workflowRootNodeId,
                            eventName, 
                            selectedSendAtTypes, 
                            selectedSendAtTimezones,
                            selectedSendAtDelaySeconds,
                            selectedSendAtMoments,
                            selectedSenderPersonas,
                            selectedTemplateIds)
                        this.props.dispatch(submitWorkflowAction(this.state.workflow.id, this.state.campaign.name, 
                            JSON.parse(JSON.stringify(workflow)),               //display
                            IChartToNode(JSON.parse(JSON.stringify(workflow))), //actual settings
                            false))

                        this.startWorkflowCreatedChecker(this.state.workflow.id, 
                        () => {
                            steps[1].status = "succeeded"
                            steps[2].status = "in_progress"
                            steps[2].execute()
                        }, (reason:string) => {
                            steps[1].status = "failed"
                            steps[1].error = reason || "This workflow took a very long time to be created. Please check the notifications tab and campaign details to make sure it was created and succesfully associated with the campaign."
                            steps[2].status = "cancelled"
                            if(onFailureFinish) {
                                onFailureFinish()
                            }
                        })
                    },
                    name: 'Create the segment-only workflow',
                    status: 'waiting',
                })
                steps.push({
                    execute: () => {
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [], [this.state.workflow.id], [],
                            (c: Campaign) => {
                                steps[2].status = "succeeded"
                                steps[2].results = {
                                    campaign: c,
                                }
                                if(steps.length > 3) {
                                    steps[3].execute()
                                }
                            }, (reason: string) => {
                                steps[2].status = "failed"
                                steps[2].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name: 'Link the campaign to the workflow',
                    status: 'waiting',
                })
                //Send segments through the workflow 
                Object.keys(this.state.selectedSegmentIds).map((id, index) => {
                    ((id:string, index:number) => {
                        const jobIndex = index + 3
                        let name = id.split("-")[0]
                        if(this.props.segments && this.props.segments[id]) {
                            name = this.props.segments[id].name
                        }
                        steps.push({
                            execute: () => {
                                this.props.dispatch(submitRunSegmentThroughWorkflowAction(
                                    id, 
                                    this.state.workflow.id,
                                    this.state.workflowRootNodeId,
                                    () => {
                                        steps[jobIndex].status = "succeeded"
                                        if(steps.length > jobIndex + 1) {
                                            steps[jobIndex + 1].execute()
                                        }
                                        if(jobIndex === steps.length-1) {
                                            if(onSuccessfulFinish) {
                                                onSuccessfulFinish()
                                            }
                                        }
                                    }, (reason: string) => {
                                        steps[jobIndex].status = "failed"
                                        steps[jobIndex].error = reason
                                        steps.map((s, i) => {
                                            if(steps[i].status == "in_progress") {
                                                steps[i].status="cancelled"
                                            }
                                        })
                                        if(onFailureFinish) {
                                            onFailureFinish()
                                        }
                                    }))
                            },
                            name: 'Send segment ' + name + ' to the workflow',
                            status: 'waiting',
                        })
                    })(id, index)
                })
            break
            case "event_triggered_drip_campaign":
                //Create Campaign
                //For some reason this step only creates the campaign and refuses to associate
                //the campaign with the workflow ahead of time.  It makes no sense b/c there are no foreign keys
                //and no errors are being thrown.  However, just reposting the campaign after the workflow creation seems to work
                steps.push({
                    execute: () => {
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [], [this.state.workflow.id], [],
                            (c: Campaign) => {
                                steps[0].status = "succeeded"
                                steps[0].results = {
                                    campaign: c,
                                }
                                steps[1].status = "in_progress"
                                steps[1].execute()
                            }, (reason: string) => {
                                steps[0].status = "failed"
                                steps[0].error = reason
                                steps[1].status = "cancelled"
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Create base campaign",
                    status:"waiting",
                })
                steps.push({
                    execute: () => {
                        const [ selectedSendAtTypes, selectedSendAtTimezones, selectedSendAtDelaySeconds,
                            selectedSendAtMoments, selectedSenderPersonas, selectedTemplateIds] = reduceArraysToLength(
                                this.state.numEmails, 
                                this.state.selectedSendAtTypes,  
                                this.state.selectedSendAtTimezones,
                                this.state.selectedSendAtDelaySeconds,
                                this.state.selectedSendAtMoments,
                                this.state.selectedSenderPersonas,
                                this.state.selectedTemplateIds
                            )
                        const workflow = GenerateWorkflow(
                            this.state.workflowRootNodeId,
                            this.state.eventName, 
                            selectedSendAtTypes, 
                            selectedSendAtTimezones,
                            selectedSendAtDelaySeconds,
                            selectedSendAtMoments,
                            selectedSenderPersonas,
                            selectedTemplateIds)
                        console.log("step 2")
                        console.log(JSON.stringify(workflow, null, 2))
                        this.props.dispatch(submitWorkflowAction(this.state.workflow.id, this.state.campaign.name, 
                            JSON.parse(JSON.stringify(workflow)),               //display
                            IChartToNode(JSON.parse(JSON.stringify(workflow))), //actual settings
                            false))
                        console.log("step 3")
                        this.startWorkflowCreatedChecker(this.state.workflow.id, 
                            () => {
                                steps[1].status = "succeeded"
                                steps[2].status = "in_progress"
                                console.log("Step 2 succeeded")
                                steps[2].execute()
                            }, (reason:string) => {
                                steps[1].status = "failed"
                                steps[1].error = reason || "This workflow took a very long time to be created. Please check the notifications tab and campaign details to make sure it was created and succesfully associated with the campaign."
                                steps[2].status = "cancelled"
                                console.log("Step 2 failed")

                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            })
                    },
                    name: 'Create the event-triggered drip-campaign workflow',
                    status: 'waiting',
                })
                steps.push({
                    execute: () => {
                        this.props.dispatch(submitCampaignAction(this.state.campaign.id, this.state.campaign.name,
                            this.translateCampaignType(this.state.campaign.type), [], [this.state.workflow.id], [],
                            (c: Campaign) => {
                                steps[2].status = "succeeded"
                                steps[2].results = {
                                    campaign: c,
                                }
                                if(onSuccessfulFinish) {
                                    onSuccessfulFinish()
                                }
                            }, (reason: string) => {
                                steps[2].status = "failed"
                                steps[2].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name: 'Link the campaign to the workflow',
                    status: 'waiting',
                })
            break
        }

        return steps
    }

    displayDelay(emailIndex: number):string {
        if(this.state.selectedSendAtTypes.length < emailIndex || !this.state.selectedSendAtTypes[emailIndex]) {
            return ""
        }

        const delaySeconds = this.state.selectedSendAtDelaySeconds[emailIndex] || 1
        const thisMoment = this.state.selectedSendAtMoments[emailIndex]
        let momentString = ""
        switch(this.state.selectedSendAtTypes[emailIndex]) {
            case "delay":
                if(Math.floor(delaySeconds / 86400) == delaySeconds / 86400) {
                    return `${Math.floor(delaySeconds / 86400)} day${Math.floor(delaySeconds / 86400)!=1?"s":""}`
                } else if(Math.floor(delaySeconds / 3600) == delaySeconds / 3600) {
                    return `${Math.floor(delaySeconds / 3600)} hour${Math.floor(delaySeconds / 3600)!=1?"s":""}`
                } else if(Math.floor(delaySeconds / 60) == delaySeconds / 60) {
                    return `${Math.floor(delaySeconds / 60)} minute${Math.floor(delaySeconds / 60)!=1?"s":""}`
                }

                return `${delaySeconds} second${delaySeconds!=1?"s":""}`
            case "specific_timezone":
                momentString = thisMoment.toISOString()
                if(moment.tz.guess()) {
                    momentString = moment(thisMoment).tz(moment.tz.guess(), true).toDate().toLocaleDateString() 
                        + " " + moment(thisMoment).tz(moment.tz.guess(), true).toDate().toLocaleTimeString() 
                }
                return `${momentString} in ${this.state.selectedSendAtTimezones[emailIndex]}`
            case "users_timezone":
                momentString = thisMoment.toISOString()
                if(moment.tz.guess()) {
                    momentString = moment(thisMoment).tz(moment.tz.guess(), true).toDate().toLocaleDateString() 
                        + " " + moment(thisMoment).tz(moment.tz.guess(), true).toDate().toLocaleTimeString() 
                }
                return `${momentString} in each contact's timezone`   
            case "time_of_day_specific_timezone":
                momentString = thisMoment.toDate().toLocaleTimeString()
                if(moment.tz.guess()) {
                    momentString = moment(thisMoment).tz(moment.tz.guess(), true).toDate().toLocaleTimeString() 
                }
                return `${momentString} in ${this.state.selectedSendAtTimezones[emailIndex]}`
            case "time_of_day_users_timezone":
                momentString = thisMoment.toDate().toLocaleTimeString()
                if(moment.tz.guess()) {
                    momentString = moment(thisMoment).tz(moment.tz.guess(), true).toDate().toLocaleTimeString() 
                }
                return `${momentString} in each contact's timezone`
        }

        return ""
    }

    abTestCampaignReviewRows(): JSX.Element {
        const rows = [] 
        for(let i = 0; i < this.state.numEmails; i++) {
            rows.push(<TableRow>
                <TableCell style={{minWidth:minReviewCol1Width}}>
                    <Typography variant="body1" component="span">
                    Template {i+1}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body1" component="span">
                    {this.state.selectedTemplateIds[i] && this.props.templates[this.state.selectedTemplateIds[i]] ? this.props.templates[this.state.selectedTemplateIds[i]].name:""}
                    <br />
                    {this.state.selectedTemplateIds[i] && this.props.templates[this.state.selectedTemplateIds[i]] ? this.props.templates[this.state.selectedTemplateIds[i]].subject:""}
                    </Typography>
                </TableCell>
            </TableRow>)
        }

        return <>{rows}</>
    }

    dripCampaignReviewRows(): JSX.Element {
        const rows = [] 
        if(this.state.campaign.type === "event_triggered_drip_campaign") {
            rows.push(<TableRow>
                <TableCell style={{minWidth:minReviewCol1Width}}>
                    <Typography variant="body1" component="span">
                    Triggering event
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body1" component="span">
                        {this.state.eventName}
                    </Typography>
                </TableCell>
            </TableRow>)
        }

        if(this.state.campaign.type === "drip_campaign") {
            if(this.state.selectedSegmentIds && Object.keys(this.state.selectedSegmentIds).length > 0) {
                Object.keys(this.state.selectedSegmentIds).map((segmentId, i) => {
                    rows.push(<TableRow>
                        <TableCell style={{minWidth:minReviewCol1Width}}>
                            <Typography variant="body1" component="span">
                            Segment {i+1} 
                            </Typography>
                        </TableCell>
                        <TableCell>
                        <Typography variant="body1" component="span">
                            {this.props.segments && this.props.segments[segmentId] ? this.props.segments[segmentId].name : "with id " + segmentId}
                        </Typography>
                        </TableCell>
                    </TableRow>)   
                })
            }
        }

        for(let i = 0; i < this.state.numEmails; i++) {
            rows.push(<TableRow>
                <TableCell style={{minWidth:minReviewCol1Width}}>
                    <Typography variant="body1" component="span">
                    Email {i+1} delay
                    {this.state.selectedSendAtTypes[i] !== "delay"?" until":""}
                    </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="body1" component="span">
                    {this.displayDelay(i)}
                    </Typography>
                </TableCell>
            </TableRow>)
            rows.push(<TableRow>
                <TableCell style={{minWidth:minReviewCol1Width}}>
                    <Typography variant="body1" component="span">
                    Email {i+1} persona
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body1" component="span">
                    {this.state.selectedSenderPersonas[i] && this.props.senderPersonas[this.state.selectedSenderPersonas[i].id] ?
                        <>
                        {this.props.senderPersonas[this.state.selectedSenderPersonas[i].id].first_name}&nbsp;
                        {this.props.senderPersonas[this.state.selectedSenderPersonas[i].id].last_name}<br />
                        {this.props.senderPersonas[this.state.selectedSenderPersonas[i].id].email}
                        </> : <></>}
                    </Typography>
                </TableCell>
            </TableRow>)
            rows.push(<TableRow>
                <TableCell style={{minWidth:minReviewCol1Width}}>
                    <Typography variant="body1" component="span">
                    Email {i+1} template
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body1" component="span">
                    {this.state.selectedTemplateIds[i] && this.props.templates[this.state.selectedTemplateIds[i]] ? this.props.templates[this.state.selectedTemplateIds[i]].name:""}
                    <br />
                    {this.state.selectedTemplateIds[i] && this.props.templates[this.state.selectedTemplateIds[i]] ? this.props.templates[this.state.selectedTemplateIds[i]].subject:""}
                    </Typography>
                </TableCell>
            </TableRow>)
        }



        return <>{rows}</>
    }
 
    render() {
        const selections = [
            {
                key:defaultValue,
                text:<>schedule a one-time email blast</>,
            },
            {
                key:"a_b_test",
                text:<>A/B test different email templates by scheduling a one-time email blast</>,
            },
            {
                key:"event_trigger_campaign",
                text:<>trigger transactional emails with events from my company</>,
            },
            {
                key:"event_triggered_drip_campaign",
                text:<>send contacts into a new drip campaign using events from my company</>,
            },
            {
                key:"drip_campaign",
                text:<>send existing segments into a new drip campaign</>,
            },
        ]

        return <>
          {/* Launch dialog */}
          {this.state.confirmingLaunch && <MyModal key={this.state.launchModalKey} 
                title={"Launch campaign?"}
                content={<>
                    <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                        Ready to launch campaign <b>{this.state.campaign.name}</b>?  We'll take these steps
                        to make it happen.  Please don't close your browser or navigate away from this page
                        while we are creating your campaign.
                    </Typography>
                    <Typography variant={"body1"} component={"ul"} style={{marginBottom:"1rem"}}>
                        {this.state.launchSteps.map((s,i) => <li key={"item_"+i} style={{listStyleType:"none"}}>
                            <div>
                                <div style={{display:"inline-block", width:"40px", textAlign:"center"}}>
                                    {s.status === "waiting" && <HourglassEmpty />}
                                    {s.status === "in_progress" && <Loading mySize="sm" hideText={true} />}
                                    {s.status === "succeeded" && <span style={{color:"green"}}><Check /></span>}
                                    {s.status === "failed" && <Error color="error" />}
                                    {s.status === "cancelled" && <span style={{color:"gray"}}>X</span>}
                                </div>
                                <div style={{display:"inline-block"}}>
                                    {s.name}
                                </div>
                            </div>
                        </li>)}
                    </Typography>
                    {this.state.launchSteps.map(s => s.error? <p style={{color:"red"}}>{s.error}</p>: <></>)}
                </>     }
                buttons={<>
                    <MyCancelButton disabled={!!this.state.executingLaunch} 
                    onClick={(event:any) => {
                        event.stopPropagation()
                        event.preventDefault()
                        
                        this.setState({
                            ...this.state,
                            confirmingLaunch: false,
                        });
                    }}/>
                    <MyOkButton disabled={!!this.state.executingLaunch} text="Launch campaign" onClick={(event:any) => {
                        event.stopPropagation()
                        event.preventDefault()

                        this.setState({
                            ...this.state,
                            executingLaunch: true,
                        });
                        this.state.launchSteps[0].execute()

                    }}/>
                </>}
            />}

        {(this.readyForSuccessPage() || this.readyForReviewAndLaunchPage() || this.state.pageNum < 3) && <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
            <Typography variant="h1" component="h1" style={{marginBottom:"1rem"}}>New Campaign</Typography>
            {this.state.pageNum==1 && <>
            <Typography variant="h2" component="p">
                I want to...
            </Typography>
            <RadioGroup
                style={{paddingLeft:"1rem"}}
                value={this.state.campaign.type}
                row={false} 
                name="campaignType"
                onChange={this.handleCampaignTypeChange}>
                {selections.map((selection,i) =>  <FormControlLabel key={"label_" + i} value={selection.key} control={<Radio color="primary" value={selection.key} />} 
                    label={<Typography variant="body1" component="span">{selection.text}</Typography>} 
                    color="primary" />)}
            </RadioGroup></>}
            {this.state.pageNum === 2 && <>
                    <Typography variant="h2" component="p">
                        Please name your campaign
                    </Typography>
                    <TextField value={this.state.campaign.name} autoFocus={true} style={{width:"100%", maxWidth:"500px", minWidth:"200px",marginBottom:"1rem"}} placeholder='Campaign name'
                        onChange={(e) => { 
                            e.stopPropagation(); 
                            e.preventDefault(); 
                            const campaignName = e.target?.value?.toString()
                            this.setState({
                                ...this.state,
                                nextButtonEnabled: campaignName.length > 0,
                                campaign: {
                                    ...this.state.campaign,
                                    name: campaignName
                            }})

                            debouncedFetchCampaignsWithName(this.props.dispatch, campaignName)
                        }} />
                    {this.props.campaigns && Object.values(this.props.campaigns).find(c => c.name === this.state.campaign?.name) ?
                        <Typography style={{color:"red"}}>A campaign with this name already exists.  Please choose a different name!</Typography>    
                        : "" }
                </>}
            {this.readyForReviewAndLaunchPage() && <>
                <Typography variant="h2" component="p">
                    Review and launch
                </Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>Name</Typography></TableCell>
                                <TableCell><Typography component="span" variant={"body1"}>{this.state.campaign.name}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>Type</Typography></TableCell>
                                <TableCell><Typography component="span" variant={"body1"}>{this.campaignTypeDisplay(this.state.campaign.type)}</Typography></TableCell>
                            </TableRow>
                            {this.state.campaign.type === "a_b_test" && 
                                this.abTestCampaignReviewRows()}
                            {(this.state.campaign.type === "event_triggered_drip_campaign" || this.state.campaign.type === "drip_campaign") && 
                                this.dripCampaignReviewRows()}
                            {this.state.campaign.type === "event_trigger_campaign" && <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>Event Name</Typography></TableCell>
                                <TableCell><Typography component="span" variant={"body1"}>{this.state.eventName}</Typography></TableCell>
                            </TableRow>}
                            {(this.state.campaign.type !== "event_triggered_drip_campaign" && this.state.campaign.type !== "drip_campaign") && <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>From</Typography></TableCell>
                                <TableCell><Typography component="span" variant={"body1"}>{this.props.senderPersonas[this.state.senderPersona?.id]?.email}</Typography></TableCell>
                            </TableRow>}
                           
                            {(this.state.campaign.type === defaultValue||this.state.campaign.type==="a_b_test") && <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>To</Typography></TableCell>
                                <TableCell>
                                    <Typography component="span" variant={"body1"}>
                                    {Object.keys(this.state.selectedSegmentIds).length} segment{Object.keys(this.state.selectedSegmentIds).length == 1 ? "":"s" } <br />
                                    {Object.keys(this.state.selectedUserIds).length} contact{Object.keys(this.state.selectedUserIds).length == 1 ? "":"s" }
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                            {(this.state.campaign.type !== "event_triggered_drip_campaign" && this.state.campaign.type !== "drip_campaign" && this.state.campaign.type !== "a_b_test") && <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>Template</Typography></TableCell>
                                <TableCell>
                                    <Typography component="span" variant={"body1"}>
                                    {this.props.templates[this.state.templateId]?.name}
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                            {(this.state.campaign.type === defaultValue||this.state.campaign.type ==="a_b_test") && <><TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>Timing strategy</Typography></TableCell>
                                <TableCell>
                                    <Typography component="span" variant={"body1"}>
                                    {this.state.sendAtType==="specific_timezone"? "send as one blast": "adjust to each contact's timezone"}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{minWidth:minReviewCol1Width}}><Typography component="span" variant={"body1"}>Send time</Typography></TableCell>
                                <TableCell>
                                    <Typography component="span" variant={"body1"}>
                                    {this.state.sendAtMoment.toLocaleString()}&nbsp;
                                    {this.state.sendAtType==="specific_timezone"? ", Timezone: " + this.state.sendAtTimezone: ""}
                                    </Typography>
                                </TableCell>
                            </TableRow></>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>}
            {this.readyForSuccessPage() && <>
                <Typography variant="h2" component="p" style={{color:"green"}}>
                    <CheckCircle fontSize='large' /> Success!
                </Typography>
                <Typography variant="body1" component="p" style={{marginBottom:"1rem"}}>
                    Your <Link to={"/campaigns"} target="_blank">campaign</Link>&nbsp; has been created successfully.  Components include:
                </Typography>
                {this.state.campaign.type === "a_b_test" && <Typography variant="body1" component="ul">
                <li key={"emailblastedititem"}><Link to={"/components/email-blasts/edit/" + this.state.scheduledEmailGroup.id} target="_blank">This</Link>&nbsp; scheduled email blast</li>
                {this.state.selectedTemplateIds.map((id, index) => {
                    if(index >= this.state.numEmails) {
                        return <></>
                    }
                    return <li key={"item_" + index}><Link to={"/components/templates/edit/" + id} target="_blank">This</Link>&nbsp; template</li>
                })}
                <Link to={"/campaigns/edit/" + this.state.campaign.id} target="_blank">This campaign component</Link>
                <li key={"senderpersonaedit"}><Link to={"/people/sender-personas/edit/" + this.state.senderPersona.id} target="_blank">This</Link>&nbsp; sender persona</li>
                </Typography>}
                {this.state.campaign.type === defaultValue && <Typography variant="body1" component="ul">
                <li key={"emailblastedititem2"}><Link to={"/components/email-blasts/edit/" + this.state.scheduledEmailGroup.id} target="_blank">This</Link>&nbsp; scheduled email blast</li>
                <li key={"templatesedit"}><Link to={"/components/templates/edit/" + this.state.templateId} target="_blank">This</Link>&nbsp; template</li>
                <li key={"personasedit"}><Link to={"/people/sender-personas/edit/" + this.state.senderPersona.id} target="_blank">This</Link>&nbsp; sender persona</li>
                </Typography>}
                {this.state.campaign.type === "event_trigger_campaign" && <>
                    <Typography variant="body1" component="ul">
                    <li key={"workflowsitemedit1"}><Link to={"/components/workflows/edit/" + this.state.workflow.id} target="_blank">This</Link>&nbsp; event-triggered workflow</li>
                    <li key={"templatesedit2"}><Link to={"/components/templates/edit/" + this.state.templateId} target="_blank">This</Link>&nbsp; template</li>
                    <li key={"senderpersonaedit2"}><Link to={"/people/sender-personas/edit/" + this.state.senderPersona.id} target="_blank">This</Link>&nbsp; sender persona</li>
                    </Typography>
                    <Typography variant='body1' component="p" style={{marginTop:"1rem"}}>
                    To trigger this workflow, you'll want to send an event to our event api from your system.  Please see the 
                    &nbsp;<Link to="/account/settings" target="_blank">settings page</Link>&nbsp; for more information on how to do that.  
                    Please remember never to give your api key to anyone!
                    </Typography>
                </>}
                {this.state.campaign.type === "drip_campaign" && <>
                    <Typography variant="body1" component="ul">
                    <li key={"workflowsedit2"}><Link to={"/components/workflows/edit/" + this.state.workflow.id} target="_blank">This</Link>&nbsp; segment-only workflow</li>
                    {this.state.selectedTemplateIds.map((s,i) => <li key={"templatesedit4_" + i}><Link to={"/components/templates/edit/" + s} target="_blank">This</Link>&nbsp; template for email {i+1}</li>)}
                    {this.state.selectedSenderPersonas.map((s,i) => <li key={"personasedit4_" + i}><Link to={"/people/sender-personas/edit/" + s.id} target="_blank">This</Link>&nbsp; sender persona for email {i+1}</li>)}
                    </Typography>
                    {Object.keys(this.state.selectedSegmentIds).length > 0 && <><Typography variant="body1" component="p" style={{marginTop:"1rem", marginBottom:"1rem"}}>
                        Contacts in each of these segments were successfully placed in the segment-only workflow:
                    </Typography>
                    <Typography variant="body1" component="ul">
                        {Object.keys(this.state.selectedSegmentIds).map(id => {
                            let segmentName = ""
                            if(this.props.segments && this.props.segments[id]) {
                                segmentName = this.props.segments[id].name
                            }
                            return <li key={"segmentsedit1"}><Link to={"/people/segments/edit/" + id} target="_blank">This</Link>&nbsp; segment {segmentName ? "(" + segmentName + ")":""}</li>
                        })}
                    </Typography></>}
                </>}
                {this.state.campaign.type === "event_triggered_drip_campaign" && <>
                    <Typography variant="body1" component="ul">
                    <li key={"workflowsedit3"}><Link to={"/components/workflows/edit/" + this.state.workflow.id} target="_blank">This</Link>&nbsp; event-triggered workflow</li>
                    {this.state.selectedTemplateIds.map((s,i) => <li key={"templatesedit3_" + i}><Link to={"/components/templates/edit/" + s} target="_blank">This</Link>&nbsp; template for email {i+1}</li>)}
                    {this.state.selectedSenderPersonas.map((s,i) => <li key={"senderpersonasedit3_" + i}><Link to={"/people/sender-personas/edit/" + s.id} target="_blank">This</Link>&nbsp; sender persona for email {i+1}</li>)}
                    </Typography>
                    <Typography variant='body1' component="p" style={{marginTop:"1rem"}}>
                    To trigger this workflow, you'll want to send an event to our event api from your system.  Please see the 
                    &nbsp;<Link to="/account/settings" target="_blank">settings page</Link>&nbsp; for more information on how to do that.  
                    Please remember never to give your api key to anyone!
                    </Typography>
                </>}
            </>}
            <div style={{marginTop:"1rem"}}>
                {this.getButtons()}
            </div>
        </Card>}
        {(!this.readyForSuccessPage() && !this.readyForReviewAndLaunchPage() && this.state.pageNum >= 3) && <>
            {(this.state.campaign.type===defaultValue) && 
                <ScheduledEmailBlastCampaign 
                    width={this.props.width} 
                    dispatch={this.props.dispatch} 
                    onPageChange = { this.onPageChange}
                    onSegmentsChange = { this.onSegmentsChange}
                    onSenderPersonaChange = { this.onSenderPersonaChange}
                    onTemplateChange = { this.onTemplateChange}
                    onSendAtChange = { this.onSendAtChange}
                    onUsersChange = { this.onUsersChange}
                    onToTypeChange = { this.onToTypeChange}
                    {...this.state}
                    />}
            {(this.state.campaign.type==="event_trigger_campaign") && 
                <WorkflowEventTriggerCampaign 
                    onEventNameChange={(name:string) => {
                        this.setState({
                            ...this.state,
                            eventName: name,
                        })
                    }}
                    eventName={this.state.eventName}
                    width={this.props.width} 
                    dispatch={this.props.dispatch} 
                    onPageChange = { this.onPageChange}
                    onSenderPersonaChange = { this.onSenderPersonaChange}
                    onTemplateChange = { this.onTemplateChange}
                    {...this.state}
                    />}
            {(this.state.campaign.type==="a_b_test") && 
                <ABTestCampaign
                    templates={this.props.templates}
                    onSelectedTemplatWeightsChange={this.onSelectedTemplatWeightsChange}
                    numTemplates={this.state.numEmails} 
                    onNumTemplatesChange={this.onNumEmailsChange } 
                    width={this.props.width}
                    dispatch={this.props.dispatch}
                    onPageChange={this.onPageChange}
                    onSegmentsChange={this.onSegmentsChange}
                    onSenderPersonaChange={this.onSenderPersonaChange}
                    onSelectedTemplateIdsChange={(selectedTemplateIds: string[], isNewTemplates: boolean[]) => {
                        this.setState({
                            ...this.state,
                            selectedTemplateIds,
                            isNewTemplates,
                        });
                    } }
                    onSendAtChange={this.onSendAtChange}
                    onUsersChange={this.onUsersChange}
                    onToTypeChange={this.onToTypeChange}
                    {...this.state}                
                />}
            {(this.state.campaign.type==="drip_campaign") && 
                <WorkflowDripCampaign 
                    forSegment={true}
                    eventName={""}
                    onEventNameChange={() => {console.error("event names shouldn't change for this workflow type")}}
                    onSegmentsChange = { this.onSegmentsChange}
                    onNumEmailsChange={this.onNumEmailsChange} 
                    onSelectedSenderPersonasChange={(selectedSenderPersonas: SenderPersona[],  isNewSenderPersonas: boolean[]) => {
                        this.setState({
                            ...this.state, 
                            selectedSenderPersonas, 
                            isNewSenderPersonas,
                        })
                    }}
                    onSelectedTemplateIdsChange={(selectedTemplateIds:string[], isNewTemplates: boolean[]) => {
                        this.setState({
                            ...this.state,
                            selectedTemplateIds,
                            isNewTemplates,
                        })
                    }}
                    onSelectedSendAtsChange = {(
                        selectedSendAtMoments: moment.Moment[], 
                        selectedSendAtTypes:("time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay")[], 
                        selectedSendAtTimezones: string[],
                        selectedSendAtDelaySeconds: number[],    
                    ) => {
                        this.setState({
                            ...this.state, 
                            selectedSendAtMoments,
                            selectedSendAtTypes,
                            selectedSendAtTimezones,
                            selectedSendAtDelaySeconds,
                        })  
                    }}
                    width={this.props.width} 
                    dispatch={this.props.dispatch} 
                    onPageChange = { this.onPageChange}
                    {...this.state}
                    />}
            {(this.state.campaign.type==="event_triggered_drip_campaign") && 
                <WorkflowDripCampaign 
                    forSegment={false}
                    eventName={this.state.eventName}
                    onEventNameChange={(eventName:string) => {
                        this.setState({
                            ...this.state,
                            eventName,
                        })
                    }}
                    onSegmentsChange = {() => { console.error("segments shouldn't be selected for this workflow type")}}
                    onNumEmailsChange={this.onNumEmailsChange} 
                    onSelectedSenderPersonasChange={(selectedSenderPersonas: SenderPersona[],  isNewSenderPersonas: boolean[]) => {
                        this.setState({
                            ...this.state, 
                            selectedSenderPersonas, 
                            isNewSenderPersonas,
                        })
                    }}
                    onSelectedTemplateIdsChange={(selectedTemplateIds:string[], isNewTemplates: boolean[]) => {
                        this.setState({
                            ...this.state,
                            selectedTemplateIds,
                            isNewTemplates,
                        })
                    }}
                    onSelectedSendAtsChange = {(
                        selectedSendAtMoments: moment.Moment[], 
                        selectedSendAtTypes:("time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay")[], 
                        selectedSendAtTimezones: string[],
                        selectedSendAtDelaySeconds: number[],    
                    ) => {
                        this.setState({
                            ...this.state, 
                            selectedSendAtMoments,
                            selectedSendAtTypes,
                            selectedSendAtTimezones,
                            selectedSendAtDelaySeconds,
                        })  
                    }}
                    width={this.props.width} 
                    dispatch={this.props.dispatch} 
                    onPageChange = { this.onPageChange}
                    {...this.state}
                    />}
        </>}
    </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageCampaignsMock)
