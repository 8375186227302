import React from 'react';
import {connect} from "react-redux";
import {AllState} from "../reducers";
import {Card} from "@material-ui/core";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import LeftNav from './LeftNavV2';
import { PublicLeftNav } from './PublicLeftNav';
import { PublicTopNav } from './PublicTopNav';
import TopNav from './TopNavV2';

const leftNavElevation = 1;

interface IState {
}

interface IProps extends RouteComponentProps<{ id?: string, path?:string,url?:string, location?:string, }> {
    match:any,
    showLeftNav: boolean,
    isAuthed: boolean,
}

class NavContainer extends React.Component<IProps, IState> {
    props: IProps;
    state: IState;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
        }
    }

    render() {
        let isAuthed = this.props.isAuthed
        if(this.props.location?.pathname === "/logout") {
            isAuthed = false
        }
        if(this.props.showLeftNav) {
            return <div style={{width:"100%",height:"100%", minHeight:"100dvh", borderRight:"1px lightgray solid"}}>
                {isAuthed &&  <LeftNav key={this.props.location.pathname} />}
                {!isAuthed && <PublicLeftNav key={this.props.location.pathname}  />}
            </div>
        }
        return <div style={{position:"fixed", zIndex:500,width:'100%'}}>
            {isAuthed && <TopNav />}
            {!isAuthed && <PublicTopNav />}
        </div>
    }
}



function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state}
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NavContainer))

