import React, {ChangeEvent, FormEvent} from 'react';
import SenderPeronaListComponent from "./ListSenderPersonas"
import TemplateListComponent from "./ListTemplates"
import { connect } from "react-redux";
import SegmentList from './ListSegments';
import {fetchEmailBlasts, submitEmailBlastAction} from './actions/EmailBlasts'
import {Button, Card, Grid, TextField, Typography} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save"
import {Description, EmojiPeople, MyLocation,Event, Language} from "@material-ui/icons";
import UserList from "./ListUsers"
import {EmailBlastIcon} from "./Icons"
import {validateExists} from "./InputValidation";
import {v4 as uuidv4} from "uuid";
import {ScheduledEmailGroup} from "./generated/types/payloadTypes";
import {TimezoneSelect} from "./components/TimezoneSelect";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {RouteComponentProps, withRouter, Link} from "react-router-dom";
import { Loading } from './components/Loading';
import {usersLocalTimezone} from './workflow/NodeSettings'
import moment from 'moment';
import DateTimePickerRow from './components/DateTimePickerRow';

interface IState {
    id: string;
    name: string;
    selectedTemplateId:string,
    selectedSenderPersonaIds: string[],
    selectedSegmentIds: string[],
    selectedUserIds: string[],
    sendAtDate: moment.Moment,
    sendAtTimezone: string,
    allowValidation: boolean,
    fetchedEmailBlast: boolean,
    isEdit: boolean,
} 

interface IProps extends RouteComponentProps<{ id?: string  }>  {
    dispatch: any,
    match: any,
    width: string,
    fetchedEmailBlast?: ScheduledEmailGroup,
}

class NewEmailBlastComponent extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;

        const nowLocalButUTC = moment(new Date()).tz(moment.tz.guess()).tz("UTC", true)
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id: uuidv4(),
            isEdit: !!this.props.match.params.id,
            name: "",
            selectedSenderPersonaIds: [],
            selectedTemplateId: null,
            selectedSegmentIds: [],
            selectedUserIds: [],
            sendAtTimezone: usersLocalTimezone,
            allowValidation: false,
            fetchedEmailBlast: false,
            sendAtDate: nowLocalButUTC,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.timezoneChange = this.timezoneChange.bind(this);
        this.senderPersonaChange = this.senderPersonaChange.bind(this);
        this.templateChange = this.templateChange.bind(this);
        this.segmentChange = this.segmentChange.bind(this);
        this.userChange = this.userChange.bind(this);
        this.userAdded = this.userAdded.bind(this);
        this.userRemoved = this.userRemoved.bind(this);
        this.segmentAdded = this.segmentAdded.bind(this);
        this.segmentRemoved = this.segmentRemoved.bind(this);
    }

    dateChange(newDate: moment.Moment) {
        this.setState({
            ...this.state,
            sendAtDate: newDate,
        });
    }

    timezoneChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                sendAtTimezone: null,
            });
            return;
        }
        this.setState({
            ...this.state,
            sendAtTimezone: event.target.value,
        });
    }

    nameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();
        const name = event.target.value;
        this.setState((state) => {
            return {
                ...state,
                name,
            };
        });
    }

    senderPersonaChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedSenderPersonaIds: null,
            });
            return;
        }

        const selectedSenderPersonaIds = [event.target.value] as string[];
        this.setState({
            ...this.state,
            selectedSenderPersonaIds,
        });
        console.log("Selected personas " + selectedSenderPersonaIds)
    }

    templateChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedTemplateId: null,
            });
            return;
        }

        this.setState({
            ...this.state,
            selectedTemplateId: event.target.value.toString(),
        });
        console.log("Selected template " + event.target.value.toString())
    }

    segmentChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedSegmentIds: null,
            });
            return;
        }

        const selectedSegmentIds = event.target.value as string[];
        this.setState({
            ...this.state,
            selectedSegmentIds,
        });
        console.log("Selected segments " + selectedSegmentIds)
    }

    userChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedUserIds: null,
            });
            return;
        }

        const selectedUserIds = event.target.value as string[];
        this.setState({
            ...this.state,
            selectedUserIds,
        });
        console.log("Selected users " + selectedUserIds)
    }

    userRemoved(userId: string) {
        if(!userId) {
            console.error("User id not given for removal, got " + userId)
            return;
        }

        const selectedUserIds = this.state.selectedUserIds || [];
        const dedupe: {[key:string]:boolean} = {}
        selectedUserIds.map(id => {
            if(userId !== id) {
                dedupe[id] = true
            }
        })

        this.setState({
            ...this.state,
            selectedUserIds: Object.keys(dedupe),
        });
        console.log("Deselected " + userId + " for total of users " +  Object.keys(dedupe).join(","))
    }

    userAdded(userId: string) {
        if(!userId) {
            console.error("User id not given, got " + userId)
            return;
        }

        const selectedUserIds = this.state.selectedUserIds || [];
        selectedUserIds.push(userId)

        const dedupe: {[key:string]:boolean} = {}
        selectedUserIds.map(id => dedupe[id] = true)

        this.setState({
            ...this.state,
            selectedUserIds: Object.keys(dedupe),
        });
        console.log("Selected users " +  Object.keys(dedupe).join(","))
    }


    segmentRemoved(segmentId: string) {
        if(!segmentId) {
            console.error("segmentId not given for removal, got " + segmentId)
            return;
        }

        const selectedSegmentIds = this.state.selectedSegmentIds || [];
        const dedupe: {[key:string]:boolean} = {}
        selectedSegmentIds.map(id => {
            if(segmentId !== id) {
                dedupe[id] = true
            }
        })

        this.setState({
            ...this.state,
            selectedSegmentIds: Object.keys(dedupe),
        });
        console.log("Deselected " + segmentId + " for total of segments " +  Object.keys(dedupe).join(","))
    }

    segmentAdded(segmentId: string) {
        if(!segmentId) {
            console.error("Segment id not given, got " + segmentId)
            return;
        }

        const selectedSegmentIds = this.state.selectedSegmentIds || [];
        selectedSegmentIds.push(segmentId)

        const dedupe: {[key:string]:boolean} = {}
        selectedSegmentIds.map(id => dedupe[id] = true)

        this.setState({
            ...this.state,
            selectedSegmentIds: Object.keys(dedupe),
        });
        console.log("Selected segment " +  Object.keys(dedupe).join(","))
    }

    validateForm(state: IState):boolean {
        if(validateExists(state.name) !== "") {
            return false;
        }
        if(!state.selectedTemplateId) {
            return false;
        }
        if(state.selectedSenderPersonaIds.length === 0) {
            return false;
        }
        if(state.selectedSegmentIds && state.selectedSegmentIds.length === 0 && state.selectedUserIds && state.selectedUserIds.length === 0) {
            return false;
        }

        if(!state.sendAtDate) {
            return false;
        }

        if(state.sendAtTimezone === "") {
            return false;
        }

        return true;
    }

    handleSubmit(event: React.MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            });
        }

        if(this.validateForm(this.state)) {
            this.props.dispatch(submitEmailBlastAction(
                this.state.id,
                this.state.name,
                this.state.selectedTemplateId,
                [],
                this.state.selectedSenderPersonaIds[0],
                this.state.selectedSegmentIds,
                this.state.selectedUserIds,
                this.state.sendAtDate,
                this.state.sendAtTimezone, // only needed for visuals when reloading this page
                () => {
                    const desiredPath = "/components/email-blasts/edit/" + this.state.id
                    if(this.props.location.pathname !== desiredPath) {
                        this.props.history.replace(desiredPath)
                    }
                })
            )
        }
    }

    componentDidMount(){
        if(this.props.match.params.id) {
            this.props.dispatch(fetchEmailBlasts([this.props.match.params.id]))
        }
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(!prevState.fetchedEmailBlast && nextProps.fetchedEmailBlast && nextProps.fetchedEmailBlast.id) { //} && Object.keys(nextProps.fetchedEmailBlast) > 0) {
            const sendAtDate = nextProps.fetchedEmailBlast.send_at ||  moment()
            let timezone = usersLocalTimezone
            if(!nextProps.fetchedEmailBlast.send_in_users_timezone) {
                timezone = nextProps.fetchedEmailBlast.payload.timezone
            }

            return {
                ...prevState,
                sendAtDate,
                selectedSegmentIds: nextProps.fetchedEmailBlast.payload.to_segment_ids,
                selectedUserIds: nextProps.fetchedEmailBlast.payload.to_user_ids,
                selectedTemplateId: nextProps.fetchedEmailBlast.payload.template_id,
                selectedSenderPersonaIds: [nextProps.fetchedEmailBlast.payload.sender_persona_id],
                sendAtTimezone: timezone,
                name: nextProps.fetchedEmailBlast.name,
                fetchedEmailBlast: true,
            }
        }

        return prevState;
    }

    render() {
        return (
            <>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"div"}>
                        {!this.state.isEdit ? "New " : "Edit " }
                        Email Blast
                    </Typography>
                    <hr />

                    <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            <EmailBlastIcon />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TextField
                                required={true}
                                autoFocus={true}
                                style={{width:"100%",minWidth:"300px"}}
                                label={"Blast name"}
                                disabled={this.state.isEdit}
                               value={this.state.name}
                               onChange={this.nameChange}
                               error={this.state.allowValidation && validateExists(this.state.name)!==""}
                               helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            <Description />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            {(this.state.isEdit && !this.state.fetchedEmailBlast)?<Loading mySize="sm" />:<TemplateListComponent
                                width={this.props.width}
                                style={{width:"100%",minWidth:"300px"}}
                                onChange={this.templateChange}
                                required={true}
                                disabled={this.state.isEdit}
                                type='select'
                                label={"Email template"}
                                placeholder={"Select email template"}
                                helperText={this.state.allowValidation && !this.state.selectedTemplateId ? "Required field" : null}
                                selectedTemplateId={this.state.selectedTemplateId}
                                dispatch={this.props.dispatch} />}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            <EmojiPeople />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            {(this.state.isEdit && !this.state.fetchedEmailBlast)?<Loading mySize="sm" />:<SenderPeronaListComponent
                                width={this.props.width}
                                required={true}
                                style={{width:"100%",minWidth:"300px"}}
                                disabled={this.state.isEdit}
                                onChange={this.senderPersonaChange}
                                selectedSenderPersonaIds={this.state.selectedSenderPersonaIds}
                                type='select'
                                label={"Sender persona"}
                                placeholder={"Select sender persona"}
                                helperText={this.state.allowValidation && !this.state.selectedSenderPersonaIds.length ? "Required field" : null}
                                dispatch={this.props.dispatch} />}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"} style={{marginTop:"9px"}}>
                        <Grid>
                            <MyLocation />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Typography component={"p"} style={{marginBottom:"5px"}}>
                                Send to: <a href="#segmentlist">{this.state && this.state.selectedSegmentIds?this.state.selectedSegmentIds.length:0} segments</a>,
                                &nbsp;<a href="#userlist">{this.state.selectedUserIds?this.state.selectedUserIds.length:0} contacts</a>
                            </Typography>
                            {this.state.allowValidation && this.state.selectedSegmentIds && !this.state.selectedSegmentIds.length && this.state.selectedUserIds && !this.state.selectedUserIds.length &&
                                <Typography component={"p"} style={{color:"red", fontSize:"9pt"}}>You must choose at least one segment or contact to send the email to</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            <Event />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <DateTimePickerRow 
                                disabled={this.state.isEdit}
                                dispatch={this.props.dispatch}
                                onChange={this.dateChange} 
                                datetimeMoment={this.state.sendAtDate}
                                />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"}>
                        <Grid item>
                            <Language />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TimezoneSelect label={"Timezone"}
                                disabled={this.state.isEdit}
                                required={true}
                                placeholder={"Select timezone"}
                                style={{width:"100%",minWidth:"300px"}}
                                includeUsersLocalTimezoneOption={true}
                                firstOption={usersLocalTimezone}
                                timezone={this.state.sendAtTimezone}
                                timezoneChange={this.timezoneChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" className={"button-footer"}>
                        <Grid item>
                            <Language style={{visibility:"hidden"}} />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    startIcon={<SaveIcon />}
                                    type={"submit"}
                                    onClick={this.handleSubmit}
                                    disabled={this.state.isEdit}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                            {this.state.isEdit && <Typography variant={"body1"} component={"p"}
                                style={{color:"orange"}}>
                                <i>Once email blasts have been scheduled, they can't be edited.  If you need to 
                                change something with this blast please disable it on the  <Link to="/components/email-blasts">list page</Link> and create a new one instead.</i>
                            </Typography>}
                        </Grid>
                    </Grid>
                    </form>
                </Card>
                <a id="userlist"></a>
                {(this.state.isEdit && !this.state.fetchedEmailBlast)? <Card style={defaultCardStyles} elevation={defaultCardElevation}>
                    <Typography variant={"h1"} component={"div"}>To contacts</Typography>
                    <br />
                    <Loading />
                </Card>:
                <Card style={defaultCardStyles} elevation={defaultCardElevation}>
                    <Grid container>
                        <Grid item>
                            <Typography variant={"h1"} component={"div"}>To contacts</Typography>
                        </Grid>
                    </Grid>
                    <hr />
                    <UserList type={"list"}
                        buttons={this.state.isEdit?{}:{"add":true, "remove":true}}
                        width={this.props.width}
                        dispatch={this.props.dispatch}
                        hideAddButton={true}
                        onUserAdd={this.userAdded}
                        onUserRemove={this.userRemoved}
                        selectedUserIds={this.state.selectedUserIds}
                        showSelectedOnly={this.state.isEdit}
                        hideSearch={this.state.isEdit}
                        />
                </Card>
                }
                <a id="segmentlist"></a>
                {(this.state.isEdit && !this.state.fetchedEmailBlast)? <Card style={defaultCardStyles} elevation={defaultCardElevation}>
                    <Typography variant={"h1"} component={"div"}>To segments</Typography>
                    <br />
                    <Loading />
                </Card>:
                <Card style={defaultCardStyles} elevation={defaultCardElevation}>
                    <Grid container>
                        <Grid item>
                            <Typography variant={"h1"} component={"div"}>To segments</Typography>
                        </Grid>
                    </Grid>
                    <hr />
                    <SegmentList
                        width={this.props.width}
                        required={true}
                        buttons={this.state.isEdit?{}:{"add":true, "remove":true}}
                        type='list'
                        hideAddButton={true}
                        dispatch={this.props.dispatch} 
                        onSegmentAdd={this.segmentAdded}
                        onSegmentRemove={this.segmentRemoved}
                        selectedSegmentIds={this.state.selectedSegmentIds}
                        showSelectedOnly={this.state.isEdit}
                        />
                </Card>
               }
            </>
        );
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    //Find the persona with the id passed in via the route
    let fetchedEmailBlast = {};
    if(state.emailBlasts && Object.keys(state.emailBlasts).length > 0) {
        if(state.emailBlasts[ownProps.match.params.id]) {
            fetchedEmailBlast = state.emailBlasts[ownProps.match.params.id];
        }
    }

    return {...state, fetchedEmailBlast}
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewEmailBlastComponent))

