import React from 'react';
import {connect} from "react-redux";
import {Card, Typography} from "@material-ui/core"
import { defaultCardElevation, defaultCardStyles } from './App';
import { TestamonialCard } from './components/TestamonialCard';
import ContactUsForm from './ContactUs';

interface IProps {
    width: string,
    dispatch: any,
}

class PageTestamonials extends React.Component<IProps, null> {
    render() {
        return  <><Card style={defaultCardStyles} elevation={defaultCardElevation}>
            <Typography variant={"h1"} component={"div"}>Testamonials</Typography>
            <br />
            <Typography variant={"body1"}>Here are some of the nice things people have said about us!</Typography>
            <br />
            <TestamonialCard 
                quote = "SwiftMissive has revolutionized our email marketing efforts. I highly recommend SwiftMissive for your email marketing needs."
                imageUrl='/images/testamonialangela.jpg' 
                attributeTo={<>
                    <Typography variant='body1' style={{marginBottom:"0.5rem"}}><b>— Angela @ <a style={{color:"#777"}} target="_blank" href="https://pittman-power.com">pittman-power.com</a></b></Typography>
                </>}
            />
            <TestamonialCard 
                quote = "I love the simple design. It cuts to the core of everything needed for email marketing. Simple and Effective."
                imageUrl='/images/testamonialaustin.jpg' 
                attributeTo={<>
                    <Typography variant='body1' style={{marginBottom:"0.5rem"}}><b>— Austin @ <a style={{color:"#777"}} target="_blank" href="https://zerobounce.net">zerobounce.net</a></b></Typography>
                </>}
            />
            <TestamonialCard 
                quote = "I am really delighted with Swiftmissive - it is already generating bookings." 
                imageUrl='/images/testamonialrobin.jpg'
                attributeTo={<>
                    <Typography variant='body1' style={{marginBottom:"0.1rem"}}><b>— Robin @ <a style={{color:"#777"}} target="_blank" href="http://www.writebackintime.co.uk">writebackintime.co.uk</a></b></Typography>
                    <Typography variant='body1' style={{fontSize:"12pt"}}>
                        &ldquo;Your child’s writing adventure starts &nbsp;
                        <a target="_blank" href="http://www.writebackintime.co.uk" style={{color:"#666"}}>here.</a>
                        &rdquo;
                    </Typography>
                    
                </>}
            />
        </Card>
        <Card elevation={defaultCardElevation} style={defaultCardStyles}>
            <ContactUsForm 
                width={this.props.width} 
                dispatch={this.props.dispatch} 
                autoFocus={false}
                buttonText='Send feedback'
                textAreaLabel='Enter your feedback'
                header={<>
                    <Typography variant='h1' component={"p"} style={{marginBottom:"1rem"}}> 
                        Anything to add?
                    </Typography>
                    <Typography variant='body1' component={"p"} style={{marginBottom:"1rem"}}> 
                    Have you been using Swift Missive and have something to add?  Let us know!
                    </Typography>
                    </>
                } />
        </Card>
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageTestamonials)
