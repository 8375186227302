import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  AddUsersToSegmentPayload,
  AddUsersToSegmentResponsePayload,
  CreateSegmentFromFiltersPayload,
  CreateSegmentFromFiltersResponsePayload,
  FilterUsersPreviewPayload,
  FilterUsersPreviewResponsePayload,
  GetSegmentsResponsePayload,
  RemoveUsersFromSegmentResponsePayload, Segment, UpdateSegmentNamePayload, UpdateSegmentNameResponsePayload,
  UpsertSegmentsPayload,
  UpsertSegmentsResponsePayload,
  User
} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";
import {UserFilter} from "../generated/types/payloadTypes"
import {translateFilters} from "../components/SegmentFilterMapWrapper"

export const REQUEST_SEGMENTS = 'REQUEST_SEGMENTS';
export const RECEIVE_SEGMENTS = 'RECEIVE_SEGMENTS';
export const SELECT_SEGMENT = 'SELECT_SEGMENT';
export const REQUEST_NEW_SEGMENT = 'REQUEST_NEW_SEGMENT';
export const RECEIVE_NEW_SEGMENT_RESPONSE = 'RECEIVE_NEW_SEGMENT_RESPONSE';
export const REQUEST_REMOVE_USERS_FROM_SEGMENT = 'REQUEST_REMOVE_USERS_FROM_SEGMENT';
export const REMOVE_USERS_FROM_SEGMENT_RESPONSE = 'REMOVE_USERS_FROM_SEGMENT_RESPONSE';
export const REQUEST_ADD_USERS_TO_SEGMENT = 'REQUEST_ADD_USERS_TO_SEGMENT';
export const ADD_USERS_TO_SEGMENT_RESPONSE = 'ADD_USERS_TO_SEGMENT_RESPONSE';
export const REQUEST_SEGMENT_NAME_UPDATE = 'REQUEST_SEGMENT_NAME_UPDATE';
export const RECEIVE_SEGMENT_NAME_UPDATE_RESPONSE = 'RECEIVE_SEGMENT_NAME_UPDATE_RESPONSE';
export const REQUEST_FILTER_USERS = 'REQUEST_FILTER_USERS';
export const REQUEST_FILTER_USERS_RESPONSE = 'REQUEST_FILTER_USERS_RESPONSE';
export const REQUEST_SEGMENT_FROM_FILTERS = 'REQUEST_SEGMENT_FROM_FILTERS';
export const REQUEST_SEGMENT_FROM_FILTERS_RESPONSE = 'REQUEST_SEGMENT_FROM_FILTERS_RESPONSE';

interface IRequestSegmentsAction {
  type: typeof REQUEST_SEGMENTS,
}
interface IReceiveSegmentsAction {
  type: typeof RECEIVE_SEGMENTS,
  data: any
}
interface IRequestSegmentNameUpdateAction {
  type: typeof REQUEST_SEGMENT_NAME_UPDATE,
}
interface IReceiveSegmentNameUpdateAction {
  type: typeof RECEIVE_SEGMENT_NAME_UPDATE_RESPONSE,
  data: Segment
}
interface ISelectSegmentAction {
  type: typeof SELECT_SEGMENT,
  selectedSegments: any
}
interface IRequestNewSegmentAction {
  type: typeof REQUEST_NEW_SEGMENT,
}
interface IReceiveNewSegmentAction {
  type: typeof RECEIVE_NEW_SEGMENT_RESPONSE,
  segments: Segment[],
}
interface IRequestRemoveUsersFromSegmentAction {
  type: typeof REQUEST_REMOVE_USERS_FROM_SEGMENT,
}
interface IRemoveUsersFromSegmentResponseAction {
  type: typeof REMOVE_USERS_FROM_SEGMENT_RESPONSE,
  segmentId: string,
  userIds: string[],
  success: boolean,
}
interface IAddUsersToSegmentAction {
  type: typeof REQUEST_ADD_USERS_TO_SEGMENT,
}
interface IAddUsersToSegmentResponseAction {
  type: typeof ADD_USERS_TO_SEGMENT_RESPONSE,
  segmentId: string,
  userIds: string[],
  success: boolean,
}
interface IFilterUsersAction {
  type: typeof REQUEST_FILTER_USERS,
  filters: UserFilter[],
  conjunction: "and" | "or"
}
interface IFilterUsersResponseAction {
  type: typeof REQUEST_FILTER_USERS_RESPONSE,
  users: User[],
  totalUsers: number,
}

interface ICreateSegmentFromFiltersAction {
  type: typeof REQUEST_SEGMENT_FROM_FILTERS,
  filters: UserFilter[],
  conjunction: "and" | "or"
}
interface ICreateSegmentFromFiltersResponseAction {
  type: typeof REQUEST_SEGMENT_FROM_FILTERS_RESPONSE,
  numUsers: number,
}

export type SegmentActionTypes = IRequestSegmentsAction | IReceiveSegmentsAction | ISelectSegmentAction
    | IRequestNewSegmentAction | IRequestRemoveUsersFromSegmentAction | IRemoveUsersFromSegmentResponseAction
    | IRequestSegmentNameUpdateAction | IReceiveSegmentNameUpdateAction | IReceiveNewSegmentAction
    | IAddUsersToSegmentAction | IAddUsersToSegmentResponseAction 
    | IFilterUsersAction | IFilterUsersResponseAction;

export function requestFilteredUsers(filters:UserFilter[], conjunction:"and"|"or"):IFilterUsersAction {
  return {
    type: 'REQUEST_FILTER_USERS',
    filters,
    conjunction,
  }
}

export function receivedFilteredUsers(users: User[], totalUsers:number):IFilterUsersResponseAction {
  return {
    type: 'REQUEST_FILTER_USERS_RESPONSE',
    users,
    totalUsers,
  }
}

export function requestSegmentFromFilters(filters:UserFilter[], conjunction:"and"|"or"): ICreateSegmentFromFiltersAction {
  return {
    type: 'REQUEST_SEGMENT_FROM_FILTERS',
    filters,
    conjunction,
  }
}

export function requestSegmentFromFiltersResponse(numUsers: number):ICreateSegmentFromFiltersResponseAction {
  return {
    type: 'REQUEST_SEGMENT_FROM_FILTERS_RESPONSE',
    numUsers,
  }
}

export function requestSegmentsAction():IRequestSegmentsAction {
  return {
    type: 'REQUEST_SEGMENTS'
  }
}

export function receiveSegments(data: any):IReceiveSegmentsAction {
  return {
    type: 'RECEIVE_SEGMENTS',
    data
  }
}

export function removeUsersFromSegmentResponseAction(segmentId: string, userIds: string[], success: boolean):IRemoveUsersFromSegmentResponseAction {
  return {
    type: 'REMOVE_USERS_FROM_SEGMENT_RESPONSE',
    segmentId,
    userIds,
    success,
  }
}

export function requestRemoveUsersFromSegmentAction():IRequestRemoveUsersFromSegmentAction {
  return {
    type: 'REQUEST_REMOVE_USERS_FROM_SEGMENT'
  }
}

export function addUsersToSegmentResponseAction(segmentId: string, userIds: string[], success: boolean):IAddUsersToSegmentResponseAction {
  return {
    type: 'ADD_USERS_TO_SEGMENT_RESPONSE',
    segmentId,
    userIds,
    success,
  }
}

export function addUsersToSegmentAction():IAddUsersToSegmentAction {
  return {
    type: 'REQUEST_ADD_USERS_TO_SEGMENT'
  }
}

export function requestSegmentNameUpdateAction():IRequestSegmentNameUpdateAction {
  return {
    type: 'REQUEST_SEGMENT_NAME_UPDATE'
  }
}

export function updateSegmentNameResponseAction(data: Segment):IReceiveSegmentNameUpdateAction {
  return {
    type: 'RECEIVE_SEGMENT_NAME_UPDATE_RESPONSE',
    data
  }
}


export function requestNewSegmentAction():IRequestNewSegmentAction {
  return {
    type: 'REQUEST_NEW_SEGMENT'
  }
}

export function receiveNewSegmentResponseAction(segments: Segment[]):IReceiveNewSegmentAction {
  return {
    type: 'RECEIVE_NEW_SEGMENT_RESPONSE',
    segments,
  }
}


export function submitRequestSegmentFromFiltersAction(filters:UserFilter[], conjunction:"and"|"or", name:string) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestSegmentFromFilters(filters, conjunction));
    return postCreateSegmentFromFiltersPromise(dispatch, filters, conjunction, name)
  }
}



export function submitRequestFilteredUsersAction(filters:UserFilter[], conjunction:"and"|"or", limit:number, offset:number) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestFilteredUsers(filters, conjunction));
    return postFilteredUsersPromise(dispatch, filters, conjunction, limit, offset)
  }
}


export function submitRemoveUsesrsFromSegmentAction(id:string, userIds:string[]) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestRemoveUsersFromSegmentAction);
    return postRemoveUsersFromSegmentPromise(dispatch, id, userIds)
  }
}

export function submitAddUsersToSegmentAction(id:string, userIds:string[]) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(addUsersToSegmentAction);
    return postAddUsersToSegmentPromise(dispatch, id, userIds)
  }
}


export function submitSegmentAction(id:string, segmentName:string, userIds:string[], onSuccess?: () => void) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNewSegmentAction());
    return postSegmentPromise(dispatch, id, segmentName, userIds, onSuccess)
  }
}

export function submitSegmentNameUpdateAction(id:string, segmentName:string) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestSegmentNameUpdateAction());
    return patchSegmentUpdateNamePromise(dispatch, id, segmentName)
  }
}

export function fetchSegments(ids?: string[], names?: string[], limit?:number, offset?:number, sortColumns?:string[], enabledBool?: boolean) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestSegmentsAction);
    return fetchSegmentsPromise(dispatch, ids, names, limit, offset, sortColumns, enabledBool)
  }
}


function postCreateSegmentFromFiltersPromise(dispatch:(...args:any)=>any, originalFilters:UserFilter[], conjunction:"and"|"or", name:string) {

  const filters = translateFilters(originalFilters)

  //Get sender segments from api endpoint
  const url = getGatewayUrl("segmentwrapper") + "/segments/create_from_filters";
  const data:CreateSegmentFromFiltersPayload = {
    conjunction,
    user_filters: filters,
    name,
  }

  console.log(url);
  fetch(url, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    },
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .then((json:CreateSegmentFromFiltersResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_SEGMENT_FROM_FILTERS));
      showToast(<>Couldn't create segment from filters: {errorsToUL(json.errors)}</>, "error");
    } else if(json.num_users) {
      showToast("Successfully enqueued segment creation for " + name + " w/" + json.num_users.toString() + " contacts", "success");
      dispatch(requestSegmentFromFiltersResponse(json.num_users))
    } else {
      dispatch(requestSegmentFromFiltersResponse(0));
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_SEGMENT_FROM_FILTERS));
    showToast(`Couldn't create segment ${name||''} from filters: ${denastifyError(e.message)}`, "error");
  });
}

function postFilteredUsersPromise(dispatch:(...args:any)=>any, originalFilters:UserFilter[], conjunction:"and"|"or",
  limit:number, offset:number) {

  const filters = translateFilters(originalFilters)

  //Get sender segments from api endpoint
  const url = getGatewayUrl("segment") + "/segments/filter_users";
  const data:FilterUsersPreviewPayload = {
    conjunction,
    user_filters: filters,
    limit,
    offset,
  }

  console.log(url);
  fetch(url, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    },
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .then((json:FilterUsersPreviewResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_FILTER_USERS));
      showToast(<>Couldn't filter users: {errorsToUL(json.errors)}</>, "error");
    }
    if(json.users && json.users.length) {
      console.log("Found " + json.users.length + " filtered users");
      dispatch(receivedFilteredUsers(json.users, json.total_users))
    } else {
      dispatch(receivedFilteredUsers(null, 0));
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_FILTER_USERS));
    showToast(`Couldn't fetch filtered users preview: ${denastifyError(e.message)}`, "error");
  });
}

function fetchSegmentsPromise(dispatch:(...args:any)=>any, ids: string[], names: string[], limit?:number, offset?:number, sortColumns?:string[], enabledBool?: boolean) {
  dispatch(requestSegmentsAction());

  //Get sender segments from api endpoint
  const url = getGatewayUrl("segment") + "/segments";
  const queryParts = []
  if(ids && ids.length > 0 && ids.join) {
    queryParts.push("ids=" + ids.join(","))
  }
  if(names && names.length > 0 && names.join) {
    const betterNames = names.map(n => encodeURIComponent(n))
    queryParts.push("names=" + betterNames.join(","))
  }
  if(sortColumns && sortColumns.length > 0 && sortColumns.join) {
    const betterNames = sortColumns.map(n => encodeURIComponent(n))
    queryParts.push("sort_columns=" + betterNames.join(","))
  }
  if(limit) {
    queryParts.push("limit=" + limit.toString())
  }
  if(offset) {
    queryParts.push("offset=" + limit.toString())
  }
  if(enabledBool === true || enabledBool === false) {
    queryParts.push("enabled_only=" + (enabledBool ? "true" : "false"))
  }
  const queryString = "?" + queryParts.join("&")

  console.log(url + queryString);
  fetch(url + queryString, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetSegmentsResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_SEGMENTS));
      showToast(<>Couldn't fetch segments: {errorsToUL(json.errors)}</>, "error");
    }
    if(json.data && json.data.length) {
      console.log("Found " + json.data.length + " segments");
      dispatch(receiveSegments(json.data))
    } else {
      dispatch(receiveSegments(null));
    }
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_SEGMENTS));
    showToast(`Couldn't fetch segments: ${denastifyError(e.message)}`, "error");
  });
}


function patchSegmentUpdateNamePromise(dispatch:(...args:any)=>any, id:string, segmentName:string) {
  const url = getGatewayUrl("segment") + "/segments/" + id;
  console.log("POSTING TO URL",url);
  const data:UpdateSegmentNamePayload = {
    name: segmentName
  };
  const options:any =  {
    method: 'PATCH',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  };
  fetch(url, options)
      .then(res => {
        if(res.status === 404) {
          throw new Error(`Couldn't find segment with id ${id} to update`)
        }
        return res.json()
      })
      .then((json:UpdateSegmentNameResponsePayload) => {
        if(json.errors && json.errors.length > 0) {
          showToast(<>Couldn't save segment name: {errorsToUL(json.errors)}</>, "error");
        } else {
          dispatch(updateSegmentNameResponseAction(json.data))
          showToast("Successfully saved segment name", "success");
        }
      }).catch(e => {
    showToast(`Couldn't save segment name: ${denastifyError(e.message)}`, "error");
  });
}


function postSegmentPromise(dispatch:(...args:any)=>any, id:string, segmentName:string, userIds: string[], onSuccess?: () => void) {
  const url = getGatewayUrl("segment") + "/segments";
  console.log("POSTING TO URL",url);
  const data:UpsertSegmentsPayload = {
    segments: [{
        id,
        name: segmentName,
        user_ids: userIds,
        num_users: userIds? userIds.length : 0,
    }]
  };
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:UpsertSegmentsResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      showToast(<>Couldn't save segment: {errorsToUL(json.errors)}</>, "error");
    } else {
      showToast("Successfully saved segment", "success");
      dispatch(receiveNewSegmentResponseAction(json.data))
      if(onSuccess) {
        onSuccess()
      }
    }
  }).catch(e => {
    showToast(`Couldn't save segment: ${denastifyError(e.message)}`, "error");
  });
}


function postRemoveUsersFromSegmentPromise(dispatch:(...args:any)=>any, id:string, userIds: string[]) {
  ((id: string, userIds:string[]) => {
    const url = getGatewayUrl("segment") + "/segments/" + id + "/users/" + userIds.join(",");
    console.log("POSTING TO URL",url);
    const options:any =  {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    };
    fetch(url, options)
        .then(res => {
          console.log(res.status, res.statusText);
          return res;
        })
        .then(res => res.json())
        .then((json:RemoveUsersFromSegmentResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(removeUsersFromSegmentResponseAction(id, userIds, false));
            showToast(<>Couldn't remove users from segment: {errorsToUL(json.errors)}</>, "error");
          } else {
            dispatch(removeUsersFromSegmentResponseAction(id, userIds, true));
            showToast("Successfully removed users from segment", "success", {autoClose:1000});
          }
        }).catch(e => {
          dispatch(removeUsersFromSegmentResponseAction(id, userIds, false));
          showToast(`Couldn't remove users from segment: ${denastifyError(e.message)}`, "error");
        });
  })(id, userIds);
}


function postAddUsersToSegmentPromise(dispatch:(...args:any)=>any, id:string, userIds: string[]) {
  ((id: string, userIds:string[]) => {
    const url = getGatewayUrl("segment") + "/segments/" + id + "/users";
    console.log("POSTING TO URL",url);
    const data:AddUsersToSegmentPayload = {
      user_ids: userIds,
      emails: [],
    };
    const options:any =  {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data),
    };
    fetch(url, options)
        .then(res => {
          console.log(res.status, res.statusText);
          return res;
        })
        .then(res => res.json())
        .then((json:AddUsersToSegmentResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            dispatch(addUsersToSegmentResponseAction(id, userIds, false));
            showToast(<>Couldn't add users to segment: {errorsToUL(json.errors)}</>, "error");
          } else {
            dispatch(addUsersToSegmentResponseAction(id, userIds, true));
            const usersText = userIds.length > 1 ? "users" : "user"
            showToast("Successfully added " + userIds.length + " " + usersText + " to segment", "success", {autoClose:1000});
          }
        }).catch(e => {
          dispatch(addUsersToSegmentResponseAction(id, userIds, false));
          showToast(`Couldn't add users to segment: ${denastifyError(e.message)}`, "error");
        });
  })(id, userIds);
}


