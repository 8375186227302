import React from 'react';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { User, SenderPersona, Event } from "./generated/types/payloadTypes";
import moment from "moment-timezone";

export const defaultUser: User = {
    id: "",
        first_name: "Dave",
        last_name: "FromSwiftMissive",
        email: "dave@swiftmissive.com",
        date_of_birth: moment(),
        unsubscribed_at: moment(),
        created_at: moment(),
        timezone: moment.tz.guess(),
        json: { favorite_animal: "zebra"},
};
export const defaultEvent: Event = {
    id: "",
    name: "sale",
    email: "dave@swiftmissive.com",
    user_id: "",
    emitted_at: moment(),
    created_at: moment(),
    metric1: 100,
    metric2: 0,
    metric3: 0,
    json: { "product_name": "Item1", "quantity": 5, "cost_per_unit":20},
};
export const defaultSenderPersona:SenderPersona =  {
    id: "",
    first_name: "Sender",
    last_name: "McSenderson",
    email: "demosender@swiftmissive.com",
    created_at:  moment(),
    updated_at:  moment(),
    version: "",
    json: { "favorite_animal": "elephant" },
}

export default function TemplateVariables(props: any) :JSX.Element {
    return <div {...props}>
        <Typography variant={"body1"} component={"p"}>
            Variables available in ALL emails:
        </Typography>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width:"100px", paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Entity</TableCell>
                    <TableCell style={{width:"120px",paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Field</TableCell>
                    <TableCell style={{width:"245px",paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Usage</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Example Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(defaultUser).map((k:string) => k === "id" || k === "created_at" ? null : <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>User</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{k}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{user."+k+"}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        {JSON.stringify(JSON.parse(JSON.stringify(defaultUser))[k]).replace(/^"(.+(?="$))"$/, '$1')}
                    </TableCell>
                </TableRow>)}
                <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>User</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>json.favorite_animal</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{user.json.favorite_animal}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        {defaultUser.json.favorite_animal}
                    </TableCell>
                </TableRow>
                {Object.keys(defaultSenderPersona).map((k:string) => k === "id" || k === "created_at" || k === "updated_at" || k === "version" ? null : <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>Sender Persona</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{k}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{sender_persona."+k+"}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        {JSON.stringify(JSON.parse(JSON.stringify(defaultSenderPersona))[k]).replace(/^"(.+(?="$))"$/, '$1')}
                    </TableCell>
                </TableRow>)}
                <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>Sender Persona</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>json.favorite_animal</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{sender_persona.json.favorite_animal}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        {defaultSenderPersona.json.favorite_animal}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>Unsubscribe</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>link</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{unsubscribe_link}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        https://unsubscribe.swiftmissive.com/unsubscribe/eyJjbQ==
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
            Variables that are additionally available for event-triggered emails, e.g. those triggered by a sales event:
        </Typography>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width:"100px", paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Entity</TableCell>
                    <TableCell style={{width:"120px",paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Field</TableCell>
                    <TableCell style={{width:"245px",paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Usage</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px", fontWeight:"bold"}}>Example Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(defaultEvent).map((k:string) => k === "id" || k === "created_at" || k === "user_id" ? null : <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>Event</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{k}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{event."+k+"}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        {JSON.stringify(JSON.parse(JSON.stringify(defaultEvent))[k]).replace(/^"(.+(?="$))"$/, '$1')}
                    </TableCell>
                </TableRow>)}
                <TableRow>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>Event</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>json.product</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>{"{{event.json.product_name}}"}</TableCell>
                    <TableCell style={{paddingTop:"1px", paddingBottom:"1px"}}>
                        {defaultEvent.json.product_name}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>

    </div>
}