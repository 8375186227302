import React, {ChangeEvent} from 'react';
import {
    archiveTemplates,
    fetchTemplates,
    REQUEST_TEMPLATES,
    submitTemplateAction,
} from "./actions/Templates"
import {connect} from "react-redux";
import {
    Card,
    FormControl, FormHelperText, Grid, InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@material-ui/core";
import {Loading} from "./components/Loading";
import {Link} from "react-router-dom";
import {Add, Delete, Edit, Description, FileCopy} from "@material-ui/icons";
import {v4 as uuidv4} from "uuid";
import {Template} from "./generated/types/payloadTypes";
import {AllState} from "./reducers";
import {MyArchiveButton, MyCancelButton, MyModal} from "./Dialog";
import {defaultCardElevation,defaultCardStyles} from "./App";
import Fab from "@material-ui/core/Fab";

interface IState {
    selectedTemplateIds: string[],
    selectedTemplateId: string,
    id: string,
    upForDeletionTemplate: Template,
}

interface IProps {
    dispatch: any,
    type: string,
    fetchedTemplates?: {[key:string]: Template},
    receivingTemplates?: boolean,
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    onSelectedTemplateIdsChange? : (ids:string[]) => any
    selectedTemplateIds?: string[],
    selectedTemplateId?: string,
    label?:string,
    placeholder?: string,
    reportErrors?: {[key:string]:string},
    helperText?: string,
    style?: any,
    templatesListKey?: string,
    required?: boolean,
    width: string,
    disabled?: boolean,
}

class ListTemplates extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4(),
            selectedTemplateId: this.props.selectedTemplateId? this.props.selectedTemplateId: 
                this.props.placeholder?"placeholder": "",
            selectedTemplateIds: this.props.selectedTemplateIds || [],
            upForDeletionTemplate: null,
        };
        this.templateChange = this.templateChange.bind(this);
        this.archiveTemplate = this.archiveTemplate.bind(this);
    }

    archiveTemplate(id: string) {
        console.log(`Archiving template ${id}`);
        this.props.dispatch(archiveTemplates([id]));
    }


    templateChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedTemplateId: "",
                selectedTemplateIds: null,
            });
            return;
        }

        if(!this.props.fetchedTemplates) {
            return
        }
        
        const selectedTemplateId = event.target.value.toString()
        if(this.props.type == "select") {
            this.setState({
                ...this.state,
                selectedTemplateId,
            });
            if(this.props.onSelectedTemplateIdsChange) {
                this.props.onSelectedTemplateIdsChange([selectedTemplateId])
            }
        } else if(this.props.type == "multiselect") {
            const newIds = [
                ...this.state.selectedTemplateIds,
                selectedTemplateId
            ]
            this.setState({
                ...this.state,
                selectedTemplateIds: newIds,
            });
            
            if(this.props.onSelectedTemplateIdsChange) {
                this.props.onSelectedTemplateIdsChange(newIds)
            }
        } else {
            console.error("unknown template component type " + this.props.type)
        }

        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    componentDidMount(){
        this.props.dispatch(fetchTemplates())
    }

    
    truncateText(text: string, len = 35) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    nameColumnWidth(): string {
        switch(this.props.width) {
            case "xs":
                return "5rem"
            case "sm":
                return "7rem"
            case "md":
                return "8rem"
            default:
                return "10rem"
        }
    }

    subjectColumnWidth(): string {
        switch(this.props.width) {
            case "xs":
                return "5rem"
            case "sm":
                return "7rem"
            case "md":
                return "8rem"
            default:
                return "10rem"
        }
    }

    selectMenuItems(excludeSelected: boolean): JSX.Element[]  {
        if(!this.props.fetchedTemplates || Object.values(this.props.fetchedTemplates).length===0)     {
            return []
        }
        
        const options:JSX.Element[] = []
        Object.values(this.props.fetchedTemplates).map(template => {
            if(excludeSelected && (
                this.state.selectedTemplateIds.find(id => template.id == id)   
            )) {
                return
            }

            options.push(<MenuItem key={template.id} value={template.id}>{template.name} {template.subject}</MenuItem>)
        })

        return options
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(nextProps.fetchedTemplates) {
            const selectedTemplateIds = prevState.selectedTemplateIds;
            return {
                ...prevState,
                selectedTemplateIds,
            }
        }

        return prevState;
    }

    
    getTemplateSelect(excludeSelected: boolean) : JSX.Element {
        return <Select id={"templateSelect"+this.state.id} 
            key={"templateSelect"+this.state.id} 
            style={{width:"100%"}}
            value={this.state.selectedTemplateId} 
            required={!!this.props.required} 
            disabled={this.props.disabled}
            onChange={this.templateChange} 
            MenuProps={{ disableScrollLock: true }}>
                { this.props.placeholder && <MenuItem key="selectplaceholder" value="placeholder" disabled selected>{this.props.placeholder}</MenuItem>}
                { this.selectMenuItems(excludeSelected) }
        </Select>
    }
    
    getMultiSelectForm(excludeSelected:boolean) : JSX.Element {
        if(!this.props.fetchedTemplates) {
            return <></>
        }

        const selectedObjects: JSX.Element  = <>{this.state.selectedTemplateIds.map((id:string,index:number) => {
            const thisTemplate = this.props.fetchedTemplates[id]
            if(!thisTemplate) {
                console.error("Missing template " + id)
                return <></>
            }

            return <Grid container 
                    wrap="nowrap"  
                    key={"template" + id + index.toString()} 
                    alignItems={"flex-end"} 
                    style={{borderBottom:"1px dotted #ccc", margin:"0", padding:"0", width:"100%", minWidth:"200px"}}>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{minWidth:"30px"}}>
                    <Description />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={10} xl={10}>
                <Typography variant={"body1"} component={"p"} 
                        title={thisTemplate.name + " " + thisTemplate.subject}
                        style={{whiteSpace:"nowrap", width:"100%", textOverflow:"ellipsis", overflow:"hidden"}}>
                        <Link target="_blank" to={"/components/templates/edit/"+thisTemplate.id}>
                        {thisTemplate.name}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={1} xl={1} className='clearfix'>
                    <Link to={""} style={{float:"right"}} onClick={(ev) => {
                         ev.stopPropagation()
                         ev.preventDefault()

                         if(this.props.disabled) {
                            return
                         }
 
                         const myTemplateIds = []
                         for(let i = 0; i < this.state.selectedTemplateIds.length; i++) {
                             if(thisTemplate.id != this.state.selectedTemplateIds[i]) {
                                myTemplateIds.push(this.state.selectedTemplateIds[i])
                             }
                         }
 
                         this.setState({
                             ...this.state,
                             selectedTemplateIds: myTemplateIds,
                         })
                        
                         if(this.props.onSelectedTemplateIdsChange) {
                            this.props.onSelectedTemplateIdsChange(myTemplateIds)
                         }
                    }}><Delete /></Link>
                </Grid>
            </Grid>
        })}</>

        return <><div style={{marginBottom:"1rem"}}>
            {this.getTemplateSelect(excludeSelected)}
        </div>
        <div>
            {selectedObjects}
        </div></>
    }

    render() {
        return <div className={'template-list'}>
            { this.props.type === 'list' &&
            <Card style={{...defaultCardStyles, ...this.props.style}} elevation={defaultCardElevation}>
                <Grid container>
                    <Grid item> 
                        <Typography variant={"h1"} component={"div"}>Templates</Typography>
                    </Grid>
                    <Grid item style={{marginLeft:"10px"}}>
                        <Link to={"/components/templates/new"} title={"Create a new template"}>
                            <Fab size={"small"} color={"primary"} aria-label={"Create a new template"}>
                                <Add />
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>
                <hr/>

                {/* Errors */ }
                {this.props.reportErrors[REQUEST_TEMPLATES] && <p className={"error-text"}>
                    Unable to load templates: {this.props.reportErrors[REQUEST_TEMPLATES]}
                </p>}

                {/* Loading */}
                {!this.props.reportErrors[REQUEST_TEMPLATES] && this.props.receivingTemplates && <div style={{minHeight:"300px", paddingTop:"2rem"}}><Loading /></div>}

                {/* Confirmation dialogs */}
                {this.state.upForDeletionTemplate && <MyModal title={"Archive template?"}
                    content={<Typography variant={"body1"} component={"p"} style={{marginBottom:"15px"}}>
                               Are you sure you want to archive {this.state.upForDeletionTemplate.name?
                                <>"<i>{this.state.upForDeletionTemplate.name}</i>?"</>:<>this template?</>}
                           </Typography>}
                    buttons={<>
                       <MyCancelButton onClick={(event:any) => {
                           event.stopPropagation();
                           event.preventDefault();
                           this.setState({
                               ...this.state,
                               upForDeletionTemplate: null,
                           });
                       }}/>
                       <MyArchiveButton onClick={(event:any) => {
                           event.stopPropagation();
                           event.preventDefault();

                           this.archiveTemplate(this.state.upForDeletionTemplate.id);
                           this.setState({
                               ...this.state,
                               upForDeletionTemplate: null,
                           });
                       }}/>
                    </>}
                />}

                {/* Main table */}
                {!this.props.reportErrors[REQUEST_TEMPLATES] && !this.props.receivingTemplates &&
                <TableContainer key={this.props.templatesListKey}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width:"50px"}} />
                                <TableCell component={"th"} style={{maxWidth:this.nameColumnWidth()}}>Name</TableCell>
                                <TableCell component={"th"}>Type</TableCell>
                                <TableCell component={"th"}>Subject</TableCell>
                                <TableCell component={"th"}>Updated</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!this.props.fetchedTemplates || Object.values(this.props.fetchedTemplates).length === 0) && <TableRow><TableCell colSpan={4}>Nothing here, yet</TableCell></TableRow> }
                            {this.props.fetchedTemplates && Object.values(this.props.fetchedTemplates).sort((a,b) => a.created_at && a.created_at < b.created_at ? 1 : -1).map(template => (
                                <TableRow key={template.id}>
                                    <TableCell style={{width:"50px", whiteSpace:"nowrap"}}>
                                        <Link to={"/components/templates/edit/" + template.id} className="svgShadow">
                                            <Edit />
                                        </Link>
                                        <Link to={"/components/templates#copy" + template.id} className="svgShadow" onClick={(event:any) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.props.dispatch(submitTemplateAction(
                                                uuidv4(),
                                                "Copy of " + template.name,
                                                template.type,
                                                template.subject,
                                                template.html_body,
                                                template.html_body,
                                                template.json,
                                                true));
                                        }}>
                                            <FileCopy />
                                        </Link>
                                        <Link to={"/components/templates#delete" + template.id} className="svgShadow" onClick={(event:any) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.setState({
                                                ...this.state,
                                                upForDeletionTemplate: template,
                                            });
                                        }}>
                                            <Delete />
                                        </Link>
                                        {/* <Grid container>
                                            <Grid item>
                                                
                                            </Grid>
                                            <Grid>
                                                
                                            </Grid>
                                        </Grid> */}
                                    </TableCell>
                                    <TableCell style={{maxWidth:this.nameColumnWidth()}}>
                                        <span title={template.name} style={{
                                        textOverflow:"ellipsis",
                                        overflow:"hidden",
                                        display: "inline-block",
                                        width: "100%",
                                        wordWrap:"break-word",
                                    }}>{template.name}</span></TableCell>
                                    <TableCell>{template.type === "TEMPLATE_MARKETING" ? "Marketing" : "Transactional"}</TableCell>
                                    <TableCell  style={{maxWidth:this.subjectColumnWidth()}}><span style={{
                                        textOverflow:"ellipsis",
                                        overflow:"hidden",
                                        display: "inline-block",
                                        width: "100%",
                                        wordWrap:"break-word",
                                    }} title={template.subject}>{template.subject}</span></TableCell>
                                    <TableCell>{new Date(template.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>}
            { this.props.type === 'select' && <>
                {this.props.receivingTemplates && <Loading mySize={"sm"} />}
                {!this.props.receivingTemplates && <FormControl style={this.props.style} error={!!this.props.reportErrors[REQUEST_TEMPLATES] || !!this.props.helperText}>
                    {this.props.label && <InputLabel id={"templateSelect"+this.state.id} required={!!this.props.required}>{this.props.label}</InputLabel>}
                        {this.getTemplateSelect(false)}
                    <FormHelperText required={!!this.props.required}>{this.props.reportErrors[REQUEST_TEMPLATES] || this.props.helperText || ""}</FormHelperText>
                </FormControl>}
            </>
            }
            { this.props.type === 'multiselect' && <>
                {this.props.receivingTemplates && <Loading mySize={"sm"} />}
                {!this.props.receivingTemplates && this.getMultiSelectForm(true)}
            </>
            }
            </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    let fetchedTemplates = null;
    if(state.templates && Object.keys(state.templates).length > 0) {
        fetchedTemplates = state.templates;
    }

    return { ...state, fetchedTemplates };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListTemplates)
