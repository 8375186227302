import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  AddDomainPayload, AddDomainResponsePayload,
  GetDKIMInfoResponsePayload,
} from "../generated/types/payloadTypes";
import {getAuthInfo, getAuthKey} from "./Auth";

export const REQUEST_DKIM_SETTINGS = 'REQUEST_DKIM_SETTINGS';
export const RECEIVE_DKIM_SETTINGS = 'RECEIVE_DKIM_SETTINGS';
export const REQUEST_DOMAIN_ADDITION = 'REQUEST_DOMAIN_ADDITION';
export const REQUEST_DOMAIN_ADDITION_RESPONSE = 'REQUEST_DOMAIN_ADDITION_RESPONSE';
interface IRequestDKIMSettings {
  type: typeof REQUEST_DKIM_SETTINGS,
}
interface IReceiveDKIMSettings {
  type: typeof RECEIVE_DKIM_SETTINGS,
  getDKIMPayload: GetDKIMInfoResponsePayload,
}
interface IRequestDomainAddition {
  type: typeof REQUEST_DOMAIN_ADDITION,
}
interface IRequestDomainAdditionResponse {
  type: typeof REQUEST_DOMAIN_ADDITION_RESPONSE,
  domains: string[],
}
export type SettingsActionsTypes = IRequestDKIMSettings | IReceiveDKIMSettings | IRequestDomainAddition | IRequestDomainAdditionResponse;

export function requestDomainAdditionAction():IRequestDomainAddition {
  return {
    type: 'REQUEST_DOMAIN_ADDITION',
  }
}
export function requestDomainAdditionResponseAction(domains: string[]):IRequestDomainAdditionResponse {
  return {
    type: 'REQUEST_DOMAIN_ADDITION_RESPONSE',
    domains,
  }
}

export function requestDKIMSettingsAction():IRequestDKIMSettings {
  return {
    type: 'REQUEST_DKIM_SETTINGS',
  }
}
export function receiveDKIMSettingsAction(getDKIMPayload: GetDKIMInfoResponsePayload):IReceiveDKIMSettings {
  return {
    type: 'RECEIVE_DKIM_SETTINGS',
    getDKIMPayload,
  }
}

export function fetchDKIMSettings()  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestDKIMSettingsAction());
    return fetchDKIMSettingsPromise(dispatch);
  }
}


function fetchDKIMSettingsPromise(dispatch:(...args:any)=>any) {
  console.log("Fetching dkim settings");

  //Get sender users from api endpoint
  const authInfo = getAuthInfo();
  if(!authInfo || !authInfo.company || !authInfo.project) {
    showToast("Couldn't get dkim info, please try logging out and back in again", "error");
    return
  }

  const url = process.env.REACT_APP_API_SETTINGS_WRAPPER_GATEWAY_URL + `/dkim?company_name=${authInfo.company.name}&project_name=${authInfo.project.name}`;
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetDKIMInfoResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_DKIM_SETTINGS));
      showToast(<>Couldn't fetch dkim settings: {errorsToUL(json.errors)}</>, "error");
    }

    if(json) {
      dispatch(receiveDKIMSettingsAction(json))
    } else {
      dispatch(receiveDKIMSettingsAction(null))
    }

  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_DKIM_SETTINGS));
    showToast(`Couldn't fetch dkim settings: ${denastifyError(e.message)}`, "error");
  });
}

export function addDomainAction(domain:string) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestDomainAdditionAction);
    return postAddDomainPromise(dispatch, domain)
  }
}


function postAddDomainPromise(dispatch:(...args:any)=>any,domain:string) {

  //Get sender users from api endpoint
  const authInfo = getAuthInfo();
  if(!authInfo || !authInfo.company || !authInfo.project) {
    showToast("Couldn't get dkim info, please try logging out and back in again", "error");
    return
  }

  //Get sender users from api endpoint
  const url = process.env.REACT_APP_API_SETTINGS_WRAPPER_GATEWAY_URL + "/domains";
  console.log("POSTING TO URL",url);

  const data:AddDomainPayload = {
    domain,
    company_name: authInfo.company.name,
    project_name: authInfo.project.name,
  };
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:AddDomainResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      showToast(<>Couldn't add domain: {errorsToUL(json.errors)}</>, "error");
    } else {
      showToast("Successfully added domain", "success");

      //Go ahead and refetch dkim settings for the settings page
      dispatch(fetchDKIMSettings());
    }
  }).catch(e => {
    showToast(`Couldn't add domain: ${denastifyError(e.message)}`, "error");
  });
}
