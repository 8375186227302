import React from "react";
import {AllState} from "../reducers";
import {connect} from "react-redux";
import {
Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withWidth
} from "@material-ui/core";
import { fetchMostOpenedEmailsReportDataAction, fetchRecentlyOpenedEmailsReportDataAction } from "../actions/Reports";
import { Loading } from "../components/Loading";
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import moment from "moment";

interface IProps {
    dispatch: any,
    reportData?: {[key:string]:any[]},
    receivingReportData?: {[key:string]:boolean},
    campaignId?: string, 
}
interface IState {
    id:string,
}

class OpenStats extends React.Component<IProps, IState> {
    props:IProps;
    state:IState;

    constructor(props:IProps) {
        super(props)
        this.props = props;
        this.state = {
            id: uuidv4(),
        }
    }

    componentDidMount(){
        this.props.dispatch(fetchRecentlyOpenedEmailsReportDataAction(this.state.id, this.props.campaignId, 10, 0))
    }
    
    render() {
        const myTimezone = moment.tz.guess()
        return <div>
            <Typography component="div" variant="h2" style={{marginBottom:"0"}}>
                Recently opened templates
            </Typography>
            {(!this.props.receivingReportData[this.state.id] && !this.props.reportData[this.state.id]) ? <>
                <div style={{marginTop:"1rem"}}>No emails have been opened for the given filters</div>  
            </> : <>
                {this.props.receivingReportData[this.state.id] ? <Loading /> :
            <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                        <Typography variant="body1">
                            Subject
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1">
                            Email
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1">
                            Opened at
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.reportData[this.state.id] && this.props.reportData[this.state.id].map((openData, i) => <TableRow key={"row_" + i}>
                        <TableCell><Typography variant="body1">
                            {openData.template_id? <Link target="_blank" to={"/components/templates/edit/" + openData.template_id}>{openData.email_subject}</Link>  :
                            <>{openData.email_subject}</>}
                        </Typography></TableCell>  
                        <TableCell><Typography variant="body1">
                            { openData.user_id ? <Link target="_blank" to={"/people/users/edit/" + openData.user_id}>
                                {openData.email}
                            </Link> : <>{openData.email}</> } 
                        </Typography></TableCell>
                        <TableCell style={{minWidth:"190px"}}><Typography variant="body1">
                            { openData.emitted_at ? <>
                                {new Date(openData.emitted_at).toLocaleString()}
                                {myTimezone ? <><br />TZ: {myTimezone}</>:<></>}</> : <></> }
                        </Typography></TableCell>   
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
            }</>}
        </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state;
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(withWidth()(OpenStats))
