import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk'
import reducer from "./../reducers/index"
import {composeWithDevTools} from "redux-devtools-extension";

export const store:any = createStore(
  reducer,
  composeWithDevTools(
      applyMiddleware(thunkMiddleware),
      // other store enhancers if any
  )
);
