import React from 'react';
import {connect} from "react-redux";
import {AllState} from "../reducers";
import {Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@material-ui/core";
import {Link} from 'react-router-dom'
import { FileCopy } from '@material-ui/icons';
import {MyModal, MyOkButton} from "../Dialog"
import {SystemEvent, Event} from "../generated/types/payloadTypes"
import SyntaxHighlighter from 'react-syntax-highlighter'
import { githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { redactSensitiveInfoForDisplay } from '../Common';

interface IState {
    anchorEl?: any,
    rawEventDetails: boolean,
    copiedRawDetails: boolean,
}

interface IProps {
    dispatch: any,
    detailedEvent: SystemEvent | Event,
    okButtonHook: (event:any) => void,
    reportData?: {[key:string]:any[]},
    errors?: {[key:string]:any[]},
    troubleReasonsId?: string,
}

class EventDetailsModal extends React.Component<IProps, IState> {
    props: IProps;
    state: IState;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            rawEventDetails: false,
            copiedRawDetails: false,
        }
    }

    eventDetails(e: SystemEvent|Event): JSX.Element {
        if(typeof e.json == "string" ) {
            e.json = JSON.parse(e.json)
        }

        let contents = <></>
        if(!this.state.rawEventDetails) {
            contents = this.eventDetailsToGrid(e)
        } else {
            contents = this.eventDetailsRaw(e)
        }
        
        return <div style={{
            overflow:"auto",
            marginBottom:"1rem",
            minWidth:"400px", 
            minHeight:"200px", 
            maxHeight:"400px"}}>
            {contents}
        </div>
    }

    eventDetailsRaw(e: SystemEvent|Event): JSX.Element {
        let obj = e
        if(typeof e.json == "string") {
            obj = JSON.parse(e.json)
        } 
        obj = redactSensitiveInfoForDisplay(obj)

        return <SyntaxHighlighter language="json" style={githubGist} customStyle={{maxHeight:"360px",overflow:"auto"}}>
            {JSON.stringify(obj, null, 2)}
        </SyntaxHighlighter>
    }

    eventDetailsToGrid(e: SystemEvent|Event): JSX.Element {
        const additionalRows: JSX.Element[] = []
        const additionalRowsBefore: JSX.Element[] = []

        if(e.name) {
            additionalRowsBefore.push(<TableRow key={"eventname"}>
                <TableCell></TableCell><TableCell>event_name</TableCell>
                <TableCell>{e.name}</TableCell>
            </TableRow>)
        }

        if(e.json?.bounce) {
            if(e.json?.bounce?.bounceType) {
                additionalRowsBefore.push(<TableRow key={"eventbouncetype"}>
                    <TableCell></TableCell><TableCell>bounce_type</TableCell><TableCell>{e.json.bounce.bounceType}</TableCell>
                </TableRow>)
            }
        }

        if(e.json?.click) {
            if(e.json?.click?.link) {
                additionalRowsBefore.push(<TableRow key={"eventclicklink"}>
                    <TableCell></TableCell><TableCell>click_link</TableCell><TableCell>{e.json.click.link}</TableCell>
                </TableRow>)
            }
        }

        if(e.email) {
            additionalRowsBefore.push(<TableRow key={"eventuser"}>
                <TableCell></TableCell><TableCell>email</TableCell>
                <TableCell>{e.user_id ? <Link target="_blank" to={"/people/users/edit/" + e.user_id}>{e.email}</Link> : e.email}</TableCell>
            </TableRow>)
        }

        if(e.json?.error_string) {
            additionalRows.push(<TableRow key={"eventerror"}>
                <TableCell></TableCell><TableCell>error</TableCell><TableCell>{e.json.error_string}</TableCell>
            </TableRow>)
        }
        if(e.json?.bucketing_error_string) {
            additionalRows.push(<TableRow key={"eventsaveerror"}>
                <TableCell></TableCell><TableCell>save_copy_error</TableCell><TableCell>{e.json.bucketing_error_string}</TableCell>
            </TableRow>)
        }
        if(e.json?.bounce) {
            if(e.json?.bounce?.bouncedRecipients && e.json?.bounce?.bouncedRecipients[0] && e.json?.bounce?.bouncedRecipients[0].diagnosticCode) {
                additionalRows.push(<TableRow key={"eventbouncediagnosticcode"}>
                    <TableCell></TableCell><TableCell>bounce_code</TableCell><TableCell>{e.json.bounce.bouncedRecipients[0].diagnosticCode}</TableCell>
                </TableRow>)
            }
        }
        if(e.emitted_at) {
            additionalRows.push(<TableRow key={"eventoccurred"}>
                <TableCell></TableCell><TableCell>occurred</TableCell><TableCell>
                    {e.emitted_at.toLocaleString()}
                </TableCell>
            </TableRow>)
        }

        return <TableContainer style={{width:"100%"}}>
            <Table cellSpacing={0} style={{width:"100%"}}>
            <TableBody>
                {additionalRowsBefore}
                {additionalRows}
            </TableBody>
            </Table>
        </TableContainer>
    }

    render() {
        return <MyModal title={<Typography variant="h2" component="p">Event details</Typography>}
            style={{        
                maxWidth:"800px",
                width:"80%",
                minWidth:"400px",
            }}
            anchorEl={this.state.anchorEl?this.state.anchorEl:null}
            content={this.eventDetails(this.props.detailedEvent)}
            buttons={<>
                    {this.state.rawEventDetails && <><Button color="primary" 
                    startIcon={<FileCopy />}
                    variant="contained" 
                    onClick={() => {
                    navigator.clipboard.writeText(
                        typeof this.props.detailedEvent.json == "string"? this.props.detailedEvent.json : JSON.stringify(this.props.detailedEvent.json, null, 2))
                    this.setState({
                        ...this.state,
                        copiedRawDetails: true,
                    })
                    }}>
                        Copy
                </Button>
                {this.state.copiedRawDetails && <Typography variant="body1" component={"span"} style={{color:"green", marginLeft:"0.5rem"}}>Copied!</Typography>}
                </>}

                <MyOkButton onClick={(event:any) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                        ...this.state,
                        copiedRawDetails: false,
                    });
                    this.props.okButtonHook(event)
                }}/>
                {!this.state.rawEventDetails && <Button 
                    variant={"contained"} 
                    color="primary"
                    onClick={() => this.setState({...this.state, rawEventDetails: true})}
                    style={{float:"right", marginRight:"0.5rem"}}
                >
                    View raw event
                </Button>}
                {this.state.rawEventDetails && <Button 
                    variant={"contained"} 
                    color="primary" 
                    onClick={() => this.setState({...this.state, rawEventDetails: false, copiedRawDetails:false})}
                    style={{float:"right", marginRight:"0.5rem"}}
                >
                    View parsed event
                </Button>}
                
            </>}
        />  
    }
}



function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {
        reportData: { ...state.reportData }, //Create copy of report data
        errors: { ...state.reportErrors }, //Create copy of error data
    }
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(EventDetailsModal)

