import {Point, ResponsiveLine} from "@nivo/line";
import React, {ChangeEvent, Key} from "react";
import {AllState} from "../reducers";
import {connect} from "react-redux";
import {MailOutline, People} from "@material-ui/icons";
import {
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    withWidth
} from "@material-ui/core";
import {costPerEmail,monthlyFee,costPerEmail2,monthlyFee2,costPerEmail3,monthlyFee3,maxFreeEmails} from "../PagePricing"

//Goes on About page of main site
interface IProps {
    width?: string,
}
interface IState {
    numContacts: number,
    numEmailsPerContact: number,
    columnsSelected: {[key:string]:boolean},
}

export const priceFunc = (numContacts:number, numEmailsPerContact: number) : number => {
    let chargedSends = (numContacts*numEmailsPerContact)-maxFreeEmails 
    if(chargedSends < 0) {
        chargedSends = 0
    }
    const cost1 = monthlyFee + costPerEmail * chargedSends;
    const cost2 = monthlyFee2 + costPerEmail2 * chargedSends;
    const cost3 = monthlyFee3 + costPerEmail3 * chargedSends;
    
    if(cost1 <= cost2 && cost1 <= cost3) {
        return cost1;
    }
    if(cost2 <= cost1 && cost2 <= cost3) {
        return cost2
    }

    return cost3;
};

const sendInBluePriceFunc = (numSends:number, numEmailsPerContact: number) => {
    if(numSends * numEmailsPerContact <= 10000) {
        return 25;
    }
    if(numSends * numEmailsPerContact <= 20000) {
        return 39;
    }
    if(numSends * numEmailsPerContact <= 40000) {
        return 54;
    }
    if(numSends * numEmailsPerContact <= 60000) {
        return 69;
    }
    if(numSends * numEmailsPerContact <= 100000) {
        return 99;
    }
    if(numSends * numEmailsPerContact <= 150000) {
        return 169;
    }
    if(numSends * numEmailsPerContact <= 250000) {
        return 229 ;
    }
    if(numSends * numEmailsPerContact <= 350000) {
        return 289;
    }
    if(numSends * numEmailsPerContact <= 500000) {
        return 379;
    }
    if(numSends * numEmailsPerContact <= 750000) {
        return 499;
    }
    if(numSends * numEmailsPerContact <= 1000000) {
        return 599;
    }
    if(numSends * numEmailsPerContact > 1000000) {
        const slope = 599 / 1000000;
        return 599 + slope * (numSends * numEmailsPerContact - 1000000);

    }

    return 0;
};

function getLabelsByCost(numContacts: number, numEmailsPerContact:number) : {id:string,color:string,maxY:number,speculative:boolean}[] {
    const data = getData(numContacts,numEmailsPerContact);
    const maxById:{[key:string]: number} = {}
    const specById:{[key:string]: boolean} = {}
    const sortedData = data.sort((d1, d2) => {
        const d1id = d1.id.replace(" speculative", "").trim()
        const d2id = d2.id.replace(" speculative", "").trim()
        let d1y:number = null
        let d2y:number = null
        let d1spec = false
        let d2spec = false
        data.find(esp => {
            const foundData = esp.data.find(d => esp.id.startsWith(d1id) && d.x == numContacts);
            if(foundData) {
                d1y = foundData.y;
                d1spec = !!esp.speculative;
                return d1y;
            }

            return null;
        });
        data.find(esp => {
            const foundData = esp.data.find(d => esp.id.startsWith(d2id) && d.x == numContacts)
            if(foundData) {
                d2y = foundData.y;
                d2spec = !!esp.speculative;
                return d2y;
            }
            return null;
        });
        if(d1y === null) {
            return -1;
        }
        if(d2y === null) {
            return 1;
        }

        if(!maxById[d2id] || d2y > maxById[d2id]) {
            maxById[d2id] = d2y;
            specById[d2id] = !!d2spec;
        }
        if(!maxById[d1id] || d1y > maxById[d1id]) {
            maxById[d1id] = d1y;
            specById[d1id] = !!d1spec;
        }

        if(d1y < d2y) {
            return -1
        }

        return 1
    })
    const retData : {id:string, color:string, maxY:number,speculative:boolean}[] = []
    const seen:{[key:string]:boolean} = {}
    sortedData.map(sd => {
        const id = sd.id.replace(" speculative", "").trim()
        if(seen[id]) {
            return
        }
        retData.push({ id, color: sd.color, maxY: maxById[id], speculative: specById[id] })
        seen[id] = true
    });

    return retData;
}

function getData(numContacts: number, numEmailsPerContact: number) {
    const speculativeDiscount = 1;
    interface ESPData {speculative?:boolean,id:string,color:string,data:{x:number,y:number}[]}
    let startData:ESPData[] = [
        {
            "id": "iContact",
            "color": "#83b900",
            "data": [
                {"x": 0, "y": 0},
                {"x": 2500, "y": 25},
                {"x": 5000, "y": 45},
            ]
        },
        {
            "id": "Constant Contact",
            "color": "#ff00ff",
            "data": [
                {"x": 0, "y": 0},
                {"x": 500, "y": 20},
                {"x": 2500, "y": 45},
                {"x": 10000, "y": 95},
                {"x": 25000, "y": 225},
                {"x": 50000, "y": 335},
            ]
        },
        {
            "id": "Active Campaign",
            "color": "#8811ff",
            "data": [
                {"x": 0, "y": 0},
                {"x": 2500, "y": 55},
                {"x": 25000, "y": 259},
                {"x": 50000, "y": 345},
                {"x": 100000, "y": 505},
            ]
        },
        {
            "id": "AWeber",
            "color": "#dd5544",
            "data": [
                {"x": 0, "y": 0},
                {"x": 2500, "y": 29},
                {"x": 10000, "y": 69},
                {"x": 25000, "y": 149},
            ]
        },
        {
            "id": "Mailchimp",
            "color": "#777700",
            "data": [
                {"x": 0, "y": 0},
                {"x": 2500, "y":30},
                {"x": 10000, "y": 78},
                {"x": 20000, "y": 170},
                {"x": 50000, "y": 270},
                {"x": 100000, "y": 540},
                {"x": 150000, "y": 950},
                {"x": 200000, "y": 1990},
            ]
        },
        {
            "id": "GetResponse",
            "color": "#aaaaaa",
            "data": [
                {"x": 0, "y": 0},
                {"x": 2000, "y": 15},
                {"x": 2500, "y": 25},
                {"x": 50000, "y": 250},
                {"x": 100000, "y": 450},
            ]
        },
        {
            "id": "Freshmail",
            "color": "#0060aa",
            "data": [
                {"x": 0, "y": 0},
                {"x": 2500, "y": 24},
                {"x": 25000, "y": 142},
                {"x": 50000, "y": 249},
                {"x": 75000, "y": 329},
                {"x": 100000, "y": 439},
            ]
        },
        {
            "id": "Convert Kit",
            "color": "#22aa00",
            "data": [
                {"x": 0, "y": 0},
                {"x":2500, "y":49},
                {"x": 50000, "y": 319},
                {"x": 75000, "y": 499},
                {"x": 100000, "y": 619},
            ]
        },
        {
            "id": "Swift Missive",
            "color": "#000000",
            "data": [
                {"x": 0, "y": priceFunc(0, numEmailsPerContact)},
                {"x": 2500, "y": priceFunc(2500, numEmailsPerContact)},
                {"x": 5000, "y": priceFunc(5000, numEmailsPerContact)},
                {"x": 50000, "y": priceFunc(50000, numEmailsPerContact)},
                {"x": 100000, "y": priceFunc(100000, numEmailsPerContact)},
                {"x": 500000, "y": priceFunc(500000, numEmailsPerContact)},
                {"x": 1000000, "y": priceFunc(1000000, numEmailsPerContact)},
                {"x": 5000000, "y": priceFunc(5000000, numEmailsPerContact)},
                {"x": 10000000, "y": priceFunc(10000000, numEmailsPerContact)},
            ]
        },
    ];

    //We need to build a graph of send in blues data based on number of sends, so we have to
    //basically speculate if the number of total sends (contacts X emails) is over 1MM
    const sibData:{x:number,y:number}[] = [];
    const sibDataSpec:{x:number,y:number}[] = [];
    sibData.push({"x": 0, "y": sendInBluePriceFunc(0, numEmailsPerContact)});
    numContactsSliderValuesArray.map(num => {
        if(num * numEmailsPerContact <= 1000000) {
            sibData.push({"x": num, "y": sendInBluePriceFunc(num, numEmailsPerContact)});
        }
    });

    startData.push({
        "id": "Send In Blue",
        "color": "#5555ff",
        "data":sibData,
    });
    sibDataSpec.push({"x": 0, "y": sendInBluePriceFunc(0, numEmailsPerContact)});

    //Add projections
    const allProjectionData:ESPData[] = [];
    startData.map(espData => {
        //Get slope of graph
        const y2 = espData.data[espData.data.length-2].y;
        const y1 = espData.data[espData.data.length-1].y;
        const x2 = espData.data[espData.data.length-2].x;
        const x1 = espData.data[espData.data.length-1].x;
        let slope = speculativeDiscount * (y1 - y2 ) / (x1 - x2);
        let maxY = 0;
        let lastNum = 0;

        if(espData.id.trim().toLowerCase().startsWith("send in blue")) {
            slope = 0.000599; //1 email per customer slope
            slope *= numEmailsPerContact;
        }

        const projectionData:ESPData = {...espData, data:[espData.data[espData.data.length-1]]};
        projectionData.id = espData.id.trim() + " speculative";
        projectionData.speculative = true;

        numContactsSliderValuesArray.map(num => {
            let found = false;
            espData.data.map(data => {
                if(data.y > maxY) {
                    maxY = data.y;
                }
                if(data.x > lastNum) {
                    lastNum = data.x;
                }
                if(data.x === num) {
                    found = true;
                    return;
                }
            });

            //Don't fill in data we already know about
            if(found) {
                return;
            }

            //Do fill in data we don't know about
            if(espData.id.toLowerCase().startsWith("send in blue")) {
                projectionData.data.push({
                    x: num,
                    y: sendInBluePriceFunc(num, numEmailsPerContact),
                });
            } else {
                projectionData.data.push({
                    x: num,
                    y: maxY + slope * (num - lastNum),
                });
            }

            maxY = maxY + slope * (num - lastNum);
            lastNum = num;
        });

        allProjectionData.push(projectionData);
    });
    startData = startData.concat(allProjectionData);

    const endData:{id:string,color:string,speculative?:boolean,data:{x:number,y:number}[]}[] = [];
    startData.map(espData => {
        const cappedData:{x:number,y:number}[] = [];
        espData.data.map((data:any, index:number) => data.x <= numContacts ? cappedData.push({...data, y: index!==0 && espData.speculative? speculativeDiscount * data.y : data.y}) : null);
        espData.data = cappedData;
        endData.push(espData);
    });

    return endData;
}

const numContactsSliderValuesArray = [ 2500, 50000, 100000, 500000, 1000000, 5000000, 10000000 ];
const numEmailsPerContactSliderValuesArray = [ 1, 3, 5, 7, 9 ];

class PricingComparison extends React.Component<IProps, IState> {
    props:IProps;
    state:IState;
    xTickCount: number;
    sliderTimeoutId: any;
    slider2TimeoutId: any;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            columnsSelected: {},
            numContacts: numContactsSliderValuesArray[1],
            numEmailsPerContact: numEmailsPerContactSliderValuesArray[2],
        };
        this.columnChange = this.columnChange.bind(this);
        this.numContactsChange = this.numContactsChange.bind(this);
        this.numEmailsPerContactChange = this.numEmailsPerContactChange.bind(this);
        this.xTickCount = 0;
    }

    columnChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        return
    }

    renderColumnSelections(selectedIds: string[]) {
        let numSelected = 0;
        let s = "";
        Object.keys(this.state.columnsSelected).map(campaignName => {
            if(this.state.columnsSelected[campaignName]) {
                numSelected++;
            }
        });
        if(numSelected !== 1) {
            s = "s"
        }
        return <div>{numSelected + " column" + s + " selected"}</div>;
    }

    numContactsChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if (!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                numContacts: numContactsSliderValuesArray[0],
            });
            return;
        }

        this.setState({
            ...this.state,
            numContacts: event.target.value as number,
        });
    }

    numEmailsPerContactChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if (!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                numEmailsPerContact: numEmailsPerContactSliderValuesArray[0],
            });
            return;
        }

        this.setState({
            ...this.state,
            numEmailsPerContact: event.target.value as number,
        });
    }

    render() {
        this.xTickCount = 0;

        return <div style={{
            minWidth: "400px",
            overflow: this.props.width !== "xs" ? "visible": "hide",
        }}>
            {/* {priceFunc(1,1)} */}
            <div style={{marginLeft:"80px", marginBottom:"1rem"}}>
                <Typography variant={"body1"} style={{width:"300px", display:"inline-block", marginTop:"10px"}}>
                    Maximum estimated contacts:
                </Typography>
                <FormControl>
                    <Select MenuProps={{ disableScrollLock: true }}
                            style={{width:"280px"}}
                            startAdornment={<People style={{marginRight:"5px"}} />}
                            onChange={this.numContactsChange}
                            renderValue={selected =>selected.toLocaleString() + " contacts"}
                            value={this.state.numContacts}>
                        {numContactsSliderValuesArray.map((numContacts:number) => {
                            return <MenuItem key={"m" + numContacts} value={numContacts}>
                                <ListItemText key={"li" + numContacts} primary={numContacts.toLocaleString() + " contacts"} />
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <br />
                <Typography variant={"body1"} style={{width:"300px", display:"inline-block", marginTop:"10px"}}>
                    Average monthly emails per contact:
                </Typography>
                <FormControl>
                    <Select MenuProps={{ disableScrollLock: true }}
                            style={{width:"280px"}}
                            startAdornment={<MailOutline style={{marginRight:"5px"}} />}
                            onChange={this.numEmailsPerContactChange}
                            renderValue={selected => selected.toLocaleString() + (selected===1?" email":" emails") +  " per contact per month"}
                            value={this.state.numEmailsPerContact}>
                        {numEmailsPerContactSliderValuesArray.map((numEmails:number) => {
                            return <MenuItem key={"m" + numEmails} value={numEmails}>
                                <ListItemText key={"li" + numEmails} primary={numEmails.toLocaleString() +(numEmails===1?" email":" emails") + " per contact per month"} />
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>
            <Grid container style={{marginBottom:"1rem"}}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} style={{height:"460px", minWidth:"400px"}}>
                    <ResponsiveLine
                        data={getData(this.state.numContacts,this.state.numEmailsPerContact)}
                        margin={{ top: 10, right: 60, bottom: 50, left: 80 }}
                        xScale={{ type: 'linear' }}
                        yScale={{ type: 'linear', min: 0, max: this.state.numContacts >= 5000000 ? 50000:'auto', stacked: false, reverse: false }}
                        yFormat=","
                        pointSize={2}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        colors={{datum:"color"}}
                        layers={['grid', 'markers', 'areas', DashedLine, 'slices', 'points', 'axes', 'legends']}
                        axisBottom={{
                            legend: '# contacts',
                            legendOffset: 36,
                            tickRotation: 15,
                            legendPosition: 'start',
                            format: value => parseInt(value.toString()).toLocaleString(),
                        }}
                        axisLeft={{
                            legend: 'estimated monthly cost',
                            format: value => parseInt(value.toString()).toLocaleString(),
                            legendOffset: -50,
                            legendPosition: 'start'
                        }}
                        useMesh={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <div style={{
                        paddingTop:(this.props.width === "lg" || this.props.width === "xl")?"0":"1.5rem",
                        paddingLeft:(this.props.width === "lg" || this.props.width === "xl")?"0":"40px"
                    }}>
                        <Typography variant={"body1"} component="p">
                            Lowest cost:
                        </Typography>
                        <div>
                            {getLabelsByCost(this.state.numContacts,this.state.numEmailsPerContact).map((espData, idx:number) => <Typography key={"label_" + idx} style={{color:espData.color}} component={"span"} variant={"body1"}>
                                    <Grid container wrap={"nowrap"}>
                                        <Grid item xs={7} sm={6} md={4} lg={7} xl={7}>
                                            <div style={{display:"inline-block",width:"28px"}}>{idx+1}.</div>
                                            <div style={{display:"inline-block",width:"15px",height:"15px",marginRight:"1rem",backgroundColor:espData.color}} />
                                            {espData.id}
                                        </Grid>
                                        <Grid item>
                                            {(espData.speculative ? "*" : "")}${Math.trunc(espData.maxY).toLocaleString()}
                                        </Grid>
                                    </Grid>
                                </Typography>)}
                        </div>
                        <Typography variant={"body1"} component={"p"} style={{width:"100%"}}>*best estimate from available data</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    }
}


const styleById = {
    dotted: {
        strokeDasharray: '4, 2',
        strokeWidth: 2,
    },
    default: {
        strokeWidth: 2,
    },
    swiftMissive: {
        strokeWidth: 3,
    },
};

const DashedLine = (args: { series:any[], lineGenerator:any, xScale:any, yScale:any }) => {
    const origArgs = args;
    return args.series.map((args: { id:Key, data:Point[], color:string, speculative:boolean }) => {
        return <path
            key={args.id}
            d={origArgs.lineGenerator(
                args.data.map((d: Point) => ({
                    x: origArgs.xScale(d.data.x),
                    y: origArgs.yScale(d.data.y),
                }))
            )}
            fill="none"
            stroke={args.color}
            style={args.id.toString().startsWith("Swift Missive") ? styleById.swiftMissive : args.speculative ? styleById.dotted : styleById.default}
        />
    })
};

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state;
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(withWidth()(PricingComparison))
