export class NotificationChecker {
    maxChecks? = 0 
    intervalId? = 0
    checkNum? = 0
    stopCheckingAfterFound? = true
    check: (n: any) => boolean
}

export class NotificationActor {
    act: (n: any) => boolean
}

interface NotificationHandler {
    checker: NotificationChecker
    actor: NotificationActor
}

export class NotificationWaiter {
    handlers: {
        [key: string]: NotificationHandler
    } = {}

    addNotificationHandler(id: string, c: NotificationChecker, a: NotificationActor) {
        console.log("adding " + id)

        const cWithDefaults = new NotificationChecker()
        c = {
            ...cWithDefaults,
            ...c,
        }
        
        const aWithDefaults = new NotificationActor()
        a = {
            ...aWithDefaults,
            ...a,
        }


        this.handlers[id] = {
            checker: c,
            actor: a
        }

        console.log("there are now " + Object.keys(this.handlers).length.toString() + " handlers")
    }

    removeNotificationHandler(id: string) {
        console.log("removing " + id)

        if(this.handlers[id] && this.handlers[id].checker.intervalId) {
            clearInterval(this.handlers[id].checker.intervalId)
        }
        this.handlers[id] = null

        // Delete doesn't seem to work so we'll just rebuild
        const newNotificationHandlers: {[key: string]: NotificationHandler} = {}
        const handlerKeys = Object.keys(this.handlers)
        handlerKeys.map(key => {
            if(this.handlers[key]) {
                newNotificationHandlers[key] = this.handlers[key]
            }
        })

        this.handlers = newNotificationHandlers
    }

    clearNotificationHandlers() {
        this.handlers = {}
    }

    actOnNotifications(notifications: any[]) {
        try {
            if(!notifications) {
                return
            }

            const foundNotificationHandlerKeys: {
                [key:string]: boolean
            } = {}
            for(let j = 0; j < notifications.length; j++) {
                const handlerKeys = Object.keys(this.handlers)
                for(let i = 0; i < handlerKeys.length; i++) {
                    const key = handlerKeys[i]
                    if(foundNotificationHandlerKeys[key]) {
                        continue
                    }
                    const handler = this.handlers[key]
                    if(!handler) {
                        continue
                    }

                    const notification = notifications[j]
                    if(handler.checker.check(notification)) {
                        foundNotificationHandlerKeys[key] = true
                        handler.actor.act(notification)
                        if(handler.checker.stopCheckingAfterFound) {
                            this.removeNotificationHandler(key)
                            continue
                        }
                    } 
                        
                    if(handler.checker.maxChecks > 0 && handler.checker.checkNum >= handler.checker.maxChecks) {
                        this.removeNotificationHandler(key)
                        continue
                    }
                }
            }

            // Update num checks
            const handlerKeys = Object.keys(this.handlers)
            for(let i = 0; i < handlerKeys.length; i++) {
                const key = handlerKeys[i]
                const handler = this.handlers[key]
                handler.checker.checkNum++
            }
            
        } catch(e) {
            console.error("unable to handle notification check/act action " + e)
        }
    }
}
