import React, {ChangeEvent, FormEvent, MouseEvent} from 'react';
import { connect } from "react-redux";
import {fetchWorkflows, submitRunSegmentThroughWorkflowAction, submitWorkflowAction} from "./actions/Workflows";
import {DavemailNodeFactory, nameMap, NodeInnerCustom} from "./workflow/NodeStyles"
import { DavemailPortFactory } from "./workflow/PortStyles"
import {Toolbar} from "./workflow/Toolbar"
import { IChartToNode } from "./workflow/Serializer"
import { actionCallbacks } from "./workflow/WorkflowEventCallbacks"
import NodeSettings, {INodeSettings} from "./workflow/NodeSettings"
import {Button, Card, Grid, TextField, Typography} from "@material-ui/core";
import {CallSplit, PanTool, PlayArrow, Power, Save, TouchApp} from "@material-ui/icons";
import {validateExists} from "./InputValidation";
import SaveIcon from "@material-ui/icons/Save";
import {v4 as uuidv4} from "uuid"; 
import {Workflow} from "./generated/types/payloadTypes";
import {defaultCardElevation,defaultCardStyles} from "./App"; 
import {MyArchiveButton, MyModal, MyOkButton} from "./Dialog";
import {showToast} from "./ToastNotifications";
import {INodeBaseInput, IOnDragNodeInput, IOnLinkBaseEvent, IOnLinkCompleteInput} from "@mrblenny/react-flow-chart/src/types/functions";
import {CanvasOuterCustom} from "./workflow/CanvasOuter";
import {AllState} from "./reducers";
import {RouteComponentProps, withRouter} from "react-router-dom";
import ListSegments from './ListSegments';
import { IChart, IPort, ILink, INode } from '@mrblenny/react-flow-chart/src/types/chart';
import { IConfig } from '@mrblenny/react-flow-chart/src/types/config';
import { FlowChart } from '@mrblenny/react-flow-chart'; 

interface IState {
    id: string,
    name:string,
    workflow: IChart,
    fetchedWorkflow: boolean,
    allowValidation: boolean,
    isDragging: boolean,
    newLinkId: string, //The id of the link we are forming between two nodes
    showRunSegmentModal: boolean,
    selectedSegmentId: string,
    selectSegmentError: string,
}

interface IProps extends RouteComponentProps<{ id?: string  }>  {
    success?: string,
    error?:string,
    selectedTemplateId?: string,
    selectedPersonaId?: string,
    dispatch: any,
    match: any,
    width: string,
    fetchedWorkflow?: Workflow,
}

export const defaultWorkflow = {
    offset: {
        x: 0,
        y: 0
    },
    nodes: {},
    links: {},
    selected: {
        id:""
    },
    hovered: {}
};


export class NewWorkflowComponent extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    actionCallbacks: any;
    deleteKeyFunc: (input: { config?: IConfig }) => void;
    onLinkStartFunc: (input: IOnLinkBaseEvent) => void;
    anchorEl: any;

    constructor(props: IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id: uuidv4(),
            name: "",
            fetchedWorkflow: false,
            workflow: JSON.parse(JSON.stringify(defaultWorkflow)), //deep clone
            allowValidation: false,
            isDragging: false,
            newLinkId: null,
            showRunSegmentModal: false,
            selectedSegmentId: "",
            selectSegmentError: "",
        };
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisThis = this;

        //Expand node click callback
        const defaultCallbacks = actionCallbacks(this);
        defaultCallbacks.onNodeClick = function(input:INodeBaseInput) {
            //Don't select node if we were just in the middle of dragging one
            if(thisThis.state.isDragging) {
                thisThis.setState({
                    ...thisThis.state,
                    isDragging: false,
                });
                return;
            }

            //Don't select node if we were in the middle of connecting one
            if(thisThis.state.newLinkId) {
                thisThis.finishNodeAttachment(input.nodeId);
                return;
            }

            thisThis.setState({
              ...thisThis.state,
              workflow : {
                  ...thisThis.state.workflow,
                  selected: {
                    type:"node",
                    id:input.nodeId,
                  }
              }
            })
        };
        const origOnCanvasClick = defaultCallbacks.onCanvasClick;
        defaultCallbacks.onCanvasClick = function(input: { config?: IConfig }) {
            if(thisThis.state.newLinkId) {
                const thisState = thisThis.state;
                delete thisState.workflow.links[thisState.newLinkId];
                thisState.newLinkId = null;
                thisThis.setState(thisState);
            }

            origOnCanvasClick(input);
        };

        const origOnDragNode = defaultCallbacks.onDragNode;
        defaultCallbacks.onDragNode = function(input: IOnDragNodeInput) {
            origOnDragNode(input);

            thisThis.setState({
                ...thisThis.state,
                isDragging: true,
            })
        };
        this.actionCallbacks = defaultCallbacks;

        //Tap into some of the default action handlers
        this.deleteKeyFunc = defaultCallbacks.onDeleteKey;
        this.onLinkStartFunc = defaultCallbacks.onLinkStart;

        //Bind event listeners to this object
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRunSegment = this.handleRunSegment.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.onNodeSettingsUpdate = this.onNodeSettingsUpdate.bind(this);
        this.addNodeToWorkflow = this.addNodeToWorkflow.bind(this);
        this.startNodeAttachment = this.startNodeAttachment.bind(this);
        this.validateLink = this.validateLink.bind(this);
        this.updateSelectedSegment = this.updateSelectedSegment.bind(this);
    }

    finishNodeAttachment(toNodeId: string) {
        //Can't connect if we didn't start one
        if(!this.state.newLinkId) {
            showToast("Can't finish connecting nodes, no from-node link id found", "error");
            return;
        }

        //Figure out where we want to start a node attachment to
        if(!toNodeId) {
            showToast("Uknown workflow node selected, can't finish attachment", "error");
            return;
        }
        const node = this.state.workflow.nodes[toNodeId];

        //If they tried to finish the connection on the node that started it, just cancel the connection
        const fromLink = this.state.workflow.links[this.state.newLinkId];
        if(toNodeId === fromLink.from.nodeId) {
            const thisState = this.state;
            delete thisState.workflow.links[thisState.newLinkId];
            thisState.newLinkId = null;
            this.setState(thisState);
            return;
        }

        //Find first unused input port
        let inputPort = Object.values(node.ports).find((port:IPort) => {
            if(port.type === "top") {
                //Make sure port isn't used in another link somewhere already
                if(!Object.values(this.state.workflow.links).find((link:ILink) => link.to.portId === port.id)) {
                    return true;
                }
            }
            return false;
        });

        //Just use the first one if we couldn't find any unused ones
        if(!inputPort) {
            inputPort = Object.values(node.ports).find((port:IPort) => {
                if (port.type === "top") {
                    return true;
                }
            });
        }

        if(!inputPort) {
            showToast("Node doesn't have any input ports", "error");
            return;
        }

        //Add link, close dialog
        const newState = this.state;
        newState.workflow.links[newState.newLinkId].to = {
            nodeId: toNodeId,
            portId: inputPort.id,
        };
        newState.newLinkId = null;
        this.setState(newState);
    }

    startNodeAttachment(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();

        //Figure out where we want to start a node attachment from
        const nodeId = this.state.workflow.selected.id;
        if(!nodeId) {
            showToast("Uknown workflow node selected, can't start attachment", "error");
            return;
        }
        const node = this.state.workflow.nodes[nodeId];

        //Find first unused output port
        let outputPort = Object.values(node.ports).find((port:IPort) => {
           if(port.type === "bottom") {
               //Make sure port isn't used in another link somewhere already
               if(!Object.values(this.state.workflow.links).find((link:ILink) => link.from.portId === port.id)) {
                   return true;
               }
           }
           return false;
        });

        //Just use the first one if we couldn't find any unused ones
        if(!outputPort) {
            outputPort = Object.values(node.ports).find((port:IPort) => {
                if (port.type === "bottom") {
                    return true;
                }
            });
        }

        if(!outputPort) {
            showToast("Node doesn't have any output ports", "error");
            return;
        }

        //Add link, close dialog
        const newState = this.state;
        const newLink:ILink = {
            from: {
                nodeId,
                portId: outputPort.id,
            },
            to: {},
            id: uuidv4(),
            properties: {},
        };
        newState.workflow.links[newLink.id] = newLink;
        newState.workflow.selected.id = null;
        newState.newLinkId = newLink.id;
        this.setState(newState);
    }

    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation()
        event.preventDefault()

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation:true,
            })
        }
        if(this.validateForm(this.state)) {
            this.props.dispatch(
                submitWorkflowAction(
                    this.state.id, 
                    this.state.name,
                    JSON.parse(JSON.stringify(this.state.workflow)),               //display
                    IChartToNode(JSON.parse(JSON.stringify(this.state.workflow))), //actual settings
                    true,
                    () => {
                        const desiredPath = "/components/workflows/edit/" + this.state.id
                        if(this.props.location.pathname !== desiredPath) {
                            this.props.history.replace(desiredPath)
                        }
                    }
                )
            )
        }
    }

    updateSelectedSegment(event:ChangeEvent<{name?:string, value?:unknown}>) {
        this.setState({
            ...this.state,
            selectSegmentError: "",
            selectedSegmentId: event.target.value ? event.target.value.toString() : "",
        })
    }
    
    handleRunSegment(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.selectedSegmentId) {
            this.setState({
                ...this.state,
                selectSegmentError: "Please select a segment!",
            })
            return
        }

        const rootNode = IChartToNode(this.state.workflow) 
        if(!rootNode || !rootNode.id) {
            this.setState({
                ...this.state,
                selectSegmentError: "Please add a trigger node to the workflow",
            })
            return
        }
        this.props.dispatch(submitRunSegmentThroughWorkflowAction(
            this.state.selectedSegmentId, 
            this.state.id,
            rootNode.id))
    }

    validateForm(state: IState):boolean {
        if(validateExists(state.name) !== "") {
            return false;
        }

        return true;
    }

    handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();

        this.setState({
            ...this.state,
            name: event.target.value,
        });
    }

    componentDidMount(){
        if(this.props.match.params.id) {
            this.props.dispatch(fetchWorkflows([this.props.match.params.id]))
        }
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) {
        if(!prevState.fetchedWorkflow && nextProps.fetchedWorkflow) {
            return {
                id: nextProps.fetchedWorkflow.id,
                name: nextProps.fetchedWorkflow.name,
                workflow: {
                    ...JSON.parse(JSON.stringify(defaultWorkflow)),
                    ...nextProps.fetchedWorkflow.json.display,
                    selected: {id: null}, //Don't re-select the previously selected workflow it will trigger popups
                },
                fetchedWorkflow: true,
            }
        }

        //We have a node selected, so let's make sure to save the selected template or sender persona id on it
        const nodes = prevState.workflow.nodes;
        if(prevState.workflow.selected.id && prevState.workflow.nodes[prevState.workflow.selected.id]) {
            let nodeProperties:INodeSettings = prevState.workflow.nodes[prevState.workflow.selected.id].properties;
            if(!nodeProperties) {
                nodeProperties = {};
            }
            if(nextProps.selectedTemplateId) {
                nodeProperties.templateId = nextProps.selectedTemplateId;
            }
            if(nextProps.selectedPersonaId) {
                nodeProperties.senderPersonaId  = nextProps.selectedPersonaId;
            }
            nodes[prevState.workflow.selected.id].properties = nodeProperties;
        }

        return {
          ...prevState,
          workflow: {
            ...prevState.workflow,
            nodes,
          }
        }
    }

    //Called from node settings child component
    onNodeSettingsUpdate(nodeId:string, settings:INodeSettings) {
        console.log("Updating node settings ", nodeId, settings);
        const nodes: { [id:string]: INode } = this.state.workflow.nodes;
        nodes[nodeId].properties = {
          ...nodes[nodeId].properties, //Let's not overwrite any other properties that we store here
          ...settings,
        };

        this.setState({
            ...this.state,
            workflow: {
              ...this.state.workflow,
              nodes,
            }
        })
    }

    addNodeToWorkflow(node: INode) {
        return (event: MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();

            const state = this.state
            state.workflow.nodes[node.id] = node
            this.setState(state)
        }
    }

    validateLink(props: IOnLinkCompleteInput & { chart: IChart }) :boolean {
        try {
            if(props.fromNodeId === props.toNodeId) {
                console.log("Invalid link");
                return false;
            }

            if(this.state.workflow.nodes[props.fromNodeId].ports[props.fromPortId].type === "bottom" &&
               this.state.workflow.nodes[props.toNodeId].ports[props.toPortId].type === "top") {
                console.log("Valid link");
                return true;
            }
        } catch(e) {
            //Will happen if ports isn't set, etc
            //we'll return false
        }

        console.log("Invalid link");
        return false;

    }

    render() {
        try {

        return <>
            <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                <Typography variant={"h1"} component={"div"} >
                    {!this.props.match.params.id ? "New " : "Edit " }
                    Workflow
                </Typography>

               <hr />

                <div style={{marginBottom:"1.5rem"}}>
                    <Grid container style={{marginBottom:"10px"}} alignItems={"flex-end"}>
                        <Grid item>
                            <CallSplit style={{transform:"rotate(180deg)"}}/>
                        </Grid>
                        <Grid item>
                            <TextField label={"Workflow name"}
                               autoFocus={true}
                               required={true}
                               style={{marginLeft:"5px",marginRight:"10px",marginBottom:"5px",minWidth:"300px",width:"50%"}}
                               value={this.state.name}
                               onChange={this.handleNameChange}
                               error={this.state.allowValidation && validateExists(this.state.name)!==""}
                               helperText={this.state.allowValidation && validateExists(this.state.name)!==""? validateExists(this.state.name): ""}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                                startIcon={<SaveIcon />}
                                onClick={this.handleSubmit}
                                style={{marginRight:"10px"}}
                                >
                                Save
                            </Button>
                            <Button
                                disabled={!this.state.workflow || !this.state.workflow.nodes || Object.keys(this.state.workflow.nodes).length === 0}
                                variant={"contained"}
                                color={"secondary"}
                                startIcon={<PlayArrow />}
                                onClick={() => this.setState({...this.state, showRunSegmentModal: true})}>
                                Run Segment
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {/* Confirmation dialogs */}
                {this.state.showRunSegmentModal &&
                <MyModal title={"Run segment"}
                   open={this.state.showRunSegmentModal}
                   content={<>
                   <Typography component="p" variant="body1" style={{marginBottom:"1rem"}}>
                        You can trigger this workflow with each contact in a segment instead
                        of triggering it with individual events.  Please make sure you have saved
                        any workflow and segment changes first!
                   </Typography>
                   <Grid container>
                        {this.props.width != "xs" && this.props.width != "sm" && <Grid item md={2} lg={2} xl={2}>
                            <Typography component="p" variant="body1" style={{marginTop:"5px"}}>
                                <b>Segment:</b>
                            </Typography>
                        </Grid>}
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <ListSegments  
                                buttons={{}}
                                placeholder="Select segment" 
                                type="select" 
                                width={this.props.width} 
                                onChange={this.updateSelectedSegment}
                                dispatch={this.props.dispatch} />
                        </Grid>
                   </Grid>
                   <Typography component="p" variant="body1" style={{color:"red", marginBottom:"1rem"}}>
                       {this.state.selectSegmentError}
                   </Typography>
                   </>}
                   buttons={<div style={{width:"100%",marginTop:"0.5rem"}}>
                        <Button 
                            variant="contained"
                            color="secondary"
                            style={{
                                marginLeft:"5px",
                                marginRight:"5px",
                                float:"right",
                            }}
                            startIcon={<PlayArrow />}
                            onClick={(event:any) => {
                                event.stopPropagation();
                                event.preventDefault();
                                this.handleRunSegment(event);
                            }}>Run segment</Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            style={{
                                marginLeft:"5px",
                                marginRight:"5px",
                                float:"right",
                            }}
                            onClick={(event:any) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.setState({
                                ...this.state,
                                showRunSegmentModal: false,
                                selectedSegmentId: "",
                            });
                        }}>Cancel</Button>
                       {this.state.workflow && this.state.workflow.selected && this.state.workflow.selected.id && this.state.workflow.nodes[this.state.workflow.selected.id] &&
                       this.state.workflow.nodes[this.state.workflow.selected.id].ports &&
                       Object.values(this.state.workflow.nodes[this.state.workflow.selected.id].ports).find(port => port.type == "bottom") &&
                       <Button variant={"contained"}
                               style={{
                                   float:"right",
                                   marginLeft:"5px",
                               }}
                               startIcon={<Power />}
                               onClick={this.startNodeAttachment}
                               color={"primary"}>
                           Connect
                       </Button>}
                    </div>}
                />}
                {this.state.workflow && this.state.workflow.selected && this.state.workflow.selected.id && this.state.workflow.nodes[this.state.workflow.selected.id] &&
                <MyModal title={nameMap[this.state.workflow.nodes[this.state.workflow.selected.id].type] + " node settings"}
                   open={this.state.workflow && this.state.workflow.selected && this.state.workflow.selected.id && !!this.state.workflow.nodes[this.state.workflow.selected.id]}
                   content={<NodeSettings
                        dispatch={this.props.dispatch}
                        nodes={this.state.workflow.nodes}
                        width={this.props.width}
                        selectedNodeId={this.state.workflow.selected.id}
                        onSettingsUpdate={this.onNodeSettingsUpdate}
                   />}
                   buttons={<div style={{width:"100%",marginTop:"10px"}}>
                       <MyArchiveButton text="Delete"
                        style={{
                            marginLeft:"5px",
                            marginRight:"5px",
                            float:"left",
                        }}
                        onClick={(event:any) => {
                           event.stopPropagation();
                           event.preventDefault();
                           this.deleteKeyFunc({});
                           this.setState({
                               ...this.state,
                               workflow: {
                                   ...this.state.workflow,
                                   selected: {
                                       id: null,
                                   }
                               }
                           });
                       }} />
                       <MyOkButton style={{marginLeft:"5px",float:"right"}}
                                   onClick={(event:any) => {
                                       event.stopPropagation();
                                       event.preventDefault();
                                       this.setState({
                                           ...this.state,
                                           workflow: {
                                               ...this.state.workflow,
                                               selected: {
                                                   id: null,
                                               }
                                           }
                                       });
                                   }}/>
                       {this.state.workflow && this.state.workflow.selected && this.state.workflow.selected.id && this.state.workflow.nodes[this.state.workflow.selected.id] &&
                       this.state.workflow.nodes[this.state.workflow.selected.id].ports &&
                       Object.values(this.state.workflow.nodes[this.state.workflow.selected.id].ports).find(port => port.type == "bottom") &&
                       <Button variant={"contained"}
                               style={{
                                   float:"right",
                                   marginLeft:"5px",
                               }}
                               startIcon={<Power />}
                               onClick={this.startNodeAttachment}
                               color={"primary"}>
                           Connect
                       </Button>}
                    </div>}
                />}
                {/* buttons above graph */}
                <div>
                    <div className="clearfix">
                        <Toolbar addNodeFunc={this.addNodeToWorkflow}  />
                    </div>
                    <div className="workflow-wrapper inset-code-box-light">
                        {this.state.newLinkId && <div className={"workflow-overlay"}  style={{marginTop:"5px"}}>
                            <Typography variant={"body1"} style={{fontSize:"1.1em"}}>
                                <Power /> Select the node where you would like to send the this node's output to, or click anywhere on the empty grid to cancel
                            </Typography>
                        </div>}
                        {(!this.state.workflow.nodes || Object.keys(this.state.workflow.nodes).length === 0) && !this.state.newLinkId &&
                        <div className={"workflow-overlay"} style={{marginTop:"5px"}}>
                            <Typography variant={"body1"} style={{fontSize:"1.1em"}}><TouchApp /> Click the buttons above to add to this workflow</Typography>
                            <Typography variant={"body1"} style={{fontSize:"1.1em"}}><TouchApp /> Once you've added nodes, select them to access their settings</Typography>
                            <Typography variant={"body1"} style={{fontSize:"1.1em"}}><Save /> Settings and other changes will only be deployed once you click the save button</Typography>
                            <Typography variant={"body1"} style={{fontSize:"1.1em"}}><PanTool /> You can drag around nodes as well as this canvas</Typography>
                        </div>}
                        {/* The actual workflow editor UI */}
                        <div>
                            <FlowChart
                                config={{ validateLink: this.validateLink }}
                                callbacks={this.actionCallbacks}
                                chart={this.state.workflow}
                                Components={{
                                    Node: DavemailNodeFactory,
                                    Port: DavemailPortFactory,
                                    NodeInner: NodeInnerCustom,
                                    CanvasOuter: CanvasOuterCustom,
                                }} />
                        </div>
                    </div>
                </div>
            </Card>
        </>
        }catch(e) {
            let message = "an error occurred while loading your workflow"
            try {
                const e2 = e as any 
                if(e2.message) {
                    message += e2.message
                }
            } catch(e) {
                console.error("unable to determine error message")
            }
            return <>
            <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                <Typography variant={"h1"} component={"div"} >
                    {!this.props.match.params.id ? "New " : "Edit " }
                    Workflow
                </Typography>
                <hr />
                <Typography variant="h2" component="p" color='error'>
                   {message}
                </Typography>
            </Card></>
        }
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    //Find the workflow with the id passed in via the route
    let fetchedWorkflow = null;
    if(state.workflows && Object.keys(state.workflows).length > 0) {
        if(state.workflows[ownProps.match.params.id]) {
            fetchedWorkflow = state.workflows[ownProps.match.params.id];
        }
    }

    return {
        fetchedWorkflow,
        error: state.error,
        success: state.success,
    }
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewWorkflowComponent))