import * as React from 'react'
import styled from 'styled-components'
import {INode, IPortDefaultProps} from "@mrblenny/react-flow-chart/src";
import { v4 as uuidv4 } from 'uuid';
import Button from "@material-ui/core/Button";
import {MouseEvent} from "react";
import {Add} from "@material-ui/icons";
import {colorMap, nameMap} from "./NodeStyles";

const ToolbarItem = styled.div`
  padding: 5px 5px 5px 5px;
  margin: 2px 2px 2px 2px;
  font-size: 14px;
  background: white;
  cursor: move;
  border: 2px solid grey;
  border-radius: 5px;
  float:left;
`;

export interface ISidebarItemProps {
  type: string,
  ports: any,
  properties?: any,
  children?: any,
}

class SidebarItem extends React.Component<ISidebarItemProps> {
  type: string;
  ports: { [key:string]: IPortDefaultProps };
  properties: any;

  constructor(props: ISidebarItemProps) {
    super(props);
    this.type = props.type;
    this.ports = props.ports;
    this.properties = props.properties;
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisItem = this;
    return (
          <ToolbarItem
              draggable={true}
              onDragStart={ (event) => {
                event.dataTransfer.setData('react-flow-chart', JSON.stringify(
                    { id: uuidv4(), type: thisItem.type, ports: thisItem.ports, properties: null }))
              }}>
            <span className={"grippy"} />
            {this.type}
            {this.props.children}
          </ToolbarItem>
    )
  }
}

function newPorts(num: number, type: string): any {
  const ports:any = {};
  let id = uuidv4();
  for(let i = 0; i < num; i++) {
    while(ports[id]) {
      id = uuidv4();
    }
    ports[id] = {
        id,
        type
    }
  }
  return ports;
}

export function NewNode(type:string): INode {
  const node:INode = {
    id: uuidv4(),
    type: type,
    ports: {},
    position: {x:10 + Math.random() * 200,y: 10 + Math.random() * 200},
    size: {width:151, height:93},
    properties: {},
  };

  switch(type) {
    case "event":
    case "user_upserted":
      node.ports = {...newPorts(5, "bottom")};
      break;
    case "sender":
      node.ports = {...newPorts(5, "top")};
      break;
    default:
      node.ports = {...newPorts(5, "top"), ...newPorts(5,"bottom")};
      break;
  }

  return node;
}

function newPortsBoth(numBottom:number, numTop:number) {
  const bottomPorts = newPorts(numBottom, "bottom");
  const topPorts = newPorts(numTop, "top");

  return {
      ...bottomPorts,
      ...topPorts,
  }
}

function DraggableToolbarButton(props: {nodeType:string, bottomPorts:number,topPorts:number,addNodeFunc:any}) : JSX.Element {
  return <Button onClick={props.addNodeFunc(NewNode(props.nodeType))} variant={"contained"} className={"toolbar-button"}
          style={{backgroundColor:colorMap[props.nodeType]}} startIcon={<Add />}
          draggable={true}
          onDragStart={ (event) => {
            event.dataTransfer.setData('react-flow-chart', JSON.stringify(
                { id: uuidv4(), type: props.nodeType, ports: newPortsBoth(props.bottomPorts, props.topPorts), properties: null }))
          }}>
    {nameMap[props.nodeType]}
  </Button>
}

export function Toolbar(props: {addNodeFunc: (node:INode) => (event: MouseEvent) => void }) : JSX.Element {
  return <div style={{display:"inline-block"}}>
    <DraggableToolbarButton nodeType={"event"} bottomPorts={5} topPorts={0} addNodeFunc={props.addNodeFunc}/>
    {/*<DraggableToolbarButton nodeType={"user_upserted"} bottomPorts={5} topPorts={0} addNodeFunc={props.addNodeFunc} />*/}
    <DraggableToolbarButton nodeType={"delay"} bottomPorts={5} topPorts={5} addNodeFunc={props.addNodeFunc} />
    <DraggableToolbarButton nodeType={"delay_until"} bottomPorts={5} topPorts={5} addNodeFunc={props.addNodeFunc} />
    <DraggableToolbarButton nodeType={"sender"} bottomPorts={0} topPorts={5} addNodeFunc={props.addNodeFunc} />
    {/*<Button onClick={props.addNodeFunc(NewNode('splitter'))} variant={"contained"} className={"toolbar-button"}*/}
    {/*        style={{backgroundColor:colorMap['splitter']}} startIcon={<Add />}>*/}
    {/*  Splitter*/}
    {/*</Button>*/}
  </div>
}

export class OldToolbar extends React.Component<any, any> {
  render() {
    return (<div {...this.props}>
      <SidebarItem type={"event"} ports={ newPorts(5, "bottom") } />
      <SidebarItem type={"user_upserted"} ports={ newPorts(5, "bottom") } />
      <SidebarItem type={"delay"} ports={ { ...newPorts(5, "top"), ...newPorts(5, "bottom") } } />
      <SidebarItem type={"delay_until"} ports={ { ...newPorts(5, "top"), ...newPorts(5, "bottom") } } />
      <SidebarItem type={"splitter"} ports={ { ...newPorts(5, "top"), ...newPorts(5, "bottom") } } />
      <SidebarItem type={"sender"} ports={ { ...newPorts(5, "top"), ...newPorts(5, "bottom") } } />
    </div>)
  }
}

