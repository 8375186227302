import { connect } from "react-redux";
import React from 'react';
import NewEmailBlastComponent from "./NewEmailBlast";
import { clearStateData} from "./actions/Common";
import {dashboardReportIds} from "./PageInsights";

interface IProps {
    width: string,
    dispatch: any,
}

class PageNewEmailBlast extends React.Component<IProps, null> {
    componentDidMount(): void {
        dashboardReportIds.map((reportId:string) => this.props.dispatch(clearStateData(["reportData", reportId])));
    }

    render() {
        return <NewEmailBlastComponent dispatch={this.props.dispatch} {...this.props} />
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageNewEmailBlast)



