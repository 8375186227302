import React, {FormEvent} from 'react';
import {connect} from "react-redux";
import {submitSegmentAction} from './actions/Segments'
import {
    Button,
    Card,
    Typography
} from "@material-ui/core";
import {DropzoneArea } from "material-ui-dropzone";
import {showToast} from "./ToastNotifications";
import {v4 as uuidv4} from "uuid";
import {fetchS3BulkUploadUrl} from "./Common";
import {defaultCardElevation,defaultCardStyles, showLeftNav} from "./App";
import {RouteComponentProps, withRouter} from "react-router-dom";
import { topNavMinHeight } from './nav/TopNav';
import moment from 'moment';
import BulkUploadProgress, { FileUpload } from './components/BulkUploadProgress';
import { JobLogEntry, Segment } from './generated/types/payloadTypes';
import { fetchBulkUploadProgress } from './actions/Notifications';


export const fileProcessingJobCheckerInterval:{[key:string]:NodeJS.Timeout} = {}
export const numChecks:{[key:string]: number} = {}

interface IState {
    id: string;
    name: string;
    users: any[];
    selectedTab: number;
    files: File[];
    fileUploads: {[key:string]: FileUpload},
}

interface IProps extends RouteComponentProps<{ id?: string  }> {
    dispatch: any,
    match: any,
    fetchSegments?: any,
    selectedUserIds?: string[],
    width: string,
    receivingUsers?: boolean,
    notifications?: {[key:string]:JobLogEntry}
    segments?: {[key:string]:Segment},
    receivingBulkUploadProgress?: {[key:string]:boolean},
    bulkUploadProgress?: {[key:string]:{numInProgress:number, numTotal:number,s3Key:string, errors:string[], processingErrors:string[]}},
}

class NewSegmentComponent extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    moreDetailsRef: any;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id: uuidv4(),
            name: "",
            users: [],
            selectedTab: 0,
            files: [],
            fileUploads: null,
        };

        this.moreDetailsRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.tabChanged = this.tabChanged.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    nameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();
        const name = event.target.value;
        this.setState((state) => {
            return {
                ...state,
                name,
            };
        });
    }

    tabChanged(event: React.ChangeEvent, newValue: number) {
        event.stopPropagation();
        event.preventDefault();
        this.setState({
            ...this.state,
            selectedTab:newValue,
        })
    }

    handleSubmit(event: React.MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.props.dispatch(submitSegmentAction(
            this.state.id,
            this.state.name,
            this.props.selectedUserIds))
    }

    withTens(num: number): string {
        if(num < 10) {
            return "0" + num.toString();
        }

        return num.toString();
    }

    nowYYYYMMDD() : string {
        return moment().format("YYYY-MM-DD")
    }

    startFileProcessProgressChecker(s3Key:string) {
        if(fileProcessingJobCheckerInterval[s3Key]) {
            console.log("clearing previous upload checker for " + s3Key)
            clearInterval(fileProcessingJobCheckerInterval[s3Key])
            fileProcessingJobCheckerInterval[s3Key] = null
        }

        let interval = 3000
        if(this.state.fileUploads && this.state.fileUploads[s3Key]) {
            const fileBytes = this.state.fileUploads[s3Key].totalBytes
            if(fileBytes > 100000) {
                interval = 4000
            } else if(fileBytes > 150000) {
                interval = 6000
            } else if(fileBytes > 1000000) {
                interval = 10000
            } else if(fileBytes > 5000000) {
                interval = 15000
            }
        }
        interval += Math.floor(Math.random() * 500) // Add some jitter to avoid clobbering the db for simultaneous uploads

        fileProcessingJobCheckerInterval[s3Key] = setInterval(() => {
            console.log("checking upload processing progress for " + s3Key)

            // if(numChecks[s3Key] > maxChecks) {
            //     clearInterval(fileProcessingJobCheckerInterval[s3Key])
            //     fileProcessingJobCheckerInterval[s3Key] = null
            //     numChecks[s3Key] = 0
            //     return
            // }

            let clearChecker = false

            // We use Num Total > 1 here because the first job is the distribution job
            if(this.props.bulkUploadProgress[s3Key]?.numInProgress === 0 && this.props.bulkUploadProgress[s3Key]?.numTotal > 1) {
                clearChecker = true 
            } else if(this.props.bulkUploadProgress[s3Key]?.processingErrors && this.props.bulkUploadProgress[s3Key]?.processingErrors.length > 0) {
                clearChecker = true
            } else if(this.props.bulkUploadProgress[s3Key]?.errors && this.props.bulkUploadProgress[s3Key]?.errors.length > 0) {
                clearChecker = true
            } else {
                console.log("continuing to check " + s3Key + " " + JSON.stringify(this.props.bulkUploadProgress[s3Key]))
            }
            
            if(clearChecker) {
                console.log("clearing checker for " + s3Key)
                clearInterval(fileProcessingJobCheckerInterval[s3Key])
                fileProcessingJobCheckerInterval[s3Key] = null
                numChecks[s3Key] = 0
                return
            }

            this.props.dispatch(fetchBulkUploadProgress(s3Key, moment().subtract(12, 'hours').toDate()))

            numChecks[s3Key] = (numChecks[s3Key] || 0) + 1
        }, interval)

        // Also check right now
        this.props.dispatch(fetchBulkUploadProgress(s3Key, moment().subtract(12, 'hours').toDate()))
    }

    componentWillUnmount() {
        Object.keys(fileProcessingJobCheckerInterval).map(k => {
            clearInterval(fileProcessingJobCheckerInterval[k])
        })
    }

    componentDidMount(): void {
        // We need to stop then restart these checks on unmount/mount because
        // if we navigate away then come back, props being checked by the old
        // intervals will become stale
        Object.values(this.props.bulkUploadProgress).map(progress => {
            if(progress.numInProgress > 0 && progress.numTotal > 0) {
                this.startFileProcessProgressChecker(progress.s3Key)
            }
        }) 
    }

    getFileUploads(): {[key:string]:FileUpload} {
        const fileUploads = this.state.fileUploads || {} 
        Object.values(this.props.bulkUploadProgress).map(progress => {
            fileUploads[progress.s3Key] = this.getFileUploadForKey(progress.s3Key)
        })

        return fileUploads
    }

    getFileUploadForKey(s3Key:string, withDefault = true): FileUpload {
        if(this.state.fileUploads && this.state.fileUploads[s3Key]) {
            return { 
                ...this.state.fileUploads[s3Key], 
                errors: this.props.bulkUploadProgress[s3Key].errors,
                processingErrors: this.props.bulkUploadProgress[s3Key].processingErrors, 
            }
        }

        if(!withDefault) {
            return null
        }

        return {
            progress: 1,
            errors: this.props.bulkUploadProgress[s3Key].errors,
            processingErrors: this.props.bulkUploadProgress[s3Key].processingErrors, 
            totalBytes: 1,//Not used
            filename: s3Key,
        }
    }


    onDrop(files:File[], dropEvent:any) {
        dropEvent.stopPropagation();
        dropEvent.preventDefault();

        files.map((file, index) => {
            ((index: number, file:File) => {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const thisThis = this;
                const newName = file.name + " " + this.nowYYYYMMDD() + "-" + uuidv4().split("-")[0];
                // const newName = file.name;
                fetchS3BulkUploadUrl(this.props.dispatch, newName, file.type).then(url => {
                    if(!url) {
                        const error = `couldn't get url to upload file ${newName}`;
                        console.error(error)
                        showToast(error, "error");
                        return
                    }

                    console.log(`Attempting to upload ${newName} ${file.type} to ${url}`);

                    //Add file to upload list
                    thisThis.setState({
                        ...thisThis.state,
                        fileUploads: {
                            ...thisThis.state.fileUploads,
                            [newName]: {
                                filename: newName,
                                progress: 0,
                                totalBytes: file.size,
                                errors: [],
                                processingErrors: [],
                            },
                        }
                    });

                    //Do file upload with xmlhttprequest, so that we can monitor progress
                    const xhr = new window.XMLHttpRequest();
                    let error = "";
                    xhr.upload.addEventListener("progress", function(evt) {
                        if(error !== "") {
                            return;
                        }
                        if (evt.lengthComputable) {
                            const percentComplete = evt.loaded / evt.total;

                            //Update state with upload progress
                            thisThis.setState({
                                ...thisThis.state,
                                fileUploads: {
                                    ...thisThis.state.fileUploads,
                                    [newName]: {
                                        filename: newName,
                                        progress: percentComplete,
                                        totalBytes: file.size,
                                        errors: (thisThis.state.fileUploads[newName].errors || []),
                                        processingErrors: (thisThis.state.fileUploads[newName].processingErrors || []),
                                    },
                                }
                            });

                            if (percentComplete === 1) {
                                thisThis.startFileProcessProgressChecker(newName)
                            }
                        }
                    }, false);
                    xhr.onload = function() {
                        if (xhr.status !== 200) {
                            error = `Upload of file ${newName} failed with status ${this.status}: ${xhr.statusText}`;
                            showToast(`Upload of file ${newName} failed with status ${this.status}: ${xhr.statusText}`, "error");

                            //Update state with upload progress
                            thisThis.setState({
                                ...thisThis.state,
                                fileUploads: {
                                    ...thisThis.state.fileUploads,
                                    [newName]: {
                                        filename: newName,
                                        progress: 0,
                                        totalBytes: file.size,
                                        errors: [error],
                                        processingErrors: [],
                                    },
                                }
                            });

                        }
                    };
                    xhr.open('PUT', url, true);
                    xhr.setRequestHeader("Content-Type", file.type);
                    xhr.send(file);
                });
            })(index, file);
        });

    }

    render() {
        return (
            <>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"p"} >
                        Bulk upload contacts
                    </Typography>
                    <hr />

                    <form onSubmit={this.handleSubmit}>
                    <DropzoneArea
                        dropzoneText="Drag-drop a file here or click to upload.  File size limit: 2GB"                      
                        showPreviews={false}
                        onDrop={this.onDrop}
                        filesLimit={100}
                        showAlerts={false}
                        showPreviewsInDropzone={false}
                        maxFileSize={9999999999}
                    />
                    <Button variant={"outlined"}
                            color={"primary"}
                            style={{marginTop:"0.5rem"}}
                            onClick={() => {
                                this.moreDetailsRef.current.scrollIntoView()
                            }}>
                        See details below
                    </Button>
                    </form>
                </Card>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <Typography variant={"h1"} component={"p"} style={{marginBottom:"1rem"}}>
                        Upload Progress
                    </Typography>
                    <Typography variant={"body1"}>
                        Please don't navigate away from this page while a file is uploading as it will stop your upload in most browsers.
                        Once processing has begun you can resume using the site as normal.
                    </Typography>

                    <BulkUploadProgress fileUploads={this.getFileUploads()} />
                </Card>
                <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
                    <a ref={this.moreDetailsRef} style={
                    showLeftNav(this.props.width)? {} :    
                    {
                        position:"relative", 
                        top: "-" + topNavMinHeight, 
                        left: 0,
                         width:0, 
                         height:0
                    }} />
                    <Typography variant={"h1"} component={"p"} style={{marginBottom:"1rem"}}>
                        Bulk upload details
                    </Typography>
                    <Typography variant={"body1"} component={"p"} style={{marginBottom:"0.5rem"}}>
                        <b>Uploading a CSV file above will:</b>
                    </Typography>
                    <Typography variant={"body1"} component={"ul"} style={{marginBottom:"1rem"}}>
                        <li>Create a new segment with the filename and the current date as the segment name</li>
                        <li>Create any contacts in our system that aren't already in there</li>
                        <li>Replace/update any contacts in our system that are already in there</li>
                        <li>Add all contacts in the file to the segment that was created when the file was uploaded</li>
                    </Typography>
            
                    <Typography variant={"body1"} component={"p"} style={{marginBottom:"0.5rem"}}>
                        <b>Example CSV</b>
                    </Typography>
                    <div className={"inset-code-box"} style={{marginBottom:"1rem"}}>
                        <Typography variant={"body1"} component={"p"} 
                        style={{fontFamily:"monospace",whiteSpace:"nowrap",overflowY:"hidden",overflowX:"scroll"}}><b>email,first_name,last_name,date_of_birth,timezone,jsonfield1,jsonfield2</b><br />
swiftmissivetestemail@swiftmissive.com,"First Name",LastName,2021-01-21T10:00:00Z,America/New_York,value1,value2<br />
nonameguy@gmail.com,,,,,value100,value200
                        </Typography>
                    </div>

                    <Typography variant={"body1"} component={"p"} style={{marginBottom:"0.5rem"}}>
                        <b>Additional details:</b>
                    </Typography>
                    <Typography variant={"body1"} component={"ul"}>
                        <li>The email, name, date of birth, and timezone fields are all required fields (however you can leave them blank).  </li>
                        <li>There is a limit of 64kb for all given fields.</li>
                        <li>The date of birth field is in UTC YYYY-MM-DD<b>T</b>HH:MM:SS<b>Z</b> format</li>
                        <li>Any additional fields you supply will be placed in a JSON blob associated with your contacts and will be accessible from your email templates.</li>
                        <li>The timezone is in <a href={"https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"} target="_blank" rel="noreferrer">Area/Location</a> format</li>
                    </Typography>
                </Card>
            </>
        )
    }
}


function mapStateToProps(state:any, ownProps:IProps):any {
    return {...state}
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(NewSegmentComponent))