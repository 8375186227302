import React from "react";
import {AllState} from "../reducers";
import {connect} from "react-redux";
import {
    Card,
 Typography, withWidth
} from "@material-ui/core";
import { fetchQuickStatsReportDataAction } from "../actions/Reports";
import { Loading } from "../components/Loading";
import { v4 as uuidv4 } from 'uuid';

interface IProps {
    dispatch: any,
    reportData?: {[key:string]:any[]},
    receivingReportData?: {[key:string]:boolean},
    campaignId?: string,
}
interface IState {
    id:string,
}

class ClickStats extends React.Component<IProps, IState> {
    props:IProps;
    state:IState;

    constructor(props:IProps) {
        super(props)
        this.props = props;
        this.state = {
            id: uuidv4(),
        }
    }

    componentDidMount(){
        this.props.dispatch(fetchQuickStatsReportDataAction(this.state.id, this.props.campaignId))
    }

    getQuickStatCard(nameKey:string, name:string, num:number, unique:number, 
            numSends:number, numUniqueSends:number,
            numOpens:number, numUniqueOpens:number,
            numClicks:number, numUniqueClicks:number): JSX.Element {

        const uniqueLabelTitle = "The number of unique " + name.toLowerCase() + " total.  Each contact is counted exactly once."
        const numLabelTitle = "The number of " + name.toLowerCase() + " total.  Contacts may be counted more than once."
        let perSendRateTitle = "The percentage of unique " + name.toLocaleLowerCase() + " per unique send."
        if(nameKey === "ses_event_click") {
            perSendRateTitle += " Also known as click through rate or CTR."
        }
        const opensPerClickTitle = "The percentage of unique opens per unique click."
        const clicksPerOpenTitle = "The percentage of unique clicks per unique open.  Also known as click to open rate or CTOR."
        const labelWidth = "90px"
        return <Card elevation={1} style={{borderRadius:"1rem", padding:"1rem", paddingTop:"0.5rem",width:"250px", height:"150px",margin:"0.25rem",display:"inline-flex",flexDirection:"column"}}>
            <Typography variant="h2" style={{marginBottom:"0.5rem"}}>{name}</Typography> 
            {(nameKey !== "user_unsubscribed" && nameKey !== "ses_event_bounce") && <Typography variant="body1" component="span">
                <div style={{width:labelWidth, display:"inline-block"}}>
                    <span style={{borderBottom:"1px dotted #888", cursor:"help"}} title={numLabelTitle}><b># Total</b></span>
                </div>
                {num.toLocaleString()}
            </Typography>}
            <Typography variant="body1" component="span">
                <div style={{width:labelWidth, display:"inline-block"}}>
                    <span style={{borderBottom:"1px dotted #888", cursor:"help"}} title={uniqueLabelTitle}><b># Unique</b></span>
                </div>
                <div style={{display:"inline-block"}}>{unique.toLocaleString()}</div>
            </Typography>
            {nameKey !== 'email_send_result_success' && <Typography variant="body1" component="span">
                <div style={{width:labelWidth, display:"inline-block"}}>
                    <span style={{borderBottom:"1px dotted #888", cursor:"help"}} title={perSendRateTitle}><b>Per send</b></span>
                </div>
                <div style={{display:"inline-block"}}>{numUniqueSends > 0 ? (100 * Number(unique) / Number(numUniqueSends)).toLocaleString() + "%" : "N/A"}</div>
            </Typography>}
            {nameKey === 'ses_event_click' && <Typography variant="body1" component="span">
            <div style={{width:labelWidth, display:"inline-block"}}>
                    <span style={{borderBottom:"1px dotted #888", cursor:"help"}} title={clicksPerOpenTitle}><b>Per open</b></span>
                </div>
                <div style={{display:"inline-block"}}>{numUniqueOpens > 0 ? (100 * Number(unique) / Number(numUniqueOpens)).toLocaleString() + "%" : "N/A"}</div>
            </Typography>}
            {nameKey === 'ses_event_open' && <Typography variant="body1" component="span">
                <div style={{width:labelWidth, display:"inline-block"}}>
                    <span style={{borderBottom:"1px dotted #888", cursor:"help"}} title={opensPerClickTitle}><b>Per click</b></span>
                </div>
                <div style={{display:"inline-block"}}>{numUniqueClicks > 0 ? (100 * Number(unique) / Number(numUniqueClicks)).toLocaleString() + "%" : "N/A"}</div>
            </Typography>}
        </Card>
    }

    getQuickStatCards(data:any[]):JSX.Element[] {
        const allowedStats:{[key:string]:string} = {
            "email_send_result_success": "Sends",
            "ses_event_open": "Opens",
            "ses_event_click": "Clicks",
            "ses_event_bounce": "Bounces",
            "user_unsubscribed": "Unsubscribes",
            "ses_event_complaint": "Complaints",
            // "email_send_result_error": "Errors",
            // "send_email_request": "Send Requests",
            // "ses_event_delivery": "Deliveries",
            // "ses_event_send": "SES Sends",
        }

        const order = Object.keys(allowedStats)
        data.sort((a,b) => {
            return order.findIndex(e => a.name === e) - order.findIndex(e => b.name === e)
        })
      

        // Find some stats that we'll need for combos like percentages
        let numSends = 0;
        let numUniqueSends = 0;
        let numOpens = 0;
        let numUniqueOpens = 0;
        let numClicks = 0;
        let numUniqueClicks = 0;
        for(let i = 0; i < data.length; i++) {
            if(data[i].name === "email_send_result_success") {
                numSends = data[i].num
                numUniqueSends = data[i].num_unique
            } else if(data[i].name === "ses_event_open") {
                numOpens = data[i].num
                numUniqueOpens = data[i].num_unique
            } else if(data[i].name === "ses_event_click") {
                numClicks = data[i].num
                numUniqueClicks = data[i].num_unique
            }
        }

        // Loop and create jsx elements
        const myElements:JSX.Element[] = []
        const foundElements:{[key:string]:boolean} = {}
        for(let i = 0; i < data.length; i++) {
            const name = allowedStats[data[i].name]
            if(!name) {
                continue
            }

            myElements.push(this.getQuickStatCard(data[i].name, name, data[i].num || 0, data[i].num_unique || 0, numSends, numUniqueSends, numOpens, numUniqueOpens, numClicks, numUniqueClicks))
            foundElements[data[i].name] = true
        }

        // Add 0s for anything we don't have stats for
        Object.keys(allowedStats).map(statKey => {
            if(foundElements[statKey]) {
                return
            }

            myElements.push(this.getQuickStatCard(statKey, allowedStats[statKey], 0, 0, numSends, numUniqueSends, numOpens, numUniqueOpens, numClicks, numUniqueClicks))
        })

        return myElements
    }

    render() {
        return <div>
            {this.props.receivingReportData[this.state.id] ? <Loading style={{minHeight:"366px", paddingTop:"2rem"}} /> :
                <div style={{display:"flex", justifyContent:"center", alignContent:"center", alignItems:"center",flexWrap:"wrap"}}>
                    {this.getQuickStatCards(this.props.reportData[this.state.id] || [])} 
                </div>
            }
        </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state;
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(withWidth()(ClickStats))
