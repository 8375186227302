import React from "react"
import {
    Card,
} from "@material-ui/core";

export interface GraphPointData {
    point: {
        id:string,
        index:number,
        serieId:string,
        serieColor:string,
        x:number,
        y:number,
        color:string,
        borderColor:string,
        data: {
            rawX: string, 
            x: string,
            y:number,
            xFormatted:string,
            yFormatted:string,
        }
    }
}

export function GraphTooltip(datum: GraphPointData | any):JSX.Element {
    try {
        let yLabel = "Num"
        if(datum.point && typeof datum.point.serieId == "string") {
            const serieIdParts = datum.point.serieId.split(" ")
            yLabel = serieIdParts[serieIdParts.length - 1]
            if(yLabel.length > 1) {
                yLabel = yLabel[0].toUpperCase() + yLabel.substring(1)
            }
        }
        return <Card style={{zIndex:100,lineHeight:"18pt", borderLeft:"1px solid",borderRight:"2px solid", borderTop:"1px solid", borderBottom:"2px solid", 
                            borderColor: datum.point.serieColor}}>
                <div style={{color:datum.point.serieColor}}><b>{yLabel}: {datum.point.data.y.toLocaleString()}</b></div>
                <div style={{color:"#555", fontSize:"10pt"}}>Date: {datum.point.data.xFormatted} UTC</div>
            </Card>
    } catch(e) {
        console.log((e as Error).message)
        return <></>
    }
}