import { Typography,Radio, FormControlLabel, RadioGroup, TextField } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { AllState } from '../../reducers';
import { TimezoneSelect } from '../../components/TimezoneSelect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateTimePickerRow from '../../components/DateTimePickerRow';
import moment from 'moment';


interface IProps {
    width: string,
    dispatch: any,
    onSendAtChange: (sendAtMoment: moment.Moment, sendAtType:"specific_timezone"|"users_timezone", sendAtTimezone: string) => void
    sendAtMoment: moment.Moment, 
    sendAtType:"specific_timezone"|"users_timezone",
    sendAtTimezone: string,
}
interface IState {

}

class SelectToType extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props
        this.state = {
        }
    }

    render() {
        return  <>
            <Typography variant="h2" component="p">
                When should we send your emails?
            </Typography>
            <RadioGroup
                style={{paddingLeft:"1rem", paddingBottom:"1rem"}}
                value={this.props.sendAtType}
                row={false} 
                name="sendAtType"
                onChange={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    
                    if(this.props.onSendAtChange) {
                        let sendAtType:"users_timezone"|"specific_timezone" = "users_timezone"
                        if(e.target?.value) {
                            switch(e.target.value) {
                                case "users_timezone":
                                    sendAtType = "users_timezone"
                                    break
                                case "specific_timezone":
                                    sendAtType = "specific_timezone"
                                    break
                            }
                        }
                        this.props.onSendAtChange(this.props.sendAtMoment, sendAtType, this.props.sendAtTimezone)
                    }
                }}>
                    <FormControlLabel value={"users_timezone"} control={<Radio color="primary" value={"users_timezone"} />} 
                        label={<Typography variant="body1" component="span">In each contact's timezone (recommended)</Typography>} 
                        color="primary" />
                    <FormControlLabel value={"specific_timezone"} control={<Radio color="primary" value={"specific_timezone"} />} 
                        label={<Typography variant="body1" component="span">All at once</Typography>} 
                        color="primary" />
            </RadioGroup>
            <div style={{marginBottom:"2rem"}}>
                <div style={{paddingLeft:"1rem"}}> 
                    <DateTimePickerRow 
                        onChange={(m:moment.Moment) => this.props.onSendAtChange(m, this.props.sendAtType, this.props.sendAtTimezone)} 
                        dispatch={this.props.dispatch} 
                        datetimeMoment={this.props.sendAtMoment} />
                </div>
                {this.props.sendAtType === "specific_timezone" && <div style={{paddingLeft:"1rem"}}> 
                    <TimezoneSelect label={"Timezone"}
                        required={true}
                        placeholder={"Select timezone"}
                        style={{width:"100%",minWidth:"300px", maxWidth:"500px"}}
                        includeUsersLocalTimezoneOption={true}
                        timezone={this.props.sendAtTimezone}
                        timezoneChange={(e:any) => {
                            e.stopPropagation()
                            e.preventDefault()
                            
                            if(this.props.onSendAtChange) {
                                let sendAtTimezone = "America/New_York"
                                if(e.target?.value) {
                                    sendAtTimezone = e.target.value.toString()
                                }
                                this.props.onSendAtChange(this.props.sendAtMoment, this.props.sendAtType, sendAtTimezone)
                            }
                        }}
                    /></div>}
            </div>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectToType)
