import React, {FormEvent, MouseEvent} from "react";
import {
    Button,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {Link} from "react-router-dom"
import {connect} from "react-redux";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {addCompanyUser, getCompanyUsers, getAuthInfo, removeCompanyUser} from "./actions/Auth";
import {Loading} from "./components/Loading";
import { CompanyUser, LoginResponsePayload, } from "./generated/types/payloadTypes";
import { MyModal, MyCancelButton, MyArchiveButton } from "./Dialog";
import { Edit, Add, Remove, Delete } from "@material-ui/icons";

interface IState {
    upForDeletionUser: CompanyUser,
    allowValidation: boolean,
    authInfo: LoginResponsePayload,
    anchorEl?: any,
    newUserInputs: {
        id: "", // never allow value, to avoid id conflicts when adding many users in a row
        companyId: string,
        companyName: string,
        projectId: string,
        projectName: string,
        firstName: string,
        lastName: string,
        email: string,
        password: string,
    }
}

interface IProps {
    dispatch: any,
    width: string,
    companyUsers?: {[key:string]: CompanyUser},
    receivingCompanyUsers?: boolean,
}

class PageTeam extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);

        const authInfo = getAuthInfo()
        this.state = {
            allowValidation: false,
            authInfo: authInfo,
            upForDeletionUser: null,
            newUserInputs: {
                id: "",
                projectId: authInfo.project.id,
                projectName: authInfo.project.name,
                companyId: authInfo.company.id,
                companyName: authInfo.company.name,
                firstName: "",
                lastName: "",
                email: "",
                password: "",
            }
        };
        this.props = props;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm(state: IState):boolean {
        return true;
    }


    handleSubmit(event: MouseEvent | FormEvent) {
        event.stopPropagation();
        event.preventDefault();

        if(!this.state.allowValidation) {
            this.setState({
                ...this.state,
                allowValidation: true,
            })
        }

        if(this.validateForm(this.state)) {
            this.setState({
                ...this.state,
                allowValidation: false,
            })
        }
    }

    
    getButtons(user:CompanyUser): JSX.Element {
        const deleteButton = <Link to={""} className="svgShadow" onClick={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            this.setState({
                ...this.state, 
                upForDeletionUser: user,
            })
        }}><Delete /></Link>

       
        const buttons = []
        buttons.push(deleteButton)

        return <div style={{width:"100%"}}>
            {buttons.map(c => c)}
        </div>
    }


    componentDidMount() {
        this.props.dispatch(getCompanyUsers());
    }

    render() {
        const labelSizes:{[key:string]: number} = { xs: 2, sm:2, md:2, lg:2, xl:2}
        const inputSizes:{[key:string]: number} = { xs: 6, sm:6, md:6, lg:6, xl:6}
        let firstCompanyUser:CompanyUser = null // This will be the "main" company user
        if(this.props.companyUsers && Object.keys(this.props.companyUsers).length > 0) {
            firstCompanyUser = Object.values(this.props.companyUsers).sort((cu1, cu2) => cu1.created_at < cu2.created_at ? -1 : 1)[0]
        }
        return <>
            <Card elevation={defaultCardElevation}  style={{...defaultCardStyles}}>
                <Typography variant={"h1"} component={"div"}>Your team</Typography>
                <hr />
                <Typography variant={"h2"} component={"p"}>Current account admins</Typography>

                {!this.props.receivingCompanyUsers ? <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell><Typography variant="body1">Email</Typography></TableCell>
                                    <TableCell><Typography variant="body1">First name</Typography></TableCell>
                                    <TableCell><Typography variant="body1">Last name</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(this.props.companyUsers || {}).map(cu => <TableRow key={"key_" + cu.id}>
                                    {firstCompanyUser.id === cu.id ? 
                                    <TableCell style={{width:"25px", whiteSpace:"nowrap"}}>
                                        <span title="You can't remove the super-admin.  Please contact support@swiftmissive.com if you need to change this email.">
                                            <Delete color="disabled" />
                                        </span>
                                    </TableCell>
                                    :<TableCell style={{width:"25px", whiteSpace:"nowrap"}}>
                                        {this.getButtons(cu)}
                                    </TableCell>
                                    }
                                    <TableCell>{cu.email}</TableCell>
                                    <TableCell>{cu.first_name}</TableCell>
                                    <TableCell>{cu.last_name}</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>: <Loading />}

                {/* Confirm deletion dialog */}
                {this.state.upForDeletionUser && <MyModal title={"Remove team member?"}
                    anchorEl={this.state.anchorEl?this.state.anchorEl:null}
                    content={
                        <Typography variant={"body1"} component={"p"} style={{marginBottom:"15px"}}>
                            Are you sure you want to remove {this.state.upForDeletionUser.email?
                            <>"<i>{this.state.upForDeletionUser.email}</i>?"</>:<>this team member?</>}
                        </Typography>
                    }
                    buttons={<>
                        <MyCancelButton onClick={(event:any) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.setState({
                                ...this.state,
                                upForDeletionUser: null,
                            });
                            }}/>
                        <MyArchiveButton 
                            text={"Remove"}
                            onClick={(event:any) => {
                                event.stopPropagation();
                                event.preventDefault();

                                this.props.dispatch(removeCompanyUser({
                                    company_name: "",
                                    project_name: "",
                                    company_user: this.state.upForDeletionUser,
                                }   
                                    
                                ))
                                this.setState({
                                    ...this.state,
                                    upForDeletionUser: null,
                                });
                            }}/>
                    </>}
                />}
            </Card>
            <Card elevation={defaultCardElevation}  style={{...defaultCardStyles, paddingTop:"1rem"}}>
                <Typography variant={"h2"} component={"p"} style={{paddingBottom:"0rem"}}>Add an admin</Typography>
                <div style={{paddingLeft:"1rem", paddingBottom:"1rem"}}>
                    <form onSubmit={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        this.props.dispatch(addCompanyUser({
                            company_user: {
                                id: "",
                                company_id: this.state.newUserInputs.companyId,
                                project_id: this.state.newUserInputs.projectId,
                                first_name: this.state.newUserInputs.firstName,
                                last_name: this.state.newUserInputs.lastName,
                                email: this.state.newUserInputs.email,
                                json: {
                                    addedBy: this.state.authInfo.user.email,
                                    addedAt: new Date().toISOString(),
                                },
                            },
                            company_name: this.state.newUserInputs.companyName,
                            project_name: this.state.newUserInputs.projectName,
                            password: this.state.newUserInputs.password,
                        }))
                    }}>
                    <Grid container style={{alignItems:"flex-end"}}>
                        <Grid item {...labelSizes} style={{minWidth:"100px"}}>
                            First name
                        </Grid>
                        <Grid item {...inputSizes}>
                            <TextField 
                                label="First name" 
                                style={{width:"100%", maxWidth:"300px"}}  
                                value={this.state.newUserInputs.firstName}
                                onChange={(e) => this.setState({
                                    ...this.state,
                                    newUserInputs: {
                                        ...this.state.newUserInputs,
                                        firstName: e.target.value,
                                    }
                                })}
                                />
                        </Grid>
                    </Grid>
                    <Grid container style={{alignItems:"flex-end"}}>
                        <Grid item {...labelSizes} style={{minWidth:"100px"}}>
                            Last name
                        </Grid>
                        <Grid item {...inputSizes}>
                            <TextField 
                                label="Last name" 
                                style={{width:"100%", maxWidth:"300px"}} 
                                value={this.state.newUserInputs.lastName}
                                onChange={(e) => this.setState({
                                    ...this.state,
                                    newUserInputs: {
                                        ...this.state.newUserInputs,
                                        lastName: e.target.value,
                                    }
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{alignItems:"flex-end"}}>
                        <Grid item {...labelSizes} style={{minWidth:"100px"}}>
                            Email
                        </Grid>
                        <Grid item {...inputSizes}>
                            <TextField label="Email" 
                                required={true} 
                                style={{width:"100%", maxWidth:"300px"}}  
                                value={this.state.newUserInputs.email}
                                onChange={(e) => this.setState({
                                    ...this.state,
                                    newUserInputs: {
                                        ...this.state.newUserInputs,
                                        email: e.target.value,
                                    }
                                })}
                                />
                        </Grid>
                    </Grid>
                    <Grid container style={{alignItems:"flex-end"}}>
                        <Grid item {...labelSizes} style={{minWidth:"100px"}}>
                            Password
                        </Grid>
                        <Grid item {...inputSizes}>
                            <TextField label="Password" 
                                required={true} 
                                type="password" 
                                style={{width:"100%", maxWidth:"300px"}}  
                                value={this.state.newUserInputs.password}
                                onChange={(e) => this.setState({
                                    ...this.state,
                                    newUserInputs: {
                                        ...this.state.newUserInputs,
                                        password: e.target.value,
                                    }
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{alignItems:"flex-end", marginTop:"1rem"}}>
                        <Grid item {...labelSizes}>
                        </Grid>
                        <Grid item {...inputSizes}>
                            <Button 
                                variant="contained"
                                color="primary"
                                type="submit"
                                >
                                Add admin
                            </Button>
                        </Grid>
                    </Grid>
                    </form>
                </div>
               
            </Card>
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageTeam)
