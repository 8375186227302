import React, {ChangeEvent} from 'react';
import {archiveUsers, fetchUsers,fetchNumUsers} from './actions/Users'
import { connect } from "react-redux";
import {Grid, Typography, TextField, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ArrowDropDown, ArrowDropUp, Delete, Edit, Search,Add,Remove} from "@material-ui/icons";
import {User} from "./generated/types/payloadTypes";
import {MyArchiveButton, MyCancelButton, MyModal} from "./Dialog";
import throttle from "lodash/debounce";
import PagingToolbarFromProps from "./components/PagingToolbarFromProps";
import { Loading } from './components/Loading';

interface IState {
    upForDeletionUser: User,
    anchorEl?: any,
    search: string,
    searchType: string,
    orderColumn: string,
    orderDirection: string,
    limit: number,
    offset: number,
}

interface IProps {
    dispatch: any,
    users?: {[key:string]:User},
    usersInSegments?: {[key:string]:User},
    usersNotInSegments?: {[key:string]:User},
    receivingUsers?: boolean,
    receivingUsersInSegments?: boolean,
    receivingUsersNotInSegments?: boolean,
    type:string,
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    onUserAdd?: (userId: string) => any,
    onUserRemove?: (userId: string) => any,
    buttons: {[key:string]: boolean},
    selectedUserIds?: string[],
    editButtonTargetBlank?: boolean,
    toggleIfNameClicked?: boolean; // for lists with add/remove button
    style?: any;
    segmentIds?: string[]; //Id of segment users are in
    nonSegmentIds?: string[]; //Id of segment users are not in
    hideAddButton?: boolean;
    usersListKey?: string;
    usersCountListKey?: string;
    width: string,
    receivingNumUsers?: boolean,
    receivingNumUsersInSegments?: boolean,
    receivingNumUsersNotInSegments?: boolean,
    numUsers?: number,
    numUsersInSegments?: number,
    numUsersNotInSegments?: number,
    hideSearch?: boolean,
    showSelectedOnly?: boolean,
    limit?: number,
    pagingButtonVariant?: "text" | "outlined" | "contained",
    prevPageText?: JSX.Element,
    nextPageText?: JSX.Element,
}


class ListUsers extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    searchChange: any;

    constructor(props:IProps) {
        super(props);
        this.state = {
            upForDeletionUser:null,
            orderColumn: "email",
            orderDirection: "ASC",
            search: "",
            searchType: "email",
            limit: props.limit || 10,
            offset:0,
            //name: "",
        };
        // this.userChange = this.userChange.bind(this);
        this.archiveUser = this.archiveUser.bind(this);
        this.searchTypeChange = this.searchTypeChange.bind(this);
        this.pagePrev = this.pagePrev.bind(this);
        this.pageNext = this.pageNext.bind(this);

        //Only search every so many seconds
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisThis = this;
        this.searchChange = throttle(function(val:string) {
            const newState = {
                ...thisThis.state,
                search:val,
                offset: 0,
            }
            thisThis.setState(newState)

            if(props.showSelectedOnly) {
                if(props.selectedUserIds && props.selectedUserIds.length > 0) {
                    props.dispatch(fetchUsers(props.selectedUserIds, thisThis.props.segmentIds, thisThis.props.nonSegmentIds, 
                        newState.limit, newState.offset, 
                        newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                        newState.searchType, newState.search))
                }
            } else {
                props.dispatch(fetchUsers(null, thisThis.props.segmentIds, thisThis.props.nonSegmentIds, 
                    newState.limit, newState.offset, 
                    newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                    newState.searchType, newState.search))
                props.dispatch(fetchNumUsers(newState.searchType, newState.search, thisThis.props.segmentIds, thisThis.props.nonSegmentIds))
            }
            

        }, 1000, {
            leading: false,
            trailing: true,
        });
    }

    archiveUser(email: string) {
        this.props.dispatch(archiveUsers([email]));
    }

    componentDidMount(){
        if(this.props.showSelectedOnly) {
            if(this.props.selectedUserIds && this.props.selectedUserIds.length > 0) {
                this.props.dispatch(fetchUsers(this.props.selectedUserIds, this.props.segmentIds, this.props.nonSegmentIds, this.state.limit, this.state.offset, 
                    this.state.orderColumn != ""?[this.state.orderColumn + " " + this.state.orderDirection]:[],
                    this.state.searchType, this.state.search))
            }
        } else {
            this.props.dispatch(fetchUsers(null, this.props.segmentIds, this.props.nonSegmentIds, this.state.limit, this.state.offset, 
                this.state.orderColumn != ""?[this.state.orderColumn + " " + this.state.orderDirection]:[],
                this.state.searchType, this.state.search))
    
            this.props.dispatch(fetchNumUsers(this.state.searchType, this.state.search, this.props.segmentIds, this.props.nonSegmentIds))
        }
    }

    pagePrev(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset -= newState.limit 
        if(newState.offset <= 0) {
            if(this.state.offset === 0) {
                return
            }
            newState.offset = 0
        }

        this.setState(newState)

        if(this.props.showSelectedOnly) {
            if(this.props.selectedUserIds && this.props.selectedUserIds.length > 0) {
                this.props.dispatch(fetchUsers(this.props.selectedUserIds, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                    newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                    newState.searchType, newState.search))
            }
        } else {
            this.props.dispatch(fetchUsers(null, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                newState.searchType, newState.search))
        }
    }

    pageNext(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset += newState.limit 

        const { numUsers } = this.userVars()
        if(newState.offset > numUsers) {
            return
        }

        this.setState(newState)
       
        if(this.props.showSelectedOnly) {
            if(this.props.selectedUserIds && this.props.selectedUserIds.length > 0) {
                this.props.dispatch(fetchUsers(this.props.selectedUserIds, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                    newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                    newState.searchType, newState.search))
            }
        } else {
            this.props.dispatch(fetchUsers(null, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                newState.searchType, newState.search))
        }

    }

    searchTypeChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        event.stopPropagation()
        event.preventDefault()

        if(!this.state.search) {
            this.setState( {
                ...this.state,
                searchType: event.target.value.toString(),
            })
            return
        }

        const newState = {
            ...this.state,
            searchType: event.target.value.toString(),
            offset: 0,
        }
        this.setState(newState)

        if(this.props.showSelectedOnly) {
            if(this.props.selectedUserIds && this.props.selectedUserIds.length > 0) {
                this.props.dispatch(fetchUsers(this.props.selectedUserIds, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                    newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                    newState.searchType, newState.search))
            }
        } else {
            this.props.dispatch(fetchUsers(null, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                newState.searchType, newState.search))
            this.props.dispatch(fetchNumUsers(newState.searchType, newState.search, this.props.segmentIds, this.props.nonSegmentIds))
        }
        
    }

    sortBy(event:any, tableHeader: string) {
        event.stopPropagation();
        event.preventDefault();

        let orderDirection = "ASC";

        //Toggle direction if clicking again
        if(this.state.orderColumn == tableHeader) {
            if(this.state.orderDirection === "ASC") {
                orderDirection = "DESC";
            } else {
                orderDirection = "ASC";
            }
        }

        const newState = {
            ...this.state,
            orderColumn: tableHeader,
            orderDirection,
        }
        this.setState(newState)

        if(this.props.showSelectedOnly) {
            if(this.props.selectedUserIds && this.props.selectedUserIds.length > 0) {
                this.props.dispatch(fetchUsers(this.props.selectedUserIds, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                    newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                    newState.searchType, newState.search))
            }
        } else {
            this.props.dispatch(fetchUsers(null, this.props.segmentIds, this.props.nonSegmentIds, newState.limit, newState.offset, 
                newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                newState.searchType, newState.search))
            // this.props.dispatch(fetchNumUsers(newState.searchType, newState.search, this.props.segmentIds, this.props.nonSegmentIds))
        }
        
    }

    sortDirectionWidget(tableHeader: string) : JSX.Element {
        if(this.state.orderColumn === tableHeader) {
            if(this.state.orderDirection === "ASC") {
                return <ArrowDropUp />
            } else {
                return <ArrowDropDown />
            }
        }

        return null;
    }

    headerWidget(headerName: string, displayName: string) : JSX.Element {
        return <Link to={""} onClick={(event) => this.sortBy(event, headerName)}>
            <Grid container wrap={"nowrap"} alignContent={"stretch"} alignItems={"center"}>
                <Grid item>
                    {displayName}
                </Grid>
                <Grid item style={{height:"20px"}}>
                    {this.sortDirectionWidget(headerName)}
                </Grid>
            </Grid>
        </Link>
    }

    getButtons(user:User): JSX.Element {
        const editButton = !this.props.editButtonTargetBlank ?  <Link key="editButton" to={"/people/users/edit/"+user.id} className="svgShadow"><Edit /></Link>
            : <a key="editButton2" href={"/people/users/edit/"+user.id} target="_blank" className="svgShadow"><Edit /></a>

        //This add button is for when we are pushing the add event back up to the parent
        const addButtonCallback = <Link to={""} className="svgShadow" onClick={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            if(this.props.onUserAdd) {
                this.props.onUserAdd(user.id)
            }
        }}><Add /></Link>

        //This remove button is for when we are pushing the remove event back up to the parent
        const removeButtonCallback =<Link to={""} className="svgShadow" onClick={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            if(this.props.onUserRemove) {
                this.props.onUserRemove(user.id)
            }
        }}><Remove /></Link>

        const deleteButton = <Link to={""} className="svgShadow" onClick={(event)=>{
                event.stopPropagation();
                event.preventDefault();
                this.setState({
                    ...this.state,
                    upForDeletionUser: user,
                })
            }}><Delete /></Link>

        //If this is an add/remove list
        if(this.props.selectedUserIds && this.props.buttons["add"] && this.props.buttons["remove"]) {
            let thisEditButton = <></>
            if(this.props.buttons["edit"]) {
                thisEditButton = editButton
            }

            if(this.props.selectedUserIds.find(id => id == user.id)) {
                return <div style={{width:"100%"}}>{removeButtonCallback}{thisEditButton}</div>
            } else {
                return <div style={{width:"100%"}}>{addButtonCallback}{thisEditButton}</div>
            }
        }
        
        const buttons = []
        if(this.props.buttons["add"]) {
            buttons.push(addButtonCallback)
        }
        if(this.props.buttons["remove"]) {
            buttons.push(removeButtonCallback)
        }
        if(this.props.buttons["archive"]) {
            buttons.push(deleteButton)
        }
        if(this.props.buttons["edit"]) {
            buttons.push(editButton)
        }

        return <div style={{width:"100%"}}>
            {buttons.map(c => c)}
        </div>
    }

    userVars(): {users:User[], isLoading:boolean, isLoadingNum: boolean, numUsers:number} {
        let myUsers:User[] = []
        let isLoading = false
        let isLoadingNum = false
        let numUsers = 0

        if(this.props.segmentIds && this.props.segmentIds.length > 0) {
            if(Object.values(this.props.usersInSegments).length > 0) {
                myUsers = Object.values(this.props.usersInSegments)
            }
            isLoading = this.props.receivingUsersInSegments
            isLoadingNum = this.props.receivingNumUsersInSegments
            numUsers = this.props.numUsersInSegments
        }
        else if(this.props.nonSegmentIds && this.props.nonSegmentIds.length > 0) {
            if(Object.values(this.props.usersNotInSegments).length > 0) {
                myUsers = Object.values(this.props.usersNotInSegments)
            }
            isLoading = this.props.receivingUsersNotInSegments
            isLoadingNum = this.props.receivingNumUsersNotInSegments
            numUsers = this.props.numUsersNotInSegments
        } else if(this.props.users) {
            if(Object.values(this.props.users).length > 0) {
                myUsers = Object.values(this.props.users)
            }
            isLoading = this.props.receivingUsers
            isLoadingNum = this.props.receivingNumUsers
            numUsers = this.props.numUsers
        }

        if(this.props.showSelectedOnly) {
            numUsers = this.props.selectedUserIds? this.props.selectedUserIds.length: 0
        }

        return {users:myUsers, isLoading, isLoadingNum, numUsers}
    }

    mainTable(): JSX.Element {
        const { users } = this.userVars()

        if(this.props.receivingUsers && (!this.props.users || Object.keys(this.props.users).length === 0)) {
            return <div style={{minHeight:"400px", paddingTop:"2rem"}}><Loading /></div>
        } 
        
        return <div style={{position:"relative"}}>
            {this.props.receivingUsers && <Loading overlay={true} mySize='lg' text={<Typography variant="h1">Loading...</Typography>} />}
            <TableContainer>
            <Table cellSpacing={0}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{this.headerWidget("email", "Email")}</TableCell>
                        <TableCell>{this.headerWidget("first_name", "First Name")}</TableCell>
                        <TableCell>{this.headerWidget("last_name", "Last Name")}</TableCell>
                        <TableCell>{this.headerWidget("timezone", "Timezone")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(u => <TableRow key={u.id}>
                        <TableCell style={{width:"25px", whiteSpace:"nowrap"}}>
                            {this.getButtons(u)}
                        </TableCell>
                        <TableCell style={{maxWidth:"200px", overflow:"hidden", textOverflow:"ellipsis"}}>
                            {!this.props.toggleIfNameClicked ? <a href={"/people/users/edit/" + u.id} target="_blank">
                                <Typography component="span" variant="body1" title={u.email}>
                                {u.email}
                                </Typography>
                            </a> : 
                            <Link to={""} onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                if(this.props.selectedUserIds && this.props.selectedUserIds.indexOf(u.id) !== -1) {
                                    if(this.props.onUserRemove) {
                                        this.props.onUserRemove(u.id)
                                    }
                                } else {
                                    if(this.props.onUserAdd) {
                                        this.props.onUserAdd(u.id)
                                    }
                                }
                            }}>
                                <Typography component="span" variant="body1" title= {u.email}>
                                {u.email}
                                </Typography>
                            </Link>}
                        </TableCell>
                        <TableCell style={{maxWidth:"150px", overflow:"hidden", textOverflow:"ellipsis"}}>
                            <Typography component="span" variant="body1" title={u.first_name}>
                                {u.first_name}
                            </Typography>
                        </TableCell>
                        <TableCell style={{maxWidth:"150px", overflow:"hidden", textOverflow:"ellipsis"}}>
                            <Typography component="span" variant="body1" title={u.last_name}>
                            {u.last_name}
                            </Typography>
                        </TableCell>
                        <TableCell><Typography component="span" variant="body1">
                            {u.timezone}
                        </Typography></TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    }

    render() {
        return <>
            { this.props.type === 'list' && <>
                    <Grid container>
                        <Grid item style={{marginRight:"10px", marginBottom:"5px"}}>
                           <PagingToolbarFromProps 
                                offset={this.state.offset} 
                                limit={this.state.limit} 
                                totalItems={this.userVars()["numUsers"]}
                                isLoading={this.userVars()["isLoadingNum"]} 
                                onNextPage={this.pageNext}
                                onPrevPage={this.pagePrev} 
                                buttonVariant={this.props.pagingButtonVariant || "contained"}
                                prevPageText = {this.props.prevPageText}
                                nextPageText = {this.props.nextPageText}
                                />
                        </Grid>
                        {!this.props.hideSearch && <Grid item>
                            <TextField placeholder={"search"}
                               style={{width:"40%", minWidth:"300px", marginTop:"3px"}}
                               onChange={e => this.searchChange(e.target.value.toString())}
                               InputProps={{
                                   startAdornment: <>
                                       <Select
                                           onChange={this.searchTypeChange}
                                           style={{backgroundColor:"#eee",borderTopLeftRadius:"5px"}}
                                           value={this.state.searchType}>
                                           <MenuItem value={"email"}>&nbsp;Email</MenuItem>
                                           <MenuItem value={"first_name"}>&nbsp;First Name</MenuItem>
                                           <MenuItem value={"last_name"}>&nbsp;Last Name</MenuItem>
                                           <MenuItem value={"timezone"}>&nbsp;Timezone</MenuItem>
                                       </Select>
                                       <Search  />
                                   </>
                               }}/>
                        </Grid>}
                    </Grid>

                    {/* Confirm deletion dialog */}
                    {this.state.upForDeletionUser && <MyModal title={"Archive contact?"}
                        anchorEl={this.state.anchorEl?this.state.anchorEl:null}
                        content={
                           <Typography variant={"body1"} component={"p"} style={{marginBottom:"15px"}}>
                               Are you sure you want to archive {this.state.upForDeletionUser.email?
                               <>"<i>{this.state.upForDeletionUser.email}</i>?"</>:<>this contact?</>}
                           </Typography>
                        }
                        buttons={<>
                           <MyCancelButton onClick={(event:any) => {
                               event.stopPropagation();
                               event.preventDefault();
                               this.setState({
                                   ...this.state,
                                   upForDeletionUser: null,
                               });
                             }}/>
                           <MyArchiveButton onClick={(event:any) => {
                               event.stopPropagation();
                               event.preventDefault();

                               this.archiveUser(this.state.upForDeletionUser.email);
                               this.setState({
                                   ...this.state,
                                   upForDeletionUser: null,
                               });
                           }}/>
                        </>}
                    />}

                    {this.mainTable()}
                </>
            }
        </>;
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListUsers)

