import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Link} from "react-router-dom";
import {publicRoutes, routes} from "./RoutesV2";

export function PublicLeftNav(): JSX.Element {
    return <>
        <div style={{opacity:1, backgroundColor:"white", paddingLeft:"0.5rem", marginTop:"0", height:"100%", minHeight:"100dvh"}}>
        <div style={{width:"100%", position:"sticky", top:0, zIndex:1,  paddingTop:"1.5rem"}}>

        <List component={"nav"} key={"nav"} >
            {PublicRouteLinks()}
        </List>
        </div>
        </div>

    </>
}

export function PublicRouteLinks() : JSX.Element {
    let listItemNum = 0;
    const primaryColor = "#333";
    return <> {publicRoutes.map(routeDef => {
        return <Link to={routeDef.href} onClick={routeDef.onClick} key={"link" + (listItemNum++)} style={{ textDecoration: 'none' }}>
            <ListItem
                button
                style={{color:primaryColor}}
                key={"item"+ (listItemNum++)}
                component={"div"}>
                {routeDef.icon &&
                <ListItemIcon key={"icon"+ (listItemNum++)} style={{ color:primaryColor,minWidth:"0px", marginRight:"12px"}}>
                    {routeDef.icon}
                </ListItemIcon>}
                <ListItemText key={"text"+ (listItemNum++)}  primary={routeDef.text}  />
            </ListItem>
        </Link>
    })}</>
}