import { Card, Typography, Button, TextField } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { defaultCardElevation, defaultCardStyles } from '../App';
import { SenderPersona, Template } from '../generated/types/payloadTypes';
import { AllState } from '../reducers';
import SelectSenderPersona from './components/SelectSenderPersona';
import { Save } from '@material-ui/icons';
import SelectTemplate from './components/SelectTemplate';
import SelectEmailDelays from './components/SelectEmailDelays';
import SelectSegments from './components/SelectSegments';
import {v4 as uuidv4} from "uuid";
import { fetchSenderPersonas, submitSenderPersonaAction } from '../actions/SenderPersonas';
import { showToast } from '../ToastNotifications';

interface IProps {
    forSegment: boolean,
    eventName: string,
    pageNum: number,
    numEmails: number,
    width: string,
    dispatch: any,
    senderPersonas?: {[key:string]:SenderPersona},
    templates?: {[key:string]:Template},
    selectedSenderPersonas: SenderPersona[],
    selectedTemplateIds: string[],
    isNewSenderPersonas: boolean[],
    isNewTemplates: boolean[],
    selectedSendAtTypes:("time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay")[],
    selectedSendAtTimezones: string[],
    selectedSendAtDelaySeconds:number[],
    selectedSendAtMoments: moment.Moment[],
    selectedSegmentIds:{[key:string]:boolean},
    onSegmentsChange:(selectedSegmentIds:{[key:string]:boolean}) => void,
    onPageChange:(pageNum:number) => void,
    onNumEmailsChange: (numEmails: number) => void
    onSelectedSenderPersonasChange: (senderPersonas: SenderPersona[],  isNewSenderPersona: boolean[]) => void,
    onSelectedTemplateIdsChange: (selectedTemplateIds:string[], isNewTemplate: boolean[]) => void,
    onEventNameChange: (n:string) => void,
    onSelectedSendAtsChange: (sendAtMoments: moment.Moment[], sendAtTypes:("time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay")[], sendAtTimezones: string[], sendAtDelaysSeconds:number[]) => void,
}
interface IState {
    newSenderPersonaPage:number,
}


class WorkflowDripCampaign extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            newSenderPersonaPage: 5,
        }
    }

    foundCreatedSenderPersona():boolean {
        if(!this.props.selectedSenderPersonas || Object.keys(this.props.selectedSenderPersonas).length == 0) {
            return false
        }

        //Compare fetched sender personas to local sender personas
        return !!Object.keys(this.props.senderPersonas).find((id:string) => this.props.selectedSenderPersonas.find(sp => id === sp.id))
    }

    onSelectSenderPersonaForm():boolean {
        const personaIndex = this.props.pageNum - this.state.newSenderPersonaPage
        if(personaIndex >= 0 && personaIndex < this.props.numEmails) {
            return true
        } 
       
        return false
    }

    onSelectTemplateForm():boolean {
        if(this.onSelectSenderPersonaForm()) {
            return false
        }
        
        const newTemplatePage = this.state.newSenderPersonaPage + this.props.numEmails
        const templateIndex = this.props.pageNum - newTemplatePage
        if(templateIndex >= 0 && templateIndex < this.props.numEmails) {
            return true
        } 
       
        return false
    }

    onDelayForm():boolean {
        if(this.onSelectSenderPersonaForm()) {
            return false
        }
        if(this.onSelectTemplateForm()) {
            return false
        }
        const newTemplatePage = this.state.newSenderPersonaPage + this.props.numEmails
        const delayPage = newTemplatePage + this.props.numEmails
        const delayIndex = this.props.pageNum - delayPage
        if(delayIndex >= 0 && delayIndex < this.props.numEmails) {
            return true
        } 
       
        return false
    }

    isSelectTemplateForm(): boolean {
        return this.props.pageNum === 5
    }
    
    isEventNamePage(): boolean {
        return (this.props.pageNum === 4 && !this.props.forSegment)
    }
    isSenderPersonasPage(): boolean {
        return this.props.pageNum === 4
    }
    isNumEmailsPage(): boolean {
        return this.props.pageNum === 3
    }
    isSelectSegmentsPage(): boolean {
        return (this.props.pageNum === 4 && this.props.forSegment)
    }

    isNextButtonEnabled() : boolean {
        if(this.isNumEmailsPage()) {
            if(this.props.numEmails) {
                return true
            }
        }

        if(this.isSelectSegmentsPage()) {
            if(Object.keys(this.props.selectedSegmentIds).length > 0) {
                return true
            }
        }

        if(this.isEventNamePage()) {
            if(this.props.eventName) {
                return true
            }
        }

        if(this.onSelectSenderPersonaForm()) {
            const personaIndex = this.props.pageNum - this.state.newSenderPersonaPage

            let numActualIds = 0
            for(let i = 0; i < this.props.selectedSenderPersonas.length; i++) {
                if(this.props.selectedSenderPersonas[i].id
                && this.props.senderPersonas && this.props.senderPersonas[this.props.selectedSenderPersonas[i].id]
                && this.props.senderPersonas[this.props.selectedSenderPersonas[i].id].id) {
                    numActualIds++
                }
            }
            if(numActualIds > personaIndex) {
                return true
            }
        }

        if(this.onSelectTemplateForm()) {
            const newTemplatePage = this.state.newSenderPersonaPage + this.props.numEmails
            const templateIndex = this.props.pageNum - newTemplatePage
            let numActualIds = 0
            for(let i = 0; i < Object.keys(this.props.selectedTemplateIds).length; i++) {
                if(this.props.templates && this.props.templates[this.props.selectedTemplateIds[i]]
                && this.props.templates[this.props.selectedTemplateIds[i]].id) {
                    numActualIds++
                }
            }
            if(numActualIds > templateIndex) {
                return true
            }
        }

        if(this.onDelayForm()) {
            const newTemplatePage = this.state.newSenderPersonaPage + this.props.numEmails
            const delayPage = newTemplatePage + this.props.numEmails
            const delayIndex = this.props.pageNum - delayPage
            let numDelays = 0
            for(let i = 0; i < this.props.selectedSendAtTypes.length; i++) {
                if(this.props.selectedSendAtTypes[i] && this.props.selectedSendAtMoments[i]) {
                    numDelays++
                }
            }
            if(numDelays > delayIndex) {
                return true
            }
        }

        return false
    }

    isSavePersonaButtonEnabled(): boolean {
        if(this.onSelectSenderPersonaForm()) {
            const personaIndex = this.props.pageNum - this.state.newSenderPersonaPage
            if(personaIndex >= 0 && this.props.isNewSenderPersonas[personaIndex]) {
                return true
            }
        }

        return false
    }

    getButtons() : JSX.Element { 
        const isNextDisabled = !this.isNextButtonEnabled()
        const isSaveDisabled = !this.isSavePersonaButtonEnabled()
        const prevButton = <Button color="primary" variant="contained" disabled={this.props.pageNum === 1} onClick={(e) => { e.stopPropagation(); e.preventDefault(); if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum-1) } }} style={{marginRight:"1rem"}}>&lt;&nbsp;&nbsp; Campaign Setup</Button>
        const nextButton = <Button disabled={isNextDisabled} color="primary" variant="contained" onClick={(e) => { e.stopPropagation(); e.preventDefault();  if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum+1) } }} style={{marginRight:"1rem"}}>Campaign Setup &nbsp;&nbsp;&gt;</Button>
        const savePersonaButton = <Button color="primary" disabled={isSaveDisabled} variant="contained" style={{marginRight:"1rem"}}
            startIcon={<Save />}
            onClick={(e) => { 
                e.stopPropagation(); 
                e.preventDefault(); 

                const personaIndex = this.props.pageNum - this.state.newSenderPersonaPage
                if(!this.props.isNewSenderPersonas[personaIndex]) {
                    console.warn("somehow ended up in save persona code while a non-new-persona option was selected")
                    return
                }
                
                const sp = this.props.selectedSenderPersonas[personaIndex]
                const id = sp.id? sp.id: uuidv4()

                this.props.dispatch(submitSenderPersonaAction(
                    id,
                    sp.first_name,
                    sp.last_name,
                    sp.email,
                    sp.json ? JSON.stringify(sp.json) : "",
                    (sp:SenderPersona) => {
                        const personaIndex = this.props.pageNum - this.state.newSenderPersonaPage
                        const selectedSenderPersonas = this.props.selectedSenderPersonas
                        selectedSenderPersonas[personaIndex].id = id
                        this.props.onSelectedSenderPersonasChange(selectedSenderPersonas, this.props.isNewSenderPersonas)

                        // Refetch so we know we can enable to next button
                        const ids = [id]
                        for(let i = 0; i < this.props.selectedSenderPersonas.length; i++) {
                            if(this.props.selectedSenderPersonas[i] && this.props.selectedSenderPersonas[i].id) {
                                ids.push(this.props.selectedSenderPersonas[i].id)
                            }
                        }
                        this.props.dispatch(fetchSenderPersonas(ids))
        
                        showToast("Successfully saved sender persona, please log in to " + sp?.email + " and verify the account", "success")
                    })
                )
            }}>Save Persona</Button>
        

        const buttons = [prevButton, nextButton]
        if(this.onSelectSenderPersonaForm()) {
            buttons.push(savePersonaButton)
        }

        return <>{buttons}</>
    }

    render() {
        const personaIndex = this.props.pageNum - this.state.newSenderPersonaPage
        const newTemplatePage = this.state.newSenderPersonaPage + this.props.numEmails
        const templateIndex = this.props.pageNum - newTemplatePage
        const delayPage = newTemplatePage + this.props.numEmails
        const delayIndex = this.props.pageNum - delayPage

        return <>
        <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
            <Typography variant="h1" component="h1" style={{marginBottom:"1rem"}}>New Campaign</Typography>
                {this.isNumEmailsPage() && <>
                    <Typography variant="h2" component="p">
                        How many emails do you want in your email series?
                    </Typography>
                    <TextField value={this.props.numEmails} 
                        autoFocus={true} 
                        type='number'
                        style={{width:"100%", maxWidth:"500px", minWidth:"200px",marginBottom:"1rem"}} 
                        placeholder='Num emails in series'
                        onChange={(e) => { 
                            e.stopPropagation(); 
                            e.preventDefault();
                            this.props.onNumEmailsChange(parseInt(e.target?.value?.toString() || "0"))
                        }} />    
                </>}
                {this.isSelectSegmentsPage() && <>
                    <SelectSegments 
                        selectedSegmentIds={this.props.selectedSegmentIds}
                        width={this.props.width} 
                        dispatch={this.props.dispatch}
                        onSegmentsChange={this.props.onSegmentsChange} />    
                </>}
                {this.isEventNamePage() && <>
                    <Typography variant="h2" component="p">
                        What event will you be sending to our api?
                    </Typography>
                    <TextField value={this.props.eventName} autoFocus={true} style={{width:"100%", maxWidth:"500px", minWidth:"200px",marginBottom:"1rem"}} placeholder='Event name'
                        onChange={(e) => { 
                            e.stopPropagation(); 
                            e.preventDefault(); 
                            this.props.onEventNameChange(e.target?.value?.toString())
                        }} />
                </>}
                {this.onSelectSenderPersonaForm() && <>
                    <SelectSenderPersona 
                        key={personaIndex}
                        numEmails={this.props.numEmails}
                        emailNum={personaIndex+1}
                        onSenderPersonaChange={(sp:SenderPersona, isNew:boolean) => {
                            const senderPersonas = this.props.selectedSenderPersonas 
                            senderPersonas[personaIndex] = sp
                            const isNewSenderPersonas = this.props.isNewSenderPersonas 
                            isNewSenderPersonas[personaIndex] = isNew

                            this.props.onSelectedSenderPersonasChange(senderPersonas, isNewSenderPersonas)
                        }}
                        senderPersona={this.props.selectedSenderPersonas[personaIndex]}
                        isNewSenderPersona={this.props.isNewSenderPersonas[personaIndex]}
                        width={this.props.width}
                        dispatch={this.props.dispatch} /></>}
                {this.onSelectTemplateForm()  && 
                    <SelectTemplate 
                        key={templateIndex}
                        numEmails={this.props.numEmails}
                        emailNum={templateIndex+1}
                        selectedTemplateIds={this.props.selectedTemplateIds}
                        onTemplateChange={(t:Template, isNew:boolean) => {
                            const templateIds = this.props.selectedTemplateIds 
                            templateIds[templateIndex] = t.id
                            const isNewTemplates = this.props.isNewTemplates
                            isNewTemplates[templateIndex] = isNew 

                            this.props.onSelectedTemplateIdsChange(templateIds, isNewTemplates)
                        }} 
                        templateId={this.props.selectedTemplateIds[templateIndex]}
                        isNewTemplate={this.props.isNewTemplates[templateIndex]}
                        width={this.props.width} 
                        dispatch={this.props.dispatch} />}
                {this.onDelayForm() && 
                    <SelectEmailDelays
                        key={delayIndex}
                        width={this.props.width}
                        dispatch={this.props.dispatch}
                        sendAtMoment={this.props.selectedSendAtMoments[delayIndex]}
                        sendAtType={this.props.selectedSendAtTypes[delayIndex]}
                        sendAtTimezone={this.props.selectedSendAtTimezones[delayIndex]}
                        sendAtDelaySeconds={this.props.selectedSendAtDelaySeconds[delayIndex]}
                        emailNum={delayIndex + 1}
                        onSendAtChange={(sendAtMoment: moment.Moment, sendAtType:"time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay", sendAtTimezone: string, sendAtDelaySeconds:number) => {
                            const selectedSendAtMoments = this.props.selectedSendAtMoments 
                            selectedSendAtMoments[delayIndex] = sendAtMoment
                            const selectedSendAtTypes = this.props.selectedSendAtTypes 
                            selectedSendAtTypes[delayIndex] = sendAtType
                            const selectedSendAtTimezones = this.props.selectedSendAtTimezones
                            selectedSendAtTimezones[delayIndex] = sendAtTimezone
                            const selectedSendAtDelaySeconds = this.props.selectedSendAtDelaySeconds
                            selectedSendAtDelaySeconds[delayIndex] = sendAtDelaySeconds

                            this.props.onSelectedSendAtsChange(selectedSendAtMoments, selectedSendAtTypes, selectedSendAtTimezones, selectedSendAtDelaySeconds)
                        }}

                    />}
            <div style={{marginTop:"1rem"}}>
                {this.getButtons()}
            </div>
            {this.isSelectSegmentsPage() && <div>
                {Object.keys(this.props.selectedSegmentIds).length === 0 && <Typography style={{color:"red", marginTop:"1rem", marginBottom:"1rem"}} variant={"body1"}>
                    Please select one or more segments
                </Typography>}
            </div>}
        </Card>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(WorkflowDripCampaign)
