import React from "react";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer, 
    TableRow,
    Typography
} from "@material-ui/core";
import {connect} from "react-redux";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {getAuthKey, getGatewayUrl} from "./actions/Auth";

interface IState {
}

interface IProps {
}

class PageAPI extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    render() {
        return <>
            <Card elevation={defaultCardElevation}  style={{...defaultCardStyles}}>
                <Typography variant={"h1"} component={"div"}>API Endpoints</Typography>
                <hr />

                <Typography variant={"body1"} component={"p"}>
                    If you want to trigger emails based on events, such as
                    when one of your customers updates their password or purchases a product, then you'll want to
                    send an event from your backend code to our events api.  The name field in the POST payload
                    must exactly match the name field of the workflow trigger that will react to the event.
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}>
                    <i>The api key below is like a password to your account, please
                        keep it safe by only using it in your
                        &nbsp;<a href={"https://en.wikipedia.org/wiki/Front_end_and_back_end"} target={"_blank"} rel="noreferrer">backend</a>&nbsp;
                        code and only over https.</i>
                </Typography>
                <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem"}}><b>Endpoint details</b></Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>URL</TableCell>
                                <TableCell>{getGatewayUrl("event")}/events</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Method</TableCell>
                                <TableCell>POST</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Headers</TableCell>
                                <TableCell>"x-api-key": "{getAuthKey()}"</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Sample Payload</TableCell>
                                <TableCell><code>{JSON.stringify({
                                    events: [{
                                        name: "sale",
                                        email: "yourcustomersemail@probablygmail.com",
                                        metric1: 100
                                    }]
                                })}</code></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageAPI)
