import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
    GetScheduledEmailsResponsePayload,
    ScheduleEmailPayload,
    ScheduleEmailResponsePayload,
    ScheduledEmailGroup,
    WeightedTemplateID
} from "../generated/types/payloadTypes";
import {getAuthInfo, getAuthKey, getGatewayUrl} from "./Auth";
import moment from "moment-timezone";
import { usersLocalTimezone } from "../workflow/NodeSettings";

export const REQUEST_EMAIL_BLASTS = 'REQUEST_EMAIL_BLASTS';
export const RECEIVE_EMAIL_BLASTS = 'RECEIVE_EMAIL_BLASTS';
export const REQUEST_NEW_EMAIL_BLAST = 'REQUEST_NEW_EMAIL_BLAST';
export const ARCHIVE_EMAIL_BLAST = 'ARCHIVE_EMAIL_BLAST'
export const ARCHIVE_EMAIL_BLAST_RESPONSE = 'ARCHIVE_EMAIL_BLAST_RESPONSE'

interface IRequestEmailBlastsAction {
    type: typeof REQUEST_EMAIL_BLASTS,
}
interface IReceiveEmailBlastsAction {
    type: typeof RECEIVE_EMAIL_BLASTS,
    data: any
}
interface IRequestNewEmailBlastAction {
    type: typeof REQUEST_NEW_EMAIL_BLAST,
}
interface IArchiveEmailBlastsAction {
    type: typeof ARCHIVE_EMAIL_BLAST,
    ids: string[],
}

interface IArchiveEmailBlastsResponseAction {
    type: typeof ARCHIVE_EMAIL_BLAST_RESPONSE,
    ids: string[],
}

export type EmailBlastActionTypes = IRequestEmailBlastsAction | IReceiveEmailBlastsAction 
    | IRequestNewEmailBlastAction | IArchiveEmailBlastsAction | IArchiveEmailBlastsResponseAction;

export function requestEmailBlastsAction() : IRequestEmailBlastsAction {
    return {
        type: REQUEST_EMAIL_BLASTS
    }
}

export function receiveEmailBlastsAction(data: any): IReceiveEmailBlastsAction {
    return {
        type: RECEIVE_EMAIL_BLASTS,
        data
    }
}

export function requestNewEmailBlastAction():IRequestNewEmailBlastAction {
    return {
        type: REQUEST_NEW_EMAIL_BLAST
    }
}

export function archiveEmailBlastsAction(ids: string[]):IArchiveEmailBlastsAction {
    return {
        type: 'ARCHIVE_EMAIL_BLAST',
        ids,
    }
}

export function archiveEmailBlastResponseAction(ids: string[]):IArchiveEmailBlastsResponseAction {
    return {
        type: 'ARCHIVE_EMAIL_BLAST_RESPONSE',
        ids,
    }
}

export function fetchEmailBlasts(ids?:string[])  {
    return function(dispatch:(...args:any)=>any) {
        dispatch(requestEmailBlastsAction());
        return fetchEmailBlastsPromise(dispatch, ids)
    }
}

export function submitEmailBlastAction(id:string, name:string, templateId:string, templateIdsWithWeights:WeightedTemplateID[], senderPersonaId:string, segmentIds:string[],
    userIds:string[], sendAtDate:moment.Moment,timezone:string, 
    onSuccess?: (blast:ScheduledEmailGroup) => any, onFailure?: (errorString:string) => any) {
    
        return function(dispatch:(...args:any)=>any) {
        dispatch(requestNewEmailBlastAction());
        return postEmailBlastPromise(dispatch, id, name,templateId,templateIdsWithWeights, senderPersonaId,segmentIds,userIds, sendAtDate, timezone,
            onSuccess, onFailure)
    }
}

export function archiveEmailBlasts(ids: string[], onSuccess?: () => void, onFailure?: (reason:string) => void) {
    return function(dispatch:(...args:any)=>any) {
      dispatch(archiveEmailBlastsAction(ids));
      return archiveBlastPromise(dispatch, ids, onSuccess, onFailure);
    }
  }

function fetchEmailBlastsPromise(dispatch: any, ids?:string[]) {
    //Get sender templates from api endpoint
    let url = getGatewayUrl("scheduler") + "/scheduler";
    if(ids && ids.length > 0) {
        url += "?ids=" + ids.join(",");
    }
    console.log(url);
    fetch(url, {
        method: 'get',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': getAuthKey(),
        }
    })
    .then(res => res.json())
    .then((json: GetScheduledEmailsResponsePayload) => {
        if(json.data) {
            console.log(`Found ${json.data.length} email blasts`);
        }
        if(json.errors && json.errors.length > 0) {
            dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_EMAIL_BLASTS));
            showToast(<>Couldn't fetch email blasts: {errorsToUL(json.errors)}</>, "error");
        }
        dispatch(receiveEmailBlastsAction(json.data))
    }).catch(e => {
        dispatch(requestDataErrorAction(e.message,REQUEST_EMAIL_BLASTS));
        showToast(`Couldn't fetch email blasts: ${denastifyError(e.message)}`, "error");
    });
}

// export function potentiallyConvertLocalToUTC(sendAtDate: Date, isUsersLocalTimezone: boolean): moment.Moment {
//     // Create a utc version of the date with the same hours/minutes
//     // 10:11am eastern will become 10:11am UTC for instance
//     if(isUsersLocalTimezone) {
//         const localDate = moment(sendAtDate).format()
//         const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
//         const newDate = moment(localDate).tz(localTZ).tz("UTC", true)
//         return newDate
//     }

//     return moment(sendAtDate)
// }

function postEmailBlastPromise(dispatch:(...args:any)=>any, id:string, name:string, templateId:string, templateIdsWithWeights:WeightedTemplateID[],
           senderPersonaId:string, segmentIds:string[], userIds:string[], sendAtDate:moment.Moment, timezone:string,
           onSuccess?: (blast:ScheduledEmailGroup) => any, onFailure?: (errorString:string) => any) {

    //Adjust send at date if needed.
    //If we have a date selection of 1/1/2001 10:11:00 ET and we want to send in the users local timeone, 
    //then this will convert to      1/1/2001 10:11:00 UTC
    if(timezone === usersLocalTimezone) {
        sendAtDate = moment(sendAtDate.format("YYYY-MM-DDTHH:mm:ssZ"))
    }

    //The way we have types set up in the backend is a bit weird
    //If we want to use the generated types here we need to
    //shiv in timezone because the generated outer layer needs to not 
    //contain timezone on the backend but does need it in the details.
    //We do some kind of collapsed json structure read on the backend that makes this work
    const data:ScheduleEmailPayload = {
        id,
        name,
        template_ids_with_weights: templateIdsWithWeights,
        template_id: templateId,
        sender_persona_id: senderPersonaId,
        to_segment_ids: segmentIds,
        to_user_ids: userIds,
        to_user_emails: null,
        bcc_to_user_ids: null,
        cc_to_user_ids: null,
        send_at: sendAtDate,
        send_in_users_timezone: timezone === usersLocalTimezone,
        timezone,
    }
    console.log("Creating email blast with data", JSON.stringify(data));
    const url = getAuthInfo().endpoints["api_scheduler_gateway"] + "/scheduler";
    fetch(url, {
        method: 'post',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': getAuthKey(),
        },
        body: JSON.stringify(data),
    })
    .then(res => res.json())
    .then((json:ScheduleEmailResponsePayload) => {
        if(!json.errors || json.errors.length == 0) {
            console.log("email blast post succeeded");
            if(onSuccess) {
                onSuccess(json.data)
            } else {
                showToast("Successfully saved email blast", "success");
            }
        } else {
            if(onFailure) {
                onFailure(denastifyError(JSON.stringify(json.errors)))
            } else {
                // dispatch(requestDataErrorAction(JSON.stringify(json.errors)));
                showToast(<>Couldn't save email blast: {errorsToUL(json.errors)}</>, "error");
            }
        }
    }).catch(e => {
        if(onFailure) {
            onFailure(denastifyError(e.message))
        } else {
            // dispatch(requestDataErrorAction(e.message));
            showToast(`Couldn't save email blast: ${denastifyError(e.message)}`, "error");
        }
    });
}


function archiveBlastPromise(dispatch:(...args:any)=>any, ids:string[], onSuccess?: () => void, onFailure?: (reason:string) => void) {
    if(!ids || ids.length === 0) {
        showToast("Couldn't archive email blast, missing email blast id", "error");
        return
    }

    console.log("Archiving email blast(s) " + ids.join(","));
    const url = getAuthInfo().endpoints["api_scheduler_gateway"] + "/scheduler/" + ids.join(",");
    fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': getAuthKey(),
        },
    })
    .then(res => res.json())
    .then((json:ScheduleEmailResponsePayload) => {
        if(!json.errors || json.errors.length == 0) {
            console.log("email blast archive succeeded");
            dispatch(archiveEmailBlastResponseAction(ids))
            if(onSuccess){
                onSuccess()
            } else {
                showToast("Successfully archived email blast", "success");
            }
        } else {
            // dispatch(requestDataErrorAction(JSON.stringify(json.errors)));
            if(onFailure) {
                onFailure(json.errors.join(", "))
            } else {
                showToast(<>Couldn't archive email blast: {errorsToUL(json.errors)}</>, "error");
            }
        }
    }).catch(e => {
        // dispatch(requestDataErrorAction(e.message));
        if(onFailure) {
            onFailure(denastifyError(e.message))
        } else {
            showToast(`Couldn't archive email blast: ${denastifyError(e.message)}`, "error");
        }
    });
}
