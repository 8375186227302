import React, {ChangeEvent} from 'react';
import {connect} from "react-redux";
import {archiveWorkflows, fetchWorkflows,REQUEST_WORKFLOWS} from "./actions/Workflows";
import {Loading} from "./components/Loading";
import {
    Card, Chip, 
    Grid, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography, 
} from "@material-ui/core"
import {Link} from "react-router-dom";
import {Add, AddCircle, Delete, Edit, Remove, Stop} from "@material-ui/icons";
import {Workflow} from "./generated/types/payloadTypes";
import {MyArchiveButton, MyCancelButton, MyModal, MyOkButton} from "./Dialog";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {AllState} from "./reducers";
import {InformationArea} from "./components/InformationArea";
import Fab from "@material-ui/core/Fab";
import {v4 as uuidv4} from "uuid";
import { WorkflowIcon } from './Icons';
import moment from 'moment';

interface IState {
    id: string,
    selectedWorkflowId: string,    //For select
    selectedWorkflowIds: string[], //For multi-select
    workflows: {[key:string]:Workflow},
    upForDeletionWorkflow?: Workflow,
    upForDisablingWorkflow?: Workflow,
}

interface IProps {
    dispatch: any,
    type: string,
    fetchedWorkflows?: {[key:string]:Workflow},
    receivingWorkflows?: boolean,
    selectedWorkflowIds?: string[],
    selectedWorkflowId?: string,
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    onSelectedWorkflowIdsChange? : (ids: string[]) => any
    reportErrors?: {[key:string]:string},
    style?: any,
    label?: string,
    placeholder?: string,
    required?: boolean,
    width:string,
}

class ListWorkflows extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    classes: any;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4(),
            selectedWorkflowId: this.props.selectedWorkflowId || this.props.placeholder?"placeholder": "",
            selectedWorkflowIds: this.props.selectedWorkflowIds || [],
            workflows: {},
        };
        this.workflowChange = this.workflowChange.bind(this);
        this.archiveWorkflow = this.archiveWorkflow.bind(this);
    }

    componentDidMount(){
        this.props.dispatch(fetchWorkflows());
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(nextProps.fetchedWorkflows) {
            const selectedWorkflowId = prevState.selectedWorkflowId;
            return {
                ...prevState,
                selectedWorkflowId,
                workflows: nextProps.fetchedWorkflows,
            }
        }

        return null;
    }

    renderWorkflowSelections(selectedIds: string[]) {
        const selectedWorkflows = selectedIds.map(id => this.state.workflows[id]);
        const selectedWorkflowsDisplay = selectedWorkflows.map(workflow => workflow?workflow.name:null);

        return <div>
            {selectedWorkflowsDisplay.map((displayString:string,index:number) => {
                if(displayString) {
                    return <Chip key={"chip"+index} label={displayString} />
                }

                return <Chip key={"chip"+index} label={"Unknown/archived workflow"} />
            })}
        </div>
    }

    workflowChangeSimple(selectedWorkflowId:string, name:string) {
        if(this.props.type == "select") {
            this.setState({
                ...this.state,
                selectedWorkflowId,
            });
            if(this.props.onSelectedWorkflowIdsChange) {
                this.props.onSelectedWorkflowIdsChange([selectedWorkflowId])
            }
        } else if(this.props.type == "multiselect") {
            const newIds = [
                ...this.state.selectedWorkflowIds,
                selectedWorkflowId,
            ]
            this.setState({
                ...this.state,
                selectedWorkflowIds: newIds,
            });

            if(this.props.onSelectedWorkflowIdsChange) {
                this.props.onSelectedWorkflowIdsChange(newIds)
            }
        } else {
            console.error("unknown workflow component type " + this.props.type)
        }
    }

    workflowChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedWorkflowId: "",
                selectedWorkflowIds: [],
            });
            return;
        }

        const selectedWorkflowId = event.target.value.toString()
        this.workflowChangeSimple(selectedWorkflowId, "")

        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    archiveWorkflow(id: string, name: string) {
        console.log(`Archiving workflow ${id} ${name}`);
        this.props.dispatch(archiveWorkflows([id], [name]));
    }

    truncateText(text: string, len = 90) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    nameColumnWidth(): string {
        switch(this.props.width) {
            case "xs":
                return "5rem"
            case "sm":
                return "7rem"
            case "md":
                return "8rem"
            default:
                return "10rem"
        }
    }

    
    selectMenuItems(excludeSelected:boolean): JSX.Element[] {
        if(!this.state.workflows || Object.values(this.state.workflows).length===0) {
            return []
        }
        
        const options:JSX.Element[] = []
        Object.values(this.state.workflows).map(workflow => {
            if(excludeSelected && (
                this.state.selectedWorkflowId == workflow.id ||
                this.state.selectedWorkflowIds.find(id => workflow.id == id)   
            )) {
                return
            }

            options.push(<MenuItem key={workflow.id} value={workflow.id}>{workflow.name}</MenuItem>)
        })

        return options
    }


    selectMenuItemsPlain(excludeSelected:boolean): {value:string, label:string}[] {
        if(!this.state.workflows || Object.values(this.state.workflows).length===0) {
            return []
        }
        
        const options: {value:string, label:string}[] = []
        Object.values(this.state.workflows).map(workflow => {
            if(excludeSelected && (
                this.state.selectedWorkflowId == workflow.id ||
                this.state.selectedWorkflowIds.find(id => workflow.id == id)   
            )) {
                return
            }

            options.push({value: workflow.id, label:workflow.name})
        })

        return options
    }


    getWorkflowSelect(excludeSelected: boolean) : JSX.Element {
        return <Select id={"workflowSelect"+this.state.id}
                required={!!this.props.required}
                MenuProps={{ disableScrollLock: true }}
                value={this.state.selectedWorkflowId}
                onChange={this.workflowChange}
                style={{width:"100%"}}
                >
            { this.props.placeholder && <MenuItem key="selectplaceholder" value="placeholder" disabled selected>{this.props.placeholder}</MenuItem>}
            {  this.selectMenuItems(excludeSelected) }
        </Select>
    }

    getMultiSelectForm() : JSX.Element {
        const selectedObjects: JSX.Element  = <>{this.state.selectedWorkflowIds.map((id:string, index:number) => {
            if(!this.props.fetchedWorkflows || Object.keys(this.props.fetchedWorkflows).length == 0) {
                return <></>
            }

            const thisWorkflow = this.props.fetchedWorkflows[id]
            if(!thisWorkflow) {
                console.error("Missing workflow " + id)
                return <></>
            }

            return <Grid container 
                    wrap="nowrap"  
                    key={"workflow" + id + index.toString()} 
                    alignItems={"flex-end"} 
                    style={{borderBottom:"1px dotted #ccc", margin:"0", padding:"0", width:"100%", minWidth:"200px"}}>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{minWidth:"30px"}}>
                    <WorkflowIcon />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={10} xl={10}>
                    <Link to={"/components/workflows/edit/"+thisWorkflow.id}>
                    <Typography variant={"body1"} component={"p"} 
                        title={thisWorkflow.name}
                        style={{whiteSpace:"nowrap", width:"100%", textOverflow:"ellipsis", overflow:"hidden"}}>
                        {thisWorkflow.name}
                    </Typography>
                    </Link>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={1} xl={1} className='clearfix'>
                    <Link to={""} style={{float:"right"}} onClick={(ev) => {
                        ev.stopPropagation()
                        ev.preventDefault()

                        const myWorkflowIds = []
                         for(let i = 0; i < this.state.selectedWorkflowIds.length; i++) {
                             if(thisWorkflow.id != this.state.selectedWorkflowIds[i]) {
                                myWorkflowIds.push(this.state.selectedWorkflowIds[i])
                             }
                         }
 

                        this.setState({
                            ...this.state,
                            selectedWorkflowIds: myWorkflowIds,
                        })

                        if(this.props.onSelectedWorkflowIdsChange) {
                            this.props.onSelectedWorkflowIdsChange(myWorkflowIds)
                         } 
                    }}><Delete /></Link>
                </Grid>
            </Grid>
        })}</>

        return <>
        <div style={{marginBottom:"1rem"}}>
            {this.getWorkflowSelect(true)}
        </div>
        <div>
            {selectedObjects}
        </div></>
    }

    dateField(df: moment.Moment): string {
        if(!df) {
            return ""
        }
        if(typeof df == "string") {
            df = moment(df)
        }
        return df.toDate().toLocaleString()
    }

    render() {
        return <div className={'workflow-list'}>
            { this.props.type === 'list' &&
            <Card style={{...defaultCardStyles, ...this.props.style}} elevation={defaultCardElevation}>
                <Grid container>
                    <Grid item>
                        <Typography variant={"h1"} component={"div"}>Workflows</Typography>
                    </Grid>
                    <Grid item style={{marginLeft:"10px"}}>
                        <Link to={"/components/workflows/new"} title={"Create a new workflow"}>
                            <Fab size={"small"} color={"primary"} aria-label={"Create a new workflow"}>
                                <Add />
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>
                <hr />

                {/* Errors */ }
                {this.props.reportErrors[REQUEST_WORKFLOWS] && <p className={"error-text"}>
                    Unable to load workflows: {this.props.reportErrors[REQUEST_WORKFLOWS]}
                </p>}

                {/* Loading */}
                {!this.props.reportErrors[REQUEST_WORKFLOWS] && this.props.receivingWorkflows && <div style={{minHeight:"300px", paddingTop:"2rem"}}><Loading /></div>}

                {/* Confirmation dialogs */}
                {this.state.upForDeletionWorkflow && <MyModal title={"Archive workflow?"}
                       content={
                           <>
                               <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                                   Are you sure you want to archive {this.state.upForDeletionWorkflow.name?
                                   <>"<i>{this.state.upForDeletionWorkflow.name}</i>?"</>:<>this workflow?</>}
                               </Typography>
                               <InformationArea type={"warning"}  style={{marginBottom:"1rem"}}
                                                contents={"This action will permanently stop all contacts and events from moving " +
                                                    " through this workflow, which means they won't be able to receive emails from it, etc. " +
                                                    "This can't be undone!"}  />
                               <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                                   To disable this workflow instead (so that contacts currently in the flow can finish going through it), please
                                   <Link to={"/components/workflows/edit/" + this.state.upForDeletionWorkflow.id} style={{marginLeft:"4px", marginRight:"4px"}}>edit it</Link>
                                   instead and remove any links that extend out from the root/trigger node.
                               </Typography>
                           </>
                       }
                       buttons={<>
                           <MyCancelButton onClick={(event:any) => {
                               event.stopPropagation();
                               event.preventDefault();
                               this.setState({
                                   ...this.state,
                                   upForDeletionWorkflow: null,
                               });
                           }}/>
                           <MyArchiveButton onClick={(event:any) => {
                               event.stopPropagation();
                               event.preventDefault();

                               this.archiveWorkflow(this.state.upForDeletionWorkflow.id, this.state.upForDeletionWorkflow.name);
                               this.setState({
                                   ...this.state,
                                   upForDeletionWorkflow: null,
                               });
                           }}/>
                       </>}
                />}
                {this.state.upForDisablingWorkflow && <MyModal title={"Please edit the workflow"}
                    content={
                        <>
                            <Typography variant={"body1"} component={"p"} style={{marginBottom:"15px"}}>
                                To disable workflow "{this.state.upForDisablingWorkflow.name}", please
                                <Link to={"/components/workflows/edit/" + this.state.upForDisablingWorkflow.id} style={{marginLeft:"4px", marginRight:"4px"}}>edit it</Link>
                                and remove any links that extend out from the root/trigger node.
                            </Typography>
                            <Typography variant={"body1"} component={"p"}>
                                That way all events/contacts that are already in the workflow can continue through the workflow successfully, while no
                                new events or contacts will trigger any workflow actions.
                            </Typography>
                        </>
                    }
                    buttons={<MyOkButton onClick={(event:any) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.setState({
                            ...this.state,
                            upForDisablingWorkflow: null,
                        });
                    }}/>}
                />}

                {/* Main table */}
                {!this.props.reportErrors[REQUEST_WORKFLOWS] && !this.props.receivingWorkflows &&  <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component={"th"} style={{width:"80px"}} />
                            <TableCell component={"th"} style={{maxWidth:this.nameColumnWidth()}}>Name</TableCell>
                            <TableCell component={"th"}>Added</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!this.state.workflows || Object.values(this.state.workflows).length === 0) && <TableRow><TableCell colSpan={3}>Nothing here, yet</TableCell></TableRow> }
                        {this.state.workflows && Object.values(this.state.workflows).map((workflow) => (
                            <TableRow key={workflow.id}>
                                <TableCell style={{width:"80px"}}>
                                    <Grid container>
                                        <Grid item>
                                            <Link target="_blank" to={"/components/workflows/edit/" + workflow.id} className="svgShadow">
                                                <Edit />
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link to={""} className="svgShadow" onClick={(event:any) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                this.setState({
                                                    ...this.state,
                                                    upForDisablingWorkflow: workflow,
                                                });
                                            }}>
                                                <Stop />
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link to={""} className="svgShadow" onClick={(event:any) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                this.setState({
                                                    ...this.state,
                                                    upForDeletionWorkflow: workflow,
                                                });
                                            }}>
                                                <Delete />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{
                                    maxWidth:this.nameColumnWidth(),
                                }}><span title={workflow.name} style={{
                                    textOverflow:"ellipsis",
                                    overflow:"hidden",
                                    display: "inline-block",
                                    width: "100%",
                                    wordWrap:"break-word",
                                }}>{this.truncateText(workflow.name)}</span></TableCell>
                                <TableCell>{this.dateField(workflow.created_at)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>}
            </Card>}
            {this.props.type === 'select' && !this.props.receivingWorkflows && 
                this.getWorkflowSelect(false)
            } 
            {this.props.type === 'select' && this.props.receivingWorkflows && 
                <Loading mySize='sm' style={{marginTop:"4px"}}  text={<Typography variant="body1" component="span" style={{fontSize:"1rem", paddingLeft:"5px"}}>
                    Fetching workflows
                </Typography>} />
            } 
            { this.props.type === 'multiselect' && <>
                {this.props.receivingWorkflows && <Loading mySize={"sm"} />}
                {!this.props.receivingWorkflows && this.getMultiSelectForm()}
            </>
            }
        </div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    let fetchedWorkflows = null;
    if(state.workflows && Object.keys(state.workflows).length > 0) {
        fetchedWorkflows = state.workflows;
    }

    return { ...state, fetchedWorkflows };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListWorkflows);


