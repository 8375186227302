import React from "react";
import {showToast} from "../ToastNotifications";
import {
  LoginPayload,
  LoginResponsePayload,
  SignUpPayload,
  SignUpResponsePayload,
  AddCompanyUserResponsePayload,
  RemoveCompanyUserResponsePayload,
  GetCompanyUsersResponsePayload,
  AddCompanyUserPayload,
  RemoveCompanyUserPayload,
  CompanyUser,
} from "../generated/types/payloadTypes";
import cookies from "js-cookie";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import moment from "moment";

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_RESPONSE = 'SIGNUP_RESPONSE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const SIGNUP_PASSWORD = "SIGNUP_PASSWORD"; //Special const used to pass signup errors to specific password component
export const GET_COMPANY_USERS_REQUEST = 'GET_COMPANY_USERS_REQUEST';
export const GET_COMPANY_USERS_RESPONSE = 'GET_COMPANY_USERS_RESPONSE';
export const ADD_COMPANY_USER_REQUEST = 'ADD_COMPANY_USER_REQUEST';
export const ADD_COMPANY_USER_RESPONSE = 'ADD_COMPANY_USER_RESPONSE';
export const REMOVE_COMPANY_USER_REQUEST = 'REMOVE_COMPANY_USER_REQUEST';
export const REMOVE_COMPANY_USER_RESPONSE = 'REMOVE_COMPANY_USER_RESPONSE';

type ISignUpRequest = {
  type: typeof SIGNUP_REQUEST,
} & SignUpPayload
type ISignUpResponse = {
  type: typeof SIGNUP_RESPONSE,
  code: number,
} & SignUpResponsePayload
type ILoginRequest = {
  type: typeof LOGIN_REQUEST,
} & LoginPayload
type ILoginResponse = {
  type: typeof LOGIN_RESPONSE,
} & LoginResponsePayload
type IGetCompanyUsersRequest = {
  type: typeof GET_COMPANY_USERS_REQUEST
} 
type IGetCompanyUsersResponse = {
  type: typeof GET_COMPANY_USERS_RESPONSE,
} & GetCompanyUsersResponsePayload
type IAddCompanyUserRequest = {
  type: typeof ADD_COMPANY_USER_REQUEST,
} & AddCompanyUserPayload
type IAddCompanyUserResponse = {
  type: typeof ADD_COMPANY_USER_RESPONSE,
} & AddCompanyUserResponsePayload
type IRemoveCompanyUserRequest = {
  type: typeof REMOVE_COMPANY_USER_REQUEST,
} & RemoveCompanyUserPayload
type IRemoveCompanyUserResponse = {
  type: typeof REMOVE_COMPANY_USER_RESPONSE,
  removedUser: CompanyUser,
} & RemoveCompanyUserResponsePayload

export type AuthActionTypes = ISignUpRequest | ISignUpResponse | ILoginRequest | ILoginResponse 
  | IGetCompanyUsersRequest | IGetCompanyUsersResponse | IAddCompanyUserRequest | IAddCompanyUserResponse 
  | IRemoveCompanyUserRequest | IRemoveCompanyUserResponse;

export function signUpAction(payload: SignUpPayload):ISignUpRequest {
  return {
    type: 'SIGNUP_REQUEST',
    ...payload,
  }
}

export function signUpResponseAction(payload: SignUpResponsePayload, code:number):ISignUpResponse {
  return {
    type: 'SIGNUP_RESPONSE',
    ...payload,
    code,
  }
}

export function loginAction(payload: LoginPayload):ILoginRequest {
  return {
    type: 'LOGIN_REQUEST',
    ...payload,
  }
}

export function loginResponseAction(payload: LoginResponsePayload):ILoginResponse {
  return {
    type: 'LOGIN_RESPONSE',
    ...payload,
  }
}


export function getCompanyUsersAction():IGetCompanyUsersRequest {
  return {
    type: GET_COMPANY_USERS_REQUEST,
  }
}

export function getCompanyUsersResponseAction(payload: GetCompanyUsersResponsePayload):IGetCompanyUsersResponse {
  return {
    type: GET_COMPANY_USERS_RESPONSE,
    ...payload,
  }
}

export function addCompanyUserAction(payload: AddCompanyUserPayload):IAddCompanyUserRequest {
  return {
    type: ADD_COMPANY_USER_REQUEST,
    ...payload,
  }
}

export function addCompanyUserResponseAction(payload: AddCompanyUserResponsePayload):IAddCompanyUserResponse {
  return {
    type: ADD_COMPANY_USER_RESPONSE,
    ...payload,
  }
}

export function removeCompanyUserAction(payload: RemoveCompanyUserPayload):IRemoveCompanyUserRequest {
  return {
    type: REMOVE_COMPANY_USER_REQUEST,
    ...payload,
  }
}

export function removeCompanyUserResponseAction(removedUser: CompanyUser, errors: any[]):IRemoveCompanyUserResponse {
  return {
    type: REMOVE_COMPANY_USER_RESPONSE,
    errors,
    removedUser,
  }
}

export function signUp(payload: SignUpPayload) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(signUpAction(payload));
    return signUpPromise(dispatch, payload);
  }
}

export function login(payload: LoginPayload) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(loginAction(payload));
    return loginPromise(dispatch, payload);
  }
}


export function getCompanyUsers() {
  return function(dispatch:(...args:any)=>any) {
    dispatch(getCompanyUsersAction());
    return getCompanyUsersPromise(dispatch);
  }
}


export function addCompanyUser(payload: AddCompanyUserPayload) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(addCompanyUserAction(payload));
    return addCompanyUserPromise(dispatch, payload);
  }
}


export function removeCompanyUser(payload: RemoveCompanyUserPayload) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(removeCompanyUserAction(payload));
    return removeCompanyUserPromise(dispatch, payload);
  }
}

// export function requestWalkthrough(payload: LoginPayload) {
//   return function(dispatch:(...args:any)=>any) {
//     dispatch(loginAction(payload));
//     return loginPromise(dispatch, payload);
//   }
// }


export function getImagesBucketUrl() : string {
  const allInfo = getAuthInfo();

  //Return early if we don't have anything
  if(!allInfo || !allInfo.endpoints || Object.values(allInfo.endpoints).length === 0) {
    return "";
  }

  if(!allInfo.company || !allInfo.company.name) {
    return "";
  }
  if(!allInfo.project || !allInfo.project.name) {
    return "";
  }

  return `https://finaldavellc-${allInfo.company.name}-${allInfo.project.name}-pub.s3.amazonaws.com`;
}

export function getGatewayUrl(gatewayName: string) : string {
  const allInfo = getAuthInfo();

  //Return early if we don't have anything
  if(!allInfo || !allInfo.endpoints || Object.values(allInfo.endpoints).length === 0) {
    return "";
  }
  const key = `api_${gatewayName}_gateway`;
  return allInfo.endpoints[key];
}

export function inSandbox():boolean {
  const allInfo = getAuthInfo();

  //Return early if we don't have anything
  if(!allInfo) {
    return null;
  }

  return !allInfo.not_in_sandbox;
}

export function storeAuthInfo(info: LoginResponsePayload) {
  
  const expires = moment(moment().unix()+(24*60*60*1000), "X");
  cookies.set("user_info", JSON.stringify(info), { expires: expires.toDate(), sameSite: "strict" });
  console.log("User info stored, expiration is " + expires.toISOString())
}

export function deleteAuthInfo() {
  console.log("Removing auth info");
  cookies.remove("user_info");
}
 
export function extendCookieTTL() {
  const authInfo = cookies.get("user_info");
  if(authInfo) {
    const expires =  moment(moment().unix()+(24*60*60*1000), "X")
    cookies.set("user_info", authInfo, { expires: expires.toDate(), sameSite: "strict" });
    console.log("Cookie expiration is now " + expires.toISOString())
  }
}

export function getAuthInfo() : LoginResponsePayload {
  const authInfo = cookies.get("user_info");
  if(!authInfo) {
    console.warn("Didn't find auth info");
    return {} as LoginResponsePayload;
  }

  try {
    const parsedInfo = JSON.parse(authInfo);
    return parsedInfo as LoginResponsePayload;
  } catch(e:any) {
   console.error("couldn't convert auth string to object:" + e.message)
  }
  
  return {} as LoginResponsePayload;
}

export function getAuthKey(): string {
  const allInfo = getAuthInfo();

  if(!allInfo || !allInfo.api_client_key) {
    console.warn("Didn't find api client key");
    return null;
  }

  return allInfo.api_client_key;
}

function loginPromise(dispatch:(...args:any)=>any, payload:LoginPayload) {
  //Get sender users from api endpoint
  const url = process.env.REACT_APP_API_AUTH_WRAPPER_GATEWAY_URL + "/login";
  console.log("POSTING TO URL",url);
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(payload),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:LoginResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(loginResponseAction(null));
      showToast(<>Couldn't log in: {errorsToUL(json.errors)}</>, "error");
    } else {
      dispatch(loginResponseAction(json));
      showToast("You've been logged in!  One moment please....", "success");
    }
  }).catch(e => {
    dispatch(loginResponseAction(null));
    showToast("Couldn't log in: " + denastifyError(e.message), "error");
  });
}

function getCompanyUsersPromise(dispatch:(...args:any)=>any) {
  const authInfo = getAuthInfo()
  const url = getGatewayUrl("companyauthwrapper")  + "/company_users" + 
    "?company_name=" + encodeURIComponent(authInfo?.company?.name) +
    "&project_name=" + encodeURIComponent(authInfo?.project?.name) 
  console.log("REQUESTING TO URL",url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetCompanyUsersResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(getCompanyUsersResponseAction(null));
      showToast(<>Couldn't fetch team members: {errorsToUL(json.errors)}</>, "error");
    } else {
      dispatch(getCompanyUsersResponseAction(json));
    }
  }).catch(e => {
    dispatch(getCompanyUsersResponseAction(null));
    showToast("Couldn't fetch team members: " + denastifyError(e.message), "error");
  });
}

function addCompanyUserPromise(dispatch:(...args:any)=>any, payload: AddCompanyUserPayload) {
  const url = getGatewayUrl("companyauthwrapper")  + "/company_users" 
  console.log("REQUESTING TO URL",url);
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(payload),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:AddCompanyUserResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      if(json.errors[0].indexOf("cu_le_idx") !== -1) {
        json.errors[0] = "Another user with that email already exists"
      }
      dispatch(addCompanyUserResponseAction(null));
      showToast(<>Couldn't add team member: {errorsToUL(json.errors)}</>, "error");
    } else {
      dispatch(addCompanyUserResponseAction(json));
      showToast("Successfully added team member", "success");
    }
  }).catch(e => {
    dispatch(addCompanyUserResponseAction(null));
    showToast("Couldn't add team members " + denastifyError(e.message), "error");
  });
}

function removeCompanyUserPromise(dispatch:(...args:any)=>any, payload: RemoveCompanyUserPayload) {
  const url = getGatewayUrl("companyauthwrapper")  + "/company_users"
  console.log("REQUESTING TO URL",url);
  const options:any =  {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(payload),
  };
  fetch(url, options)
  .then(res => {
    if(res.status != 200) {
      return res.json()
    } 
    return {
      errors: []
    } 
  })
  .then((json:RemoveCompanyUserResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(removeCompanyUserResponseAction(null, json.errors));
      showToast(<>Couldn't remove team member: {errorsToUL(json.errors)}</>, "error");
    } else {
      dispatch(removeCompanyUserResponseAction(payload.company_user, null));
      showToast("Successfully removed team member", "success");
    }
  }).catch(e => {
    dispatch(removeCompanyUserResponseAction(null, [e.message]));
    showToast("Couldn't remove team member " + denastifyError(e.message), "error");
  });
}

async function signUpPromise(dispatch:(...args:any)=>any, payload:SignUpPayload) {
  (async (payload: SignUpPayload) => {
    //Get sender users from api endpoint
    const url = process.env.REACT_APP_API_AUTH_WRAPPER_GATEWAY_URL + "/signup";
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 3000); //We'll time out on purpose b/c this call takes forever to complete
    console.log("POSTING TO URL",url);
    const options:any =  {
      method: 'POST',
      mode: 'cors',
      signal: controller.signal,
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(payload),
    };
    let foundError = false;
    await fetch(url, options)
      .then(res => res.json())
      .then((json:SignUpResponsePayload) => {
        if(json.errors && json.errors.length > 0) {
          showToast(<>Couldn't sign you up: {errorsToUL(json.errors)}</>, "error");
          dispatch(signUpResponseAction(null, 400));
          dispatch(requestDataErrorAction(JSON.stringify(json.errors),SIGNUP_REQUEST));
          foundError = true;
        } else {
          dispatch(signUpResponseAction(json, 200));
        }
      }).catch(e => {
        if(e.name !== "AbortError") {
          dispatch(signUpResponseAction( null, 400));
          dispatch(requestDataErrorAction(e.message,SIGNUP_REQUEST));
          foundError = true;
          showToast("Couldn't sign up! " +denastifyError( e.message), "error");
        }
      });

    clearTimeout(id);
    if(!foundError) {
      showToast("Successfully signed up!", "success");
      dispatch(signUpResponseAction(null, 200));
    }
  })(payload);
}