import {FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {REQUEST_TEMPLATES} from "../actions/Templates";

export function TimezoneSelect(props:any) {
    return <FormControl style={props.style} error={props.error}>
        {props.label && <InputLabel id={"timezoneSelect"} required={!!props.required} >{props.label}</InputLabel>}
        <Select disabled={!!props.disabled} required={!!props.required} id={"timezoneSelect"} value={props.timezone} onChange={props.timezoneChange} label={"Timezone"} MenuProps={{ disableScrollLock: true }}>
            {props.firstOption && <MenuItem key={props.firstOption} value={props.firstOption}>{props.firstOption}</MenuItem>}
            <ListSubheader>United States</ListSubheader>
            <MenuItem key="America/Anchorage" value="America/Anchorage">America/Anchorage</MenuItem>
            <MenuItem key="America/Chicago" value="America/Chicago">America/Chicago</MenuItem>
            <MenuItem key="America/Denver" value="America/Denver">America/Denver</MenuItem>
            <MenuItem key="Pacific/Honolulu" value="Pacific/Honolulu">Pacific/Honolulu</MenuItem>
            <MenuItem key="America/Los_Angeles" value="America/Los_Angeles">America/Los_Angeles</MenuItem>
            <MenuItem key="America/New_York" value="America/New_York">America/New_York</MenuItem>
            <MenuItem key="America/Phoenix" value="America/Phoenix">America/Phoenix</MenuItem>
            <ListSubheader>Greater Americas</ListSubheader>
            <MenuItem key="America/Adak" value="America/Adak">America/Adak</MenuItem>
            <MenuItem key="America/Araguaina" value="America/Araguaina">America/Araguaina</MenuItem>
            <MenuItem key="America/Argentina/Buenos_Aires" value="America/Argentina/Buenos_Aires">America/Argentina/Buenos_Aires</MenuItem>
            <MenuItem key="America/Argentina/Catamarca" value="America/Argentina/Catamarca">America/Argentina/Catamarca</MenuItem>
            <MenuItem key="America/Argentina/Cordoba" value="America/Argentina/Cordoba">America/Argentina/Cordoba</MenuItem>
            <MenuItem key="America/Argentina/Jujuy" value="America/Argentina/Jujuy">America/Argentina/Jujuy</MenuItem>
            <MenuItem key="America/Argentina/La_Rioja" value="America/Argentina/La_Rioja">America/Argentina/La_Rioja</MenuItem>
            <MenuItem key="America/Argentina/Mendoza" value="America/Argentina/Mendoza">America/Argentina/Mendoza</MenuItem>
            <MenuItem key="America/Argentina/Rio_Gallegos" value="America/Argentina/Rio_Gallegos">America/Argentina/Rio_Gallegos</MenuItem>
            <MenuItem key="America/Argentina/Salta" value="America/Argentina/Salta">America/Argentina/Salta</MenuItem>
            <MenuItem key="America/Argentina/San_Juan" value="America/Argentina/San_Juan">America/Argentina/San_Juan</MenuItem>
            <MenuItem key="America/Argentina/San_Luis" value="America/Argentina/San_Luis">America/Argentina/San_Luis</MenuItem>
            <MenuItem key="America/Argentina/Tucuman" value="America/Argentina/Tucuman">America/Argentina/Tucuman</MenuItem>
            <MenuItem key="America/Argentina/Ushuaia" value="America/Argentina/Ushuaia">America/Argentina/Ushuaia</MenuItem>
            <MenuItem key="America/Asuncion" value="America/Asuncion">America/Asuncion</MenuItem>
            <MenuItem key="America/Atikokan" value="America/Atikokan">America/Atikokan</MenuItem>
            <MenuItem key="America/Bahia" value="America/Bahia">America/Bahia</MenuItem>
            <MenuItem key="America/Bahia_Banderas" value="America/Bahia_Banderas">America/Bahia_Banderas</MenuItem>
            <MenuItem key="America/Barbados" value="America/Barbados">America/Barbados</MenuItem>
            <MenuItem key="America/Belem" value="America/Belem">America/Belem</MenuItem>
            <MenuItem key="America/Belize" value="America/Belize">America/Belize</MenuItem>
            <MenuItem key="America/Blanc-Sablon" value="America/Blanc-Sablon">America/Blanc-Sablon</MenuItem>
            <MenuItem key="America/Boa_Vista" value="America/Boa_Vista">America/Boa_Vista</MenuItem>
            <MenuItem key="America/Bogota" value="America/Bogota">America/Bogota</MenuItem>
            <MenuItem key="America/Boise" value="America/Boise">America/Boise</MenuItem>
            <MenuItem key="America/Cambridge_Bay" value="America/Cambridge_Bay">America/Cambridge_Bay</MenuItem>
            <MenuItem key="America/Campo_Grande" value="America/Campo_Grande">America/Campo_Grande</MenuItem>
            <MenuItem key="America/Cancun" value="America/Cancun">America/Cancun</MenuItem>
            <MenuItem key="America/Caracas" value="America/Caracas">America/Caracas</MenuItem>
            <MenuItem key="America/Cayenne" value="America/Cayenne">America/Cayenne</MenuItem>
            <MenuItem key="America/Chihuahua" value="America/Chihuahua">America/Chihuahua</MenuItem>
            <MenuItem key="America/Costa_Rica" value="America/Costa_Rica">America/Costa_Rica</MenuItem>
            <MenuItem key="America/Creston" value="America/Creston">America/Creston</MenuItem>
            <MenuItem key="America/Cuiaba" value="America/Cuiaba">America/Cuiaba</MenuItem>
            <MenuItem key="America/Curacao" value="America/Curacao">America/Curacao</MenuItem>
            <MenuItem key="America/Danmarkshavn" value="America/Danmarkshavn">America/Danmarkshavn</MenuItem>
            <MenuItem key="America/Dawson" value="America/Dawson">America/Dawson</MenuItem>
            <MenuItem key="America/Dawson_Creek" value="America/Dawson_Creek">America/Dawson_Creek</MenuItem>
            <MenuItem key="America/Detroit" value="America/Detroit">America/Detroit</MenuItem>
            <MenuItem key="America/Edmonton" value="America/Edmonton">America/Edmonton</MenuItem>
            <MenuItem key="America/Eirunepe" value="America/Eirunepe">America/Eirunepe</MenuItem>
            <MenuItem key="America/El_Salvador" value="America/El_Salvador">America/El_Salvador</MenuItem>
            <MenuItem key="America/Fort_Nelson" value="America/Fort_Nelson">America/Fort_Nelson</MenuItem>
            <MenuItem key="America/Fortaleza" value="America/Fortaleza">America/Fortaleza</MenuItem>
            <MenuItem key="America/Glace_Bay" value="America/Glace_Bay">America/Glace_Bay</MenuItem>
            <MenuItem key="America/Godthab" value="America/Godthab">America/Godthab</MenuItem>
            <MenuItem key="America/Goose_Bay" value="America/Goose_Bay">America/Goose_Bay</MenuItem>
            <MenuItem key="America/Grand_Turk" value="America/Grand_Turk">America/Grand_Turk</MenuItem>
            <MenuItem key="America/Guatemala" value="America/Guatemala">America/Guatemala</MenuItem>
            <MenuItem key="America/Guayaquil" value="America/Guayaquil">America/Guayaquil</MenuItem>
            <MenuItem key="America/Guyana" value="America/Guyana">America/Guyana</MenuItem>
            <MenuItem key="America/Halifax" value="America/Halifax">America/Halifax</MenuItem>
            <MenuItem key="America/Havana" value="America/Havana">America/Havana</MenuItem>
            <MenuItem key="America/Hermosillo" value="America/Hermosillo">America/Hermosillo</MenuItem>
            <MenuItem key="America/Indiana/Indianapolis" value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</MenuItem>
            <MenuItem key="America/Indiana/Knox" value="America/Indiana/Knox">America/Indiana/Knox</MenuItem>
            <MenuItem key="America/Indiana/Marengo" value="America/Indiana/Marengo">America/Indiana/Marengo</MenuItem>
            <MenuItem key="America/Indiana/Petersburg" value="America/Indiana/Petersburg">America/Indiana/Petersburg</MenuItem>
            <MenuItem key="America/Indiana/Tell_City" value="America/Indiana/Tell_City">America/Indiana/Tell_City</MenuItem>
            <MenuItem key="America/Indiana/Vevay" value="America/Indiana/Vevay">America/Indiana/Vevay</MenuItem>
            <MenuItem key="America/Indiana/Vincennes" value="America/Indiana/Vincennes">America/Indiana/Vincennes</MenuItem>
            <MenuItem key="America/Indiana/Winamac" value="America/Indiana/Winamac">America/Indiana/Winamac</MenuItem>
            <MenuItem key="America/Inuvik" value="America/Inuvik">America/Inuvik</MenuItem>
            <MenuItem key="America/Iqaluit" value="America/Iqaluit">America/Iqaluit</MenuItem>
            <MenuItem key="America/Jamaica" value="America/Jamaica">America/Jamaica</MenuItem>
            <MenuItem key="America/Juneau" value="America/Juneau">America/Juneau</MenuItem>
            <MenuItem key="America/Kentucky/Louisville" value="America/Kentucky/Louisville">America/Kentucky/Louisville</MenuItem>
            <MenuItem key="America/Kentucky/Monticello" value="America/Kentucky/Monticello">America/Kentucky/Monticello</MenuItem>
            <MenuItem key="America/La_Paz" value="America/La_Paz">America/La_Paz</MenuItem>
            <MenuItem key="America/Lima" value="America/Lima">America/Lima</MenuItem>
            <MenuItem key="America/Maceio" value="America/Maceio">America/Maceio</MenuItem>
            <MenuItem key="America/Managua" value="America/Managua">America/Managua</MenuItem>
            <MenuItem key="America/Manaus" value="America/Manaus">America/Manaus</MenuItem>
            <MenuItem key="America/Martinique" value="America/Martinique">America/Martinique</MenuItem>
            <MenuItem key="America/Matamoros" value="America/Matamoros">America/Matamoros</MenuItem>
            <MenuItem key="America/Mazatlan" value="America/Mazatlan">America/Mazatlan</MenuItem>
            <MenuItem key="America/Menominee" value="America/Menominee">America/Menominee</MenuItem>
            <MenuItem key="America/Merida" value="America/Merida">America/Merida</MenuItem>
            <MenuItem key="America/Metlakatla" value="America/Metlakatla">America/Metlakatla</MenuItem>
            <MenuItem key="America/Mexico_City" value="America/Mexico_City">America/Mexico_City</MenuItem>
            <MenuItem key="America/Miquelon" value="America/Miquelon">America/Miquelon</MenuItem>
            <MenuItem key="America/Moncton" value="America/Moncton">America/Moncton</MenuItem>
            <MenuItem key="America/Monterrey" value="America/Monterrey">America/Monterrey</MenuItem>
            <MenuItem key="America/Montevideo" value="America/Montevideo">America/Montevideo</MenuItem>
            <MenuItem key="America/Nassau" value="America/Nassau">America/Nassau</MenuItem>
            <MenuItem key="America/Nipigon" value="America/Nipigon">America/Nipigon</MenuItem>
            <MenuItem key="America/Nome" value="America/Nome">America/Nome</MenuItem>
            <MenuItem key="America/Noronha" value="America/Noronha">America/Noronha</MenuItem>
            <MenuItem key="America/North_Dakota/Beulah" value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</MenuItem>
            <MenuItem key="America/North_Dakota/Center" value="America/North_Dakota/Center">America/North_Dakota/Center</MenuItem>
            <MenuItem key="America/North_Dakota/New_Salem" value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</MenuItem>
            <MenuItem key="America/Ojinaga" value="America/Ojinaga">America/Ojinaga</MenuItem>
            <MenuItem key="America/Panama" value="America/Panama">America/Panama</MenuItem>
            <MenuItem key="America/Pangnirtung" value="America/Pangnirtung">America/Pangnirtung</MenuItem>
            <MenuItem key="America/Paramaribo" value="America/Paramaribo">America/Paramaribo</MenuItem>
            <MenuItem key="America/Port_of_Spain" value="America/Port_of_Spain">America/Port_of_Spain</MenuItem>
            <MenuItem key="America/Port-au-Prince" value="America/Port-au-Prince">America/Port-au-Prince</MenuItem>
            <MenuItem key="America/Porto_Velho" value="America/Porto_Velho">America/Porto_Velho</MenuItem>
            <MenuItem key="America/Puerto_Rico" value="America/Puerto_Rico">America/Puerto_Rico</MenuItem>
            <MenuItem key="America/Punta_Arenas" value="America/Punta_Arenas">America/Punta_Arenas</MenuItem>
            <MenuItem key="America/Rainy_River" value="America/Rainy_River">America/Rainy_River</MenuItem>
            <MenuItem key="America/Rankin_Inlet" value="America/Rankin_Inlet">America/Rankin_Inlet</MenuItem>
            <MenuItem key="America/Recife" value="America/Recife">America/Recife</MenuItem>
            <MenuItem key="America/Regina" value="America/Regina">America/Regina</MenuItem>
            <MenuItem key="America/Resolute" value="America/Resolute">America/Resolute</MenuItem>
            <MenuItem key="America/Rio_Branco" value="America/Rio_Branco">America/Rio_Branco</MenuItem>
            <MenuItem key="America/Santarem" value="America/Santarem">America/Santarem</MenuItem>
            <MenuItem key="America/Santiago" value="America/Santiago">America/Santiago</MenuItem>
            <MenuItem key="America/Santo_Domingo" value="America/Santo_Domingo">America/Santo_Domingo</MenuItem>
            <MenuItem key="America/Sao_Paulo" value="America/Sao_Paulo">America/Sao_Paulo</MenuItem>
            <MenuItem key="America/Scoresbysund" value="America/Scoresbysund">America/Scoresbysund</MenuItem>
            <MenuItem key="America/Sitka" value="America/Sitka">America/Sitka</MenuItem>
            <MenuItem key="America/St_Johns" value="America/St_Johns">America/St_Johns</MenuItem>
            <MenuItem key="America/Swift_Current" value="America/Swift_Current">America/Swift_Current</MenuItem>
            <MenuItem key="America/Tegucigalpa" value="America/Tegucigalpa">America/Tegucigalpa</MenuItem>
            <MenuItem key="America/Thule" value="America/Thule">America/Thule</MenuItem>
            <MenuItem key="America/Thunder_Bay" value="America/Thunder_Bay">America/Thunder_Bay</MenuItem>
            <MenuItem key="America/Tijuana" value="America/Tijuana">America/Tijuana</MenuItem>
            <MenuItem key="America/Toronto" value="America/Toronto">America/Toronto</MenuItem>
            <MenuItem key="America/Vancouver" value="America/Vancouver">America/Vancouver</MenuItem>
            <MenuItem key="America/Whitehorse" value="America/Whitehorse">America/Whitehorse</MenuItem>
            <MenuItem key="America/Winnipeg" value="America/Winnipeg">America/Winnipeg</MenuItem>
            <MenuItem key="America/Yakutat" value="America/Yakutat">America/Yakutat</MenuItem>
            <MenuItem key="America/Yellowknife" value="America/Yellowknife">America/Yellowknife</MenuItem>
            <ListSubheader>Africa</ListSubheader>
            <MenuItem key="Africa/Abidjan" value="Africa/Abidjan">Africa/Abidjan</MenuItem>
            <MenuItem key="Africa/Accra" value="Africa/Accra">Africa/Accra</MenuItem>
            <MenuItem key="Africa/Algiers" value="Africa/Algiers">Africa/Algiers</MenuItem>
            <MenuItem key="Africa/Bissau" value="Africa/Bissau">Africa/Bissau</MenuItem>
            <MenuItem key="Africa/Cairo" value="Africa/Cairo">Africa/Cairo</MenuItem>
            <MenuItem key="Africa/Casablanca" value="Africa/Casablanca">Africa/Casablanca</MenuItem>
            <MenuItem key="Africa/Ceuta" value="Africa/Ceuta">Africa/Ceuta</MenuItem>
            <MenuItem key="Africa/El_Aaiun" value="Africa/El_Aaiun">Africa/El_Aaiun</MenuItem>
            <MenuItem key="Africa/Johannesburg" value="Africa/Johannesburg">Africa/Johannesburg</MenuItem>
            <MenuItem key="Africa/Juba" value="Africa/Juba">Africa/Juba</MenuItem>
            <MenuItem key="Africa/Khartoum" value="Africa/Khartoum">Africa/Khartoum</MenuItem>
            <MenuItem key="Africa/Lagos" value="Africa/Lagos">Africa/Lagos</MenuItem>
            <MenuItem key="Africa/Maputo" value="Africa/Maputo">Africa/Maputo</MenuItem>
            <MenuItem key="Africa/Monrovia" value="Africa/Monrovia">Africa/Monrovia</MenuItem>
            <MenuItem key="Africa/Nairobi" value="Africa/Nairobi">Africa/Nairobi</MenuItem>
            <MenuItem key="Africa/Ndjamena" value="Africa/Ndjamena">Africa/Ndjamena</MenuItem>
            <MenuItem key="Africa/Tripoli" value="Africa/Tripoli">Africa/Tripoli</MenuItem>
            <MenuItem key="Africa/Tunis" value="Africa/Tunis">Africa/Tunis</MenuItem>
            <MenuItem key="Africa/Windhoek" value="Africa/Windhoek">Africa/Windhoek</MenuItem>
            <ListSubheader>Asia</ListSubheader>
            <MenuItem key="Asia/Almaty" value="Asia/Almaty">Asia/Almaty</MenuItem>
            <MenuItem key="Asia/Amman" value="Asia/Amman">Asia/Amman</MenuItem>
            <MenuItem key="Asia/Anadyr" value="Asia/Anadyr">Asia/Anadyr</MenuItem>
            <MenuItem key="Asia/Aqtau" value="Asia/Aqtau">Asia/Aqtau</MenuItem>
            <MenuItem key="Asia/Aqtobe" value="Asia/Aqtobe">Asia/Aqtobe</MenuItem>
            <MenuItem key="Asia/Ashgabat" value="Asia/Ashgabat">Asia/Ashgabat</MenuItem>
            <MenuItem key="Asia/Atyrau" value="Asia/Atyrau">Asia/Atyrau</MenuItem>
            <MenuItem key="Asia/Baghdad" value="Asia/Baghdad">Asia/Baghdad</MenuItem>
            <MenuItem key="Asia/Baku" value="Asia/Baku">Asia/Baku</MenuItem>
            <MenuItem key="Asia/Bangkok" value="Asia/Bangkok">Asia/Bangkok</MenuItem>
            <MenuItem key="Asia/Barnaul" value="Asia/Barnaul">Asia/Barnaul</MenuItem>
            <MenuItem key="Asia/Beirut" value="Asia/Beirut">Asia/Beirut</MenuItem>
            <MenuItem key="Asia/Bishkek" value="Asia/Bishkek">Asia/Bishkek</MenuItem>
            <MenuItem key="Asia/Brunei" value="Asia/Brunei">Asia/Brunei</MenuItem>
            <MenuItem key="Asia/Chita" value="Asia/Chita">Asia/Chita</MenuItem>
            <MenuItem key="Asia/Choibalsan" value="Asia/Choibalsan">Asia/Choibalsan</MenuItem>
            <MenuItem key="Asia/Colombo" value="Asia/Colombo">Asia/Colombo</MenuItem>
            <MenuItem key="Asia/Damascus" value="Asia/Damascus">Asia/Damascus</MenuItem>
            <MenuItem key="Asia/Dhaka" value="Asia/Dhaka">Asia/Dhaka</MenuItem>
            <MenuItem key="Asia/Dili" value="Asia/Dili">Asia/Dili</MenuItem>
            <MenuItem key="Asia/Dubai" value="Asia/Dubai">Asia/Dubai</MenuItem>
            <MenuItem key="Asia/Dushanbe" value="Asia/Dushanbe">Asia/Dushanbe</MenuItem>
            <MenuItem key="Asia/Famagusta" value="Asia/Famagusta">Asia/Famagusta</MenuItem>
            <MenuItem key="Asia/Gaza" value="Asia/Gaza">Asia/Gaza</MenuItem>
            <MenuItem key="Asia/Hebron" value="Asia/Hebron">Asia/Hebron</MenuItem>
            <MenuItem key="Asia/Ho_Chi_Minh" value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</MenuItem>
            <MenuItem key="Asia/Hong_Kong" value="Asia/Hong_Kong">Asia/Hong_Kong</MenuItem>
            <MenuItem key="Asia/Hovd" value="Asia/Hovd">Asia/Hovd</MenuItem>
            <MenuItem key="Asia/Irkutsk" value="Asia/Irkutsk">Asia/Irkutsk</MenuItem>
            <MenuItem key="Asia/Jakarta" value="Asia/Jakarta">Asia/Jakarta</MenuItem>
            <MenuItem key="Asia/Jayapura" value="Asia/Jayapura">Asia/Jayapura</MenuItem>
            <MenuItem key="Asia/Jerusalem" value="Asia/Jerusalem">Asia/Jerusalem</MenuItem>
            <MenuItem key="Asia/Kabul" value="Asia/Kabul">Asia/Kabul</MenuItem>
            <MenuItem key="Asia/Kamchatka" value="Asia/Kamchatka">Asia/Kamchatka</MenuItem>
            <MenuItem key="Asia/Karachi" value="Asia/Karachi">Asia/Karachi</MenuItem>
            <MenuItem key="Asia/Kathmandu" value="Asia/Kathmandu">Asia/Kathmandu</MenuItem>
            <MenuItem key="Asia/Khandyga" value="Asia/Khandyga">Asia/Khandyga</MenuItem>
            <MenuItem key="Asia/Kolkata" value="Asia/Kolkata">Asia/Kolkata</MenuItem>
            <MenuItem key="Asia/Krasnoyarsk" value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</MenuItem>
            <MenuItem key="Asia/Kuala_Lumpur" value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur</MenuItem>
            <MenuItem key="Asia/Kuching" value="Asia/Kuching">Asia/Kuching</MenuItem>
            <MenuItem key="Asia/Macau" value="Asia/Macau">Asia/Macau</MenuItem>
            <MenuItem key="Asia/Magadan" value="Asia/Magadan">Asia/Magadan</MenuItem>
            <MenuItem key="Asia/Makassar" value="Asia/Makassar">Asia/Makassar</MenuItem>
            <MenuItem key="Asia/Manila" value="Asia/Manila">Asia/Manila</MenuItem>
            <MenuItem key="Asia/Novokuznetsk" value="Asia/Novokuznetsk">Asia/Novokuznetsk</MenuItem>
            <MenuItem key="Asia/Novosibirsk" value="Asia/Novosibirsk">Asia/Novosibirsk</MenuItem>
            <MenuItem key="Asia/Omsk" value="Asia/Omsk">Asia/Omsk</MenuItem>
            <MenuItem key="Asia/Oral" value="Asia/Oral">Asia/Oral</MenuItem>
            <MenuItem key="Asia/Pontianak" value="Asia/Pontianak">Asia/Pontianak</MenuItem>
            <MenuItem key="Asia/Pyongyang" value="Asia/Pyongyang">Asia/Pyongyang</MenuItem>
            <MenuItem key="Asia/Qatar" value="Asia/Qatar">Asia/Qatar</MenuItem>
            <MenuItem key="Asia/Qyzylorda" value="Asia/Qyzylorda">Asia/Qyzylorda</MenuItem>
            <MenuItem key="Asia/Riyadh" value="Asia/Riyadh">Asia/Riyadh</MenuItem>
            <MenuItem key="Asia/Sakhalin" value="Asia/Sakhalin">Asia/Sakhalin</MenuItem>
            <MenuItem key="Asia/Samarkand" value="Asia/Samarkand">Asia/Samarkand</MenuItem>
            <MenuItem key="Asia/Seoul" value="Asia/Seoul">Asia/Seoul</MenuItem>
            <MenuItem key="Asia/Shanghai" value="Asia/Shanghai">Asia/Shanghai</MenuItem>
            <MenuItem key="Asia/Singapore" value="Asia/Singapore">Asia/Singapore</MenuItem>
            <MenuItem key="Asia/Srednekolymsk" value="Asia/Srednekolymsk">Asia/Srednekolymsk</MenuItem>
            <MenuItem key="Asia/Taipei" value="Asia/Taipei">Asia/Taipei</MenuItem>
            <MenuItem key="Asia/Tashkent" value="Asia/Tashkent">Asia/Tashkent</MenuItem>
            <MenuItem key="Asia/Tbilisi" value="Asia/Tbilisi">Asia/Tbilisi</MenuItem>
            <MenuItem key="Asia/Tehran" value="Asia/Tehran">Asia/Tehran</MenuItem>
            <MenuItem key="Asia/Thimphu" value="Asia/Thimphu">Asia/Thimphu</MenuItem>
            <MenuItem key="Asia/Tokyo" value="Asia/Tokyo">Asia/Tokyo</MenuItem>
            <MenuItem key="Asia/Tomsk" value="Asia/Tomsk">Asia/Tomsk</MenuItem>
            <MenuItem key="Asia/Ulaanbaatar" value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</MenuItem>
            <MenuItem key="Asia/Urumqi" value="Asia/Urumqi">Asia/Urumqi</MenuItem>
            <MenuItem key="Asia/Ust-Nera" value="Asia/Ust-Nera">Asia/Ust-Nera</MenuItem>
            <MenuItem key="Asia/Vladivostok" value="Asia/Vladivostok">Asia/Vladivostok</MenuItem>
            <MenuItem key="Asia/Yakutsk" value="Asia/Yakutsk">Asia/Yakutsk</MenuItem>
            <MenuItem key="Asia/Yangon" value="Asia/Yangon">Asia/Yangon</MenuItem>
            <MenuItem key="Asia/Yekaterinburg" value="Asia/Yekaterinburg">Asia/Yekaterinburg</MenuItem>
            <MenuItem key="Asia/Yerevan" value="Asia/Yerevan">Asia/Yerevan</MenuItem>
            <ListSubheader>Atlantic</ListSubheader>
            <MenuItem key="Atlantic/Azores" value="Atlantic/Azores">Atlantic/Azores</MenuItem>
            <MenuItem key="Atlantic/Bermuda" value="Atlantic/Bermuda">Atlantic/Bermuda</MenuItem>
            <MenuItem key="Atlantic/Canary" value="Atlantic/Canary">Atlantic/Canary</MenuItem>
            <MenuItem key="Atlantic/Cape_Verde" value="Atlantic/Cape_Verde">Atlantic/Cape_Verde</MenuItem>
            <MenuItem key="Atlantic/Faroe" value="Atlantic/Faroe">Atlantic/Faroe</MenuItem>
            <MenuItem key="Atlantic/Madeira" value="Atlantic/Madeira">Atlantic/Madeira</MenuItem>
            <MenuItem key="Atlantic/Reykjavik" value="Atlantic/Reykjavik">Atlantic/Reykjavik</MenuItem>
            <MenuItem key="Atlantic/South_Georgia" value="Atlantic/South_Georgia">Atlantic/South_Georgia</MenuItem>
            <MenuItem key="Atlantic/Stanley" value="Atlantic/Stanley">Atlantic/Stanley</MenuItem>
            <ListSubheader>Australia</ListSubheader>
            <MenuItem key="Australia/Adelaide" value="Australia/Adelaide">Australia/Adelaide</MenuItem>
            <MenuItem key="Australia/Brisbane" value="Australia/Brisbane">Australia/Brisbane</MenuItem>
            <MenuItem key="Australia/Broken_Hill" value="Australia/Broken_Hill">Australia/Broken_Hill</MenuItem>
            <MenuItem key="Australia/Currie" value="Australia/Currie">Australia/Currie</MenuItem>
            <MenuItem key="Australia/Darwin" value="Australia/Darwin">Australia/Darwin</MenuItem>
            <MenuItem key="Australia/Eucla" value="Australia/Eucla">Australia/Eucla</MenuItem>
            <MenuItem key="Australia/Hobart" value="Australia/Hobart">Australia/Hobart</MenuItem>
            <MenuItem key="Australia/Lindeman" value="Australia/Lindeman">Australia/Lindeman</MenuItem>
            <MenuItem key="Australia/Lord_Howe" value="Australia/Lord_Howe">Australia/Lord_Howe</MenuItem>
            <MenuItem key="Australia/Melbourne" value="Australia/Melbourne">Australia/Melbourne</MenuItem>
            <MenuItem key="Australia/Perth" value="Australia/Perth">Australia/Perth</MenuItem>
            <MenuItem key="Australia/Sydney" value="Australia/Sydney">Australia/Sydney</MenuItem>
            <ListSubheader>Europe</ListSubheader>
            <MenuItem key="Europe/Amsterdam" value="Europe/Amsterdam">Europe/Amsterdam</MenuItem>
            <MenuItem key="Europe/Andorra" value="Europe/Andorra">Europe/Andorra</MenuItem>
            <MenuItem key="Europe/Astrakhan" value="Europe/Astrakhan">Europe/Astrakhan</MenuItem>
            <MenuItem key="Europe/Athens" value="Europe/Athens">Europe/Athens</MenuItem>
            <MenuItem key="Europe/Belgrade" value="Europe/Belgrade">Europe/Belgrade</MenuItem>
            <MenuItem key="Europe/Berlin" value="Europe/Berlin">Europe/Berlin</MenuItem>
            <MenuItem key="Europe/Brussels" value="Europe/Brussels">Europe/Brussels</MenuItem>
            <MenuItem key="Europe/Bucharest" value="Europe/Bucharest">Europe/Bucharest</MenuItem>
            <MenuItem key="Europe/Budapest" value="Europe/Budapest">Europe/Budapest</MenuItem>
            <MenuItem key="Europe/Chisinau" value="Europe/Chisinau">Europe/Chisinau</MenuItem>
            <MenuItem key="Europe/Copenhagen" value="Europe/Copenhagen">Europe/Copenhagen</MenuItem>
            <MenuItem key="Europe/Dublin" value="Europe/Dublin">Europe/Dublin</MenuItem>
            <MenuItem key="Europe/Gibraltar" value="Europe/Gibraltar">Europe/Gibraltar</MenuItem>
            <MenuItem key="Europe/Helsinki" value="Europe/Helsinki">Europe/Helsinki</MenuItem>
            <MenuItem key="Europe/Istanbul" value="Europe/Istanbul">Europe/Istanbul</MenuItem>
            <MenuItem key="Europe/Kaliningrad" value="Europe/Kaliningrad">Europe/Kaliningrad</MenuItem>
            <MenuItem key="Europe/Kiev" value="Europe/Kiev">Europe/Kiev</MenuItem>
            <MenuItem key="Europe/Kirov" value="Europe/Kirov">Europe/Kirov</MenuItem>
            <MenuItem key="Europe/Lisbon" value="Europe/Lisbon">Europe/Lisbon</MenuItem>
            <MenuItem key="Europe/London" value="Europe/London">Europe/London</MenuItem>
            <MenuItem key="Europe/Luxembourg" value="Europe/Luxembourg">Europe/Luxembourg</MenuItem>
            <MenuItem key="Europe/Madrid" value="Europe/Madrid">Europe/Madrid</MenuItem>
            <MenuItem key="Europe/Malta" value="Europe/Malta">Europe/Malta</MenuItem>
            <MenuItem key="Europe/Minsk" value="Europe/Minsk">Europe/Minsk</MenuItem>
            <MenuItem key="Europe/Monaco" value="Europe/Monaco">Europe/Monaco</MenuItem>
            <MenuItem key="Europe/Moscow" value="Europe/Moscow">Europe/Moscow</MenuItem>
            <MenuItem key="Europe/Nicosia" value="Europe/Nicosia">Europe/Nicosia</MenuItem>
            <MenuItem key="Europe/Oslo" value="Europe/Oslo">Europe/Oslo</MenuItem>
            <MenuItem key="Europe/Paris" value="Europe/Paris">Europe/Paris</MenuItem>
            <MenuItem key="Europe/Prague" value="Europe/Prague">Europe/Prague</MenuItem>
            <MenuItem key="Europe/Riga" value="Europe/Riga">Europe/Riga</MenuItem>
            <MenuItem key="Europe/Rome" value="Europe/Rome">Europe/Rome</MenuItem>
            <MenuItem key="Europe/Samara" value="Europe/Samara">Europe/Samara</MenuItem>
            <MenuItem key="Europe/Saratov" value="Europe/Saratov">Europe/Saratov</MenuItem>
            <MenuItem key="Europe/Simferopol" value="Europe/Simferopol">Europe/Simferopol</MenuItem>
            <MenuItem key="Europe/Sofia" value="Europe/Sofia">Europe/Sofia</MenuItem>
            <MenuItem key="Europe/Stockholm" value="Europe/Stockholm">Europe/Stockholm</MenuItem>
            <MenuItem key="Europe/Tallinn" value="Europe/Tallinn">Europe/Tallinn</MenuItem>
            <MenuItem key="Europe/Tirane" value="Europe/Tirane">Europe/Tirane</MenuItem>
            <MenuItem key="Europe/Ulyanovsk" value="Europe/Ulyanovsk">Europe/Ulyanovsk</MenuItem>
            <MenuItem key="Europe/Uzhgorod" value="Europe/Uzhgorod">Europe/Uzhgorod</MenuItem>
            <MenuItem key="Europe/Vienna" value="Europe/Vienna">Europe/Vienna</MenuItem>
            <MenuItem key="Europe/Vilnius" value="Europe/Vilnius">Europe/Vilnius</MenuItem>
            <MenuItem key="Europe/Volgograd" value="Europe/Volgograd">Europe/Volgograd</MenuItem>
            <MenuItem key="Europe/Warsaw" value="Europe/Warsaw">Europe/Warsaw</MenuItem>
            <MenuItem key="Europe/Zaporozhye" value="Europe/Zaporozhye">Europe/Zaporozhye</MenuItem>
            <MenuItem key="Europe/Zurich" value="Europe/Zurich">Europe/Zurich</MenuItem>
            <ListSubheader>India</ListSubheader>
            <MenuItem key="Indian/Chagos" value="Indian/Chagos">Indian/Chagos</MenuItem>
            <MenuItem key="Indian/Christmas" value="Indian/Christmas">Indian/Christmas</MenuItem>
            <MenuItem key="Indian/Cocos" value="Indian/Cocos">Indian/Cocos</MenuItem>
            <MenuItem key="Indian/Kerguelen" value="Indian/Kerguelen">Indian/Kerguelen</MenuItem>
            <MenuItem key="Indian/Mahe" value="Indian/Mahe">Indian/Mahe</MenuItem>
            <MenuItem key="Indian/Maldives" value="Indian/Maldives">Indian/Maldives</MenuItem>
            <MenuItem key="Indian/Mauritius" value="Indian/Mauritius">Indian/Mauritius</MenuItem>
            <MenuItem key="Indian/Reunion" value="Indian/Reunion">Indian/Reunion</MenuItem>
            <ListSubheader>Pacific</ListSubheader>
            <MenuItem key="Pacific/Apia" value="Pacific/Apia">Pacific/Apia</MenuItem>
            <MenuItem key="Pacific/Auckland" value="Pacific/Auckland">Pacific/Auckland</MenuItem>
            <MenuItem key="Pacific/Bougainville" value="Pacific/Bougainville">Pacific/Bougainville</MenuItem>
            <MenuItem key="Pacific/Chatham" value="Pacific/Chatham">Pacific/Chatham</MenuItem>
            <MenuItem key="Pacific/Chuuk" value="Pacific/Chuuk">Pacific/Chuuk</MenuItem>
            <MenuItem key="Pacific/Easter" value="Pacific/Easter">Pacific/Easter</MenuItem>
            <MenuItem key="Pacific/Efate" value="Pacific/Efate">Pacific/Efate</MenuItem>
            <MenuItem key="Pacific/Enderbury" value="Pacific/Enderbury">Pacific/Enderbury</MenuItem>
            <MenuItem key="Pacific/Fakaofo" value="Pacific/Fakaofo">Pacific/Fakaofo</MenuItem>
            <MenuItem key="Pacific/Fiji" value="Pacific/Fiji">Pacific/Fiji</MenuItem>
            <MenuItem key="Pacific/Funafuti" value="Pacific/Funafuti">Pacific/Funafuti</MenuItem>
            <MenuItem key="Pacific/Galapagos" value="Pacific/Galapagos">Pacific/Galapagos</MenuItem>
            <MenuItem key="Pacific/Gambier" value="Pacific/Gambier">Pacific/Gambier</MenuItem>
            <MenuItem key="Pacific/Guadalcanal" value="Pacific/Guadalcanal">Pacific/Guadalcanal</MenuItem>
            <MenuItem key="Pacific/Guam" value="Pacific/Guam">Pacific/Guam</MenuItem>
            <MenuItem key="Pacific/Kiritimati" value="Pacific/Kiritimati">Pacific/Kiritimati</MenuItem>
            <MenuItem key="Pacific/Kosrae" value="Pacific/Kosrae">Pacific/Kosrae</MenuItem>
            <MenuItem key="Pacific/Kwajalein" value="Pacific/Kwajalein">Pacific/Kwajalein</MenuItem>
            <MenuItem key="Pacific/Majuro" value="Pacific/Majuro">Pacific/Majuro</MenuItem>
            <MenuItem key="Pacific/Marquesas" value="Pacific/Marquesas">Pacific/Marquesas</MenuItem>
            <MenuItem key="Pacific/Nauru" value="Pacific/Nauru">Pacific/Nauru</MenuItem>
            <MenuItem key="Pacific/Niue" value="Pacific/Niue">Pacific/Niue</MenuItem>
            <MenuItem key="Pacific/Norfolk" value="Pacific/Norfolk">Pacific/Norfolk</MenuItem>
            <MenuItem key="Pacific/Noumea" value="Pacific/Noumea">Pacific/Noumea</MenuItem>
            <MenuItem key="Pacific/Pago_Pago" value="Pacific/Pago_Pago">Pacific/Pago_Pago</MenuItem>
            <MenuItem key="Pacific/Palau" value="Pacific/Palau">Pacific/Palau</MenuItem>
            <MenuItem key="Pacific/Pitcairn" value="Pacific/Pitcairn">Pacific/Pitcairn</MenuItem>
            <MenuItem key="Pacific/Pohnpei" value="Pacific/Pohnpei">Pacific/Pohnpei</MenuItem>
            <MenuItem key="Pacific/Port_Moresby" value="Pacific/Port_Moresby">Pacific/Port_Moresby</MenuItem>
            <MenuItem key="Pacific/Rarotonga" value="Pacific/Rarotonga">Pacific/Rarotonga</MenuItem>
            <MenuItem key="Pacific/Tahiti" value="Pacific/Tahiti">Pacific/Tahiti</MenuItem>
            <MenuItem key="Pacific/Tarawa" value="Pacific/Tarawa">Pacific/Tarawa</MenuItem>
            <MenuItem key="Pacific/Tongatapu" value="Pacific/Tongatapu">Pacific/Tongatapu</MenuItem>
            <MenuItem key="Pacific/Wake" value="Pacific/Wake">Pacific/Wake</MenuItem>
            <MenuItem key="Pacific/Wallis" value="Pacific/Wallis">Pacific/Wallis</MenuItem>
        </Select>
        <FormHelperText required={!!props.required}>{props.helperText}</FormHelperText>
    </FormControl>;
}



