import {showToast} from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";
import {
  ArchiveUsersResponsePayload,
  GetNumUsersResponsePayload,
  GetUsersResponsePayload,
  UpsertUserPayload,
  UpsertUserResponsePayload,
  User
} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";

export const REQUEST_USERS = 'REQUEST_USERS';
export const ARCHIVE_USERS = 'ARCHIVE_USERS';
export const ARCHIVE_USERS_RESPONSE = 'ARCHIVE_USERS_RESPONSE';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const REQUEST_NUM_USERS = 'REQUEST_NUM_USERS';
export const RECEIVE_NUM_USERS = 'RECEIVE_NUM_USERS';
export const SELECT_USERS = 'SELECT_USERS';
export const REQUEST_NEW_USER = 'REQUEST_NEW_USER';
interface IRequestUsersAction {
  type: typeof REQUEST_USERS,
  segmentIds: string[],
  nonSegmentIds: string[],
}
interface IReceiveUsersAction {
  type: typeof RECEIVE_USERS,
  data: User[],
  segmentIds: string[],
  nonSegmentIds: string[],
}
interface IRequestNumUsersAction {
  type: typeof REQUEST_NUM_USERS,
  segmentIds:string[], 
  nonSegmentIds:string[],
}
interface IReceiveNumUsersAction {
  type: typeof RECEIVE_NUM_USERS,
  numUsers: number,
  segmentIds: string[],
  nonSegmentIds: string[],
}
interface ISelectUserAction {
  type: typeof SELECT_USERS,
  selectedUserIds: string[],
}
interface IRequestNewUserAction {
  type: typeof REQUEST_NEW_USER,
}
interface IArchiveUsersAction {
  type: typeof ARCHIVE_USERS,
  emails: string[],
}
interface IArchiveUsersResponseAction {
  type: typeof ARCHIVE_USERS_RESPONSE,
  emailToIdMap: {[key:string]: {id:string, email:string}}, //email to id mapping
  success: boolean,
}
export type UserActionTypes = IRequestUsersAction | IReceiveUsersAction | ISelectUserAction | IRequestNewUserAction | 
  IArchiveUsersAction | IArchiveUsersResponseAction | IReceiveNumUsersAction | IRequestNumUsersAction;

export function archiveUsersAction(emails: string[]):IArchiveUsersAction {
  return {
    type: 'ARCHIVE_USERS',
    emails,
  }
}

export function archiveUsersResponseAction(success: boolean, emailToIdMap: {[key:string]:{id:string, email:string}}):IArchiveUsersResponseAction {
  return {
    type: 'ARCHIVE_USERS_RESPONSE',
    emailToIdMap,
    success,
  }
}


export function requestNumUsersAction(segmentIds:string[], nonSegmentIds:string[]):IRequestNumUsersAction {
  return {
    type: 'REQUEST_NUM_USERS',
    segmentIds,
    nonSegmentIds,
  }
}

export function receiveNumUsersAction(numUsers:number, segmentIds:string[], nonSegmentIds:string[]):IReceiveNumUsersAction {
  return {
    type: 'RECEIVE_NUM_USERS',
    numUsers,
    segmentIds,
    nonSegmentIds,
  }
}


export function requestUsersAction(segmentIds: string[],nonSegmentIds: string[]):IRequestUsersAction {
  return {
    type: 'REQUEST_USERS',
    segmentIds,
    nonSegmentIds,
  }
}

export function receiveUsersAction(data:any, segmentIds:string[], nonSegmentIds:string[]):IReceiveUsersAction {
  return {
    type: 'RECEIVE_USERS',
    data,
    segmentIds,
    nonSegmentIds,
  }
}

export function selectUserAction(selectedUserIds:string[]):ISelectUserAction {
  return {
    type: 'SELECT_USERS',
    selectedUserIds,
  }
}

export function requestNewUser():IRequestNewUserAction{
  return {
    type: 'REQUEST_NEW_USER',
  }
}

export function archiveUsers(emails: string[]) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(archiveUsersAction(emails));
    return archiveUsersPromise(dispatch, emails);
  }
}

export function fetchNumUsers(searchField:string, searchValue:string, segmentIds:string[], nonSegmentIds:string[]) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNumUsersAction(segmentIds, nonSegmentIds));
    return fetchNumUsersPromise(dispatch, searchField, searchValue, segmentIds, nonSegmentIds)
  }
}

export function fetchUsers(ids: string[] = [], segmentIds:string[] = [], nonSegmentIds:string[] = [], limit = 10, offset = 0, sortColumns:string[] = [], searchField:string, searchValue:string) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestUsersAction(segmentIds, nonSegmentIds));
    return fetchUsersPromise(dispatch, ids, segmentIds, nonSegmentIds, limit, offset, sortColumns, searchField, searchValue)
  }
}


function fetchNumUsersPromise(dispatch:(...args:any)=>any, searchField:string, searchValue:string, segmentIds:string[], nonSegmentIds:string[]) {
  let url = getGatewayUrl("user") + "/users/count";
  const urlParts = []
  if(searchField && searchValue) {
    urlParts.push("search_field=" + encodeURIComponent(searchField))
    urlParts.push("search_value=" + encodeURIComponent(searchValue))
  }
  if(segmentIds && segmentIds.length > 0) {
    urlParts.push("in_segment_ids=" + encodeURIComponent(segmentIds.join(",")))
  }
  if(nonSegmentIds && nonSegmentIds.length > 0) {
    urlParts.push("not_in_segment_ids=" + encodeURIComponent(nonSegmentIds.join(",")))
  }
  if(urlParts.length > 0) {
    url += "?" + urlParts.join("&")
  }
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetNumUsersResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_NUM_USERS));
      showToast(<>Couldn't fetch num contacts: {errorsToUL(json.errors)}</>, "error");
    }
    console.log("Found num contacts: " +json.num_users.toString());
    dispatch(receiveNumUsersAction(json.num_users, segmentIds, nonSegmentIds))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_NUM_USERS));
    showToast(`Couldn't fetch num contacts: ${denastifyError(e.message)}`, "error");
  });
}

function fetchUsersPromise(dispatch:(...args:any)=>any, ids:string[] = [], segmentIds:string[], nonSegmentIds:string[], limit = 10, offset = 0, sortColumns:string[] = [], searchField:string, searchValue:string) {
  let url = getGatewayUrl("user") + "/users";
  const urlParts = []
  if(ids && ids.length > 0) {
    urlParts.push("ids=" + encodeURIComponent(ids.join(",")))
  }
  if(segmentIds && segmentIds.length > 0) {
    urlParts.push("in_segment_ids=" + encodeURIComponent(segmentIds.join(",")))
  }
  if(nonSegmentIds && nonSegmentIds.length > 0) {
    urlParts.push("not_in_segment_ids=" + encodeURIComponent(nonSegmentIds.join(",")))
  }
  if(limit) {
    urlParts.push("limit=" + limit.toString())
  }
  if(offset) {
    urlParts.push("offset=" + offset.toString())
  }
  if(sortColumns && sortColumns.length > 0) {
    urlParts.push("sort_columns=" + encodeURIComponent(sortColumns.join(",")))
  }
  if(searchField && searchValue) {
    urlParts.push("search_field=" + encodeURIComponent(searchField))
    urlParts.push("search_value=" + encodeURIComponent(searchValue))
  }
  if(urlParts.length > 0) {
    url += "?" + urlParts.join("&")
  }
  console.log(url);
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetUsersResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_USERS));
      showToast(<>Couldn't fetch contacts: {errorsToUL(json.errors)}</>, "error");
    }

    let users:any[] = [];
    if(json.data) {
      users = json.data.map((user:any) => {
        return user;
      });
    }
    console.log("Found " + users.length + " contacts");
    dispatch(receiveUsersAction(users, segmentIds, nonSegmentIds))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_USERS));
    showToast(`Couldn't fetch contacts: ${denastifyError(e.message)}`, "error");
  });
}

export function submitUserAction(id:string,firstName:string, lastName:string, timezone:string, 
  email:string,dob:moment.Moment,json:{[key:string]:any}, onSuccess?: () => void) {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestNewUser());
    return postUserPromise(dispatch,id,firstName,lastName,timezone,email,dob,json,onSuccess)
  }
}

function postUserPromise(dispatch:(...args:any)=>any,id:string,firstName:string,lastName:string,timezone:string, 
  email:string,dob:moment.Moment, json:{[key:string]:any}, onSuccess?: () => void) {
  //Get sender users from api endpoint
  const url = getGatewayUrl("user") + "/users";
  console.log("POSTING TO URL",url);

  let dobString:string = null
  if(dob) {
    dobString = dob.toISOString()
  } 

  const data:UpsertUserPayload = {
    users:[{
      id,
      first_name: firstName,
      last_name: lastName,
      date_of_birth:dobString,
      email,
      timezone,
      json,
    }]
  }
  const options:any =  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-api-key': getAuthKey(),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  };
  fetch(url, options)
  .then(res => res.json())
  .then((json:UpsertUserResponsePayload) => {
    if(json.errors && json.errors.length > 0) {
      showToast(<>Couldn't save contact: {errorsToUL(json.errors)}</>, "error");
    } else {
      showToast("Successfully saved contact", "success");
      if(onSuccess) {
        onSuccess()
      }
    }
  }).catch(e => {
    showToast(`Couldn't save contact: ${denastifyError(e.message)}`, "error");
  });
}


function archiveUsersPromise(dispatch:(...args:any)=>any,emails:string[]) {
  ((emails:string[]) => {
    //Get sender users from api endpoint
    const url = getGatewayUrl("user") + "/users?emails=" + encodeURIComponent(emails.join(","));
    console.log("POSTING TO URL",url);
    const options:any =  {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-api-key': getAuthKey(),
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    };
    fetch(url, options)
        .then(res => res.json())
        .then((json:ArchiveUsersResponsePayload) => {
          if(json.errors && json.errors.length > 0) {
            showToast(<>Couldn't archive contact: {errorsToUL(json.errors)}</>, "error");
            dispatch(archiveUsersResponseAction(false, json.archived_email_to_user_map));
          } else {
            dispatch(archiveUsersResponseAction(true, json.archived_email_to_user_map));
            showToast("Successfully archived contact", "success");
          }
        }).catch(e => {
          dispatch(archiveUsersResponseAction(false, null));
          showToast(`Couldn't archive contact: ${denastifyError(e.message)}`, "error");
        });
  })(emails);
}