import {
    AppBar,
    Menu,
    MenuItem,
    Toolbar,
} from "@material-ui/core";
import {connect} from "react-redux";
import {Menu as MenuIcon} from "@material-ui/icons"
import React from "react";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {NavDef, routes} from "./RoutesV2";
import moment from "moment-timezone";
import { JobLogEntry } from "../generated/types/payloadTypes";

export const topNavMinHeight = "50px"

interface IState {
    open: boolean,
    anchorEl: any,
}
interface IProps {
    dispatch?: any,
    notifications?: JobLogEntry[],
}

class TopNav extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            open: false,
            anchorEl: null,
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    toggleOpen(event: React.MouseEvent<HTMLElement>) {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget,
        })
    }

    closeMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({
            open: false,
            anchorEl: null,
        })
    }

    render() {
        return <AppBar position="fixed" style={{minHeight:topNavMinHeight}}>
            <Toolbar  style={{minHeight:topNavMinHeight}}>
                <Button variant={"contained"}
                        style={{border:"1px solid #3c3fa8"}}
                        onClick={this.toggleOpen}
                        color={"primary"}
                        startIcon={<MenuIcon />}>
                    Navigation
                </Button>
                <TopMenu open={this.state.open}
                         
                         anchorEl={this.state.anchorEl}
                         onClick={this.closeMenu}
                         notifications={this.props.notifications}
                />
            </Toolbar>
        </AppBar>;
    }
}

export function RouteMenuItems(props: {notifications?:JobLogEntry[]}) : JSX.Element {
    let listItemNum = 0;
    const primaryColor = "#333";
    const secondaryColor = "#5f5f5f";

    return <>{routes.map((routeDef:NavDef) => {
        const paletteStyle = routeDef.depth !== 1?
            {color:primaryColor }:
            {color:secondaryColor};

        return <Link to={routeDef.href} key={"link" + (listItemNum++)} style={{ textDecoration: 'none' }}>
            <MenuItem
                style={{
                    padding:"10px 10px 10px 10px",
                    ...paletteStyle
                }}
                key={"item"+ (listItemNum++)}
                onClick={routeDef.onClick}>
                <span style={{ visibility: routeDef.depth?"hidden":"visible"}}>{routeDef.icon}</span>&nbsp;&nbsp;{routeDef.text}&nbsp;
                {routeDef.isNotifications && props.notifications ? <span style={{color:"red"}}>{getNotificationsText(props.notifications)}</span> : null}
            </MenuItem>
        </Link>
    })}</>;
}

export function getNotificationsText(notifications: JobLogEntry[]) : string {
    if(!notifications || notifications.length === 0) {
        return "";
    }

    let numErrors = 0;
    const now = moment();
    notifications.map(notification => {
        if(!notification.ended_at) {
            return;
        }

        const endedAt = moment(notification.ended_at, "YYYY/MM/DDTHH:mm:ssZ");
        if(now.diff(endedAt, 'seconds') < 60 * 30) {
            if(!notification.success) {
                numErrors++;
            }
        }
    })

    if(!numErrors) {
        return "";
    }

    return numErrors.toString();
}


export function TopMenu(props: any): JSX.Element {
    return <Menu  {...props} className="hideForPrint" style={{overflow:"auto", marginTop:"30px"}} PaperProps={{  
        style: {  
        minWidth: 250,  
        },  
    }}>
        <RouteMenuItems {...props} />
    </Menu>
}


function mapStateToProps(state:any, ownProps:IProps):any {
    return {...state}
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(TopNav);