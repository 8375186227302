import React from 'react';
import {connect} from "react-redux";
import ListUsers from "./ListUsers";
import {Card, Fab, Grid, Typography} from "@material-ui/core"
import { defaultCardElevation, defaultCardStyles } from './App';
import { Add, FileCopyOutlined } from '@material-ui/icons';
import {Link} from "react-router-dom"

interface IProps {
    width: string,
    dispatch: any,
}

class PageUsers extends React.Component<IProps, null> {
    render() {
        return  <Card style={defaultCardStyles} elevation={defaultCardElevation}>
            <Grid container>
                <Grid item>
                    <Typography variant={"h1"} component={"div"}>Contacts</Typography>
                </Grid>
                <Grid item style={{marginLeft:"10px"}}>
                    <Link to={"/people/users/new/bulk"} title='Upload a contact list to create a new segment'>
                        <Fab size={"large"} color={"primary"} aria-label={"Upload a contact list to create a new segment"}>
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                            >
                                 <Add style={{marginLeft:"-5px"}} />
                                <FileCopyOutlined  style={{marginLeft:"-5px"}}/>
                            </span>
                        </Fab>
                    </Link>
                    <Link to={"/people/users/new"} style={{marginLeft:"0.5rem"}} title='Add a single contact'>
                        <Fab size={"small"} color={"secondary"} aria-label={"Add a single contact"}>
                            <Add style={{marginLeft:"-5px"}} /><span style={{marginLeft:"-5px", fontSize:"14pt"}}><b>1</b></span>
                        </Fab>
                    </Link>
                </Grid>
            </Grid>

            <hr />
            <ListUsers type='list' buttons={{"edit":true,"archive":true}}  {...this.props}  />
        </Card>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageUsers)
