import { Card, Typography, Button, TextField } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { defaultCardElevation, defaultCardStyles } from '../App';
import { ScheduledEmailGroup, SenderPersona, Template } from '../generated/types/payloadTypes';
import { fetchSenderPersonas, submitSenderPersonaAction } from '../actions/SenderPersonas';
import { AllState } from '../reducers';
import SelectSenderPersona from './components/SelectSenderPersona';
import SelectTemplate from './components/SelectTemplate';
import { Save } from '@material-ui/icons';
import SelectToType from './components/SelectToType';
import SelectSegments from './components/SelectSegments';
import SelectUsers from './components/SelectUsers';
import SelectTiming from './components/SelectTiming';
import SelectTemplateWeights from './components/SelectTemplateWeights';
import {v4 as uuidv4} from "uuid";
import { showToast } from '../ToastNotifications';

interface IProps {
    pageNum: number,
    width: string,
    dispatch: any,
    senderPersonas?: {[key:string]:SenderPersona},
    senderPersona: SenderPersona,
    scheduledEmailGroup: ScheduledEmailGroup,
    templates?: {[key:string]:Template},
    selectedTemplateIds: string[],
    selectedTemplateWeights: number[],
    isNewSenderPersona: boolean,
    numTemplates: number,
    toType: "segments"|"users"|"segments_and_users",
    sendAtType: "specific_timezone" | "users_timezone",
    sendAtMoment: moment.Moment,
    sendAtTimezone: string,
    isNewTemplates: boolean[],
    selectedSegmentIds:{[key:string]:boolean},
    selectedUserIds:{[key:string]:boolean},
    onToTypeChange: (toType: "segments"|"users"|"segments_and_users") => void,
    onSenderPersonaChange: (senderPersona: SenderPersona,  isNewSenderPersona: boolean) => void,
    onNumTemplatesChange: (n:number) => void
    onUsersChange:(userIds:{[key:string]:boolean}) => void,
    onSegmentsChange:(selectedSegmentIds:{[key:string]:boolean}) => void,
    onPageChange:(pageNum:number) => void,
    onSendAtChange: (sendAtMoment: moment.Moment, sendAtType:"specific_timezone"|"users_timezone", sendAtTimezone: string) => void,
    onSelectedTemplateIdsChange: (selectedTemplateIds:string[], isNewTemplate: boolean[]) => void,
    onSelectedTemplatWeightsChange: (selectedTemplateWeights: number[]) => void,
}
interface IState {
   
}
const newSenderPersonaPage = 3

class ABTestCampaign extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
        };
    }

    foundCreatedSenderPersona():boolean {
        if(!this.props.senderPersonas || Object.keys(this.props.senderPersonas).length == 0) {
            return false
        }

        return !!Object.keys(this.props.senderPersonas).find((id:string) => id === this.props.senderPersona.id)
    }

    onCreateNewSenderPersonaForm():boolean {
        if(this.props.pageNum === newSenderPersonaPage && this.props.isNewSenderPersona) {
            return true
        }

        return false
    }

    isTemplateWeightsPage(): boolean {
        return this.props.pageNum === 5+this.props.numTemplates
    }

    isToTypePage(): boolean {
        return this.props.pageNum === 6+this.props.numTemplates
    }
    isSelectSegmentsPage(): boolean {
        return (this.props.pageNum === 7+this.props.numTemplates && (this.props.toType === "segments" || this.props.toType==="segments_and_users"))
    }
    isSelectUsersPage(): boolean {
        return ((this.props.pageNum === 7+this.props.numTemplates && this.props.toType === "users") || 
            (this.props.pageNum===8+this.props.numTemplates && this.props.toType==="segments_and_users"))
    }
    isSelectTimingPage(): boolean {
        return ( (this.props.pageNum === 8+this.props.numTemplates && (this.props.toType === "users" || this.props.toType === "segments")) 
        || (this.props.pageNum === 9+this.props.numTemplates && this.props.toType==="segments_and_users"))
    }

    numActualTemplateIds(): number {
        let numActualIds = 0
        for(let i = 0; i < this.props.selectedTemplateIds.length; i++) {
            if(this.props.templates && this.props.templates[this.props.selectedTemplateIds[i]] 
            && this.props.templates[this.props.selectedTemplateIds[i]].id) {
                numActualIds++
            }
        }
        return numActualIds
    }

    isNextButtonEnabled() : boolean {
        if(this.props.pageNum === newSenderPersonaPage && this.props.senderPersona && this.props.senderPersona.id) {
            return true
        }

        if(this.props.pageNum === newSenderPersonaPage + 1 && Number.isSafeInteger(this.props.numTemplates)) {
            return true
        }

        if(this.onSelectTemplateForm()) {
            const templateIndex = this.props.pageNum - 5
            const numActualIds = this.numActualTemplateIds()
            if(numActualIds > templateIndex) {
                return true
            }
        }

        if(this.isTemplateWeightsPage()) {
            return true
        }
         
        if(this.isToTypePage()) {
            return true
        }

        if(this.isSelectSegmentsPage()) {
            if(Object.keys(this.props.selectedSegmentIds).length > 0) {
                return true
            }
        }

        if(this.isSelectUsersPage()) {
            if(Object.keys(this.props.selectedUserIds).length > 0) {
                return true
            }
        }
        
        if(this.isSelectTimingPage()) {
            if(this.props.sendAtMoment) {
                return true
            }
        }

        return false
    }

    getButtons() : JSX.Element { 
        const isNextDisabled = !this.isNextButtonEnabled() 
        const prevButton = <Button color="primary" variant="contained" disabled={this.props.pageNum === 1} onClick={(e) => { e.stopPropagation(); e.preventDefault(); if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum-1) } }} style={{marginRight:"1rem"}}>&lt;&nbsp;&nbsp; Campaign Setup</Button>
        const nextButton = <Button disabled={isNextDisabled} color="primary" variant="contained" onClick={(e) => { e.stopPropagation(); e.preventDefault();  if(this.props.onPageChange) { this.props.onPageChange(this.props.pageNum+1) } }} style={{marginRight:"1rem"}}>Campaign Setup &nbsp;&nbsp;&gt;</Button>
        const savePersonaButton = <Button color="primary" variant="contained" style={{marginRight:"1rem"}}
            startIcon={<Save />}
            onClick={(e) => { 
                e.stopPropagation(); 
                e.preventDefault(); 
                
                const sp = this.props.senderPersona
                sp.id = uuidv4()
                this.props.onSenderPersonaChange(sp, true) // add id
                this.props.dispatch(submitSenderPersonaAction(
                    sp.id,
                    sp.first_name,
                    sp.last_name,
                    sp.email,
                    sp.json ? JSON.stringify(sp.json) : "",
                    (sp:SenderPersona) => {
                        this.props.onSenderPersonaChange(sp, true)
    
                        // Refetch so we know we can enable to next button
                        this.props.dispatch(fetchSenderPersonas([sp?.id]))
                        showToast("Successfully saved sender persona, please log in to " + sp?.email + " and verify the account", "success")
                    })
                )
            }}>Save Persona</Button>

        const buttons = [prevButton, nextButton]
        if(this.onCreateNewSenderPersonaForm()) {
            buttons.push(savePersonaButton)
        }

        return <>{buttons}</>
    }

    
    onSelectTemplateForm():boolean {
        const templateIndex = this.props.pageNum - 5
        if(templateIndex >= 0 && templateIndex < this.props.numTemplates) {
            return true
        } 
       
        return false
    }

    render() {
        const templateIndex = this.props.pageNum - 5
        

        return <>
        <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
            <Typography variant="h1" component="h1" style={{marginBottom:"1rem"}}>New Campaign</Typography>
                {this.props.pageNum === 3 && <>
                    <SelectSenderPersona 
                        onSenderPersonaChange={this.props.onSenderPersonaChange}
                        senderPersona={this.props.senderPersona}
                        isNewSenderPersona={this.props.isNewSenderPersona}
                        width={this.props.width}
                        dispatch={this.props.dispatch} /></>}
                {this.props.pageNum === 4 && <>
                    <Typography variant="h2" component="p">
                        How many templates do you want to test out?
                    </Typography>
                    <TextField value={this.props.numTemplates} 
                        autoFocus={true} 
                        type='number'
                        style={{width:"100%", maxWidth:"500px", minWidth:"200px",marginBottom:"1rem"}} 
                        placeholder='Num templates to test against each other'
                        onChange={(e) => { 
                            e.stopPropagation(); 
                            e.preventDefault();
                            this.props.onNumTemplatesChange(parseInt(e.target?.value?.toString() || "0"))
                        }} />    
                </>}
                {this.onSelectTemplateForm()  && 
                    <SelectTemplate 
                        forABTest={true}
                        key={templateIndex}
                        numEmails={this.props.numTemplates}
                        emailNum={templateIndex+1}
                        selectedTemplateIds={this.props.selectedTemplateIds}
                        onTemplateChange={(t:Template, isNew:boolean) => {
                            const templateIds = this.props.selectedTemplateIds 
                            templateIds[templateIndex] = t.id
                            const isNewTemplates = this.props.isNewTemplates
                            isNewTemplates[templateIndex] = isNew 

                            this.props.onSelectedTemplateIdsChange(templateIds, isNewTemplates)
                        }} 
                        templateId={this.props.selectedTemplateIds[templateIndex]}
                        isNewTemplate={this.props.isNewTemplates[templateIndex]}
                        width={this.props.width} 
                        dispatch={this.props.dispatch} />}
                {this.isTemplateWeightsPage() && <SelectTemplateWeights 
                    numTemplates={this.props.numTemplates}
                    templates={this.props.templates}
                    selectedTemplateWeights={this.props.selectedTemplateWeights}
                    onSelectedTemplatWeightsChange={this.props.onSelectedTemplatWeightsChange}
                    selectedTemplateIds={this.props.selectedTemplateIds}
                    width={this.props.width} 
                    dispatch={this.props.dispatch} />}
                {this.isToTypePage() && <SelectToType 
                    toType={this.props.toType}
                    width={this.props.width} 
                    dispatch={this.props.dispatch} 
                    onToTypeChange={this.props.onToTypeChange} />}
                {this.isSelectSegmentsPage() && 
                    <SelectSegments 
                        selectedSegmentIds={this.props.selectedSegmentIds}
                        width={this.props.width} 
                        dispatch={this.props.dispatch}
                        onSegmentsChange={this.props.onSegmentsChange} /> }
                {this.isSelectUsersPage() && 
                    <SelectUsers 
                        selectedUserIds={this.props.selectedUserIds}
                        width={this.props.width} 
                        dispatch={this.props.dispatch} 
                        onUsersChange={this.props.onUsersChange} />}
                {this.isSelectTimingPage() && 
                    <SelectTiming 
                        width={this.props.width} 
                        dispatch={this.props.dispatch} 
                        sendAtMoment={this.props.sendAtMoment}
                        sendAtTimezone={this.props.sendAtTimezone}
                        sendAtType={this.props.sendAtType}
                        onSendAtChange={this.props.onSendAtChange} />
                }
            <div style={{marginTop:"1rem"}}>
                {this.getButtons()}
            </div>
            {this.isSelectUsersPage() && <div>
                {Object.keys(this.props.selectedUserIds).length === 0 && <Typography style={{color:"red", marginTop:"1rem", marginBottom:"1rem"}} variant={"body1"}>
                    Please select one or more contacts
                </Typography>}
            </div>}
            {this.isSelectSegmentsPage() && <div>
                {Object.keys(this.props.selectedSegmentIds).length === 0 && <Typography style={{color:"red", marginTop:"1rem", marginBottom:"1rem"}} variant={"body1"}>
                    Please select one or more segments
                </Typography>}
            </div>}
        </Card>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ABTestCampaign)
