import React from "react";
import {defaultCardElevation,defaultCardStyles} from "./App";
import {Card, Typography} from "@material-ui/core";
import PricingComparison from "./reports/PricingComparison";
export const costPerEmail = 0.65/1000;
export const monthlyFee = 13;
export const costPerEmail2 = 0.35/1000;
export const monthlyFee2 = 50;
export const costPerEmail3:number = 0.98/1000 
export const monthlyFee3 = 0
export const maxFreeEmails = 10000;

export function PagePricing(props:any) {
    return <Card elevation={defaultCardElevation} style={{...defaultCardStyles}}>
        <Typography variant={"h1"} component={"p"} style={{marginBottom:"1rem"}}>
            Pricing
        </Typography>
        <Typography variant={"body1"} component={"p"}>
            <b>You get these for free:</b>
        </Typography>
        <Typography variant="body1" component="ul" style={{marginBottom:"1rem"}}>
            <li>Upload and store unlimited contacts for free</li>
            <li>Send up to {maxFreeEmails.toLocaleString()} emails per month for free</li>
        </Typography>
        <Typography variant={"body1"} component={"p"}>
            <b>After {maxFreeEmails.toLocaleString()} free emails we charge the <u>lowest one</u> of these every month:</b>
        </Typography>
        <Typography variant="body1" component="ul" style={{marginBottom:"1rem"}}>
            <li>&nbsp;&nbsp;&nbsp;${monthlyFee3}/mo + {costPerEmail3 * 100000}¢ per 1,000 emails</li>
            <li>${monthlyFee}/mo + {costPerEmail * 100000}¢ per 1,000 emails</li>
            <li>${monthlyFee2}/mo + {costPerEmail2 * 100000}¢ per 1,000 emails</li>
        </Typography>
        <Typography variant={"h2"} component={"p"}>
            Pricing comparison
        </Typography>
        <Typography variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
            You may have noticed that we use a very rare pay-for-what-you-send pricing model.  Check out your savings using the chart below!
        </Typography>
        <PricingComparison />
        <Typography variant={"body1"} component={"p"} style={{marginTop:"1rem", marginBottom:"1rem"}}>
            About the graph: Solid lines mean the price was pulled directly from our competitor's website,
            while dashed lines mean we made our best-guess estimate based
            on the data they made available to the public.
        </Typography>
    </Card>
}