import { Typography, RadioGroup, FormControlLabel, Radio, Grid, TextField } from "@material-ui/core";
import { EmojiPeople, Email } from "@material-ui/icons";
import { Template } from "handlebars";
import React from "react";
import { connect } from "react-redux";
import ListSenderPersonas from "../../ListSenderPersonas";
import { SenderPersona } from "../../generated/types/payloadTypes";
import { AllState } from "../../reducers";
import {v4 as uuidv4} from "uuid";
import { InformationArea } from "../../components/InformationArea";

interface IProps {
    width: string,
    dispatch: any,
    senderPersonas?: {[key:string]:SenderPersona},
    templates?: {[key:string]:Template},
    onSenderPersonaChange: (senderPersona: SenderPersona,  isNewSenderPersona: boolean) => void,
    senderPersona: SenderPersona,
    isNewSenderPersona: boolean,
    numEmails?: number,
    emailNum?:number,
}
interface IState {
    newSenderPersonaId: string,
}

class SelectSenderPersona extends React.Component<IProps, IState> {
    state:IState 
    props:IProps

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            newSenderPersonaId: uuidv4(),
        };
    }
    
    render() {
        return <><Typography variant="h2" component="p">
            {this.props.numEmails?"Who should email " + (this.props.emailNum?.toString()||"1") + " be from?":"Who should your emails be from?"}
        
        </Typography>
        <RadioGroup
            style={{paddingLeft:"1rem"}}
            value={this.props.isNewSenderPersona?"true":"false"}
            row={false} 
            name="senderPersonaType"
            onChange={(e) => {
                e.stopPropagation()
                e.preventDefault() 

                const isNewSenderPersona = e.target?.value?.toString() === "true"
                let senderPersona = this.props.senderPersona
                if(isNewSenderPersona) {
                    senderPersona = new SenderPersona()
                }
                this.props.onSenderPersonaChange(senderPersona, isNewSenderPersona)
            }}>
                <FormControlLabel value={false} control={<Radio color="primary" value={"false"} />} 
                    label={<Typography variant="body1" component="span">An existing sender persona</Typography>} 
                    color="primary" />
                <FormControlLabel value={true} control={<Radio color="primary" value={"true"} />} 
                    label={<Typography variant="body1" component="span">A new sender persona</Typography>} 
                    color="primary" />
        </RadioGroup>
        {this.props.isNewSenderPersona ? <div>
        <Typography variant="h2" component="div" style={{marginTop:"1rem", marginBottom:"0.5rem"}}>New sender persona</Typography>  
        <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"} style={{paddingLeft:"1rem"}}>
            <Grid item >
                <EmojiPeople />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <TextField label={"First name"}
                    autoFocus={true}
                    required={true}
                    value={this.props.senderPersona.first_name}
                    onChange={e => {
                        e.stopPropagation(); 
                        e.preventDefault();
                        
                        const senderPersona =  {...this.props.senderPersona, first_name: e.target?.value?.toString() }
                        this.props.onSenderPersonaChange(senderPersona, this.props.isNewSenderPersona)
                    }}
                    style={{width:"calc(50% - 5px)",marginRight:"10px",minWidth:"200px",maxWidth:"300px"}}
                />
                <TextField label={"Last name"}
                    value={this.props.senderPersona.last_name}
                    onChange={e => {
                        e.stopPropagation(); 
                        e.preventDefault();
                        
                        const senderPersona =  {...this.props.senderPersona, last_name: e.target?.value?.toString() }
                        this.props.onSenderPersonaChange(senderPersona, this.props.isNewSenderPersona)
                    }}
                    style={{width:"calc(50% - 5px)",minWidth:"200px",maxWidth:"300px"}}
                />
            </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-end" alignContent={"flex-start"} style={{marginBottom:"1rem",paddingLeft:"1rem"}}>
            <Grid item >
                <Email />
            </Grid>
            <Grid  item xs={10} sm={10} md={10} lg={10} xl={10}>
                <TextField label={"Email"}
                    required={true}
                    type={"email"}
                    style={{width:"100%",minWidth:"200px",maxWidth:"610px"}}
                    value={this.props.senderPersona.email}
                    onChange={e => {
                        e.stopPropagation(); 
                        e.preventDefault();
                        
                        const senderPersona =  {...this.props.senderPersona, email:  e.target?.value?.toString() }
                        this.props.onSenderPersonaChange(senderPersona, this.props.isNewSenderPersona)
                    }}
                />
            </Grid>
        </Grid>
        <InformationArea type={"warning"}  style={{marginBottom:"2rem"}}
            contents={<Typography variant={"body1"} component={"p"}>
            After you save this sender persona, you MUST log in to that 
            email address and verify it using the link provided in the verification email from amazon web services, 
            or all of your emails will be blocked by our system and each email will throw a "sender not verified" error 
            in the events area.
        </Typography>}  />
        </div>                 
        : <div style={{marginTop:"1.5rem", marginBottom:"2rem",paddingLeft:"1rem"}}>
            <ListSenderPersonas type="select" 
                selectedSenderPersonaIds={this.props.senderPersonas && this.props.senderPersona && this.props.senderPersonas[this.props.senderPersona.id]?[this.props.senderPersona.id]: []}
                width={this.props.width} 
                dispatch={this.props.dispatch} 
                style={{width:"100%", maxWidth:"500px", minWidth:"200px"}} 
                label='Select your sender persona'
                onChange={e => {
                    e.stopPropagation(); 
                    e.preventDefault();

                    const senderPersona =  new SenderPersona()
                    senderPersona.id = e.target?.value?.toString()
                    this.props.onSenderPersonaChange(senderPersona, this.props.isNewSenderPersona)
                }}
                />
        </div>}</>
    }
}


function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectSenderPersona)
