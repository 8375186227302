import { Typography,Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { AllState } from '../../reducers';


interface IProps {
    width: string,
    dispatch: any,
    onToTypeChange: (toType: "segments"|"users"|"segments_and_users") => void,
    toType: "segments"|"users"|"segments_and_users",
}
interface IState {
   
}

class SelectToType extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props
        this.state = {
            toType: "segments",
        }
    }

    render() {
        return  <>
            <Typography variant="h2" component="p">
                Who do you want to send emails to?
            </Typography>
            <RadioGroup
                style={{paddingLeft:"1rem"}}
                value={this.props.toType}
                row={false} 
                name="toType"
                onChange={(e) => {
                    e.stopPropagation(); 
                    e.preventDefault();
                    
                    if(this.props.onToTypeChange) {
                        let toType:"segments"|"users"|"segments_and_users" = "segments"
                        if(e.target?.value) {
                            switch(e.target.value) {
                                case "users":
                                    toType = "users"
                                    break
                                case "segments":
                                    toType = "segments"
                                    break
                                case "segments_and_users":
                                    toType = "segments_and_users"
                                    break
                            }
                        }
                        this.props.onToTypeChange(toType)
                    }
                }}>
                    <FormControlLabel value={"segments"} control={<Radio color="primary" value={"segments"} />} 
                        label={<Typography variant="body1" component="span">Segments</Typography>} 
                        color="primary" />
                    <FormControlLabel value={"users"} control={<Radio color="primary" value={"users"} />} 
                        label={<Typography variant="body1" component="span">Contacts</Typography>} 
                        color="primary" />
                    <FormControlLabel value={"segments_and_users"} control={<Radio color="primary" value={"segments_and_users"} />} 
                        label={<Typography variant="body1" component="span">Segments and contacts</Typography>} 
                        color="primary" />
            </RadioGroup>
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectToType)
