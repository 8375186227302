import React, {ChangeEvent} from 'react';
import {fetchSenderPersonas, REQUEST_SENDER_PERSONAS} from "./actions/SenderPersonas"
import { connect } from "react-redux";
import {Loading} from "./components/Loading";
import {
    Card,
    FormControl, FormHelperText, Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Add, Edit} from "@material-ui/icons";
import {v4 as uuidv4} from "uuid";
import {SenderPersona} from "./generated/types/payloadTypes";
import {defaultCardElevation,defaultCardStyles} from "./App";
import Fab from "@material-ui/core/Fab";
interface IState {
    id: string,
    selectedPersonaIds: string[],
    senderPersonas: {[key:string]: SenderPersona},
}

interface IProps {
    dispatch: any,
    type: string,
    required?: boolean,
    fetchedSenderPersonas?: {[key:string]:SenderPersona},
    receivingSenderPersonas?: boolean,
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    placeholder?: string,
    label?:string,
    reportErrors?: {[key:string]:string},
    style?:any,
    helperText?: string,
    width:string,
    disabled?: boolean,
    selectedSenderPersonaIds?: string[],
}

const spanStyle = {
    textOverflow:"ellipsis",
    overflow:"hidden",
    display: "inline-block",
    width: "100%",
}

class ListSenderPersonas extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4(),
            selectedPersonaIds: this.props.selectedSenderPersonaIds || [],
            senderPersonas: {},
        };
        this.senderChange = this.senderChange.bind(this);
    }

    senderChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedPersonaIds: null,
            });
            return;
        }

        let selectedPersonaIds = event.target.value as string[];
        if(typeof event.target.value === "string") {
            selectedPersonaIds = [event.target.value];
        }

        this.setState({
            ...this.state,
            selectedPersonaIds,
        });

        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    componentDidMount(){
        this.props.dispatch(fetchSenderPersonas(null))
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(nextProps.fetchedSenderPersonas) {
            const selectedPersonaIds = prevState.selectedPersonaIds;
            return {
                ...prevState,
                selectedPersonaIds,
                senderPersonas: {
                    ...prevState.senderPersonas,
                    ...nextProps.fetchedSenderPersonas,
                },
            }
        }

        return prevState;
    }

    truncateText(text: string, len = 55) : string {
        
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    emailColumnWidth(): string {
        switch(this.props.width) {
            case "xs":
                return "5rem"
            case "sm":
                return "7rem"
            case "md":
                return "8rem"
            default:
                return "10rem"
        }
    }


    render() {
        return <div className={'persona-list'}>
            { this.props.type === 'list' &&
            <Card style={{...defaultCardStyles, ...this.props.style}} elevation={defaultCardElevation}>
                <Grid container>
                    <Grid item>
                        <Typography variant={"h1"} component={"div"}>Sender Personas</Typography>
                    </Grid>
                    <Grid item style={{marginLeft:"10px"}}>
                        <Link to={"/people/sender-personas/new"} title='Add a new sender persona'>
                            <Fab size={"small"} color={"primary"} aria-label={"Add a new sender persona"}>
                                <Add />
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>
                <hr />

                {/* Errors */}
                {this.props.reportErrors[REQUEST_SENDER_PERSONAS] && <p className={"error-text"}>
                    Unable to load sender personas: {this.props.reportErrors[REQUEST_SENDER_PERSONAS]}
                </p>}

                {/* Loading */}
                {!this.props.reportErrors[REQUEST_SENDER_PERSONAS] && this.props.receivingSenderPersonas && <div style={{minHeight:"300px", paddingTop:"2rem"}}><Loading /></div>}

                {/* Main table */}
                {!this.props.reportErrors[REQUEST_SENDER_PERSONAS] && !this.props.receivingSenderPersonas &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component={"th"} style={{width:"25px"}} />
                                <TableCell component={"th"} style={{maxWidth:this.emailColumnWidth()}}>Email</TableCell>
                                <TableCell component={"th"}>First Name</TableCell>
                                <TableCell component={"th"}>Last Name</TableCell>
                                <TableCell component={"th"}>Added</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(this.state.senderPersonas).length === 0 && <TableRow><TableCell colSpan={5}>Nothing here, yet</TableCell></TableRow> }
                            {Object.values(this.state.senderPersonas).map((persona) => (
                                <TableRow key={persona.id}>
                                    <TableCell  style={{width:"25px"}}>
                                        <Link to={"/people/sender-personas/edit/" + persona.id} className="svgShadow">
                                            <Edit />
                                        </Link>
                                    </TableCell>
                                    <TableCell style={{maxWidth:this.emailColumnWidth()}}>
                                        <span style={{...spanStyle, wordWrap:"break-word"}} title={persona.email}>{persona.email}</span></TableCell>
                                    <TableCell><span style={{...spanStyle, wordWrap:"break-word"}} title={persona.first_name}>{persona.first_name}</span></TableCell>
                                    <TableCell><span style={{...spanStyle, wordWrap:"break-word"}} title={persona.last_name}>{persona.last_name}</span></TableCell>
                                    <TableCell>{new Date(persona.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>}
            { this.props.type === 'select' && <>
                {this.props.receivingSenderPersonas && <Loading mySize={"sm"} />}
                {!this.props.receivingSenderPersonas && <FormControl style={this.props.style} error={!!this.props.reportErrors[REQUEST_SENDER_PERSONAS] || !!this.props.helperText}>
                    {this.props.label && <InputLabel id={"senderPersonaSelect"+this.state.id} required={!!this.props.required}>{this.props.label}</InputLabel>}
                    <Select required={!!this.props.required} 
                            id={"senderPersonaSelect"+this.state.id} 
                            value={this.state.selectedPersonaIds}
                            onChange={this.senderChange}
                            disabled={this.props.disabled}
                            MenuProps={{ disableScrollLock: true }}>
                        {this.props.placeholder && <MenuItem value="" disabled selected={true}>{this.props.placeholder}</MenuItem>}
                        {this.state.senderPersonas && Object.values(this.state.senderPersonas).map(persona => {
                            return <MenuItem key={persona.id} value={persona.id}>{persona.first_name} {persona.last_name} {persona.email}</MenuItem>
                        }) }
                    </Select>
                    <FormHelperText required={!!this.props.required}>{this.props.reportErrors[REQUEST_SENDER_PERSONAS] || this.props.helperText || ""}</FormHelperText>
                </FormControl>}
            </>
            }
        </div>
    }
}


function mapStateToProps(state:any, ownProps:IProps):any {
    let fetchedSenderPersonas = null;
    if(state.senderPersonas && Object.keys(state.senderPersonas).length > 0) {
        fetchedSenderPersonas = state.senderPersonas;
    }

    return { ...state, fetchedSenderPersonas };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListSenderPersonas)


