import {GetEventsAndSystemEventsResponsePayload, Event,SystemEvent,GetNumEventsAndSystemEventsResponsePayload, MailerPayload, EmitEmailSendRequestPayload, EmitEmailSendRequestResponsePayload} from "../generated/types/payloadTypes";
import {getAuthKey, getGatewayUrl} from "./Auth";
import { showToast } from "../ToastNotifications";
import {denastifyError, errorsToUL, requestDataErrorAction} from "./Common";

export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const REQUEST_SEND_SINGLE_EMAIL = 'REQUEST_SEND_SINGLE_EMAIL';
export const RECEIVE_SEND_SINGLE_EMAIL_RESPONSE = 'RECEIVE_SEND_SINGLE_EMAIL_RESPONSE';
export const REQUEST_NUM_EVENTS = 'REQUEST_NUM_EVENTS';
export const RECEIVE_NUM_EVENTS = 'RECEIVE_NUM_EVENTS';

interface IRequestEventsAction {
  type: typeof REQUEST_EVENTS,
}
interface IReceiveEventsAction {
  type: typeof RECEIVE_EVENTS,
  data: (Event|SystemEvent)[],
}

interface IRequestSendSingleEmailAction {
  type: typeof REQUEST_SEND_SINGLE_EMAIL,
  mailerPayload: MailerPayload,
}
interface IReceiveSendSingleEmailResponseAction {
  type: typeof RECEIVE_SEND_SINGLE_EMAIL_RESPONSE,
  errors: string[],
}

interface IRequestNumEventsAction {
  type: typeof REQUEST_NUM_EVENTS,
}
interface IReceiveNumEventsAction {
  type: typeof RECEIVE_NUM_EVENTS,
  numEvents: number,
}

export type EventActionTypes = IRequestEventsAction | IReceiveEventsAction | IReceiveNumEventsAction 
  | IRequestNumEventsAction | IRequestSendSingleEmailAction | IReceiveSendSingleEmailResponseAction;


export function requestEvents() : IRequestEventsAction {
  return {
    type: REQUEST_EVENTS,
  }
}

export function receiveEvents(data: any): IReceiveEventsAction {
  return {
    type: RECEIVE_EVENTS,
    data,
  }
}

export function requestSendSingleEmailAction(mailerPayload: MailerPayload): IRequestSendSingleEmailAction {
  return {
    type: REQUEST_SEND_SINGLE_EMAIL,
    mailerPayload,
  }
}

export function receiveSendSignleEmailResponseAction(errors?: string[]): IReceiveSendSingleEmailResponseAction {
  return {
    type: RECEIVE_SEND_SINGLE_EMAIL_RESPONSE,
    errors,
  }
}

export function requestNumEvents() : IRequestNumEventsAction {
  return {
    type: REQUEST_NUM_EVENTS,
  }
}

export function receiveNumEvents(numEvents: number): IReceiveNumEventsAction {
  return {
    type: RECEIVE_NUM_EVENTS,
    numEvents,
  }
}

export function fetchEvents(ids: string[] = [], limit = 10, offset = 0, sortColumns:string[] = [], searchField:string, searchValue:string, includeEvents: boolean, includeSystemEvents: boolean)  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestEvents());
    return fetchEventsPromise(dispatch, ids, limit, offset, sortColumns, searchField, searchValue, includeEvents, includeSystemEvents)
  }
}

function fetchEventsPromise(dispatch: any, ids: string[] = [], limit = 10, offset = 0, sortColumns:string[] = [], searchField:string, searchValue:string, includeEvents: boolean, includeSystemEvents: boolean) {
  console.log("Fetching events");

  //Get sender events from api endpoint
  let url = getGatewayUrl("event") + "/all_events";
  const urlParts = []
  if(ids && ids.length > 0) {
    urlParts.push("ids=" + encodeURIComponent(ids.join(",")))
  }
  if(limit) {
    urlParts.push("limit=" + limit.toString())
  }
  if(offset) {
    urlParts.push("offset=" + offset.toString())
  }
  if(sortColumns && sortColumns.length > 0) {
    urlParts.push("sort_columns=" + encodeURIComponent(sortColumns.join(",")))
  }
  if(searchField && searchValue) {
    urlParts.push("search_field=" + encodeURIComponent(searchField))
    urlParts.push("search_value=" + encodeURIComponent(searchValue))
  }
  if(includeEvents) {
    urlParts.push("include_events=true")
  }
  if(includeSystemEvents) {
    urlParts.push("include_system_events=true")
  }
  if(urlParts.length > 0) {
    url += "?" + urlParts.join("&")
  }
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetEventsAndSystemEventsResponsePayload) => {
    if(json.data) {
      console.log(`Found ${json.data.length} events`);
    }
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_EVENTS));
      showToast(<>Couldn't fetch events: {errorsToUL(json.errors)}</>, "error");
    }
      dispatch(receiveEvents(json.data))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_EVENTS));
    showToast(`Couldn't fetch events: ${denastifyError(e.message)}`, "error");
  });
}

export function fetchNumEvents(ids: string[] = [], searchField:string, searchValue:string, includeEvents:boolean, includeSystemEvents:boolean)  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestEvents());
    return fetchNumEventsPromise(dispatch, ids, searchField, searchValue, includeEvents, includeSystemEvents)
  }
}

function fetchNumEventsPromise(dispatch: any, ids: string[] = [], searchField:string, searchValue:string, includeEvents:boolean, includeSystemEvents:boolean) {
  console.log("Fetching num events");

  //Get sender events from api endpoint
  let url = getGatewayUrl("event") + "/all_events/count";
  const urlParts = []
  if(ids && ids.length > 0) {
    urlParts.push("ids=" + encodeURIComponent(ids.join(",")))
  }
  if(searchField && searchValue) {
    urlParts.push("search_field=" + encodeURIComponent(searchField))
    urlParts.push("search_value=" + encodeURIComponent(searchValue))
  }
  if(includeEvents) {
    urlParts.push("include_events=true")
  }
  if(includeSystemEvents) {
    urlParts.push("include_system_events=true")
  }
  if(urlParts.length > 0) {
    url += "?" + urlParts.join("&")
  }
  fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': getAuthKey(),
    }
  })
  .then(res => res.json())
  .then((json:GetNumEventsAndSystemEventsResponsePayload) => {
    if(json.num_events) {
      console.log(`Found count of ${json.num_events} events`);
    }
    if(json.errors && json.errors.length > 0) {
      dispatch(requestDataErrorAction(JSON.stringify(json.errors),REQUEST_NUM_EVENTS));
      showToast(<>Couldn't fetch num events: {errorsToUL(json.errors)}</>, "error");
    }
      dispatch(receiveNumEvents(json.num_events))
  }).catch(e => {
    dispatch(requestDataErrorAction(e.message,REQUEST_NUM_EVENTS));
    showToast(`Couldn't fetch events: ${denastifyError(e.message)}`, "error");
  });
}



export function sendSingleEmail(mailerPayload: MailerPayload)  {
  return function(dispatch:(...args:any)=>any) {
    dispatch(requestSendSingleEmailAction(mailerPayload));
    return sendSingleEmailPromise(dispatch, mailerPayload)
  }
}

function sendSingleEmailPromise(dispatch: any, mailerPayload: MailerPayload) {
 //Get sender templates from api endpoint
 const url = getGatewayUrl("event") + "/emit_send_single_email_event";
 console.log("POSTING TO URL",url);
 const data:EmitEmailSendRequestPayload = {
   mailer_payload: mailerPayload,
 }
 fetch(url, {
   method: 'post',
   mode: 'cors',
   headers: {
     'Content-Type': 'application/json',
     'x-api-key': getAuthKey(),
   },
   body: JSON.stringify(data),
 })
 .then(res => res.json())
 .then((json:EmitEmailSendRequestResponsePayload) => {
   if(json.errors && json.errors.length > 0) {
      dispatch(receiveSendSignleEmailResponseAction(json.errors))
     showToast(<>Couldn't send email: {errorsToUL(json.errors)}</>, "error");
   } else {
    dispatch(receiveSendSignleEmailResponseAction())
    showToast("Successfully sent email to " + mailerPayload.to[0].email, "success");
   }
 }).catch(e => {
   showToast(`Couldn't send email: ${denastifyError(e.message)}`, "error");
 });
}
