import React from 'react';
import {defaultCardElevation} from "../App";
import {Card, Typography} from "@material-ui/core";
import {pBottom, pImageStyle,blogPostCardStyle,postMetaDetails} from "./blogPosts"

interface IProps {
    dispatch?: any,
}

export function PageBlog20231203() {
    return <>
        <Card elevation={defaultCardElevation} style={blogPostCardStyle}>
            <Typography variant={"h1"} style={{textAlign:"center"}}>Event Triggered Emails</Typography>
            <Typography variant={"h3"} style={{textAlign:"center"}}>How to use Swift Missive with your company's events to drive transaction and drip campaign emails.</Typography>
            {postMetaDetails()}

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Welcome to the Swift Missive blog!👋
            </Typography>
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Today I'm going to outline how you can use the Swift Missive API along with events from your company to trigger
            emails that go out to your contacts.  Usually I get requests for this when people want to do one of the following:
            </Typography>

            <Typography variant="body1" component="ul" style={{marginBottom:pBottom}}>
                <li>Send purchase receipts or password reset emails to their customers.</li>
                <li>Confirm newsletter subscriptions.</li>
                <li>Start their customers on a drip campaign, which will automatically email them at a later date.
                    <br />This is to help with:
                    <ul>
                        <li>Customer retention / stickiness</li>
                        <li>Return sales</li>
                        <li>Brand recognition</li>
                        <li>Product promotion via sales and discounts</li>
                    </ul>
                </li>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            If you are interested in any of the above, read on!
            </Typography>

            <Typography variant={"h2"}>Overview</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Here is a super-simplified version of an event-triggered email-sending workflow.
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
                <img src="/images/Blog-2023-12-03-1.svg" 
                        style={{width:"90%"}}
                        title="An event-triggered email-sending workflow"
                        alt="An event-triggered email-sending workflow" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                A nice guy orders flowers and receives a well-crafted email receipt
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Here are the steps being outlined in the image:
            </Typography>

            <Typography variant="body1" component="ol" style={{marginBottom:pBottom}}>
                <li>A customer visits your website to look for Valentine's day flowers.</li>
                <li>They find a perfect bunch of flowers and order it.</li>
                <li>Your website sends the order event to your website's backend processing code, which will 
                    process the order:
                    <ol>
                        <li>Your credit card processor is asked to charge the customer's credit card.</li>
                        <li>Your credit card customer charges the card, then tells your backend code when it is done.</li>
                    </ol>
                </li>
                <li>Now that the order is complete, your backend code sends an event with the customers email to Swift Missive</li>
                <li>Swift Missive picks up the event and looks for any campaigns or workflows that are set up to handle that type 
                    of event.  In this case the "receipt" workflow picks up the event and sends a receipt email with all the 
                    customer's information in it.
                </li>
                <li>The "receipt" workflows template is rendered using the variables from the purchase event, and a lovely receipt
                    email is sent to the nice guy who ordered flowers from your company.
                </li>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            I'm going to assume from here on that you already have a website that can process orders.  
            If not, that's probably a good first step, then return here once you're ready to continue.
            Once you're able to process orders, read the following steps on how you can send events to our
            system in order to trigger transactional and drip campaign email automations. 
            </Typography>

            <Typography variant={"h2"}>Preparing an "order complete" event</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            These steps can be a little bit tricky if you're not comfortable with coding.  For best results,
            make sure there is at least a level-seven nerd present when attempting these steps 🤓
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            <b>Structuring an event</b>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            When we use the term "event", we are referring to a <a href="https://en.wikipedia.org/wiki/JSON" target="_blank" rel="noreferrer">JSON blob</a> 
            &nbsp; of data that describes something that happened in the past.  For Swift Missive events, the name of the event and 
            the email of the person associated with the event should always be in the same spot.  The data listed under the key "json"
            of each event's data can take any shape you want, though.   Here is an example JSON blob:
            </Typography>
            
            <pre className={"inset-code-box"} >
                {JSON.stringify({
                    "events": [
                        {
                            "name":"order complete",
                            "email":"success+100@simulator.amazonses.com",
                            "json": {
                                "description": "12 roses, 1 tiger lily",
                                "total": 40.47,
                                "total_currency": "USD"
                            }
                        }
                    ]
                }, null, 2)}
            </pre>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            And here is a second example.  Note that the information in under the "json" key is different in each example.
            </Typography>
            
            <pre className={"inset-code-box"} >
                {JSON.stringify({
                    "events": [
                        {
                            "name":"order complete",
                            "email":"success+100@simulator.amazonses.com",
                            "json": {
                                "items": [
                                    {
                                        "sku": "flower2",
                                        "name": "tiger lily",
                                        "details": {
                                            "color": "orange"
                                        },
                                        "price": 4.78,
                                        "quantity": 1
                                    }
                                ],
                                "total": 4.78,
                                "total_currency": "USD",
                                "shipping": {
                                    "to": {
                                        "name": "Nice Guy",
                                        "address1": "123 Some Street",
                                    }
                                }
                            }
                        }
                    ]
                }, null, 2)}
            </pre>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            What we're seeing in the JSON blobs above is a description of an event that occurred when 
            your customer ordered a flower.  How that event is formed will be a little bit different depending 
            on what your backend order-processing code looks like.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            <b>Constructing an event</b>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Actually creating the json blob will also be a little bit different depending on the 
            coding language that you backend order-processing code "speaks."  Here are some 
            examples of forming a JSON blob from an order in a few different languages:
            </Typography>

            <Typography variant={"body1"} component={"p"} style={{color:"gray"}}>
            <b>GoLang</b>
            </Typography>
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>
            // Turn the order into a JSON event <br />
            eventJSON, _ := json.Marshal(order) <br /> <br />
            // Output the JSON text <br />
            fmt.Printf("%s", eventJSON) <br />
            </pre>

            <Typography variant={"body1"} component={"p"} style={{color:"gray"}}>
            <b>Javascript / Typescript</b>
            </Typography>
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>
            // Turn the order into a JSON event <br />
            var json = JSON.stringify(order, null, 2); <br /> <br />
            // Output the JSON text  <br />
            console.log(json); <br />
            </pre>

            <Typography variant={"body1"} component={"p"} style={{color:"gray"}}>
            <b>PHP</b>
            </Typography>
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>
            // Turn the order into a JSON event <br />
            $orderJSON = json_encode($arr); <br /> <br />
            // Output the JSON text  <br />
            echo $orderJSON; <br />
            </pre>
        
            <Typography variant={"h2"}>Sending your event to Swift Missive</Typography>


            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Now that you're able to construct an event from your backend system, you'll want to send it to 
            your account's endpoint in the Swift Missive API in order to trigger emails within our system.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Like most APIs, the Swift Missive API accepts HTTP POST requests to ingest events.  The exact 
            url you need to make a post to is different for each account. To find out what url you should send 
            events to, <a href="/login" target="_blank">sign in</a> to Swift Missive and then navigate to the 
            &nbsp;<a href="/account/settings" target="_blank">settings page</a>.  If you don't have an account yet 
            you can also try this out using the <a href="/demo-login" target="_blank">live demo.</a>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Once you are on the Settings page for your account, look for the API endpoints section.  There, you will see 
            a URL where you want to post your events to, the HTTP method you want to use send the events (POST), the headers
            you need to send in the HTTP post, and a sample event that should look a lot the the JSON from the last section.
            We will need all of these components to send our event.  Like the JSON formation we looked at in the last section,
            each backend language has a different way to send HTTP POST requests.  Here are some examples:
            </Typography>


            <Typography variant={"body1"} component={"p"} style={{color:"gray"}}>
            <b>GoLang</b>
            </Typography>
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>
            // Turn the order into a JSON event <br />
            eventJSON, _ := json.Marshal(order) <br /> <br />

            // Output the JSON text <br />
            fmt.Printf("%s", eventJSON) <br /><br />

            // POST the event to Swift Missive <br />
            r, _ := http.NewRequest("POST", "<u style={{color:"orange"}}>URL FROM YOUR SETTINGS PAGE GOES HERE</u>", bytes.NewBuffer(eventJSON))<br /><br />
	
            // Set headers that the API server needs so it can make sense of our request<br /> 
            r.Header.Add("Content-Type", "application/json")<br />
            r.Header.Add("x-api-key", "<u style={{color:"orange"}}>THE API KEY FROM YOUR SETTINGS PAGE GOES HERE</u>")<br /><br />
            
            // Send the event! <br />
            client := &http.Client{}<br />
            _, _ := client.Do(r)<br />
            </pre>

            <Typography variant={"body1"} component={"p"} style={{color:"gray"}}>
            <b>Javascript / Typescript</b>
            </Typography>
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>
            // Turn the order into a JSON event <br />
            var json = JSON.stringify(order, null, 2); <br /> <br />

            // Output the JSON text  <br />
            console.log(json); <br />< br/>

            // POST the event to Swift Missive <br />
            await fetch('<u style={{color:"orange"}}>URL FROM YOUR SETTINGS PAGE GOES HERE</u>', {`{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `}
                    <u style={{color:"orange"}}>THE API KEY FROM YOUR SETTINGS PAGE GOES HERE</u>{`
                },
                body: json
            }`});
            </pre>

            <Typography variant={"body1"} component={"p"} style={{color:"gray"}}>
            <b>PHP</b>
            </Typography>
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>
            // Turn the order into a JSON event <br />
            $orderJSON = json_encode($arr); <br /> <br />

            // Output the JSON text  <br />
            echo $orderJSON; <br /><br />

            // Prepare the request <br />
            $ch = curl_init("<u style={{color:"orange"}}>URL FROM YOUR SETTINGS PAGE GOES HERE</u>");<br />
            curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);<br />
            curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");<br />
            curl_setopt($ch, CURLOPT_POSTFIELDS, $orderJSON);<br />
            curl_setopt($ch, CURLOPT_HTTPHEADER, array(<br />
              &nbsp;&nbsp;'Content-Type: application/json',<br />
              &nbsp;&nbsp;'x-api-key: <u style={{color:"orange"}}>THE API KEY FROM YOUR SETTINGS PAGE GOES HERE</u>'<br />
            ));<br /><br />

            // Send the request <br />
            curl_exec($ch);<br />
            </pre>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            As an easy alternative if you're not ready to alter your backend code yet, you can try to use
            a tool like <a href="https://www.postman.com/" target="_blank" rel="noreferrer">Postman</a>&nbsp; to send your HTTP post
            instead of actual code like the above examples.
            </Typography>


            <Typography variant={"h2"}>Triggering an email with the event</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Hopefully by now you're able to send events to your account's API endpoint.  Now it's time for the 
            fun part--let's trigger an email!
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            <b>Creating a template</b>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            The first thing we're going to need to do is to create an email template.  Let's create a simple 
            one on the <a href="/components/templates/new" target="_blank">templates page</a>. Start out by naming 
            your template, and then by setting its type to "transactional."  Next, try drag-dropping 
            some columns, and then some text into it.  Maybe throw a cute dog in there if you have an image handy.  🐶 
            </Typography>
            
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            In order to use the fields from the event's JSON blob, we're going to need to use template variables 
            in our text fields.  These are simply values that are passed in and are associated with a certain key in JSON.
            For example, if our JSON was <code>{JSON.stringify({ "events": [ {"name":"myevent" }] })}</code>, then a template
            variable of <code>{`{{event.name}}`}</code> would be replaced with <code>myevent</code> in the rendered email template.
            I'm eventually going send the first JSON event listed in this document to the Swift Missive api, so 
            I created a test template using this text:
            </Typography>
            
            <pre className={"inset-code-box"} style={{marginBottom:pBottom}}>{`
            Woof! Thanks for your order!

            Order description:  {{event.json.description}}

            Order total:  $ {{event.json.total}}
            `}</pre>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Just for fun I threw in some emojis and some AI-generated pupper images  🐶  Hey, this is looking 
            pretty cute!
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
                <img src="/images/Blog-2023-12-03-2.png" 
                    style={{width:"70%"}}
                    title="An absurdly cute receipt email template with template variables"
                    alt="An absurdly cute receipt email template with template variables" 
                    />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                Our template inside the Swift Missive template editor, including two AI-generated dog images
            </Typography>
            <Typography variant={"body1"} style={{marginBottom:pBottom, marginTop:pBottom}} component={"p"}>
            <b>Setting up a campaign</b>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            If you don't have a sender persona set up yet, it's probably best to 
            &nbsp;<a href="/people/sender-personas/new" target="_blank">create one</a> now.  
            Once that's done, setting up a campaign will actually be pretty easy with the campaign wizard: 
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"ol"}>
            <li>When logged in, navigate to the <a href="/campaigns/v2/new" target="_blank">campaign creation wizard</a>.</li>
            <li>Select "trigger transactional emails with events from my company" and click Next.</li>
            <li>Name your campaign something clever and click Next.</li>
            <li>Enter the name of the event you'll be sending the API. This will need to be the value associated
                with the "name" key in the JSON.  For instance if the event JSON will look like this: 
                <code>{JSON.stringify({ "events": [ {"name":"myevent" }] })}</code> then the name of your events 
                is "myevent".  Click Next after entering the name.
            </li>
            <li>Select your sender persona and click Next.</li>
            <li>Select your template and click Next.</li>
            <li>Review your choices then click Next and then Launch.  Please don't close the page while it's doing work.</li>
            <li>Once the success page pops up, the campaign is ready to receive events!</li>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            <b>Triggering your new campaign</b>
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Once you have everything set up--a sender persona, email template including template variables, 
            a campaign that is ready to receive those events and send templates, and your event sender itself--you 
            should be ready to send some events and get some emails back!  For testing purposes it may be wise to send your first few 
            events as if a user with the email "success+100@simulator.amazonses.com" made a purchase on your site.  That email
            receiver is provided by AWS and is intended to receive test traffic.  Don't worry, you'll still be able see your 
            rendered email to make sure everything is set up correctly by finding the contact and checking their recent 
            emails list.  By clicking on a recent email you'll be able to see what was sent even if you don't have access 
            to the recipient's inbox!  Here is what our template looks like after I followed all the steps in this blog:
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
                <img src="/images/Blog-2023-12-03-03.png" 
                    style={{width:"70%"}}
                    title="An absurdly cute RENDERED receipt email template with template variables"
                    alt="An absurdly cute RENDERED receipt email template with template variables" 
                    />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                A perfectly-rendered and absurdly cute email receipt
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:"5rem"}} component={"p"}>
            Looks pretty good, huh?  Give it a shot yourself if you haven't yet!  If you need some help,
            don't hesitate to ask us a question or two!  Probably the best way to do that right now is to use 
            the <a href="/about/contact-us" target="_blank">contact us</a> form.  Happy coding everyone!
            </Typography>
        </Card>
    </>
}
