import { Typography } from '@material-ui/core';
import React from 'react';
import {connect} from "react-redux";
import { AllState } from '../../reducers';
import ListUsers from '../../ListUsers';


interface IProps {
    width: string,
    dispatch: any,
    onUsersChange:(userIds:{[key:string]:boolean}) => void
    selectedUserIds:{[key:string]:boolean},
    prevPageText?: JSX.Element,
    nextPageText?: JSX.Element,
}
interface IState {
}

class SelectUsers extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props
        this.state = {
            selectedUserIds: {},
        }
    }

    render() {
        return  <>
            <Typography variant="h2" component="p">
                Please select your target contacts
            </Typography>
            <Typography variant="body1" component="p" style={{marginBottom:"1rem"}}>
                <b>{Object.keys(this.props.selectedUserIds).length  ?? "0" } contact{Object.keys(this.props.selectedUserIds).length == 1 ? "":"s" } currently selected</b>
            </Typography>
            <ListUsers 
                type="list" 
                limit={5}
                selectedUserIds={Object.keys(this.props.selectedUserIds)}
                dispatch={this.props.dispatch} 
                width={this.props.width} 
                pagingButtonVariant='outlined'
                buttons={{"add":true,"remove":true, "edit":true}}
                editButtonTargetBlank={true}
                toggleIfNameClicked={true} 
                prevPageText = {this.props.prevPageText}
                nextPageText = {this.props.nextPageText}
                onUserAdd={id => {
                    if(this.props.onUsersChange) {
                        this.props.onUsersChange({
                            ...this.props.selectedUserIds, 
                            [id]: true,
                        })
                    }
                }}
                onUserRemove={id => {
                    if(this.props.onUsersChange) {
                        const newUserIds:{[key:string]:boolean} = {}
                        Object.keys(this.props.selectedUserIds).map(currentId => {
                            if(id != currentId) {
                                newUserIds[currentId] = true
                            }
                        })
                        this.props.onUsersChange(newUserIds)
                    }
                }}
                />
        </>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return {...state};
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(SelectUsers)
