import React from 'react';
import {defaultCardElevation} from "../App";
import {Card, Typography} from "@material-ui/core";
import {pBottom, pImageStyle,blogPostCardStyle,postMetaDetails} from "./blogPosts"

interface IProps {
    dispatch?: any,
}

export function PageBlog20230808() {
    return <>
        <Card elevation={defaultCardElevation} style={blogPostCardStyle}>
            <Typography variant={"h1"} style={{textAlign:"center"}}>Timezones and email marketing</Typography>
            <Typography variant={"h3"} style={{textAlign:"center"}}>How adjusting to your contacts' timezones improves your open rates.</Typography>
            {postMetaDetails()}

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Hi!👋
            </Typography>
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            In this blog post I'm going to dig into contact timezones and how they affect your open rates.
            In short, you should only be sending emails from an email service provider (like Swift Missive) 
            that lets you schedule emails so that they arrive
            in your contacts' inboxes at the best time, which will be a different time depending on where your 
            contacts are sitting.
            </Typography>

            <Typography variant={"h2"}>Why do timezones matter at all?</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Email marketers know that messages that show up further down the inbox, or 
            <a href="https://www.wordstream.com/below-the-fold" target="_blank" style={{marginLeft:"3px"}} rel="noreferrer">below the fold</a> especially, 
            are more likely to be ignored or missed completely.  So they try to use their knowledge of 
            consumer patterns along with precise timing to get their emails to be the first thing 
            their contacts see when they open their inboxes.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Contacts usually open emails when they are in between other things.  So we usually want our
            emails to arrive when they are just waking up,
            about to head to work, taking a lunch break, about to head home, or about to fall asleep.
            Let's graph it!
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
                <img src="/images/Blog-2023-08-08-00.png" 
                        style={{width:"70%"}}
                        title="Email open rates fluctuate throughout the day"
                        alt="Email open rates fluctuate throughout the day" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
                People tend to open emails at certain times of day
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            So if we want to increase open rates for our midday newsletter we can just send at lunchtime
            instead of at 10am, right?  Sort of... but not quite. 
            Since our contacts are likely spread across several different timezones, we have to think in terms of 
            relative time instead of universal time.
            </Typography>
            
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            This is where timezones start to get confusing.  If I want my email
            to show up when my contacts are eating lunch, for instance, then I can't just send out an email 
            blast at noon in my timezone.  Noon in my timezone might be 11am for someone else, 10am for their friend
            who lives in the next state over, and so on.  Let's take a look at what "noon eastern time" means for different 
            areas of the United States.
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
                <img src="/images/Blog-2023-08-08-01.png" 
                        style={{width:"70%"}}
                        title="Targeting a certain time of day gets tricky when you add in timezones"
                        alt="Targeting a certain time of day gets tricky when you add in timezones" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0"}} component={"p"}>
            "Lunchtime" becomes a moving target once you add in timezones
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            So if we are on the east coast and are aiming for the 12pm email-opening spike, 
            then for our fellow New York City folks we should send out our email blast 
            right at noon so they will have a piping hot email at the top of their inbox when they sit down
            with their slice of cheese pizza and decide to check their email.  
            But wait a minute, that means our friends in Chicago will be receiving an email at 11am their time.
            And even worse, denizens of Denver will get the email around 10am.  
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            One way to get around these timezone issues is to schedule one email blast per timezone.  But you 
            really don't need to do that.  Any self-respecting email service provider will handle timezones for you.
            </Typography>

            <Typography variant={"h2"}>How Swift Missive helps you navigate timezones</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Swift Missive will automatically queue up 
            your emails in different timezones for you so that you don't have to think about them. 
            For instance, if you're sending an email blast we'll ask you if you want to send it in your timezone,
            a different timezone, or in each receiver's timezone.  We do the same thing for drip campaigns as 
            well.  If you're curious about how we pull this off, please read on.  We use what we call a "delay 
            queue." 
            </Typography>

            <Typography variant={"body1"} style={{...pImageStyle, marginBottom:"0", paddingBottom:"0"}} component={"p"}>
            <img src="/images/Blog-2023-08-08-02.png" 
                        style={{width:"70%"}}
                        title="An overview of a queueing system that waits for the right time to deliver your emails"
                        alt="An overview of a queueing system that waits for the right time to deliver your emails" 
                        />
            </Typography>
            <Typography variant={"body1"} style={{...pImageStyle, color:"#777", paddingTop:"0", marginTop:"0", textAlign:"center"}} component={"p"}>
            An overview of a queueing system that waits for the right time to deliver your emails.<br />
            We promise our actual delay queue does not look like a giant spider.
            </Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Delay queues know that when you say "I want this email to show up at noon in my contacts' timezones," that
            it will need to send some emails immediately, some emails a little later, and so on, to make sure 
            that your emails show up at the best time to optimize your open rates.  The less time you spend on 
            navigating timezones, the more time you have for everything else.
            </Typography>

            <Typography variant={"h2"}>Choose a timezone-aware email service provider!</Typography>

            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            We hope this blog post helps to explain some of the complexities around email marketing
            and timezones.  Even if you don't end up choosing Swift Missive, that's ok, we can still be  
            friends.  But at the very least please make sure you use an email service provider that takes away the 
            complexity of timezones for you.  You deserve at least that.
            </Typography>
        
            <Typography variant={"body1"} style={{marginBottom:pBottom}} component={"p"}>
            Thanks for reading!
            </Typography>
        </Card>
    </>
}
