import React, {ChangeEvent, FormEvent} from 'react';
import ListTemplates from "../ListTemplates";
import ListSenderPersonas from "../ListSenderPersonas";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import {
  AccessTime, AttachMoney,
  Description,
  EmojiPeople,
  Event,
  GolfCourse,
  KeyboardCapslock,
  Language,
  PlayCircleFilled,
  VerticalAlignCenter
} from "@material-ui/icons";
import {TimezoneSelect} from "../components/TimezoneSelect";
import { INode } from '@mrblenny/react-flow-chart/src/types/chart';
import DateTimePickerRow from '../components/DateTimePickerRow';
import moment from 'moment';
 
export const usersLocalTimezone = "Contact's local timezone";

export interface INodeSettings {
  delayMS?: number,
  templateId?: string,
  senderPersonaId?: string,
  dataSource?: string,
  delayUntil?: moment.Moment, 
  delayUntilAnyDate?: boolean,
  delayUntilExclusive?: boolean,
  eventName?: string,
  delayUntilTimezone?: string,
}

interface IProps {
  nodes: {[key:string]:INode},
  selectedNodeId: string,
  onSettingsUpdate: (nodeId:string, settings:INodeSettings) => void,
  dispatch: any,
  width: string,
}

class NodeSettings extends React.Component<IProps,any> {
  props:IProps;

  constructor(props: IProps) {
    super(props);
    this.props = props;
  }

  render() {
    const selectedNode:INode = this.props.nodes[this.props.selectedNodeId];
    return <>{selectedNode && <form onSubmit={(event:FormEvent) => { event.stopPropagation(); event.preventDefault(); }}>
      {selectedNode.type === "user_upserted" && <UserUpsertedSettings {...this.props} />}
      {selectedNode.type === "event" && <EventOccurredSettings {...this.props} />}
      {selectedNode.type === "delay" && <DelayNodeSettings {...this.props} />}
      {selectedNode.type === "delay_until" && <DelayUntilNodeSettings {...this.props} />}
      {selectedNode.type === "sender" && <SenderNodeSettings {...this.props} />}
      <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"}>
        <Grid item>
          <GolfCourse />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <TextField InputProps={{
                readOnly: true,
                disabled: true,
              }}
              style={{width:"100%", minWidth:"300px"}}
              label={"Node Id"}
              value={selectedNode.id}
          />
        </Grid>
      </Grid>
    </form>}</>;
  }
}

class EventOccurredSettings extends React.Component<IProps, any> {
  props:IProps;

  constructor(props:IProps) {
    super(props);
    this.props = props;
    this.handleEventNameChange = this.handleEventNameChange.bind(this);
  }

  handleEventNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    //Update node in parent
    const eventName = event.target.value;
    this.props.onSettingsUpdate(this.props.selectedNodeId, {
      eventName,
    })
  }


  render() {
    const value = "Event occurred";
    let eventName = "";

    if(this.props.nodes[this.props.selectedNodeId] &&
        this.props.nodes[this.props.selectedNodeId].properties &&
        this.props.nodes[this.props.selectedNodeId].properties.eventName) {
      eventName = this.props.nodes[this.props.selectedNodeId].properties.eventName;
    }

    return <div key={this.props.selectedNodeId}>
    <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"} key={"type"+this.props.selectedNodeId}>
      <Grid item>
        <PlayCircleFilled />
      </Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
        <TextField InputProps={{
              readOnly: true,
              disabled: true,
            }}
           style={{width:"100%", minWidth:"300px"}}
           label={"Trigger"}
           value={value}
        />
      </Grid>
    </Grid>
    <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"} key={"name"+this.props.selectedNodeId}>
      <Grid item>
        <AttachMoney />
      </Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
        <TextField
            autoFocus={true}
            required={true}
             style={{width:"100%", minWidth:"300px"}}
             label={"Event name"}
             value={eventName}
             onChange={this.handleEventNameChange}
        />
      </Grid>
    </Grid>
    </div>
  }
}

class UserUpsertedSettings extends React.Component<IProps, any> {
  props:IProps;

  constructor(props:IProps) {
    super(props);
    this.props = props;
  }

  render() {
    const value = "New contact created";
    return <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"} key={this.props.selectedNodeId}>
      <Grid item>
        <PlayCircleFilled />
      </Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
        <TextField InputProps={{
              readOnly: true,
              disabled: true,
            }}
            style={{width:"100%", minWidth:"300px"}}
            label={"Trigger"}
            value={value}
        />
      </Grid>
    </Grid>
  }
}

class DelayNodeSettings extends React.Component<IProps, any> {
  props:IProps;

  constructor(props:IProps) {
    super(props);
    this.props = props;

    //Set defaults so that even if we don't change anything we'll still end up getting the right values
    let delayMS = 0
    if(this.props.nodes[this.props.selectedNodeId] 
      && this.props.nodes[this.props.selectedNodeId].properties 
      && this.props.nodes[this.props.selectedNodeId].properties.delayMS) {
        delayMS = this.props.nodes[this.props.selectedNodeId].properties.delayMS
    }
    this.props.onSettingsUpdate(this.props.selectedNodeId, {delayMS})

    this.handleDelayChange = this.handleDelayChange.bind(this);
  }

  handleDelayChange(event: React.ChangeEvent<HTMLInputElement>) {
    //Update node in parent
    const delayMS = parseFloat(event.target.value) * 1000;
    this.props.onSettingsUpdate(this.props.selectedNodeId, {
      delayMS
    })
  }

  render() {
    let delaySeconds = 0;

    if(this.props.nodes[this.props.selectedNodeId] &&
    this.props.nodes[this.props.selectedNodeId].properties &&
    this.props.nodes[this.props.selectedNodeId].properties.delayMS) {
      delaySeconds = this.props.nodes[this.props.selectedNodeId].properties.delayMS / 1000;
    }

    return <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"} key={this.props.selectedNodeId}>
      <Grid item>
        <AccessTime />
      </Grid>
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
        <TextField label={"Delay (seconds)"}
                   autoFocus={true}
                   required={true}
                   type={"number"}
                   style={{width:"100%", minWidth:"300px"}}
                   value={delaySeconds}
                   onChange={this.handleDelayChange}
        />
      </Grid>
    </Grid>
  }
}


class DelayUntilNodeSettings extends React.Component<IProps,any> {
  props:IProps

  constructor(props:IProps) {
    super(props);
    this.props = props;

    //Set defaults so that even if we don't change anything we'll still end up getting the right values
    this.props.onSettingsUpdate(this.props.selectedNodeId, this.getProps())

    this.dateChange = this.dateChange.bind(this);
    this.timezoneChange = this.timezoneChange.bind(this);
    this.anyDateChange = this.anyDateChange.bind(this);
  }

  timezoneChange(event: React.ChangeEvent<HTMLInputElement>) {
      const newTimezone = event.currentTarget.textContent
      this.props.onSettingsUpdate(this.props.selectedNodeId, {
        delayUntilTimezone: newTimezone,
      })
  }

  dateChange(newDate: moment.Moment) {
    this.props.onSettingsUpdate(this.props.selectedNodeId, {
      delayUntil: newDate,
    })
  }

  anyDateChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
    //Wait until the given date & time, excluding tardy messages
    let delayUntilAnyDate = false
    let delayUntilExclusive = true
    switch(event.target.value) {
      case 1: //Wait until at least the given date & time, allowing tardy messages
        delayUntilAnyDate = false
        delayUntilExclusive = false
        break
      case 2: //Wait until the given time of day exactly, waiting until the next day if it's after that time for the current day
        delayUntilAnyDate = true
        delayUntilExclusive = false
        break
    }

    this.props.onSettingsUpdate(this.props.selectedNodeId, {
      delayUntilAnyDate,
      delayUntilExclusive,
    });
  }

  getProps(): {delayUntil:moment.Moment, delayUntilTimezone:string, delayUntilAnyDate:boolean,delayUntilExclusive:boolean} {
    let delayUntilTimezone = usersLocalTimezone
    let delayUntilAnyDate = false
    let delayUntilExclusive = true
    let delayUntil = moment().tz(moment.tz.guess(), false).tz("UTC", true)
    if(this.props.nodes[this.props.selectedNodeId] && this.props.nodes[this.props.selectedNodeId].properties) {
      const thisProps = this.props.nodes[this.props.selectedNodeId].properties
      if(thisProps.delayUntilTimezone) delayUntilTimezone = thisProps.delayUntilTimezone
      if(thisProps.delayUntil) delayUntil = thisProps.delayUntil
      if(typeof thisProps.delayUntilAnyDate !== "undefined")  delayUntilAnyDate = thisProps.delayUntilAnyDate;
      if(typeof thisProps.delayUntilExclusive !== "undefined")  delayUntilExclusive = thisProps.delayUntilExclusive;
    }

    return {
      delayUntil,
      delayUntilTimezone,
      delayUntilAnyDate,
      delayUntilExclusive,
    }
  }

  render() {
    const { delayUntil, delayUntilTimezone, delayUntilAnyDate, delayUntilExclusive } = this.getProps();
    let delayStyle = 0;
    let delayIcon = <VerticalAlignCenter style={{transform:"rotate(90deg)"}} />;
    if(!delayUntilAnyDate && !delayUntilExclusive) {
      delayStyle = 1;
      delayIcon = <KeyboardCapslock style={{transform:"rotate(90deg)"}} />
    } else if(delayUntilAnyDate && !delayUntilExclusive) {
      delayStyle = 2;
      delayIcon = <AccessTime />
    }
 
    return <div key={this.props.selectedNodeId}>
      <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"}>
        <Grid item>
          {delayIcon}
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <FormControl style={{width:"100%",minWidth:"300px"}}>
          <InputLabel id={"anyDateSelect"}>Delay style</InputLabel>
            <Select required={true} MenuProps={{ disableScrollLock: true }} id={"anyDateSelect"} value={delayStyle} onChange={this.anyDateChange}>
              <MenuItem key={"dateandtimethenstop"} value={0}>Wait until the given date & time, excluding tardy messages</MenuItem>
              <MenuItem key={"dateandtime"} value={1}>Wait until at least the given date & time, allowing tardy messages</MenuItem>
              <MenuItem key={"timeonly"} value={2}>Wait until the given time of day exactly, waiting until the next day if it's after that time for the current day</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"}>
        <Grid item>
          <Event />
        </Grid> 
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <FormControl style={{width:"100%",minWidth:"300px"}} >
             <FormHelperText required={true}>Delay until</FormHelperText>
             <DateTimePickerRow 
                hideDatePicker={delayUntilAnyDate}
                datetimeMoment={delayUntil} 
                dispatch={this.props.dispatch}
                onChange={this.dateChange} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"}>
        <Grid item>
          <Language />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <TimezoneSelect label={"Timezone"}
                placeholder={"Select timezone"}
                required={true}
                style={{width:"100%", minWidth:"300px"}}
                includeUsersLocalTimezoneOption={true}
                firstOption={usersLocalTimezone}
                timezone={delayUntilTimezone}
                timezoneChange={this.timezoneChange}
              />
        </Grid>
      </Grid>
    </div>
  }
}


class SenderNodeSettings extends React.Component<IProps, any> {
  props:IProps;

  constructor(props:IProps) {
    super(props);
    this.props = props;
    this.personaChange = this.personaChange.bind(this)
    this.templateChange = this.templateChange.bind(this)
  }

  personaChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
    if(typeof event.target.value === "string") {
      this.props.onSettingsUpdate(this.props.selectedNodeId, {
        senderPersonaId: event.target.value,
      })
    }
  }

  templateChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
    if(typeof event.target.value === "string") {
      this.props.onSettingsUpdate(this.props.selectedNodeId, {
        templateId: event.target.value,
      })
    }
  }

  render() {
    let selectedTemplateId = ""
    let selectedPersonaId = ""
    if(this.props.nodes[this.props.selectedNodeId] && this.props.nodes[this.props.selectedNodeId].properties) {
        selectedTemplateId = this.props.nodes[this.props.selectedNodeId].properties.templateId
        selectedPersonaId = this.props.nodes[this.props.selectedNodeId].properties.senderPersonaId
    }

    return <div key={this.props.selectedNodeId}>
      <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"}>
        <Grid item>
          <Description />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <ListTemplates dispatch={this.props.dispatch}
                width={this.props.width}
                type={"select"}
                style={{width:"100%", minWidth:"300px"}}
                onChange={this.templateChange}
                selectedTemplateId={selectedTemplateId}
                label={"Email template"}
                placeholder={"Select email template"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} wrap={"nowrap"} alignItems="flex-end" alignContent={"flex-start"}>
        <Grid item>
          <EmojiPeople />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <ListSenderPersonas dispatch={this.props.dispatch}
                  width={this.props.width}
                  type={"select"}
                  style={{width:"100%", minWidth:"300px"}}
                  onChange={this.personaChange}
                  selectedSenderPersonaIds={[selectedPersonaId]}
                  label={"Sender persona"}
                  placeholder={"Select sender personas"}
          />
        </Grid>
      </Grid>
    </div>
  }
}

export default NodeSettings;
