import { connect } from "react-redux";
import React from 'react';
import { Card, Typography, Grid, Button, TextField } from "@material-ui/core";
import {Link} from "react-router-dom";
import { defaultCardElevation, defaultCardStyles} from "./App";
import { ArrowDropUp, ArrowDropDown, Search, ZoomIn } from "@material-ui/icons";
import throttle from "lodash/throttle";
import { SystemEvent } from "./generated/types/payloadTypes";
import EventDetailsModal from "./components/EventDetailsModal";
import ListEvents from "./ListEvents";

interface IState {
    search: string,
    orderColumn: string,
    limit: number,
    offset: number,
    orderDirection: string,
    detailedEvent?: SystemEvent,
    events?: {[key:string]:Event},
}

interface IProps {
    dispatch: any,
    style? : any,
    reportData?: {[key:string]:any[]};
    fetchedEvents?: {[key:string]:Event},
    width: string,
    receivingEvents?: boolean,
}

const LIST_EVENTS_TABLE = "LIST_EVENTS_TABLE";
const COUNT_EVENTS_TABLE = "COUNT_EVENTS_TABLE";

class PageEvents extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    searchChange: any;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            limit: 10,
            offset: 0,
            orderColumn: "emitted_at",
            orderDirection: "DESC",
            search: "",
        };
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);

        //Only search every so many seconds
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisThis = this;
        this.searchChange = throttle(function(val:string) {
            thisThis.setState({
                ...thisThis.state,
                search:val,
                offset: 0,
            })
        }, 1000, {
            leading: false,
            trailing: true,
        });
    }

    sortDirectionWidget(tableHeader: string) : JSX.Element {
        if(this.state.orderColumn === tableHeader) {
            if(this.state.orderDirection === "ASC") {
                return <ArrowDropUp />
            } else {
                return <ArrowDropDown />
            }
        }

        return null;
    }


    sortBy(event:any, tableHeader: string) {
        event.stopPropagation();
        event.preventDefault();

        let orderDirection = "ASC";

        //Toggle direction if clicking again
        if(this.state.orderColumn == tableHeader) {
            if(this.state.orderDirection === "ASC") {
                orderDirection = "DESC";
            } else {
                orderDirection = "ASC";
            }
        }

        this.setState({
            ...this.state,
            orderColumn: tableHeader,
            orderDirection,
        })
    }

    pageText() :string {
        if(this.props.reportData[LIST_EVENTS_TABLE] && this.props.reportData[COUNT_EVENTS_TABLE]
            && this.props.reportData[COUNT_EVENTS_TABLE][0] && this.props.reportData[COUNT_EVENTS_TABLE][0]['count']) {
            const userCount = parseInt(this.props.reportData[COUNT_EVENTS_TABLE][0]['count']);
            const totalPages  = Math.ceil(userCount / this.state.limit);
            const currentPage = 1 + Math.floor(this.state.offset / this.state.limit);
            return currentPage + " of " + totalPages;
        }

        return "1 of X"
    }

    headerWidget(headerName: string, displayName: string) : JSX.Element {
        return <Link to={""} onClick={(event) => this.sortBy(event, headerName)}>
            <Grid container wrap={"nowrap"} alignContent={"stretch"} alignItems={"center"}>
                <Grid item>
                    {displayName}
                </Grid>
                <Grid item style={{height:"20px"}}>
                    {this.sortDirectionWidget(headerName)}
                </Grid>
            </Grid>
        </Link>
    }

    withTens(num: number): string {
        if(num < 10) {
            return "0" + num.toString();
        }

        return num.toString();
    }

    whereClause():string {
        if(!this.state.search) {
            return "";
        }
        const search = this.state.search.replace("'", "\\'").trim();

        //ID
        const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if(search.match(regex)) {
            return " WHERE id='" + search + "' OR user_id='" + search + "'";
        }

        //Emitted-at date
        const dateOnlyChars = /^[0-9/\- ]+$/i;
        if(search.length > 6 && search.match(dateOnlyChars)) { //Prevents success+00 etc from being interpreted as date
            const searchDate = new Date(search);
            if (!isNaN(searchDate.getFullYear())) {
                const dateString = searchDate.getUTCFullYear() + "-" + this.withTens(searchDate.getUTCMonth() + 1) + "-" + this.withTens(searchDate.getDate());
                return " WHERE emitted_at >= '" + dateString + "' AND emitted_at < '" + dateString + "'::timestamptz + INTERVAL '1 DAY'"
            }
        }

        //Name/email
        return " WHERE (email ILIKE '%" +search + "%' OR  name ILIKE '%" + search + "%')";
    }

    orderClause():string {
        return " ORDER BY " + this.state.orderColumn + " " + this.state.orderDirection;
    }

    pagingClause():string {
        let pagingClause = "";

        if(this.state.limit) {
            pagingClause += " LIMIT " + this.state.limit + " ";
        }
        if(this.state.offset) {
            if(this.state.offset < 0) {
                this.state.offset = 0;
            }
            pagingClause += " OFFSET " + this.state.offset + " ";
        }

        return pagingClause;
    }

    static getDerivedStateFromProps(nextProps:IProps, prevState:IState) :IState {
        if(nextProps.fetchedEvents) {
            return {
                ...prevState,
                events: nextProps.fetchedEvents,
            }
        }

        return null;
    }

    prevPage() {
        let offset = this.state.offset;
        offset -= this.state.limit;
        if(offset < 0) {
            offset = 0;
        }

        this.setState({
            ...this.state,
            offset,
        })
    }

    nextPage() {
        let numUsers = 0;
        if(this.props.reportData[COUNT_EVENTS_TABLE] && this.props.reportData[COUNT_EVENTS_TABLE][0]
            && this.props.reportData[COUNT_EVENTS_TABLE][0]['count']) {
            numUsers = parseInt(this.props.reportData[COUNT_EVENTS_TABLE][0]['count']);
        }
        let offset = this.state.offset;
        offset += this.state.limit;
        if(offset > numUsers) {
            offset = numUsers - (numUsers % this.state.limit);
        } else if(offset === numUsers) {
            offset = numUsers - this.state.limit;
        }

        this.setState({
            ...this.state,
            offset,
        })
    }

    truncateText(text: string, len = 45) : string {
        
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }


    render() {
        return  <>
            <ListEvents dispatch={this.props.dispatch} type="list" title={"Events"} width={this.props.width}
                buttons={{"zoom":true}} includeEvents={true} includeSystemEvents={true} />
            {/* <ListEvents dispatch={this.props.dispatch} type="list" title={"Your API Events"} width={this.props.width}
                buttons={{"zoom":true}} includeEvents={true} includeSystemEvents={false} /> */}
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    let fetchedEvents = null;
    if(state.events && Object.keys(state.events).length > 0) {
        fetchedEvents = state.events;
    }

    return { ...state, fetchedEvents };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageEvents)



