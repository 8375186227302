import {
    AppBar,
    Grid,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@material-ui/core";
import {connect} from "react-redux";
import {Menu as MenuIcon} from "@material-ui/icons"
import React from "react";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {NavDef, routes} from "./RoutesV2";
import moment from "moment-timezone";
import { JobLogEntry } from "../generated/types/payloadTypes";

export const topNavMinHeight = "50px"

interface IState {
    open: boolean,
    anchorEl: any,
}
interface IProps {
    dispatch?: any,
    notifications?: JobLogEntry[],
}

class TopNavV2 extends React.Component<IProps, IState> {
    state: IState
    props: IProps

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            open: false,
            anchorEl: null,
        };
        this.toggleOpen = this.toggleOpen.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
    }

    toggleOpen(event: React.MouseEvent<HTMLElement>) {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget,
        })
    }

    closeMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({
            open: false,
            anchorEl: null,
        })
    }

    renderRoute(routeDef: NavDef): JSX.Element {
        const paddingLeft = (5 + 10 * (routeDef.depth || 0)).toString() + "px"
        const hasChildren = routeDef.children && routeDef.children.length > 0
        return <span key={"div" + routeDef.href}>
        <Link to={routeDef.href} key={"link" + routeDef.href} style={{ textDecoration: 'none', color:"black" }}>
            <MenuItem
                style={{
                    paddingLeft,
                    verticalAlign:"middle",
                    alignContent:"center",
                    alignItems:"center",
                }}
                key={"item"+ routeDef.href}
                onClick={(e) => {
                    if(routeDef.onClick) {
                        routeDef.onClick(e)
                    }
                    this.closeMenu(e)
                }}>
               
                &nbsp;&nbsp;
                <Typography variant="body1" component="span">
                    {!hasChildren ? <><ul><li>
                        {routeDef.text}
                        {routeDef.isNotifications && this.props.notifications ? <span style={{color:"red", marginLeft:"5px"}}>
                            {getNotificationsText(this.props.notifications)}
                        </span> : null}
                    </li></ul></> : <><Grid container>
                        <Grid item style={{width:"30px"}}>
                        {routeDef.icon}
                        </Grid>
                        <Grid>
                        {routeDef.text}
                        {routeDef.isNotifications && this.props.notifications ? <span style={{color:"red", marginLeft:"5px"}}>
                            {getNotificationsText(this.props.notifications)}
                        </span> : <></>}
                        </Grid>
                    </Grid></>}

                </Typography>
            </MenuItem>
        </Link>
        {(routeDef.children && routeDef.children.length > 0) && routeDef.children.map(c => this.renderRoute(c))}
        </span>
    }

    render() {
        return <AppBar position="fixed" style={{minHeight:topNavMinHeight}} className={"hideForPrint"}>
            <Toolbar  style={{minHeight:topNavMinHeight}}>
                <Button variant={"contained"}
                        style={{border:"1px solid #3c3fa8"}}
                        onClick={this.toggleOpen}
                        color={"primary"}
                        startIcon={<MenuIcon />}>
                    Navigation
                </Button>
                <Menu open={this.state.open} onClick={this.closeMenu} anchorEl={this.state.anchorEl} style={{overflow:"auto", marginTop:"30px"}} PaperProps={{  
                    style: {  
                        paddingTop:"10px",
                        paddingBottom:"10px",
                        minWidth: 350,  
                    },  
                }}>
                    {routes.map(routeDef => {
                        return this.renderRoute(routeDef)
                    })}
                </Menu>
            </Toolbar>
        </AppBar>
    }
}

export function getNotificationsText(notifications: JobLogEntry[]) : string {
    if(!notifications || notifications.length === 0) {
        return ""
    }

    let numErrors = 0;
    const now = moment();
    notifications.map(notification => {
        if(!notification.ended_at) {
            return;
        }

        const endedAt = moment(notification.ended_at, "YYYY/MM/DDTHH:mm:ssZ");
        if(now.diff(endedAt, 'seconds') < 60 * 30) {
            if(!notification.success) {
                numErrors++
            }
        }
    })

    if(!numErrors) {
        return ""
    }

    return numErrors.toString()
}


function mapStateToProps(state:any, ownProps:IProps):any {
    return {...state}
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(TopNavV2);