import React, {ChangeEvent} from 'react';
import {fetchEvents,fetchNumEvents} from './actions/Events'
import { connect } from "react-redux";
import {Grid, Typography, Card, TextField, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ArrowDropDown, ArrowDropUp, Search, ZoomIn, Visibility} from "@material-ui/icons";
import {Event} from "./generated/types/payloadTypes";
import {defaultCardElevation,defaultCardStyles} from "./App";
import throttle from "lodash/debounce";
import PagingToolbarFromProps from "./components/PagingToolbarFromProps";
import { Loading } from './components/Loading';
import EventDetailsModal from './components/EventDetailsModal';
import { encode, decode } from 'js-base64';
import moment from 'moment';

interface IState {
    anchorEl?: any,
    search: string,
    searchType: string,
    orderColumn: string,
    orderDirection: string,
    limit: number,
    offset: number,
    detailedEvent?: Event,
}

interface IProps {
    dispatch: any,
    events?: {[key:string]:Event},
    receivingEvents?: boolean,
    receivingEventsInSegments?: boolean,
    receivingEventsNotInSegments?: boolean,
    type:string,
    buttons: {[key:string]: boolean},
    style?: any;
    title?: string;
    usersListKey?: string;
    usersCountListKey?: string;
    width: string,
    receivingNumEvents?: boolean,
    numEvents?: number,
    hideSearch?: boolean,
    searchType?: string,
    search?: string,
    includeEvents?: boolean,
    includeSystemEvents?: boolean,
}


class ListEvents extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    searchChange: any;

    constructor(props:IProps) {
        super(props);
        this.state = {
            orderColumn: "emitted_at",
            orderDirection: "DESC",
            search: props.search?props.search:"",
            searchType: props.searchType?props.searchType: "email",
            limit: 10,
            offset:0,
        };
        this.searchTypeChange = this.searchTypeChange.bind(this);
        this.pagePrev = this.pagePrev.bind(this);
        this.pageNext = this.pageNext.bind(this);

        //Only search every so many seconds
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisThis = this;
        this.searchChange = throttle(function(val:string) {
            const newState = {
                ...thisThis.state,
                search:val,
                offset: 0,
            }
            thisThis.setState(newState)

            props.dispatch(fetchEvents(null, 
                newState.limit, newState.offset, 
                newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
                newState.searchType, newState.search, !!props.includeEvents, !!props.includeSystemEvents))
            props.dispatch(fetchNumEvents(null, newState.searchType, newState.search, !!props.includeEvents, !!props.includeSystemEvents))
            

        }, 1000, {
            leading: false,
            trailing: true,
        });
    }

    componentDidMount(){
        this.props.dispatch(fetchEvents(null, 
            this.state.limit, this.state.offset, 
            this.state.orderColumn != ""?[this.state.orderColumn + " " + this.state.orderDirection]:[],
            this.state.searchType, this.state.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
        this.props.dispatch(fetchNumEvents(null, this.state.searchType, this.state.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
    }

    pagePrev(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset -= newState.limit 
        if(newState.offset <= 0) {
            if(this.state.offset === 0) {
                return
            }
            newState.offset = 0
        }

        this.setState(newState)

        this.props.dispatch(fetchEvents(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
            newState.searchType, newState.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
    }

    pageNext(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset += newState.limit 

        if(newState.offset > this.props.numEvents) {
            return
        }

        this.setState(newState)
       
        this.props.dispatch(fetchEvents(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
            newState.searchType, newState.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
    }

    searchTypeChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        event.stopPropagation()
        event.preventDefault()

        if(!this.state.search) {
            this.setState( {
                ...this.state,
                searchType: event.target.value.toString(),
            })
            return
        }

        const newState = {
            ...this.state,
            searchType: event.target.value.toString(),
            offset: 0,
        }
        this.setState(newState)

        
        this.props.dispatch(fetchEvents(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
            newState.searchType, newState.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
        this.props.dispatch(fetchNumEvents(null, newState.searchType, newState.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
    }

    sortBy(event:any, tableHeader: string) {
        event.stopPropagation();
        event.preventDefault();

        let orderDirection = "ASC";

        //Toggle direction if clicking again
        if(this.state.orderColumn == tableHeader) {
            if(this.state.orderDirection === "ASC") {
                orderDirection = "DESC";
            } else {
                orderDirection = "ASC";
            }
        }

        const newState = {
            ...this.state,
            orderColumn: tableHeader,
            orderDirection,
        }
        this.setState(newState)

        this.props.dispatch(fetchEvents(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[],
            newState.searchType, newState.search, !!this.props.includeEvents, !!this.props.includeSystemEvents))
    }

    sortDirectionWidget(tableHeader: string) : JSX.Element {
        if(this.state.orderColumn === tableHeader) {
            if(this.state.orderDirection === "ASC") {
                return <ArrowDropUp />
            } else {
                return <ArrowDropDown />
            }
        }

        return null;
    }

    headerWidget(headerName: string, displayName: string) : JSX.Element {
        return <Link to={""} onClick={(event) => this.sortBy(event, headerName)}>
            <Grid container wrap={"nowrap"} alignContent={"stretch"} alignItems={"center"}>
                <Grid item>
                    {displayName}
                </Grid>
                <Grid item style={{height:"20px"}}>
                    {this.sortDirectionWidget(headerName)}
                </Grid>
            </Grid>
        </Link>
    }

    getButtons(e:Event): JSX.Element {
        const zoomButton = <Link key="zoomButton" to={"#" +e.id?.toString()} className="svgShadow" onClick={(event)=>{
            this.setState({
                ...this.state,
                detailedEvent: e,
            })
        }}><ZoomIn /></Link>

        const s3Location = e.json?.bucket + "/" + e.json?.key 
        const link = "/email/" + encode(s3Location)
        let disabled = false
        if(!e.json?.bucket || !e.json?.key ) {
            disabled=true
        }
        const viewEmailButton = <Link key="viewButton" to={link} target="_blank" className="svgShadow"><Visibility /></Link>
        
        const buttons = []
        if(this.props.buttons["zoom"]) {
            buttons.push(zoomButton)
        }
        if(!disabled && this.props.buttons["viewemail"]) {
            buttons.push(viewEmailButton)
        }
        
        return <div style={{width:"100%"}}>
            {buttons.map(c => c)}
        </div>
    }

    
    dateField(dateField: moment.Moment | string): string {
        if(!dateField) {
            return ""
        }
        if(typeof dateField == "string") {
            dateField = moment(dateField)
        }

        const tz = moment.tz.guess()
        let myDate:Date = dateField.toDate()
        if(tz) {
            myDate = moment.tz(dateField, tz).toDate()
        }
        
        return myDate.toLocaleDateString() + " " + myDate.toLocaleTimeString()
    }

    mainTable(): JSX.Element {
        return this.props.receivingEvents && (!this.props.events || Object.keys(this.props.events).length === 0) ? 
                <div style={{minHeight:"400px", paddingTop:"2rem"}}><Loading /></div> : 
            <div style={{position:"relative"}}>
            {this.props.receivingEvents && <Loading overlay={true} mySize='lg' text={<Typography variant="h1">Loading...</Typography>} />}
            <TableContainer>
                <Table cellSpacing={0}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{this.headerWidget("name", "Name")}</TableCell>
                            <TableCell>{this.headerWidget("email", "Email")}</TableCell>
                            <TableCell>{this.headerWidget("emitted_at", "Occurred")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(this.props.events).map((e,i) => <TableRow key={"row_" + i}>
                            <TableCell style={{width:"25px", whiteSpace:"nowrap"}}>
                                {this.getButtons(e)}
                            </TableCell>
                            <TableCell style={{maxWidth:"150px", overflow:"hidden", textOverflow:"ellipsis"}}>
                                <Typography component="span" variant="body1" title={e.name}>
                                    {e.name}
                                </Typography>
                            </TableCell>
                            <TableCell style={{maxWidth:"200px", overflow:"hidden", textOverflow:"ellipsis"}}>
                                <Link target="_blank" to={"/people/users/edit/"+e.user_id}>
                                <Typography component="span" variant="body1" title={e.email}>
                                    {e.email}
                                </Typography>
                                </Link>
                            </TableCell>
                            <TableCell style={{maxWidth:"150px", overflow:"hidden", textOverflow:"ellipsis"}}>
                                <Typography component="span" variant="body1" title={this.dateField(e.emitted_at)}>
                                {this.dateField(e.emitted_at)}
                                </Typography>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
    }

    render() {
        return <>
            { (this.props.type =="email-list" || this.props.type == 'list') &&
                <Card style={{...defaultCardStyles, ...this.props.style}} elevation={defaultCardElevation}>
                    <Grid key="c1" container>
                        <Grid item>
                            <Typography variant={"h1"} component={"div"}>{this.props.title || "Events"}</Typography>
                        </Grid>
                    </Grid>

                    <hr />

                    <Grid key="c2" container>
                        <Grid item style={{marginRight:"10px", marginBottom:"5px"}}>
                            <PagingToolbarFromProps 
                                offset={this.state.offset} 
                                limit={this.state.limit}  
                                totalItems={this.props.numEvents}
                                isLoading={this.props.receivingNumEvents}   
                                onNextPage={this.pageNext}
                                onPrevPage={this.pagePrev}
                                />
                        </Grid>
                        {!this.props.hideSearch && <Grid item>
                            <TextField 
                               style={{width:"40%", minWidth:"300px"}}
                               onChange={e => this.searchChange(e.target.value.toString())}
                               placeholder={this.state.searchType=="emitted_at"?"YYYY-MM-DD":"search"}
                               InputProps={{
                                   startAdornment: <>
                                       <Select
                                           onChange={this.searchTypeChange}
                                           style={{backgroundColor:"#eee",borderTopLeftRadius:"5px"}}
                                           value={this.state.searchType}>
                                           <MenuItem value={"email"}>&nbsp;Email</MenuItem>
                                           <MenuItem value={"name"}>&nbsp;Event Name</MenuItem>
                                           <MenuItem value={"emitted_at"}>&nbsp;Occurred</MenuItem>
                                       </Select>
                                       <Search  />
                                   </>
                               }}/>
                        </Grid>}
                    </Grid>

                    {!!this.state.detailedEvent && <EventDetailsModal 
                        key="edm8838"
                        dispatch={this.props.dispatch} 
                        detailedEvent={this.state.detailedEvent}  
                        okButtonHook={(event:any) => {
                            this.setState({
                                ...this.state,
                                detailedEvent: null,
                            })
                        }}
                    />  }

                    {this.mainTable()}
                </Card>
            }
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListEvents)

