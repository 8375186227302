import {v4 as uuidv4} from "uuid";
import { Node, SenderPersona } from '../../generated/types/payloadTypes';
import { INodeSettings, usersLocalTimezone } from "../../workflow/NodeSettings";
import { showToast } from "../../ToastNotifications";
import moment from "moment";
import { IChart, IPort, ILink, INode } from '@mrblenny/react-flow-chart/src/types/chart';
import { NewNode } from "../../workflow/Toolbar";
import { defaultWorkflow } from "../../NewWorkflow";


export function GetBasicWorkflowFields(eventName: string, senderPersonaId:string, templateId: string):{chart:IChart, rootNode:Node} {
    //Ids
    const numNodes = 2 
    const nodeIds = []
    for(let i = 0; i < numNodes; i++) {
        nodeIds.push(uuidv4())
    }
    const numPorts = 10
    const portIds = []
    for(let i = 0; i < numPorts; i++) {
        portIds.push(uuidv4())
    }
    const linkId = uuidv4()

    // Nodes
    const childNode = new Node()
    childNode.id = nodeIds[1]
    childNode.type = "SENDER"
    childNode.settings = {
        "delay_ms": 0,
        "sender_persona_id": senderPersonaId,
        "template_id": templateId,
        "send_at_close_enough_ms": 0,
        "delay_until": null,
        "delay_until_users_timezone": false,
        "delay_until_any_date": false,
        "delay_until_exclusive": false
      }

    const rootNode = new Node()
    rootNode.id = nodeIds[0]
    rootNode.type = "ROOT"
    rootNode.event_topic = "event"
    rootNode.event_type = eventName
    rootNode.children = [childNode]

    return { 
        rootNode,
        chart: {
        "offset": {
          "x": 0,
          "y": 0
        },
        "nodes": {
          [nodeIds[0]]: {
            "id": nodeIds[0],
            "ports": {
              [portIds[0]]: {
                "id": portIds[0],
                "position": {
                  "x": 23,
                  "y": 95
                },
                "type": "bottom"
              },
              [portIds[1]]: {
                "id": portIds[1],
                "position": {
                  "x": 49,
                  "y": 95
                },
                "type": "bottom"
              },
              [portIds[2]]: {
                "id": portIds[2],
                "position": {
                  "x": 75,
                  "y": 95
                },
                "type": "bottom"
              },
              [portIds[3]]: {
                "id": portIds[3],
                "position": {
                  "x": 101,
                  "y": 95
                },
                "type": "bottom"
              },
              [portIds[4]]: {
                "id": portIds[4],
                "position": {
                  "x": 127,
                  "y": 95
                },
                "type": "bottom"
              }
            },
            "position": {
              "x": 12.69499276858852,
              "y": 10.134046493043087
            },
            "properties": {
              "eventName": eventName
            },
            "size": {
              "width": 151,
              "height": 93
            },
            "type": "event"
          },
          [nodeIds[1]]: {
            "id": nodeIds[1],
            "ports": {
              [portIds[5]]: {
                "id": portIds[5],
                "position": {
                  "x": 23,
                  "y": -3
                },
                "type": "top"
              },
              [portIds[6]]: {
                "id": portIds[6],
                "position": {
                  "x": 49,
                  "y": -3
                },
                "type": "top"
              },
              [portIds[7]]: {
                "id": portIds[7],
                "position": {
                  "x": 75,
                  "y": -3
                },
                "type": "top"
              },
              [portIds[8]]: {
                "id": portIds[8],
                "position": {
                  "x": 101,
                  "y": -3
                },
                "type": "top"
              },
              [portIds[9]]: {
                "id": portIds[9],
                "position": {
                  "x": 127,
                  "y": -3
                },
                "type": "top"
              }
            },
            "position": {
              "x": 13.290053662269571,
              "y": 160.847989188417
            },
            "properties": {
              "senderPersonaId": senderPersonaId,
              "templateId": templateId
            },
            "size": {
              "width": 151,
              "height": 93
            },
            "type": "sender"
          }
        },
        "links": {
          [linkId]: {
            "id": linkId,
            "from": {
              "nodeId": nodeIds[0],
              "portId": portIds[0]
            },
            "to": {
              "nodeId": nodeIds[1],
              "portId": portIds[5]
            }
          }
        }
      } as unknown as  IChart, 
    }
}

export function GenerateWorkflow(
  rootNodeId: string,
  eventName: string,
  selectedSendAtTypes:("time_of_day_specific_timezone"|"time_of_day_users_timezone"|"users_timezone"|"specific_timezone"|"delay")[],
  selectedSendAtTimezones: string[],
  selectedSendAtDelaySeconds:number[],
  selectedSendAtMoments: moment.Moment[],
  selectedSenderPersonas: SenderPersona[],
  selectedTemplateIds: string[],
):IChart {
  const workflow:IChart = JSON.parse(JSON.stringify(defaultWorkflow))

  // Root node
  const rootNode:INode = NewNode("event")
  rootNode.id = rootNodeId
  rootNode.position = {x:10, y:10}
  const properties:INodeSettings = {
    eventName,
  } 
  rootNode.properties = properties
  let nodeCursor = rootNode

  for(let i = 0; i < selectedSendAtTypes.length; i++) {
    const sendAtType = selectedSendAtTypes[i].toLowerCase()
    let delayNode:INode = null
    if(sendAtType === "delay" ) {
      delayNode = NewNode("delay")
      delayNode.properties = {
        delayMS: Number.isSafeInteger(selectedSendAtDelaySeconds[i]) ? selectedSendAtDelaySeconds[i] * 1000 : 0,
      }

      if(! Number.isSafeInteger(selectedSendAtDelaySeconds[i]) ) {
        const errorMessage = "missing delay for node " + (delayNode?delayNode.id:"(no id)") + ".  Using 0 delay."
        showToast(errorMessage, "error")
      }
    } else {
      delayNode = NewNode("delay_until")
      //Determine timezone
      const isUsersTimezone = sendAtType === "time_of_day_users_timezone" || sendAtType === "users_timezone" 
      const delayUntilAnyDate = sendAtType === "time_of_day_specific_timezone" || sendAtType === "time_of_day_users_timezone"
      let delayUntilTimezone = isUsersTimezone? usersLocalTimezone : selectedSendAtTimezones[i]
      if(!delayUntilTimezone) {
        const errorMessage = "missing timezone for node " +(delayNode?delayNode.id:"(no id)") + ".  Using UTC timezone."
        showToast(errorMessage, "error")
        delayUntilTimezone = "UTC"
      }

      //Determine when to send at
      let sendAtMoment = selectedSendAtMoments[i]
      if(!sendAtMoment) {
        sendAtMoment = moment()
        const errorMessage = "missing delay-until time for node " + (delayNode?delayNode.id:"(no id)") + ".  Using " + sendAtMoment.toString() 
        showToast(errorMessage, "error")
      }

      delayNode.properties =  {
        delayUntil: sendAtMoment,
        delayUntilExclusive: false, // website text says wait until "on or after date"
        delayUntilAnyDate,
        delayUntilTimezone,
      }
    }
    delayNode.position = { x: rootNode.position.x, y: rootNode.position.y + rootNode.size.height + 40 + 40 * i + delayNode.size.height * i }

    //Sender node
    const sendNode:INode = NewNode("sender")
    sendNode.position = { x: delayNode.position.x + delayNode.size.width + 40, y: delayNode.position.y + delayNode.size.height + 40 }
    sendNode.properties = {
       senderPersonaId: selectedSenderPersonas[i].id,
       templateId: selectedTemplateIds[i],
    }
    

    //Link delay node to sender node
    const firstDelayBottomPort = Object.values(delayNode.ports).find(p => p.type === "bottom")
    const firstSenderTopPort = Object.values(sendNode.ports).find(p => p.type === "top")
    const delayToSenderLink:ILink = {
      id: uuidv4(),
      from: { nodeId: delayNode.id, portId: firstDelayBottomPort.id},
      to:   { nodeId: sendNode.id,  portId: firstSenderTopPort.id},
    }
    workflow.links[delayToSenderLink.id] = delayToSenderLink

    
    //Link node cursor node to delay node
    const firstCursorBottomPort = Object.values(nodeCursor.ports).find(p => p.type === "bottom")
    const firstDelayTopPort = Object.values(delayNode.ports).find(p => p.type === "top")
    const cursorToDelayLink:ILink = {
      id: uuidv4(),
      from: { nodeId: nodeCursor.id, portId: firstCursorBottomPort.id},
      to:   { nodeId: delayNode.id,  portId: firstDelayTopPort.id},
    }
    workflow.links[cursorToDelayLink.id] = cursorToDelayLink

    workflow.nodes[delayNode.id] = delayNode
    workflow.nodes[sendNode.id] = sendNode
    nodeCursor = delayNode
  }

  workflow.nodes[rootNode.id] = rootNode

  return workflow
}
