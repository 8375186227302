import React from 'react';
import {connect} from "react-redux";
import {
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {CheckCircle, Close, HourglassEmpty} from "@material-ui/icons";
import {RouteComponentProps, withRouter} from "react-router-dom";
import { Loading } from './Loading';

export interface FileUpload {
    filename: string,
    progress: number, //upload progress
    totalBytes: number,
    errors: string[],
    processingErrors:string[]
}

interface IState {
}

interface IProps extends RouteComponentProps<{ id?: string  }> {
    receivingBulkUploadProgress?: {[key:string]:boolean},
    bulkUploadProgress?: {[key:string]:{numInProgress:number, numTotal:number,s3Key:string, errors:string[], processingErrors:string[]}},
    fileUploads: {[key:string]: FileUpload},
}

class BulkUploadProgress extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;
    moreDetailsRef: any;

    constructor(props:IProps) {
        super(props);
        this.props = props;

        this.state = {
        };
    }

    getUploadProgress(percentDone:number, errors:string[]): JSX.Element {
        const haveErrors = (errors && errors.length > 0 && !(errors.length === 1 && errors[0] == ""))
        if(haveErrors) {
            return <p style={{color: "red"}}><Close />{errors.join(", ")}</p>
        }

        if(percentDone != 1) {
            return <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Loading mySize={"xs"} hideText={true} />
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} style={{paddingTop:"10px"}}>
                    <LinearProgress variant="determinate" value={percentDone*100} />
                </Grid>
            </Grid>
        }

        return <CheckCircle color={"primary"} />
    }

    getParsingProgress(isStarted:boolean, percentDone:number, errors:string[]): JSX.Element {
        if(!isStarted) {
            return <HourglassEmpty fontSize='small' color="primary" />
        }

        const haveErrors = (errors && errors.length > 0 && !(errors.length === 1 && errors[0] == ""))
        if(haveErrors) {
            return <p style={{color: "red"}}><Close />{errors.join(", ")}</p>
        }
        
        if(percentDone !== 1) {
            return <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Loading  mySize={"xs"} hideText={true} />
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} style={{paddingTop:"10px"}}>
                    <LinearProgress variant="determinate" value={percentDone*100} />
                </Grid>
            </Grid>
        } 
        
        return <CheckCircle color={"primary"} />
    }

    render() {
        return <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>File</TableCell>
                        <TableCell style={{width:"150px"}}>Uploading</TableCell>
                        <TableCell style={{width:"150px", paddingRight:"1rem"}}>Processing</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(!this.props.fileUploads || Object.keys(this.props.fileUploads).length === 0) && (!this.props.bulkUploadProgress || Object.values(this.props.bulkUploadProgress).length === 0) &&
                        <TableRow>
                            <TableCell colSpan={3}>Nothing here, yet.</TableCell>
                        </TableRow>
                    }
                    {this.props.fileUploads && Object.values(this.props.fileUploads).map(upload => <TableRow key={"upload" + upload.filename}>
                            <TableCell>{upload.filename}</TableCell>
                            <TableCell style={{width:"150px"}}>
                                {this.getUploadProgress(upload.progress, upload.errors)}
                            </TableCell>
                            <TableCell  style={{width:"150px", paddingRight:"1rem"}}>
                                {this.props.bulkUploadProgress[upload.filename] ? 
                                    this.getParsingProgress(true, 
                                        this.props.bulkUploadProgress[upload.filename].numTotal ?
                                        (this.props.bulkUploadProgress[upload.filename].numTotal 
                                        - this.props.bulkUploadProgress[upload.filename].numInProgress)
                                        / this.props.bulkUploadProgress[upload.filename].numTotal : 0, 
                                        this.props.bulkUploadProgress[upload.filename].processingErrors) : 
                                    this.getParsingProgress(false, 0, [])}
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    }
}


function mapStateToProps(state:any, ownProps:IProps):any {
    return {...state}
}

export default withRouter(connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(BulkUploadProgress))