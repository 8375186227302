import React, { ChangeEvent } from 'react';
import { connect } from "react-redux";
import {
    Switch, Grid, Typography, Card, FormControl, InputLabel, FormHelperText, Select, MenuItem, TableCell, Table, TableContainer, TableHead, TableRow, TableBody, TextField,
    FormGroup,
    FormControlLabel
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Add, ArrowDropDown, ArrowDropUp, Check, Delete, Edit, HourglassEmpty, Error, Assessment, BarChart} from "@material-ui/icons";
import {Campaign, CampaignWithMetrics, JobLogEntry} from "./generated/types/payloadTypes";
import {MyCancelButton, MyModal, MyOkButton} from "./Dialog";
import {defaultCardElevation,defaultCardStyles, myTheme} from "./App";
import {REQUEST_CAMPAIGNS, archiveCampaign, fetchCampaigns, fetchCampaignsWithMetrics, fetchNumCampaignsWithMetrics } from "./actions/Campaigns";
import Fab from "@material-ui/core/Fab";
import { Loading } from './components/Loading';
import {v4 as uuidv4} from "uuid";
import PagingToolbarFromProps from './components/PagingToolbarFromProps';
import { archiveEmailBlasts } from './actions/EmailBlasts';
import { archiveWorkflows } from './actions/Workflows';
import { fetchNotifications } from './actions/Notifications';
import { showToast } from './ToastNotifications';

let workflowCheckerIntervalId:NodeJS.Timeout = null
let numChecks = 0
const maxChecks = 100

interface IState {
    id: string,
    upForDeletionCampaign: Campaign,
    limit: number,
    offset: number,
    orderColumn: string,
    orderDirection: string,
    splitTemplates: boolean,
    selectedCampaignId?: string,
    selectedCampaignIds?: string[], //For multi-select
    executingArchive: boolean,
    confirmingArchive: boolean,
    successfulArchive: boolean,
    archiveSteps: ArchiveStep[],
}

interface IProps {
    dispatch: any,
    campaigns?: {[key:string]:Campaign},
    campaignsWithMetrics?: CampaignWithMetrics[],
    receivingCampaignsWithMetrics?: boolean,
    receivingNumCampaignsWithMetrics?: boolean,
    numCampaignsWithMetrics?: number,
    type:string,
    receivingCampaigns?: boolean,
    reportErrors?: {[key:string]:string},
    style?: any,
    reportData?: {[key:string]:any[]},
    showDates?: boolean,
    campaignsListKey?: string,
    required?: boolean,
    campaignsCountListKey?:string,
    label?: string,
    placeholder?: string,
    placeholderEnabled?: boolean,
    onChange?: (event:ChangeEvent<{name?:string, value?:unknown}>) => any,
    onSelectedCampaignIdsChange? : (ids:string[]) => any,
    selectStartAdornment?: React.ReactNode;
    selectedCampaignId?: string,
    hideSearch?: boolean,
    pagingButtonVariant?: "text" | "outlined" | "contained",
    notifications?: {[key:string]:JobLogEntry}
}

interface ArchiveStep {
    name: string,
    status: "waiting"|"in_progress"|"succeeded"|"failed"|"cancelled",
    error?: string,
    results?: any,
    execute: () => any,
}


class ListCampaigns extends React.Component<IProps, IState> {
    state: IState;
    props: IProps;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4(),
            executingArchive: false,
            confirmingArchive: false,
            successfulArchive: false,
            archiveSteps: [],
            upForDeletionCampaign:null,
            limit: 5,
            offset: 0,
            orderColumn: "created_at",
            orderDirection: "DESC",
            splitTemplates: false,
            selectedCampaignId: this.props.selectedCampaignId? this.props.selectedCampaignId: 
                this.props.placeholder?"placeholder": "",
        };

        this.archiveCampaign = this.archiveCampaign.bind(this)
        this.pagePrev = this.pagePrev.bind(this)
        this.pageNext = this.pageNext.bind(this)
        this.campaignChange = this.campaignChange.bind(this)
    }

    componentDidMount() {
        if(this.props.type == "list") {
            this.fetchCampaignsWithMetricsViaStateVars(this.state.splitTemplates)
            this.fetchNumCampaignsWithMetricsViaStateVars(this.state.splitTemplates)
        } else {
            this.props.dispatch(fetchCampaigns())
        }
    }

    fetchCampaignsWithMetricsViaStateVars(splitTemplates: boolean) {
        this.props.dispatch(fetchCampaignsWithMetrics(null, this.state.limit, this.state.offset, 
            this.state.orderColumn != ""?[this.state.orderColumn + " " + this.state.orderDirection]:[], splitTemplates))
    }

    
    fetchNumCampaignsWithMetricsViaStateVars(splitTemplates: boolean) {
        this.props.dispatch(fetchNumCampaignsWithMetrics(null, splitTemplates))
    }

    archiveCampaign(id: string) {
        this.props.dispatch(archiveCampaign(id));
    }

    getCampaignTypeText(type:string): string {
        //We rename template to a_b_test in the query so that sorting works better
        const typeMap:{[key:string]:string}= {
            "template": "A/B Test",
            "a_b_test": "A/B Test",
            "scheduled_email_group": "Scheduled Email Blast / Newsletter",
            "workflow": "Workflow / Drip",
        }
        if(!typeMap[type]) {
            return ""
        }

        return typeMap[type]
    }

    sortBy(event:any, tableHeader: string) {
        event.stopPropagation();
        event.preventDefault();

        let orderDirection = "ASC";

        //Toggle direction if clicking again
        if(this.state.orderColumn == tableHeader) {
            if(this.state.orderDirection === "ASC") {
                orderDirection = "DESC";
            } else {
                orderDirection = "ASC";
            }
        }

        const newState = {
            ...this.state,
            orderColumn: tableHeader,
            orderDirection,
        }
        this.setState(newState)

        this.props.dispatch(fetchCampaignsWithMetrics(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[], newState.splitTemplates))
    }

    sortDirectionWidget(tableHeader: string) : JSX.Element {
        if(this.state.orderColumn === tableHeader) {
            if(this.state.orderDirection === "ASC") {
                return <ArrowDropUp />
            } else {
                return <ArrowDropDown />
            }
        }

        return null;
    }

    headerWidget(headerName: string, displayName: string|JSX.Element) : JSX.Element {
        return <Link to={""} onClick={(event) => this.sortBy(event, headerName)}>
            <Grid container wrap={"nowrap"} alignContent={"stretch"} alignItems={"center"}>
                <Grid item>
                    <Typography variant={"body1"} component={"p"}>{displayName}</Typography>
                </Grid>
                <Grid item style={{minHeight:"20px"}}>
                    {this.sortDirectionWidget(headerName)}
                </Grid>
            </Grid>
        </Link>
    }

    truncateText(text: string, len = 60) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    getCampaignSelect(excludeSelected: boolean) : JSX.Element {
        return <Select id={"campaignSelect"+this.state.id} 
            key={"campaignSelect"+this.state.id} 
            style={{width:"100%", minWidth:"120px"}}
            value={this.state.selectedCampaignId} 
            required={!!this.props.required} 
            onChange={this.campaignChange} 
            startAdornment={this.props.selectStartAdornment}
            MenuProps={{ disableScrollLock: true }}>
                { this.props.placeholder && <MenuItem key="selectplaceholder" value="placeholder" disabled={!this.props.placeholderEnabled}>{this.props.placeholder}</MenuItem>}
                { this.selectMenuItems(excludeSelected) }
        </Select>
    }

    safeDateStringFromString(fromString: string): string {
        try {
            if(fromString && new Date(fromString).toLocaleDateString) {
                return new Date(fromString).toLocaleDateString(undefined, { 
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
            }
        } catch(e) {
            //Do nothing
        }
    }
    
    selectMenuItems(excludeSelected: boolean): JSX.Element[]  {
        if(!this.props.campaigns || Object.values(this.props.campaigns).length===0)     {
            return []
        }
        
        const options:JSX.Element[] = []
        Object.values(this.props.campaigns).sort((a:Campaign,b:Campaign):number =>a.name < b.name ? -1 : 1).map(campaign => {
            if(excludeSelected && (
                this.state.selectedCampaignIds.find(id => campaign.id == id)   
            )) {
                return
            }

            options.push(<MenuItem key={campaign.id} value={campaign.id}>
                {this.props.showDates? <Grid container style={{width:"100%", overflow:"hidden", flexWrap:"nowrap"}}>
                    <Grid item style={{width:"calc(100% - 105px)", overflow:"hidden", textOverflow:"ellipsis"}}>
                        {campaign.name}
                    </Grid>
                    <Grid item style={{width:"100px", color:"#bbb", margin:"0", padding:"0", paddingLeft:"5px", textAlign:"right"}}>
                        {this.safeDateStringFromString(campaign.created_at)}
                    </Grid>
                </Grid> : campaign.name}
            </MenuItem>)
        })

        return options
    }

    campaignChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                selectedCampaignId: "",
                selectedCampaignIds: null,
            });
            return;
        }

        if(!this.props.campaigns) {
            return
        }
        
        const selectedCampaignId = event.target.value.toString()
        if(this.props.type == "select") {
            this.setState({
                ...this.state,
                selectedCampaignId,
            });
            if(this.props.onSelectedCampaignIdsChange) {
                this.props.onSelectedCampaignIdsChange([selectedCampaignId])
            }
        } else if(this.props.type == "multiselect") {
            const newIds = [
                ...this.state.selectedCampaignIds,
                selectedCampaignId
            ]
            this.setState({
                ...this.state,
                selectedCampaignIds: newIds,
            });
            
            if(this.props.onSelectedCampaignIdsChange) {
                this.props.onSelectedCampaignIdsChange(newIds)
            }
        } else {
            console.error("unknown campaign component type " + this.props.type)
        }

        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    pagePrev(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset -= newState.limit 
        if(newState.offset <= 0) {
            if(this.state.offset === 0) {
                return
            }
            newState.offset = 0
        }

        this.setState(newState)

        this.props.dispatch(fetchCampaignsWithMetrics(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[], newState.splitTemplates))
    }

    pageNext(event:any) {
        event.stopPropagation();
        event.preventDefault();

        const newState = {...this.state}
        newState.offset += newState.limit 

        let numCampaigns = Object.keys(this.props?.campaigns || {})?.length || 0
        if(this.props.type === 'list') {
            numCampaigns = this.props?.numCampaignsWithMetrics || 0
        }
        if(newState.offset > numCampaigns) {
            return
        }

        this.setState(newState)
        this.props.dispatch(fetchCampaignsWithMetrics(null, newState.limit, newState.offset, 
            newState.orderColumn != ""?[newState.orderColumn + " " + newState.orderDirection]:[], newState.splitTemplates))
    }

    numDisplayCampaigns() : number {
        let numCampaigns = 0
        if(this.props.campaigns) {
            Object.values(this.props.campaigns).map(c => {
                if(c.type === "template") {
                    numCampaigns += c.template_ids?.length || 0
                } else {
                    numCampaigns++
                }
            }) 
        }

        return numCampaigns
    }

    
    startWorkflowDeletedChecker(workflowId:string, onSuccess:() => any, onFailure:(reason:string) => any) {
        if(workflowCheckerIntervalId) {
            console.log("clearing previous workflow checker")
            clearInterval(workflowCheckerIntervalId)
            workflowCheckerIntervalId = null
        }

        console.log("checking for workflow " + workflowId)
        workflowCheckerIntervalId = setInterval(() => {
            if(numChecks > maxChecks) {
                clearInterval(workflowCheckerIntervalId)
                workflowCheckerIntervalId = null
                numChecks = 0
                onFailure("")
                return
            }

            if(this.state.executingArchive) {
                const notificationKeys = Object.keys(this.props.notifications)
                for(let i = 0; i < notificationKeys.length; i++) {
                    if(this.notificationIndicatesFinishedWorkflow(this.props.notifications[notificationKeys[i]], workflowId)) {
                        clearInterval(workflowCheckerIntervalId)
                        workflowCheckerIntervalId = null
                        numChecks = 0
                        onSuccess()
                        return
                    }
                }

                this.props.dispatch(fetchNotifications(100))
            } else {
                clearInterval(workflowCheckerIntervalId)
                workflowCheckerIntervalId = null
                numChecks = 0
                return
            }

            numChecks++
        }, 2000)
    }

    notificationIndicatesFinishedWorkflow(notification: JobLogEntry, workflowId: string): boolean {
        return (
           notification.job_name === "JOB_DELETE_WORKFLOW"
        && notification.ended_at 
        && notification.start_details 
        && notification.start_details?.workflow_id === workflowId)
    }

    getArchiveSteps(campaign: Campaign, onSuccessfulFinish?: () => any, onFailureFinish?: () => any): ArchiveStep[] {
        const steps:ArchiveStep[] = []
        const associatedWorkflowIds = campaign.workflow_ids || []
        const associatedEmailBlastIds = campaign.scheduled_email_blast_group_ids || []
        switch(campaign?.type) {
            case "scheduled_email_group":
            case "template":
                for(let i = 0; i < associatedEmailBlastIds.length; i++) {
                    // Archive the email blast
                    steps.push({
                        execute: () => {
                            steps[i].status = "in_progress"
                            
                            //Archive email blast
                            this.props.dispatch(archiveEmailBlasts([associatedEmailBlastIds[i]],
                                () => {
                                    steps[i].status = "succeeded"
                                    steps[i+1].status = "in_progress"
                                    steps[i+1].execute()
                                }, (reason: string) => {
                                    steps[i].status = "failed"
                                    steps[i].error = reason
                                    if(onFailureFinish) {
                                        onFailureFinish()
                                    }
                                }));
                        },
                        name: 'Disable associated email blast ' + (i + 1).toString(),
                        status: 'waiting',
                    })
                }
                
                //Campaign
                steps.push({
                    execute: () => {
                        steps[associatedEmailBlastIds.length].status = "in_progress"

                        // Archive this campaign
                        this.props.dispatch(archiveCampaign(
                            campaign.id, 
                            () => {
                                steps[associatedEmailBlastIds.length].status = "succeeded"
                                if(onSuccessfulFinish) {
                                    onSuccessfulFinish()
                                }
                            }, (reason: string) => {
                                steps[associatedEmailBlastIds.length].status = "failed"
                                steps[associatedEmailBlastIds.length].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Archive base campaign",
                    status:"waiting",
                })
            break
            case "workflow":
                // Archive the workflow
                for(let i = 0; i < associatedWorkflowIds.length; i++) {
                    steps.push({
                        execute: () => {
                            steps[i].status = "in_progress"
    
                            // Archive the workflow
                            this.props.dispatch(archiveWorkflows(
                                associatedWorkflowIds, 
                                null,
                                () => console.log("successfully archived workflow"),
                                (reason:string) => console.log("ignoring error, probably 503 " + reason)
                            ))
                                    
                            this.startWorkflowDeletedChecker(associatedWorkflowIds[i], 
                            () => {
                                steps[i].status = "succeeded"
                                steps[i+1].status = "in_progress"
                                steps[i+1].execute()
                            }, (reason:string) => {
                                steps[i].status = "failed"
                                steps[i].error = reason || "This workflow took a very long time to be archived. Please check the notifications tab and campaign details to make sure it was created and succesfully associated with the campaign."
                                steps[i+1].status = "cancelled"
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            })
                        },
                        name: 'Archive workflow ' + (i + 1).toString(),
                        status: 'waiting',
                    })
                }
                //Campaign
                steps.push({
                    execute: () => {
                        steps[associatedEmailBlastIds.length].status = "in_progress"

                        // Archive this campaign
                        this.props.dispatch(archiveCampaign(
                            campaign.id, 
                            () => {
                                steps[associatedWorkflowIds.length].status = "succeeded"
                                if(onSuccessfulFinish) {
                                    onSuccessfulFinish()
                                }
                            }, (reason: string) => {
                                steps[associatedWorkflowIds.length].status = "failed"
                                steps[associatedWorkflowIds.length].error = reason
                                if(onFailureFinish) {
                                    onFailureFinish()
                                }
                            }))
                    },
                    name:"Archive base campaign",
                    status:"waiting",
                })

            break
        }

        return steps
    }


    render() {
        return <>
            { this.props.type === 'list' &&
                <Card style={{...defaultCardStyles, ...this.props.style}} elevation={defaultCardElevation}>
                    <Grid container>
                        <Grid item>
                            <Typography variant={"h1"} component={"div"}>Campaigns</Typography>
                        </Grid>
                        <Grid item style={{marginLeft:"10px"}} title='Create a new campaign'>
                            <Link to={"/campaigns/v2/new"}>
                                <Fab size={"small"} color={"primary"} aria-label={"Create a new campaign"}>
                                    <Add />
                                </Fab>
                            </Link>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Grid item style={{marginRight:"10px", marginBottom:"5px"}}>
                           <PagingToolbarFromProps 
                                offset={this.state.offset} 
                                limit={this.state.limit} 
                                totalItems={this.props.numCampaignsWithMetrics}
                                isLoading={this.props.receivingNumCampaignsWithMetrics} 
                                onNextPage={this.pageNext}
                                onPrevPage={this.pagePrev} 
                                buttonVariant={this.props.pagingButtonVariant || "contained"}
                                />
                        </Grid>
                        <Grid item style={{marginLeft:"10px", paddingLeft:"10px", borderLeft:"1px solid #eee"}}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={this.props.receivingCampaigns || this.props.receivingCampaignsWithMetrics}
                                            color="primary"
                                            style={{display:"inline-block"}}
                                            checked={this.state.splitTemplates}
                                            onChange={e => {
                                                this.setState({...this.state, splitTemplates: e.target.checked})
                                                this.fetchCampaignsWithMetricsViaStateVars(e.target.checked)
                                                this.fetchNumCampaignsWithMetricsViaStateVars(e.target.checked)
                                            }}
                                            inputProps={{ 'aria-label': 'Split out A/B template campaigns by template' }} 
                                        /> 
                                    }
                                    label="A/B template split"
                                />
                            </FormGroup>
                        </Grid>
                        {/* {!this.props.hideSearch && <Grid item>
                            <TextField placeholder={"search"}
                               style={{width:"40%", minWidth:"300px", marginTop:"3px"}}
                               onChange={e => this.searchChange(e.target.value.toString())}
                               InputProps={{
                                   startAdornment: <>
                                       <Select
                                           onChange={this.searchTypeChange}
                                           style={{backgroundColor:"#eee",borderTopLeftRadius:"5px"}}
                                           value={this.state.searchType}>
                                           <MenuItem value={"email"}>&nbsp;Email</MenuItem>
                                           <MenuItem value={"first_name"}>&nbsp;First Name</MenuItem>
                                           <MenuItem value={"last_name"}>&nbsp;Last Name</MenuItem>
                                           <MenuItem value={"timezone"}>&nbsp;Timezone</MenuItem>
                                       </Select>
                                       <Search  />
                                   </>
                               }}/>
                        </Grid>} */}
                    </Grid>

                    {/* Archive dialog */}
                    {this.state.upForDeletionCampaign && this.state.confirmingArchive && <MyModal key={"archive-modal-key"} 
                        title={"Archive campaign and components?"}
                        content={<>
                            <Typography key={"archivetext1"} variant={"body1"} component={"p"} style={{marginBottom:"1rem"}}>
                                Archive <b>{this.state.upForDeletionCampaign.name}</b> and <b>all associated components</b>?  We'll take these steps
                                to make it happen.  Please don't close your browser or navigate away from this page
                                while we are archiving your campaign.
                            </Typography>
                            <Typography key={"archivetext2"}  variant={"body1"} component={"ul"} style={{marginBottom:"1rem"}}>
                                {this.state.archiveSteps.map((s,i) => <li key={"archivetext3_" + i} style={{listStyleType:"none"}}>
                                    <div>
                                        <div style={{display:"inline-block", width:"40px", textAlign:"center"}}>
                                            {s.status === "waiting" && <HourglassEmpty />}
                                            {s.status === "in_progress" && <Loading mySize="sm" hideText={true} />}
                                            {s.status === "succeeded" && <span style={{color:"green"}}><Check /></span>}
                                            {s.status === "failed" && <Error color="error" />}
                                            {s.status === "cancelled" && <span style={{color:"gray"}}>X</span>}
                                        </div>
                                        <div style={{display:"inline-block"}}>
                                            {s.name}
                                        </div>
                                    </div>
                                </li>)}
                            </Typography>
                            {this.state.archiveSteps.map((s,i) => s.error? <p  key={"archivetext__" + i}  style={{color:"red"}}>{s.error}</p>: <></>)}
                            </>     
                        }
                        buttons={<>
                            <MyCancelButton disabled={!!this.state.executingArchive} 
                            onClick={(event:any) => {
                                event.stopPropagation()
                                event.preventDefault()
                                
                                this.setState({
                                    ...this.state,
                                    confirmingArchive: false,
                                    executingArchive: false,
                                    upForDeletionCampaign: null,
                                });
                            }}/>
                            <MyOkButton disabled={!!this.state.executingArchive} text="Archive campaign" onClick={(event:any) => {
                                event.stopPropagation()
                                event.preventDefault()

                                this.setState({
                                    ...this.state,
                                    executingArchive: true,
                                });
                                this.state.archiveSteps[0].execute()

                            }}/>
                        </>}
                    />}

                    {/* Main table container*/}
                    {/* {(this.props.receivingCampaignsWithMetrics && !(this.props.campaignsWithMetrics && Object.keys(this.props.campaignsWithMetrics).length > 0)) &&
                        <div style={{minHeight:"400px", float:"left", height:"100%",paddingTop:"2rem",position:"relative", zIndex:3}}>
                            <Loading overlay={true} mySize='lg' text={<Typography variant="h1">Loading...</Typography>} />
                        </div> 
                    }
                    */}
                   {this.props.receivingCampaignsWithMetrics && (!this.props.campaignsWithMetrics || Object.keys(this.props.campaignsWithMetrics).length === 0) ? 
                        <div style={{minHeight:"400px", paddingTop:"2rem"}}><Loading mySize='lg' /></div> : <>
                        <div style={{position:"relative"}}>
                        {this.props.receivingCampaignsWithMetrics && <Loading overlay={true} mySize='lg' text={<Typography variant="h1">Loading...</Typography>} />}
                        <TableContainer>
                        <Table cellSpacing={0}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{this.headerWidget("name", "Name")}</TableCell>
                                    <TableCell>{this.headerWidget("type", "Type")}</TableCell>
                                    <TableCell>{this.headerWidget("sends", "Sends")}</TableCell>
                                    <TableCell>{this.headerWidget("opens", "Opens")}</TableCell>
                                    <TableCell>{this.headerWidget("clicks", "Clicks")}</TableCell>
                                    <TableCell>
                                        {this.headerWidget("bounces", <>Bounces</>)}
                                        {this.headerWidget("unsuccessful_sends", <>Failures</>)}
                                        {this.headerWidget("unsubscribes", <>Unsubscribes</>)}
                                    </TableCell>
                                    <TableCell>{this.headerWidget("created_at", "Added")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.campaignsWithMetrics && this.props.campaignsWithMetrics.map((c,i) => <TableRow key={"row_" + i}>
                                    <TableCell style={{width:"25px", whiteSpace:"nowrap"}}>
                                    <Link to={"/insights/stats?campaign_id=" + c.campaign.id}><BarChart className='svgShadow' /></Link>
                                    <Link to={"/campaigns/edit/" + c.campaign.id}><Edit className='svgShadow' /></Link>
                                    <Link to={""} onClick={(ev) => {
                                        ev.stopPropagation()
                                        ev.preventDefault()

                                        const campaignForDeletion = Object.values(this.props.campaignsWithMetrics).find(campaign => campaign.campaign.id === c.campaign.id)
                                        if(!campaignForDeletion) {
                                            showToast("an error has occurred, please reload the page and try again", "error")
                                            return
                                        }
                                        this.setState({
                                            ...this.state,
                                            upForDeletionCampaign: campaignForDeletion.campaign,
                                            executingArchive: false,
                                            confirmingArchive: true,
                                            archiveSteps: this.getArchiveSteps(campaignForDeletion.campaign, () => {
                                                this.setState({...this.state, executingArchive: false, confirmingArchive:false })
                                                showToast("Campaign " + (campaignForDeletion.campaign.name || "") + " archived successfully", "success")
                                            }, () => {
                                                this.setState({...this.state, executingArchive: true, confirmingArchive: true })
                                                showToast("Unable to archive campaign " + (campaignForDeletion.campaign.name || ""), "error")
                                            }),
                                        })
                                    }}><Delete  className='svgShadow' /></Link>

                                    </TableCell>
                                    <TableCell><Typography component="p" variant="body1" style={{textOverflow:"ellipsis", overflow:"hidden", maxWidth:"200px"}}>
                                        {c.campaign.name}
                                        {this.state.splitTemplates && c.campaign.type==="template" && c.template_name && <>
                                            <br />
                                            <Link to={"/components/templates/edit/"+c.template_id}>{c.template_name}</Link>
                                        </>}
                                    </Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">{this.getCampaignTypeText(c.campaign.type)}</Typography></TableCell>
 
                                    <TableCell><Typography component="span" variant="body1">{(c.sends || 0).toLocaleString()}</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">{(c.opens || 0).toLocaleString()}</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">{(c.clicks || 0).toLocaleString()}</Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">
                                        Bounces: {(c.bounces || 0).toLocaleString()}<br />
                                        Failures: {(c.unsuccessful_sends || 0).toLocaleString()}<br />
                                        Unsubs:  {(c.unsubscribes || 0).toLocaleString()}
                                    </Typography></TableCell>
                                    <TableCell><Typography component="span" variant="body1">{c.campaign.created_at?
                                        new Date(c.campaign.created_at).toLocaleDateString() + " " +
                                        new Date(c.campaign.created_at).toLocaleTimeString()
                                        :""}</Typography></TableCell>  
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                </>}
                </Card>
            }

            { this.props.type === 'select' && <>
                {this.props.receivingCampaigns && <Loading mySize={"sm"} />}
                {!this.props.receivingCampaigns && <FormControl style={{width:"100%", minWidth:"200px",...this.props.style}} error={!!this.props.reportErrors[REQUEST_CAMPAIGNS]}>
                    {this.props.label && <InputLabel id={"campaignSelect"+this.state.id} required={!!this.props.required}>{this.props.label}</InputLabel>}
                        {this.getCampaignSelect(false)}
                    <FormHelperText required={!!this.props.required}>{this.props.reportErrors[REQUEST_CAMPAIGNS]}</FormHelperText>
                </FormControl>}
            </>
            }
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {

    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(ListCampaigns)

