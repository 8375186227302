
const nonBusinessDomains = ["gmail.com","yahoo.com","hotmail.com","aol.com","hotmail.co.uk","hotmail.fr","msn.com","yahoo.fr","wanadoo.fr","1orange.fr","1comcast.net","1yahoo.co.uk","1yahoo.com.br","1yahoo.co.in","1live.com","1rediffmail.com","1free.fr","1gmx.de","1web.de","2yandex.ru","2ymail.com","2libero.it","2outlook.com","2uol.com.br","2bol.com.br","2mail.ru","2cox.net","2hotmail.it","2sbcglobal.net","3sfr.fr","3live.fr","3verizon.net","3live.co.uk","3googlemail.com","3yahoo.es","3ig.com.br","3live.nl","3bigpond.com","3terra.com.br","4yahoo.it","4neuf.fr","4yahoo.de","4alice.it","4rocketmail.com","4att.net","4laposte.net","4facebook.com","4bellsouth.net","4yahoo.in","5hotmail.es","5charter.net","5yahoo.ca","5yahoo.com.au","5rambler.ru","5hotmail.de","5tiscali.it","5shaw.ca","5yahoo.co.jp","5sky.com","6earthlink.net","6optonline.net","6freenet.de","6t-online.de","6aliceadsl.fr","6virgilio.it","6home.nl","6qq.com","6telenet.be","6me.com","7yahoo.com.ar","7tiscali.co.uk","7yahoo.com.mx","7voila.fr","7gmx.net","7mail.com","7planet.nl","7tin.it","7live.it","7ntlworld.com","8arcor.de","8yahoo.co.id","8frontiernet.net","8hetnet.nl","8live.com.au","8yahoo.com.sg","8zonnet.nl","8club-internet.fr","8juno.com","8optusnet.com.au","9blueyonder.co.uk","9bluewin.ch","9skynet.be","9sympatico.ca","9windstream.net","9mac.com","9centurytel.net","9chello.nl","9live.ca","9aim.com","10bigpond.net.au"]

export function validateBusinessEmail(email: string) : string {
    
    const emailError = validateEmail(email)
    if(emailError) {
        return emailError
    }
    
    const emailParts = email.split("@")
    if(emailParts.length != 2) {
        return "Email has too few or too many @ signs"
    }

    const domain = emailParts[1].toLowerCase()
    if(nonBusinessDomains.find(d => d === domain)) {
        return "Invalid business email domain.  You must sign up with a valid business email (e.g. not gmail.com, etc)"
    }

    return ""
}

export function validateEmail(email: string) : string {
    if(email === "") {
        return "Required field";
    }
    if(email.indexOf("@") === -1) {
        return "Invalid email";
    }

    if(email.indexOf("@") !== email.lastIndexOf("@")) {
        return "Please don't use confusing email addresses (e.g. with two @s)";
    }

    if(email.indexOf(".") === -1) {
        return "Must contain dot";
    }

    return "";
}

export function validateExists(value: string) : string {
    if(value === "") {
        return "Required field";
    }

    return "";
}

export function validateDomain(value: string) : string {
    if(value === "") {
        return "Required field";
    }

    if(value.indexOf(".") === -1) {
        return "Must contain dot";
    }

    return "";
}