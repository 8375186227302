import { ResponsiveLine} from "@nivo/line";
import {getLineColor} from "./Common";
import React, {ChangeEvent} from "react";
import moment from "moment-timezone";
import {connect} from "react-redux";
import {AllState} from "../reducers";
import {Checkbox, FormControl, Grid, Input, ListItemText, MenuItem, Select, Typography, withWidth} from "@material-ui/core";
import {Loading} from "../components/Loading";
import {EmojiFlags, ViewColumn} from "@material-ui/icons";
import {fetch24HourReportDataAction} from "../actions/Reports";
import {
    sesEventsToTrack, arrayToTrueMap
} from "./Common";
import {withTens} from "../App"
import ListCampaigns from "../ListCampaigns";
import { GraphTooltip } from "../components/GraphTooltip";


interface IProps {
    id:string,
    dispatch: any,
    reportData: {[key:string]:any[]},
    errors: {[key:string]:any[]},
    receivingReportData?: {[key:string]:boolean},
    style?:any,
    reportErrors?: {[key:string]:string},
    width?: string,
    eventsToTrack?: string[],
    initiallyDeselectedColumns?: string[],
    campaignId?: string,
    hideToolbar?: boolean,
    minHeight?: string,
    dataKey?: string,
    onCampaignChange?: (campaignId:string) => void,
    onDeselectedColumnChange?: (deselectedColumns:string[]) => void,
}
interface IState {
    campaignIdSelected: string
    columnsSelected: {[key:string]:boolean},
}

class SendsByHour extends React.Component<IProps, IState> {
    props:IProps;
    state:IState;
    xTickCount: number;

    constructor(props:IProps) {
        super(props);
        this.props = props;
        this.state = {
            campaignIdSelected: this.props.campaignId || "placeholder",
            columnsSelected: arrayToTrueMap(SendsByHour.getYColumns(props)),
        };
        if(this.props.initiallyDeselectedColumns) {
            this.props.initiallyDeselectedColumns.map(column => {
                if(this.state.columnsSelected[column]) {
                    this.state.columnsSelected[column] = false
                }
                if(this.state.columnsSelected[column+"s"]) {
                    this.state.columnsSelected[column+"s"] = false
                }
            })
        }
        this.campaignChange = this.campaignChange.bind(this);
        this.columnChange = this.columnChange.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.xTickCount = 0;
    }


    static truncateText(text: string, len = 60) : string {
        if(text.length > len) {
            return text.substring(0, len) + "..."
        }

        return text
    }

    static getYColumns(props:IProps) : string[] {
        let yColumns = ["sends", "unsuccessful_sends", "unsubscribes", ...sesEventsToTrack.map(event => event + "s")]
        if(props.eventsToTrack) {
            yColumns = props.eventsToTrack
        }

        return yColumns
    }

    static processRawDataForTable(data: any[], yColumns:string[], options?: {
        campaignIds?: string[],
        columns?: {[key:string]:boolean},
    }): { data: any, haveDataAbove0?: boolean} {
        if(!data || !data.map || data.length === 0) {
            return { data:{} }
        }

        //Helper types
        interface xyVal { x:string, rawX:string, y:number }
        interface nivoData { id: string, color?: string, data: xyVal[] }
        const dataById:{[key:string]: nivoData } = {};
        let lastIndex = 0;
        let haveValueAbove0 = false;
        if(options && options.columns) {
            yColumns = Object.keys(options.columns);
        }

        //Convert data rows to useful data rows
        data.map(row => {
            if(options && options.campaignIds) {
                if(row.campaign_id == "all" && (options.campaignIds[0] == "placeholder" || options.campaignIds[0] == "all")) {
                    //allow "all" if all or placeholder is selected
                } else if(!options.campaignIds.find(id => id == row.campaign_id)) {
                    //don't allow rows for campaigns that we don't care about
                    return
                }
            } 

            const utcDate = new Date(row.emitted_at_trunc);
            const localeDateString = moment.tz(
                utcDate.getUTCFullYear()+"-"+
                withTens(utcDate.getUTCMonth()+1) +"-"+
                withTens(utcDate.getUTCDate()) +
                " " + withTens(utcDate.getUTCHours()) + ":00:00",
                moment.tz.guess()).toDate().toLocaleString();

            yColumns.map((col:string) => {
                //Skip columns that aren't selected in the filters area
                if(options && options.columns && !options.columns[col]) {
                    return;
                }

                if(!row[col] && row[col] !== 0) {
                    console.error(`Missing ${col} in row ${JSON.stringify(row)}`);
                    row[col] = 0;
                }

                const id = SendsByHour.truncateText(row.campaign_name,20) + " " + col;
                if(!dataById[id]) {
                    lastIndex++;
                    dataById[id] = {
                        id,
                        color: getLineColor(col),
                        data: [],
                    };

                    dataById[id].data.push({
                        rawX: row.emitted_at_trunc,
                        x: localeDateString,
                        y: row[col],
                    });

                    if(row[col] > 0) {
                        haveValueAbove0 = true;
                    }
                } else {
                    let found = false;
                    for(let i = 0; i < dataById[id].data.length; i++) {
                        if(dataById[id].data[i].x === localeDateString) {
                            found = true;
                            dataById[id].data[i].y += row[col];

                            if(row[col] > 0) {
                                haveValueAbove0 = true
                            }
                        }
                    }

                    if(!found) {
                        dataById[id].data.push({
                            rawX: row.emitted_at_trunc,
                            x: localeDateString,
                            y: row[col],
                        });
                        if(row[col] > 0) {
                            haveValueAbove0 = true;
                        }
                    }
                }
            });
        });

        Object.keys(dataById).map(id => dataById[id].data = dataById[id].data.sort((row, row2) => {
            return row.rawX < row2.rawX ? -1 : 1;
        }));

        return { data: Object.values(dataById), haveDataAbove0:haveValueAbove0 };
    }

    static processRawDataForToolbar(data: any[]) : {campaignNames:string[]} {
        if(!data || !data.map || data.length === 0) {
            return {
                campaignNames:[],
            }
        }
        const campaignNamesUnique:{[key:string]:boolean} = {};
        data.map((row:{campaign_name:string}) => campaignNamesUnique[row.campaign_name] = true);

        return {
            campaignNames: Object.keys(campaignNamesUnique),
        };
    }

    componentDidMount(){
        // let trimmedQuery = query.match(/[^\w]*((.|\n|\r\n)*)/); //super-trim the start of the query
        console.log("Fetching data for report " + this.props.id);
        this.props.dispatch(fetch24HourReportDataAction(this.props.id, this.state.campaignIdSelected))
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        this.xTickCount = 0;
    }

    doneLoadingNoData(): boolean {
        if(this.props.receivingReportData && this.props.receivingReportData[this.props.id]) {
            return false;
        }

        if(!this.props.reportData
            || Object.keys(this.props.reportData).length === 0
            || !this.props.reportData[this.props.id]
            || Object.keys(this.props.reportData[this.props.id]).length === 0) {
            return true;
        }

        return false;
    }

    campaignChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                campaignIdSelected: null,
            });
            if(this.props.onCampaignChange) {
                this.props.onCampaignChange(null)
            }
            return;
        }

        const campaignId = event.target.value.toString() //might also be "placeholder"
        
        //Query for data again
        console.log("Fetching hourly data for report " + this.props.id);
        this.props.dispatch(fetch24HourReportDataAction(this.props.id, campaignId))

        const thisState = {
            ...this.state,
            campaignIdSelected: campaignId,
        }

        //Alter report data to match filters
        this.xTickCount = 0;
        this.setState(thisState);
    
        if(this.props.onCampaignChange) {
            this.props.onCampaignChange(campaignId)
        }
    }

    columnChange(event:ChangeEvent<{name?:string, value?:unknown}>) {
        if(!event || !event.target || !event.target.value) {
            this.setState({
                ...this.state,
                columnsSelected: null,
            });
            return;
        }

        let columnsSelected = event.target.value as string[];
        if(typeof event.target.value === "string") {
            columnsSelected = [event.target.value];
        }

        //Select/deselect checkbox
        const thisState = this.state;
        Object.keys(thisState.columnsSelected).map((columnName:string) => {
            if(columnName) {
                thisState.columnsSelected[columnName] = false;
            }
        });
        columnsSelected.map((columnName:string) => {
            if(columnName) {
                thisState.columnsSelected[columnName] = true;
            }
        });

        //Alter report data to match filters
        this.xTickCount = 0;
        this.setState(thisState);

        if(this.props.onDeselectedColumnChange) {
            const deselectedColumns = Object.keys(thisState.columnsSelected).filter(c => !thisState.columnsSelected[c])
            this.props.onDeselectedColumnChange(deselectedColumns)
        }
    }

    renderColumnSelections(selectedIds: string[]) {
        let numSelected = 0;
        let s = "";
        Object.keys(this.state.columnsSelected).map(campaignName => {
            if(this.state.columnsSelected[campaignName]) {
                numSelected++;
            }
        });
        if(numSelected !== 1) {
            s = "s"
        }
        return <div>{numSelected + " column" + s + " selected"}</div>;
    }

    getReportData(): [any, boolean] {
        const dataSet = SendsByHour.processRawDataForTable(this.props.reportData[this.props.id],  
            SendsByHour.getYColumns(this.props), {
                campaignIds: [this.state.campaignIdSelected],
                columns: this.state.columnsSelected,
            });
        return [dataSet.data, dataSet.haveDataAbove0]
    }

    render() {
        this.xTickCount = 0;

        return <div style={{
            minHeight:this.props.minHeight || "450px",
            width:"100%",
            overflow: this.props.width !== "xs" ? "visible" : "hidden",
        }}>
            <Typography component={"div"} variant={"h2"} >Sends in last 24 hours</Typography>
            <div style={{overflow:"visible"}}>

                {!this.props.hideToolbar && <Grid container spacing={1}  style={{marginTop:"1rem", marginBottom:"1rem"}}>
                    <Grid item style={{ justifyContent:"flex-end", alignContent:"flex-end", alignItems:"flex-end", padding:"5px"}}>
                        <Typography variant={"body1"} style={{fontSize:"16pt"}}>
                            Filters:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div key={"sendsbyhourdiv1"} style={{overflow:"visible"}}>
                            <div style={{paddingLeft:"25px",overflow:"visible"}}>
                                <FormControl key={"listcampaignsselectformcontrol"}>
                                    <ListCampaigns type="select" 
                                        selectedCampaignId={this.state.campaignIdSelected}
                                        dispatch={this.props.dispatch} 
                                        onChange={this.campaignChange}
                                        selectStartAdornment={<EmojiFlags />}
                                        placeholder="All campaigns"
                                        placeholderEnabled={true}
                                        style={{
                                            width:"100%",
                                            minWidth:"300px",
                                            maxWidth:"400px", 
                                            marginRight:"5px",
                                        }}
                                        showDates={true}
                                    />
                                </FormControl>
                                <FormControl key="columnselectformcontrol">
                                    <Select id={"columnSelect"}
                                            MenuProps={{ disableScrollLock: true }}
                                            name='columnSelect'
                                            startAdornment={<ViewColumn />}
                                            onChange={this.columnChange}
                                            renderValue={selected => this.renderColumnSelections(selected as string[])}
                                            value={Object.keys(this.state.columnsSelected).map((columnName:string) => this.state.columnsSelected[columnName] ? columnName : null)}
                                            input={<Input id="select-multiple-chip" />}
                                            multiple>
                                        {Object.keys(this.state.columnsSelected).map((columnName:string) => {
                                            return <MenuItem key={"m" + columnName} value={columnName}>
                                                <Checkbox key={"c" +columnName} checked={this.state.columnsSelected[columnName]} />
                                                <ListItemText key={"li" + columnName} primary={"Column: " + columnName} />
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Grid>
                </Grid>}
            
                <div  key={this.props.dataKey || "sendsbyhourdiv2"} 
                    style={{
                    height:this.props.minHeight || "450px",
                    width:"100%",
                    overflow:this.props.width !== "xs" ? "visible" : "hidden",
                }}>
                    {/* Loading / no data */}
                    {this.props.receivingReportData && this.props.receivingReportData[this.props.id] && <div style={{paddingLeft:"1rem",paddingTop:"1rem"}}><Loading /></div>}
                    {this.props.reportErrors && !this.props.reportErrors[this.props.id] && this.doneLoadingNoData() &&  <p>Nothing here, yet</p>}

                    {/* Errors */}
                    {this.props.receivingReportData && !this.props.receivingReportData[this.props.id]  && this.props.reportErrors[this.props.id] && <p className={"error-text"}>
                        Unable to load data: {this.props.reportErrors[this.props.id]}
                    </p>}
                    
                    {this.props.receivingReportData && !this.props.receivingReportData[this.props.id] && !this.doneLoadingNoData()  && 
                    <ResponsiveLine
                    data={this.getReportData()[0]}
                    margin={{ top: 10, right: 30, bottom: 50, left: 65 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 0, max: this.getReportData()[1]?"auto":1, stacked: false, reverse: false }}
                    yFormat=","
                    pointSize={4}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={3}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    colors={{datum:"color"}}
                    tooltip={GraphTooltip} 
                    axisBottom={{
                        legend: 'date',
                        legendOffset: 36,
                        tickRotation: 15,
                        legendPosition: 'start',
                        format: value => {
                            let mod = 3;
                            if(this.props.width === "xs") {
                                mod = 5;
                            } else if(this.props.width === "sm") {
                                mod = 4;
                            }
                            if(this.xTickCount++ % mod !== 0) {
                                return "";
                            }

                            const localeDateString = new Date(value.toString()).toLocaleString();
                            return localeDateString + " UTC";
                        }
                    }}
                    axisLeft={{
                        legend: 'emails',
                        format: value => parseInt(value.toString()) == parseFloat(value.toString()) ? value.toString(): "",
                        legendOffset: -50,
                        legendPosition: 'start'
                    }}
                    useMesh={true}
                    legends={[
                        {
                            translateX: 10,
                            anchor: 'top-left',
                            direction: 'column',
                            itemDirection: 'left-to-right',
                            itemWidth: 150,
                            itemHeight: 20,
                            itemOpacity: 1,
                            symbolShape: 'circle',
                        }
                    ]}
                />}
                </div>
            </div></div>
    }
}

function mapStateToProps(state:AllState, ownProps:IProps):any {
    return state;
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(withWidth()(SendsByHour))
