
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';

interface INextPageAction {
    type: typeof NEXT_PAGE,
    tableId: string,
    offset: number,
    limit: number,
}
interface IPrevPageAction {
    type: typeof PREV_PAGE,
    tableId: string,
    offset: number,
    limit: number,
}

export type PagingToolbarActionTypes = INextPageAction | IPrevPageAction;

export function nextPageAction(tableId: string, offset:number, limit: number):INextPageAction {
    return {
        type: 'NEXT_PAGE',
        tableId,
        offset,
        limit,
    }
}

export function prevPageAction(tableId: string, offset: number, limit: number):IPrevPageAction {
    return {
        type: 'PREV_PAGE',
        tableId,
        offset,
        limit,
    }
}

export function nextPage(tableId: string, offset: number, limit: number) {
    return function(dispatch:(...args:any)=>any) {
        dispatch(nextPageAction(tableId, offset, limit));
    }
}

export function prevPage(tableId: string, offset:number, limit:number) {
    return function(dispatch:(...args:any)=>any) {
        dispatch(prevPageAction(tableId, offset, limit));
    }
}


