import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import {routes, publicRoutes, NavDef} from './RoutesV2'

export interface SEOInfo {title:string, description:string, creator?:string, type?:string}
const defaultSEOInfo : SEOInfo = {
    title:"Swift Missive",
    description: "A cost-effective email marketing tool.",
    creator: "Swift Missive",
    type:"website",
}

export default function SEO() {
    const location = useLocation();
    let seo: SEOInfo;
    if(!location || !location.pathname) {
        seo = defaultSEOInfo
    } else {
        seo = determineSEOInfo(location, routes, publicRoutes) 
        if(!seo) {
            seo = defaultSEOInfo
        }
    }

    return (
        <Helmet>
            <title>{seo.title || defaultSEOInfo.title }</title>
            <meta name='description' content={seo.description|| defaultSEOInfo.description } />
            <meta property="og:type" content={seo.type|| defaultSEOInfo.type } />
            <meta property="og:title" content={seo.title|| defaultSEOInfo.title } />
            <meta property="og:description" content={seo.description|| defaultSEOInfo.description } />
            <meta name="twitter:creator" content={seo.creator|| defaultSEOInfo.creator } />
            <meta name="twitter:card" content={seo.type|| defaultSEOInfo.type } />
            <meta name="twitter:title" content={seo.title|| defaultSEOInfo.title } />
            <meta name="twitter:description" content={seo.description|| defaultSEOInfo.description } />
        </Helmet>
    )
}

function determineSEOInfo(location:any, routes:NavDef[], publicRoutes:NavDef[]) : SEOInfo {
    const pathname = location.pathname
    let mySEO = null
    routes.find(r => {
        if(r.href === pathname) {
            mySEO = r.seo
            return mySEO
        }

        const c = r.children?.find(r => {
            const matchedChild = determineSEOInfo(location, [r], [])
            if(matchedChild) {
                mySEO = matchedChild
                return mySEO
            }
            return null
        }) || null

        return c
    })
    if(!mySEO) {
       publicRoutes.find(r => {
            if(r.href === pathname) {
                return r.seo
            }
    
            const c = r.children?.find(r => {
                const matchedChild = determineSEOInfo(location, [r], [])
                if(matchedChild) {
                    mySEO = matchedChild
                    return mySEO
                }
                return null
            }) || null
    
            return c
        })
    }

    return mySEO || null
}