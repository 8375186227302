import React from 'react';
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import ListCampaigns from "./ListCampaigns";
import {clearAllButStateData} from "./actions/Common";
import {campaignTableIds } from "./reducers";

interface IProps {
    width: string,
    dispatch: any,
}

class PageCampaigns extends React.Component<IProps, null> {

    componentDidMount(): void {
        this.props.dispatch(clearAllButStateData([
            ["reportErrors", campaignTableIds.COUNT_CAMPAIGNS_TABLE],
            ["reportErrors",  campaignTableIds.LIST_CAMPAIGNS_TABLE],
        ]));
    }

    render() {
        return <>
            <ListCampaigns type='list' campaignsListKey={"pagecampaignscampaignsListKey"} {...this.props} />
        </>
    }
}

function mapStateToProps(state:any, ownProps:IProps):any {
    return { ...state };
}

export default connect<typeof mapStateToProps, any, IProps, any>(mapStateToProps)(PageCampaigns)
